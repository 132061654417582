import React from 'react'
import "./Table.css";

const Legend = () => {
  return (
    <table>
        <tr>
            <td><span class="legend-key">HOMO</span> : Highest occupied molecular orbital</td>
            <td><span class="legend-key">LUMO</span> : Lowest unoccupied molecular orbital</td>
            <td><span class="legend-key">VEE</span> : Vertical Excitation Energy (eV) </td>
        </tr>
        <tr>
            <td><span class="legend-key"><span><math xmlns='http://www.w3.org/1998/Math/MathML'><mrow class='MJX-TeXAtom-ORD'><msubsup><mi mathvariant='bold'>λ</mi><mi mathvariant='bold'>max</mi></msubsup></mrow></math></span></span> : Wavelength of absorption (nm)</td>
            <td><span class="legend-key"><span><math xmlns='http://www.w3.org/1998/Math/MathML'><mrow class='MJX-TeXAtom-ORD'><msubsup><mi mathvariant='bold'>E</mi><mi mathvariant='bold'>1/2</mi><mrow class='MJX-TeXAtom-ORD'><msub><mi mathvariant='bold'>ox</mi></msub></mrow></msubsup></mrow></math></span></span>: Ground state oxidation potential (V vs SCE)</td>
            <td><span class="legend-key"><span><math xmlns='http://www.w3.org/1998/Math/MathML'><mrow class='MJX-TeXAtom-ORD'><msubsup><mi mathvariant='bold'>E</mi><mi mathvariant='bold'>1/2</mi><mrow class='MJX-TeXAtom-ORD'><msub><mi mathvariant='bold'>red</mi></msub></mrow></msubsup></mrow></math></span></span>: Ground state reduction potential (V vs SCE)</td>
        </tr>
        <tr>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">ox</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span></span>: S<sub>1</sub> oxidation potential (V vs SCE)</td>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">red</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span></span>: S<sub>1</sub> reduction potential (V vs SCE)</td>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">ox</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span></span>: T<sub>1</sub> oxidation potential (V vs SCE)</td>
        </tr>
        <tr>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">red</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span></span>: T<sub>1</sub> reduction potential (V vs SCE)</td>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msup><mi>&#x03BC;</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">0</mn></msub></mrow></msup></mrow></math></span></span>: Dipole Moment S<sub>0</sub> (D)</td>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msup><mi>&#x03BC;</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msup></mrow></math></span></span>: Dipole Moment S<sub>1</sub> (D)</td>
        </tr>
        <tr>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msup><mi>&#x03BC;</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msup></mrow></math></span></span>: Dipole Moment T<sub>1</sub> (D)</td>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">0,0</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span></span> : Excited state energy of S<sub>1</sub> (eV)</td>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">0,0</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span></span>: Excited state energy of T<sub>1</sub> (eV)</td>
        </tr>
        <tr>
            <td><span class="legend-key"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">E</mi><mi mathvariant="bold">i</mi></msub></mrow></math></span></span> : Ionization Potential (eV)</td>
        </tr>
    </table>


  )
}

export default Legend