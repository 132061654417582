import './App.css';
//import {Table, Header, Navbar, Subscribe, Faq, Footer} from './components/index';
import App1 from './App1';

function App() {
  return (
    <App1 />
  );
}

export default App;
