import React from "react";
import "./Footer.css";
import {GiChemicalTank} from 'react-icons/gi';
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFax,
  FaEnvelope,
  FaGlobe,
} from "react-icons/fa";

const Footer = () => {
  return (
    <section id="footer">
      <div className="container footer">
        {/* <div className="footer-box">
          <h4>Useful Links</h4>
          <div className="footer-links">
            <a href="#">&bull; Support</a>
            <a href="#">&bull; About</a>
            <a href="#">&bull; Learn</a>
          </div>
        </div> */}
        {/* <div className="footer-box">
          <h4>Support</h4>
          <div className="footer-links">
            <a href="#">&bull; Support</a>
            <a href="#">&bull; About</a>
            <a href="#">&bull; Learn</a>
          </div>
        </div> */}
        <div className="footer-box">
          <h4>Contact Us</h4>
          <div className="footer-contact u-text-small">
            <p>
              <FaFax /> &nbsp; Contact: Prof. Steven A. Lopez
            </p>
            <p>
              <FaPhoneAlt /> &nbsp; Phone: +1 617-373-3696
            </p>
            <p>
              <FaEnvelope /> &nbsp; Email: s.lopez@northeastern.edu
            </p>
            <p>
              <FaGlobe /> &nbsp; Website: &nbsp; <a href="https://web.northeastern.edu/lopezlab/" target="_blank" className="link">https://web.northeastern.edu/lopezlab/</a>
            </p>
            <p>
              <FaMapMarkerAlt /> &nbsp; Address: Northeastern University
            </p> 
          </div>
        </div>
        <div className="footer-box">
          <div>
            < GiChemicalTank color="#fff" size={45}/>
            <p className='logo-text'>VERDEmaterials&nbsp;<span>DB</span></p>
          </div>
          <p className="u-text-small">&copy; Copyright verdematerials&nbsp;db</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;