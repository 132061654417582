import React, {useState} from 'react'
import "./Navbar.css";
import {GiChemicalTank} from 'react-icons/gi';
import {AiOutlineBars} from 'react-icons/ai';
import {RiCloseLine} from 'react-icons/ri';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <nav className="container navbar">
      <div className='logo'>
          < GiChemicalTank color="#fff" size={45}/>
          <p className='logo-text'>
              VERDE<span className='white'>&nbsp;materials&nbsp; </span><span className='orange'>DB</span>
          </p>
      </div>
      <menu>
        <ul className='nav-links' id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}>
          <li><a href="#">Home</a></li>
          <li><a href="#table">VerdeDB</a></li>
          <li><a href="#subscribe">Download</a></li>
          {/* <li><a href="#faq">FAQs</a></li> */}
          {/* <li className='nav-btn'><a href="#" className='btn btn-dark'>Add SMILE</a></li> */}
        </ul>
      </menu>
      <div className='menu-icons' onClick={toggleMenu}>
        {
          showMenu ? (<RiCloseLine color='#fff' size={35} />): (<AiOutlineBars color='#fff' size={35} />)
        }
      </div>
    </nav>
  )
}

export default Navbar;