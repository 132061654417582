import React, { useMemo } from 'react';
import "./Table.css";
import {BsFillBookmarkStarFill} from "react-icons/bs";
import {data1} from './Data1.js';
import MaterialReactTable from 'material-react-table';
//Material-UI Imports
import {  Box,  Button } from '@mui/material';
import Legend from './Legend';



const Table = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'HOMO',
        filterVariant: 'range',
        header: 'HOMO',
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
      },
      {
        accessorKey: 'LUMO',
        filterVariant: 'range',
        header: 'LUMO',
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
      },
      {
        accessorKey: 'VEE',
        filterVariant: 'range',
        header: 'VEE',
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
      },
      {
        accessorKey: 'Lmax',
        filterVariant: 'range',
        header: <span><math xmlns='http://www.w3.org/1998/Math/MathML'><mrow class='MJX-TeXAtom-ORD'><msubsup><mi mathvariant='bold'>λ</mi><mi mathvariant='bold'>max</mi></msubsup></mrow></math></span>,
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          > 
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
      },
      {
        accessorKey: 'Eox',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns='http://www.w3.org/1998/Math/MathML'><mrow class='MJX-TeXAtom-ORD'><msubsup><mi mathvariant='bold'>E</mi><mi mathvariant='bold'>1/2</mi><mrow class='MJX-TeXAtom-ORD'><msub><mi mathvariant='bold'>ox</mi></msub></mrow></msubsup></mrow></math></span>
      },
      {
        accessorKey: 'Ered',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns='http://www.w3.org/1998/Math/MathML'><mrow class='MJX-TeXAtom-ORD'><msubsup><mi mathvariant='bold'>E</mi><mi mathvariant='bold'>1/2</mi><mrow class='MJX-TeXAtom-ORD'><msub><mi mathvariant='bold'>red</mi></msub></mrow></msubsup></mrow></math></span>
      },
      {
        accessorKey: 'ES1ox',
        filterVariant: 'range',
        size: 10, 
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">ox</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span>
      },
      {
        accessorKey: 'ES1red',
        filterVariant: 'range',
        size: 10, 
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">red</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span>
      },
      {
        accessorKey: 'ET1ox',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">ox</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span>
      },
      {
        accessorKey: 'ET1red',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">red</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span>
      },
      {
        accessorKey: 'DipoleMomentS0',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msup><mi>&#x03BC;</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">0</mn></msub></mrow></msup></mrow></math></span>
      },
      {
        accessorKey: 'DipoleMomentS1',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msup><mi>&#x03BC;</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msup></mrow></math></span>
      },
      {
        accessorKey: 'DipoleMomentT1',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msup><mi>&#x03BC;</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msup></mrow></math></span>
      },
      {
        accessorKey: 'E00S1',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            //onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">0,0</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span>
      },
      {
        accessorKey: 'E00T1',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
           // onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">0,0</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math></span>
      },
      {
        accessorKey: 'ionizationPotential',
        filterVariant: 'range', 
        size: 10,
        Cell: ({ cell, row}) => (
          <Box
            // onClick={() => alert(cell.getValue() + " Hello " )}
            component="span"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderRadius: '0.25rem',
              color: 'black',
              fontSize: '15px',
            })}
          >
            {cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : 'nan'}
          </Box>
        ),
        header: <span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">E</mi><mi mathvariant="bold">i</mi></msub></mrow></math></span>
      },
    ],[]);

  return (
    <section id='table'>
      <div className='table'>
        <div className='title'>
          <BsFillBookmarkStarFill color="white" size={30} />
          <h2>Verde materials DB</h2>
          <div>
            <Legend />
          </div>
        </div>
        <div className='container-table'>
          <MaterialReactTable
            columns={columns}
            data={data1} 
            enableRowSelection
            renderTopToolbarCustomActions={({ table }) => {
              const handleActivate = () => {
                const selectedRows = table.getSelectedRowModel().flatRows;
                const rowsContent = selectedRows.map(row => {
                  const { InchiKey, Smiles, MolView_url, HOMO, LUMO, VEE, Lmax, E00S1, E00T1,
                    Eox, Ered,  
                    ES1ox, ES1red,
                    ET1ox, ET1red,
                    DipoleMomentS0, DipoleMomentS1, DipoleMomentT1, ionizationPotential, source_directory } = row.original;
                  return `
                    <div>
                      <p style="color: white;">InChIKey: ${InchiKey}</p>
                      <p style="color: white;">SMILES: ${Smiles}</p>
                      <p style="color: white;">MolView URL: <a href="${MolView_url}" target="_blank">${MolView_url}</a></p>
                      <iframe src="${row.original.MolView_url}" style="width:800px;height:600px;"></iframe>
                      <p style="color: white;">HOMO: ${HOMO ? HOMO : 'null'}</p>
                      <p style="color: white;">LUMO: ${LUMO ? LUMO : 'null'}</p>
                      <p style="color: white;">Vertical Excitation Energy: ${VEE ? VEE : 'null'}</p>
                      <p style="color: white;"><span><math xmlns='http://www.w3.org/1998/Math/MathML'><mrow class='MJX-TeXAtom-ORD'><msubsup><mi mathvariant='bold'>λ</mi><mi mathvariant='bold'>max</mi></msubsup></mrow></math>(nm)</span>: ${Lmax ? Lmax : 'null'}</p>
                      <p style="color: white;"><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">0,0</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math>(V vs SCE): ${E00S1 ? E00S1 : 'null'}</p>
                      <p style="color: white;"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">0,0</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math>(V vs SCE)</span>: ${E00T1 ? E00T1 : 'null'}</p>
                      <p style="color: white;"><span><math xmlns='http://www.w3.org/1998/Math/MathML'><mrow class='MJX-TeXAtom-ORD'><msubsup><mi mathvariant='bold'>E</mi><mi mathvariant='bold'>1/2</mi><mrow class='MJX-TeXAtom-ORD'><msub><mi mathvariant='bold'>ox</mi></msub></mrow></msubsup></mrow></math>(V vs SCE)</span>: ${Eox ? Eox : 'null'}</p>
                      <p style="color: white;"><span><math xmlns='http://www.w3.org/1998/Math/MathML'><mrow class='MJX-TeXAtom-ORD'><msubsup><mi mathvariant='bold'>E</mi><mi mathvariant='bold'>1/2</mi><mrow class='MJX-TeXAtom-ORD'><msub><mi mathvariant='bold'>red</mi></msub></mrow></msubsup></mrow></math>(V vs SCE)</span>: ${Ered ? Ered : 'null'}</p>
                      <p style="color: white;"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">ox</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math>(V vs SCE)</span>: ${ES1ox ? ES1ox : 'null'}</p>
                      <p style="color: white;"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">red</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math>(V vs SCE)</span>: ${ES1red ? ES1red : 'null'}</p>
                      <p style="color: white;"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">ox</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math>(V vs SCE)</span>: ${ET1ox ? ET1ox : 'null'}</p>
                      <p style="color: white;"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msubsup><mi mathvariant="bold">E</mi><mi mathvariant="bold">red</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msubsup></mrow></math>(V vs SCE)</span>: ${ET1red ? ET1red : 'null'}</p>
                      <p style="color: white;"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msup><mi>&#x03BC;</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">0</mn></msub></mrow></msup></mrow></math>(D)</span>: ${DipoleMomentS0 ? DipoleMomentS0 : 'null'}</p>
                      <p style="color: white;"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msup><mi>&#x03BC;</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">S</mi><mn mathvariant="bold">1</mn></msub></mrow></msup></mrow></math>(D)</span>: ${DipoleMomentS1 ? DipoleMomentS1 : 'null'}</p>
                      <p style="color: white;"><span><math xmlns="http://www.w3.org/1998/Math/MathML"><mrow class="MJX-TeXAtom-ORD"><msup><mi>&#x03BC;</mi><mrow class="MJX-TeXAtom-ORD"><msub><mi mathvariant="bold">T</mi><mn mathvariant="bold">1</mn></msub></mrow></msup></mrow></math>(D)</span>: ${DipoleMomentT1? DipoleMomentT1 : 'null'}</p>
                      <p style="color: white;">Ionization Potential (eV): ${ionizationPotential ? ionizationPotential : 'null'}</p>
                      <p style="color: white;">Source Directory: ${source_directory ? source_directory : 'null'}</p>
                      <hr />
                    </div>
                  `;
                }).join('');
              
                const content = `
                  <!DOCTYPE html>
                  <html>
                    <head>
                      <title>Details</title>
                    </head>
                    <body  style="background: linear-gradient(90deg, rgba(131,58,180,1) 0%,
                    rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);" >
                      <h1>Selected Rows</h1>
                      ${rowsContent}
                    </body>
                  </html>
                `;
                const newWindow = window.open();
                newWindow.document.write(content);
              };
              
                            

              return (
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <Button
                    color="success"
                    disabled={!table.getIsSomeRowsSelected()}
                    onClick={handleActivate}
                    variant="contained"
                  >
                    Show Details
                  </Button>
                </div>
              );
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default Table

