import React from 'react';
import "./Header.css";
import {BsMouse} from 'react-icons/bs';

const Header = () => {
  return (
    <section id="header">
      <div className='header'>
        <h1>
          <span>Virtual Excited state Reference for the Discovery of Electronic materials Database</span><br />
          <span>VERDE materials Database</span><br />
        </h1>
        <p className='u-text-small u-text-light'>
          The Lopez group at Northeastern University (Boston, USA)
          announces the Virtual Excited State Reference
          for the Discovery of Electronic Materials Database
          (VERDE materials DB) is searchable, open-access
          via www.verdedb.org. We have established this resource
          in line with our interest in light-responsive
          &pi;-conjugated organic molecules with applications 
          in green chemistry, organic solar cells, 
          and organic redox flow batteries. It includes 
          results of our active and past virtual screening studies; to date, 
          more than 13000 density functional theory (DFT) calculations have been 
          performed on 1500 molecules to obtain frontier molecular orbitals and 
          photophysical properties, including excitation energies, dipole moments,
          and redox potentials. We are leveraging VERDE materials B to train machine 
          learning algorithms to identify new materials and structure-property 
          relationships between molecular ground- and excited-states. If you are 
          interested in downloading the complete set, please e-mail Prof. Lopez at 
          s.lopez[at]northeastern.edu. You can find out more 
          about our group at 
          <a className='lopez' href='https://web.northeastern.edu/lopezlab/' target='_blank'> https://web.northeastern.edu/lopezlab/</a>. 
        </p>
        <div className='floating-icon'>
          <a href="#table">
            <BsMouse color="#fff" size={25} className="mouse" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Header;