import './App.css';
import {Table, Header, Navbar, Subscribe, Faq, Footer} from './components/index';

function App1() {
  return (
    <main>
      <header className="header-bg">
        <Navbar />
        <Header />
      </header>
      <Table />
      <Subscribe />
      <Footer />
    </main>
  );
}

export default App1;