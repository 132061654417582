export const data1 = [
    {
      "InchiKey": "AAACLBGNAMDLRM-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)[N+](=O)[O-])cc(c5ccc4)C1=CC=C(C1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)[N+](=O)[O-])cc(c5ccc4)C1=CC=C(C1)[N+](=O)[O-]",
      "HOMO": "-5.68",
      "LUMO": "-3.29",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "1.55",
      "E00T1": "0.33",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.65",
      "DipoleMomentS1": "7.65",
      "DipoleMomentT1": "9.44",
      "ionizationPotential": "6.94",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AABWABYCSVIRCD-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1[nH]cc(c1)C)C#N)c1[nH]cc(c1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1[nH]cc(c1)C)C#N)c1[nH]cc(c1)C",
      "HOMO": "-6",
      "LUMO": "-2.7",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "1.9",
      "Eox": "-1.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.15",
      "ET1red": "",
      "DipoleMomentS0": "1.94",
      "DipoleMomentS1": "1.94",
      "DipoleMomentT1": "8.3",
      "ionizationPotential": "7.23",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "AAORCGFHHZZHNA-DQRAZIAOSA-N",
      "Smiles": "O=Cn1cccc1c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1cccn1C=O",
      "MolView_url": "http://molview.org/?q=O=Cn1cccc1c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1cccn1C=O",
      "HOMO": "-5.5",
      "LUMO": "-2.53",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "1.45",
      "E00T1": "0.24",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-0.62",
      "ES1red": "",
      "ET1ox": "0.59",
      "ET1red": "",
      "DipoleMomentS0": "7.91",
      "DipoleMomentS1": "7.91",
      "DipoleMomentT1": "6.94",
      "ionizationPotential": "5.68",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "AAVCLCWJGYZTDA-DQRAZIAOSA-N",
      "Smiles": "Sc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)S",
      "HOMO": "-5.99",
      "LUMO": "-2.94",
      "VEE": "2.56",
      "Lmax": "484.296875",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.12",
      "DipoleMomentS1": "8.12",
      "DipoleMomentT1": "6.76",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "AAVCLCWJGYZTDA-QURGRASLSA-N",
      "Smiles": "Sc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)S",
      "HOMO": "-5.83",
      "LUMO": "-3.05",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.19",
      "ET1red": "",
      "DipoleMomentS0": "4.23",
      "DipoleMomentS1": "4.23",
      "DipoleMomentT1": "3.79",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ABQDDWFBVUXCCM-UHFFFAOYSA-N",
      "Smiles": "C1=CC=C(C1)c1cc2c3cc(C4=CC=CC4)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=CC1)cc(c5ccc4)C1=CC=CC1",
      "MolView_url": "http://molview.org/?q=C1=CC=C(C1)c1cc2c3cc(C4=CC=CC4)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=CC1)cc(c5ccc4)C1=CC=CC1",
      "HOMO": "-5.11",
      "LUMO": "-2.43",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "1.85",
      "E00T1": "1.07",
      "Eox": "-4.26",
      "Ered": "",
      "ES1ox": "-6.11",
      "ES1red": "",
      "ET1ox": "-5.33",
      "ET1red": "",
      "DipoleMomentS0": "1.27",
      "DipoleMomentS1": "1.27",
      "DipoleMomentT1": "1.45",
      "ionizationPotential": "5.75",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ACHAKXGARSBJCU-FMQUCBEESA-N",
      "Smiles": "ClC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)Cl",
      "HOMO": "-6.15",
      "LUMO": "-3.67",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "1.8",
      "E00T1": "0.43",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "-0.51",
      "ES1red": "",
      "ET1ox": "0.86",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.04",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ACQPYVKVSJDFCK-FMQUCBEESA-N",
      "Smiles": "COc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)OC",
      "HOMO": "-5.68",
      "LUMO": "-3",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "2.03",
      "E00T1": "0.76",
      "Eox": "0.79",
      "Ered": "",
      "ES1ox": "-1.24",
      "ES1red": "",
      "ET1ox": "0.03",
      "ET1red": "",
      "DipoleMomentS0": "3.72",
      "DipoleMomentS1": "3.72",
      "DipoleMomentT1": "3.7",
      "ionizationPotential": "6.34",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ACTGLTIMWSZFGY-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)C",
      "HOMO": "-5.42",
      "LUMO": "-2.45",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.1",
      "E00T1": "1.24",
      "Eox": "0.56",
      "Ered": "",
      "ES1ox": "-1.54",
      "ES1red": "",
      "ET1ox": "-0.68",
      "ET1red": "",
      "DipoleMomentS0": "5.01",
      "DipoleMomentS1": "5.01",
      "DipoleMomentT1": "5.02",
      "ionizationPotential": "6.04",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "ADAZAFCPASNDPP-FMQUCBEESA-N",
      "Smiles": "O=Cn1c(O)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)O",
      "MolView_url": "http://molview.org/?q=O=Cn1c(O)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)O",
      "HOMO": "-5.56",
      "LUMO": "-2.96",
      "VEE": "3.05",
      "Lmax": "406.491803278689",
      "E00S1": "",
      "E00T1": "0.74",
      "Eox": "0.61",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.13",
      "ET1red": "",
      "DipoleMomentS0": "1.15",
      "DipoleMomentS1": "1.15",
      "DipoleMomentT1": "0.37",
      "ionizationPotential": "6.33",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ADAZAFCPASNDPP-VXPUYCOJSA-N",
      "Smiles": "O=Cn1c(O)ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)O",
      "MolView_url": "http://molview.org/?q=O=Cn1c(O)ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)O",
      "HOMO": "-5.6",
      "LUMO": "-2.92",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.51",
      "DipoleMomentS1": "5.51",
      "DipoleMomentT1": "5.57",
      "ionizationPotential": "6.32",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "ADBHWIFYPVCEFF-DHTIVLMFSA-N",
      "Smiles": "FC(O/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N\\OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N\\OC(F)(F)F)(F)F",
      "HOMO": "-7.05",
      "LUMO": "-3.21",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.88",
      "ET1red": "",
      "DipoleMomentS0": "6.03",
      "DipoleMomentS1": "6.03",
      "DipoleMomentT1": "5.66",
      "ionizationPotential": "7.98",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "ADBHWIFYPVCEFF-DQZFJYGGSA-N",
      "Smiles": "FC(O/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N\\OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N\\OC(F)(F)F)(F)F",
      "HOMO": "-6.73",
      "LUMO": "-2.83",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "0.54",
      "Eox": "1.57",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.03",
      "ET1red": "",
      "DipoleMomentS0": "0.33",
      "DipoleMomentS1": "0.33",
      "DipoleMomentT1": "0.78",
      "ionizationPotential": "7.68",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "ADKRDEGKLBNGBG-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc([nH]c1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1[nH]cc(c1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc([nH]c1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1[nH]cc(c1)C#N",
      "HOMO": "-6.55",
      "LUMO": "-3.18",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "2.61",
      "E00T1": "2.05",
      "Eox": "1.69",
      "Ered": "",
      "ES1ox": "-0.92",
      "ES1red": "",
      "ET1ox": "-0.36",
      "ET1red": "",
      "DipoleMomentS0": "7.23",
      "DipoleMomentS1": "7.23",
      "DipoleMomentT1": "5.29",
      "ionizationPotential": "7.76",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ADNBYRVFYMWXLS-VHXPQNKSSA-N",
      "Smiles": "FC(OC#Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C#COC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC#Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C#COC(F)(F)F)(F)F",
      "HOMO": "-5.95",
      "LUMO": "-2.7",
      "VEE": "3.07",
      "Lmax": "403.843648208469",
      "E00S1": "",
      "E00T1": "0.35",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.83",
      "DipoleMomentS1": "2.83",
      "DipoleMomentT1": "2.21",
      "ionizationPotential": "5.86",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "ADNOQZQZUQQVRY-UHFFFAOYSA-N",
      "Smiles": "CC1=CC=C(S1(=O)=O)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(S1(=O)=O)[C@]1(C)N=N1",
      "HOMO": "-7.05",
      "LUMO": "-2.52",
      "VEE": "3.15",
      "Lmax": "393.587301587302",
      "E00S1": "2.99",
      "E00T1": "",
      "Eox": "2.05",
      "Ered": "",
      "ES1ox": "-0.94",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.96",
      "DipoleMomentS1": "4.96",
      "DipoleMomentT1": "5.25",
      "ionizationPotential": "8.37",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ADTPNEOAPBZJMN-UHFFFAOYSA-N",
      "Smiles": "N#C/N=C/c1cc2c3cc(/C=N/C#N)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/C#N)cc(c5ccc4)/C=N/C#N",
      "MolView_url": "http://molview.org/?q=N#C/N=C/c1cc2c3cc(/C=N/C#N)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/C#N)cc(c5ccc4)/C=N/C#N",
      "HOMO": "-6.21",
      "LUMO": "-3.89",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "1.61",
      "E00T1": "0.87",
      "Eox": "1.4",
      "Ered": "",
      "ES1ox": "-0.21",
      "ES1red": "",
      "ET1ox": "0.53",
      "ET1red": "",
      "DipoleMomentS0": "10.09",
      "DipoleMomentS1": "10.09",
      "DipoleMomentT1": "10.74",
      "ionizationPotential": "0.03",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AEAYIMCBGRSGQI-DQSJHHFOSA-N",
      "Smiles": "COc1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(cn1)OC",
      "HOMO": "-6.3",
      "LUMO": "-2.53",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "0.55",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.73",
      "ET1red": "",
      "DipoleMomentS0": "10.71",
      "DipoleMomentS1": "10.71",
      "DipoleMomentT1": "7.85",
      "ionizationPotential": "6.74",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "AEGULJNXPWYZNH-FMQUCBEESA-N",
      "Smiles": "COc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)OC",
      "HOMO": "-5.87",
      "LUMO": "-3.15",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "2.05",
      "E00T1": "0.73",
      "Eox": "0.98",
      "Ered": "",
      "ES1ox": "-1.07",
      "ES1red": "",
      "ET1ox": "0.25",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.3",
      "ionizationPotential": "6.56",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "AEJUBZUKERUAJG-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(Cl)ccc1c1cc2c3cc(c4ccc(n4C=O)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)Cl)cc(c5ccc4)c1ccc(n1C=O)Cl",
      "MolView_url": "http://molview.org/?q=O=Cn1c(Cl)ccc1c1cc2c3cc(c4ccc(n4C=O)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)Cl)cc(c5ccc4)c1ccc(n1C=O)Cl",
      "HOMO": "-5.78",
      "LUMO": "-2.79",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "2.1",
      "E00T1": "1.26",
      "Eox": "-4.19",
      "Ered": "",
      "ES1ox": "-6.29",
      "ES1red": "",
      "ET1ox": "-5.45",
      "ET1red": "",
      "DipoleMomentS0": "14.55",
      "DipoleMomentS1": "14.55",
      "DipoleMomentT1": "13.92",
      "ionizationPotential": "0.17",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AEKZZCLDHFTUPJ-FLWNBWAVSA-N",
      "Smiles": "FC(c1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-6.18",
      "LUMO": "-3.17",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "1.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.89",
      "ET1red": "",
      "DipoleMomentS0": "7.98",
      "DipoleMomentS1": "7.98",
      "DipoleMomentT1": "6.53",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "AEKZZCLDHFTUPJ-QVIHXGFCSA-N",
      "Smiles": "FC(c1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-6.27",
      "LUMO": "-3.31",
      "VEE": "3.07",
      "Lmax": "403.843648208469",
      "E00S1": "",
      "E00T1": "0.86",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "0.37",
      "DipoleMomentS1": "0.37",
      "DipoleMomentT1": "0.38",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "AEOIGRJIPRLKBQ-DQRAZIAOSA-N",
      "Smiles": "[O-][N+](=O)c1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)[N+](=O)[O-]",
      "HOMO": "-6.7",
      "LUMO": "-3",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "0.56",
      "Eox": "1.6",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.04",
      "ET1red": "",
      "DipoleMomentS0": "7.51",
      "DipoleMomentS1": "7.51",
      "DipoleMomentT1": "7.24",
      "ionizationPotential": "7.7",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "AEOIGRJIPRLKBQ-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)[N+](=O)[O-]",
      "HOMO": "-6.9",
      "LUMO": "-3.19",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "1.39",
      "Eox": "2.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.63",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.97",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "AESSLHRQUPMOFD-VHXPQNKSSA-N",
      "Smiles": "Oc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)O",
      "HOMO": "-5.39",
      "LUMO": "-2.31",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "0.5",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "8.75",
      "DipoleMomentS1": "8.75",
      "DipoleMomentT1": "8.09",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "AFDQNWQLQWRHIC-UHFFFAOYSA-N",
      "Smiles": "N#CC1=CC=C(C1)c1cc2ccc3c4c2c2c1ccc1c2c4c(cc1C1=CC=C(C1)C#N)cc3",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(C1)c1cc2ccc3c4c2c2c1ccc1c2c4c(cc1C1=CC=C(C1)C#N)cc3",
      "HOMO": "-6.25",
      "LUMO": "-2.46",
      "VEE": "3.27",
      "Lmax": "379.14373088685",
      "E00S1": "2.53",
      "E00T1": "1.5",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.42",
      "DipoleMomentS1": "5.42",
      "DipoleMomentT1": "4.15",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/dbf_1"
    },
    {
      "InchiKey": "AFFVXBUJXLTTRV-YIJOZEPASA-N",
      "Smiles": "S/N=C/c1ccc(o1)/N=N/c1ccc(o1)/C=N/S",
      "MolView_url": "http://molview.org/?q=S/N=C/c1ccc(o1)/N=N/c1ccc(o1)/C=N/S",
      "HOMO": "-5.94",
      "LUMO": "-3.16",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.4",
      "DipoleMomentS1": "1.4",
      "DipoleMomentT1": "3.25",
      "ionizationPotential": "6.75",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "AFHDJRYYPAMOJU-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccccn1)c1ccccn1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccccn1)c1ccccn1",
      "HOMO": "-7.09",
      "LUMO": "-3.16",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "3.05",
      "E00T1": "2.42",
      "Eox": "2.2",
      "Ered": "",
      "ES1ox": "-0.85",
      "ES1red": "",
      "ET1ox": "-0.22",
      "ET1red": "",
      "DipoleMomentS0": "3.73",
      "DipoleMomentS1": "3.73",
      "DipoleMomentT1": "5.47",
      "ionizationPotential": "7.94",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "AFVMCDTWPLMQCF-HSFFGMMNSA-N",
      "Smiles": "N#C/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C/C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C/C#N",
      "HOMO": "-7.4",
      "LUMO": "-3.44",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "3.06",
      "E00T1": "0.01",
      "Eox": "2.54",
      "Ered": "",
      "ES1ox": "-0.52",
      "ES1red": "",
      "ET1ox": "2.53",
      "ET1red": "",
      "DipoleMomentS0": "5.37",
      "DipoleMomentS1": "5.37",
      "DipoleMomentT1": "5.35",
      "ionizationPotential": "8.6",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "AFVPQFMVEBIYAA-UHFFFAOYSA-N",
      "Smiles": "Cc1nnc(nn1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1nnc(nn1)[C@]1(C)N=N1",
      "HOMO": "-7.16",
      "LUMO": "-3.2",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "2.02",
      "E00T1": "1.36",
      "Eox": "2.19",
      "Ered": "",
      "ES1ox": "0.17",
      "ES1red": "",
      "ET1ox": "0.83",
      "ET1red": "",
      "DipoleMomentS0": "2.34",
      "DipoleMomentS1": "2.34",
      "DipoleMomentT1": "2.51",
      "ionizationPotential": "8.48",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "AFWGWVQKWXZTQB-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1coc(n1)c1cc2c3cc(c4occ(n4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)[N+](=O)[O-])cc(c5ccc4)c1occ(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1coc(n1)c1cc2c3cc(c4occ(n4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)[N+](=O)[O-])cc(c5ccc4)c1occ(n1)[N+](=O)[O-]",
      "HOMO": "-5.96",
      "LUMO": "-3.3",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.35",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.33",
      "DipoleMomentS1": "1.33",
      "DipoleMomentT1": "2.27",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AFXOODAXTQESGF-VHXPQNKSSA-N",
      "Smiles": "Sc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)S",
      "HOMO": "-5.87",
      "LUMO": "-2.4",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.44",
      "DipoleMomentS1": "3.44",
      "DipoleMomentT1": "2.86",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "AGEDITIIUHHGKA-HUWOUMPJSA-N",
      "Smiles": "O/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\O",
      "MolView_url": "http://molview.org/?q=O/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\O",
      "HOMO": "-6.2",
      "LUMO": "-3.12",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.84",
      "DipoleMomentS1": "4.84",
      "DipoleMomentT1": "3.97",
      "ionizationPotential": "6.97",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "AGEDITIIUHHGKA-NSFMOVNFSA-N",
      "Smiles": "O/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\O",
      "MolView_url": "http://molview.org/?q=O/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\O",
      "HOMO": "-6.25",
      "LUMO": "-3.26",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.08",
      "DipoleMomentS1": "1.08",
      "DipoleMomentT1": "1.1",
      "ionizationPotential": "7.07",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "AHJHWQYOSRYEHZ-UHFFFAOYSA-N",
      "Smiles": "COc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)OC",
      "HOMO": "-5.35",
      "LUMO": "-2.62",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "1.87",
      "E00T1": "",
      "Eox": "0.56",
      "Ered": "",
      "ES1ox": "-1.31",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.7",
      "DipoleMomentS1": "6.7",
      "DipoleMomentT1": "6.39",
      "ionizationPotential": "5.99",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "AHUFQCCLEPEHAM-DQSJHHFOSA-N",
      "Smiles": "FC(c1ccc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(o1)C(F)(F)F)(F)F",
      "HOMO": "-5.64",
      "LUMO": "-2.67",
      "VEE": "2.56",
      "Lmax": "484.296875",
      "E00S1": "",
      "E00T1": "0.33",
      "Eox": "0.99",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.66",
      "ET1red": "",
      "DipoleMomentS0": "3.55",
      "DipoleMomentS1": "3.55",
      "DipoleMomentT1": "6.17",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "AHVSIJQUKZCIQD-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)[N+](=O)[O-])c1ncc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)[N+](=O)[O-])c1ncc(o1)[N+](=O)[O-]",
      "HOMO": "-7.63",
      "LUMO": "-3.69",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "3.05",
      "E00T1": "2.49",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.82",
      "DipoleMomentS1": "5.82",
      "DipoleMomentT1": "4.17",
      "ionizationPotential": "8.83",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "AIBUDYASXYCUJS-FMQUCBEESA-N",
      "Smiles": "COc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)OC",
      "HOMO": "-5.39",
      "LUMO": "-2.89",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.44",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.54",
      "DipoleMomentS1": "3.54",
      "DipoleMomentT1": "3.54",
      "ionizationPotential": "5.93",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "AIBUDYASXYCUJS-VXPUYCOJSA-N",
      "Smiles": "COc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)OC",
      "HOMO": "-5.44",
      "LUMO": "-2.86",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.36",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "12.19",
      "DipoleMomentS1": "12.19",
      "DipoleMomentT1": "9.78",
      "ionizationPotential": "5.9",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "AIBYCLNJAVKIQQ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)C#N)cc(c5ccc4)c1cnc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)C#N)cc(c5ccc4)c1cnc([nH]1)C#N",
      "HOMO": "-5.72",
      "LUMO": "-2.89",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "1.99",
      "E00T1": "1.16",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "-1.07",
      "ES1red": "",
      "ET1ox": "-0.24",
      "ET1red": "",
      "DipoleMomentS0": "1.03",
      "DipoleMomentS1": "1.03",
      "DipoleMomentT1": "1.72",
      "ionizationPotential": "7.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AIFHBMMGUWWCNR-UHFFFAOYSA-N",
      "Smiles": "SC#Cc1cc(C#N)cc(c1C#N)C#CS",
      "MolView_url": "http://molview.org/?q=SC#Cc1cc(C#N)cc(c1C#N)C#CS",
      "HOMO": "-6.75",
      "LUMO": "-2.67",
      "VEE": "3.23",
      "Lmax": "383.839009287926",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.94",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.19",
      "DipoleMomentS1": "3.19",
      "DipoleMomentT1": "6.03",
      "ionizationPotential": "8.16",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "AIMCWSZPGPELED-FMQUCBEESA-N",
      "Smiles": "COc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)OC",
      "HOMO": "-5.76",
      "LUMO": "-3.13",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "",
      "E00T1": "0.74",
      "Eox": "0.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.08",
      "ET1red": "",
      "DipoleMomentS0": "1.82",
      "DipoleMomentS1": "1.82",
      "DipoleMomentT1": "2.01",
      "ionizationPotential": "6.33",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "AIMCWSZPGPELED-VXPUYCOJSA-N",
      "Smiles": "COc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)OC",
      "HOMO": "-5.77",
      "LUMO": "-3.09",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.6",
      "ET1red": "",
      "DipoleMomentS0": "9.2",
      "DipoleMomentS1": "9.2",
      "DipoleMomentT1": "7.76",
      "ionizationPotential": "6.31",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "AIMQZQFEGVNVMF-WCWDXBQESA-N",
      "Smiles": "Sc1cc([nH]c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1[nH]cc(c1)S",
      "MolView_url": "http://molview.org/?q=Sc1cc([nH]c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1[nH]cc(c1)S",
      "HOMO": "-5.85",
      "LUMO": "-2.65",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "1.08",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.17",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "2.07",
      "ionizationPotential": "6.56",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "AISBPSZFKSOVKQ-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(s1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(s1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-7.88",
      "LUMO": "-2.89",
      "VEE": "3.97",
      "Lmax": "312.292191435768",
      "E00S1": "3.41",
      "E00T1": "",
      "Eox": "2.91",
      "Ered": "",
      "ES1ox": "-0.5",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.15",
      "DipoleMomentS1": "0.15",
      "DipoleMomentT1": "6.29",
      "ionizationPotential": "9.44",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "AISPCOPFPBLQGL-UHFFFAOYSA-N",
      "Smiles": "CC#Cc1cc2c3cc(C#CC)c4c5c3c(c3c2c2c1cccc2c(c3)C#CC)cc(c5ccc4)C#CC",
      "MolView_url": "http://molview.org/?q=CC#Cc1cc2c3cc(C#CC)c4c5c3c(c3c2c2c1cccc2c(c3)C#CC)cc(c5ccc4)C#CC",
      "HOMO": "-5.42",
      "LUMO": "-2.68",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "1.96",
      "E00T1": "1.12",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "-1.04",
      "ES1red": "",
      "ET1ox": "-0.2",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.87",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AIWCQGOUCCJXMV-UHFFFAOYSA-N",
      "Smiles": "c1coc(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccco1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccco1",
      "HOMO": "-5.32",
      "LUMO": "-2.5",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "2.15",
      "E00T1": "1.16",
      "Eox": "0.56",
      "Ered": "",
      "ES1ox": "-1.59",
      "ES1red": "",
      "ET1ox": "-0.6",
      "ET1red": "",
      "DipoleMomentS0": "1.72",
      "DipoleMomentS1": "1.72",
      "DipoleMomentT1": "2.16",
      "ionizationPotential": "6.07",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "AJBIYLGVQLXABL-DQSJHHFOSA-N",
      "Smiles": "N#Cc1ccc(nc1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(cn1)C#N",
      "HOMO": "-5.88",
      "LUMO": "-3",
      "VEE": "2.51",
      "Lmax": "493.94422310757",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.67",
      "DipoleMomentS1": "7.67",
      "DipoleMomentT1": "6.78",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "AJLHDUGFRKCPSZ-UHFFFAOYSA-N",
      "Smiles": "Fc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)F",
      "HOMO": "-5.55",
      "LUMO": "-2.73",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.91",
      "E00T1": "1.16",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "-1.18",
      "ES1red": "",
      "ET1ox": "-0.43",
      "ET1red": "",
      "DipoleMomentS0": "6.39",
      "DipoleMomentS1": "6.39",
      "DipoleMomentT1": "7.32",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "AJNJHNSKQXZTRS-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(nc1)c1cc2ccc3c4c2c2c1ccc1c2c4c(cc1c1ncc(cn1)F)cc3",
      "MolView_url": "http://molview.org/?q=Fc1cnc(nc1)c1cc2ccc3c4c2c2c1ccc1c2c4c(cc1c1ncc(cn1)F)cc3",
      "HOMO": "-6.63",
      "LUMO": "-2.27",
      "VEE": "3.48",
      "Lmax": "356.264367816092",
      "E00S1": "2.96",
      "E00T1": "2.35",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.94",
      "DipoleMomentS1": "2.94",
      "DipoleMomentT1": "2.5",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/dbf_1"
    },
    {
      "InchiKey": "AJYDRTUPDXCAEO-UHFFFAOYSA-N",
      "Smiles": "Cn1c(O)ccc1[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Cn1c(O)ccc1[C@]1(O)N=N1",
      "HOMO": "-5.93",
      "LUMO": "-2.13",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "2.47",
      "E00T1": "1.58",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "-1.61",
      "ES1red": "",
      "ET1ox": "-0.72",
      "ET1red": "",
      "DipoleMomentS0": "5.12",
      "DipoleMomentS1": "5.12",
      "DipoleMomentT1": "10.77",
      "ionizationPotential": "7.13",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "AKDFNBRPQZVILS-UHFFFAOYSA-N",
      "Smiles": "Cc1coc(n1)c1cc2c3cc(c4occ(n4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)C)cc(c5ccc4)c1occ(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1coc(n1)c1cc2c3cc(c4occ(n4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)C)cc(c5ccc4)c1occ(n1)C",
      "HOMO": "-5.57",
      "LUMO": "-2.88",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "1.86",
      "E00T1": "0.22",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "-1.17",
      "ES1red": "",
      "ET1ox": "0.47",
      "ET1red": "",
      "DipoleMomentS0": "2.55",
      "DipoleMomentS1": "2.55",
      "DipoleMomentT1": "2.61",
      "ionizationPotential": "6.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AKDXABHEOJIAMU-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1[nH]cc(c1)C#N)C#N)c1[nH]cc(c1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1[nH]cc(c1)C#N)C#N)c1[nH]cc(c1)C#N",
      "HOMO": "-6.8",
      "LUMO": "-2.71",
      "VEE": "3.27",
      "Lmax": "379.14373088685",
      "E00S1": "",
      "E00T1": "2.25",
      "Eox": "1.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.39",
      "ET1red": "",
      "DipoleMomentS0": "8.1",
      "DipoleMomentS1": "8.1",
      "DipoleMomentT1": "3.21",
      "ionizationPotential": "8.27",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "AKGHZKPYFCNCFX-UHFFFAOYSA-N",
      "Smiles": "Cc1ncc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cnc([nH]1)C)c1cnc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ncc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cnc([nH]1)C)c1cnc([nH]1)C",
      "HOMO": "-5.46",
      "LUMO": "-2.38",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "1.57",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.99",
      "ET1red": "",
      "DipoleMomentS0": "9.39",
      "DipoleMomentS1": "9.39",
      "DipoleMomentT1": "10.62",
      "ionizationPotential": "6.13",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "AKLALPFICIUKQQ-UHFFFAOYSA-N",
      "Smiles": "Clc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)Cl)cc(c5ccc4)c1cnc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)Cl)cc(c5ccc4)c1cnc([nH]1)Cl",
      "HOMO": "-5.55",
      "LUMO": "-2.69",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "2",
      "E00T1": "1.17",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "-1.29",
      "ES1red": "",
      "ET1ox": "-0.46",
      "ET1red": "",
      "DipoleMomentS0": "8.19",
      "DipoleMomentS1": "8.19",
      "DipoleMomentT1": "7.47",
      "ionizationPotential": "6.52",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AKWQYOVDKSJCTF-DPKLHXOTSA-N",
      "Smiles": "S/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N/S",
      "MolView_url": "http://molview.org/?q=S/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N/S",
      "HOMO": "-6.27",
      "LUMO": "-2.66",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "0.52",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.8",
      "ET1red": "",
      "DipoleMomentS0": "3.58",
      "DipoleMomentS1": "3.58",
      "DipoleMomentT1": "2.9",
      "ionizationPotential": "7.04",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "AKWQYOVDKSJCTF-SXZOPZGESA-N",
      "Smiles": "S/N=C/c1ccc(cc1)/N=N/c1ccc(cc1)/C=N/S",
      "MolView_url": "http://molview.org/?q=S/N=C/c1ccc(cc1)/N=N/c1ccc(cc1)/C=N/S",
      "HOMO": "-6.34",
      "LUMO": "-2.98",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.62",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.21",
      "ionizationPotential": "7.21",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "AKZNAIBPMTTYEK-UHFFFAOYSA-N",
      "Smiles": "FC(c1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)C(F)(F)F)(F)F",
      "HOMO": "-5.59",
      "LUMO": "-2.74",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.95",
      "E00T1": "",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.12",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.57",
      "DipoleMomentS1": "10.57",
      "DipoleMomentT1": "10.71",
      "ionizationPotential": "6.5",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "ALJCEEPJJHTTDR-ZXXQZIGBSA-N",
      "Smiles": "N#C/C=C\\c1ccc([nH]1)/N=N\\c1ccc([nH]1)/C=C/C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\c1ccc([nH]1)/N=N\\c1ccc([nH]1)/C=C/C#N",
      "HOMO": "-6.03",
      "LUMO": "-3.14",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "1.26",
      "E00T1": "0.01",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "-0.68",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "10.62",
      "DipoleMomentS1": "10.62",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.97",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "ALLBCJWJXOQIAT-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(o1)Cl)C#N)c1ncc(o1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(o1)Cl)C#N)c1ncc(o1)Cl",
      "HOMO": "-6.85",
      "LUMO": "-3.15",
      "VEE": "3.21",
      "Lmax": "386.230529595016",
      "E00S1": "",
      "E00T1": "2.12",
      "Eox": "-0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.9",
      "ET1red": "",
      "DipoleMomentS0": "1.5",
      "DipoleMomentS1": "1.5",
      "DipoleMomentT1": "5.85",
      "ionizationPotential": "8.31",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ALOYCUJRSMCALL-DQSJHHFOSA-N",
      "Smiles": "N#Cc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)C#N",
      "HOMO": "-6.23",
      "LUMO": "-2.54",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.94",
      "DipoleMomentS1": "5.94",
      "DipoleMomentT1": "4.26",
      "ionizationPotential": "7.13",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "ALQCBSRCXHTTHM-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)S)c1occ(n1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)S)c1occ(n1)S",
      "HOMO": "-6.5",
      "LUMO": "-3.31",
      "VEE": "2.12",
      "Lmax": "584.811320754717",
      "E00S1": "",
      "E00T1": "0.04",
      "Eox": "1.87",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.83",
      "ET1red": "",
      "DipoleMomentS0": "1.21",
      "DipoleMomentS1": "1.21",
      "DipoleMomentT1": "0.99",
      "ionizationPotential": "7.51",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "AMAZYTRMFZCCHA-UHFFFAOYSA-N",
      "Smiles": "Oc1csc(n1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1csc(n1)[C@]1(O)N=N1",
      "HOMO": "-6.69",
      "LUMO": "-2.41",
      "VEE": "3.2",
      "Lmax": "387.4375",
      "E00S1": "2.87",
      "E00T1": "2.12",
      "Eox": "1.73",
      "Ered": "",
      "ES1ox": "-1.14",
      "ES1red": "",
      "ET1ox": "-0.39",
      "ET1red": "",
      "DipoleMomentS0": "2.99",
      "DipoleMomentS1": "2.99",
      "DipoleMomentT1": "6.11",
      "ionizationPotential": "8.14",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "AMDDGRBWNRIHLU-DQSJHHFOSA-N",
      "Smiles": "Clc1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(cn1)Cl",
      "HOMO": "-6.53",
      "LUMO": "-2.67",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.89",
      "DipoleMomentS1": "4.89",
      "DipoleMomentT1": "3.43",
      "ionizationPotential": "7.14",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "ANEZBUYLPICMKQ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cc1)O)C#N)c1ccc(cc1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cc1)O)C#N)c1ccc(cc1)O",
      "HOMO": "-6.63",
      "LUMO": "-2.45",
      "VEE": "3.34",
      "Lmax": "371.197604790419",
      "E00S1": "",
      "E00T1": "2.55",
      "Eox": "1.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.78",
      "ET1red": "",
      "DipoleMomentS0": "4.83",
      "DipoleMomentS1": "4.83",
      "DipoleMomentT1": "9.26",
      "ionizationPotential": "7.77",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ANZMAKQNEXXESU-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cc1)C(F)(F)F)C#N)c1ccc(cc1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cc1)C(F)(F)F)C#N)c1ccc(cc1)C(F)(F)F",
      "HOMO": "-7.28",
      "LUMO": "-2.96",
      "VEE": "4.05",
      "Lmax": "306.123456790123",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.27",
      "DipoleMomentS1": "2.27",
      "DipoleMomentT1": "0.72",
      "ionizationPotential": "8.89",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "AONCYTBQCGKYAJ-UHFFFAOYSA-N",
      "Smiles": "c1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]ccc1",
      "MolView_url": "http://molview.org/?q=c1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]ccc1",
      "HOMO": "-5.25",
      "LUMO": "-2.39",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.2",
      "E00T1": "",
      "Eox": "0.44",
      "Ered": "",
      "ES1ox": "-1.76",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.71",
      "DipoleMomentS1": "0.71",
      "DipoleMomentT1": "2",
      "ionizationPotential": "5.99",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "AOYNWEXBKOHTIY-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)S)c1[nH]cc(c1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)S)c1[nH]cc(c1)S",
      "HOMO": "-6.22",
      "LUMO": "-3.11",
      "VEE": "2.13",
      "Lmax": "582.06572769953",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.26",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.55",
      "DipoleMomentS1": "4.55",
      "DipoleMomentT1": "7.85",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "APOLHSNYGCCJHV-SQKWQXNLSA-N",
      "Smiles": "S/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C\\S)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C/S)cc(c5ccc4)/C=C\\O/C=C\\S",
      "MolView_url": "http://molview.org/?q=S/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C\\S)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C/S)cc(c5ccc4)/C=C\\O/C=C\\S",
      "HOMO": "-5.38",
      "LUMO": "-2.51",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "2.02",
      "E00T1": "0.24",
      "Eox": "-4.4",
      "Ered": "",
      "ES1ox": "-6.42",
      "ES1red": "",
      "ET1ox": "-4.64",
      "ET1red": "",
      "DipoleMomentS0": "3.3",
      "DipoleMomentS1": "3.3",
      "DipoleMomentT1": "2.69",
      "ionizationPotential": "0.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "APYUXZNVMFPUSK-UHFFFAOYSA-N",
      "Smiles": "Sc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)S",
      "HOMO": "-5.54",
      "LUMO": "-2.76",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.78",
      "DipoleMomentS1": "4.78",
      "DipoleMomentT1": "4.38",
      "ionizationPotential": "6.29",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "AQEFRWAKOPHWLU-UHFFFAOYSA-N",
      "Smiles": "Fc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)F)cc(c5ccc4)c1nnc(nn1)F",
      "MolView_url": "http://molview.org/?q=Fc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)F)cc(c5ccc4)c1nnc(nn1)F",
      "HOMO": "-5.97",
      "LUMO": "-3.55",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "1.38",
      "E00T1": "1.1",
      "Eox": "1.17",
      "Ered": "",
      "ES1ox": "-0.21",
      "ES1red": "",
      "ET1ox": "0.07",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AQOQZWYINUJKPQ-HZUVFDMRSA-N",
      "Smiles": "O/N=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/O",
      "MolView_url": "http://molview.org/?q=O/N=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/O",
      "HOMO": "-5.7",
      "LUMO": "-2.67",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.16",
      "E00T1": "1.26",
      "Eox": "0.88",
      "Ered": "",
      "ES1ox": "-1.28",
      "ES1red": "",
      "ET1ox": "-0.38",
      "ET1red": "",
      "DipoleMomentS0": "9.52",
      "DipoleMomentS1": "9.52",
      "DipoleMomentT1": "10.16",
      "ionizationPotential": "6.81",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "AQYFGWINIJJWBQ-KTKRTIGZSA-N",
      "Smiles": "c1csc(c1)/N=N\\c1cccs1",
      "MolView_url": "http://molview.org/?q=c1csc(c1)/N=N\\c1cccs1",
      "HOMO": "-6.24",
      "LUMO": "-2.74",
      "VEE": "2.51",
      "Lmax": "493.94422310757",
      "E00S1": "",
      "E00T1": "0.35",
      "Eox": "1.1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.75",
      "ET1red": "",
      "DipoleMomentS0": "5.92",
      "DipoleMomentS1": "5.92",
      "DipoleMomentT1": "4.67",
      "ionizationPotential": "7.17",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "AQYFGWINIJJWBQ-MDZDMXLPSA-N",
      "Smiles": "c1csc(c1)/N=N/c1cccs1",
      "MolView_url": "http://molview.org/?q=c1csc(c1)/N=N/c1cccs1",
      "HOMO": "-6.34",
      "LUMO": "-2.78",
      "VEE": "3.15",
      "Lmax": "393.587301587302",
      "E00S1": "",
      "E00T1": "1.1",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.22",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.36",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ARAMHWZMISYVIN-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)Cl",
      "HOMO": "-5.42",
      "LUMO": "-2.42",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.61",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.86",
      "DipoleMomentS1": "3.86",
      "DipoleMomentT1": "3.84",
      "ionizationPotential": "6.27",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "ARGXWJAMWQYJGG-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(s1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(s1)[C@]1(C#N)N=N1",
      "HOMO": "-7.44",
      "LUMO": "-3",
      "VEE": "3.77",
      "Lmax": "328.859416445623",
      "E00S1": "2.97",
      "E00T1": "2.37",
      "Eox": "2.5",
      "Ered": "",
      "ES1ox": "-0.47",
      "ES1red": "",
      "ET1ox": "0.13",
      "ET1red": "",
      "DipoleMomentS0": "5.69",
      "DipoleMomentS1": "5.69",
      "DipoleMomentT1": "6.15",
      "ionizationPotential": "9.12",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "AROPSCIYRDYONR-BYYHNAKLSA-N",
      "Smiles": "FC(C1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)C(F)(F)F)(F)F",
      "HOMO": "-5.66",
      "LUMO": "-3.06",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "1.93",
      "E00T1": "0.63",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.21",
      "ES1red": "",
      "ET1ox": "0.09",
      "ET1red": "",
      "DipoleMomentS0": "3.86",
      "DipoleMomentS1": "3.86",
      "DipoleMomentT1": "3.97",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ARQIAMJICFNIHJ-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)[N+](=O)[O-]",
      "HOMO": "-6.33",
      "LUMO": "-3.65",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "0.74",
      "Eox": "1.45",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.71",
      "ET1red": "",
      "DipoleMomentS0": "5.89",
      "DipoleMomentS1": "5.89",
      "DipoleMomentT1": "7.78",
      "ionizationPotential": "7.37",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ASLWQARYCMGXGV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1scc(n1)C)C#N)c1scc(n1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1scc(n1)C)C#N)c1scc(n1)C",
      "HOMO": "-6.66",
      "LUMO": "-3.12",
      "VEE": "3.45",
      "Lmax": "359.36231884058",
      "E00S1": "",
      "E00T1": "2.34",
      "Eox": "-0.8",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.14",
      "ET1red": "",
      "DipoleMomentS0": "4.95",
      "DipoleMomentS1": "4.95",
      "DipoleMomentT1": "6.47",
      "ionizationPotential": "7.94",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ASOFOXKPUPKKSS-WCWDXBQESA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)C#N)C#N",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)C#N)C#N",
      "HOMO": "-6.01",
      "LUMO": "-3.24",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "2.08",
      "E00T1": "0.7",
      "Eox": "1.01",
      "Ered": "",
      "ES1ox": "-1.07",
      "ES1red": "",
      "ET1ox": "0.31",
      "ET1red": "",
      "DipoleMomentS0": "16.05",
      "DipoleMomentS1": "16.05",
      "DipoleMomentT1": "16.42",
      "ionizationPotential": "6.94",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ASXQSPLVGNGJLQ-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)S)c1ccc([nH]1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)S)c1ccc([nH]1)S",
      "HOMO": "-6.2",
      "LUMO": "-3.08",
      "VEE": "2.1",
      "Lmax": "590.380952380952",
      "E00S1": "1.93",
      "E00T1": "1.66",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "-0.8",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "3.19",
      "DipoleMomentS1": "3.19",
      "DipoleMomentT1": "5.56",
      "ionizationPotential": "6.83",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ATAVCYXBEHHGRC-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)C)cc(c5ccc4)c1ccc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)C)cc(c5ccc4)c1ccc([nH]1)C",
      "HOMO": "-5.07",
      "LUMO": "-2.39",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "1.85",
      "E00T1": "1.1",
      "Eox": "0.26",
      "Ered": "",
      "ES1ox": "-1.59",
      "ES1red": "",
      "ET1ox": "-0.84",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.58",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ATHGLAZLDUTELK-REGPOSIJSA-N",
      "Smiles": "O/N=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=N\\O)ccc2-c([n+]31)c4)Cl)/C=N\\O",
      "MolView_url": "http://molview.org/?q=O/N=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=N\\O)ccc2-c([n+]31)c4)Cl)/C=N\\O",
      "HOMO": "-5.75",
      "LUMO": "-2.61",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.34",
      "DipoleMomentS1": "8.34",
      "DipoleMomentT1": "7.62",
      "ionizationPotential": "6.65",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "ATTBOIYEJRPCAH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1nnc(nn1)[N+](=O)[O-])C#N)c1nnc(nn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1nnc(nn1)[N+](=O)[O-])C#N)c1nnc(nn1)[N+](=O)[O-]",
      "HOMO": "-8.05",
      "LUMO": "-4",
      "VEE": "2.12",
      "Lmax": "584.811320754717",
      "E00S1": "",
      "E00T1": "1.4",
      "Eox": "3.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.63",
      "ET1red": "",
      "DipoleMomentS0": "5.23",
      "DipoleMomentS1": "5.23",
      "DipoleMomentT1": "5.24",
      "ionizationPotential": "9.53",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ATYJRVIPJARYAE-QURGRASLSA-N",
      "Smiles": "Fc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)F",
      "HOMO": "-5.28",
      "LUMO": "-2.71",
      "VEE": "2.6",
      "Lmax": "476.846153846154",
      "E00S1": "1.92",
      "E00T1": "0.7",
      "Eox": "0.37",
      "Ered": "",
      "ES1ox": "-1.55",
      "ES1red": "",
      "ET1ox": "-0.33",
      "ET1red": "",
      "DipoleMomentS0": "3.09",
      "DipoleMomentS1": "3.09",
      "DipoleMomentT1": "1.34",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "AUHXASBOJKVKEA-UHFFFAOYSA-N",
      "Smiles": "ClC1=CC=C(C1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(C1)[C@]1(Cl)N=N1",
      "HOMO": "-6.5",
      "LUMO": "-2.49",
      "VEE": "3.31",
      "Lmax": "374.561933534743",
      "E00S1": "2.56",
      "E00T1": "1.93",
      "Eox": "1.57",
      "Ered": "",
      "ES1ox": "-0.99",
      "ES1red": "",
      "ET1ox": "-0.36",
      "ET1red": "",
      "DipoleMomentS0": "1.25",
      "DipoleMomentS1": "1.25",
      "DipoleMomentT1": "9.61",
      "ionizationPotential": "8",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "AUMICKUKXCMVCU-DQRAZIAOSA-N",
      "Smiles": "ClC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)Cl",
      "HOMO": "-5.51",
      "LUMO": "-3",
      "VEE": "2.31",
      "Lmax": "536.709956709957",
      "E00S1": "",
      "E00T1": "-0.04",
      "Eox": "0.48",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.52",
      "ET1red": "",
      "DipoleMomentS0": "5.33",
      "DipoleMomentS1": "5.33",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.21",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "AUMICKUKXCMVCU-QURGRASLSA-N",
      "Smiles": "ClC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)Cl",
      "HOMO": "-5.46",
      "LUMO": "-3.02",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "0.53",
      "Eox": "0.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.01",
      "ET1red": "",
      "DipoleMomentS0": "1.2",
      "DipoleMomentS1": "1.2",
      "DipoleMomentT1": "0.82",
      "ionizationPotential": "6.2",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "AUWBMNGVHBNSHJ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(s1)OC(F)(F)F)C#N)c1ncc(s1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(s1)OC(F)(F)F)C#N)c1ncc(s1)OC(F)(F)F",
      "HOMO": "-7.01",
      "LUMO": "-3.3",
      "VEE": "3.71",
      "Lmax": "334.177897574124",
      "E00S1": "",
      "E00T1": "2.46",
      "Eox": "-0.72",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.18",
      "ET1red": "",
      "DipoleMomentS0": "2.18",
      "DipoleMomentS1": "2.18",
      "DipoleMomentT1": "2.95",
      "ionizationPotential": "8.29",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "AVDSDWZAIPGUGX-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc([nH]1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc([nH]1)OC(F)(F)F)(F)F",
      "HOMO": "-6.29",
      "LUMO": "-3.11",
      "VEE": "2.2",
      "Lmax": "563.545454545455",
      "E00S1": "2.12",
      "E00T1": "",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "-0.83",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.01",
      "DipoleMomentS1": "1.01",
      "DipoleMomentT1": "",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "AVHMRWXPUVPPPT-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C#Cc1cc2c3cc(C#C[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)C#C[N+](=O)[O-])cc(c5ccc4)C#C[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C#Cc1cc2c3cc(C#C[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)C#C[N+](=O)[O-])cc(c5ccc4)C#C[N+](=O)[O-]",
      "HOMO": "-6.29",
      "LUMO": "-4.08",
      "VEE": "2.56",
      "Lmax": "484.296875",
      "E00S1": "1.54",
      "E00T1": "",
      "Eox": "1.4",
      "Ered": "",
      "ES1ox": "-0.14",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "7.65",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "AVLJFDNELATYBM-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(s1)c1cc2ccc3c4c2c2c1ccc1c2c4c(cc1c1ccc(s1)S)cc3",
      "MolView_url": "http://molview.org/?q=Sc1ccc(s1)c1cc2ccc3c4c2c2c1ccc1c2c4c(cc1c1ccc(s1)S)cc3",
      "HOMO": "-5.91",
      "LUMO": "-2.12",
      "VEE": "3.13",
      "Lmax": "396.102236421725",
      "E00S1": "2.58",
      "E00T1": "2.16",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.21",
      "DipoleMomentS1": "3.21",
      "DipoleMomentT1": "8.43",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/dbf_1"
    },
    {
      "InchiKey": "AVRPZRBBZUJNAE-KVJLFNRQSA-N",
      "Smiles": "N#C/N=C/c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/C#N",
      "MolView_url": "http://molview.org/?q=N#C/N=C/c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/C#N",
      "HOMO": "-8.04",
      "LUMO": "-3.77",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "2.35",
      "Eox": "3.09",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.74",
      "ET1red": "",
      "DipoleMomentS0": "4.39",
      "DipoleMomentS1": "4.39",
      "DipoleMomentT1": "7.44",
      "ionizationPotential": "9.33",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "AWKDEEXFAVIGAF-ISLYRVAYSA-N",
      "Smiles": "C=Cc1ccc(cc1)/N=N/c1ccc(cc1)C=C",
      "MolView_url": "http://molview.org/?q=C=Cc1ccc(cc1)/N=N/c1ccc(cc1)C=C",
      "HOMO": "-6.33",
      "LUMO": "-2.74",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.52",
      "ET1red": "",
      "DipoleMomentS0": "0.1",
      "DipoleMomentS1": "0.1",
      "DipoleMomentT1": "3.52",
      "ionizationPotential": "7.22",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "AWKDEEXFAVIGAF-ZCXUNETKSA-N",
      "Smiles": "C=Cc1ccc(cc1)/N=N\\c1ccc(cc1)C=C",
      "MolView_url": "http://molview.org/?q=C=Cc1ccc(cc1)/N=N\\c1ccc(cc1)C=C",
      "HOMO": "-6.21",
      "LUMO": "-2.48",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "0.51",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.65",
      "ET1red": "",
      "DipoleMomentS0": "4.65",
      "DipoleMomentS1": "4.65",
      "DipoleMomentT1": "3.58",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "AWZZBLPXDOHIMP-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(nc1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(nc1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-8.31",
      "LUMO": "-2.88",
      "VEE": "3.79",
      "Lmax": "327.12401055409",
      "E00S1": "3.75",
      "E00T1": "3.12",
      "Eox": "3.59",
      "Ered": "",
      "ES1ox": "-0.16",
      "ES1red": "",
      "ET1ox": "0.47",
      "ET1red": "",
      "DipoleMomentS0": "2.18",
      "DipoleMomentS1": "2.18",
      "DipoleMomentT1": "5.16",
      "ionizationPotential": "10.06",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "AXKLERBIHDLHAI-UHFFFAOYSA-N",
      "Smiles": "OC1(O)N=N1",
      "MolView_url": "http://molview.org/?q=OC1(O)N=N1",
      "HOMO": "-7.77",
      "LUMO": "-2.32",
      "VEE": "3.23",
      "Lmax": "383.839009287926",
      "E00S1": "3.15",
      "E00T1": "",
      "Eox": "2.55",
      "Ered": "",
      "ES1ox": "-0.6",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.69",
      "DipoleMomentS1": "3.69",
      "DipoleMomentT1": "7.12",
      "ionizationPotential": "9.44",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "AXQSIRAKZPLURQ-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)C(F)(F)F)(F)F",
      "HOMO": "-5.49",
      "LUMO": "-2.64",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "1.98",
      "E00T1": "1.17",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "-1.3",
      "ES1red": "",
      "ET1ox": "-0.49",
      "ET1red": "",
      "DipoleMomentS0": "0.61",
      "DipoleMomentS1": "0.61",
      "DipoleMomentT1": "0.3",
      "ionizationPotential": "6.45",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "AXWUCDKPOILRRQ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(n1C)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(n1C)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C)C#N",
      "HOMO": "-6.62",
      "LUMO": "-3.23",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.65",
      "E00T1": "2.11",
      "Eox": "1.89",
      "Ered": "",
      "ES1ox": "-0.76",
      "ES1red": "",
      "ET1ox": "-0.22",
      "ET1red": "",
      "DipoleMomentS0": "7.52",
      "DipoleMomentS1": "7.52",
      "DipoleMomentT1": "1.75",
      "ionizationPotential": "7.66",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "AXZRRXTWVUZUPK-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)[N+](=O)[O-])c1ncc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)[N+](=O)[O-])c1ncc(s1)[N+](=O)[O-]",
      "HOMO": "-7.47",
      "LUMO": "-3.71",
      "VEE": "2.49",
      "Lmax": "497.911646586345",
      "E00S1": "2.74",
      "E00T1": "",
      "Eox": "2.46",
      "Ered": "",
      "ES1ox": "-0.28",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.35",
      "DipoleMomentS1": "6.35",
      "DipoleMomentT1": "4.33",
      "ionizationPotential": "8.69",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "AYCGNWNXOHZMGY-DQRAZIAOSA-N",
      "Smiles": "c1ccc([nH]1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc[nH]1",
      "MolView_url": "http://molview.org/?q=c1ccc([nH]1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc[nH]1",
      "HOMO": "-5.18",
      "LUMO": "-2.34",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "0.31",
      "Eox": "0.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.1",
      "ET1red": "",
      "DipoleMomentS0": "8.57",
      "DipoleMomentS1": "8.57",
      "DipoleMomentT1": "5.87",
      "ionizationPotential": "5.85",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "AYECXPYIIQPIDX-FMQUCBEESA-N",
      "Smiles": "Sc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)S",
      "HOMO": "-5.49",
      "LUMO": "-2.91",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "",
      "E00T1": "0.71",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.05",
      "ET1red": "",
      "DipoleMomentS0": "0.01",
      "DipoleMomentS1": "0.01",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.24",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "AYECXPYIIQPIDX-VXPUYCOJSA-N",
      "Smiles": "Sc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)S",
      "HOMO": "-5.6",
      "LUMO": "-2.93",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.15",
      "Eox": "0.57",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.42",
      "ET1red": "",
      "DipoleMomentS0": "10.19",
      "DipoleMomentS1": "10.19",
      "DipoleMomentT1": "7.76",
      "ionizationPotential": "6.32",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "AZBSMPUUXDDOLI-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccco1)c1ccco1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccco1)c1ccco1",
      "HOMO": "-6.4",
      "LUMO": "-3.11",
      "VEE": "2.16",
      "Lmax": "573.981481481481",
      "E00S1": "2.53",
      "E00T1": "1.99",
      "Eox": "1.73",
      "Ered": "",
      "ES1ox": "-0.8",
      "ES1red": "",
      "ET1ox": "-0.26",
      "ET1red": "",
      "DipoleMomentS0": "3.21",
      "DipoleMomentS1": "3.21",
      "DipoleMomentT1": "6.97",
      "ionizationPotential": "7.4",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "AZBXYFYSDQYJMH-UHFFFAOYSA-N",
      "Smiles": "Fc1csc(n1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1csc(n1)[C@]1(F)N=N1",
      "HOMO": "-7.39",
      "LUMO": "-2.8",
      "VEE": "3.18",
      "Lmax": "389.874213836478",
      "E00S1": "3.02",
      "E00T1": "2.41",
      "Eox": "2.44",
      "Ered": "",
      "ES1ox": "-0.58",
      "ES1red": "",
      "ET1ox": "0.03",
      "ET1red": "",
      "DipoleMomentS0": "4.19",
      "DipoleMomentS1": "4.19",
      "DipoleMomentT1": "9.81",
      "ionizationPotential": "9.06",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "AZFCEKURMUNYMW-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1[nH]cc(c1)OC)C#N)c1[nH]cc(c1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1[nH]cc(c1)OC)C#N)c1[nH]cc(c1)OC",
      "HOMO": "-5.69",
      "LUMO": "-2.77",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.58",
      "DipoleMomentS1": "3.58",
      "DipoleMomentT1": "7.84",
      "ionizationPotential": "7.07",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "AZQLWKPFVGCQSP-IESJRNOISA-N",
      "Smiles": "N#C/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N\\C#N",
      "MolView_url": "http://molview.org/?q=N#C/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N\\C#N",
      "HOMO": "-6.97",
      "LUMO": "-3.29",
      "VEE": "2.59",
      "Lmax": "478.687258687259",
      "E00S1": "",
      "E00T1": "0.53",
      "Eox": "1.8",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.27",
      "ET1red": "",
      "DipoleMomentS0": "11.13",
      "DipoleMomentS1": "11.13",
      "DipoleMomentT1": "8.78",
      "ionizationPotential": "8.09",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "AZQLWKPFVGCQSP-XPZLCZKUSA-N",
      "Smiles": "N#C/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N/C#N",
      "MolView_url": "http://molview.org/?q=N#C/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N/C#N",
      "HOMO": "-7.31",
      "LUMO": "-3.66",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "1.23",
      "Eox": "2.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.93",
      "ET1red": "",
      "DipoleMomentS0": "4.21",
      "DipoleMomentS1": "4.21",
      "DipoleMomentT1": "4.46",
      "ionizationPotential": "8.49",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "AZTKBMGRKFMQOS-OCEACIFDSA-N",
      "Smiles": "FC(Oc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)OC(F)(F)F)(F)F",
      "HOMO": "-6.28",
      "LUMO": "-3.39",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "",
      "E00T1": "0.83",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.44",
      "ET1red": "",
      "DipoleMomentS0": "6.74",
      "DipoleMomentS1": "6.74",
      "DipoleMomentT1": "6.65",
      "ionizationPotential": "7.12",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "AZTKBMGRKFMQOS-QPLCGJKRSA-N",
      "Smiles": "FC(Oc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)OC(F)(F)F)(F)F",
      "HOMO": "-6.23",
      "LUMO": "-3.28",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "-3.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-4.01",
      "ET1red": "",
      "DipoleMomentS0": "5.87",
      "DipoleMomentS1": "5.87",
      "DipoleMomentT1": "6.5",
      "ionizationPotential": "7.06",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "BBCYNIFNXFFIJX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cnc([nH]1)C)C#N)c1cnc([nH]1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cnc([nH]1)C)C#N)c1cnc([nH]1)C",
      "HOMO": "-6.26",
      "LUMO": "-2.83",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "",
      "E00T1": "2.02",
      "Eox": "-1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.06",
      "ET1red": "",
      "DipoleMomentS0": "6.88",
      "DipoleMomentS1": "6.88",
      "DipoleMomentT1": "10.97",
      "ionizationPotential": "7.58",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "BBXCFOJWLJKRRG-VHXPQNKSSA-N",
      "Smiles": "Clc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)Cl",
      "HOMO": "-6.23",
      "LUMO": "-3.19",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.93",
      "ET1red": "",
      "DipoleMomentS0": "5.84",
      "DipoleMomentS1": "5.84",
      "DipoleMomentT1": "4.83",
      "ionizationPotential": "6.87",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "BBXCFOJWLJKRRG-WCWDXBQESA-N",
      "Smiles": "Clc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)Cl",
      "HOMO": "-6.31",
      "LUMO": "-3.32",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "0.89",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.43",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "BCAIIJAFDBXREC-QPLCGJKRSA-N",
      "Smiles": "Sc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)S",
      "HOMO": "-6.14",
      "LUMO": "-2.5",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9",
      "DipoleMomentS1": "9",
      "DipoleMomentT1": "7.56",
      "ionizationPotential": "6.75",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "BDEQXSJYGBXUJO-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)[N+](=O)[O-]",
      "HOMO": "-6.2",
      "LUMO": "-3.66",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "1.91",
      "E00T1": "0.72",
      "Eox": "1.34",
      "Ered": "",
      "ES1ox": "-0.57",
      "ES1red": "",
      "ET1ox": "0.62",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "7.25",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BDEYIGWYOMXLBM-UHFFFAOYSA-N",
      "Smiles": "FC(OC1=CC=C(S1(=O)=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(S1(=O)=O)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(S1(=O)=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(S1(=O)=O)OC(F)(F)F)(F)F",
      "HOMO": "-7.12",
      "LUMO": "-3.45",
      "VEE": "1.75",
      "Lmax": "708.457142857143",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.23",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "11.73",
      "DipoleMomentS1": "11.73",
      "DipoleMomentT1": "11.96",
      "ionizationPotential": "8.14",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "BDYLORXWYMEOLC-MWQXNLTMSA-N",
      "Smiles": "FC(/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N/C(F)(F)F)(F)F",
      "HOMO": "-6.8",
      "LUMO": "-3.7",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "1.83",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.07",
      "ET1red": "",
      "DipoleMomentS0": "10.63",
      "DipoleMomentS1": "10.63",
      "DipoleMomentT1": "9.66",
      "ionizationPotential": "7.97",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BDZZBEZJWZHGIU-ISLYRVAYSA-N",
      "Smiles": "Sc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)S",
      "HOMO": "-6.03",
      "LUMO": "-3.27",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.94",
      "DipoleMomentS1": "3.94",
      "DipoleMomentT1": "3.52",
      "ionizationPotential": "6.8",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "BDZZBEZJWZHGIU-ZCXUNETKSA-N",
      "Smiles": "Sc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)S",
      "HOMO": "-6.12",
      "LUMO": "-3.22",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "1.06",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.86",
      "ET1red": "",
      "DipoleMomentS0": "4.52",
      "DipoleMomentS1": "4.52",
      "DipoleMomentT1": "3.78",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "BENYSEOCGASLPP-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)C#N",
      "HOMO": "-5.63",
      "LUMO": "-2.92",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.86",
      "E00T1": "",
      "Eox": "0.8",
      "Ered": "",
      "ES1ox": "-1.06",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.38",
      "DipoleMomentS1": "7.38",
      "DipoleMomentT1": "9.22",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "BESIOADPVLCNLJ-UHFFFAOYSA-N",
      "Smiles": "c1ccc(nc1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=c1ccc(nc1)[C@@H]1N=N1",
      "HOMO": "-7.3",
      "LUMO": "-2.26",
      "VEE": "3.34",
      "Lmax": "371.197604790419",
      "E00S1": "3.24",
      "E00T1": "2.64",
      "Eox": "2.38",
      "Ered": "",
      "ES1ox": "-0.86",
      "ES1red": "",
      "ET1ox": "-0.26",
      "ET1red": "",
      "DipoleMomentS0": "3.09",
      "DipoleMomentS1": "3.09",
      "DipoleMomentT1": "7.3",
      "ionizationPotential": "8.83",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "BEXLDUTWEBQKRT-UHFFFAOYSA-N",
      "Smiles": "N#Cc1coc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1occ(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1coc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1occ(n1)C#N",
      "HOMO": "-7.36",
      "LUMO": "-3.4",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "2.49",
      "Eox": "2.56",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.07",
      "ET1red": "",
      "DipoleMomentS0": "12.94",
      "DipoleMomentS1": "12.94",
      "DipoleMomentT1": "9.4",
      "ionizationPotential": "8.55",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "BFAPCIQLGNDZKX-OCEACIFDSA-N",
      "Smiles": "FC(C1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "HOMO": "-6.33",
      "LUMO": "-3.87",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.42",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.04",
      "ET1red": "",
      "DipoleMomentS0": "0.74",
      "DipoleMomentS1": "0.74",
      "DipoleMomentT1": "0.84",
      "ionizationPotential": "7.29",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BFXHBNJIWVYUCK-FLWNBWAVSA-N",
      "Smiles": "FC(Oc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)OC(F)(F)F)(F)F",
      "HOMO": "-6",
      "LUMO": "-3",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "0.73",
      "Eox": "0.88",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.15",
      "ET1red": "",
      "DipoleMomentS0": "4.3",
      "DipoleMomentS1": "4.3",
      "DipoleMomentT1": "3.3",
      "ionizationPotential": "6.79",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "BFXHBNJIWVYUCK-QVIHXGFCSA-N",
      "Smiles": "FC(Oc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)OC(F)(F)F)(F)F",
      "HOMO": "-6.04",
      "LUMO": "-3.04",
      "VEE": "3.16",
      "Lmax": "392.341772151899",
      "E00S1": "",
      "E00T1": "0.85",
      "Eox": "1.06",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.21",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.88",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "BGEFUZNAKBDKQS-QPLCGJKRSA-N",
      "Smiles": "N#Cc1ccc([nH]1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc([nH]1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc([nH]1)C#N",
      "HOMO": "-5.45",
      "LUMO": "-2.39",
      "VEE": "2.6",
      "Lmax": "476.846153846154",
      "E00S1": "",
      "E00T1": "0.35",
      "Eox": "1.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.89",
      "ET1red": "",
      "DipoleMomentS0": "3.46",
      "DipoleMomentS1": "3.46",
      "DipoleMomentT1": "3.09",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "BGXSTWHBPDARFO-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)Cl)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)Cl)c1occ(n1)Cl",
      "HOMO": "-7",
      "LUMO": "-3.36",
      "VEE": "2.26",
      "Lmax": "548.58407079646",
      "E00S1": "2.87",
      "E00T1": "2.31",
      "Eox": "2.34",
      "Ered": "",
      "ES1ox": "-0.53",
      "ES1red": "",
      "ET1ox": "0.03",
      "ET1red": "",
      "DipoleMomentS0": "3.63",
      "DipoleMomentS1": "3.63",
      "DipoleMomentT1": "7.97",
      "ionizationPotential": "8.02",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "BHBANTRZRBDFHW-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncccn1)c1ncccn1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncccn1)c1ncccn1",
      "HOMO": "-7.39",
      "LUMO": "-3.23",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.05",
      "DipoleMomentS1": "3.05",
      "DipoleMomentT1": "1.37",
      "ionizationPotential": "8.54",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "BHBUGJZRMCYZKK-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(o1)c1cc2c3cc(c4ccc(o4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)C#N)cc(c5ccc4)c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)c1cc2c3cc(c4ccc(o4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)C#N)cc(c5ccc4)c1ccc(o1)C#N",
      "HOMO": "-5.64",
      "LUMO": "-2.99",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "1.83",
      "E00T1": "1.07",
      "Eox": "-4.27",
      "Ered": "",
      "ES1ox": "-6.1",
      "ES1red": "",
      "ET1ox": "-5.34",
      "ET1red": "",
      "DipoleMomentS0": "10.8",
      "DipoleMomentS1": "10.8",
      "DipoleMomentT1": "10.87",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "BHKKWVYOZWDYID-VHXPQNKSSA-N",
      "Smiles": "[O-][N+](=O)c1cc([nH]c1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1[nH]cc(c1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cc([nH]c1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1[nH]cc(c1)[N+](=O)[O-]",
      "HOMO": "-6.22",
      "LUMO": "-2.65",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.19",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "11.56",
      "DipoleMomentS1": "11.56",
      "DipoleMomentT1": "10.03",
      "ionizationPotential": "7.2",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "BHLMACSDDHWUBI-NURZGURASA-N",
      "Smiles": "F/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C/F",
      "MolView_url": "http://molview.org/?q=F/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C/F",
      "HOMO": "-5.62",
      "LUMO": "-2.84",
      "VEE": "1.45",
      "Lmax": "855.034482758621",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.59",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.91",
      "DipoleMomentS1": "8.91",
      "DipoleMomentT1": "7.98",
      "ionizationPotential": "6.18",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "BHSLUBYNQGBIIN-UHFFFAOYSA-N",
      "Smiles": "Cn1c(S)ccc1[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Cn1c(S)ccc1[C@]1(S)N=N1",
      "HOMO": "-6.4",
      "LUMO": "-2.34",
      "VEE": "3.33",
      "Lmax": "372.312312312312",
      "E00S1": "2.77",
      "E00T1": "1.94",
      "Eox": "1.14",
      "Ered": "",
      "ES1ox": "-1.63",
      "ES1red": "",
      "ET1ox": "-0.8",
      "ET1red": "",
      "DipoleMomentS0": "3.2",
      "DipoleMomentS1": "3.2",
      "DipoleMomentT1": "13.69",
      "ionizationPotential": "7.43",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "BHXFKXOIODIUJO-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(cc1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(cc1)C#N",
      "HOMO": "-8.1",
      "LUMO": "-2.42",
      "VEE": "4.74",
      "Lmax": "261.561181434599",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.19",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "9.81",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "BIARINBFOMALTI-FMQUCBEESA-N",
      "Smiles": "Clc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)Cl",
      "HOMO": "-6.14",
      "LUMO": "-3.31",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.67",
      "DipoleMomentS1": "1.67",
      "DipoleMomentT1": "1.84",
      "ionizationPotential": "6.92",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "BIARINBFOMALTI-VXPUYCOJSA-N",
      "Smiles": "Clc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)Cl",
      "HOMO": "-6.11",
      "LUMO": "-3.21",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.42",
      "DipoleMomentS1": "6.42",
      "DipoleMomentT1": "5.46",
      "ionizationPotential": "6.88",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "BICVEIWRKBPNNS-UHFFFAOYSA-N",
      "Smiles": "N#CC#Cc1cc(C#N)cc(c1C#N)C#CC#N",
      "MolView_url": "http://molview.org/?q=N#CC#Cc1cc(C#N)cc(c1C#N)C#CC#N",
      "HOMO": "-8.12",
      "LUMO": "-3.34",
      "VEE": "3.88",
      "Lmax": "319.536082474227",
      "E00S1": "",
      "E00T1": "3.05",
      "Eox": "3.23",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.18",
      "ET1red": "",
      "DipoleMomentS0": "5.84",
      "DipoleMomentS1": "5.84",
      "DipoleMomentT1": "6.05",
      "ionizationPotential": "9.84",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "BIJXVFVNOXNEHK-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C=O)[N+](=O)[O-])C#N)c1ccc(n1C=O)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C=O)[N+](=O)[O-])C#N)c1ccc(n1C=O)[N+](=O)[O-]",
      "HOMO": "-7.62",
      "LUMO": "-3.38",
      "VEE": "3.6",
      "Lmax": "344.388888888889",
      "E00S1": "",
      "E00T1": "1.97",
      "Eox": "2.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "0.53",
      "DipoleMomentS1": "0.53",
      "DipoleMomentT1": "5.11",
      "ionizationPotential": "8.99",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "BINABOFLFPOZCD-UHFFFAOYSA-N",
      "Smiles": "Cn1c(Cl)ccc1c1cc2c3cc(c4ccc(n4C)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)Cl)cc(c5ccc4)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=Cn1c(Cl)ccc1c1cc2c3cc(c4ccc(n4C)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)Cl)cc(c5ccc4)c1ccc(n1C)Cl",
      "HOMO": "-5.42",
      "LUMO": "-2.55",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "2.01",
      "E00T1": "1.2",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.67",
      "DipoleMomentS1": "2.67",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.16",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "BISKDGONOUMNHM-FMQUCBEESA-N",
      "Smiles": "Fc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)F",
      "HOMO": "-5.57",
      "LUMO": "-2.86",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "2.02",
      "E00T1": "0.74",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "-1.37",
      "ES1red": "",
      "ET1ox": "-0.09",
      "ET1red": "",
      "DipoleMomentS0": "2.81",
      "DipoleMomentS1": "2.81",
      "DipoleMomentT1": "2.74",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BIWUTHWQRWYTLG-VHXPQNKSSA-N",
      "Smiles": "ClC1=CC=C(S1(=O)=O)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(S1(=O)=O)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(S1(=O)=O)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(S1(=O)=O)Cl",
      "HOMO": "-6.48",
      "LUMO": "-3.16",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "0.44",
      "Eox": "1.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.98",
      "ET1red": "",
      "DipoleMomentS0": "13.65",
      "DipoleMomentS1": "13.65",
      "DipoleMomentT1": "13.26",
      "ionizationPotential": "7.27",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "BJARRLODZKZMHZ-VHXPQNKSSA-N",
      "Smiles": "[O-][N+](=O)c1ncc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1cnc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ncc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1cnc([nH]1)[N+](=O)[O-]",
      "HOMO": "-6.53",
      "LUMO": "-3.23",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "0.54",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.92",
      "ET1red": "",
      "DipoleMomentS0": "10.45",
      "DipoleMomentS1": "10.45",
      "DipoleMomentT1": "7.07",
      "ionizationPotential": "7.6",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "BJEZHFXVFAJIFG-VCFJNTAESA-N",
      "Smiles": "Cl/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/Cl",
      "MolView_url": "http://molview.org/?q=Cl/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/Cl",
      "HOMO": "-5.42",
      "LUMO": "-2.54",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.23",
      "E00T1": "1.16",
      "Eox": "0.59",
      "Ered": "",
      "ES1ox": "-1.64",
      "ES1red": "",
      "ET1ox": "-0.57",
      "ET1red": "",
      "DipoleMomentS0": "3.75",
      "DipoleMomentS1": "3.75",
      "DipoleMomentT1": "3.89",
      "ionizationPotential": "6.32",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "BJIMJHQUCFZBNI-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)Cl",
      "HOMO": "-5.28",
      "LUMO": "-2.43",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.97",
      "E00T1": "1.19",
      "Eox": "0.45",
      "Ered": "",
      "ES1ox": "-1.52",
      "ES1red": "",
      "ET1ox": "-0.74",
      "ET1red": "",
      "DipoleMomentS0": "3.36",
      "DipoleMomentS1": "3.36",
      "DipoleMomentT1": "2.51",
      "ionizationPotential": "6.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "BJNMHZQUZGMVFM-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)/N=C/c1cc2c3cc(/C=N/[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/[N+](=O)[O-])cc(c5ccc4)/C=N/[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/N=C/c1cc2c3cc(/C=N/[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/[N+](=O)[O-])cc(c5ccc4)/C=N/[N+](=O)[O-]",
      "HOMO": "-6.37",
      "LUMO": "-3.99",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "1.63",
      "E00T1": "0.96",
      "Eox": "1.53",
      "Ered": "",
      "ES1ox": "-0.1",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "5.26",
      "DipoleMomentS1": "5.26",
      "DipoleMomentT1": "6.03",
      "ionizationPotential": "7.67",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "BJWKVXNGCKMTDR-UHFFFAOYSA-N",
      "Smiles": "FC(c1nnc(nn1)c1cc2c3cc(c4nnc(nn4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)C(F)(F)F)cc(c5ccc4)c1nnc(nn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1nnc(nn1)c1cc2c3cc(c4nnc(nn4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)C(F)(F)F)cc(c5ccc4)c1nnc(nn1)C(F)(F)F)(F)F",
      "HOMO": "-6.7",
      "LUMO": "-4.02",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "1.47",
      "E00T1": "0.21",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.57",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "0.64",
      "ionizationPotential": "7.28",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "BKDVNRQEULOUNV-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ncc([nH]1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ncc([nH]1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.06",
      "LUMO": "-2.75",
      "VEE": "3.32",
      "Lmax": "373.433734939759",
      "E00S1": "2.75",
      "E00T1": "2.11",
      "Eox": "2.08",
      "Ered": "",
      "ES1ox": "-0.67",
      "ES1red": "",
      "ET1ox": "-0.03",
      "ET1red": "",
      "DipoleMomentS0": "2.84",
      "DipoleMomentS1": "2.84",
      "DipoleMomentT1": "7.24",
      "ionizationPotential": "8.54",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "BKEDHUNWAYXPGC-QPLCGJKRSA-N",
      "Smiles": "Clc1ccc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc([nH]1)Cl",
      "HOMO": "-5.76",
      "LUMO": "-2.42",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "0.5",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.34",
      "ET1red": "",
      "DipoleMomentS0": "2.91",
      "DipoleMomentS1": "2.91",
      "DipoleMomentT1": "3.15",
      "ionizationPotential": "6.55",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "BKHOJTLFEMDGBD-UHFFFAOYSA-N",
      "Smiles": "c1csc(c1)c1cc2c3cc(c4cccs4)c4c5c3c(c3c2c2c1cccc2c(c3)c1cccs1)cc(c5ccc4)c1cccs1",
      "MolView_url": "http://molview.org/?q=c1csc(c1)c1cc2c3cc(c4cccs4)c4c5c3c(c3c2c2c1cccc2c(c3)c1cccs1)cc(c5ccc4)c1cccs1",
      "HOMO": "-5.36",
      "LUMO": "-2.56",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.99",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "BKZHFOYOTLVQHE-WCWDXBQESA-N",
      "Smiles": "N#Cc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)C#N",
      "HOMO": "-5.72",
      "LUMO": "-3.01",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "2.02",
      "E00T1": "0.78",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "-1.21",
      "ES1red": "",
      "ET1ox": "0.03",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.74",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BLBXSGNMYBATMG-VHXPQNKSSA-N",
      "Smiles": "Clc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)Cl",
      "HOMO": "-6.46",
      "LUMO": "-2.72",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "0.54",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.89",
      "ET1red": "",
      "DipoleMomentS0": "5.43",
      "DipoleMomentS1": "5.43",
      "DipoleMomentT1": "5.11",
      "ionizationPotential": "7.26",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "BLBXSGNMYBATMG-WCWDXBQESA-N",
      "Smiles": "Clc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)Cl",
      "HOMO": "-6.57",
      "LUMO": "-3.04",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.64",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.7",
      "ionizationPotential": "7.43",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "BLDMYIZAXMACJZ-QPLCGJKRSA-N",
      "Smiles": "Cc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)C",
      "HOMO": "-5.6",
      "LUMO": "-2.34",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "0.5",
      "Eox": "0.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.2",
      "ET1red": "",
      "DipoleMomentS0": "8.07",
      "DipoleMomentS1": "8.07",
      "DipoleMomentT1": "7.64",
      "ionizationPotential": "6.28",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "BLJXHHDAFQXEEU-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)F",
      "HOMO": "-5.52",
      "LUMO": "-2.59",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "2.05",
      "E00T1": "1.22",
      "Eox": "0.7",
      "Ered": "",
      "ES1ox": "-1.35",
      "ES1red": "",
      "ET1ox": "-0.52",
      "ET1red": "",
      "DipoleMomentS0": "0.41",
      "DipoleMomentS1": "0.41",
      "DipoleMomentT1": "0.1",
      "ionizationPotential": "6.27",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "BLYXLXQFBFGIMB-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1occ(n1)O)C#N)c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1occ(n1)O)C#N)c1occ(n1)O",
      "HOMO": "-6.55",
      "LUMO": "-3.1",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "",
      "E00T1": "2.33",
      "Eox": "-0.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.18",
      "ET1red": "",
      "DipoleMomentS0": "1.1",
      "DipoleMomentS1": "1.1",
      "DipoleMomentT1": "3.46",
      "ionizationPotential": "8.02",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "BMKJMTZTUBGEFL-UIXGLJBASA-N",
      "Smiles": "C/N=C\\c1cc2ccc3c4c2c2c1ccc1c2c4c(cc1/C=N/C)cc3",
      "MolView_url": "http://molview.org/?q=C/N=C\\c1cc2ccc3c4c2c2c1ccc1c2c4c(cc1/C=N/C)cc3",
      "HOMO": "-6.62",
      "LUMO": "-2.29",
      "VEE": "3.52",
      "Lmax": "352.215909090909",
      "E00S1": "3.31",
      "E00T1": "2.04",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.27",
      "DipoleMomentS1": "2.27",
      "DipoleMomentT1": "2.13",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/dbf_1"
    },
    {
      "InchiKey": "BNPZTATZVAUXTB-ISLYRVAYSA-N",
      "Smiles": "n1cnnc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1nncnn1",
      "MolView_url": "http://molview.org/?q=n1cnnc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1nncnn1",
      "HOMO": "-6.63",
      "LUMO": "-3.57",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.62",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BNSSNOFSMHQEMA-WCWDXBQESA-N",
      "Smiles": "COc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)OC",
      "HOMO": "-5.96",
      "LUMO": "-3.01",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "0.79",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.34",
      "ET1red": "",
      "DipoleMomentS0": "3.63",
      "DipoleMomentS1": "3.63",
      "DipoleMomentT1": "10.2",
      "ionizationPotential": "6.47",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BNYRCTWYHQXEKB-ISLYRVAYSA-N",
      "Smiles": "Sc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)S",
      "HOMO": "-5.72",
      "LUMO": "-3.12",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "1.91",
      "E00T1": "0.72",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.08",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "4.6",
      "ionizationPotential": "6.5",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BPDSIYOZJCFOKR-MNSGOHHFSA-N",
      "Smiles": "[O-][N+](=O)/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N\\[N+](=O)[O-]",
      "HOMO": "-7.39",
      "LUMO": "-3.78",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.09",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.57",
      "ionizationPotential": "8.41",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "BPDSIYOZJCFOKR-MWJLBBCUSA-N",
      "Smiles": "[O-][N+](=O)/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N\\[N+](=O)[O-]",
      "HOMO": "-7.06",
      "LUMO": "-3.51",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "",
      "E00T1": "0.53",
      "Eox": "1.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.33",
      "ET1red": "",
      "DipoleMomentS0": "10.05",
      "DipoleMomentS1": "10.05",
      "DipoleMomentT1": "8.73",
      "ionizationPotential": "8.13",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "BQGNCLPKYJEALV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1scc(n1)C#N)C#N)c1scc(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1scc(n1)C#N)C#N)c1scc(n1)C#N",
      "HOMO": "-7.24",
      "LUMO": "-3.4",
      "VEE": "3.95",
      "Lmax": "313.873417721519",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.78",
      "DipoleMomentS1": "9.78",
      "DipoleMomentT1": "9.96",
      "ionizationPotential": "8.89",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "BQPNWYIZAOQKMY-UHFFFAOYSA-N",
      "Smiles": "Cc1cnc(nc1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1cnc(nc1)[C@]1(C)N=N1",
      "HOMO": "-7.31",
      "LUMO": "-2.11",
      "VEE": "3.36",
      "Lmax": "368.988095238095",
      "E00S1": "3.29",
      "E00T1": "2.69",
      "Eox": "2.34",
      "Ered": "",
      "ES1ox": "-0.95",
      "ES1red": "",
      "ET1ox": "-0.35",
      "ET1red": "",
      "DipoleMomentS0": "5.11",
      "DipoleMomentS1": "5.11",
      "DipoleMomentT1": "8.46",
      "ionizationPotential": "8.51",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "BQPSSPUDWTXYLD-UHFFFAOYSA-N",
      "Smiles": "Cn1c(Cl)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)Cl)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=Cn1c(Cl)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)Cl)c1ccc(n1C)Cl",
      "HOMO": "-5.48",
      "LUMO": "-2.37",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.67",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.57",
      "DipoleMomentS1": "3.57",
      "DipoleMomentT1": "4.34",
      "ionizationPotential": "6.14",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "BQTWENUTYIJGOX-UHFFFAOYSA-N",
      "Smiles": "Cc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)C)cc(c5ccc4)c1cnc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)C)cc(c5ccc4)c1cnc([nH]1)C",
      "HOMO": "-5.32",
      "LUMO": "-2.54",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "1.95",
      "E00T1": "1.16",
      "Eox": "0.52",
      "Ered": "",
      "ES1ox": "-1.43",
      "ES1red": "",
      "ET1ox": "-0.64",
      "ET1red": "",
      "DipoleMomentS0": "10.09",
      "DipoleMomentS1": "10.09",
      "DipoleMomentT1": "11.26",
      "ionizationPotential": "6.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "BSCMALNKZHTFND-RSQYGXQASA-N",
      "Smiles": "C/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N\\C",
      "MolView_url": "http://molview.org/?q=C/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N\\C",
      "HOMO": "-6.19",
      "LUMO": "-3.13",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.62",
      "DipoleMomentS1": "2.62",
      "DipoleMomentT1": "3.47",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BTMRGPHTEXTYNA-FOCLMDBBSA-N",
      "Smiles": "Clc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)Cl",
      "HOMO": "-6.65",
      "LUMO": "-3.66",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.15",
      "E00T1": "0.75",
      "Eox": "1.84",
      "Ered": "",
      "ES1ox": "-0.31",
      "ES1red": "",
      "ET1ox": "1.09",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "1.5",
      "ionizationPotential": "7.66",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BVKHYHXARAVAMP-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)C(F)(F)F)(F)F",
      "HOMO": "-5.47",
      "LUMO": "-2.48",
      "VEE": "1.52",
      "Lmax": "815.657894736842",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.65",
      "DipoleMomentS1": "6.65",
      "DipoleMomentT1": "6.84",
      "ionizationPotential": "6.44",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "BVVQEJRHOTVAAT-QURGRASLSA-N",
      "Smiles": "COc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)OC",
      "MolView_url": "http://molview.org/?q=COc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)OC",
      "HOMO": "-5.17",
      "LUMO": "-2.7",
      "VEE": "2.55",
      "Lmax": "486.196078431373",
      "E00S1": "1.83",
      "E00T1": "0.67",
      "Eox": "0.23",
      "Ered": "",
      "ES1ox": "-1.6",
      "ES1red": "",
      "ET1ox": "-0.44",
      "ET1red": "",
      "DipoleMomentS0": "2.6",
      "DipoleMomentS1": "2.6",
      "DipoleMomentT1": "1.05",
      "ionizationPotential": "5.85",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "BVXNHAFCAIKWJO-FLWNBWAVSA-N",
      "Smiles": "N#Cc1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(cn1)C#N",
      "HOMO": "-6.7",
      "LUMO": "-2.94",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.58",
      "DipoleMomentS1": "2.58",
      "DipoleMomentT1": "2.05",
      "ionizationPotential": "7.51",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "BWCZPPRYTXEPCG-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)F)c1ccc(s1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)F)c1ccc(s1)F",
      "HOMO": "-6.51",
      "LUMO": "-3.15",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "2.59",
      "E00T1": "1.83",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.63",
      "DipoleMomentS1": "1.63",
      "DipoleMomentT1": "12.64",
      "ionizationPotential": "7.5",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "BWIPMMVDQWPABW-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(s1)c1cc2c3cc(c4ncc(s4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)S)cc(c5ccc4)c1ncc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(s1)c1cc2c3cc(c4ncc(s4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)S)cc(c5ccc4)c1ncc(s1)S",
      "HOMO": "-5.63",
      "LUMO": "-2.9",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.87",
      "E00T1": "0.25",
      "Eox": "-4.24",
      "Ered": "",
      "ES1ox": "-6.11",
      "ES1red": "",
      "ET1ox": "-4.49",
      "ET1red": "",
      "DipoleMomentS0": "2.76",
      "DipoleMomentS1": "2.76",
      "DipoleMomentT1": "2.45",
      "ionizationPotential": "0.07",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "BWLSTGTZFYPSFZ-BYYHNAKLSA-N",
      "Smiles": "FC(Oc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "HOMO": "-5.77",
      "LUMO": "-3.02",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.77",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "4.52",
      "DipoleMomentS1": "4.52",
      "DipoleMomentT1": "4.72",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "BWLSTGTZFYPSFZ-DQSJHHFOSA-N",
      "Smiles": "FC(Oc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "HOMO": "-5.8",
      "LUMO": "-2.98",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.67",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.81",
      "DipoleMomentS1": "0.81",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "BXNYECZHWNLIPS-UHFFFAOYSA-N",
      "Smiles": "N#CC#C[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#CC#C[C@]1(C#N)N=N1",
      "HOMO": "-8.5",
      "LUMO": "-3.23",
      "VEE": "3.62",
      "Lmax": "342.486187845304",
      "E00S1": "3.48",
      "E00T1": "2.88",
      "Eox": "3.63",
      "Ered": "",
      "ES1ox": "0.15",
      "ES1red": "",
      "ET1ox": "0.75",
      "ET1red": "",
      "DipoleMomentS0": "3.52",
      "DipoleMomentS1": "3.52",
      "DipoleMomentT1": "1.32",
      "ionizationPotential": "10.41",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "BXQWWDMFNKCAFN-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(s1)C(F)(F)F)C#N)c1ncc(s1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(s1)C(F)(F)F)C#N)c1ncc(s1)C(F)(F)F",
      "HOMO": "-7.57",
      "LUMO": "-3.08",
      "VEE": "4.21",
      "Lmax": "294.489311163895",
      "E00S1": "",
      "E00T1": "2.71",
      "Eox": "2.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.24",
      "ET1red": "",
      "DipoleMomentS0": "0.8",
      "DipoleMomentS1": "0.8",
      "DipoleMomentT1": "1.71",
      "ionizationPotential": "8.82",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "BYCCZHWWRXPEOH-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)S",
      "HOMO": "-6.52",
      "LUMO": "-3.16",
      "VEE": "2.52",
      "Lmax": "491.984126984127",
      "E00S1": "2.45",
      "E00T1": "2.04",
      "Eox": "1.89",
      "Ered": "",
      "ES1ox": "-0.56",
      "ES1red": "",
      "ET1ox": "-0.15",
      "ET1red": "",
      "DipoleMomentS0": "6.09",
      "DipoleMomentS1": "6.09",
      "DipoleMomentT1": "19.52",
      "ionizationPotential": "7.46",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "BYXWPRCTYCQNQH-UHFFFAOYSA-N",
      "Smiles": "Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1cccs1)c1c3cc(cc1)c1cccs1)ccc(c5)c1cccs1",
      "MolView_url": "http://molview.org/?q=Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1cccs1)c1c3cc(cc1)c1cccs1)ccc(c5)c1cccs1",
      "HOMO": "-5.53",
      "LUMO": "-2.43",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "1.59",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.91",
      "ET1red": "",
      "DipoleMomentS0": "8.48",
      "DipoleMomentS1": "8.48",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.19",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "BZBSDVNUGKYWLX-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(o1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1ccc(o1)[C@]1(F)N=N1",
      "HOMO": "-6.89",
      "LUMO": "-2.68",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "2.63",
      "E00T1": "2.04",
      "Eox": "2.02",
      "Ered": "",
      "ES1ox": "-0.61",
      "ES1red": "",
      "ET1ox": "-0.02",
      "ET1red": "",
      "DipoleMomentS0": "3.24",
      "DipoleMomentS1": "3.24",
      "DipoleMomentT1": "9.06",
      "ionizationPotential": "8.64",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "BZFPGDMVRFZXMG-UHFFFAOYSA-N",
      "Smiles": "Fc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)F",
      "MolView_url": "http://molview.org/?q=Fc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)F",
      "HOMO": "-5.73",
      "LUMO": "-3.45",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.98",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.92",
      "DipoleMomentS1": "6.92",
      "DipoleMomentT1": "33.5",
      "ionizationPotential": "6.77",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CAFKTMYBPSXEGJ-UHFFFAOYSA-N",
      "Smiles": "S/C=C/[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=S/C=C/[C@]1(S)N=N1",
      "HOMO": "-6.78",
      "LUMO": "-2.34",
      "VEE": "3.48",
      "Lmax": "356.264367816092",
      "E00S1": "2.97",
      "E00T1": "1.97",
      "Eox": "1.81",
      "Ered": "",
      "ES1ox": "-1.16",
      "ES1red": "",
      "ET1ox": "-0.16",
      "ET1red": "",
      "DipoleMomentS0": "2.68",
      "DipoleMomentS1": "2.68",
      "DipoleMomentT1": "6.06",
      "ionizationPotential": "8.32",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "CAHFMHPGCFETPL-UHFFFAOYSA-N",
      "Smiles": "Clc1c[nH]c(c1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1c[nH]c(c1)[C@]1(Cl)N=N1",
      "HOMO": "-6.61",
      "LUMO": "-2.59",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.67",
      "DipoleMomentS1": "3.67",
      "DipoleMomentT1": "10.2",
      "ionizationPotential": "8.11",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "CANOMQQSAVILIN-UHFFFAOYSA-N",
      "Smiles": "Cn1c(ccc1c1cc2c3cc(c4ccc(n4C)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)OC(F)(F)F)cc(c5ccc4)c1ccc(n1C)OC(F)(F)F)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=Cn1c(ccc1c1cc2c3cc(c4ccc(n4C)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)OC(F)(F)F)cc(c5ccc4)c1ccc(n1C)OC(F)(F)F)OC(F)(F)F",
      "HOMO": "-5.53",
      "LUMO": "-2.59",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "-4.14",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-4.37",
      "ET1red": "",
      "DipoleMomentS0": "1.96",
      "DipoleMomentS1": "1.96",
      "DipoleMomentT1": "1.9",
      "ionizationPotential": "0.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "CAPLPTYMGWJUSV-FMQUCBEESA-N",
      "Smiles": "Fc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)F",
      "HOMO": "-6.05",
      "LUMO": "-3.2",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "2.14",
      "E00T1": "0.78",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "-1.01",
      "ES1red": "",
      "ET1ox": "0.35",
      "ET1red": "",
      "DipoleMomentS0": "2.29",
      "DipoleMomentS1": "2.29",
      "DipoleMomentT1": "1.72",
      "ionizationPotential": "6.92",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "CBDYBLAQSSFLSE-UHFFFAOYSA-N",
      "Smiles": "FC(C1=CC=C(S1(=O)=O)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(S1(=O)=O)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-8.06",
      "LUMO": "-3.48",
      "VEE": "3.82",
      "Lmax": "324.554973821989",
      "E00S1": "3.17",
      "E00T1": "",
      "Eox": "3.13",
      "Ered": "",
      "ES1ox": "-0.04",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.19",
      "DipoleMomentS1": "7.19",
      "DipoleMomentT1": "7.18",
      "ionizationPotential": "9.58",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "CBHPPIJPCRTIHM-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)S",
      "HOMO": "-5.41",
      "LUMO": "-2.45",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.06",
      "E00T1": "1.25",
      "Eox": "0.6",
      "Ered": "",
      "ES1ox": "-1.46",
      "ES1red": "",
      "ET1ox": "-0.65",
      "ET1red": "",
      "DipoleMomentS0": "2.07",
      "DipoleMomentS1": "2.07",
      "DipoleMomentT1": "1.35",
      "ionizationPotential": "6.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CBOGOZSCLHQWAE-FLWNBWAVSA-N",
      "Smiles": "FC(c1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)C(F)(F)F)(F)F",
      "HOMO": "-6.25",
      "LUMO": "-2.62",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "0.52",
      "Eox": "1.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.73",
      "ET1red": "",
      "DipoleMomentS0": "0.87",
      "DipoleMomentS1": "0.87",
      "DipoleMomentT1": "1.71",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "CBUIEVKAMJGIPP-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)S",
      "HOMO": "-5.36",
      "LUMO": "-2.39",
      "VEE": "1.52",
      "Lmax": "815.657894736842",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.56",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.28",
      "DipoleMomentS1": "0.28",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "6.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CDBOHLQHVOIZHL-UHFFFAOYSA-N",
      "Smiles": "ClC1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)Cl",
      "HOMO": "-5.64",
      "LUMO": "-3",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.73",
      "E00T1": "",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "-0.92",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "13.69",
      "DipoleMomentS1": "13.69",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.74",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CDLWLRHRMNWTMU-DQRAZIAOSA-N",
      "Smiles": "c1coc(c1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccco1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccco1",
      "HOMO": "-5.93",
      "LUMO": "-2.47",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "1.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.52",
      "ET1red": "",
      "DipoleMomentS0": "5.45",
      "DipoleMomentS1": "5.45",
      "DipoleMomentT1": "4.27",
      "ionizationPotential": "6.64",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "CDLWLRHRMNWTMU-QURGRASLSA-N",
      "Smiles": "c1coc(c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccco1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccco1",
      "HOMO": "-5.97",
      "LUMO": "-2.71",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.08",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "5.59",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "CDQRCGPZEGVLIE-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(o1)C(F)(F)F)C#N)c1ncc(o1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(o1)C(F)(F)F)C#N)c1ncc(o1)C(F)(F)F",
      "HOMO": "-7.37",
      "LUMO": "-3.34",
      "VEE": "3.66",
      "Lmax": "338.743169398907",
      "E00S1": "",
      "E00T1": "2.74",
      "Eox": "-0.55",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.29",
      "ET1red": "",
      "DipoleMomentS0": "2.56",
      "DipoleMomentS1": "2.56",
      "DipoleMomentT1": "1.94",
      "ionizationPotential": "8.99",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "CEHGRCLLMICTQS-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nccs1)c1nccs1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nccs1)c1nccs1",
      "HOMO": "-6.87",
      "LUMO": "-3.27",
      "VEE": "2.52",
      "Lmax": "491.984126984127",
      "E00S1": "2.8",
      "E00T1": "2.24",
      "Eox": "1.94",
      "Ered": "",
      "ES1ox": "-0.86",
      "ES1red": "",
      "ET1ox": "-0.3",
      "ET1red": "",
      "DipoleMomentS0": "2.3",
      "DipoleMomentS1": "2.3",
      "DipoleMomentT1": "5.21",
      "ionizationPotential": "7.84",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "CERNXSRWYBBWEI-FMQUCBEESA-N",
      "Smiles": "[O-][N+](=O)C1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)[N+](=O)[O-]",
      "HOMO": "-5.98",
      "LUMO": "-3.7",
      "VEE": "2.5",
      "Lmax": "495.92",
      "E00S1": "1.69",
      "E00T1": "0.5",
      "Eox": "1.01",
      "Ered": "",
      "ES1ox": "-0.68",
      "ES1red": "",
      "ET1ox": "0.51",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.01",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "CFGCEWBDXHBWST-OCEACIFDSA-N",
      "Smiles": "CC1=CC=C(C1)c1ccc(cc1)/N=N/c1ccc(cc1)C1=CC=C(C1)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(C1)c1ccc(cc1)/N=N/c1ccc(cc1)C1=CC=C(C1)C",
      "HOMO": "-5.54",
      "LUMO": "-2.62",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "1.51",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.83",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "5.78",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "CFGCEWBDXHBWST-QPLCGJKRSA-N",
      "Smiles": "CC1=CC=C(C1)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(C1)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(C1)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(C1)C",
      "HOMO": "-5.53",
      "LUMO": "-2.42",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.37",
      "DipoleMomentS1": "7.37",
      "DipoleMomentT1": "6.6",
      "ionizationPotential": "6.24",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "CFKAHSNQPMLNNO-QURGRASLSA-N",
      "Smiles": "O=Cn1c(C)ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)C",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C)ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)C",
      "HOMO": "-5.5",
      "LUMO": "-2.85",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.57",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.76",
      "DipoleMomentS1": "5.76",
      "DipoleMomentT1": "5.71",
      "ionizationPotential": "6.18",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "CFOXWQYJAUATPO-VXPUYCOJSA-N",
      "Smiles": "Sc1ccc(s1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(s1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(s1)S",
      "HOMO": "-5.49",
      "LUMO": "-2.62",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "0.28",
      "Eox": "-0.35",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.63",
      "ET1red": "",
      "DipoleMomentS0": "3.48",
      "DipoleMomentS1": "3.48",
      "DipoleMomentT1": "3.5",
      "ionizationPotential": "5.32",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "CFZYJORKPYFCLH-UHFFFAOYSA-N",
      "Smiles": "c1ccc(cc1)c1cc2c3cc(c4ccccc4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccccc1)cc(c5ccc4)c1ccccc1",
      "MolView_url": "http://molview.org/?q=c1ccc(cc1)c1cc2c3cc(c4ccccc4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccccc1)cc(c5ccc4)c1ccccc1",
      "HOMO": "-5.37",
      "LUMO": "-2.41",
      "VEE": "3.04",
      "Lmax": "407.828947368421",
      "E00S1": "",
      "E00T1": "1.23",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.06",
      "ET1red": "",
      "DipoleMomentS0": "0.03",
      "DipoleMomentS1": "0.03",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "5.99",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "CGHHZZJMBFBKDQ-UHFFFAOYSA-N",
      "Smiles": "FC(c1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)C(F)(F)F)(F)F",
      "HOMO": "-5.8",
      "LUMO": "-3.41",
      "VEE": "1.41",
      "Lmax": "879.290780141844",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.74",
      "DipoleMomentS1": "9.74",
      "DipoleMomentT1": "34.12",
      "ionizationPotential": "7.35",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CGILFSCOFDTGFV-UHFFFAOYSA-N",
      "Smiles": "c1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nccs1",
      "MolView_url": "http://molview.org/?q=c1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nccs1",
      "HOMO": "-5.49",
      "LUMO": "-2.63",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.96",
      "E00T1": "1.18",
      "Eox": "0.67",
      "Ered": "",
      "ES1ox": "-1.29",
      "ES1red": "",
      "ET1ox": "-0.51",
      "ET1red": "",
      "DipoleMomentS0": "1.66",
      "DipoleMomentS1": "1.66",
      "DipoleMomentT1": "2.09",
      "ionizationPotential": "6.22",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CGJKLUNSCJFMND-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cc1)C#N)C#N)c1ccc(cc1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cc1)C#N)C#N)c1ccc(cc1)C#N",
      "HOMO": "-7.28",
      "LUMO": "-3.06",
      "VEE": "4.25",
      "Lmax": "291.717647058823",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.61",
      "DipoleMomentS1": "4.61",
      "DipoleMomentT1": "3.16",
      "ionizationPotential": "8.93",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "CGXIVLYUPDEBCH-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)Cl",
      "HOMO": "-5.41",
      "LUMO": "-2.46",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.59",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.65",
      "DipoleMomentS1": "3.65",
      "DipoleMomentT1": "2.82",
      "ionizationPotential": "6.14",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CHEYBYDDLMJLKW-DQRAZIAOSA-N",
      "Smiles": "Oc1ccc([nH]1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc([nH]1)O",
      "HOMO": "-4.93",
      "LUMO": "-2.24",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.43",
      "DipoleMomentS1": "9.43",
      "DipoleMomentT1": "7.02",
      "ionizationPotential": "4.75",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "CHLNYXQJUPTEJZ-VHXPQNKSSA-N",
      "Smiles": "Oc1ccc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc([nH]1)O",
      "HOMO": "-5.34",
      "LUMO": "-2.33",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "0.44",
      "Eox": "0.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.08",
      "ET1red": "",
      "DipoleMomentS0": "9.16",
      "DipoleMomentS1": "9.16",
      "DipoleMomentT1": "8.2",
      "ionizationPotential": "6.09",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "CITGVGJXMKEMRK-OCEACIFDSA-N",
      "Smiles": "N#Cc1ccc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(s1)C#N",
      "HOMO": "-6.47",
      "LUMO": "-3.03",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "1.47",
      "ionizationPotential": "7.44",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "CITGVGJXMKEMRK-QPLCGJKRSA-N",
      "Smiles": "N#Cc1ccc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(s1)C#N",
      "HOMO": "-6.38",
      "LUMO": "-2.76",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.31",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.83",
      "DipoleMomentS1": "8.83",
      "DipoleMomentT1": "7.83",
      "ionizationPotential": "7.31",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "CJAAQSDCJULNQB-UHFFFAOYSA-N",
      "Smiles": "COc1csc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1scc(n1)OC)c1scc(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1csc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1scc(n1)OC)c1scc(n1)OC",
      "HOMO": "-5.61",
      "LUMO": "-2.59",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "0.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.67",
      "ET1red": "",
      "DipoleMomentS0": "6.29",
      "DipoleMomentS1": "6.29",
      "DipoleMomentT1": "6.57",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "CKJXRRFMXGLFJE-KNOCWOPBSA-N",
      "Smiles": "[O-][N+](=O)/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\[N+](=O)[O-]",
      "HOMO": "-6.55",
      "LUMO": "-3.76",
      "VEE": "1.42",
      "Lmax": "873.098591549296",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "1.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.26",
      "ET1red": "",
      "DipoleMomentS0": "7.84",
      "DipoleMomentS1": "7.84",
      "DipoleMomentT1": "7.81",
      "ionizationPotential": "7.61",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "CKJXRRFMXGLFJE-LBPZLPHVSA-N",
      "Smiles": "[O-][N+](=O)/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/[N+](=O)[O-]",
      "HOMO": "-6.52",
      "LUMO": "-3.79",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "0.13",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.28",
      "ET1red": "",
      "DipoleMomentS0": "2.89",
      "DipoleMomentS1": "2.89",
      "DipoleMomentT1": "1.98",
      "ionizationPotential": "7.68",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "CKJXRRFMXGLFJE-XLLIXGIFSA-N",
      "Smiles": "[O-][N+](=O)/C=C/c1ccc(s1)/N=N/c1ccc(s1)/C=C\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C/c1ccc(s1)/N=N/c1ccc(s1)/C=C\\[N+](=O)[O-]",
      "HOMO": "-6.66",
      "LUMO": "-3.92",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "0.8",
      "Eox": "1.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.86",
      "ET1red": "",
      "DipoleMomentS0": "5.99",
      "DipoleMomentS1": "5.99",
      "DipoleMomentT1": "5.97",
      "ionizationPotential": "7.64",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "CLJQLKYDXGFJJT-VJINACGZSA-N",
      "Smiles": "F/N=C\\c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=N\\F)ccc2-c([n+]31)c4)Cl)/C=N\\F",
      "MolView_url": "http://molview.org/?q=F/N=C\\c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=N\\F)ccc2-c([n+]31)c4)Cl)/C=N\\F",
      "HOMO": "-5.84",
      "LUMO": "-2.77",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "2.24",
      "E00T1": "1.58",
      "Eox": "0.99",
      "Ered": "",
      "ES1ox": "-1.25",
      "ES1red": "",
      "ET1ox": "-0.59",
      "ET1red": "",
      "DipoleMomentS0": "4.71",
      "DipoleMomentS1": "4.71",
      "DipoleMomentT1": "5.86",
      "ionizationPotential": "6.89",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "CLKYESVEFZGQIH-OCEACIFDSA-N",
      "Smiles": "FC(Oc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-5.78",
      "LUMO": "-3.06",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.71",
      "DipoleMomentS1": "3.71",
      "DipoleMomentT1": "2.28",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "CLKYESVEFZGQIH-QPLCGJKRSA-N",
      "Smiles": "FC(Oc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-5.8",
      "LUMO": "-3.04",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.18",
      "DipoleMomentS1": "6.18",
      "DipoleMomentT1": "6.15",
      "ionizationPotential": "6.45",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "CMCMKBPRJOYLGY-UHFFFAOYSA-N",
      "Smiles": "N#CC1=CC=C(C1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(C1)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(C1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(C1)C#N",
      "HOMO": "-6.56",
      "LUMO": "-3.28",
      "VEE": "1.98",
      "Lmax": "626.161616161616",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.87",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.63",
      "DipoleMomentS1": "5.63",
      "DipoleMomentT1": "2.89",
      "ionizationPotential": "7.73",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "CMQZUJMWGVXJMK-UHFFFAOYSA-N",
      "Smiles": "CC1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)C)C1=CC=C(C1)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)C)C1=CC=C(C1)C",
      "HOMO": "-5.28",
      "LUMO": "-2.35",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "2.11",
      "E00T1": "",
      "Eox": "0.48",
      "Ered": "",
      "ES1ox": "-1.63",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.96",
      "DipoleMomentS1": "8.96",
      "DipoleMomentT1": "",
      "ionizationPotential": "5.84",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "CNSKENAQGROUTC-UHFFFAOYSA-N",
      "Smiles": "N#Cc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)C#N",
      "HOMO": "-5.84",
      "LUMO": "-3.43",
      "VEE": "1.39",
      "Lmax": "891.942446043165",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "13.63",
      "DipoleMomentS1": "13.63",
      "DipoleMomentT1": "17.25",
      "ionizationPotential": "7.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CNTZVJORLKGWJZ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ncc([nH]1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1ncc([nH]1)[C@]1(C#N)N=N1",
      "HOMO": "-7.46",
      "LUMO": "-3.08",
      "VEE": "3.82",
      "Lmax": "324.554973821989",
      "E00S1": "2.85",
      "E00T1": "2.33",
      "Eox": "2.57",
      "Ered": "",
      "ES1ox": "-0.28",
      "ES1red": "",
      "ET1ox": "0.24",
      "ET1red": "",
      "DipoleMomentS0": "4.81",
      "DipoleMomentS1": "4.81",
      "DipoleMomentT1": "3.56",
      "ionizationPotential": "9.3",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "COTQPDLIJPQNAC-AGSASIROSA-N",
      "Smiles": "N#C/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C/C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C/C#N",
      "HOMO": "-6.24",
      "LUMO": "-3.47",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "2.07",
      "E00T1": "0.65",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "-0.75",
      "ES1red": "",
      "ET1ox": "0.67",
      "ET1red": "",
      "DipoleMomentS0": "13.17",
      "DipoleMomentS1": "13.17",
      "DipoleMomentT1": "14.08",
      "ionizationPotential": "7.3",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "COXAKHPHWSUWIV-ISLYRVAYSA-N",
      "Smiles": "O=S1(=O)C=CC=C1c1ccc(s1)/N=N/c1ccc(s1)C1=CC=CS1(=O)=O",
      "MolView_url": "http://molview.org/?q=O=S1(=O)C=CC=C1c1ccc(s1)/N=N/c1ccc(s1)C1=CC=CS1(=O)=O",
      "HOMO": "-6.21",
      "LUMO": "-3.61",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.72",
      "DipoleMomentS1": "6.72",
      "DipoleMomentT1": "7.42",
      "ionizationPotential": "7.05",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "COXAKHPHWSUWIV-ZCXUNETKSA-N",
      "Smiles": "O=S1(=O)C=CC=C1c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=CS1(=O)=O",
      "MolView_url": "http://molview.org/?q=O=S1(=O)C=CC=C1c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=CS1(=O)=O",
      "HOMO": "-6.39",
      "LUMO": "-3.51",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "-0.09",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.25",
      "ET1red": "",
      "DipoleMomentS0": "8.74",
      "DipoleMomentS1": "8.74",
      "DipoleMomentT1": "10.71",
      "ionizationPotential": "7.07",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "CPANPYZVYYAHAF-DQRAZIAOSA-N",
      "Smiles": "Oc1cnc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(o1)O",
      "HOMO": "-6.05",
      "LUMO": "-2.59",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "0.51",
      "Eox": "1.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.67",
      "ET1red": "",
      "DipoleMomentS0": "7.27",
      "DipoleMomentS1": "7.27",
      "DipoleMomentT1": "6.94",
      "ionizationPotential": "6.81",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "CPANPYZVYYAHAF-QURGRASLSA-N",
      "Smiles": "Oc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)O",
      "HOMO": "-6.08",
      "LUMO": "-2.87",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.26",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.2",
      "ET1red": "",
      "DipoleMomentS0": "6.82",
      "DipoleMomentS1": "6.82",
      "DipoleMomentT1": "6.94",
      "ionizationPotential": "6.89",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "CPEPBMPRSADCOB-ISLYRVAYSA-N",
      "Smiles": "Sc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)S",
      "HOMO": "-5.93",
      "LUMO": "-3.26",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "0.75",
      "Eox": "1.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.33",
      "ET1red": "",
      "DipoleMomentS0": "3.52",
      "DipoleMomentS1": "3.52",
      "DipoleMomentT1": "5.07",
      "ionizationPotential": "6.7",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "CPEPBMPRSADCOB-ZCXUNETKSA-N",
      "Smiles": "Sc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)S",
      "HOMO": "-5.92",
      "LUMO": "-3.21",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.81",
      "ET1red": "",
      "DipoleMomentS0": "7.85",
      "DipoleMomentS1": "7.85",
      "DipoleMomentT1": "6.38",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "CPHBILXMSQZFRE-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1nnc(nn1)OC)C#N)c1nnc(nn1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1nnc(nn1)OC)C#N)c1nnc(nn1)OC",
      "HOMO": "-7.37",
      "LUMO": "-3.34",
      "VEE": "2.08",
      "Lmax": "596.057692307692",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.33",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.39",
      "DipoleMomentS1": "5.39",
      "DipoleMomentT1": "5.46",
      "ionizationPotential": "8.38",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "CPNJWWRDYIKSFJ-AROPPZRASA-N",
      "Smiles": "C=CO/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\OC=C",
      "MolView_url": "http://molview.org/?q=C=CO/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\OC=C",
      "HOMO": "-5.72",
      "LUMO": "-2.71",
      "VEE": "1.53",
      "Lmax": "810.326797385621",
      "E00S1": "",
      "E00T1": "0.15",
      "Eox": "0.56",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "9.88",
      "DipoleMomentS1": "9.88",
      "DipoleMomentT1": "7.05",
      "ionizationPotential": "6.18",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "CPTHHTGZTQBXLG-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)O)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)O)c1ccc([nH]1)O",
      "HOMO": "-5.65",
      "LUMO": "-2.96",
      "VEE": "1.77",
      "Lmax": "700.45197740113",
      "E00S1": "1.8",
      "E00T1": "1.53",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "-0.77",
      "ES1red": "",
      "ET1ox": "-0.5",
      "ET1red": "",
      "DipoleMomentS0": "4.12",
      "DipoleMomentS1": "4.12",
      "DipoleMomentT1": "8.64",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "CPZQZSGJUBMNHH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(c2cccs2)c(c(c1)c1cccs1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(c2cccs2)c(c(c1)c1cccs1)C#N",
      "HOMO": "-6.85",
      "LUMO": "-2.6",
      "VEE": "3.5",
      "Lmax": "354.228571428571",
      "E00S1": "",
      "E00T1": "2.63",
      "Eox": "1.88",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.75",
      "ET1red": "",
      "DipoleMomentS0": "1.69",
      "DipoleMomentS1": "1.69",
      "DipoleMomentT1": "4.06",
      "ionizationPotential": "7.99",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "CQFONUXIAHDACZ-VHXPQNKSSA-N",
      "Smiles": "OC1=CC=C(C1)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(C1)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(C1)O",
      "HOMO": "-5.35",
      "LUMO": "-2.37",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.19",
      "DipoleMomentS1": "7.19",
      "DipoleMomentT1": "6.52",
      "ionizationPotential": "6.08",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "CQFONUXIAHDACZ-WCWDXBQESA-N",
      "Smiles": "OC1=CC=C(C1)c1ccc(cc1)/N=N/c1ccc(cc1)C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(C1)c1ccc(cc1)/N=N/c1ccc(cc1)C1=CC=C(C1)O",
      "HOMO": "-5.33",
      "LUMO": "-2.55",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "0.98",
      "Eox": "0.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.46",
      "ET1red": "",
      "DipoleMomentS0": "3.16",
      "DipoleMomentS1": "3.16",
      "DipoleMomentT1": "8.59",
      "ionizationPotential": "6.09",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "CQVVBYLEHCVETJ-UHFFFAOYSA-N",
      "Smiles": "Clc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)Cl",
      "HOMO": "-5.73",
      "LUMO": "-3.36",
      "VEE": "1.45",
      "Lmax": "855.034482758621",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.11",
      "DipoleMomentS1": "6.11",
      "DipoleMomentT1": "33.83",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CQWCEVPJPHYCGM-UHFFFAOYSA-N",
      "Smiles": "CC#Cc1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(C#CC)ccc2-c([n+]31)c4)Cl)C#CC",
      "MolView_url": "http://molview.org/?q=CC#Cc1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(C#CC)ccc2-c([n+]31)c4)Cl)C#CC",
      "HOMO": "-5.6",
      "LUMO": "-2.44",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "1.6",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.79",
      "ET1red": "",
      "DipoleMomentS0": "8.02",
      "DipoleMomentS1": "8.02",
      "DipoleMomentT1": "8.99",
      "ionizationPotential": "0.02",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "CRPWWEGCXMDPCA-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(o1)c1cc2c3cc(c4ncc(o4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)C(F)(F)F)cc(c5ccc4)c1ncc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(o1)c1cc2c3cc(c4ncc(o4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)C(F)(F)F)cc(c5ccc4)c1ncc(o1)C(F)(F)F)(F)F",
      "HOMO": "-5.85",
      "LUMO": "-3.2",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "1.84",
      "E00T1": "0.22",
      "Eox": "-4.26",
      "Ered": "",
      "ES1ox": "-6.1",
      "ES1red": "",
      "ET1ox": "-4.48",
      "ET1red": "",
      "DipoleMomentS0": "1.55",
      "DipoleMomentS1": "1.55",
      "DipoleMomentT1": "1.73",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "CSPQIBFERFRQJM-WCWDXBQESA-N",
      "Smiles": "COc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)OC",
      "HOMO": "-6.03",
      "LUMO": "-3.1",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "0.87",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.29",
      "ET1red": "",
      "DipoleMomentS0": "3.25",
      "DipoleMomentS1": "3.25",
      "DipoleMomentT1": "3.83",
      "ionizationPotential": "6.52",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "CTBQDYFTWNJJMI-BSHIDLADSA-N",
      "Smiles": "CO/N=C\\c1cc(C#N)cc(c1C#N)/C=N/OC",
      "MolView_url": "http://molview.org/?q=CO/N=C\\c1cc(C#N)cc(c1C#N)/C=N/OC",
      "HOMO": "-7.01",
      "LUMO": "-3.12",
      "VEE": "3.68",
      "Lmax": "336.902173913043",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.79",
      "DipoleMomentS1": "1.79",
      "DipoleMomentT1": "3.19",
      "ionizationPotential": "8.44",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "CTJDWVMZNHKDKG-VHXPQNKSSA-N",
      "Smiles": "Fc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)F",
      "HOMO": "-6.02",
      "LUMO": "-3.06",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.99",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.65",
      "DipoleMomentS1": "1.65",
      "DipoleMomentT1": "1.58",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "CVWCIHMZAVMAIC-UHFFFAOYSA-N",
      "Smiles": "Cn1c(ccc1[C@@]1(N=N1)C(F)(F)F)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=Cn1c(ccc1[C@@]1(N=N1)C(F)(F)F)C(F)(F)F",
      "HOMO": "-7.28",
      "LUMO": "-2.73",
      "VEE": "3.81",
      "Lmax": "325.406824146982",
      "E00S1": "3.37",
      "E00T1": "2.51",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.97",
      "DipoleMomentS1": "0.97",
      "DipoleMomentT1": "7.91",
      "ionizationPotential": "8.62",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "CWIRALQULHQMEP-UHFFFAOYSA-N",
      "Smiles": "C1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=CC1",
      "MolView_url": "http://molview.org/?q=C1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=CC1",
      "HOMO": "-5.19",
      "LUMO": "-2.4",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.89",
      "E00T1": "1.11",
      "Eox": "0.43",
      "Ered": "",
      "ES1ox": "-1.46",
      "ES1red": "",
      "ET1ox": "-0.68",
      "ET1red": "",
      "DipoleMomentS0": "0.71",
      "DipoleMomentS1": "0.71",
      "DipoleMomentT1": "0.52",
      "ionizationPotential": "5.91",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CWZAOYMNMSPDER-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-7.64",
      "LUMO": "-3.36",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "3.07",
      "E00T1": "2.49",
      "Eox": "2.76",
      "Ered": "",
      "ES1ox": "-0.31",
      "ES1red": "",
      "ET1ox": "0.27",
      "ET1red": "",
      "DipoleMomentS0": "1.33",
      "DipoleMomentS1": "1.33",
      "DipoleMomentT1": "5.75",
      "ionizationPotential": "8.67",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "CXBFGTLYYMFWCD-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-5.56",
      "LUMO": "-2.68",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.01",
      "E00T1": "1.2",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "-1.28",
      "ES1red": "",
      "ET1ox": "-0.47",
      "ET1red": "",
      "DipoleMomentS0": "1.7",
      "DipoleMomentS1": "1.7",
      "DipoleMomentT1": "2.46",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CXHTZPDPLQJFAC-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-5.57",
      "LUMO": "-2.72",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.95",
      "E00T1": "1.44",
      "Eox": "0.77",
      "Ered": "",
      "ES1ox": "-1.18",
      "ES1red": "",
      "ET1ox": "-0.67",
      "ET1red": "",
      "DipoleMomentS0": "5.71",
      "DipoleMomentS1": "5.71",
      "DipoleMomentT1": "6.09",
      "ionizationPotential": "6.43",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CYKUMPVYVMAFKH-VHXPQNKSSA-N",
      "Smiles": "N#Cc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)C#N",
      "HOMO": "-5.84",
      "LUMO": "-3.09",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "0.18",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.6",
      "ET1red": "",
      "DipoleMomentS0": "3.25",
      "DipoleMomentS1": "3.25",
      "DipoleMomentT1": "4.17",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "CYOIOBCJQTZPPF-UHFFFAOYSA-N",
      "Smiles": "FC(OC#Cc1cc2c3cc(C#COC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)C#COC(F)(F)F)cc(c5ccc4)C#COC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC#Cc1cc2c3cc(C#COC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)C#COC(F)(F)F)cc(c5ccc4)C#COC(F)(F)F)(F)F",
      "HOMO": "-5.59",
      "LUMO": "-2.81",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.23",
      "DipoleMomentS1": "0.23",
      "DipoleMomentT1": "0.28",
      "ionizationPotential": "6.52",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "CYQVAQIOAWGRCN-QURGRASLSA-N",
      "Smiles": "Sc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)S",
      "HOMO": "-6",
      "LUMO": "-3.12",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "",
      "E00T1": "0.81",
      "Eox": "1.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "6.68",
      "ionizationPotential": "6.65",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "CYVMUIZXJCVTJZ-DQRAZIAOSA-N",
      "Smiles": "Fc1csc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1scc(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1csc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1scc(n1)F",
      "HOMO": "-5.74",
      "LUMO": "-2.8",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "1.48",
      "E00T1": "",
      "Eox": "0.26",
      "Ered": "",
      "ES1ox": "-1.22",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.46",
      "DipoleMomentS1": "8.46",
      "DipoleMomentT1": "5.48",
      "ionizationPotential": "5.92",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "CZRFULQNDHFNRN-UHFFFAOYSA-N",
      "Smiles": "OC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)O",
      "HOMO": "-4.99",
      "LUMO": "-2.33",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.79",
      "E00T1": "1.06",
      "Eox": "0.18",
      "Ered": "",
      "ES1ox": "-1.61",
      "ES1red": "",
      "ET1ox": "-0.88",
      "ET1red": "",
      "DipoleMomentS0": "2.3",
      "DipoleMomentS1": "2.3",
      "DipoleMomentT1": "2.99",
      "ionizationPotential": "5.65",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "CZUDJNVVGVOYLF-UHFFFAOYSA-N",
      "Smiles": "COc1ncc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cnc([nH]1)OC)c1cnc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ncc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cnc([nH]1)OC)c1cnc([nH]1)OC",
      "HOMO": "-5.4",
      "LUMO": "-2.37",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "-4.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-4.35",
      "ET1red": "",
      "DipoleMomentS0": "11.07",
      "DipoleMomentS1": "11.07",
      "DipoleMomentT1": "10.77",
      "ionizationPotential": "0.03",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "CZZVLCYAKCFYJY-UHFFFAOYSA-N",
      "Smiles": "C/C=C\\[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=C/C=C\\[C@]1(C)N=N1",
      "HOMO": "-6.79",
      "LUMO": "-1.87",
      "VEE": "3.17",
      "Lmax": "391.104100946372",
      "E00S1": "2.94",
      "E00T1": "2.32",
      "Eox": "1.79",
      "Ered": "",
      "ES1ox": "-1.15",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "2.77",
      "DipoleMomentS1": "2.77",
      "DipoleMomentT1": "6.07",
      "ionizationPotential": "8.26",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DACAQZYSNNTWFH-VHXPQNKSSA-N",
      "Smiles": "COc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)OC",
      "HOMO": "-6.13",
      "LUMO": "-2.65",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.26",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.21",
      "DipoleMomentS1": "3.21",
      "DipoleMomentT1": "2.7",
      "ionizationPotential": "6.87",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "DACAQZYSNNTWFH-WCWDXBQESA-N",
      "Smiles": "COc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)OC",
      "HOMO": "-6.16",
      "LUMO": "-2.95",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.23",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "2.89",
      "ionizationPotential": "6.89",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "DAONYSZAHZJLDU-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C#C[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C#C[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-9.23",
      "LUMO": "-3.8",
      "VEE": "3.89",
      "Lmax": "318.714652956298",
      "E00S1": "3.52",
      "E00T1": "3.18",
      "Eox": "4.37",
      "Ered": "",
      "ES1ox": "0.85",
      "ES1red": "",
      "ET1ox": "1.19",
      "ET1red": "",
      "DipoleMomentS0": "3.47",
      "DipoleMomentS1": "3.47",
      "DipoleMomentT1": "1.14",
      "ionizationPotential": "11.13",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DAZBDUQMBCYKIG-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(o1)[N+](=O)[O-])C#N)c1ccc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(o1)[N+](=O)[O-])C#N)c1ccc(o1)[N+](=O)[O-]",
      "HOMO": "-7.2",
      "LUMO": "-3.64",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "",
      "E00T1": "1.9",
      "Eox": "-0.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.22",
      "ET1red": "",
      "DipoleMomentS0": "6.21",
      "DipoleMomentS1": "6.21",
      "DipoleMomentT1": "9.22",
      "ionizationPotential": "8.85",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DBDDSYYREGOJGP-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(cn1)[N+](=O)[O-])C#N)c1ncc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(cn1)[N+](=O)[O-])C#N)c1ncc(cn1)[N+](=O)[O-]",
      "HOMO": "-8.16",
      "LUMO": "-3.56",
      "VEE": "3.48",
      "Lmax": "356.264367816092",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.65",
      "DipoleMomentS1": "2.65",
      "DipoleMomentT1": "5.85",
      "ionizationPotential": "8.61",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DBGMCFFHHKHHBZ-QPLCGJKRSA-N",
      "Smiles": "COc1ncc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1cnc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ncc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1cnc([nH]1)OC",
      "HOMO": "-5.75",
      "LUMO": "-2.42",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.32",
      "ET1red": "",
      "DipoleMomentS0": "6.07",
      "DipoleMomentS1": "6.07",
      "DipoleMomentT1": "6.15",
      "ionizationPotential": "6.44",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "DBZIFQWTGCMOFT-DNAUBOPZSA-N",
      "Smiles": "N=Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C=N",
      "HOMO": "-6.09",
      "LUMO": "-2.93",
      "VEE": "3.04",
      "Lmax": "407.828947368421",
      "E00S1": "2.38",
      "E00T1": "0.97",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "-1.25",
      "ES1red": "",
      "ET1ox": "0.16",
      "ET1red": "",
      "DipoleMomentS0": "2.97",
      "DipoleMomentS1": "2.97",
      "DipoleMomentT1": "3.2",
      "ionizationPotential": "7.24",
      "source_directory": "/scratch/myrthil.n/pyrrole_trans"
    },
    {
      "InchiKey": "DCLWZIFAWXLQTP-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(o1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1ccc(o1)[C@]1(Cl)N=N1",
      "HOMO": "-6.81",
      "LUMO": "-2.66",
      "VEE": "3.43",
      "Lmax": "361.457725947522",
      "E00S1": "2.64",
      "E00T1": "2.1",
      "Eox": "1.89",
      "Ered": "",
      "ES1ox": "-0.75",
      "ES1red": "",
      "ET1ox": "-0.21",
      "ET1red": "",
      "DipoleMomentS0": "3.13",
      "DipoleMomentS1": "3.13",
      "DipoleMomentT1": "10.42",
      "ionizationPotential": "8.33",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DCXIMRSHMXAVHX-UHFFFAOYSA-N",
      "Smiles": "c1ccc(cc1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=c1ccc(cc1)[C@@H]1N=N1",
      "HOMO": "-6.97",
      "LUMO": "-2.13",
      "VEE": "3.53",
      "Lmax": "351.218130311615",
      "E00S1": "3.07",
      "E00T1": "2.49",
      "Eox": "2.06",
      "Ered": "",
      "ES1ox": "-1.01",
      "ES1red": "",
      "ET1ox": "-0.43",
      "ET1red": "",
      "DipoleMomentS0": "2.47",
      "DipoleMomentS1": "2.47",
      "DipoleMomentT1": "7.39",
      "ionizationPotential": "8.46",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DCYPZDKOUBCVCN-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(C1)Cl)C#N)C1=CC=C(C1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(C1)Cl)C#N)C1=CC=C(C1)Cl",
      "HOMO": "-6.12",
      "LUMO": "-2.85",
      "VEE": "3.08",
      "Lmax": "402.532467532468",
      "E00S1": "",
      "E00T1": "2.02",
      "Eox": "-1.09",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.11",
      "ET1red": "",
      "DipoleMomentS0": "1.99",
      "DipoleMomentS1": "1.99",
      "DipoleMomentT1": "3.35",
      "ionizationPotential": "7.53",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DDHPYLVJEXPJQU-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=CC1)C1=CC=CC1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=CC1)C1=CC=CC1",
      "HOMO": "-6.11",
      "LUMO": "-3.03",
      "VEE": "1.95",
      "Lmax": "635.794871794872",
      "E00S1": "2.35",
      "E00T1": "1.8",
      "Eox": "1.44",
      "Ered": "",
      "ES1ox": "-0.91",
      "ES1red": "",
      "ET1ox": "-0.36",
      "ET1red": "",
      "DipoleMomentS0": "0.7",
      "DipoleMomentS1": "0.7",
      "DipoleMomentT1": "4.93",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DDQABLDGNVCBDR-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(cc1)c1cc2c3cc(c4ccc(cc4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)[N+](=O)[O-])cc(c5ccc4)c1ccc(cc1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(cc1)c1cc2c3cc(c4ccc(cc4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)[N+](=O)[O-])cc(c5ccc4)c1ccc(cc1)[N+](=O)[O-]",
      "HOMO": "-5.64",
      "LUMO": "-3.1",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "1.83",
      "E00T1": "",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DDVPLFMIAGBNRR-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1occ(n1)F)C#N)c1occ(n1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1occ(n1)F)C#N)c1occ(n1)F",
      "HOMO": "-6.97",
      "LUMO": "-3.2",
      "VEE": "3.43",
      "Lmax": "361.457725947522",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.35",
      "DipoleMomentS1": "4.35",
      "DipoleMomentT1": "5.67",
      "ionizationPotential": "8.63",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DDXKCKBBPAYDGA-UHFFFAOYSA-N",
      "Smiles": "Sc1coc(n1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1coc(n1)[C@]1(S)N=N1",
      "HOMO": "-6.88",
      "LUMO": "-2.58",
      "VEE": "3.23",
      "Lmax": "383.839009287926",
      "E00S1": "2.72",
      "E00T1": "2.19",
      "Eox": "1.8",
      "Ered": "",
      "ES1ox": "-0.92",
      "ES1red": "",
      "ET1ox": "-0.39",
      "ET1red": "",
      "DipoleMomentS0": "2.42",
      "DipoleMomentS1": "2.42",
      "DipoleMomentT1": "8.78",
      "ionizationPotential": "8.21",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DEOKCOKFIMZNNB-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(s1)O)C#N)c1ccc(s1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(s1)O)C#N)c1ccc(s1)O",
      "HOMO": "-6.26",
      "LUMO": "-2.54",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "2.04",
      "Eox": "1.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.65",
      "ET1red": "",
      "DipoleMomentS0": "5.22",
      "DipoleMomentS1": "5.22",
      "DipoleMomentT1": "11.75",
      "ionizationPotential": "7.4",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DERFPHVVJJEXKE-UHFFFAOYSA-N",
      "Smiles": "OC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)O)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)O)cc(c5ccc4)C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)O)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)O)cc(c5ccc4)C1=CC=C(C1)O",
      "HOMO": "-4.94",
      "LUMO": "-2.32",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.77",
      "DipoleMomentS1": "3.77",
      "DipoleMomentT1": "3.09",
      "ionizationPotential": "5.7",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DEZQSMQOKKCBPQ-UHFFFAOYSA-N",
      "Smiles": "FC(C1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-9.65",
      "LUMO": "-3.17",
      "VEE": "4.09",
      "Lmax": "303.129584352078",
      "E00S1": "",
      "E00T1": "",
      "Eox": "4.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.9",
      "DipoleMomentS1": "0.9",
      "DipoleMomentT1": "0.56",
      "ionizationPotential": "11.7",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DFDSSEYIIMGZBX-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(o1)Cl)c1ncc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(o1)Cl)c1ncc(o1)Cl",
      "HOMO": "-5.71",
      "LUMO": "-2.61",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "1.61",
      "Eox": "0.87",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.74",
      "ET1red": "",
      "DipoleMomentS0": "6.67",
      "DipoleMomentS1": "6.67",
      "DipoleMomentT1": "8.14",
      "ionizationPotential": "6.47",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "DFPUGULMUYDYKV-WYSJLEGWSA-N",
      "Smiles": "FC(/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/C(F)(F)F)(F)F",
      "HOMO": "-6.26",
      "LUMO": "-3.25",
      "VEE": "1.44",
      "Lmax": "860.972222222222",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3",
      "DipoleMomentS1": "3",
      "DipoleMomentT1": "2.7",
      "ionizationPotential": "7.3",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "DFPUGULMUYDYKV-YKGQLRGASA-N",
      "Smiles": "FC(/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\C(F)(F)F)(F)F",
      "HOMO": "-6.43",
      "LUMO": "-3.15",
      "VEE": "2.52",
      "Lmax": "491.984126984127",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "1.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.01",
      "ET1red": "",
      "DipoleMomentS0": "3.1",
      "DipoleMomentS1": "3.1",
      "DipoleMomentT1": "1.99",
      "ionizationPotential": "7.24",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "DFQSEKZYJPAANI-QURGRASLSA-N",
      "Smiles": "c1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccccc1",
      "MolView_url": "http://molview.org/?q=c1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccccc1",
      "HOMO": "-5.72",
      "LUMO": "-2.83",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "2.18",
      "E00T1": "",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.35",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.45",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DFXSZWZFLOXWTB-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)OC(F)(F)F)cc(c5ccc4)c1ccc(cc1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)OC(F)(F)F)cc(c5ccc4)c1ccc(cc1)OC(F)(F)F)(F)F",
      "HOMO": "-5.49",
      "LUMO": "-2.52",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "2.12",
      "E00T1": "",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.83",
      "DipoleMomentS1": "1.83",
      "DipoleMomentT1": "1.94",
      "ionizationPotential": "6.44",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DGJRLIHVCIOSEC-FMQUCBEESA-N",
      "Smiles": "[O-][N+](=O)c1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)[N+](=O)[O-]",
      "HOMO": "-5.97",
      "LUMO": "-3.47",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "1.87",
      "E00T1": "0.68",
      "Eox": "1.02",
      "Ered": "",
      "ES1ox": "-0.85",
      "ES1red": "",
      "ET1ox": "0.34",
      "ET1red": "",
      "DipoleMomentS0": "17.17",
      "DipoleMomentS1": "17.17",
      "DipoleMomentT1": "18.17",
      "ionizationPotential": "6.96",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DGNUNUGWIJZIFW-QURGRASLSA-N",
      "Smiles": "Oc1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)O",
      "HOMO": "-5.49",
      "LUMO": "-2.71",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.67",
      "DipoleMomentS1": "3.67",
      "DipoleMomentT1": "3.68",
      "ionizationPotential": "6.16",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DHJUYONPCPGTHS-ZCXUNETKSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)[N+](=O)[O-]",
      "HOMO": "-6.22",
      "LUMO": "-3.6",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "0.14",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.98",
      "ET1red": "",
      "DipoleMomentS0": "9.23",
      "DipoleMomentS1": "9.23",
      "DipoleMomentT1": "6.8",
      "ionizationPotential": "7.19",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "DHKXEATUBLFLPP-SEUONAGBSA-N",
      "Smiles": "[O-][N+](=O)/N=C\\c1ccc([nH]1)/N=N/c1ccc([nH]1)/C=N\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/N=C\\c1ccc([nH]1)/N=N/c1ccc([nH]1)/C=N\\[N+](=O)[O-]",
      "HOMO": "-6.68",
      "LUMO": "-3.9",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "12.04",
      "DipoleMomentS1": "12.04",
      "DipoleMomentT1": "14.89",
      "ionizationPotential": "7.79",
      "source_directory": "/scratch/myrthil.n/pyrrole_trans"
    },
    {
      "InchiKey": "DHLXRAILLJPXLU-QURGRASLSA-N",
      "Smiles": "Fc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)F",
      "HOMO": "-5.68",
      "LUMO": "-2.84",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.03",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.66",
      "ionizationPotential": "6.61",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DHZKKMIRTMCIQA-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(o1)c1cc2c3cc(c4ccc(o4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)S)cc(c5ccc4)c1ccc(o1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(o1)c1cc2c3cc(c4ccc(o4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)S)cc(c5ccc4)c1ccc(o1)S",
      "HOMO": "-5.43",
      "LUMO": "-2.68",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "1.87",
      "E00T1": "1.1",
      "Eox": "-4.22",
      "Ered": "",
      "ES1ox": "-6.09",
      "ES1red": "",
      "ET1ox": "-5.32",
      "ET1red": "",
      "DipoleMomentS0": "0.11",
      "DipoleMomentS1": "0.11",
      "DipoleMomentT1": "0.53",
      "ionizationPotential": "6.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DJKSKULPBXJAFA-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncco1)c1ncco1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncco1)c1ncco1",
      "HOMO": "-6.95",
      "LUMO": "-3.29",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "2.87",
      "E00T1": "2.3",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.97",
      "DipoleMomentS1": "1.97",
      "DipoleMomentT1": "5",
      "ionizationPotential": "7.95",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DJOUIENRCXUSNZ-UHFFFAOYSA-N",
      "Smiles": "FC(c1cc([nH]c1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cc([nH]c1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "HOMO": "-6.49",
      "LUMO": "-3.15",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "2.33",
      "E00T1": "2.02",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.8",
      "DipoleMomentS1": "4.8",
      "DipoleMomentT1": "4.25",
      "ionizationPotential": "7.63",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DKFOVBLMNGCCIC-FMQUCBEESA-N",
      "Smiles": "Fc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)F",
      "HOMO": "-6.04",
      "LUMO": "-3.28",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "2.04",
      "E00T1": "",
      "Eox": "1.1",
      "Ered": "",
      "ES1ox": "-0.94",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.96",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DKTALCSEAGZQJV-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)S)c1scc(n1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)S)c1scc(n1)S",
      "HOMO": "-6.41",
      "LUMO": "-3.3",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "2.47",
      "E00T1": "1.79",
      "Eox": "1.75",
      "Ered": "",
      "ES1ox": "-0.72",
      "ES1red": "",
      "ET1ox": "-0.04",
      "ET1red": "",
      "DipoleMomentS0": "1.8",
      "DipoleMomentS1": "1.8",
      "DipoleMomentT1": "18.68",
      "ionizationPotential": "7.41",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DLENVYASMXCDEG-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(s1)C(F)(F)F)(F)F",
      "HOMO": "-7.26",
      "LUMO": "-3.41",
      "VEE": "2.59",
      "Lmax": "478.687258687259",
      "E00S1": "3.01",
      "E00T1": "2.4",
      "Eox": "2.46",
      "Ered": "",
      "ES1ox": "-0.55",
      "ES1red": "",
      "ET1ox": "0.06",
      "ET1red": "",
      "DipoleMomentS0": "2.17",
      "DipoleMomentS1": "2.17",
      "DipoleMomentT1": "1.32",
      "ionizationPotential": "8.36",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DMHBFYSYKNLTLA-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(o1)OC",
      "HOMO": "-5.84",
      "LUMO": "-3",
      "VEE": "1.89",
      "Lmax": "655.978835978836",
      "E00S1": "2.16",
      "E00T1": "1.68",
      "Eox": "-4.22",
      "Ered": "",
      "ES1ox": "-6.38",
      "ES1red": "",
      "ET1ox": "-5.9",
      "ET1red": "",
      "DipoleMomentS0": "0.38",
      "DipoleMomentS1": "0.38",
      "DipoleMomentT1": "3.05",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DMIBVWGGWBJDKC-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)O)c1ccc(s1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)O)c1ccc(s1)O",
      "HOMO": "-6.08",
      "LUMO": "-3.05",
      "VEE": "2.17",
      "Lmax": "571.336405529954",
      "E00S1": "2.32",
      "E00T1": "1.81",
      "Eox": "1.34",
      "Ered": "",
      "ES1ox": "-0.98",
      "ES1red": "",
      "ET1ox": "-0.47",
      "ET1red": "",
      "DipoleMomentS0": "7.03",
      "DipoleMomentS1": "7.03",
      "DipoleMomentT1": "11.29",
      "ionizationPotential": "6.99",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DMJLAWMFXJTEEQ-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(s1)c1cc2c3cc(c4ccc(s4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)F)cc(c5ccc4)c1ccc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(s1)c1cc2c3cc(c4ccc(s4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)F)cc(c5ccc4)c1ccc(s1)F",
      "HOMO": "-5.4",
      "LUMO": "-2.61",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "1.95",
      "E00T1": "1.14",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "-1.4",
      "ES1red": "",
      "ET1ox": "-0.59",
      "ET1red": "",
      "DipoleMomentS0": "1.85",
      "DipoleMomentS1": "1.85",
      "DipoleMomentT1": "1.69",
      "ionizationPotential": "6.17",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DMLAVOWQYNRWNQ-BUHFOSPRSA-N",
      "Smiles": "c1ccc(cc1)/N=N/c1ccccc1",
      "MolView_url": "http://molview.org/?q=c1ccc(cc1)/N=N/c1ccccc1",
      "HOMO": "-6.85",
      "LUMO": "-2.55",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "1.15",
      "Eox": "1.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.5",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.2",
      "ionizationPotential": "7.63",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "DMLAVOWQYNRWNQ-YPKPFQOOSA-N",
      "Smiles": "c1ccc(cc1)/N=N\\c1ccccc1",
      "MolView_url": "http://molview.org/?q=c1ccc(cc1)/N=N\\c1ccccc1",
      "HOMO": "-6.51",
      "LUMO": "-2.26",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "",
      "E00T1": "0.62",
      "Eox": "1.34",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.72",
      "ET1red": "",
      "DipoleMomentS0": "4.58",
      "DipoleMomentS1": "4.58",
      "DipoleMomentT1": "3.21",
      "ionizationPotential": "7.34",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "DMRPUUKKHXUDTN-UHFFFAOYSA-N",
      "Smiles": "FC#Cc1cc2c3cc(C#CF)c4c5c3c(c3c2c2c1cccc2c(c3)C#CF)cc(c5ccc4)C#CF",
      "MolView_url": "http://molview.org/?q=FC#Cc1cc2c3cc(C#CF)c4c5c3c(c3c2c2c1cccc2c(c3)C#CF)cc(c5ccc4)C#CF",
      "HOMO": "-5.58",
      "LUMO": "-2.78",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "2.18",
      "E00T1": "1.13",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.35",
      "ES1red": "",
      "ET1ox": "-0.3",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.47",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DMTLMOZZRPENQI-DQSJHHFOSA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)C(F)(F)F)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)C(F)(F)F)C(F)(F)F",
      "HOMO": "-6.11",
      "LUMO": "-3.1",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.81",
      "ET1red": "",
      "DipoleMomentS0": "5.69",
      "DipoleMomentS1": "5.69",
      "DipoleMomentT1": "4.05",
      "ionizationPotential": "6.95",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "DMZYAVYCKNYTIZ-UCZXZSEYSA-N",
      "Smiles": "F/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/F",
      "MolView_url": "http://molview.org/?q=F/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/F",
      "HOMO": "-6.53",
      "LUMO": "-3.37",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.21",
      "ET1red": "",
      "DipoleMomentS0": "4.39",
      "DipoleMomentS1": "4.39",
      "DipoleMomentT1": "4.78",
      "ionizationPotential": "7.54",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "DMZYAVYCKNYTIZ-ZPHADDHFSA-N",
      "Smiles": "F/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\F",
      "MolView_url": "http://molview.org/?q=F/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\F",
      "HOMO": "-6.5",
      "LUMO": "-3.34",
      "VEE": "1.43",
      "Lmax": "866.993006993007",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.18",
      "ET1red": "",
      "DipoleMomentS0": "2.48",
      "DipoleMomentS1": "2.48",
      "DipoleMomentT1": "1.54",
      "ionizationPotential": "7.52",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "DNBUXXPGELBGKQ-YHZPTAEISA-N",
      "Smiles": "FC(Oc1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-6.59",
      "LUMO": "-2.72",
      "VEE": "2.57",
      "Lmax": "482.412451361868",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.25",
      "DipoleMomentS1": "3.25",
      "DipoleMomentT1": "2.12",
      "ionizationPotential": "7.27",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "DNCKDXKSORHMQT-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(s1)c1cc2c3cc(c4ccc(s4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)C#N)cc(c5ccc4)c1ccc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(s1)c1cc2c3cc(c4ccc(s4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)C#N)cc(c5ccc4)c1ccc(s1)C#N",
      "HOMO": "-5.66",
      "LUMO": "-2.91",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "1.91",
      "E00T1": "0.23",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "-1.01",
      "ES1red": "",
      "ET1ox": "0.67",
      "ET1red": "",
      "DipoleMomentS0": "8.98",
      "DipoleMomentS1": "8.98",
      "DipoleMomentT1": "7.32",
      "ionizationPotential": "6.8",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DNHXBHFLXMJCSS-UHFFFAOYSA-N",
      "Smiles": "N#CC1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#CC1(C#N)N=N1",
      "HOMO": "-9.18",
      "LUMO": "-3.36",
      "VEE": "3.83",
      "Lmax": "323.707571801567",
      "E00S1": "",
      "E00T1": "",
      "Eox": "4.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.1",
      "DipoleMomentS1": "3.1",
      "DipoleMomentT1": "0.26",
      "ionizationPotential": "11.27",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DNXIASLMFWVXCZ-UHFFFAOYSA-N",
      "Smiles": "N#CC1=CC=C(S1(=O)=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(S1(=O)=O)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(S1(=O)=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(S1(=O)=O)C#N",
      "HOMO": "-7.45",
      "LUMO": "-3.87",
      "VEE": "1.73",
      "Lmax": "716.647398843931",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.19",
      "DipoleMomentS1": "10.19",
      "DipoleMomentT1": "11.24",
      "ionizationPotential": "8.62",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DOHOXEDBRGUCGJ-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ncc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ncc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "HOMO": "-6.56",
      "LUMO": "-3.18",
      "VEE": "2.28",
      "Lmax": "543.771929824561",
      "E00S1": "2.35",
      "E00T1": "2.05",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.61",
      "DipoleMomentS1": "4.61",
      "DipoleMomentT1": "2.57",
      "ionizationPotential": "7.61",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DOKRBLLIFIJKEE-UHFFFAOYSA-N",
      "Smiles": "Oc1nnc(nn1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1nnc(nn1)[C@]1(O)N=N1",
      "HOMO": "-7.68",
      "LUMO": "-3.56",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.16",
      "E00T1": "1.48",
      "Eox": "2.8",
      "Ered": "",
      "ES1ox": "0.64",
      "ES1red": "",
      "ET1ox": "1.32",
      "ET1red": "",
      "DipoleMomentS0": "5.34",
      "DipoleMomentS1": "5.34",
      "DipoleMomentT1": "5.25",
      "ionizationPotential": "9.09",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DOUDWFHYHKDFGA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc([nH]1)O)C#N)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc([nH]1)O)C#N)c1ccc([nH]1)O",
      "HOMO": "-5.66",
      "LUMO": "-2.6",
      "VEE": "2.56",
      "Lmax": "484.296875",
      "E00S1": "",
      "E00T1": "1.74",
      "Eox": "-1.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.11",
      "ET1red": "",
      "DipoleMomentS0": "2.92",
      "DipoleMomentS1": "2.92",
      "DipoleMomentT1": "10.48",
      "ionizationPotential": "6.93",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DOYKZRSTVHQBTJ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cc1)OC)C#N)c1ccc(cc1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cc1)OC)C#N)c1ccc(cc1)OC",
      "HOMO": "-6.61",
      "LUMO": "-2.43",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.72",
      "DipoleMomentS1": "3.72",
      "DipoleMomentT1": "8.64",
      "ionizationPotential": "7.61",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DOYMAAFUYSNDCW-VXPUYCOJSA-N",
      "Smiles": "[O-][N+](=O)C1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)[N+](=O)[O-]",
      "HOMO": "-6.04",
      "LUMO": "-3.64",
      "VEE": "2.27",
      "Lmax": "546.167400881057",
      "E00S1": "",
      "E00T1": "-0.02",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.02",
      "ET1red": "",
      "DipoleMomentS0": "6.91",
      "DipoleMomentS1": "6.91",
      "DipoleMomentT1": "0.38",
      "ionizationPotential": "7.01",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "DOZPJUPFZVZAKW-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(o1)F)C#N)c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(o1)F)C#N)c1ccc(o1)F",
      "HOMO": "-6.56",
      "LUMO": "-2.64",
      "VEE": "3.16",
      "Lmax": "392.341772151899",
      "E00S1": "",
      "E00T1": "2.45",
      "Eox": "1.55",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.9",
      "ET1red": "",
      "DipoleMomentS0": "4.64",
      "DipoleMomentS1": "4.64",
      "DipoleMomentT1": "6.35",
      "ionizationPotential": "7.95",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DPGRDUGGUUXXKR-UHFFFAOYSA-N",
      "Smiles": "Clc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)Cl",
      "HOMO": "-5.57",
      "LUMO": "-2.81",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "2.1",
      "E00T1": "1.14",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.38",
      "ES1red": "",
      "ET1ox": "-0.42",
      "ET1red": "",
      "DipoleMomentS0": "6.54",
      "DipoleMomentS1": "6.54",
      "DipoleMomentT1": "6.6",
      "ionizationPotential": "6.39",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "DPPNCCYTEIOSKD-FOCLMDBBSA-N",
      "Smiles": "COc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)OC",
      "MolView_url": "http://molview.org/?q=COc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)OC",
      "HOMO": "-6.38",
      "LUMO": "-3.33",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "2.12",
      "E00T1": "",
      "Eox": "1.44",
      "Ered": "",
      "ES1ox": "-0.68",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.34",
      "DipoleMomentS1": "0.34",
      "DipoleMomentT1": "4.09",
      "ionizationPotential": "7.14",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DPPZROBNKRXOMY-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc([nH]1)OC)C#N)c1ccc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc([nH]1)OC)C#N)c1ccc([nH]1)OC",
      "HOMO": "-5.63",
      "LUMO": "-2.61",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.28",
      "DipoleMomentS1": "3.28",
      "DipoleMomentT1": "11.23",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DPRIFTDICSSESH-UHFFFAOYSA-N",
      "Smiles": "C#Cc1cc2c3cc(C#C)c4c5c3c(c3c2c2c1cccc2c(c3)C#C)cc(c5ccc4)C#C",
      "MolView_url": "http://molview.org/?q=C#Cc1cc2c3cc(C#C)c4c5c3c(c3c2c2c1cccc2c(c3)C#C)cc(c5ccc4)C#C",
      "HOMO": "-5.65",
      "LUMO": "-2.89",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "1.11",
      "Eox": "0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.22",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.43",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DPUPUKXISYIQRA-UHFFFAOYSA-N",
      "Smiles": "c1ccc([nH]1)c1cc2c3cc(c4ccc[nH]4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc[nH]1)cc(c5ccc4)c1ccc[nH]1",
      "MolView_url": "http://molview.org/?q=c1ccc([nH]1)c1cc2c3cc(c4ccc[nH]4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc[nH]1)cc(c5ccc4)c1ccc[nH]1",
      "HOMO": "-5.13",
      "LUMO": "-2.42",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "1.9",
      "E00T1": "1.12",
      "Eox": "0.33",
      "Ered": "",
      "ES1ox": "-1.57",
      "ES1red": "",
      "ET1ox": "-0.79",
      "ET1red": "",
      "DipoleMomentS0": "2.74",
      "DipoleMomentS1": "2.74",
      "DipoleMomentT1": "2.55",
      "ionizationPotential": "5.81",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DQKWAGPBERJFRU-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(nc1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1ccc(nc1)[C@]1(O)N=N1",
      "HOMO": "-6.85",
      "LUMO": "-2.28",
      "VEE": "3.14",
      "Lmax": "394.84076433121",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.94",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.1",
      "DipoleMomentS1": "7.1",
      "DipoleMomentT1": "9.75",
      "ionizationPotential": "8.25",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DQSYRDUEZRUTBK-VXPUYCOJSA-N",
      "Smiles": "Fc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)F",
      "HOMO": "-6.07",
      "LUMO": "-3.26",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.87",
      "ET1red": "",
      "DipoleMomentS0": "10.58",
      "DipoleMomentS1": "10.58",
      "DipoleMomentT1": "8.32",
      "ionizationPotential": "6.92",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "DRUGBLSBUAHKDY-UHFFFAOYSA-N",
      "Smiles": "Sc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)S",
      "HOMO": "-5.34",
      "LUMO": "-2.48",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.2",
      "E00T1": "1.2",
      "Eox": "0.5",
      "Ered": "",
      "ES1ox": "-1.7",
      "ES1red": "",
      "ET1ox": "-0.7",
      "ET1red": "",
      "DipoleMomentS0": "5.39",
      "DipoleMomentS1": "5.39",
      "DipoleMomentT1": "4.89",
      "ionizationPotential": "6.12",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "DSEATRZWWODIDE-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)OC(F)(F)F)cc(c5ccc4)c1ccc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)OC(F)(F)F)cc(c5ccc4)c1ccc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-5.57",
      "LUMO": "-2.69",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "2.04",
      "E00T1": "",
      "Eox": "0.7",
      "Ered": "",
      "ES1ox": "-1.34",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.37",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DTIICCIDAMRDMY-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)F)cc(c5ccc4)c1ccc(cc1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)F)cc(c5ccc4)c1ccc(cc1)F",
      "HOMO": "-5.42",
      "LUMO": "-2.45",
      "VEE": "3.05",
      "Lmax": "406.491803278689",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.23",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DTJBCVXBRQXSPJ-QJUDHZBZSA-N",
      "Smiles": "CO/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N\\OC",
      "MolView_url": "http://molview.org/?q=CO/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N\\OC",
      "HOMO": "-7.05",
      "LUMO": "-3.24",
      "VEE": "2.53",
      "Lmax": "490.0395256917",
      "E00S1": "2.99",
      "E00T1": "",
      "Eox": "2.12",
      "Ered": "",
      "ES1ox": "-0.87",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.16",
      "DipoleMomentS1": "1.16",
      "DipoleMomentT1": "9.94",
      "ionizationPotential": "8",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DTRVHNIXHIOQED-PVVPRHBSSA-N",
      "Smiles": "S/C=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=C/S",
      "MolView_url": "http://molview.org/?q=S/C=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=C/S",
      "HOMO": "-5.82",
      "LUMO": "-2.44",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "0.47",
      "Eox": "0.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.46",
      "ET1red": "",
      "DipoleMomentS0": "5.68",
      "DipoleMomentS1": "5.68",
      "DipoleMomentT1": "5.2",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "DTRVHNIXHIOQED-TXCZWMRLSA-N",
      "Smiles": "S/C=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/S",
      "MolView_url": "http://molview.org/?q=S/C=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/S",
      "HOMO": "-5.95",
      "LUMO": "-2.68",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.14",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.08",
      "ET1red": "",
      "DipoleMomentS0": "1.7",
      "DipoleMomentS1": "1.7",
      "DipoleMomentT1": "4.82",
      "ionizationPotential": "6.83",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "DUCHRJPZRKLKEJ-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-5.54",
      "LUMO": "-2.61",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "1.23",
      "Eox": "0.75",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.48",
      "ET1red": "",
      "DipoleMomentS0": "4.54",
      "DipoleMomentS1": "4.54",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "DVAGMTYTUNLFFX-UHFFFAOYSA-N",
      "Smiles": "C/N=C\\[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=C/N=C\\[C@]1(C)N=N1",
      "HOMO": "-7.52",
      "LUMO": "-2",
      "VEE": "3.36",
      "Lmax": "368.988095238095",
      "E00S1": "3.37",
      "E00T1": "2.71",
      "Eox": "2.47",
      "Ered": "",
      "ES1ox": "-0.9",
      "ES1red": "",
      "ET1ox": "-0.24",
      "ET1red": "",
      "DipoleMomentS0": "4.38",
      "DipoleMomentS1": "4.38",
      "DipoleMomentT1": "6.41",
      "ionizationPotential": "8.9",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DVCGEJNCWNRFST-UHFFFAOYSA-N",
      "Smiles": "N#Cc1csc(n1)c1cc2c3cc(c4scc(n4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)C#N)cc(c5ccc4)c1scc(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1csc(n1)c1cc2c3cc(c4scc(n4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)C#N)cc(c5ccc4)c1scc(n1)C#N",
      "HOMO": "-5.82",
      "LUMO": "-3.04",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "",
      "E00T1": "0.26",
      "Eox": "0.98",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.72",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.25",
      "ionizationPotential": "6.96",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "DVHIPPMCDBFBNZ-UHFFFAOYSA-N",
      "Smiles": "COc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)OC",
      "HOMO": "-5.43",
      "LUMO": "-2.73",
      "VEE": "1.45",
      "Lmax": "855.034482758621",
      "E00S1": "2.06",
      "E00T1": "",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "-1.52",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.32",
      "DipoleMomentS1": "1.32",
      "DipoleMomentT1": "0.8",
      "ionizationPotential": "6.16",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "DVRXPAXGMPLRHV-ISLYRVAYSA-N",
      "Smiles": "Sc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)S",
      "HOMO": "-5.34",
      "LUMO": "-2.86",
      "VEE": "2.56",
      "Lmax": "484.296875",
      "E00S1": "1.82",
      "E00T1": "0.63",
      "Eox": "0.44",
      "Ered": "",
      "ES1ox": "-1.38",
      "ES1red": "",
      "ET1ox": "-0.19",
      "ET1red": "",
      "DipoleMomentS0": "1.62",
      "DipoleMomentS1": "1.62",
      "DipoleMomentT1": "1.12",
      "ionizationPotential": "6.01",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DWBKTFMANYMNOE-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(nc1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)[C@]1(C#N)N=N1",
      "HOMO": "-7.96",
      "LUMO": "-2.89",
      "VEE": "3.57",
      "Lmax": "347.282913165266",
      "E00S1": "3.43",
      "E00T1": "2.85",
      "Eox": "3.03",
      "Ered": "",
      "ES1ox": "-0.4",
      "ES1red": "",
      "ET1ox": "0.18",
      "ET1red": "",
      "DipoleMomentS0": "0.82",
      "DipoleMomentS1": "0.82",
      "DipoleMomentT1": "4.95",
      "ionizationPotential": "9.61",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "DWBXMEBCPJHATR-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)Cl)c1nnc(nn1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)Cl)c1nnc(nn1)Cl",
      "HOMO": "-7.55",
      "LUMO": "-3.58",
      "VEE": "1.56",
      "Lmax": "794.74358974359",
      "E00S1": "2.09",
      "E00T1": "1.41",
      "Eox": "2.47",
      "Ered": "",
      "ES1ox": "0.38",
      "ES1red": "",
      "ET1ox": "1.06",
      "ET1red": "",
      "DipoleMomentS0": "2.74",
      "DipoleMomentS1": "2.74",
      "DipoleMomentT1": "2.83",
      "ionizationPotential": "8.47",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DWPPXPFPPXHZNP-FMQUCBEESA-N",
      "Smiles": "O=Cn1c(S)ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)S",
      "MolView_url": "http://molview.org/?q=O=Cn1c(S)ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)S",
      "HOMO": "-5.51",
      "LUMO": "-2.94",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "1.84",
      "E00T1": "0.64",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "-1.35",
      "ES1red": "",
      "ET1ox": "-0.15",
      "ET1red": "",
      "DipoleMomentS0": "5.39",
      "DipoleMomentS1": "5.39",
      "DipoleMomentT1": "4.76",
      "ionizationPotential": "6.17",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DWXFAINNEFVMHV-UHFFFAOYSA-N",
      "Smiles": "O=Cn1cccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cccn1C=O",
      "MolView_url": "http://molview.org/?q=O=Cn1cccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cccn1C=O",
      "HOMO": "-5.55",
      "LUMO": "-2.54",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.12",
      "E00T1": "1.25",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.4",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "5.61",
      "DipoleMomentS1": "5.61",
      "DipoleMomentT1": "5.77",
      "ionizationPotential": "6.42",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "DWXOIKGIKKZBBN-ISLYRVAYSA-N",
      "Smiles": "c1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1ncco1",
      "MolView_url": "http://molview.org/?q=c1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1ncco1",
      "HOMO": "-6.05",
      "LUMO": "-3.17",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "2.16",
      "E00T1": "",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "-1.03",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.28",
      "DipoleMomentS1": "1.28",
      "DipoleMomentT1": "1.74",
      "ionizationPotential": "6.85",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DXGLUYLBMQDCEQ-UHFFFAOYSA-N",
      "Smiles": "FC(C1=CC=C(S1(=O)=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(S1(=O)=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "HOMO": "-7.46",
      "LUMO": "-3.68",
      "VEE": "1.84",
      "Lmax": "673.804347826087",
      "E00S1": "",
      "E00T1": "2.22",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "11.74",
      "DipoleMomentS1": "11.74",
      "DipoleMomentT1": "13.33",
      "ionizationPotential": "8.63",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "DXOZHDBZUVHEKT-FMQUCBEESA-N",
      "Smiles": "Cc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)C",
      "HOMO": "-5.89",
      "LUMO": "-3.12",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "2.06",
      "E00T1": "0.79",
      "Eox": "0.94",
      "Ered": "",
      "ES1ox": "-1.12",
      "ES1red": "",
      "ET1ox": "0.15",
      "ET1red": "",
      "DipoleMomentS0": "3.05",
      "DipoleMomentS1": "3.05",
      "DipoleMomentT1": "3.06",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DYSPKLURPVOAHI-DQRAZIAOSA-N",
      "Smiles": "Cn1cccc1c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1cccn1C",
      "MolView_url": "http://molview.org/?q=Cn1cccc1c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1cccn1C",
      "HOMO": "-5.19",
      "LUMO": "-2.3",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "1.36",
      "E00T1": "0.31",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.54",
      "DipoleMomentS1": "9.54",
      "DipoleMomentT1": "6.8",
      "ionizationPotential": "4.94",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "DYUIOFTWGALLIW-VHXPQNKSSA-N",
      "Smiles": "Cc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)C",
      "HOMO": "-5.91",
      "LUMO": "-2.98",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "0.94",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.74",
      "ET1red": "",
      "DipoleMomentS0": "8.5",
      "DipoleMomentS1": "8.5",
      "DipoleMomentT1": "7.81",
      "ionizationPotential": "6.41",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "DYVXZQJBVSBOAD-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc([nH]1)C(F)(F)F)C#N)c1ccc([nH]1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc([nH]1)C(F)(F)F)C#N)c1ccc([nH]1)C(F)(F)F",
      "HOMO": "-6.53",
      "LUMO": "-3",
      "VEE": "3.2",
      "Lmax": "387.4375",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.93",
      "DipoleMomentS1": "2.93",
      "DipoleMomentT1": "5.87",
      "ionizationPotential": "8.04",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "DYXPEUNJLDHVIE-QURGRASLSA-N",
      "Smiles": "Cc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)C",
      "HOMO": "-5.1",
      "LUMO": "-2.63",
      "VEE": "2.52",
      "Lmax": "491.984126984127",
      "E00S1": "1.83",
      "E00T1": "0.65",
      "Eox": "0.23",
      "Ered": "",
      "ES1ox": "-1.6",
      "ES1red": "",
      "ET1ox": "-0.42",
      "ET1red": "",
      "DipoleMomentS0": "4.15",
      "DipoleMomentS1": "4.15",
      "DipoleMomentT1": "5.6",
      "ionizationPotential": "5.8",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "DZBGQSJTOWIAFG-VHXPQNKSSA-N",
      "Smiles": "Sc1ccc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc([nH]1)S",
      "HOMO": "-5.82",
      "LUMO": "-2.44",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.27",
      "ET1red": "",
      "DipoleMomentS0": "2.79",
      "DipoleMomentS1": "2.79",
      "DipoleMomentT1": "2.5",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "DZBGQSJTOWIAFG-WCWDXBQESA-N",
      "Smiles": "Sc1ccc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc([nH]1)S",
      "HOMO": "-5.84",
      "LUMO": "-2.66",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "1.36",
      "DipoleMomentS1": "1.36",
      "DipoleMomentT1": "4.35",
      "ionizationPotential": "6.4",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "DZTQQVNYOFZVEO-UHFFFAOYSA-N",
      "Smiles": "Cn1c(C)ccc1[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cn1c(C)ccc1[C@]1(C)N=N1",
      "HOMO": "-6.07",
      "LUMO": "-1.97",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "2.87",
      "E00T1": "1.95",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "-1.9",
      "ES1red": "",
      "ET1ox": "-0.98",
      "ET1red": "",
      "DipoleMomentS0": "4.48",
      "DipoleMomentS1": "4.48",
      "DipoleMomentT1": "11.91",
      "ionizationPotential": "7.18",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FADBWTVPDJZQOE-UHFFFAOYSA-N",
      "Smiles": "FC1(F)N=N1",
      "MolView_url": "http://molview.org/?q=FC1(F)N=N1",
      "HOMO": "-8.9",
      "LUMO": "-3.08",
      "VEE": "3.17",
      "Lmax": "391.104100946372",
      "E00S1": "3.32",
      "E00T1": "2.62",
      "Eox": "4",
      "Ered": "",
      "ES1ox": "0.68",
      "ES1red": "",
      "ET1ox": "1.38",
      "ET1red": "",
      "DipoleMomentS0": "0.02",
      "DipoleMomentS1": "0.02",
      "DipoleMomentT1": "1.26",
      "ionizationPotential": "11.15",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FBBHPJKXRHPJME-UHFFFAOYSA-N",
      "Smiles": "N#Cc1coc(n1)c1cc2c3cc(c4occ(n4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)C#N)cc(c5ccc4)c1occ(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1coc(n1)c1cc2c3cc(c4occ(n4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)C#N)cc(c5ccc4)c1occ(n1)C#N",
      "HOMO": "-5.87",
      "LUMO": "-3.18",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "1.86",
      "E00T1": "0.26",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "-0.82",
      "ES1red": "",
      "ET1ox": "0.78",
      "ET1red": "",
      "DipoleMomentS0": "1.57",
      "DipoleMomentS1": "1.57",
      "DipoleMomentT1": "3.88",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "FBJIPPYIQQVSCM-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)F)c1ncc(o1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)F)c1ncc(o1)F",
      "HOMO": "-6.93",
      "LUMO": "-3.32",
      "VEE": "2.24",
      "Lmax": "553.482142857143",
      "E00S1": "",
      "E00T1": "2.27",
      "Eox": "2.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.2",
      "ET1red": "",
      "DipoleMomentS0": "0.57",
      "DipoleMomentS1": "0.57",
      "DipoleMomentT1": "4.84",
      "ionizationPotential": "8.01",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FBPCKUXOKFDCQC-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(o1)c1cc2c3cc(c4ncc(o4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)OC(F)(F)F)cc(c5ccc4)c1ncc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(o1)c1cc2c3cc(c4ncc(o4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)OC(F)(F)F)cc(c5ccc4)c1ncc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-5.74",
      "LUMO": "-3.06",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "1.87",
      "E00T1": "0.22",
      "Eox": "-4.32",
      "Ered": "",
      "ES1ox": "-6.19",
      "ES1red": "",
      "ET1ox": "-4.54",
      "ET1red": "",
      "DipoleMomentS0": "0.61",
      "DipoleMomentS1": "0.61",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.47",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "FBSVTAZTGYJHQT-MYUULXRZSA-N",
      "Smiles": "O/N=C\\c1ccc(o1)/N=N\\c1ccc(o1)/C=N/O",
      "MolView_url": "http://molview.org/?q=O/N=C\\c1ccc(o1)/N=N\\c1ccc(o1)/C=N/O",
      "HOMO": "-6.27",
      "LUMO": "-3.26",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "2.31",
      "E00T1": "0.79",
      "Eox": "1.39",
      "Ered": "",
      "ES1ox": "-0.92",
      "ES1red": "",
      "ET1ox": "0.6",
      "ET1red": "",
      "DipoleMomentS0": "4.48",
      "DipoleMomentS1": "4.48",
      "DipoleMomentT1": "4.57",
      "ionizationPotential": "7.25",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "FCABJUKNJGGPLA-DQSJHHFOSA-N",
      "Smiles": "FC(OC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)OC(F)(F)F)(F)F",
      "HOMO": "-5.49",
      "LUMO": "-2.97",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "-0.02",
      "Eox": "0.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.49",
      "ET1red": "",
      "DipoleMomentS0": "5.3",
      "DipoleMomentS1": "5.3",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.22",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "FCKAIPCCHIPBQY-UHFFFAOYSA-N",
      "Smiles": "ClC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)Cl",
      "HOMO": "-5.23",
      "LUMO": "-2.46",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.86",
      "E00T1": "1.1",
      "Eox": "0.46",
      "Ered": "",
      "ES1ox": "-1.4",
      "ES1red": "",
      "ET1ox": "-0.64",
      "ET1red": "",
      "DipoleMomentS0": "1.97",
      "DipoleMomentS1": "1.97",
      "DipoleMomentT1": "1.31",
      "ionizationPotential": "6",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FCQOIQJKSHRUEF-UHFFFAOYSA-N",
      "Smiles": "Cl[B@@-]12n3c4cc5n1c(cc1-c6c(-c([n+]21)cc3c1c4ccc(c1)c1ncccn1)ccc(c6)c1ncccn1)c1c5cc(cc1)c1ncccn1",
      "MolView_url": "http://molview.org/?q=Cl[B@@-]12n3c4cc5n1c(cc1-c6c(-c([n+]21)cc3c1c4ccc(c1)c1ncccn1)ccc(c6)c1ncccn1)c1c5cc(cc1)c1ncccn1",
      "HOMO": "-5.64",
      "LUMO": "-2.54",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "1.58",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.77",
      "ET1red": "",
      "DipoleMomentS0": "9.22",
      "DipoleMomentS1": "9.22",
      "DipoleMomentT1": "10.12",
      "ionizationPotential": "0.05",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "FDUFHUXDVCUIQP-UHFFFAOYSA-N",
      "Smiles": "Sc1nnc(nn1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1nnc(nn1)[C@]1(S)N=N1",
      "HOMO": "-7.21",
      "LUMO": "-3.47",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.45",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.2",
      "DipoleMomentS1": "4.2",
      "DipoleMomentT1": "4.28",
      "ionizationPotential": "8.82",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FEGCFUYWUZVGBH-QPLCGJKRSA-N",
      "Smiles": "Fc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)F",
      "HOMO": "-5.77",
      "LUMO": "-2.38",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "",
      "E00T1": "0.52",
      "Eox": "0.88",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.36",
      "ET1red": "",
      "DipoleMomentS0": "4.24",
      "DipoleMomentS1": "4.24",
      "DipoleMomentT1": "4.73",
      "ionizationPotential": "6.51",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "FEKXJSXJFBVHGA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(s1)[N+](=O)[O-])C#N)c1ccc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(s1)[N+](=O)[O-])C#N)c1ccc(s1)[N+](=O)[O-]",
      "HOMO": "-7.25",
      "LUMO": "-3.75",
      "VEE": "3.67",
      "Lmax": "337.820163487738",
      "E00S1": "",
      "E00T1": "1.81",
      "Eox": "-0.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.06",
      "ET1red": "",
      "DipoleMomentS0": "3.48",
      "DipoleMomentS1": "3.48",
      "DipoleMomentT1": "5.69",
      "ionizationPotential": "8.98",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FEXMJCWMJCYADW-ISLYRVAYSA-N",
      "Smiles": "O=S1(=O)C=CC=C1c1ccc(o1)/N=N/c1ccc(o1)C1=CC=CS1(=O)=O",
      "MolView_url": "http://molview.org/?q=O=S1(=O)C=CC=C1c1ccc(o1)/N=N/c1ccc(o1)C1=CC=CS1(=O)=O",
      "HOMO": "-6.12",
      "LUMO": "-3.59",
      "VEE": "2.52",
      "Lmax": "491.984126984127",
      "E00S1": "1.86",
      "E00T1": "0.44",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "-0.71",
      "ES1red": "",
      "ET1ox": "0.71",
      "ET1red": "",
      "DipoleMomentS0": "9.91",
      "DipoleMomentS1": "9.91",
      "DipoleMomentT1": "10.61",
      "ionizationPotential": "6.99",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "FFAWXNYWFOGSDD-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)[N+](=O)[O-]",
      "HOMO": "-5.7",
      "LUMO": "-3.03",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "2.06",
      "E00T1": "1.14",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "-1.06",
      "ES1red": "",
      "ET1ox": "-0.14",
      "ET1red": "",
      "DipoleMomentS0": "16.13",
      "DipoleMomentS1": "16.13",
      "DipoleMomentT1": "16.78",
      "ionizationPotential": "6.73",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FFCZGWQMYLMFNQ-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)OC",
      "HOMO": "-5.24",
      "LUMO": "-2.41",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.52",
      "DipoleMomentS1": "2.52",
      "DipoleMomentT1": "3.55",
      "ionizationPotential": "5.84",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FFIFBLGISSNFML-ISLYRVAYSA-N",
      "Smiles": "Oc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=Oc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)O",
      "HOMO": "-6.92",
      "LUMO": "-3.22",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.43",
      "ionizationPotential": "7.7",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "FFIFBLGISSNFML-ZCXUNETKSA-N",
      "Smiles": "Oc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=Oc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)O",
      "HOMO": "-6.69",
      "LUMO": "-3.21",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "2.08",
      "E00T1": "1.41",
      "Eox": "1.58",
      "Ered": "",
      "ES1ox": "-0.5",
      "ES1red": "",
      "ET1ox": "0.17",
      "ET1red": "",
      "DipoleMomentS0": "4.49",
      "DipoleMomentS1": "4.49",
      "DipoleMomentT1": "4.19",
      "ionizationPotential": "7.45",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "FFKZWMBFXPCWDM-UHFFFAOYSA-N",
      "Smiles": "Cc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)C",
      "MolView_url": "http://molview.org/?q=Cc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)C",
      "HOMO": "-5.21",
      "LUMO": "-2.38",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.17",
      "E00T1": "1.18",
      "Eox": "0.43",
      "Ered": "",
      "ES1ox": "-1.74",
      "ES1red": "",
      "ET1ox": "-0.75",
      "ET1red": "",
      "DipoleMomentS0": "2.88",
      "DipoleMomentS1": "2.88",
      "DipoleMomentT1": "3.51",
      "ionizationPotential": "5.91",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FFZGUDADPZIRPU-ZIRPIMHASA-N",
      "Smiles": "N#C/C=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=C\\C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=C\\C#N",
      "HOMO": "-6.58",
      "LUMO": "-2.91",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "0.51",
      "Eox": "1.51",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1",
      "ET1red": "",
      "DipoleMomentS0": "4.99",
      "DipoleMomentS1": "4.99",
      "DipoleMomentT1": "5.94",
      "ionizationPotential": "7.59",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "FGBYDGDHFSDRFR-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(cc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cc1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(cc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cc1)C#N",
      "HOMO": "-7.22",
      "LUMO": "-3.24",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "3.09",
      "E00T1": "",
      "Eox": "2.6",
      "Ered": "",
      "ES1ox": "-0.49",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.91",
      "DipoleMomentS1": "5.91",
      "DipoleMomentT1": "3.05",
      "ionizationPotential": "8.34",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FGCAMFSWDLDFOV-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(o1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1cnc(o1)[C@]1(S)N=N1",
      "HOMO": "-7.05",
      "LUMO": "-2.57",
      "VEE": "3.13",
      "Lmax": "396.102236421725",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.09",
      "DipoleMomentS1": "3.09",
      "DipoleMomentT1": "9.11",
      "ionizationPotential": "8.17",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FIEWUSOXTJIAHQ-QURGRASLSA-N",
      "Smiles": "N#Cc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)C#N",
      "HOMO": "-6.23",
      "LUMO": "-3.35",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "1.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.61",
      "ET1red": "",
      "DipoleMomentS0": "16.2",
      "DipoleMomentS1": "16.2",
      "DipoleMomentT1": "16.5",
      "ionizationPotential": "7.26",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "FIGWLDVQDYKAQQ-UHFFFAOYSA-N",
      "Smiles": "FC(c1c[nH]c(c1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1c[nH]c(c1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-6.99",
      "LUMO": "-2.71",
      "VEE": "3.61",
      "Lmax": "343.434903047091",
      "E00S1": "2.79",
      "E00T1": "2.21",
      "Eox": "2.08",
      "Ered": "",
      "ES1ox": "-0.71",
      "ES1red": "",
      "ET1ox": "-0.13",
      "ET1red": "",
      "DipoleMomentS0": "3.96",
      "DipoleMomentS1": "3.96",
      "DipoleMomentT1": "8.72",
      "ionizationPotential": "8.65",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FITMBBCBNRWXHU-UHFFFAOYSA-N",
      "Smiles": "FC(/N=C\\[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/N=C\\[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-9.17",
      "LUMO": "-2.99",
      "VEE": "3.93",
      "Lmax": "315.470737913486",
      "E00S1": "4.03",
      "E00T1": "3.29",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.24",
      "DipoleMomentS1": "2.24",
      "DipoleMomentT1": "2.78",
      "ionizationPotential": "10.87",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FIXNIEAENJSSME-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(o1)C#N)C#N)c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(o1)C#N)C#N)c1ncc(o1)C#N",
      "HOMO": "-7.57",
      "LUMO": "-3.22",
      "VEE": "3.59",
      "Lmax": "345.348189415042",
      "E00S1": "",
      "E00T1": "2.75",
      "Eox": "2.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.05",
      "ET1red": "",
      "DipoleMomentS0": "6.75",
      "DipoleMomentS1": "6.75",
      "DipoleMomentT1": "5.97",
      "ionizationPotential": "9.03",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FJQURIUMDZOSTM-QPLCGJKRSA-N",
      "Smiles": "FC(c1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)C(F)(F)F)(F)F",
      "HOMO": "-6.26",
      "LUMO": "-3.34",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "0.19",
      "Eox": "1.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.99",
      "ET1red": "",
      "DipoleMomentS0": "3.4",
      "DipoleMomentS1": "3.4",
      "DipoleMomentT1": "3.02",
      "ionizationPotential": "7.17",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "FJUFIRJRTWTYOX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)C#N)cc(c5ccc4)c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)C#N)cc(c5ccc4)c1ccc(cn1)C#N",
      "HOMO": "-5.67",
      "LUMO": "-2.91",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "1.93",
      "E00T1": "1.14",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "-1.01",
      "ES1red": "",
      "ET1ox": "-0.22",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "FJWIDGCCELYQDY-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(s1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1ccc(s1)[C@]1(S)N=N1",
      "HOMO": "-6.73",
      "LUMO": "-2.39",
      "VEE": "3.35",
      "Lmax": "370.089552238806",
      "E00S1": "2.87",
      "E00T1": "2.03",
      "Eox": "1.36",
      "Ered": "",
      "ES1ox": "-1.51",
      "ES1red": "",
      "ET1ox": "-0.67",
      "ET1red": "",
      "DipoleMomentS0": "1.09",
      "DipoleMomentS1": "1.09",
      "DipoleMomentT1": "12.3",
      "ionizationPotential": "7.69",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FJYNWUAIMJNLAW-UHFFFAOYSA-N",
      "Smiles": "OC1=CC=C(C1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(C1)[C@]1(O)N=N1",
      "HOMO": "-5.86",
      "LUMO": "-2.13",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.41",
      "DipoleMomentS1": "2.41",
      "DipoleMomentT1": "9.94",
      "ionizationPotential": "7.3",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FKBHNASBBVLYKJ-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)OC",
      "HOMO": "-5.14",
      "LUMO": "-2.42",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "1.85",
      "E00T1": "",
      "Eox": "0.27",
      "Ered": "",
      "ES1ox": "-1.58",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.35",
      "DipoleMomentS1": "4.35",
      "DipoleMomentT1": "",
      "ionizationPotential": "5.71",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FKMUAZBUZZBVDI-FMQUCBEESA-N",
      "Smiles": "Sc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)S",
      "HOMO": "-5.33",
      "LUMO": "-2.7",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "1.97",
      "E00T1": "0.74",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4",
      "DipoleMomentS1": "4",
      "DipoleMomentT1": "5.6",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "FKSZKTDIWCRBOE-UHFFFAOYSA-N",
      "Smiles": "FC1=CC=C(S1(=O)=O)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(S1(=O)=O)[C@]1(F)N=N1",
      "HOMO": "-7.62",
      "LUMO": "-2.98",
      "VEE": "3.36",
      "Lmax": "368.988095238095",
      "E00S1": "2.97",
      "E00T1": "",
      "Eox": "2.68",
      "Ered": "",
      "ES1ox": "-0.29",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.73",
      "DipoleMomentS1": "7.73",
      "DipoleMomentT1": "8.13",
      "ionizationPotential": "9.25",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FLMCOEDMGPGTON-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)F",
      "HOMO": "-5.47",
      "LUMO": "-2.49",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.11",
      "E00T1": "",
      "Eox": "0.64",
      "Ered": "",
      "ES1ox": "-1.47",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.33",
      "DipoleMomentS1": "0.33",
      "DipoleMomentT1": "0.28",
      "ionizationPotential": "6.3",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FLRPEPFPWMZKJY-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(nc1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1cnc(nc1)[C@]1(O)N=N1",
      "HOMO": "-7.15",
      "LUMO": "-2.28",
      "VEE": "3.26",
      "Lmax": "380.306748466258",
      "E00S1": "2.92",
      "E00T1": "2.34",
      "Eox": "2.24",
      "Ered": "",
      "ES1ox": "-0.68",
      "ES1red": "",
      "ET1ox": "-0.1",
      "ET1red": "",
      "DipoleMomentS0": "7.63",
      "DipoleMomentS1": "7.63",
      "DipoleMomentT1": "10.31",
      "ionizationPotential": "8.51",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FLYDYOBHEKLJCG-FMQUCBEESA-N",
      "Smiles": "CC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CC",
      "MolView_url": "http://molview.org/?q=CC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CC",
      "HOMO": "-6.4",
      "LUMO": "-2.76",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "1.08",
      "Eox": "1.59",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.51",
      "ET1red": "",
      "DipoleMomentS0": "0.01",
      "DipoleMomentS1": "0.01",
      "DipoleMomentT1": "4.51",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "FMGAJHUDOSFMNP-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cc1)S)C#N)c1ccc(cc1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cc1)S)C#N)c1ccc(cc1)S",
      "HOMO": "-6.51",
      "LUMO": "-2.5",
      "VEE": "3.27",
      "Lmax": "379.14373088685",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.73",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.63",
      "DipoleMomentS1": "1.63",
      "DipoleMomentT1": "11.37",
      "ionizationPotential": "7.68",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FMMPTTTVNROHAY-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(cn1)C#N",
      "HOMO": "-7.63",
      "LUMO": "-3.44",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "2.48",
      "Eox": "3.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.1",
      "ET1red": "",
      "DipoleMomentS0": "3.54",
      "DipoleMomentS1": "3.54",
      "DipoleMomentT1": "8.03",
      "ionizationPotential": "9.11",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FMSSCUHYZUPUMB-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1[nH]cc(c1)S)C#N)c1[nH]cc(c1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1[nH]cc(c1)S)C#N)c1[nH]cc(c1)S",
      "HOMO": "-5.82",
      "LUMO": "-2.83",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.03",
      "DipoleMomentS1": "2.03",
      "DipoleMomentT1": "10.18",
      "ionizationPotential": "7.33",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FNPGODJEKVTSDT-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)Cl)c1ncc(s1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)Cl)c1ncc(s1)Cl",
      "HOMO": "-6.85",
      "LUMO": "-3.32",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "2.76",
      "E00T1": "1.98",
      "Eox": "2.23",
      "Ered": "",
      "ES1ox": "-0.53",
      "ES1red": "",
      "ET1ox": "0.25",
      "ET1red": "",
      "DipoleMomentS0": "1.48",
      "DipoleMomentS1": "1.48",
      "DipoleMomentT1": "11.3",
      "ionizationPotential": "7.83",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FNWDLOHIWONDBB-DQSJHHFOSA-N",
      "Smiles": "Clc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)Cl",
      "HOMO": "-6.39",
      "LUMO": "-2.55",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "0.56",
      "Eox": "1.34",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.78",
      "ET1red": "",
      "DipoleMomentS0": "5.04",
      "DipoleMomentS1": "5.04",
      "DipoleMomentT1": "4.61",
      "ionizationPotential": "7.03",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "FNXBGHBPLDMSHZ-UHFFFAOYSA-N",
      "Smiles": "COC1=CC=C(S1(=O)=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(S1(=O)=O)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(S1(=O)=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(S1(=O)=O)OC",
      "HOMO": "-6.71",
      "LUMO": "-3.36",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "2.29",
      "E00T1": "1.36",
      "Eox": "1.71",
      "Ered": "",
      "ES1ox": "-0.58",
      "ES1red": "",
      "ET1ox": "0.35",
      "ET1red": "",
      "DipoleMomentS0": "4.99",
      "DipoleMomentS1": "4.99",
      "DipoleMomentT1": "5.18",
      "ionizationPotential": "7.42",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FOFBOIMBLUTGED-VHXPQNKSSA-N",
      "Smiles": "Clc1cc([nH]c1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cc([nH]c1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1[nH]cc(c1)Cl",
      "HOMO": "-5.34",
      "LUMO": "-2.43",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.06",
      "DipoleMomentS1": "7.06",
      "DipoleMomentT1": "3.31",
      "ionizationPotential": "6.2",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "FOOKGYMSWRDMJD-DQRAZIAOSA-N",
      "Smiles": "Sc1ccc([nH]1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc([nH]1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc([nH]1)S",
      "HOMO": "-5.26",
      "LUMO": "-2.39",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "0.29",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.16",
      "DipoleMomentS1": "4.16",
      "DipoleMomentT1": "2.8",
      "ionizationPotential": "5.12",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "FOPSTGCKSBTWEJ-FMQUCBEESA-N",
      "Smiles": "COc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)OC",
      "HOMO": "-5.23",
      "LUMO": "-2.82",
      "VEE": "2.57",
      "Lmax": "482.412451361868",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.35",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.02",
      "DipoleMomentS1": "0.02",
      "DipoleMomentT1": "0.69",
      "ionizationPotential": "5.85",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "FOPSTGCKSBTWEJ-VXPUYCOJSA-N",
      "Smiles": "COc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)OC",
      "HOMO": "-5.27",
      "LUMO": "-2.81",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.68",
      "DipoleMomentS1": "7.68",
      "DipoleMomentT1": "6.93",
      "ionizationPotential": "5.83",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "FOQHCNMSSJDDTD-UHFFFAOYSA-N",
      "Smiles": "OC#Cc1cc(C#N)cc(c1C#N)C#CO",
      "MolView_url": "http://molview.org/?q=OC#Cc1cc(C#N)cc(c1C#N)C#CO",
      "HOMO": "-6.93",
      "LUMO": "-2.52",
      "VEE": "3.57",
      "Lmax": "347.282913165266",
      "E00S1": "",
      "E00T1": "2.86",
      "Eox": "2.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.79",
      "ET1red": "",
      "DipoleMomentS0": "5.24",
      "DipoleMomentS1": "5.24",
      "DipoleMomentT1": "7.36",
      "ionizationPotential": "8.37",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FPGOYHXUTYWCGX-OCEACIFDSA-N",
      "Smiles": "Cc1ncc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cnc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ncc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cnc([nH]1)C",
      "HOMO": "-5.91",
      "LUMO": "-2.66",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.11",
      "DipoleMomentS1": "8.11",
      "DipoleMomentT1": "8.16",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "FPLCRWKPZWYLPN-WXUKJITCSA-N",
      "Smiles": "F/C=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/F",
      "MolView_url": "http://molview.org/?q=F/C=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/F",
      "HOMO": "-5.4",
      "LUMO": "-2.49",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "2.06",
      "E00T1": "",
      "Eox": "0.59",
      "Ered": "",
      "ES1ox": "-1.47",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.26",
      "DipoleMomentS1": "1.26",
      "DipoleMomentT1": "1.11",
      "ionizationPotential": "6.29",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FQFVDEXQMUIUFE-HGANIXKKSA-N",
      "Smiles": "FC(/C=C/c1ccc(o1)/N=N/c1ccc(o1)/C=C/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C/c1ccc(o1)/N=N/c1ccc(o1)/C=C/C(F)(F)F)(F)F",
      "HOMO": "-6.2",
      "LUMO": "-3.24",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "",
      "E00T1": "0.67",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.26",
      "DipoleMomentS1": "4.26",
      "DipoleMomentT1": "4.24",
      "ionizationPotential": "7.25",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "FQGZIJRZZHHLSR-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(cc1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(cc1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.58",
      "LUMO": "-2.75",
      "VEE": "3.31",
      "Lmax": "374.561933534743",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.42",
      "DipoleMomentS1": "0.42",
      "DipoleMomentT1": "4.2",
      "ionizationPotential": "8.74",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FQJIWCXSMRUOBB-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1c[nH]c(c1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1c[nH]c(c1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-7.4",
      "LUMO": "-3.19",
      "VEE": "3.44",
      "Lmax": "360.406976744186",
      "E00S1": "2.82",
      "E00T1": "2.26",
      "Eox": "2.39",
      "Ered": "",
      "ES1ox": "-0.43",
      "ES1red": "",
      "ET1ox": "0.13",
      "ET1red": "",
      "DipoleMomentS0": "4.3",
      "DipoleMomentS1": "4.3",
      "DipoleMomentT1": "7.84",
      "ionizationPotential": "9.16",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FQJMIPDYVCZQNM-WCWDXBQESA-N",
      "Smiles": "Clc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)Cl",
      "HOMO": "-6.24",
      "LUMO": "-3.23",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "2.25",
      "E00T1": "0.89",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "-0.97",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.97",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "FQKDWSVEKCNSHA-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(cc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cc1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(cc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cc1)C(F)(F)F)(F)F",
      "HOMO": "-7.22",
      "LUMO": "-3.19",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "2.85",
      "E00T1": "0.34",
      "Eox": "2.3",
      "Ered": "",
      "ES1ox": "-0.55",
      "ES1red": "",
      "ET1ox": "1.96",
      "ET1red": "",
      "DipoleMomentS0": "3.09",
      "DipoleMomentS1": "3.09",
      "DipoleMomentT1": "2.62",
      "ionizationPotential": "8.28",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FQNRIRQUHXFDIJ-LUKHMWGVSA-N",
      "Smiles": "N#C/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N\\C#N",
      "MolView_url": "http://molview.org/?q=N#C/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N\\C#N",
      "HOMO": "-5.93",
      "LUMO": "-3.43",
      "VEE": "1.43",
      "Lmax": "866.993006993007",
      "E00S1": "1.91",
      "E00T1": "",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "-0.79",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "16.4",
      "DipoleMomentS1": "16.4",
      "DipoleMomentT1": "17.86",
      "ionizationPotential": "7.12",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FQUGBMDNWHIMMT-HSFFGMMNSA-N",
      "Smiles": "N#C/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/C#N",
      "HOMO": "-5.62",
      "LUMO": "-2.94",
      "VEE": "1.45",
      "Lmax": "855.034482758621",
      "E00S1": "1.85",
      "E00T1": "1.08",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.02",
      "ES1red": "",
      "ET1ox": "-0.25",
      "ET1red": "",
      "DipoleMomentS0": "11.85",
      "DipoleMomentS1": "11.85",
      "DipoleMomentT1": "12.95",
      "ionizationPotential": "6.75",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FRNTUFPHTJXXEO-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)O)c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)O)c1nnc(nn1)O",
      "HOMO": "-7.34",
      "LUMO": "-3.42",
      "VEE": "1.6",
      "Lmax": "774.875",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.78",
      "DipoleMomentS1": "2.78",
      "DipoleMomentT1": "2.59",
      "ionizationPotential": "8.29",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FSEIMYBQWITGOR-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)[N+](=O)[O-]",
      "HOMO": "-5.61",
      "LUMO": "-3.21",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.65",
      "E00T1": "1.09",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-0.82",
      "ES1red": "",
      "ET1ox": "-0.26",
      "ET1red": "",
      "DipoleMomentS0": "11.08",
      "DipoleMomentS1": "11.08",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FSHGDHWXDWQWLY-BYYHNAKLSA-N",
      "Smiles": "FC(c1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "HOMO": "-5.6",
      "LUMO": "-2.86",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "2.05",
      "E00T1": "0.76",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "-1.31",
      "ES1red": "",
      "ET1ox": "-0.02",
      "ET1red": "",
      "DipoleMomentS0": "11.83",
      "DipoleMomentS1": "11.83",
      "DipoleMomentT1": "11.97",
      "ionizationPotential": "6.54",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "FSIXXABOSAQMMD-FMQUCBEESA-N",
      "Smiles": "Fc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)F",
      "HOMO": "-5.71",
      "LUMO": "-3.02",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "",
      "E00T1": "0.71",
      "Eox": "0.75",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.04",
      "ET1red": "",
      "DipoleMomentS0": "2.69",
      "DipoleMomentS1": "2.69",
      "DipoleMomentT1": "2.8",
      "ionizationPotential": "6.47",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "FSIXXABOSAQMMD-VXPUYCOJSA-N",
      "Smiles": "Fc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)F",
      "HOMO": "-5.75",
      "LUMO": "-2.98",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.17",
      "DipoleMomentS1": "8.17",
      "DipoleMomentT1": "6.56",
      "ionizationPotential": "6.43",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "FSRVRIKYSBKKSV-DQRAZIAOSA-N",
      "Smiles": "N#Cc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)C#N",
      "HOMO": "-6.96",
      "LUMO": "-3.51",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "2.08",
      "E00T1": "1.4",
      "Eox": "1.81",
      "Ered": "",
      "ES1ox": "-0.27",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "8.49",
      "DipoleMomentS1": "8.49",
      "DipoleMomentT1": "8.57",
      "ionizationPotential": "8.05",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "FSRVRIKYSBKKSV-QURGRASLSA-N",
      "Smiles": "N#Cc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)C#N",
      "HOMO": "-7.27",
      "LUMO": "-3.7",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "1.27",
      "Eox": "2.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.12",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "8.39",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "FTLXUJCAONQVFV-FLWNBWAVSA-N",
      "Smiles": "FC(c1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)C(F)(F)F)(F)F",
      "HOMO": "-6.07",
      "LUMO": "-3.06",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.6",
      "DipoleMomentS1": "3.6",
      "DipoleMomentT1": "2.8",
      "ionizationPotential": "6.92",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "FTLXUJCAONQVFV-QVIHXGFCSA-N",
      "Smiles": "FC(c1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)C(F)(F)F)(F)F",
      "HOMO": "-6.1",
      "LUMO": "-3.1",
      "VEE": "3.02",
      "Lmax": "410.529801324503",
      "E00S1": "",
      "E00T1": "1.2",
      "Eox": "-4.1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-5.3",
      "ET1red": "",
      "DipoleMomentS0": "4.77",
      "DipoleMomentS1": "4.77",
      "DipoleMomentT1": "4.98",
      "ionizationPotential": "0.16",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "FTMYJLFIPCHZTR-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)S)c1nnc(nn1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)S)c1nnc(nn1)S",
      "HOMO": "-7.28",
      "LUMO": "-3.45",
      "VEE": "1.55",
      "Lmax": "799.870967741936",
      "E00S1": "2.07",
      "E00T1": "1.39",
      "Eox": "2.62",
      "Ered": "",
      "ES1ox": "0.55",
      "ES1red": "",
      "ET1ox": "1.23",
      "ET1red": "",
      "DipoleMomentS0": "1.71",
      "DipoleMomentS1": "1.71",
      "DipoleMomentT1": "1.59",
      "ionizationPotential": "8.15",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FTSYUBCKTHHITG-UHFFFAOYSA-N",
      "Smiles": "CC#Cc1cc(C#N)cc(c1C#N)C#CC",
      "MolView_url": "http://molview.org/?q=CC#Cc1cc(C#N)cc(c1C#N)C#CC",
      "HOMO": "-7.22",
      "LUMO": "-2.63",
      "VEE": "3.69",
      "Lmax": "335.989159891599",
      "E00S1": "",
      "E00T1": "2.89",
      "Eox": "2.31",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.58",
      "ET1red": "",
      "DipoleMomentS0": "1.63",
      "DipoleMomentS1": "1.63",
      "DipoleMomentT1": "2.9",
      "ionizationPotential": "8.48",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FTYZZZCVLRBJNS-ISLYRVAYSA-N",
      "Smiles": "Sc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)S",
      "HOMO": "-5.74",
      "LUMO": "-3.08",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.27",
      "DipoleMomentS1": "2.27",
      "DipoleMomentT1": "1.85",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "FTYZZZCVLRBJNS-ZCXUNETKSA-N",
      "Smiles": "Sc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)S",
      "HOMO": "-5.75",
      "LUMO": "-3.06",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "0.12",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.42",
      "ET1red": "",
      "DipoleMomentS0": "5.18",
      "DipoleMomentS1": "5.18",
      "DipoleMomentT1": "4.64",
      "ionizationPotential": "6.27",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "FUFVQFJGOWERLC-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(o1)c1cc2c3cc(c4ncc(o4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)C#N)cc(c5ccc4)c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)c1cc2c3cc(c4ncc(o4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)C#N)cc(c5ccc4)c1ncc(o1)C#N",
      "HOMO": "-5.91",
      "LUMO": "-3.32",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "1.78",
      "E00T1": "0.22",
      "Eox": "-4.33",
      "Ered": "",
      "ES1ox": "-6.11",
      "ES1red": "",
      "ET1ox": "-4.55",
      "ET1red": "",
      "DipoleMomentS0": "9.36",
      "DipoleMomentS1": "9.36",
      "DipoleMomentT1": "9.42",
      "ionizationPotential": "6.94",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "FUSKRIKNDYDVDC-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(o1)C#N)c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(o1)C#N)c1ncc(o1)C#N",
      "HOMO": "-5.84",
      "LUMO": "-2.8",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.99",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.64",
      "DipoleMomentS1": "5.64",
      "DipoleMomentT1": "8.64",
      "ionizationPotential": "6.9",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "FUZYEVZDQOZJNT-QJZPKIEXSA-N",
      "Smiles": "N#C/N=C/c1cc(C#N)cc(c1C#N)/C=N/C#N",
      "MolView_url": "http://molview.org/?q=N#C/N=C/c1cc(C#N)cc(c1C#N)/C=N/C#N",
      "HOMO": "-8.55",
      "LUMO": "-3.72",
      "VEE": "4.08",
      "Lmax": "303.872549019608",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.01",
      "DipoleMomentS1": "3.01",
      "DipoleMomentT1": "4.63",
      "ionizationPotential": "9.85",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FVCSADSSYLHCRT-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C=O)C)C#N)c1ccc(n1C=O)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C=O)C)C#N)c1ccc(n1C=O)C",
      "HOMO": "-6.39",
      "LUMO": "-3",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.1",
      "DipoleMomentS1": "4.1",
      "DipoleMomentT1": "6.77",
      "ionizationPotential": "7.71",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FVFBSQJLTQYIKA-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(n1C)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(n1C)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C)C(F)(F)F)(F)F",
      "HOMO": "-6.61",
      "LUMO": "-3.17",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.17",
      "DipoleMomentS1": "3.17",
      "DipoleMomentT1": "1.47",
      "ionizationPotential": "7.58",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FVGJYQCTTZHHTI-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)Cl)c1cnc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)Cl)c1cnc([nH]1)Cl",
      "HOMO": "-6.56",
      "LUMO": "-3.17",
      "VEE": "2.23",
      "Lmax": "555.964125560538",
      "E00S1": "2.63",
      "E00T1": "1.88",
      "Eox": "1.87",
      "Ered": "",
      "ES1ox": "-0.76",
      "ES1red": "",
      "ET1ox": "-0.01",
      "ET1red": "",
      "DipoleMomentS0": "7.68",
      "DipoleMomentS1": "7.68",
      "DipoleMomentT1": "15.7",
      "ionizationPotential": "7.59",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FVMWQZWFXGDYMN-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(nc1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(nc1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.59",
      "LUMO": "-3.57",
      "VEE": "3.83",
      "Lmax": "323.707571801567",
      "E00S1": "3.56",
      "E00T1": "",
      "Eox": "3.43",
      "Ered": "",
      "ES1ox": "-0.13",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.52",
      "DipoleMomentS1": "2.52",
      "DipoleMomentT1": "3.31",
      "ionizationPotential": "10",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FWFGHLLPLOFHNT-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(s1)c1cc2c3cc(c4ccc(s4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)S)cc(c5ccc4)c1ccc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(s1)c1cc2c3cc(c4ccc(s4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)S)cc(c5ccc4)c1ccc(s1)S",
      "HOMO": "-5.42",
      "LUMO": "-2.65",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "1.92",
      "E00T1": "1.13",
      "Eox": "0.67",
      "Ered": "",
      "ES1ox": "-1.25",
      "ES1red": "",
      "ET1ox": "-0.46",
      "ET1red": "",
      "DipoleMomentS0": "2.12",
      "DipoleMomentS1": "2.12",
      "DipoleMomentT1": "1.95",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "FWJLOMIHEOSDLE-BYYHNAKLSA-N",
      "Smiles": "Fc1cnc(nc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(nc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(cn1)F",
      "HOMO": "-6.71",
      "LUMO": "-2.95",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "1.67",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.6",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.66",
      "ionizationPotential": "7.38",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "FWWJMELKDXIFEW-UHFFFAOYSA-N",
      "Smiles": "c1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncco1",
      "MolView_url": "http://molview.org/?q=c1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncco1",
      "HOMO": "-5.5",
      "LUMO": "-2.72",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "2.11",
      "E00T1": "1.15",
      "Eox": "0.59",
      "Ered": "",
      "ES1ox": "-1.52",
      "ES1red": "",
      "ET1ox": "-0.56",
      "ET1red": "",
      "DipoleMomentS0": "2.26",
      "DipoleMomentS1": "2.26",
      "DipoleMomentT1": "2.2",
      "ionizationPotential": "6.24",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "FXMWRYKXOWLGDD-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(o1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(o1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-7.88",
      "LUMO": "-3.34",
      "VEE": "3.53",
      "Lmax": "351.218130311615",
      "E00S1": "3.14",
      "E00T1": "2.26",
      "Eox": "2.96",
      "Ered": "",
      "ES1ox": "-0.18",
      "ES1red": "",
      "ET1ox": "0.7",
      "ET1red": "",
      "DipoleMomentS0": "3.75",
      "DipoleMomentS1": "3.75",
      "DipoleMomentT1": "7.39",
      "ionizationPotential": "9.62",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "FXZUSFACRICRPK-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(cn1)S)C#N)c1ncc(cn1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(cn1)S)C#N)c1ncc(cn1)S",
      "HOMO": "-6.88",
      "LUMO": "-2.69",
      "VEE": "3.47",
      "Lmax": "357.291066282421",
      "E00S1": "",
      "E00T1": "2.44",
      "Eox": "2.15",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.29",
      "ET1red": "",
      "DipoleMomentS0": "5.42",
      "DipoleMomentS1": "5.42",
      "DipoleMomentT1": "11.15",
      "ionizationPotential": "8.11",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FYMRBULDMKUDIA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1nncnn1)C#N)c1nncnn1",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1nncnn1)C#N)c1nncnn1",
      "HOMO": "-7.45",
      "LUMO": "-3.41",
      "VEE": "2.03",
      "Lmax": "610.738916256158",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.4",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.56",
      "DipoleMomentS1": "0.56",
      "DipoleMomentT1": "0.59",
      "ionizationPotential": "8.69",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "FYYYIMKYDSAAEP-WCWDXBQESA-N",
      "Smiles": "COc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)OC",
      "HOMO": "-6.07",
      "LUMO": "-2.97",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.5",
      "DipoleMomentS1": "0.5",
      "DipoleMomentT1": "2.43",
      "ionizationPotential": "6.81",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "FZCGGXGNCCTXLC-NXVVXOECSA-N",
      "Smiles": "OC#Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C#CO",
      "MolView_url": "http://molview.org/?q=OC#Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C#CO",
      "HOMO": "-5.62",
      "LUMO": "-2.42",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.06",
      "DipoleMomentS1": "4.06",
      "DipoleMomentT1": "3.6",
      "ionizationPotential": "5.48",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "FZDBLGZKYOIGAX-KQQUZDAGSA-N",
      "Smiles": "S/C=C/c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C/S",
      "MolView_url": "http://molview.org/?q=S/C=C/c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C/S",
      "HOMO": "-6.29",
      "LUMO": "-3.07",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.94",
      "DipoleMomentS1": "3.94",
      "DipoleMomentT1": "13.72",
      "ionizationPotential": "7.37",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "FZPAZPSIBOQYFV-VXPUYCOJSA-N",
      "Smiles": "Sc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)S",
      "HOMO": "-5.55",
      "LUMO": "-2.89",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.14",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.59",
      "DipoleMomentS1": "6.59",
      "DipoleMomentT1": "5.48",
      "ionizationPotential": "6.11",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "FZZREEZDZUQMHA-FMQUCBEESA-N",
      "Smiles": "[O-][N+](=O)c1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)[N+](=O)[O-]",
      "HOMO": "-5.75",
      "LUMO": "-3.01",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "2.05",
      "E00T1": "",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "-1.34",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.54",
      "DipoleMomentS1": "4.54",
      "DipoleMomentT1": "3.24",
      "ionizationPotential": "6.86",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GAIWUBAQTJDEGZ-ISLYRVAYSA-N",
      "Smiles": "Oc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)O",
      "HOMO": "-5.29",
      "LUMO": "-2.72",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "1.93",
      "E00T1": "0.71",
      "Eox": "0.39",
      "Ered": "",
      "ES1ox": "-1.54",
      "ES1red": "",
      "ET1ox": "-0.32",
      "ET1red": "",
      "DipoleMomentS0": "4.11",
      "DipoleMomentS1": "4.11",
      "DipoleMomentT1": "3.98",
      "ionizationPotential": "5.95",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GAKHRVNGGLNRNA-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)O)cc(c5ccc4)c1ncc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)O)cc(c5ccc4)c1ncc(cn1)O",
      "HOMO": "-5.44",
      "LUMO": "-2.6",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "2.01",
      "E00T1": "1.14",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "-1.3",
      "ES1red": "",
      "ET1ox": "-0.43",
      "ET1red": "",
      "DipoleMomentS0": "5.76",
      "DipoleMomentS1": "5.76",
      "DipoleMomentT1": "4.97",
      "ionizationPotential": "5.8",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GBAMTVHGFZIHRZ-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)Cl)c1ccc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)Cl)c1ccc([nH]1)Cl",
      "HOMO": "-6.1",
      "LUMO": "-3.07",
      "VEE": "2.02",
      "Lmax": "613.762376237624",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.28",
      "DipoleMomentS1": "1.28",
      "DipoleMomentT1": "18.17",
      "ionizationPotential": "7.08",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GBCGMGYXFCHVGE-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-6.72",
      "LUMO": "-3.21",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.46",
      "E00T1": "0.31",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.12",
      "DipoleMomentS1": "2.12",
      "DipoleMomentT1": "4",
      "ionizationPotential": "7.57",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GBDNHOJKOIHPMC-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)[N+](=O)[O-])c1[nH]cc(c1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)[N+](=O)[O-])c1[nH]cc(c1)[N+](=O)[O-]",
      "HOMO": "-6.74",
      "LUMO": "-3.23",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.92",
      "DipoleMomentS1": "8.92",
      "DipoleMomentT1": "8.97",
      "ionizationPotential": "7.93",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GBHGVKWLONLULE-VNXWYBNKSA-N",
      "Smiles": "C/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N\\C",
      "MolView_url": "http://molview.org/?q=C/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N\\C",
      "HOMO": "-6.71",
      "LUMO": "-2.91",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "1.44",
      "Eox": "1.83",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.62",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "GBHGVKWLONLULE-ZNRWAZBVSA-N",
      "Smiles": "C/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N/C",
      "MolView_url": "http://molview.org/?q=C/N=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=N/C",
      "HOMO": "-6.61",
      "LUMO": "-2.59",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "0.58",
      "Eox": "1.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.91",
      "ET1red": "",
      "DipoleMomentS0": "0.74",
      "DipoleMomentS1": "0.74",
      "DipoleMomentT1": "0.78",
      "ionizationPotential": "7.47",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "GBNYAFCUPBMUSJ-UHFFFAOYSA-N",
      "Smiles": "Cc1cnc(s1)c1cc2c3cc(c4ncc(s4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)C)cc(c5ccc4)c1ncc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(s1)c1cc2c3cc(c4ncc(s4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)C)cc(c5ccc4)c1ncc(s1)C",
      "HOMO": "-5.52",
      "LUMO": "-2.77",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.88",
      "E00T1": "1.13",
      "Eox": "0.75",
      "Ered": "",
      "ES1ox": "-1.13",
      "ES1red": "",
      "ET1ox": "-0.38",
      "ET1red": "",
      "DipoleMomentS0": "2.88",
      "DipoleMomentS1": "2.88",
      "DipoleMomentT1": "2.19",
      "ionizationPotential": "6.03",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GCMMOHYOZSLCEW-CIIODKQPSA-N",
      "Smiles": "C/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/C",
      "MolView_url": "http://molview.org/?q=C/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/C",
      "HOMO": "-5.34",
      "LUMO": "-2.33",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.14",
      "E00T1": "1.26",
      "Eox": "0.57",
      "Ered": "",
      "ES1ox": "-1.57",
      "ES1red": "",
      "ET1ox": "-0.69",
      "ET1red": "",
      "DipoleMomentS0": "1.43",
      "DipoleMomentS1": "1.43",
      "DipoleMomentT1": "1.54",
      "ionizationPotential": "6.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "GCXLLKZOSJAYGV-WXUKJITCSA-N",
      "Smiles": "O/C=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/O",
      "MolView_url": "http://molview.org/?q=O/C=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/O",
      "HOMO": "-5.26",
      "LUMO": "-2.38",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "2.23",
      "E00T1": "1.18",
      "Eox": "0.41",
      "Ered": "",
      "ES1ox": "-1.82",
      "ES1red": "",
      "ET1ox": "-0.77",
      "ET1red": "",
      "DipoleMomentS0": "1.72",
      "DipoleMomentS1": "1.72",
      "DipoleMomentT1": "1.88",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "GCYLYBAXPBXMCF-DQRAZIAOSA-N",
      "Smiles": "N#Cc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)C#N",
      "HOMO": "-6.25",
      "LUMO": "-3.31",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "1.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1",
      "ET1red": "",
      "DipoleMomentS0": "13.09",
      "DipoleMomentS1": "13.09",
      "DipoleMomentT1": "10.13",
      "ionizationPotential": "7.24",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "GCYLYBAXPBXMCF-QURGRASLSA-N",
      "Smiles": "N#Cc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)C#N",
      "HOMO": "-6.31",
      "LUMO": "-3.42",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.85",
      "Eox": "1.36",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.51",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.31",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "GDQJTIYZEDMXDL-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(cc1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(cc1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.27",
      "LUMO": "-3.2",
      "VEE": "3.81",
      "Lmax": "325.406824146982",
      "E00S1": "3.53",
      "E00T1": "2.77",
      "Eox": "3.24",
      "Ered": "",
      "ES1ox": "-0.29",
      "ES1red": "",
      "ET1ox": "0.47",
      "ET1red": "",
      "DipoleMomentS0": "3.08",
      "DipoleMomentS1": "3.08",
      "DipoleMomentT1": "3.25",
      "ionizationPotential": "9.81",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "GEBRRLGBUHAWKA-BYYHNAKLSA-N",
      "Smiles": "FC(Oc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)OC(F)(F)F)(F)F",
      "HOMO": "-5.45",
      "LUMO": "-2.75",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "2.03",
      "E00T1": "0.77",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GEGLKSWHMRVSLU-ISLYRVAYSA-N",
      "Smiles": "c1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1nccs1",
      "MolView_url": "http://molview.org/?q=c1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1nccs1",
      "HOMO": "-6.02",
      "LUMO": "-3.2",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "",
      "E00T1": "0.81",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.32",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "GEGLKSWHMRVSLU-ZCXUNETKSA-N",
      "Smiles": "c1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1nccs1",
      "MolView_url": "http://molview.org/?q=c1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1nccs1",
      "HOMO": "-6.01",
      "LUMO": "-3.18",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "0.18",
      "Eox": "0.98",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.8",
      "ET1red": "",
      "DipoleMomentS0": "6.77",
      "DipoleMomentS1": "6.77",
      "DipoleMomentT1": "6.26",
      "ionizationPotential": "6.7",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "GEOMSUICPZWMPX-UHFFFAOYSA-N",
      "Smiles": "FC(/C=C/c1cc2c3cc(/C=C/C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\C(F)(F)F)cc(c5ccc4)/C=C/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C/c1cc2c3cc(/C=C/C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\C(F)(F)F)cc(c5ccc4)/C=C/C(F)(F)F)(F)F",
      "HOMO": "-5.7",
      "LUMO": "-2.89",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "1.97",
      "E00T1": "",
      "Eox": "0.89",
      "Ered": "",
      "ES1ox": "-1.08",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.64",
      "DipoleMomentS1": "4.64",
      "DipoleMomentT1": "4.5",
      "ionizationPotential": "6.84",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GERLMSXALGGMFW-FMQUCBEESA-N",
      "Smiles": "Oc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)O",
      "HOMO": "-5.05",
      "LUMO": "-2.7",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.23",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.75",
      "DipoleMomentS1": "1.75",
      "DipoleMomentT1": "3.4",
      "ionizationPotential": "5.72",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "GERLMSXALGGMFW-VXPUYCOJSA-N",
      "Smiles": "Oc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)O",
      "HOMO": "-5.18",
      "LUMO": "-2.64",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.95",
      "DipoleMomentS1": "9.95",
      "DipoleMomentT1": "2.95",
      "ionizationPotential": "5.75",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "GGDCGKUSUGIZPQ-DQRAZIAOSA-N",
      "Smiles": "n1cc([nH]c1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1[nH]cnc1",
      "MolView_url": "http://molview.org/?q=n1cc([nH]c1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1[nH]cnc1",
      "HOMO": "-5.44",
      "LUMO": "-2.4",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.7",
      "DipoleMomentS1": "8.7",
      "DipoleMomentT1": "6.24",
      "ionizationPotential": "6.32",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "GGEOEMUYVQJTDF-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1C(F)(F)F)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C=O)C(F)(F)F)c1ccc(n1C=O)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1C(F)(F)F)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C=O)C(F)(F)F)c1ccc(n1C=O)C(F)(F)F",
      "HOMO": "-5.71",
      "LUMO": "-2.56",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "1.61",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.75",
      "ET1red": "",
      "DipoleMomentS0": "5.26",
      "DipoleMomentS1": "5.26",
      "DipoleMomentT1": "5.49",
      "ionizationPotential": "6.7",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "GGKQLHMRMIRNRP-VHXPQNKSSA-N",
      "Smiles": "C1=CC=C(C1)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=CC1",
      "MolView_url": "http://molview.org/?q=C1=CC=C(C1)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=CC1",
      "HOMO": "-5.71",
      "LUMO": "-2.46",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "0.43",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.38",
      "ET1red": "",
      "DipoleMomentS0": "5.82",
      "DipoleMomentS1": "5.82",
      "DipoleMomentT1": "5.08",
      "ionizationPotential": "6.45",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "GGKQLHMRMIRNRP-WCWDXBQESA-N",
      "Smiles": "C1=CC=C(C1)c1ccc(cc1)/N=N/c1ccc(cc1)C1=CC=CC1",
      "MolView_url": "http://molview.org/?q=C1=CC=C(C1)c1ccc(cc1)/N=N/c1ccc(cc1)C1=CC=CC1",
      "HOMO": "-5.73",
      "LUMO": "-2.69",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "0.99",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.08",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "5.5",
      "ionizationPotential": "6.5",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "GHCNKNMNVGTNLK-UHFFFAOYSA-N",
      "Smiles": "Sc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)S)cc(c5ccc4)c1nnc(nn1)S",
      "MolView_url": "http://molview.org/?q=Sc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)S)cc(c5ccc4)c1nnc(nn1)S",
      "HOMO": "-5.83",
      "LUMO": "-3.22",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "1.58",
      "E00T1": "1.13",
      "Eox": "1.05",
      "Ered": "",
      "ES1ox": "-0.53",
      "ES1red": "",
      "ET1ox": "-0.08",
      "ET1red": "",
      "DipoleMomentS0": "2.2",
      "DipoleMomentS1": "2.2",
      "DipoleMomentT1": "1.99",
      "ionizationPotential": "6.57",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GHECZMIGDNHEAS-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1scc(n1)[N+](=O)[O-])C#N)c1scc(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1scc(n1)[N+](=O)[O-])C#N)c1scc(n1)[N+](=O)[O-]",
      "HOMO": "-7.39",
      "LUMO": "-3.48",
      "VEE": "3.91",
      "Lmax": "317.084398976982",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.75",
      "DipoleMomentS1": "9.75",
      "DipoleMomentT1": "9.58",
      "ionizationPotential": "9.08",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "GHRBHXYUBXRAJG-OKOAMWBMSA-N",
      "Smiles": "O/C=C\\c1ccc([nH]1)/N=N\\c1ccc([nH]1)/C=C/O",
      "MolView_url": "http://molview.org/?q=O/C=C\\c1ccc([nH]1)/N=N\\c1ccc([nH]1)/C=C/O",
      "HOMO": "-4.98",
      "LUMO": "-2.16",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "2.15",
      "E00T1": "0.83",
      "Eox": "0.13",
      "Ered": "",
      "ES1ox": "-2.02",
      "ES1red": "",
      "ET1ox": "-0.7",
      "ET1red": "",
      "DipoleMomentS0": "2.1",
      "DipoleMomentS1": "2.1",
      "DipoleMomentT1": "2.23",
      "ionizationPotential": "5.74",
      "source_directory": "/scratch/myrthil.n/pyrrole_trans"
    },
    {
      "InchiKey": "GHZWNZSOIIVSLN-ZDKZHOOFSA-N",
      "Smiles": "F/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C\\F",
      "MolView_url": "http://molview.org/?q=F/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C\\F",
      "HOMO": "-5.79",
      "LUMO": "-2.88",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "2.24",
      "E00T1": "0.74",
      "Eox": "0.82",
      "Ered": "",
      "ES1ox": "-1.42",
      "ES1red": "",
      "ET1ox": "0.08",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GIBUFQBNXMFZJR-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(C1)[N+](=O)[O-])C#N)C1=CC=C(C1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(C1)[N+](=O)[O-])C#N)C1=CC=C(C1)[N+](=O)[O-]",
      "HOMO": "-7.17",
      "LUMO": "-3.34",
      "VEE": "3.27",
      "Lmax": "379.14373088685",
      "E00S1": "",
      "E00T1": "1.52",
      "Eox": "2.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.66",
      "ET1red": "",
      "DipoleMomentS0": "0.31",
      "DipoleMomentS1": "0.31",
      "DipoleMomentT1": "4.11",
      "ionizationPotential": "8.58",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "GIQJYSRFZXGZLC-FMQUCBEESA-N",
      "Smiles": "c1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc[nH]1",
      "MolView_url": "http://molview.org/?q=c1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc[nH]1",
      "HOMO": "-5.27",
      "LUMO": "-2.64",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "1.96",
      "E00T1": "0.74",
      "Eox": "0.39",
      "Ered": "",
      "ES1ox": "-1.57",
      "ES1red": "",
      "ET1ox": "-0.35",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.71",
      "ionizationPotential": "6.04",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GITNVYYXTJUEOW-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)O",
      "HOMO": "-5.4",
      "LUMO": "-2.43",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.29",
      "E00T1": "1.25",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "-1.63",
      "ES1red": "",
      "ET1ox": "-0.59",
      "ET1red": "",
      "DipoleMomentS0": "3.19",
      "DipoleMomentS1": "3.19",
      "DipoleMomentT1": "3.23",
      "ionizationPotential": "6.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "GIVSKCIJIYJETF-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(nc1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1cnc(nc1)[C@]1(Cl)N=N1",
      "HOMO": "-7.76",
      "LUMO": "-2.68",
      "VEE": "3.37",
      "Lmax": "367.893175074184",
      "E00S1": "3.3",
      "E00T1": "2.74",
      "Eox": "2.85",
      "Ered": "",
      "ES1ox": "-0.45",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "3.52",
      "DipoleMomentS1": "3.52",
      "DipoleMomentT1": "6.82",
      "ionizationPotential": "9.16",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "GIVYAYFLIOTMCD-UHFFFAOYSA-N",
      "Smiles": "Sc1coc(n1)c1cc2c3cc(c4occ(n4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)S)cc(c5ccc4)c1occ(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1coc(n1)c1cc2c3cc(c4occ(n4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)S)cc(c5ccc4)c1occ(n1)S",
      "HOMO": "-5.65",
      "LUMO": "-3.01",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "1.83",
      "E00T1": "1.06",
      "Eox": "-4.28",
      "Ered": "",
      "ES1ox": "-6.11",
      "ES1red": "",
      "ET1ox": "-5.34",
      "ET1red": "",
      "DipoleMomentS0": "4.97",
      "DipoleMomentS1": "4.97",
      "DipoleMomentT1": "4.88",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GJMDKDVQARDKJP-NORIJPHCSA-N",
      "Smiles": "Cl/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/Cl",
      "MolView_url": "http://molview.org/?q=Cl/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/Cl",
      "HOMO": "-7.69",
      "LUMO": "-3.41",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "3.08",
      "E00T1": "2.7",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.32",
      "DipoleMomentS1": "7.32",
      "DipoleMomentT1": "6.19",
      "ionizationPotential": "8.84",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GKAKLFOOVWEYCU-UHFFFAOYSA-N",
      "Smiles": "FC(c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C(F)(F)F)cc(c5ccc4)C(F)(F)F)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C(F)(F)F)cc(c5ccc4)C(F)(F)F)C(F)(F)F)(F)F",
      "HOMO": "-6.18",
      "LUMO": "-3.07",
      "VEE": "3.08",
      "Lmax": "402.532467532468",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.35",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GKFRQVGBQVWRIR-UHFFFAOYSA-N",
      "Smiles": "SC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)S",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)S",
      "HOMO": "-5.24",
      "LUMO": "-2.41",
      "VEE": "1.53",
      "Lmax": "810.326797385621",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "0.36",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.71",
      "ET1red": "",
      "DipoleMomentS0": "2.61",
      "DipoleMomentS1": "2.61",
      "DipoleMomentT1": "2.29",
      "ionizationPotential": "5.89",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "GKOPULUCZJLFOP-UHFFFAOYSA-N",
      "Smiles": "FC(c1ncc([nH]1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ncc([nH]1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-7.47",
      "LUMO": "-2.85",
      "VEE": "3.72",
      "Lmax": "333.279569892473",
      "E00S1": "3.02",
      "E00T1": "2.43",
      "Eox": "2.56",
      "Ered": "",
      "ES1ox": "-0.46",
      "ES1red": "",
      "ET1ox": "0.13",
      "ET1red": "",
      "DipoleMomentS0": "3.12",
      "DipoleMomentS1": "3.12",
      "DipoleMomentT1": "4.8",
      "ionizationPotential": "9.14",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "GKRINRIVHQFAJG-ZCXUNETKSA-N",
      "Smiles": "Fc1nnc(nn1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1nnc(nn1)F",
      "MolView_url": "http://molview.org/?q=Fc1nnc(nn1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1nnc(nn1)F",
      "HOMO": "-6.27",
      "LUMO": "-3.54",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "1.98",
      "E00T1": "0.31",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.42",
      "DipoleMomentS1": "2.42",
      "DipoleMomentT1": "3.29",
      "ionizationPotential": "7.26",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "GKRLSQTZFJNMTJ-UHFFFAOYSA-N",
      "Smiles": "Fc1ncc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cnc([nH]1)F)c1cnc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ncc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cnc([nH]1)F)c1cnc([nH]1)F",
      "HOMO": "-5.56",
      "LUMO": "-2.44",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.03",
      "DipoleMomentS1": "6.03",
      "DipoleMomentT1": "7.1",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "GKVDXUXIAHWQIK-UHFFFAOYSA-N",
      "Smiles": "N1=NC1",
      "MolView_url": "http://molview.org/?q=N1=NC1",
      "HOMO": "-8.17",
      "LUMO": "-1.97",
      "VEE": "3.69",
      "Lmax": "335.989159891599",
      "E00S1": "3.75",
      "E00T1": "3.01",
      "Eox": "3.38",
      "Ered": "",
      "ES1ox": "-0.37",
      "ES1red": "",
      "ET1ox": "0.37",
      "ET1red": "",
      "DipoleMomentS0": "2.21",
      "DipoleMomentS1": "2.21",
      "DipoleMomentT1": "3.39",
      "ionizationPotential": "10.57",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "GLBUBHPKPUMVMX-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(s1)c1cc2c3cc(c4ccc(s4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)Cl)cc(c5ccc4)c1ccc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(s1)c1cc2c3cc(c4ccc(s4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)Cl)cc(c5ccc4)c1ccc(s1)Cl",
      "HOMO": "-5.44",
      "LUMO": "-2.66",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "",
      "E00T1": "1.13",
      "Eox": "0.6",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.18",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GLFOEKRLPHJKGP-IHXWQEJPSA-N",
      "Smiles": "C/N=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/C",
      "MolView_url": "http://molview.org/?q=C/N=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/C",
      "HOMO": "-5.52",
      "LUMO": "-2.64",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "2.23",
      "E00T1": "1.17",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.51",
      "ES1red": "",
      "ET1ox": "-0.45",
      "ET1red": "",
      "DipoleMomentS0": "1.6",
      "DipoleMomentS1": "1.6",
      "DipoleMomentT1": "1.37",
      "ionizationPotential": "6.34",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "GLUSHKNJCIRLDE-UHFFFAOYSA-N",
      "Smiles": "COc1coc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1occ(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1coc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1occ(n1)OC",
      "HOMO": "-6.45",
      "LUMO": "-3.29",
      "VEE": "2.03",
      "Lmax": "610.738916256158",
      "E00S1": "2.5",
      "E00T1": "",
      "Eox": "1.8",
      "Ered": "",
      "ES1ox": "-0.7",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.67",
      "DipoleMomentS1": "0.67",
      "DipoleMomentT1": "5.22",
      "ionizationPotential": "7.34",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GLVZWPFDCJINMM-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1nnc(nn1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1nnc(nn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1nnc(nn1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1nnc(nn1)OC(F)(F)F)(F)F",
      "HOMO": "-7.62",
      "LUMO": "-3.6",
      "VEE": "1.59",
      "Lmax": "779.748427672956",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.32",
      "DipoleMomentS1": "3.32",
      "DipoleMomentT1": "3.48",
      "ionizationPotential": "8.59",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GMTCRTFSEOXKQA-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(s1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1cnc(s1)[C@]1(F)N=N1",
      "HOMO": "-7.36",
      "LUMO": "-2.75",
      "VEE": "3.12",
      "Lmax": "397.371794871795",
      "E00S1": "3.01",
      "E00T1": "2.39",
      "Eox": "2.4",
      "Ered": "",
      "ES1ox": "-0.61",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "2.42",
      "DipoleMomentS1": "2.42",
      "DipoleMomentT1": "8.81",
      "ionizationPotential": "9",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "GMUNELMZIPFMDG-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)S)c1ccc(o1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)S)c1ccc(o1)S",
      "HOMO": "-6.35",
      "LUMO": "-3.16",
      "VEE": "1.91",
      "Lmax": "649.109947643979",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.19",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.22",
      "DipoleMomentS1": "1.22",
      "DipoleMomentT1": "7.9",
      "ionizationPotential": "7",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GMYOHAMWFBJRCN-IAZLARGGSA-N",
      "Smiles": "FC(/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C/C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C/C(F)(F)F)cc(c5ccc4)/C=C\\O/C=C/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C/C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C/C(F)(F)F)cc(c5ccc4)/C=C\\O/C=C/C(F)(F)F)(F)F",
      "HOMO": "-5.35",
      "LUMO": "-2.61",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "0.18",
      "Eox": "-4.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-4.5",
      "ET1red": "",
      "DipoleMomentS0": "4.39",
      "DipoleMomentS1": "4.39",
      "DipoleMomentT1": "3.9",
      "ionizationPotential": "0.07",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GORDDDHBMHEVSP-WCWDXBQESA-N",
      "Smiles": "Clc1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)Cl",
      "HOMO": "-6.42",
      "LUMO": "-3.02",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.59",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.54",
      "ET1red": "",
      "DipoleMomentS0": "2.56",
      "DipoleMomentS1": "2.56",
      "DipoleMomentT1": "2.93",
      "ionizationPotential": "7.23",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "GORPVCDQCLQTHL-YODPDAALSA-N",
      "Smiles": "N=Cc1ccc(cc1)/N=N/c1ccc(cc1)C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1ccc(cc1)/N=N/c1ccc(cc1)C=N",
      "HOMO": "-6.93",
      "LUMO": "-3.01",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "1.42",
      "Eox": "1.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.51",
      "ET1red": "",
      "DipoleMomentS0": "4.72",
      "DipoleMomentS1": "4.72",
      "DipoleMomentT1": "4.91",
      "ionizationPotential": "7.92",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "GPAMEGNRIKMEKV-BYYHNAKLSA-N",
      "Smiles": "FC(Oc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)OC(F)(F)F)(F)F",
      "HOMO": "-5.64",
      "LUMO": "-2.89",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "0.79",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.1",
      "ET1red": "",
      "DipoleMomentS0": "3.98",
      "DipoleMomentS1": "3.98",
      "DipoleMomentT1": "3.71",
      "ionizationPotential": "6.34",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "GPAMEGNRIKMEKV-DQSJHHFOSA-N",
      "Smiles": "FC(Oc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)OC(F)(F)F)(F)F",
      "HOMO": "-5.95",
      "LUMO": "-2.71",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.42",
      "DipoleMomentS1": "5.42",
      "DipoleMomentT1": "5.59",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "GPGFUTQRPIRKTC-DQRAZIAOSA-N",
      "Smiles": "c1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccccc1",
      "MolView_url": "http://molview.org/?q=c1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccccc1",
      "HOMO": "-5.89",
      "LUMO": "-2.92",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "0.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.56",
      "ET1red": "",
      "DipoleMomentS0": "6.77",
      "DipoleMomentS1": "6.77",
      "DipoleMomentT1": "5.66",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "GPGFUTQRPIRKTC-QURGRASLSA-N",
      "Smiles": "c1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccccc1",
      "MolView_url": "http://molview.org/?q=c1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccccc1",
      "HOMO": "-5.9",
      "LUMO": "-2.95",
      "VEE": "3.1",
      "Lmax": "399.935483870968",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.07",
      "ET1red": "",
      "DipoleMomentS0": "0.02",
      "DipoleMomentS1": "0.02",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.57",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "GPLISXJFICIEKA-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(o1)c1cc2c3cc(c4ccc(o4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)O)cc(c5ccc4)c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(o1)c1cc2c3cc(c4ccc(o4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)O)cc(c5ccc4)c1ccc(o1)O",
      "HOMO": "-5.12",
      "LUMO": "-2.51",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "1.79",
      "E00T1": "1.07",
      "Eox": "0.32",
      "Ered": "",
      "ES1ox": "-1.47",
      "ES1red": "",
      "ET1ox": "-0.75",
      "ET1red": "",
      "DipoleMomentS0": "1.27",
      "DipoleMomentS1": "1.27",
      "DipoleMomentT1": "1.54",
      "ionizationPotential": "5.75",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GQGDKYRZXHHXRV-UHFFFAOYSA-N",
      "Smiles": "Sc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)S)cc(c5ccc4)c1cnc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)S)cc(c5ccc4)c1cnc([nH]1)S",
      "HOMO": "-5.39",
      "LUMO": "-2.6",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "1.94",
      "E00T1": "1.14",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "-1.41",
      "ES1red": "",
      "ET1ox": "-0.61",
      "ET1red": "",
      "DipoleMomentS0": "2.94",
      "DipoleMomentS1": "2.94",
      "DipoleMomentT1": "3.61",
      "ionizationPotential": "6.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GQGORVWASGJMHD-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(nc1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(cn1)C#N)c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(cn1)C#N)c1ccc(cn1)C#N",
      "HOMO": "-5.7",
      "LUMO": "-2.68",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.45",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.1",
      "DipoleMomentS1": "3.1",
      "DipoleMomentT1": "5.1",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "GQHIYRAVQDOILO-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc([nH]1)[N+](=O)[O-])C#N)c1ccc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc([nH]1)[N+](=O)[O-])C#N)c1ccc([nH]1)[N+](=O)[O-]",
      "HOMO": "-6.91",
      "LUMO": "-3.52",
      "VEE": "3.24",
      "Lmax": "382.654320987654",
      "E00S1": "",
      "E00T1": "2.21",
      "Eox": "-0.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.62",
      "ET1red": "",
      "DipoleMomentS0": "10.68",
      "DipoleMomentS1": "10.68",
      "DipoleMomentT1": "12.32",
      "ionizationPotential": "8.48",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "GQRCYGIZBXPLRU-FMQUCBEESA-N",
      "Smiles": "O=Cn1c(S)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)S",
      "MolView_url": "http://molview.org/?q=O=Cn1c(S)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)S",
      "HOMO": "-5.65",
      "LUMO": "-3.03",
      "VEE": "3.02",
      "Lmax": "410.529801324503",
      "E00S1": "",
      "E00T1": "0.77",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.09",
      "ET1red": "",
      "DipoleMomentS0": "4.04",
      "DipoleMomentS1": "4.04",
      "DipoleMomentT1": "2.86",
      "ionizationPotential": "6.35",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "GQSXUHRCHGRIGH-DQRAZIAOSA-N",
      "Smiles": "c1cnc(nc1)c1ccc(o1)/N=N\\c1ccc(o1)c1ncccn1",
      "MolView_url": "http://molview.org/?q=c1cnc(nc1)c1ccc(o1)/N=N\\c1ccc(o1)c1ncccn1",
      "HOMO": "-6.3",
      "LUMO": "-3.11",
      "VEE": "2.55",
      "Lmax": "486.196078431373",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.4",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.87",
      "DipoleMomentS1": "8.87",
      "DipoleMomentT1": "9.94",
      "ionizationPotential": "0",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "GQSXUHRCHGRIGH-QURGRASLSA-N",
      "Smiles": "c1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncccn1",
      "MolView_url": "http://molview.org/?q=c1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncccn1",
      "HOMO": "-6.21",
      "LUMO": "-3.15",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "2.31",
      "E00T1": "0.9",
      "Eox": "1.25",
      "Ered": "",
      "ES1ox": "-1.06",
      "ES1red": "",
      "ET1ox": "0.35",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.86",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GQSYPWODQFRQIJ-BYYHNAKLSA-N",
      "Smiles": "FC(c1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)C(F)(F)F)(F)F",
      "HOMO": "-5.97",
      "LUMO": "-3.14",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.26",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "GQSYPWODQFRQIJ-DQSJHHFOSA-N",
      "Smiles": "FC(c1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)C(F)(F)F)(F)F",
      "HOMO": "-6",
      "LUMO": "-3.07",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "0.19",
      "Eox": "0.94",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.75",
      "ET1red": "",
      "DipoleMomentS0": "2.7",
      "DipoleMomentS1": "2.7",
      "DipoleMomentT1": "2.99",
      "ionizationPotential": "6.96",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "GQXSXSSQCAKYSM-ISLYRVAYSA-N",
      "Smiles": "Oc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)O",
      "HOMO": "-5.75",
      "LUMO": "-3.06",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "2.02",
      "E00T1": "0.73",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "-1.18",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "6.04",
      "DipoleMomentS1": "6.04",
      "DipoleMomentT1": "5.21",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GRMRSBIDWAEKQI-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(nc1)c1cc2c3cc(c4ncc(cn4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)[N+](=O)[O-])cc(c5ccc4)c1ncc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(nc1)c1cc2c3cc(c4ncc(cn4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)[N+](=O)[O-])cc(c5ccc4)c1ncc(cn1)[N+](=O)[O-]",
      "HOMO": "-5.85",
      "LUMO": "-3.59",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "1.57",
      "E00T1": "0.99",
      "Eox": "1.02",
      "Ered": "",
      "ES1ox": "-0.55",
      "ES1red": "",
      "ET1ox": "0.03",
      "ET1red": "",
      "DipoleMomentS0": "0.74",
      "DipoleMomentS1": "0.74",
      "DipoleMomentT1": "1",
      "ionizationPotential": "6.94",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GRPOZYMVAJOGAE-UHFFFAOYSA-N",
      "Smiles": "FC(C#Cc1cc2c3cc(C#CC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)C#CC(F)(F)F)cc(c5ccc4)C#CC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C#Cc1cc2c3cc(C#CC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)C#CC(F)(F)F)cc(c5ccc4)C#CC(F)(F)F)(F)F",
      "HOMO": "-5.99",
      "LUMO": "-3.28",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "1.95",
      "E00T1": "1.09",
      "Eox": "-4.11",
      "Ered": "",
      "ES1ox": "-6.06",
      "ES1red": "",
      "ET1ox": "-5.2",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "",
      "ionizationPotential": "7.19",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GRRNMHDOLHOUEF-FMQUCBEESA-N",
      "Smiles": "CC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)C",
      "HOMO": "-6.12",
      "LUMO": "-3.55",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.85",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "GRRNMHDOLHOUEF-VXPUYCOJSA-N",
      "Smiles": "CC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)C",
      "HOMO": "-6.08",
      "LUMO": "-3.45",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "-0.06",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.09",
      "ET1red": "",
      "DipoleMomentS0": "2.27",
      "DipoleMomentS1": "2.27",
      "DipoleMomentT1": "0.1",
      "ionizationPotential": "6.75",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "GRWWYJNTYARHHH-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(s1)c1cc2c3cc(c4ncc(s4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)C(F)(F)F)cc(c5ccc4)c1ncc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(s1)c1cc2c3cc(c4ncc(s4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)C(F)(F)F)cc(c5ccc4)c1ncc(s1)C(F)(F)F)(F)F",
      "HOMO": "-5.81",
      "LUMO": "-3.07",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "1.88",
      "E00T1": "",
      "Eox": "0.99",
      "Ered": "",
      "ES1ox": "-0.89",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.6",
      "DipoleMomentS1": "0.6",
      "DipoleMomentT1": "0.53",
      "ionizationPotential": "6.81",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GSDGRRWFJMTHGJ-DQRAZIAOSA-N",
      "Smiles": "Oc1ccc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(s1)O",
      "HOMO": "-5.73",
      "LUMO": "-2.44",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.8",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.96",
      "DipoleMomentS1": "5.96",
      "DipoleMomentT1": "4.71",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "GSXKGRMNEHRZHD-UNBGJDTASA-N",
      "Smiles": "S/N=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=N/S)ccc2-c([n+]31)c4)Cl)/C=N/S",
      "MolView_url": "http://molview.org/?q=S/N=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=N/S)ccc2-c([n+]31)c4)Cl)/C=N/S",
      "HOMO": "-5.72",
      "LUMO": "-2.63",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.88",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.26",
      "DipoleMomentS1": "6.26",
      "DipoleMomentT1": "6.7",
      "ionizationPotential": "6.52",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "GSYCBINEZRLFOL-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)O)c1[nH]cc(c1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)O)c1[nH]cc(c1)O",
      "HOMO": "-5.89",
      "LUMO": "-3.06",
      "VEE": "1.99",
      "Lmax": "623.015075376884",
      "E00S1": "2.15",
      "E00T1": "1.66",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "-1.12",
      "ES1red": "",
      "ET1ox": "-0.63",
      "ET1red": "",
      "DipoleMomentS0": "4.6",
      "DipoleMomentS1": "4.6",
      "DipoleMomentT1": "10.16",
      "ionizationPotential": "6.85",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GSYMHWKULSIHAR-UHFFFAOYSA-N",
      "Smiles": "Cl/N=C\\[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Cl/N=C\\[C@]1(Cl)N=N1",
      "HOMO": "-8.1",
      "LUMO": "-2.76",
      "VEE": "3.39",
      "Lmax": "365.722713864307",
      "E00S1": "3.36",
      "E00T1": "",
      "Eox": "3.16",
      "Ered": "",
      "ES1ox": "-0.2",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.68",
      "DipoleMomentS1": "2.68",
      "DipoleMomentT1": "4.88",
      "ionizationPotential": "9.73",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "GTDRZOSVSKXEGR-UHFFFAOYSA-N",
      "Smiles": "FC1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)F",
      "HOMO": "-5.62",
      "LUMO": "-2.96",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.77",
      "E00T1": "",
      "Eox": "0.79",
      "Ered": "",
      "ES1ox": "-0.98",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.96",
      "DipoleMomentS1": "2.96",
      "DipoleMomentT1": "1.99",
      "ionizationPotential": "6.62",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "GTOKMJVJONOWOK-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)C)c1ncc(s1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)C)c1ncc(s1)C",
      "HOMO": "-6.68",
      "LUMO": "-3.24",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "2.68",
      "E00T1": "",
      "Eox": "1.99",
      "Ered": "",
      "ES1ox": "-0.69",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.18",
      "DipoleMomentS1": "3.18",
      "DipoleMomentT1": "7.01",
      "ionizationPotential": "7.54",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GTXFVWXIZLJDNS-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)[N+](=O)[O-]",
      "HOMO": "-5.65",
      "LUMO": "-2.9",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "0.25",
      "Eox": "0.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "14.63",
      "DipoleMomentS1": "14.63",
      "DipoleMomentT1": "16.38",
      "ionizationPotential": "6.64",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "GUAZFJWBAQFCFA-MVJHLKBCSA-N",
      "Smiles": "FC(Oc1cc([nH]c1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cc([nH]c1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "HOMO": "-5.94",
      "LUMO": "-2.45",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.99",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.98",
      "DipoleMomentS1": "6.98",
      "DipoleMomentT1": "6.86",
      "ionizationPotential": "6.83",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "GUJPGAWZNHBKGA-VCFJNTAESA-N",
      "Smiles": "[O-][N+](=O)/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/[N+](=O)[O-]",
      "HOMO": "-5.75",
      "LUMO": "-3.39",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "1.63",
      "E00T1": "1",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "-0.73",
      "ES1red": "",
      "ET1ox": "-0.1",
      "ET1red": "",
      "DipoleMomentS0": "8.88",
      "DipoleMomentS1": "8.88",
      "DipoleMomentT1": "11.2",
      "ionizationPotential": "0.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "GUPDNHRKPLXXFK-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(nc1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(nc1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.44",
      "LUMO": "-3.35",
      "VEE": "3.87",
      "Lmax": "320.361757105943",
      "E00S1": "3.73",
      "E00T1": "",
      "Eox": "3.44",
      "Ered": "",
      "ES1ox": "-0.29",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.73",
      "DipoleMomentS1": "1.73",
      "DipoleMomentT1": "",
      "ionizationPotential": "10.05",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "GVFYPBOGKUTCEJ-NURZGURASA-N",
      "Smiles": "Cl/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C/Cl",
      "MolView_url": "http://molview.org/?q=Cl/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C/Cl",
      "HOMO": "-6.09",
      "LUMO": "-2.81",
      "VEE": "1.64",
      "Lmax": "755.975609756097",
      "E00S1": "",
      "E00T1": "0.05",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.6",
      "ET1red": "",
      "DipoleMomentS0": "6.9",
      "DipoleMomentS1": "6.9",
      "DipoleMomentT1": "6.27",
      "ionizationPotential": "6.41",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "GVGMJWVXCUIMEY-BYYHNAKLSA-N",
      "Smiles": "FC(Oc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "HOMO": "-5.69",
      "LUMO": "-2.86",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "2.14",
      "E00T1": "0.82",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "-1.33",
      "ES1red": "",
      "ET1ox": "-0.01",
      "ET1red": "",
      "DipoleMomentS0": "0.01",
      "DipoleMomentS1": "0.01",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GVVDRAQKLSEOPK-FMQUCBEESA-N",
      "Smiles": "O=Cn1c(ccc1[N+](=O)[O-])c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1[N+](=O)[O-])c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)[N+](=O)[O-]",
      "HOMO": "-6.24",
      "LUMO": "-3.66",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "1.94",
      "E00T1": "0.66",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "-0.66",
      "ES1red": "",
      "ET1ox": "0.62",
      "ET1red": "",
      "DipoleMomentS0": "11.19",
      "DipoleMomentS1": "11.19",
      "DipoleMomentT1": "11.26",
      "ionizationPotential": "7.23",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GVVUIQWXEWFLHB-DQRAZIAOSA-N",
      "Smiles": "COc1cnc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(o1)OC",
      "HOMO": "-5.47",
      "LUMO": "-2.58",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "0.34",
      "Eox": "-0.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.35",
      "ET1red": "",
      "DipoleMomentS0": "9.82",
      "DipoleMomentS1": "9.82",
      "DipoleMomentT1": "7.89",
      "ionizationPotential": "5.41",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "GVVXXTADRCHNIK-FOCLMDBBSA-N",
      "Smiles": "FC#Cc1ccc(o1)/N=N/c1ccc(o1)C#CF",
      "MolView_url": "http://molview.org/?q=FC#Cc1ccc(o1)/N=N/c1ccc(o1)C#CF",
      "HOMO": "-6.1",
      "LUMO": "-3.01",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "",
      "E00T1": "0.85",
      "Eox": "1.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.32",
      "ET1red": "",
      "DipoleMomentS0": "3.34",
      "DipoleMomentS1": "3.34",
      "DipoleMomentT1": "3.05",
      "ionizationPotential": "7.09",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "GWBJMQDLEXTALJ-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)S",
      "HOMO": "-5.31",
      "LUMO": "-2.44",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.99",
      "E00T1": "1.2",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.22",
      "DipoleMomentS1": "4.22",
      "DipoleMomentT1": "3.84",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "GWESHPBEXINFMB-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)F)C1=CC=C(C1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)F)C1=CC=C(C1)F",
      "HOMO": "-6.09",
      "LUMO": "-3.06",
      "VEE": "1.89",
      "Lmax": "655.978835978836",
      "E00S1": "2.31",
      "E00T1": "1.78",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.56",
      "DipoleMomentS1": "0.56",
      "DipoleMomentT1": "4.8",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GWIXNQMPRMEINC-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1csc(n1)c1cc2c3cc(c4scc(n4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)OC(F)(F)F)cc(c5ccc4)c1scc(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1csc(n1)c1cc2c3cc(c4scc(n4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)OC(F)(F)F)cc(c5ccc4)c1scc(n1)OC(F)(F)F)(F)F",
      "HOMO": "-5.72",
      "LUMO": "-2.99",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "1.88",
      "E00T1": "0.25",
      "Eox": "-4.22",
      "Ered": "",
      "ES1ox": "-6.1",
      "ES1red": "",
      "ET1ox": "-4.47",
      "ET1red": "",
      "DipoleMomentS0": "10.36",
      "DipoleMomentS1": "10.36",
      "DipoleMomentT1": "7.81",
      "ionizationPotential": "0.09",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GWRHHXVEARIYKY-UHFFFAOYSA-N",
      "Smiles": "N#CC#Cc1cc2c3cc(C#CC#N)c4c5c3c(c3c2c2c1cccc2c(c3)C#CC#N)cc(c5ccc4)C#CC#N",
      "MolView_url": "http://molview.org/?q=N#CC#Cc1cc2c3cc(C#CC#N)c4c5c3c(c3c2c2c1cccc2c(c3)C#CC#N)cc(c5ccc4)C#CC#N",
      "HOMO": "-6.09",
      "LUMO": "-3.54",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.41",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GXLPSLWMEHRPAU-FMQUCBEESA-N",
      "Smiles": "Clc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)Cl",
      "HOMO": "-6.23",
      "LUMO": "-3.36",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.43",
      "ET1red": "",
      "DipoleMomentS0": "5.38",
      "DipoleMomentS1": "5.38",
      "DipoleMomentT1": "5.35",
      "ionizationPotential": "7.06",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "GXLPSLWMEHRPAU-VXPUYCOJSA-N",
      "Smiles": "Clc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)Cl",
      "HOMO": "-6.19",
      "LUMO": "-3.25",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.96",
      "ET1red": "",
      "DipoleMomentS0": "5.53",
      "DipoleMomentS1": "5.53",
      "DipoleMomentT1": "5.73",
      "ionizationPotential": "7",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "GYKAGIQHHIDDOW-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cc([nH]c1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cc([nH]c1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "HOMO": "-6.36",
      "LUMO": "-3.13",
      "VEE": "2.23",
      "Lmax": "555.964125560538",
      "E00S1": "2.25",
      "E00T1": "",
      "Eox": "-4.17",
      "Ered": "",
      "ES1ox": "-6.42",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.56",
      "DipoleMomentS1": "4.56",
      "DipoleMomentT1": "4.24",
      "ionizationPotential": "7.41",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GYSCVFSEIABAAS-IAZLARGGSA-N",
      "Smiles": "F/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C/F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C\\F)cc(c5ccc4)/C=C\\O/C=C/F",
      "MolView_url": "http://molview.org/?q=F/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C/F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C\\F)cc(c5ccc4)/C=C\\O/C=C/F",
      "HOMO": "-5.22",
      "LUMO": "-2.51",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "1.89",
      "E00T1": "0.22",
      "Eox": "0.34",
      "Ered": "",
      "ES1ox": "-1.55",
      "ES1red": "",
      "ET1ox": "0.12",
      "ET1red": "",
      "DipoleMomentS0": "5.35",
      "DipoleMomentS1": "5.35",
      "DipoleMomentT1": "5.2",
      "ionizationPotential": "5.85",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "GZJJJOPTJVVSJD-DQRAZIAOSA-N",
      "Smiles": "c1csc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nccs1",
      "MolView_url": "http://molview.org/?q=c1csc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nccs1",
      "HOMO": "-6.31",
      "LUMO": "-2.66",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "0.53",
      "Eox": "1.33",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.8",
      "ET1red": "",
      "DipoleMomentS0": "4.02",
      "DipoleMomentS1": "4.02",
      "DipoleMomentT1": "2.24",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "GZJJJOPTJVVSJD-QURGRASLSA-N",
      "Smiles": "c1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nccs1",
      "MolView_url": "http://molview.org/?q=c1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nccs1",
      "HOMO": "-6.4",
      "LUMO": "-2.95",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.85",
      "DipoleMomentS1": "3.85",
      "DipoleMomentT1": "5.03",
      "ionizationPotential": "7.19",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "GZTAJJBXXKZHCG-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)F)c1ccc(cc1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)F)c1ccc(cc1)F",
      "HOMO": "-6.89",
      "LUMO": "-3.12",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.22",
      "DipoleMomentS1": "1.22",
      "DipoleMomentT1": "2.14",
      "ionizationPotential": "7.87",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "GZZZUAYKSQDXSH-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1C(F)(F)F)c1cc2c3cc(c4ccc(n4C=O)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)C(F)(F)F)cc(c5ccc4)c1ccc(n1C=O)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1C(F)(F)F)c1cc2c3cc(c4ccc(n4C=O)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)C(F)(F)F)cc(c5ccc4)c1ccc(n1C=O)C(F)(F)F",
      "HOMO": "-6.32",
      "LUMO": "-3.16",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "2.16",
      "E00T1": "0.24",
      "Eox": "-4.24",
      "Ered": "",
      "ES1ox": "-6.4",
      "ES1red": "",
      "ET1ox": "-4.48",
      "ET1red": "",
      "DipoleMomentS0": "13",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "12.81",
      "ionizationPotential": "7.25",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HAFABZVPDVGUEG-UHFFFAOYSA-N",
      "Smiles": "FC(OC1=CC=C(C1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(C1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-6.59",
      "LUMO": "-2.57",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "2.58",
      "E00T1": "",
      "Eox": "1.61",
      "Ered": "",
      "ES1ox": "-0.97",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.36",
      "DipoleMomentS1": "1.36",
      "DipoleMomentT1": "10.08",
      "ionizationPotential": "8.07",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HAKHDYMNYFOMTG-UHFFFAOYSA-N",
      "Smiles": "c1cnc(nc1)c1cc2c3cc(c4ncccn4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncccn1)cc(c5ccc4)c1ncccn1",
      "MolView_url": "http://molview.org/?q=c1cnc(nc1)c1cc2c3cc(c4ncccn4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncccn1)cc(c5ccc4)c1ncccn1",
      "HOMO": "-5.54",
      "LUMO": "-2.7",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "2",
      "E00T1": "0.24",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "-1.27",
      "ES1red": "",
      "ET1ox": "0.49",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.03",
      "ionizationPotential": "5.94",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HAWOUKVUFDMLAZ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(o1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)[C@]1(C#N)N=N1",
      "HOMO": "-7.4",
      "LUMO": "-2.99",
      "VEE": "3.79",
      "Lmax": "327.12401055409",
      "E00S1": "2.92",
      "E00T1": "2.38",
      "Eox": "2.52",
      "Ered": "",
      "ES1ox": "-0.4",
      "ES1red": "",
      "ET1ox": "0.14",
      "ET1red": "",
      "DipoleMomentS0": "2.99",
      "DipoleMomentS1": "2.99",
      "DipoleMomentT1": "8.53",
      "ionizationPotential": "9.18",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HBBIETJUGOKZNE-DQRAZIAOSA-N",
      "Smiles": "Cc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)C",
      "HOMO": "-5.63",
      "LUMO": "-2.9",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.5",
      "ET1red": "",
      "DipoleMomentS0": "13.26",
      "DipoleMomentS1": "13.26",
      "DipoleMomentT1": "9.75",
      "ionizationPotential": "6.3",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "HBBIETJUGOKZNE-QURGRASLSA-N",
      "Smiles": "Cc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)C",
      "HOMO": "-5.57",
      "LUMO": "-2.91",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "",
      "E00T1": "0.75",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.09",
      "ET1red": "",
      "DipoleMomentS0": "8.22",
      "DipoleMomentS1": "8.22",
      "DipoleMomentT1": "8.32",
      "ionizationPotential": "6.25",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HBCUCHMJZOPPDI-QURGRASLSA-N",
      "Smiles": "Fc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)F",
      "HOMO": "-5.27",
      "LUMO": "-2.66",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "1.96",
      "E00T1": "",
      "Eox": "0.44",
      "Ered": "",
      "ES1ox": "-1.52",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "HBEHYLCYNROXEP-DQSJHHFOSA-N",
      "Smiles": "COc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)OC",
      "HOMO": "-6.11",
      "LUMO": "-2.43",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "0.55",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.58",
      "ET1red": "",
      "DipoleMomentS0": "11.54",
      "DipoleMomentS1": "11.54",
      "DipoleMomentT1": "9.61",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "HBKJTYUWYTUFES-QVIHXGFCSA-N",
      "Smiles": "FC(Oc1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)OC(F)(F)F)(F)F",
      "HOMO": "-5.87",
      "LUMO": "-2.94",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.45",
      "DipoleMomentS1": "1.45",
      "DipoleMomentT1": "0.89",
      "ionizationPotential": "6.77",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "HBOYHZWBIROGJU-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)C)C#N)C1=CC=C(S1(=O)=O)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)C)C#N)C1=CC=C(S1(=O)=O)C",
      "HOMO": "-7.25",
      "LUMO": "-3.15",
      "VEE": "3.22",
      "Lmax": "385.031055900621",
      "E00S1": "",
      "E00T1": "2.27",
      "Eox": "2.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.05",
      "ET1red": "",
      "DipoleMomentS0": "13.84",
      "DipoleMomentS1": "13.84",
      "DipoleMomentT1": "12.99",
      "ionizationPotential": "8.38",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HBYUJAQQAZNDBM-UHFFFAOYSA-N",
      "Smiles": "COc1ccc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc([nH]1)OC)c1ccc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc([nH]1)OC)c1ccc([nH]1)OC",
      "HOMO": "-5.18",
      "LUMO": "-2.29",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.71",
      "DipoleMomentS1": "8.71",
      "DipoleMomentT1": "10.04",
      "ionizationPotential": "5.68",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "HCBHMKNNZIRFPS-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(cn1)C(F)(F)F)C#N)c1ncc(cn1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(cn1)C(F)(F)F)C#N)c1ncc(cn1)C(F)(F)F",
      "HOMO": "-8.01",
      "LUMO": "-2.9",
      "VEE": "3.95",
      "Lmax": "313.873417721519",
      "E00S1": "",
      "E00T1": "2.51",
      "Eox": "2.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.4",
      "ET1red": "",
      "DipoleMomentS0": "0.03",
      "DipoleMomentS1": "0.03",
      "DipoleMomentT1": "3.14",
      "ionizationPotential": "8.3",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HEIWLSGNGZTKCT-UHFFFAOYSA-N",
      "Smiles": "Clc1ncc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cnc([nH]1)Cl)c1cnc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ncc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cnc([nH]1)Cl)c1cnc([nH]1)Cl",
      "HOMO": "-5.59",
      "LUMO": "-2.46",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.11",
      "Eox": "1.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.14",
      "ET1red": "",
      "DipoleMomentS0": "5.82",
      "DipoleMomentS1": "5.82",
      "DipoleMomentT1": "6.92",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "HELGZBGENGGFCK-JBWWEBJPSA-N",
      "Smiles": "N#Cc1cc(C=N)c(c(c1)C=N)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(C=N)c(c(c1)C=N)C#N",
      "HOMO": "-8.12",
      "LUMO": "-2.83",
      "VEE": "4.21",
      "Lmax": "294.489311163895",
      "E00S1": "",
      "E00T1": "2.67",
      "Eox": "2.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.24",
      "ET1red": "",
      "DipoleMomentS0": "2.34",
      "DipoleMomentS1": "2.34",
      "DipoleMomentT1": "2.36",
      "ionizationPotential": "9.63",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HEMWNHDBVGXLBD-UHFFFAOYSA-N",
      "Smiles": "Cc1coc(n1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1coc(n1)[C@]1(C)N=N1",
      "HOMO": "-6.9",
      "LUMO": "-2.26",
      "VEE": "3.2",
      "Lmax": "387.4375",
      "E00S1": "3.02",
      "E00T1": "",
      "Eox": "1.95",
      "Ered": "",
      "ES1ox": "-1.07",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.17",
      "DipoleMomentS1": "2.17",
      "DipoleMomentT1": "8.3",
      "ionizationPotential": "8.31",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HEPFSVBAEWXDNQ-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)C(F)(F)F)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)C(F)(F)F)C(F)(F)F",
      "HOMO": "-7.04",
      "LUMO": "-3.28",
      "VEE": "2.52",
      "Lmax": "491.984126984127",
      "E00S1": "2.64",
      "E00T1": "",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.47",
      "DipoleMomentS1": "7.47",
      "DipoleMomentT1": "4.95",
      "ionizationPotential": "8.05",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "HERFIKXKFNKIPR-UHFFFAOYSA-N",
      "Smiles": "ClC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)Cl)cc(c5ccc4)C1=CC=C(S1(=O)=O)Cl)C1=CC=C(S1(=O)=O)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)Cl)cc(c5ccc4)C1=CC=C(S1(=O)=O)Cl)C1=CC=C(S1(=O)=O)Cl",
      "HOMO": "-5.83",
      "LUMO": "-3.25",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "1.74",
      "E00T1": "",
      "Eox": "1.01",
      "Ered": "",
      "ES1ox": "-0.73",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.62",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HEVIVLPZMOZKGV-ISLYRVAYSA-N",
      "Smiles": "N#CC#Cc1ccc(o1)/N=N/c1ccc(o1)C#CC#N",
      "MolView_url": "http://molview.org/?q=N#CC#Cc1ccc(o1)/N=N/c1ccc(o1)C#CC#N",
      "HOMO": "-6.61",
      "LUMO": "-3.61",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "2.28",
      "E00T1": "0.85",
      "Eox": "1.73",
      "Ered": "",
      "ES1ox": "-0.55",
      "ES1red": "",
      "ET1ox": "0.88",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.8",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "HEYBXGUENUHMSY-WCWDXBQESA-N",
      "Smiles": "Clc1ccc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(s1)Cl",
      "HOMO": "-6.12",
      "LUMO": "-2.8",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.14",
      "ET1red": "",
      "DipoleMomentS0": "2.64",
      "DipoleMomentS1": "2.64",
      "DipoleMomentT1": "3.34",
      "ionizationPotential": "6.88",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "HFACAUQOPJEOOP-UHFFFAOYSA-N",
      "Smiles": "FC(C1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "HOMO": "-5.73",
      "LUMO": "-3.31",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "",
      "E00T1": "0.94",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.93",
      "DipoleMomentS1": "10.93",
      "DipoleMomentT1": "5.86",
      "ionizationPotential": "6.83",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "HFVPXLCLVBEXOY-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(C1)C)C#N)C1=CC=C(C1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(C1)C)C#N)C1=CC=C(C1)C",
      "HOMO": "-5.88",
      "LUMO": "-2.69",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "1.95",
      "Eox": "-1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.22",
      "ET1red": "",
      "DipoleMomentS0": "1.4",
      "DipoleMomentS1": "1.4",
      "DipoleMomentT1": "3.21",
      "ionizationPotential": "7.18",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HHFRPCBQXYSDCN-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cccn1C=O)C#N)c1cccn1C=O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cccn1C=O)C#N)c1cccn1C=O",
      "HOMO": "-6.55",
      "LUMO": "-2.92",
      "VEE": "3.19",
      "Lmax": "388.652037617555",
      "E00S1": "",
      "E00T1": "2.04",
      "Eox": "-0.99",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.03",
      "ET1red": "",
      "DipoleMomentS0": "4.07",
      "DipoleMomentS1": "4.07",
      "DipoleMomentT1": "7.72",
      "ionizationPotential": "7.98",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HHILBPYPKBAHDX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1scc(n1)Cl)C#N)c1scc(n1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1scc(n1)Cl)C#N)c1scc(n1)Cl",
      "HOMO": "-6.88",
      "LUMO": "-3.3",
      "VEE": "3.58",
      "Lmax": "346.312849162011",
      "E00S1": "",
      "E00T1": "2.38",
      "Eox": "-0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.04",
      "ET1red": "",
      "DipoleMomentS0": "6.89",
      "DipoleMomentS1": "6.89",
      "DipoleMomentT1": "7.71",
      "ionizationPotential": "8.33",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HHJMACYXOVCELS-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc([nH]1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1ccc([nH]1)[C@]1(Cl)N=N1",
      "HOMO": "-6.47",
      "LUMO": "-2.56",
      "VEE": "3.37",
      "Lmax": "367.893175074184",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.61",
      "DipoleMomentS1": "1.61",
      "DipoleMomentT1": "10.79",
      "ionizationPotential": "7.96",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HHPNCAZFTRXBAF-UHFFFAOYSA-N",
      "Smiles": "c1coc(c1)c1cc2c3cc(c4ccco4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccco1)cc(c5ccc4)c1ccco1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)c1cc2c3cc(c4ccco4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccco1)cc(c5ccc4)c1ccco1",
      "HOMO": "-5.32",
      "LUMO": "-2.61",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "1.87",
      "E00T1": "",
      "Eox": "0.47",
      "Ered": "",
      "ES1ox": "-1.4",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.47",
      "DipoleMomentS1": "2.47",
      "DipoleMomentT1": "2.33",
      "ionizationPotential": "5.91",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HIDNLJPCFPOABN-QPLCGJKRSA-N",
      "Smiles": "Clc1ccc(nc1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(cn1)Cl",
      "HOMO": "-5.7",
      "LUMO": "-2.7",
      "VEE": "2.49",
      "Lmax": "497.911646586345",
      "E00S1": "",
      "E00T1": "0.33",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.75",
      "DipoleMomentS1": "6.75",
      "DipoleMomentT1": "4.76",
      "ionizationPotential": "6.28",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "HIIQRJZJKGEARV-BUHFOSPRSA-N",
      "Smiles": "OC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CO",
      "MolView_url": "http://molview.org/?q=OC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CO",
      "HOMO": "-5.8",
      "LUMO": "-2.89",
      "VEE": "3.04",
      "Lmax": "407.828947368421",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "0.95",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "3.9",
      "DipoleMomentS1": "3.9",
      "DipoleMomentT1": "3.87",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HIIQRJZJKGEARV-YPKPFQOOSA-N",
      "Smiles": "OC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CO",
      "MolView_url": "http://molview.org/?q=OC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CO",
      "HOMO": "-5.91",
      "LUMO": "-2.91",
      "VEE": "1.45",
      "Lmax": "855.034482758621",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.67",
      "DipoleMomentS1": "3.67",
      "DipoleMomentT1": "2.95",
      "ionizationPotential": "6.51",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "HIRLFXMJOOSGRN-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(nc1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(nc1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-8.19",
      "LUMO": "-2.82",
      "VEE": "3.54",
      "Lmax": "350.225988700565",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.11",
      "DipoleMomentS1": "3.11",
      "DipoleMomentT1": "4.74",
      "ionizationPotential": "9.38",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HJWFJDWBRCYNKW-BUHFOSPRSA-N",
      "Smiles": "C=Cc1ccc(s1)/N=N/c1ccc(s1)C=C",
      "MolView_url": "http://molview.org/?q=C=Cc1ccc(s1)/N=N/c1ccc(s1)C=C",
      "HOMO": "-5.95",
      "LUMO": "-3.04",
      "VEE": "3.13",
      "Lmax": "396.102236421725",
      "E00S1": "",
      "E00T1": "0.75",
      "Eox": "0.98",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.23",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.8",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HJWFJDWBRCYNKW-YPKPFQOOSA-N",
      "Smiles": "C=Cc1ccc(s1)/N=N\\c1ccc(s1)C=C",
      "MolView_url": "http://molview.org/?q=C=Cc1ccc(s1)/N=N\\c1ccc(s1)C=C",
      "HOMO": "-5.92",
      "LUMO": "-3",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "0.13",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.71",
      "ET1red": "",
      "DipoleMomentS0": "6.32",
      "DipoleMomentS1": "6.32",
      "DipoleMomentT1": "5.22",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "HKALOLIZFKAIBY-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)S)c1ncc(s1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)S)c1ncc(s1)S",
      "HOMO": "-6.36",
      "LUMO": "-3.26",
      "VEE": "2.26",
      "Lmax": "548.58407079646",
      "E00S1": "2.44",
      "E00T1": "1.77",
      "Eox": "1.67",
      "Ered": "",
      "ES1ox": "-0.77",
      "ES1red": "",
      "ET1ox": "-0.1",
      "ET1red": "",
      "DipoleMomentS0": "1.86",
      "DipoleMomentS1": "1.86",
      "DipoleMomentT1": "18.2",
      "ionizationPotential": "7.36",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "HKWYHBFIHMYDIK-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cnc1)c1[nH]cnc1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cnc1)c1[nH]cnc1",
      "HOMO": "-6.51",
      "LUMO": "-3.16",
      "VEE": "2.25",
      "Lmax": "551.022222222222",
      "E00S1": "2.56",
      "E00T1": "2.02",
      "Eox": "1.68",
      "Ered": "",
      "ES1ox": "-0.88",
      "ES1red": "",
      "ET1ox": "-0.34",
      "ET1red": "",
      "DipoleMomentS0": "4.42",
      "DipoleMomentS1": "4.42",
      "DipoleMomentT1": "8.21",
      "ionizationPotential": "7.58",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "HLJILERUQKKMNV-YADVMQPKSA-N",
      "Smiles": "FC(O/N=C\\c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(/C=N\\OC(F)(F)F)ccc3c1c2)/C=N\\OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C\\c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(/C=N\\OC(F)(F)F)ccc3c1c2)/C=N\\OC(F)(F)F)(F)F",
      "HOMO": "-5.83",
      "LUMO": "-2.77",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.98",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.36",
      "DipoleMomentS1": "7.36",
      "DipoleMomentT1": "9.94",
      "ionizationPotential": "7.03",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "HLMUCIBHYYKEMX-OCEACIFDSA-N",
      "Smiles": "FC(OC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#COC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#COC(F)(F)F)(F)F",
      "HOMO": "-6.56",
      "LUMO": "-2.83",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "1.42",
      "ionizationPotential": "7.43",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "HLTHIYIGVAIKPD-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cc1)[N+](=O)[O-])C#N)c1ccc(cc1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cc1)[N+](=O)[O-])C#N)c1ccc(cc1)[N+](=O)[O-]",
      "HOMO": "-7.81",
      "LUMO": "-3.12",
      "VEE": "3.9",
      "Lmax": "317.897435897436",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.32",
      "DipoleMomentS1": "5.32",
      "DipoleMomentT1": "6.22",
      "ionizationPotential": "9.21",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HMGFWPOXOGPALO-FMQUCBEESA-N",
      "Smiles": "Cc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)C",
      "HOMO": "-6",
      "LUMO": "-3.11",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.06",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.58",
      "DipoleMomentS1": "2.58",
      "DipoleMomentT1": "2.6",
      "ionizationPotential": "6.7",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "HMGFWPOXOGPALO-VXPUYCOJSA-N",
      "Smiles": "Cc1coc(n1)c1ccc(o1)/N=N\\c1ccc(o1)c1occ(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1coc(n1)c1ccc(o1)/N=N\\c1ccc(o1)c1occ(n1)C",
      "HOMO": "-5.92",
      "LUMO": "-3.15",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.66",
      "ET1red": "",
      "DipoleMomentS0": "6.91",
      "DipoleMomentS1": "6.91",
      "DipoleMomentT1": "5.33",
      "ionizationPotential": "0",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "HNAWZOLTHHDZLP-VHXPQNKSSA-N",
      "Smiles": "n1cc([nH]c1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1[nH]cnc1",
      "MolView_url": "http://molview.org/?q=n1cc([nH]c1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1[nH]cnc1",
      "HOMO": "-6.04",
      "LUMO": "-2.46",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "0.51",
      "Eox": "1.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.56",
      "ET1red": "",
      "DipoleMomentS0": "6.59",
      "DipoleMomentS1": "6.59",
      "DipoleMomentT1": "6.56",
      "ionizationPotential": "6.84",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "HNAWZOLTHHDZLP-WCWDXBQESA-N",
      "Smiles": "n1cc([nH]c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1[nH]cnc1",
      "MolView_url": "http://molview.org/?q=n1cc([nH]c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1[nH]cnc1",
      "HOMO": "-6.07",
      "LUMO": "-2.7",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "1.08",
      "Eox": "1.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.1",
      "ET1red": "",
      "DipoleMomentS0": "2.11",
      "DipoleMomentS1": "2.11",
      "DipoleMomentT1": "1.32",
      "ionizationPotential": "6.93",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "HNDZSZIWHLZPFS-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(s1)OC(F)(F)F)C#N)c1ccc(s1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(s1)OC(F)(F)F)C#N)c1ccc(s1)OC(F)(F)F",
      "HOMO": "-6.74",
      "LUMO": "-3",
      "VEE": "3.36",
      "Lmax": "368.988095238095",
      "E00S1": "",
      "E00T1": "2.48",
      "Eox": "-1.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.49",
      "ET1red": "",
      "DipoleMomentS0": "2.86",
      "DipoleMomentS1": "2.86",
      "DipoleMomentT1": "4.72",
      "ionizationPotential": "8.03",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HNGBANBOPXOZKU-FMQUCBEESA-N",
      "Smiles": "Cc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)C",
      "HOMO": "-5.46",
      "LUMO": "-2.8",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "1.98",
      "E00T1": "0.73",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "-1.45",
      "ES1red": "",
      "ET1ox": "-0.2",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "HNQFQLQSWPUZAZ-UHFFFAOYSA-N",
      "Smiles": "Cl[B@@-]12n3c4cc5n1c(cc1-c6c(-c([n+]21)cc3c1c4ccc(c1)c1ncc(o1)OC(F)(F)F)ccc(c6)c1ncc(o1)OC(F)(F)F)c1c5cc(cc1)c1ncc(o1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=Cl[B@@-]12n3c4cc5n1c(cc1-c6c(-c([n+]21)cc3c1c4ccc(c1)c1ncc(o1)OC(F)(F)F)ccc(c6)c1ncc(o1)OC(F)(F)F)c1c5cc(cc1)c1ncc(o1)OC(F)(F)F",
      "HOMO": "-5.75",
      "LUMO": "-2.63",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "1.61",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.7",
      "ET1red": "",
      "DipoleMomentS0": "5.97",
      "DipoleMomentS1": "5.97",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.61",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "HOFOMOSKBNEFIX-UHFFFAOYSA-N",
      "Smiles": "Cc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)C",
      "HOMO": "-5.33",
      "LUMO": "-2.45",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "2.01",
      "E00T1": "1.2",
      "Eox": "0.51",
      "Ered": "",
      "ES1ox": "-1.5",
      "ES1red": "",
      "ET1ox": "-0.69",
      "ET1red": "",
      "DipoleMomentS0": "6.78",
      "DipoleMomentS1": "6.78",
      "DipoleMomentT1": "6.72",
      "ionizationPotential": "6.04",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "HOPCHDDWOUAPGM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(o1)C#N",
      "HOMO": "-7.37",
      "LUMO": "-3.47",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.55",
      "DipoleMomentS1": "4.55",
      "DipoleMomentT1": "2.24",
      "ionizationPotential": "8.54",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "HOSBRXAMJXQUKU-FMQUCBEESA-N",
      "Smiles": "COc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)OC",
      "HOMO": "-5.79",
      "LUMO": "-3.24",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "0.71",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.21",
      "ET1red": "",
      "DipoleMomentS0": "2.39",
      "DipoleMomentS1": "2.39",
      "DipoleMomentT1": "2",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HOSBRXAMJXQUKU-VXPUYCOJSA-N",
      "Smiles": "COc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)OC",
      "HOMO": "-5.82",
      "LUMO": "-3.22",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.12",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.78",
      "ET1red": "",
      "DipoleMomentS0": "5.02",
      "DipoleMomentS1": "5.02",
      "DipoleMomentT1": "4.44",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "HPBZGQTXBGDSSH-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)C",
      "HOMO": "-5.29",
      "LUMO": "-2.39",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.01",
      "E00T1": "1.23",
      "Eox": "0.43",
      "Ered": "",
      "ES1ox": "-1.58",
      "ES1red": "",
      "ET1ox": "-0.8",
      "ET1red": "",
      "DipoleMomentS0": "1.05",
      "DipoleMomentS1": "1.05",
      "DipoleMomentT1": "2.42",
      "ionizationPotential": "5.9",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "HQKAQFXKDDDRRA-UHFFFAOYSA-N",
      "Smiles": "Cc1cnc(s1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1cnc(s1)[C@]1(C)N=N1",
      "HOMO": "-6.82",
      "LUMO": "-2.26",
      "VEE": "3.23",
      "Lmax": "383.839009287926",
      "E00S1": "2.94",
      "E00T1": "",
      "Eox": "1.83",
      "Ered": "",
      "ES1ox": "-1.11",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.52",
      "DipoleMomentS1": "2.52",
      "DipoleMomentT1": "8.35",
      "ionizationPotential": "8.12",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HQNBDAOJHKGJIX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(o1)O)C#N)c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(o1)O)C#N)c1ncc(o1)O",
      "HOMO": "-6.39",
      "LUMO": "-2.95",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.62",
      "DipoleMomentS1": "3.62",
      "DipoleMomentT1": "5.31",
      "ionizationPotential": "7.83",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HQNLKYGVRZXMBK-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)[N+](=O)[O-])C1=CC=C(C1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)[N+](=O)[O-])C1=CC=C(C1)[N+](=O)[O-]",
      "HOMO": "-5.73",
      "LUMO": "-3.22",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "1.39",
      "Eox": "0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.5",
      "ET1red": "",
      "DipoleMomentS0": "8.24",
      "DipoleMomentS1": "8.24",
      "DipoleMomentT1": "4.19",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "HQZNRWKTEBNXIH-QURGRASLSA-N",
      "Smiles": "COc1ccc(n1C=O)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C=O)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)OC",
      "HOMO": "-5.33",
      "LUMO": "-2.76",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "1.89",
      "E00T1": "0.66",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "1.9",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "HRJYFAMWZAJLLV-UHFFFAOYSA-N",
      "Smiles": "SC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)S)cc(c5ccc4)C1=CC=C(S1(=O)=O)S)C1=CC=C(S1(=O)=O)S",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)S)cc(c5ccc4)C1=CC=C(S1(=O)=O)S)C1=CC=C(S1(=O)=O)S",
      "HOMO": "-5.74",
      "LUMO": "-3.18",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.31",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.09",
      "DipoleMomentS1": "8.09",
      "DipoleMomentT1": "6.2",
      "ionizationPotential": "0.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HRKIDDLONKMUSM-UHFFFAOYSA-N",
      "Smiles": "COC1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)OC",
      "HOMO": "-5.53",
      "LUMO": "-2.8",
      "VEE": "1.45",
      "Lmax": "855.034482758621",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.55",
      "DipoleMomentS1": "8.55",
      "DipoleMomentT1": "8.38",
      "ionizationPotential": "0.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "HRWLFTMZGJBJHO-QURGRASLSA-N",
      "Smiles": "FC(OC#Cc1ccc(s1)/N=N/c1ccc(s1)C#COC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC#Cc1ccc(s1)/N=N/c1ccc(s1)C#COC(F)(F)F)(F)F",
      "HOMO": "-6.33",
      "LUMO": "-3.22",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "",
      "E00T1": "0.9",
      "Eox": "1.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.35",
      "ET1red": "",
      "DipoleMomentS0": "0.1",
      "DipoleMomentS1": "0.1",
      "DipoleMomentT1": "0.03",
      "ionizationPotential": "7.05",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HRXWXWRBQHFEGV-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(F)ccc1[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=O=Cn1c(F)ccc1[C@]1(F)N=N1",
      "HOMO": "-7.17",
      "LUMO": "-2.6",
      "VEE": "3.13",
      "Lmax": "396.102236421725",
      "E00S1": "3.07",
      "E00T1": "2.21",
      "Eox": "2.05",
      "Ered": "",
      "ES1ox": "-1.02",
      "ES1red": "",
      "ET1ox": "-0.16",
      "ET1red": "",
      "DipoleMomentS0": "5.49",
      "DipoleMomentS1": "5.49",
      "DipoleMomentT1": "11.17",
      "ionizationPotential": "8.57",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HRZMDDNONNZLPA-UHFFFAOYSA-N",
      "Smiles": "FC(C#C[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C#C[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-8.67",
      "LUMO": "-3.05",
      "VEE": "3.74",
      "Lmax": "331.497326203208",
      "E00S1": "3.64",
      "E00T1": "3",
      "Eox": "3.81",
      "Ered": "",
      "ES1ox": "0.17",
      "ES1red": "",
      "ET1ox": "0.81",
      "ET1red": "",
      "DipoleMomentS0": "1.45",
      "DipoleMomentS1": "1.45",
      "DipoleMomentT1": "2.07",
      "ionizationPotential": "10.53",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HSKLKSYUIHWEBT-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)C)c1ccc(n1C)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)C)c1ccc(n1C)C",
      "HOMO": "-5.87",
      "LUMO": "-3.02",
      "VEE": "1.93",
      "Lmax": "642.383419689119",
      "E00S1": "2.15",
      "E00T1": "1.66",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "-1.03",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "5.95",
      "DipoleMomentS1": "5.95",
      "DipoleMomentT1": "11.78",
      "ionizationPotential": "6.7",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "HSMLZGGPFLRQJN-DQRAZIAOSA-N",
      "Smiles": "n1cnnc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nncnn1",
      "MolView_url": "http://molview.org/?q=n1cnnc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nncnn1",
      "HOMO": "-6.81",
      "LUMO": "-3.13",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "2.05",
      "E00T1": "1.37",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.35",
      "DipoleMomentS1": "1.35",
      "DipoleMomentT1": "1.19",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "HSMLZGGPFLRQJN-QURGRASLSA-N",
      "Smiles": "n1cnnc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nncnn1",
      "MolView_url": "http://molview.org/?q=n1cnnc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nncnn1",
      "HOMO": "-7.09",
      "LUMO": "-3.27",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "1.37",
      "Eox": "1.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.53",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.27",
      "ionizationPotential": "8",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "HSOSYTISUWKJRX-UHFFFAOYSA-N",
      "Smiles": "FC(c1csc(n1)c1cc2c3cc(c4scc(n4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)C(F)(F)F)cc(c5ccc4)c1scc(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1csc(n1)c1cc2c3cc(c4scc(n4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)C(F)(F)F)cc(c5ccc4)c1scc(n1)C(F)(F)F)(F)F",
      "HOMO": "-5.77",
      "LUMO": "-3",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.92",
      "E00T1": "0.25",
      "Eox": "1.01",
      "Ered": "",
      "ES1ox": "-0.91",
      "ES1red": "",
      "ET1ox": "0.76",
      "ET1red": "",
      "DipoleMomentS0": "6.31",
      "DipoleMomentS1": "6.31",
      "DipoleMomentT1": "5.25",
      "ionizationPotential": "6.77",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HSTFDYCYZVELDR-UHFFFAOYSA-N",
      "Smiles": "FC(c1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)C(F)(F)F)(F)F",
      "HOMO": "-5.42",
      "LUMO": "-2.48",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.62",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.29",
      "DipoleMomentS1": "4.29",
      "DipoleMomentT1": "3.16",
      "ionizationPotential": "6.4",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "HTGSWOHHMYRGCU-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)F)c1scc(n1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)F)c1scc(n1)F",
      "HOMO": "-6.92",
      "LUMO": "-3.35",
      "VEE": "2.5",
      "Lmax": "495.92",
      "E00S1": "2.75",
      "E00T1": "2.24",
      "Eox": "2.27",
      "Ered": "",
      "ES1ox": "-0.48",
      "ES1red": "",
      "ET1ox": "0.03",
      "ET1red": "",
      "DipoleMomentS0": "5.48",
      "DipoleMomentS1": "5.48",
      "DipoleMomentT1": "9.71",
      "ionizationPotential": "8",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "HTUIZLGTZNAKJD-UHFFFAOYSA-N",
      "Smiles": "O=S1(=O)C=CC=C1[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=O=S1(=O)C=CC=C1[C@@H]1N=N1",
      "HOMO": "-7.37",
      "LUMO": "-2.72",
      "VEE": "3.29",
      "Lmax": "376.838905775076",
      "E00S1": "3.2",
      "E00T1": "1.66",
      "Eox": "2.4",
      "Ered": "",
      "ES1ox": "-0.8",
      "ES1red": "",
      "ET1ox": "0.74",
      "ET1red": "",
      "DipoleMomentS0": "5.73",
      "DipoleMomentS1": "5.73",
      "DipoleMomentT1": "6.15",
      "ionizationPotential": "8.96",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HTUJKSIHUWTZGE-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)[N+](=O)[O-])c1ncc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)[N+](=O)[O-])c1ncc(cn1)[N+](=O)[O-]",
      "HOMO": "-7.78",
      "LUMO": "-3.66",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.11",
      "DipoleMomentS1": "4.11",
      "DipoleMomentT1": "8.82",
      "ionizationPotential": "8.93",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "HUBLKXPDAPASNR-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)[N+](=O)[O-]",
      "HOMO": "-5.63",
      "LUMO": "-2.8",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "",
      "E00T1": "1.22",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "15.94",
      "DipoleMomentS1": "15.94",
      "DipoleMomentT1": "17.21",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "HUDQBKPPSSJKJI-DQRAZIAOSA-N",
      "Smiles": "FC(C#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CC(F)(F)F)(F)F",
      "HOMO": "-6.5",
      "LUMO": "-3.32",
      "VEE": "1.42",
      "Lmax": "873.098591549296",
      "E00S1": "",
      "E00T1": "0.24",
      "Eox": "1.45",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.21",
      "ET1red": "",
      "DipoleMomentS0": "1.18",
      "DipoleMomentS1": "1.18",
      "DipoleMomentT1": "1.35",
      "ionizationPotential": "7.58",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "HUDQBKPPSSJKJI-QURGRASLSA-N",
      "Smiles": "FC(C#Cc1ccc(s1)/N=N/c1ccc(s1)C#CC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C#Cc1ccc(s1)/N=N/c1ccc(s1)C#CC(F)(F)F)(F)F",
      "HOMO": "-6.6",
      "LUMO": "-3.47",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.71",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HUKZUSPYXLEAJY-BUHFOSPRSA-N",
      "Smiles": "[O-][N+](=O)c1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)[N+](=O)[O-]",
      "HOMO": "-6.94",
      "LUMO": "-4.26",
      "VEE": "2.49",
      "Lmax": "497.911646586345",
      "E00S1": "",
      "E00T1": "0.77",
      "Eox": "2.05",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.28",
      "ET1red": "",
      "DipoleMomentS0": "0.12",
      "DipoleMomentS1": "0.12",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "8.21",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "HUNSVDUCLDESDX-ISLYRVAYSA-N",
      "Smiles": "OC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CO",
      "MolView_url": "http://molview.org/?q=OC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CO",
      "HOMO": "-6.19",
      "LUMO": "-2.65",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.35",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.87",
      "DipoleMomentS1": "3.87",
      "DipoleMomentT1": "6.11",
      "ionizationPotential": "6.99",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "HUYVLLDGZAKITM-FMQUCBEESA-N",
      "Smiles": "FC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)F",
      "HOMO": "-6.22",
      "LUMO": "-3.64",
      "VEE": "3.02",
      "Lmax": "410.529801324503",
      "E00S1": "",
      "E00T1": "0.51",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.76",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HUYVLLDGZAKITM-VXPUYCOJSA-N",
      "Smiles": "FC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)F",
      "HOMO": "-6.17",
      "LUMO": "-3.53",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "-0.05",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.21",
      "ET1red": "",
      "DipoleMomentS0": "6.54",
      "DipoleMomentS1": "6.54",
      "DipoleMomentT1": "0.56",
      "ionizationPotential": "7.04",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "HVRDRCPPNNOZDJ-FMQUCBEESA-N",
      "Smiles": "Clc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)Cl",
      "HOMO": "-7.1",
      "LUMO": "-3.42",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "1.41",
      "Eox": "1.95",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.54",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.31",
      "ionizationPotential": "7.95",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "HVRDRCPPNNOZDJ-VXPUYCOJSA-N",
      "Smiles": "Clc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)Cl",
      "HOMO": "-6.83",
      "LUMO": "-3.4",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "2.08",
      "E00T1": "0.56",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.19",
      "DipoleMomentS1": "1.19",
      "DipoleMomentT1": "1.07",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "HVUMGWLCAHGISO-BYYHNAKLSA-N",
      "Smiles": "FC(C1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)C(F)(F)F)(F)F",
      "HOMO": "-5.75",
      "LUMO": "-3.17",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "0.59",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.25",
      "ET1red": "",
      "DipoleMomentS0": "4.39",
      "DipoleMomentS1": "4.39",
      "DipoleMomentT1": "4.94",
      "ionizationPotential": "6.64",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HVUMGWLCAHGISO-DQSJHHFOSA-N",
      "Smiles": "FC(C1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)C(F)(F)F)(F)F",
      "HOMO": "-5.76",
      "LUMO": "-3.13",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "0.01",
      "Eox": "0.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.69",
      "ET1red": "",
      "DipoleMomentS0": "4.62",
      "DipoleMomentS1": "4.62",
      "DipoleMomentT1": "3.65",
      "ionizationPotential": "6.62",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "HVZNZCGZNIZWIY-UHFFFAOYSA-N",
      "Smiles": "COC#Cc1cc(C#N)cc(c1C#N)C#COC",
      "MolView_url": "http://molview.org/?q=COC#Cc1cc(C#N)cc(c1C#N)C#COC",
      "HOMO": "-6.7",
      "LUMO": "-2.72",
      "VEE": "3.52",
      "Lmax": "352.215909090909",
      "E00S1": "",
      "E00T1": "2.41",
      "Eox": "-1.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.58",
      "ET1red": "",
      "DipoleMomentS0": "6.84",
      "DipoleMomentS1": "6.84",
      "DipoleMomentT1": "9.7",
      "ionizationPotential": "8.05",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HWJWLMGQELRZQJ-QXMHVHEDSA-N",
      "Smiles": "c1ccc([nH]1)/N=N\\c1ccc[nH]1",
      "MolView_url": "http://molview.org/?q=c1ccc([nH]1)/N=N\\c1ccc[nH]1",
      "HOMO": "-5.82",
      "LUMO": "-2.23",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.2",
      "ET1red": "",
      "DipoleMomentS0": "5.44",
      "DipoleMomentS1": "5.44",
      "DipoleMomentT1": "5.26",
      "ionizationPotential": "6.61",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "HWOGKTMDPWAPRT-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)C(F)(F)F)cc(c5ccc4)c1ccc([nH]1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)C(F)(F)F)cc(c5ccc4)c1ccc([nH]1)C(F)(F)F)(F)F",
      "HOMO": "-5.48",
      "LUMO": "-2.64",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "1.99",
      "E00T1": "1.16",
      "Eox": "-4.25",
      "Ered": "",
      "ES1ox": "-6.24",
      "ES1red": "",
      "ET1ox": "-5.41",
      "ET1red": "",
      "DipoleMomentS0": "1.73",
      "DipoleMomentS1": "1.73",
      "DipoleMomentT1": "1.67",
      "ionizationPotential": "6.44",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HWPMBMJFRZYVMY-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)F)c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)F)c1ccc(o1)F",
      "HOMO": "-6.39",
      "LUMO": "-3.12",
      "VEE": "2.13",
      "Lmax": "582.06572769953",
      "E00S1": "2.51",
      "E00T1": "1.99",
      "Eox": "1.55",
      "Ered": "",
      "ES1ox": "-0.96",
      "ES1red": "",
      "ET1ox": "-0.44",
      "ET1red": "",
      "DipoleMomentS0": "4.66",
      "DipoleMomentS1": "4.66",
      "DipoleMomentT1": "8.27",
      "ionizationPotential": "7.46",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "HWPNYCQVNGQLOG-FMQUCBEESA-N",
      "Smiles": "Clc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)Cl",
      "HOMO": "-5.66",
      "LUMO": "-3.04",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.75",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.07",
      "DipoleMomentS1": "4.07",
      "DipoleMomentT1": "3.88",
      "ionizationPotential": "6.41",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HWPNYCQVNGQLOG-VXPUYCOJSA-N",
      "Smiles": "Clc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)Cl",
      "HOMO": "-5.67",
      "LUMO": "-2.99",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "",
      "E00T1": "0.13",
      "Eox": "0.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.51",
      "ET1red": "",
      "DipoleMomentS0": "9.98",
      "DipoleMomentS1": "9.98",
      "DipoleMomentT1": "7.92",
      "ionizationPotential": "6.36",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "HWVLTAICLALHSQ-OJYNNELHSA-N",
      "Smiles": "C=CO/C=C\\c1cc2c3cc(/C=C\\OC=C)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\OC=C)cc(c5ccc4)/C=C\\OC=C",
      "MolView_url": "http://molview.org/?q=C=CO/C=C\\c1cc2c3cc(/C=C\\OC=C)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\OC=C)cc(c5ccc4)/C=C\\OC=C",
      "HOMO": "-5.18",
      "LUMO": "-2.47",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "1.9",
      "E00T1": "0.23",
      "Eox": "-4.29",
      "Ered": "",
      "ES1ox": "-6.19",
      "ES1red": "",
      "ET1ox": "-4.52",
      "ET1red": "",
      "DipoleMomentS0": "1.78",
      "DipoleMomentS1": "1.78",
      "DipoleMomentT1": "1.83",
      "ionizationPotential": "0.14",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HXDCISMUJVUYNF-DQRAZIAOSA-N",
      "Smiles": "COC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)OC",
      "HOMO": "-5.09",
      "LUMO": "-2.79",
      "VEE": "2.28",
      "Lmax": "543.771929824561",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "11.75",
      "DipoleMomentS1": "11.75",
      "DipoleMomentT1": "3.62",
      "ionizationPotential": "5.64",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "HXDCISMUJVUYNF-QURGRASLSA-N",
      "Smiles": "COC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)OC",
      "HOMO": "-4.96",
      "LUMO": "-2.73",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.32",
      "DipoleMomentS1": "0.32",
      "DipoleMomentT1": "0.55",
      "ionizationPotential": "5.57",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "HXIWVHHUIHLLNX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)[N+](=O)[O-])C#N)c1ccc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)[N+](=O)[O-])C#N)c1ccc(cn1)[N+](=O)[O-]",
      "HOMO": "-7.94",
      "LUMO": "-3.33",
      "VEE": "3.92",
      "Lmax": "316.275510204082",
      "E00S1": "",
      "E00T1": "3.02",
      "Eox": "2.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "0.26",
      "DipoleMomentS1": "0.26",
      "DipoleMomentT1": "1.37",
      "ionizationPotential": "9.3",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HXJRZKWSHMHZJC-UHFFFAOYSA-N",
      "Smiles": "Clc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)Cl)cc(c5ccc4)c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)Cl)cc(c5ccc4)c1[nH]cc(c1)Cl",
      "HOMO": "-5.33",
      "LUMO": "-2.55",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.5",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.2",
      "DipoleMomentS1": "9.2",
      "DipoleMomentT1": "8.11",
      "ionizationPotential": "6.18",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HXOHUUAUAWGCPJ-UHFFFAOYSA-N",
      "Smiles": "F/N=C/[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=F/N=C/[C@]1(F)N=N1",
      "HOMO": "-8.59",
      "LUMO": "-2.9",
      "VEE": "3.31",
      "Lmax": "374.561933534743",
      "E00S1": "3.44",
      "E00T1": "",
      "Eox": "3.63",
      "Ered": "",
      "ES1ox": "0.19",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.41",
      "DipoleMomentS1": "2.41",
      "DipoleMomentT1": "3.6",
      "ionizationPotential": "10.45",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "HXPFPBLORLYGCU-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)Cl",
      "HOMO": "-7.07",
      "LUMO": "-3.2",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "3.01",
      "E00T1": "2.23",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.39",
      "DipoleMomentS1": "2.39",
      "DipoleMomentT1": "8.74",
      "ionizationPotential": "7.96",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "HYAWFOQGZPYVON-UHFFFAOYSA-N",
      "Smiles": "Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1[nH]ccc1)c1c3cc(cc1)c1[nH]ccc1)ccc(c5)c1[nH]ccc1",
      "MolView_url": "http://molview.org/?q=Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1[nH]ccc1)c1c3cc(cc1)c1[nH]ccc1)ccc(c5)c1[nH]ccc1",
      "HOMO": "-5.38",
      "LUMO": "-2.34",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "0.57",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.47",
      "ET1red": "",
      "DipoleMomentS0": "7.46",
      "DipoleMomentS1": "7.46",
      "DipoleMomentT1": "8.25",
      "ionizationPotential": "6.04",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "HYBJDJONYPYWHY-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(n1C)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C)C#N",
      "HOMO": "-5.56",
      "LUMO": "-2.55",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "1.26",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "11.77",
      "DipoleMomentS1": "11.77",
      "DipoleMomentT1": "11.81",
      "ionizationPotential": "6.55",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "HYDBAKXPGAMNAV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc([nH]1)C)C#N)c1ccc([nH]1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc([nH]1)C)C#N)c1ccc([nH]1)C",
      "HOMO": "-5.88",
      "LUMO": "-2.66",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "1.84",
      "Eox": "-1.33",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.17",
      "ET1red": "",
      "DipoleMomentS0": "2.66",
      "DipoleMomentS1": "2.66",
      "DipoleMomentT1": "7.94",
      "ionizationPotential": "7.09",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "HYEQBOJIIQCKNR-UHFFFAOYSA-N",
      "Smiles": "Sc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)S)cc(c5ccc4)c1[nH]cc(c1)S",
      "MolView_url": "http://molview.org/?q=Sc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)S)cc(c5ccc4)c1[nH]cc(c1)S",
      "HOMO": "-5.27",
      "LUMO": "-2.51",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "1.93",
      "E00T1": "1.14",
      "Eox": "0.44",
      "Ered": "",
      "ES1ox": "-1.49",
      "ES1red": "",
      "ET1ox": "-0.7",
      "ET1red": "",
      "DipoleMomentS0": "5.3",
      "DipoleMomentS1": "5.3",
      "DipoleMomentT1": "5.52",
      "ionizationPotential": "6.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "HZNVMDNUFYWAFJ-FMQUCBEESA-N",
      "Smiles": "Fc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)F",
      "MolView_url": "http://molview.org/?q=Fc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)F",
      "HOMO": "-7.11",
      "LUMO": "-3.5",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "1.66",
      "ionizationPotential": "8.03",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "HZNVMDNUFYWAFJ-VXPUYCOJSA-N",
      "Smiles": "Fc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)F",
      "MolView_url": "http://molview.org/?q=Fc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)F",
      "HOMO": "-6.83",
      "LUMO": "-3.49",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "2.09",
      "E00T1": "1.43",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.17",
      "DipoleMomentS1": "2.17",
      "DipoleMomentT1": "2.51",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "HZYYQRKDEWPWAH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)C#N",
      "HOMO": "-5.65",
      "LUMO": "-2.92",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.84",
      "E00T1": "1.14",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "-0.98",
      "ES1red": "",
      "ET1ox": "-0.28",
      "ET1red": "",
      "DipoleMomentS0": "10.11",
      "DipoleMomentS1": "10.11",
      "DipoleMomentT1": "11.11",
      "ionizationPotential": "6.69",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "HZZWZHSJKXCSCR-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc([nH]1)C#N",
      "HOMO": "-6.57",
      "LUMO": "-3.24",
      "VEE": "2.3",
      "Lmax": "539.04347826087",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.54",
      "DipoleMomentS1": "8.54",
      "DipoleMomentT1": "3.34",
      "ionizationPotential": "7.73",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "IAHQPZCGJHNNBI-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)Cl)cc(c5ccc4)c1ncc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)Cl)cc(c5ccc4)c1ncc(cn1)Cl",
      "HOMO": "-5.6",
      "LUMO": "-2.82",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "1.95",
      "E00T1": "1.14",
      "Eox": "0.88",
      "Ered": "",
      "ES1ox": "-1.07",
      "ES1red": "",
      "ET1ox": "-0.26",
      "ET1red": "",
      "DipoleMomentS0": "0.26",
      "DipoleMomentS1": "0.26",
      "DipoleMomentT1": "0.07",
      "ionizationPotential": "6.18",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "IALPCBPWTMFNQT-FLWNBWAVSA-N",
      "Smiles": "FC(Oc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)OC(F)(F)F)(F)F",
      "HOMO": "-6.51",
      "LUMO": "-2.74",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "0.54",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.89",
      "ET1red": "",
      "DipoleMomentS0": "1.61",
      "DipoleMomentS1": "1.61",
      "DipoleMomentT1": "2.35",
      "ionizationPotential": "7.32",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "IALPCBPWTMFNQT-QVIHXGFCSA-N",
      "Smiles": "FC(Oc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)OC(F)(F)F)(F)F",
      "HOMO": "-6.63",
      "LUMO": "-3.06",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.43",
      "DipoleMomentS1": "0.43",
      "DipoleMomentT1": "1.78",
      "ionizationPotential": "7.48",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "IANVYWJWXOMBIA-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)[N+](=O)[O-]",
      "HOMO": "-6.45",
      "LUMO": "-3.9",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "1.89",
      "E00T1": "0.73",
      "Eox": "1.6",
      "Ered": "",
      "ES1ox": "-0.29",
      "ES1red": "",
      "ET1ox": "0.87",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.55",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "IBWRTHQXOBAQGZ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C=O)S)C#N)c1ccc(n1C=O)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C=O)S)C#N)c1ccc(n1C=O)S",
      "HOMO": "-5.95",
      "LUMO": "-2.98",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "1.65",
      "Eox": "-1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.69",
      "ET1red": "",
      "DipoleMomentS0": "4.45",
      "DipoleMomentS1": "4.45",
      "DipoleMomentT1": "2.56",
      "ionizationPotential": "7.44",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ICBNKYYVJFZJDX-ISLYRVAYSA-N",
      "Smiles": "c1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1ncco1",
      "MolView_url": "http://molview.org/?q=c1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1ncco1",
      "HOMO": "-6.14",
      "LUMO": "-3.25",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "1.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.37",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.9",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ICBNKYYVJFZJDX-ZCXUNETKSA-N",
      "Smiles": "c1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncco1",
      "MolView_url": "http://molview.org/?q=c1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncco1",
      "HOMO": "-6.1",
      "LUMO": "-3.15",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "1.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.87",
      "ET1red": "",
      "DipoleMomentS0": "8.74",
      "DipoleMomentS1": "8.74",
      "DipoleMomentT1": "7.21",
      "ionizationPotential": "6.83",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "ICDUXRSYSFKATC-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)Cl)c1ncc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)Cl)c1ncc(cn1)Cl",
      "HOMO": "-7.32",
      "LUMO": "-3.28",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.45",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.97",
      "DipoleMomentS1": "0.97",
      "DipoleMomentT1": "3.61",
      "ionizationPotential": "8.24",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ICOIWDZGJKXSRU-ZCXUNETKSA-N",
      "Smiles": "Sc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)S",
      "HOMO": "-6.04",
      "LUMO": "-3.21",
      "VEE": "2.31",
      "Lmax": "536.709956709957",
      "E00S1": "",
      "E00T1": "0.18",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.73",
      "ET1red": "",
      "DipoleMomentS0": "7.29",
      "DipoleMomentS1": "7.29",
      "DipoleMomentT1": "5.53",
      "ionizationPotential": "6.65",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "ICPPAXMSXHPOPA-FMQUCBEESA-N",
      "Smiles": "O=Cn1c(O)ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)O",
      "MolView_url": "http://molview.org/?q=O=Cn1c(O)ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)O",
      "HOMO": "-5.31",
      "LUMO": "-2.8",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "1.85",
      "E00T1": "",
      "Eox": "0.39",
      "Ered": "",
      "ES1ox": "-1.46",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.08",
      "DipoleMomentS1": "3.08",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.14",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ICZJFLQLYLIILS-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1csc(n1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1csc(n1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.18",
      "LUMO": "-3.28",
      "VEE": "3.61",
      "Lmax": "343.434903047091",
      "E00S1": "3.46",
      "E00T1": "2.7",
      "Eox": "3.23",
      "Ered": "",
      "ES1ox": "-0.23",
      "ES1red": "",
      "ET1ox": "0.53",
      "ET1red": "",
      "DipoleMomentS0": "4.72",
      "DipoleMomentS1": "4.72",
      "DipoleMomentT1": "8.23",
      "ionizationPotential": "9.86",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IDIACAAPRGOIPY-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(o1)C(F)(F)F)(F)F",
      "HOMO": "-7.38",
      "LUMO": "-3.42",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "2.87",
      "E00T1": "2.49",
      "Eox": "2.44",
      "Ered": "",
      "ES1ox": "-0.43",
      "ES1red": "",
      "ET1ox": "-0.05",
      "ET1red": "",
      "DipoleMomentS0": "2.22",
      "DipoleMomentS1": "2.22",
      "DipoleMomentT1": "6.44",
      "ionizationPotential": "8.49",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "IDLQJEHZIBYYDL-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)OC",
      "HOMO": "-5.39",
      "LUMO": "-2.42",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "",
      "E00T1": "1.25",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.06",
      "DipoleMomentS1": "7.06",
      "DipoleMomentT1": "7.15",
      "ionizationPotential": "6.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "IDMYDUTURZXLQN-OCEACIFDSA-N",
      "Smiles": "FC(c1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)C(F)(F)F)(F)F",
      "HOMO": "-6.32",
      "LUMO": "-3.31",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "2.29",
      "E00T1": "0.88",
      "Eox": "1.47",
      "Ered": "",
      "ES1ox": "-0.82",
      "ES1red": "",
      "ET1ox": "0.59",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.35",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "IDRFWGJWMQMWGC-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)OC(F)(F)F)cc(c5ccc4)c1nnc(nn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)OC(F)(F)F)cc(c5ccc4)c1nnc(nn1)OC(F)(F)F)(F)F",
      "HOMO": "-5.97",
      "LUMO": "-3.53",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "1.38",
      "E00T1": "",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "-0.26",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.02",
      "DipoleMomentS1": "0.02",
      "DipoleMomentT1": "",
      "ionizationPotential": "0.01",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "IDTVAHHFYSDNMA-OCEACIFDSA-N",
      "Smiles": "FC(c1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)C(F)(F)F)(F)F",
      "HOMO": "-6.27",
      "LUMO": "-3.41",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.83",
      "Eox": "1.33",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.5",
      "ET1red": "",
      "DipoleMomentS0": "9.64",
      "DipoleMomentS1": "9.64",
      "DipoleMomentT1": "9.59",
      "ionizationPotential": "7.21",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IDTVAHHFYSDNMA-QPLCGJKRSA-N",
      "Smiles": "FC(c1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)C(F)(F)F)(F)F",
      "HOMO": "-6.22",
      "LUMO": "-3.32",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.39",
      "DipoleMomentS1": "3.39",
      "DipoleMomentT1": "2.27",
      "ionizationPotential": "7.12",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "IEKLZLNZOQBCGT-NXVVXOECSA-N",
      "Smiles": "C=Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C=C",
      "MolView_url": "http://molview.org/?q=C=Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C=C",
      "HOMO": "-5.57",
      "LUMO": "-2.56",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "1.54",
      "E00T1": "",
      "Eox": "-0.1",
      "Ered": "",
      "ES1ox": "-1.64",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.28",
      "DipoleMomentS1": "5.28",
      "DipoleMomentT1": "3",
      "ionizationPotential": "6.47",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "IEKUICNEKWZWJF-FMQUCBEESA-N",
      "Smiles": "C1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=CC1",
      "MolView_url": "http://molview.org/?q=C1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=CC1",
      "HOMO": "-5.44",
      "LUMO": "-2.95",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "0.57",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.04",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.15",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IEKUICNEKWZWJF-VXPUYCOJSA-N",
      "Smiles": "C1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=CC1",
      "MolView_url": "http://molview.org/?q=C1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=CC1",
      "HOMO": "-5.44",
      "LUMO": "-2.92",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "-0.02",
      "Eox": "0.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.45",
      "ET1red": "",
      "DipoleMomentS0": "7.41",
      "DipoleMomentS1": "7.41",
      "DipoleMomentT1": "1.34",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "IEWIDNXUTWTAAH-PGQPTCQNSA-N",
      "Smiles": "S/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/S",
      "MolView_url": "http://molview.org/?q=S/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/S",
      "HOMO": "-5.53",
      "LUMO": "-2.71",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.97",
      "E00T1": "1.17",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "-1.23",
      "ES1red": "",
      "ET1ox": "-0.43",
      "ET1red": "",
      "DipoleMomentS0": "2.35",
      "DipoleMomentS1": "2.35",
      "DipoleMomentT1": "2.55",
      "ionizationPotential": "6.39",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "IFQRVOIWVMQONV-UHFFFAOYSA-N",
      "Smiles": "FC(c1csc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1scc(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1csc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1scc(n1)C(F)(F)F)(F)F",
      "HOMO": "-7.23",
      "LUMO": "-3.38",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.63",
      "DipoleMomentS1": "6.63",
      "DipoleMomentT1": "9.68",
      "ionizationPotential": "8.39",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "IFWRZSASHGJFJC-DQRAZIAOSA-N",
      "Smiles": "FC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)F",
      "HOMO": "-5.43",
      "LUMO": "-2.92",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "-0.03",
      "Eox": "0.38",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "6.58",
      "DipoleMomentS1": "6.58",
      "DipoleMomentT1": "0.6",
      "ionizationPotential": "6.16",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "IFWRZSASHGJFJC-QURGRASLSA-N",
      "Smiles": "FC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)F",
      "HOMO": "-5.42",
      "LUMO": "-2.95",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "0.56",
      "Eox": "0.5",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "1.18",
      "DipoleMomentS1": "1.18",
      "DipoleMomentT1": "1.58",
      "ionizationPotential": "6.2",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IFXHXJDJLZHFFE-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)Cl)c1ccc(s1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)Cl)c1ccc(s1)Cl",
      "HOMO": "-6.51",
      "LUMO": "-3.18",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "2.58",
      "E00T1": "1.83",
      "Eox": "1.73",
      "Ered": "",
      "ES1ox": "-0.85",
      "ES1red": "",
      "ET1ox": "-0.1",
      "ET1red": "",
      "DipoleMomentS0": "1.31",
      "DipoleMomentS1": "1.31",
      "DipoleMomentT1": "15.65",
      "ionizationPotential": "7.45",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "IFYHNYKYIHTFJI-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)[N+](=O)[O-])c1occ(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)[N+](=O)[O-])c1occ(n1)[N+](=O)[O-]",
      "HOMO": "-7.52",
      "LUMO": "-3.47",
      "VEE": "2.5",
      "Lmax": "495.92",
      "E00S1": "3.09",
      "E00T1": "2.31",
      "Eox": "2.64",
      "Ered": "",
      "ES1ox": "-0.45",
      "ES1red": "",
      "ET1ox": "0.33",
      "ET1red": "",
      "DipoleMomentS0": "7.11",
      "DipoleMomentS1": "7.11",
      "DipoleMomentT1": "4.31",
      "ionizationPotential": "8.76",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "IGJZAOPSTCTJKB-HSFFGMMNSA-N",
      "Smiles": "F/C=C\\c1cc(C#N)cc(c1C#N)/C=C/F",
      "MolView_url": "http://molview.org/?q=F/C=C\\c1cc(C#N)cc(c1C#N)/C=C/F",
      "HOMO": "-7.02",
      "LUMO": "-2.83",
      "VEE": "3.88",
      "Lmax": "319.536082474227",
      "E00S1": "",
      "E00T1": "2.27",
      "Eox": "-1.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.29",
      "ET1red": "",
      "DipoleMomentS0": "1.9",
      "DipoleMomentS1": "1.9",
      "DipoleMomentT1": "1.7",
      "ionizationPotential": "8.79",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IGSCKDVLOLMCKO-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1[nH]cc(c1)O)C#N)c1[nH]cc(c1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1[nH]cc(c1)O)C#N)c1[nH]cc(c1)O",
      "HOMO": "-5.86",
      "LUMO": "-2.75",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.35",
      "DipoleMomentS1": "0.35",
      "DipoleMomentT1": "9.99",
      "ionizationPotential": "7.21",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IGYZOHJWQZNCLI-QURGRASLSA-N",
      "Smiles": "Fc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)F",
      "MolView_url": "http://molview.org/?q=Fc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)F",
      "HOMO": "-5.42",
      "LUMO": "-2.74",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "2.01",
      "E00T1": "0.77",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "-1.47",
      "ES1red": "",
      "ET1ox": "-0.23",
      "ET1red": "",
      "DipoleMomentS0": "6",
      "DipoleMomentS1": "6",
      "DipoleMomentT1": "6.1",
      "ionizationPotential": "6.29",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "IHEXYFCOUBVFED-DQRAZIAOSA-N",
      "Smiles": "Oc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)O",
      "HOMO": "-5.59",
      "LUMO": "-2.42",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "0.44",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.29",
      "ET1red": "",
      "DipoleMomentS0": "5.56",
      "DipoleMomentS1": "5.56",
      "DipoleMomentT1": "5.06",
      "ionizationPotential": "6.35",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "IHEXYFCOUBVFED-QURGRASLSA-N",
      "Smiles": "Oc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)O",
      "HOMO": "-5.59",
      "LUMO": "-2.61",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.79",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.8",
      "DipoleMomentS1": "3.8",
      "DipoleMomentT1": "7.3",
      "ionizationPotential": "6.36",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "IHGRWYYMOVFTTE-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(C)ccc1[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C)ccc1[C@]1(C)N=N1",
      "HOMO": "-6.63",
      "LUMO": "-2.13",
      "VEE": "3.46",
      "Lmax": "358.323699421965",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.09",
      "DipoleMomentS1": "3.09",
      "DipoleMomentT1": "6.61",
      "ionizationPotential": "7.75",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IHNWVLWAGKVUCP-FMQUCBEESA-N",
      "Smiles": "Clc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)Cl",
      "HOMO": "-6.06",
      "LUMO": "-3.31",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.53",
      "DipoleMomentS1": "2.53",
      "DipoleMomentT1": "3.38",
      "ionizationPotential": "6.84",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IHNWVLWAGKVUCP-VXPUYCOJSA-N",
      "Smiles": "Clc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)Cl",
      "HOMO": "-6.04",
      "LUMO": "-3.22",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.16",
      "DipoleMomentS1": "7.16",
      "DipoleMomentT1": "5.84",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "IHVKFDMDQIUKRT-UHFFFAOYSA-N",
      "Smiles": "N#CC1=CC=C(C1)c1cc(C#N)cc(c1C#N)C1=CC=C(C1)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(C1)c1cc(C#N)cc(c1C#N)C1=CC=C(C1)C#N",
      "HOMO": "-6.58",
      "LUMO": "-3.21",
      "VEE": "3.34",
      "Lmax": "371.197604790419",
      "E00S1": "",
      "E00T1": "2.11",
      "Eox": "-0.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.81",
      "ET1red": "",
      "DipoleMomentS0": "2.69",
      "DipoleMomentS1": "2.69",
      "DipoleMomentT1": "3.04",
      "ionizationPotential": "8.26",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IICZEFZGYNNLFJ-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(O)ccc1[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=O=Cn1c(O)ccc1[C@]1(O)N=N1",
      "HOMO": "-6.41",
      "LUMO": "-2.18",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "2.74",
      "E00T1": "",
      "Eox": "1.34",
      "Ered": "",
      "ES1ox": "-1.4",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.73",
      "DipoleMomentS1": "8.73",
      "DipoleMomentT1": "",
      "ionizationPotential": "7.73",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IIEZTXLLWBCGON-BYYHNAKLSA-N",
      "Smiles": "FC(c1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)C(F)(F)F)(F)F",
      "HOMO": "-5.77",
      "LUMO": "-2.99",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.52",
      "DipoleMomentS1": "1.52",
      "DipoleMomentT1": "1.34",
      "ionizationPotential": "6.64",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IIEZTXLLWBCGON-DQSJHHFOSA-N",
      "Smiles": "FC(c1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)C(F)(F)F)(F)F",
      "HOMO": "-5.8",
      "LUMO": "-2.99",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.37",
      "DipoleMomentS1": "4.37",
      "DipoleMomentT1": "3.76",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "IIJOFLGKCVZISI-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(s1)S)C#N)c1ncc(s1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(s1)S)C#N)c1ncc(s1)S",
      "HOMO": "-6.47",
      "LUMO": "-2.82",
      "VEE": "3.02",
      "Lmax": "410.529801324503",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.98",
      "DipoleMomentS1": "2.98",
      "DipoleMomentT1": "7.42",
      "ionizationPotential": "7.6",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IIQVBUCCEGCTSH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)C#N)C#N)c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)C#N)C#N)c1ccc(cn1)C#N",
      "HOMO": "-7.44",
      "LUMO": "-3.23",
      "VEE": "4.07",
      "Lmax": "304.619164619165",
      "E00S1": "",
      "E00T1": "2.93",
      "Eox": "-0.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.58",
      "ET1red": "",
      "DipoleMomentS0": "1.18",
      "DipoleMomentS1": "1.18",
      "DipoleMomentT1": "1.28",
      "ionizationPotential": "9.05",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IJIWVSBRXVHMFC-VHXPQNKSSA-N",
      "Smiles": "COc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)OC",
      "HOMO": "-5.77",
      "LUMO": "-2.93",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.58",
      "ET1red": "",
      "DipoleMomentS0": "12.39",
      "DipoleMomentS1": "12.39",
      "DipoleMomentT1": "10.39",
      "ionizationPotential": "6.23",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "IJIWVSBRXVHMFC-WCWDXBQESA-N",
      "Smiles": "COc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)OC",
      "HOMO": "-5.79",
      "LUMO": "-2.98",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.12",
      "DipoleMomentS1": "5.12",
      "DipoleMomentT1": "5.81",
      "ionizationPotential": "6.32",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IJLZCBITTWMRKI-UHFFFAOYSA-N",
      "Smiles": "Oc1ncc([nH]1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1ncc([nH]1)[C@]1(O)N=N1",
      "HOMO": "-6.39",
      "LUMO": "-2.34",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "2.4",
      "E00T1": "1.82",
      "Eox": "1.45",
      "Ered": "",
      "ES1ox": "-0.95",
      "ES1red": "",
      "ET1ox": "-0.37",
      "ET1red": "",
      "DipoleMomentS0": "3.4",
      "DipoleMomentS1": "3.4",
      "DipoleMomentT1": "7.09",
      "ionizationPotential": "7.85",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IKBCCGJGTHBTEU-UHFFFAOYSA-N",
      "Smiles": "O/N=C\\c1cc2c3cc(/C=N/O)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/O)cc(c5ccc4)/C=N/O",
      "MolView_url": "http://molview.org/?q=O/N=C\\c1cc2c3cc(/C=N/O)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/O)cc(c5ccc4)/C=N/O",
      "HOMO": "-5.63",
      "LUMO": "-2.89",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "1.92",
      "E00T1": "1.04",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "-1.18",
      "ES1red": "",
      "ET1ox": "-0.3",
      "ET1red": "",
      "DipoleMomentS0": "4.2",
      "DipoleMomentS1": "4.2",
      "DipoleMomentT1": "4.15",
      "ionizationPotential": "0.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "IKGNAOVIZIBAHI-FUJGBLOQSA-N",
      "Smiles": "S/N=C\\c1cc(C#N)cc(c1C#N)/C=N/S",
      "MolView_url": "http://molview.org/?q=S/N=C\\c1cc(C#N)cc(c1C#N)/C=N/S",
      "HOMO": "-6.98",
      "LUMO": "-2.92",
      "VEE": "3.59",
      "Lmax": "345.348189415042",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.58",
      "DipoleMomentS1": "2.58",
      "DipoleMomentT1": "4.73",
      "ionizationPotential": "8.47",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ILAFQLIBURJUFD-BYYHNAKLSA-N",
      "Smiles": "FC(c1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)C(F)(F)F)(F)F",
      "HOMO": "-5.87",
      "LUMO": "-3",
      "VEE": "3.1",
      "Lmax": "399.935483870968",
      "E00S1": "",
      "E00T1": "0.87",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.1",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.65",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ILAFQLIBURJUFD-DQSJHHFOSA-N",
      "Smiles": "FC(c1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)C(F)(F)F)(F)F",
      "HOMO": "-6.07",
      "LUMO": "-2.82",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "0.26",
      "Eox": "0.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.56",
      "ET1red": "",
      "DipoleMomentS0": "5.13",
      "DipoleMomentS1": "5.13",
      "DipoleMomentT1": "4.54",
      "ionizationPotential": "6.66",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "ILKIOEHBVFQRGE-FMQUCBEESA-N",
      "Smiles": "Oc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)O",
      "HOMO": "-5.39",
      "LUMO": "-2.71",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "2.02",
      "E00T1": "0.76",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "-1.53",
      "ES1red": "",
      "ET1ox": "-0.27",
      "ET1red": "",
      "DipoleMomentS0": "4.27",
      "DipoleMomentS1": "4.27",
      "DipoleMomentT1": "4.49",
      "ionizationPotential": "6.17",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "IMSPJYBXRJETIY-QURGRASLSA-N",
      "Smiles": "N#CC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)C#N",
      "HOMO": "-6.42",
      "LUMO": "-4.12",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "1.67",
      "E00T1": "0.36",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "18.64",
      "DipoleMomentS1": "18.64",
      "DipoleMomentT1": "17.63",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "IMTSNTVBEFDMAF-UHFFFAOYSA-N",
      "Smiles": "C#C[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=C#C[C@@H]1N=N1",
      "HOMO": "-7.64",
      "LUMO": "-2.28",
      "VEE": "3.41",
      "Lmax": "363.577712609971",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.79",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.9",
      "DipoleMomentS1": "1.9",
      "DipoleMomentT1": "5.16",
      "ionizationPotential": "9.54",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IMUXMIVQLMCLNO-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(nc1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(cn1)Cl)c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(cn1)Cl)c1ccc(cn1)Cl",
      "HOMO": "-5.64",
      "LUMO": "-2.49",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "",
      "E00T1": "1.62",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.81",
      "ET1red": "",
      "DipoleMomentS0": "6.73",
      "DipoleMomentS1": "6.73",
      "DipoleMomentT1": "7.57",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "INKGQOVRMNQBFH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(c2cnc[nH]2)c(c(c1)c1cnc[nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(c2cnc[nH]2)c(c(c1)c1cnc[nH]1)C#N",
      "HOMO": "-6.46",
      "LUMO": "-2.9",
      "VEE": "3.21",
      "Lmax": "386.230529595016",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.05",
      "DipoleMomentS1": "5.05",
      "DipoleMomentT1": "8.78",
      "ionizationPotential": "8",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "INQBWMWJEOQIDZ-DQRAZIAOSA-N",
      "Smiles": "Sc1csc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1scc(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1csc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1scc(n1)S",
      "HOMO": "-6.15",
      "LUMO": "-2.71",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "0.52",
      "Eox": "1.31",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.79",
      "ET1red": "",
      "DipoleMomentS0": "4.05",
      "DipoleMomentS1": "4.05",
      "DipoleMomentT1": "3.51",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "INQBWMWJEOQIDZ-QURGRASLSA-N",
      "Smiles": "Sc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)S",
      "HOMO": "-6.18",
      "LUMO": "-3",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "1.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.4",
      "ET1red": "",
      "DipoleMomentS0": "3.4",
      "DipoleMomentS1": "3.4",
      "DipoleMomentT1": "3.53",
      "ionizationPotential": "7.01",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "IOEBPECGTFTNBU-YOGJSDRDSA-N",
      "Smiles": "FC(O/N=C\\c1ccc([nH]1)/N=N\\c1ccc([nH]1)/C=N/OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C\\c1ccc([nH]1)/N=N\\c1ccc([nH]1)/C=N/OC(F)(F)F)(F)F",
      "HOMO": "-6.15",
      "LUMO": "-3.14",
      "VEE": "2.53",
      "Lmax": "490.0395256917",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.2",
      "DipoleMomentS1": "1.2",
      "DipoleMomentT1": "0.15",
      "ionizationPotential": "7.04",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "IOHSENADRIONCF-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(s1)OC",
      "HOMO": "-6.05",
      "LUMO": "-3.05",
      "VEE": "2.14",
      "Lmax": "579.345794392523",
      "E00S1": "2.3",
      "E00T1": "",
      "Eox": "1.25",
      "Ered": "",
      "ES1ox": "-1.05",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.17",
      "DipoleMomentS1": "7.17",
      "DipoleMomentT1": "11.86",
      "ionizationPotential": "6.85",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "IOKYWKUDQSPVNR-OCEACIFDSA-N",
      "Smiles": "FC(C#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CC(F)(F)F)(F)F",
      "HOMO": "-7.01",
      "LUMO": "-3.14",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.88",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.03",
      "DipoleMomentS1": "0.03",
      "DipoleMomentT1": "2.52",
      "ionizationPotential": "8.04",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "IOLLKCPURNBTGN-FOCLMDBBSA-N",
      "Smiles": "COC#Cc1ccc(s1)/N=N/c1ccc(s1)C#COC",
      "MolView_url": "http://molview.org/?q=COC#Cc1ccc(s1)/N=N/c1ccc(s1)C#COC",
      "HOMO": "-5.73",
      "LUMO": "-2.87",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "",
      "E00T1": "0.81",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.03",
      "ET1red": "",
      "DipoleMomentS0": "2.34",
      "DipoleMomentS1": "2.34",
      "DipoleMomentT1": "3.52",
      "ionizationPotential": "6.41",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IOWDBJAHTIBHBN-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)OC)C#N)c1ccc(cn1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)OC)C#N)c1ccc(cn1)OC",
      "HOMO": "-6.57",
      "LUMO": "-2.78",
      "VEE": "3.56",
      "Lmax": "348.258426966292",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.9",
      "DipoleMomentS1": "6.9",
      "DipoleMomentT1": "11.02",
      "ionizationPotential": "7.77",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IPAVOGAYBQLHRV-OCEACIFDSA-N",
      "Smiles": "Oc1ccc(nc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(nc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(cn1)O",
      "HOMO": "-6.25",
      "LUMO": "-2.71",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "1.09",
      "Eox": "1.34",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.25",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "5.24",
      "ionizationPotential": "6.9",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "IPAVOGAYBQLHRV-QPLCGJKRSA-N",
      "Smiles": "Oc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)O",
      "HOMO": "-6.16",
      "LUMO": "-2.44",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "0.55",
      "Eox": "1.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.62",
      "ET1red": "",
      "DipoleMomentS0": "10.65",
      "DipoleMomentS1": "10.65",
      "DipoleMomentT1": "9.35",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "IPQIOZJYRAIRCL-VHXPQNKSSA-N",
      "Smiles": "N#Cc1cnc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(o1)C#N",
      "HOMO": "-6.12",
      "LUMO": "-3.11",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "1.51",
      "E00T1": "0.34",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "-1.02",
      "ES1red": "",
      "ET1ox": "0.15",
      "ET1red": "",
      "DipoleMomentS0": "2.94",
      "DipoleMomentS1": "2.94",
      "DipoleMomentT1": "4.37",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "IPYDXCLFBFTWEA-WMCCOBJESA-N",
      "Smiles": "F/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N\\F",
      "MolView_url": "http://molview.org/?q=F/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N\\F",
      "HOMO": "-6.62",
      "LUMO": "-3.48",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "1.72",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.96",
      "ET1red": "",
      "DipoleMomentS0": "1.05",
      "DipoleMomentS1": "1.05",
      "DipoleMomentT1": "2.58",
      "ionizationPotential": "7.76",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "IQJQNCVMTQSAEI-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cnc([nH]1)Cl)C#N)c1cnc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cnc([nH]1)Cl)C#N)c1cnc([nH]1)Cl",
      "HOMO": "-6.49",
      "LUMO": "-2.99",
      "VEE": "3.11",
      "Lmax": "398.649517684887",
      "E00S1": "",
      "E00T1": "2.06",
      "Eox": "-0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.95",
      "ET1red": "",
      "DipoleMomentS0": "2.33",
      "DipoleMomentS1": "2.33",
      "DipoleMomentT1": "7.62",
      "ionizationPotential": "8.01",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IQKAFPWKNIEWIW-VHXPQNKSSA-N",
      "Smiles": "COC1=CC=C(S1(=O)=O)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(S1(=O)=O)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(S1(=O)=O)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(S1(=O)=O)OC",
      "HOMO": "-6.23",
      "LUMO": "-2.89",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.45",
      "Eox": "1.23",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.78",
      "ET1red": "",
      "DipoleMomentS0": "12.56",
      "DipoleMomentS1": "12.56",
      "DipoleMomentT1": "10.62",
      "ionizationPotential": "6.83",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "IQQIGPPHKKHMJD-QURGRASLSA-N",
      "Smiles": "Clc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)Cl",
      "HOMO": "-5.43",
      "LUMO": "-2.75",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "2",
      "E00T1": "0.77",
      "Eox": "0.56",
      "Ered": "",
      "ES1ox": "-1.44",
      "ES1red": "",
      "ET1ox": "-0.21",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.3",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "IQRYOYKUAGWOGM-UHFFFAOYSA-N",
      "Smiles": "ClC#Cc1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C#CCl",
      "MolView_url": "http://molview.org/?q=ClC#Cc1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C#CCl",
      "HOMO": "-5.5",
      "LUMO": "-2.63",
      "VEE": "1.41",
      "Lmax": "879.290780141844",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.24",
      "DipoleMomentS1": "2.24",
      "DipoleMomentT1": "2.07",
      "ionizationPotential": "6.35",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "IQZVXSAFFQJWRD-PEIUIALNSA-N",
      "Smiles": "CO/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/OC",
      "MolView_url": "http://molview.org/?q=CO/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/OC",
      "HOMO": "-6.1",
      "LUMO": "-3.13",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.86",
      "ET1red": "",
      "DipoleMomentS0": "5.2",
      "DipoleMomentS1": "5.2",
      "DipoleMomentT1": "4.41",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "IQZVXSAFFQJWRD-PFRXGMGXSA-N",
      "Smiles": "CO/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N/OC",
      "MolView_url": "http://molview.org/?q=CO/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N/OC",
      "HOMO": "-6.18",
      "LUMO": "-3.18",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "",
      "E00T1": "0.85",
      "Eox": "1.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.36",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.91",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IRLLACSCVLEIRY-UHFFFAOYSA-N",
      "Smiles": "C1=CC=C(C1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=C1=CC=C(C1)[C@@H]1N=N1",
      "HOMO": "-6.2",
      "LUMO": "-2.05",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "2.62",
      "E00T1": "2",
      "Eox": "1.3",
      "Ered": "",
      "ES1ox": "-1.32",
      "ES1red": "",
      "ET1ox": "-0.7",
      "ET1red": "",
      "DipoleMomentS0": "1.79",
      "DipoleMomentS1": "1.79",
      "DipoleMomentT1": "8.71",
      "ionizationPotential": "7.79",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IRSDVNNMOVAYJK-OCEACIFDSA-N",
      "Smiles": "Clc1ccc(n1C)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(n1C)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(n1C)Cl",
      "HOMO": "-5.83",
      "LUMO": "-2.64",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "1.09",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.18",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "1.98",
      "ionizationPotential": "6.56",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "IRSDVNNMOVAYJK-QPLCGJKRSA-N",
      "Smiles": "Clc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)Cl",
      "HOMO": "-5.84",
      "LUMO": "-2.4",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "0.54",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.42",
      "ET1red": "",
      "DipoleMomentS0": "3.25",
      "DipoleMomentS1": "3.25",
      "DipoleMomentT1": "3.37",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "IRSZHNGCJCBLDT-UHFFFAOYSA-N",
      "Smiles": "N#CC#Cc1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C#CC#N",
      "MolView_url": "http://molview.org/?q=N#CC#Cc1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C#CC#N",
      "HOMO": "-7.73",
      "LUMO": "-3.57",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "3.05",
      "E00T1": "2.46",
      "Eox": "2.82",
      "Ered": "",
      "ES1ox": "-0.23",
      "ES1red": "",
      "ET1ox": "0.36",
      "ET1red": "",
      "DipoleMomentS0": "6.95",
      "DipoleMomentS1": "6.95",
      "DipoleMomentT1": "11.23",
      "ionizationPotential": "9.06",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ISDLZVWFYXYPBO-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(F)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(F)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)F",
      "HOMO": "-6.55",
      "LUMO": "-3.17",
      "VEE": "2.3",
      "Lmax": "539.04347826087",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.6",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.55",
      "DipoleMomentS1": "6.55",
      "DipoleMomentT1": "12.17",
      "ionizationPotential": "7.56",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ISYFGKVSQIFLNE-ISLYRVAYSA-N",
      "Smiles": "Oc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)O",
      "HOMO": "-5.96",
      "LUMO": "-3.18",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.23",
      "DipoleMomentS1": "0.23",
      "DipoleMomentT1": "0.27",
      "ionizationPotential": "6.75",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "IUEINDDVTYIZKY-QVIHXGFCSA-N",
      "Smiles": "FC(c1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-6.41",
      "LUMO": "-3.43",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "",
      "E00T1": "0.79",
      "Eox": "1.48",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.69",
      "ET1red": "",
      "DipoleMomentS0": "0.32",
      "DipoleMomentS1": "0.32",
      "DipoleMomentT1": "4.93",
      "ionizationPotential": "7.31",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "IURKQQLJSSEABI-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)C#N)c1[nH]cc(c1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)C#N)c1[nH]cc(c1)C#N",
      "HOMO": "-5.56",
      "LUMO": "-2.44",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "1.58",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.87",
      "ET1red": "",
      "DipoleMomentS0": "2.95",
      "DipoleMomentS1": "2.95",
      "DipoleMomentT1": "4.59",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "IVHABPXNRDSPPZ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(nc1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(nc1)[C@]1(C#N)N=N1",
      "HOMO": "-8.37",
      "LUMO": "-3.04",
      "VEE": "3.7",
      "Lmax": "335.081081081081",
      "E00S1": "3.61",
      "E00T1": "3.02",
      "Eox": "3.47",
      "Ered": "",
      "ES1ox": "-0.14",
      "ES1red": "",
      "ET1ox": "0.45",
      "ET1red": "",
      "DipoleMomentS0": "2.73",
      "DipoleMomentS1": "2.73",
      "DipoleMomentT1": "4.22",
      "ionizationPotential": "10",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IVKJRVAMLAMXDA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(cn1)Cl)C#N)c1ncc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(cn1)Cl)C#N)c1ncc(cn1)Cl",
      "HOMO": "-7.35",
      "LUMO": "-3.15",
      "VEE": "3.94",
      "Lmax": "314.670050761421",
      "E00S1": "",
      "E00T1": "2.36",
      "Eox": "-0.67",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.03",
      "ET1red": "",
      "DipoleMomentS0": "2.71",
      "DipoleMomentS1": "2.71",
      "DipoleMomentT1": "4.89",
      "ionizationPotential": "8.75",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IWSXYUCCSKMCRK-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)F)C#N)C1=CC=C(S1(=O)=O)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)F)C#N)C1=CC=C(S1(=O)=O)F",
      "HOMO": "-7.53",
      "LUMO": "-3.22",
      "VEE": "3.31",
      "Lmax": "374.561933534743",
      "E00S1": "",
      "E00T1": "2.38",
      "Eox": "2.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.2",
      "ET1red": "",
      "DipoleMomentS0": "9.93",
      "DipoleMomentS1": "9.93",
      "DipoleMomentT1": "11.45",
      "ionizationPotential": "8.85",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IWVXIWKJGKSKOI-UHFFFAOYSA-N",
      "Smiles": "Fc1c[nH]c(c1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1c[nH]c(c1)[C@]1(F)N=N1",
      "HOMO": "-6.61",
      "LUMO": "-2.61",
      "VEE": "3.33",
      "Lmax": "372.312312312312",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.22",
      "DipoleMomentS1": "3.22",
      "DipoleMomentT1": "10.02",
      "ionizationPotential": "8.31",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IXGVKNHJSRLNSB-OPCYYNASSA-N",
      "Smiles": "F/N=C\\c1cc(C#N)cc(c1C#N)/C=N/F",
      "MolView_url": "http://molview.org/?q=F/N=C\\c1cc(C#N)cc(c1C#N)/C=N/F",
      "HOMO": "-8.34",
      "LUMO": "-3.12",
      "VEE": "4.26",
      "Lmax": "291.032863849765",
      "E00S1": "4.06",
      "E00T1": "2.21",
      "Eox": "3.4",
      "Ered": "",
      "ES1ox": "-0.66",
      "ES1red": "",
      "ET1ox": "1.19",
      "ET1red": "",
      "DipoleMomentS0": "2.03",
      "DipoleMomentS1": "2.03",
      "DipoleMomentT1": "2.84",
      "ionizationPotential": "10.03",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "IXMJJGOHQIPNAT-UHFFFAOYSA-N",
      "Smiles": "SC1=CC=C(C1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(C1)[C@]1(S)N=N1",
      "HOMO": "-5.87",
      "LUMO": "-2.18",
      "VEE": "3.32",
      "Lmax": "373.433734939759",
      "E00S1": "2.52",
      "E00T1": "1.79",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "-1.56",
      "ES1red": "",
      "ET1ox": "-0.83",
      "ET1red": "",
      "DipoleMomentS0": "3.32",
      "DipoleMomentS1": "3.32",
      "DipoleMomentT1": "12.59",
      "ionizationPotential": "7.31",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IYHVQJGOHRYYBE-DQRAZIAOSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)[N+](=O)[O-]",
      "HOMO": "-6.34",
      "LUMO": "-3.56",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.26",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.48",
      "DipoleMomentS1": "4.48",
      "DipoleMomentT1": "4.92",
      "ionizationPotential": "7.27",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "IYHVQJGOHRYYBE-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)[N+](=O)[O-]",
      "HOMO": "-6.41",
      "LUMO": "-3.67",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.85",
      "DipoleMomentS1": "1.85",
      "DipoleMomentT1": "2.62",
      "ionizationPotential": "7.42",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IYUCRYJFSPZXOM-DQRAZIAOSA-N",
      "Smiles": "Sc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)S",
      "HOMO": "-6.02",
      "LUMO": "-3.11",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.76",
      "DipoleMomentS1": "5.76",
      "DipoleMomentT1": "4.95",
      "ionizationPotential": "6.57",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "IYUCRYJFSPZXOM-QURGRASLSA-N",
      "Smiles": "Sc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)S",
      "HOMO": "-6.07",
      "LUMO": "-3.21",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "",
      "E00T1": "0.86",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.3",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.67",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "IYUMPYVTZOWDAB-OCEACIFDSA-N",
      "Smiles": "N#Cc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)C#N",
      "HOMO": "-6.83",
      "LUMO": "-3.22",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.78",
      "ET1red": "",
      "DipoleMomentS0": "4.89",
      "DipoleMomentS1": "4.89",
      "DipoleMomentT1": "4.82",
      "ionizationPotential": "7.84",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "IYUMPYVTZOWDAB-QPLCGJKRSA-N",
      "Smiles": "N#Cc1cnc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(o1)C#N",
      "HOMO": "-6.66",
      "LUMO": "-2.88",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "0.55",
      "Eox": "1.61",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.06",
      "ET1red": "",
      "DipoleMomentS0": "1.29",
      "DipoleMomentS1": "1.29",
      "DipoleMomentT1": "0.19",
      "ionizationPotential": "7.62",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "IYXOXSZWCHRDBJ-UHFFFAOYSA-N",
      "Smiles": "S/N=C/[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=S/N=C/[C@]1(S)N=N1",
      "HOMO": "-6.96",
      "LUMO": "-2.33",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "3.17",
      "E00T1": "2.37",
      "Eox": "2.09",
      "Ered": "",
      "ES1ox": "-1.08",
      "ES1red": "",
      "ET1ox": "-0.28",
      "ET1red": "",
      "DipoleMomentS0": "4.48",
      "DipoleMomentS1": "4.48",
      "DipoleMomentT1": "8.91",
      "ionizationPotential": "8.59",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "IYXXEEKGPGPGAH-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)S)c1ncc(o1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)S)c1ncc(o1)S",
      "HOMO": "-6.88",
      "LUMO": "-3.33",
      "VEE": "2.01",
      "Lmax": "616.81592039801",
      "E00S1": "2.79",
      "E00T1": "2.05",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.39",
      "DipoleMomentS1": "3.39",
      "DipoleMomentT1": "12.98",
      "ionizationPotential": "7.51",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "IZUPOPKWHNKSGK-UHFFFAOYSA-N",
      "Smiles": "Clc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)Cl",
      "HOMO": "-5.55",
      "LUMO": "-2.72",
      "VEE": "1.52",
      "Lmax": "815.657894736842",
      "E00S1": "1.93",
      "E00T1": "1.17",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.21",
      "ES1red": "",
      "ET1ox": "-0.45",
      "ET1red": "",
      "DipoleMomentS0": "6.16",
      "DipoleMomentS1": "6.16",
      "DipoleMomentT1": "7.04",
      "ionizationPotential": "6.36",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JAGBWEXBDRCGAW-IAZLARGGSA-N",
      "Smiles": "[O-][N+](=O)/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C\\[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C\\[N+](=O)[O-])cc(c5ccc4)/C=C\\O/C=C\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C\\[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C\\[N+](=O)[O-])cc(c5ccc4)/C=C\\O/C=C\\[N+](=O)[O-]",
      "HOMO": "-5.65",
      "LUMO": "-2.65",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "0.24",
      "Eox": "-4.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-4.63",
      "ET1red": "",
      "DipoleMomentS0": "8.37",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "12.28",
      "ionizationPotential": "0.12",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "JAQBTIXCPYYNAW-FMQUCBEESA-N",
      "Smiles": "Oc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)O",
      "HOMO": "-4.97",
      "LUMO": "-2.57",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "1.78",
      "E00T1": "0.64",
      "Eox": "0.11",
      "Ered": "",
      "ES1ox": "-1.67",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "3.71",
      "DipoleMomentS1": "3.71",
      "DipoleMomentT1": "4.5",
      "ionizationPotential": "5.66",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "JAQBTIXCPYYNAW-VXPUYCOJSA-N",
      "Smiles": "Oc1ccc([nH]1)c1ccc(o1)/N=N\\c1ccc(o1)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)c1ccc(o1)/N=N\\c1ccc(o1)c1ccc([nH]1)O",
      "HOMO": "-5",
      "LUMO": "-2.56",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "0.24",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "12.22",
      "DipoleMomentS1": "12.22",
      "DipoleMomentT1": "9.83",
      "ionizationPotential": "0",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "JBPBYMZZUCRUIX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(o1)C(F)(F)F)C#N)c1ccc(o1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(o1)C(F)(F)F)C#N)c1ccc(o1)C(F)(F)F",
      "HOMO": "-6.87",
      "LUMO": "-3.07",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "",
      "E00T1": "2.15",
      "Eox": "-0.83",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.98",
      "ET1red": "",
      "DipoleMomentS0": "3.32",
      "DipoleMomentS1": "3.32",
      "DipoleMomentT1": "1.04",
      "ionizationPotential": "8.43",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JBXPDVJZVJRADK-DQRAZIAOSA-N",
      "Smiles": "OC1=CC=C(C1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(C1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)C1=CC=C(C1)O",
      "HOMO": "-4.86",
      "LUMO": "-2.39",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "",
      "E00T1": "0.05",
      "Eox": "-0.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.87",
      "ET1red": "",
      "DipoleMomentS0": "10.72",
      "DipoleMomentS1": "10.72",
      "DipoleMomentT1": "3.74",
      "ionizationPotential": "5.57",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "JBXVGXWYNLMFOU-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cnc([nH]1)S)C#N)c1cnc([nH]1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cnc([nH]1)S)C#N)c1cnc([nH]1)S",
      "HOMO": "-6.16",
      "LUMO": "-2.88",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.2",
      "DipoleMomentS1": "2.2",
      "DipoleMomentT1": "7.17",
      "ionizationPotential": "7.53",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JCSOABOKWBYMGA-UHFFFAOYSA-N",
      "Smiles": "SC1(S)N=N1",
      "MolView_url": "http://molview.org/?q=SC1(S)N=N1",
      "HOMO": "-7.07",
      "LUMO": "-2.51",
      "VEE": "3.59",
      "Lmax": "345.348189415042",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.54",
      "DipoleMomentS1": "2.54",
      "DipoleMomentT1": "6.68",
      "ionizationPotential": "9.03",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JCZZGPRZEPAJTD-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)[N+](=O)[O-]",
      "HOMO": "-6.51",
      "LUMO": "-3.5",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "0.9",
      "Eox": "1.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.62",
      "ET1red": "",
      "DipoleMomentS0": "7.92",
      "DipoleMomentS1": "7.92",
      "DipoleMomentT1": "8.43",
      "ionizationPotential": "7.6",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "JCZZGPRZEPAJTD-ZCXUNETKSA-N",
      "Smiles": "[O-][N+](=O)c1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)[N+](=O)[O-]",
      "HOMO": "-6.44",
      "LUMO": "-3.4",
      "VEE": "2.31",
      "Lmax": "536.709956709957",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.14",
      "ET1red": "",
      "DipoleMomentS0": "6.75",
      "DipoleMomentS1": "6.75",
      "DipoleMomentT1": "8.29",
      "ionizationPotential": "7.5",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "JDWGUJKXJSJPJX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(cn1)C)C#N)c1ncc(cn1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(cn1)C)C#N)c1ncc(cn1)C",
      "HOMO": "-7.47",
      "LUMO": "-2.64",
      "VEE": "3.97",
      "Lmax": "312.292191435768",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.48",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.3",
      "DipoleMomentS1": "4.3",
      "DipoleMomentT1": "6.08",
      "ionizationPotential": "8.44",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JEGWBVPQYCEVTN-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)O)c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)O)c1ccc(o1)O",
      "HOMO": "-5.34",
      "LUMO": "-2.35",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "1.55",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.27",
      "DipoleMomentS1": "9.27",
      "DipoleMomentT1": "10.11",
      "ionizationPotential": "5.92",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "JESYQEFQSAIDRL-VHXPQNKSSA-N",
      "Smiles": "N#Cc1cnc(s1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(s1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(s1)C#N",
      "HOMO": "-6",
      "LUMO": "-3.17",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "1.41",
      "E00T1": "",
      "Eox": "0.4",
      "Ered": "",
      "ES1ox": "-1.01",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.15",
      "DipoleMomentS1": "5.15",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "JEYOKIDLPPCNTF-UHFFFAOYSA-N",
      "Smiles": "Fc1ncc([nH]1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1ncc([nH]1)[C@]1(F)N=N1",
      "HOMO": "-7.06",
      "LUMO": "-2.7",
      "VEE": "3.41",
      "Lmax": "363.577712609971",
      "E00S1": "2.71",
      "E00T1": "2.12",
      "Eox": "2.13",
      "Ered": "",
      "ES1ox": "-0.58",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "2.92",
      "DipoleMomentS1": "2.92",
      "DipoleMomentT1": "6.5",
      "ionizationPotential": "8.79",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JEZGTGLOQNYAGF-UHFFFAOYSA-N",
      "Smiles": "Cc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)C",
      "HOMO": "-5.45",
      "LUMO": "-2.65",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "1.94",
      "E00T1": "",
      "Eox": "0.6",
      "Ered": "",
      "ES1ox": "-1.34",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.17",
      "DipoleMomentS1": "4.17",
      "DipoleMomentT1": "4.03",
      "ionizationPotential": "6.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JFRVBXHYBIFZIM-DQRAZIAOSA-N",
      "Smiles": "Clc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)Cl",
      "HOMO": "-5.71",
      "LUMO": "-2.81",
      "VEE": "2.54",
      "Lmax": "488.110236220472",
      "E00S1": "",
      "E00T1": "0.14",
      "Eox": "0.6",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.46",
      "ET1red": "",
      "DipoleMomentS0": "5.91",
      "DipoleMomentS1": "5.91",
      "DipoleMomentT1": "2.66",
      "ionizationPotential": "6.34",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "JFRVBXHYBIFZIM-QURGRASLSA-N",
      "Smiles": "Clc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)Cl",
      "HOMO": "-5.53",
      "LUMO": "-2.92",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "0.74",
      "Eox": "0.61",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.13",
      "ET1red": "",
      "DipoleMomentS0": "1.26",
      "DipoleMomentS1": "1.26",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.34",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "JFVGBJJLBQHLDJ-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc([nH]1)C)c1ccc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc([nH]1)C)c1ccc([nH]1)C",
      "HOMO": "-5.25",
      "LUMO": "-2.3",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "1.51",
      "Eox": "0.45",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-1.06",
      "ET1red": "",
      "DipoleMomentS0": "6.93",
      "DipoleMomentS1": "6.93",
      "DipoleMomentT1": "9.51",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "JGEMNRHKZAASNI-UHFFFAOYSA-N",
      "Smiles": "N=Cc1cc2c3cc(C=N)c4c5c3c(c3c2c2c1cccc2c(c3)C=N)cc(c5ccc4)C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1cc2c3cc(C=N)c4c5c3c(c3c2c2c1cccc2c(c3)C=N)cc(c5ccc4)C=N",
      "HOMO": "-5.77",
      "LUMO": "-2.99",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "1.97",
      "E00T1": "1.1",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "-1.07",
      "ES1red": "",
      "ET1ox": "-0.2",
      "ET1red": "",
      "DipoleMomentS0": "2.56",
      "DipoleMomentS1": "2.56",
      "DipoleMomentT1": "2.82",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "JGOURJXEELFIQX-UHFFFAOYSA-N",
      "Smiles": "Clc1coc(n1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)[C@]1(Cl)N=N1",
      "HOMO": "-7.39",
      "LUMO": "-2.81",
      "VEE": "3.22",
      "Lmax": "385.031055900621",
      "E00S1": "3.07",
      "E00T1": "2.47",
      "Eox": "2.45",
      "Ered": "",
      "ES1ox": "-0.62",
      "ES1red": "",
      "ET1ox": "-0.02",
      "ET1red": "",
      "DipoleMomentS0": "3.27",
      "DipoleMomentS1": "3.27",
      "DipoleMomentT1": "9.26",
      "ionizationPotential": "8.93",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JHQUYYKLDBYIHC-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nncnn1)c1nncnn1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nncnn1)c1nncnn1",
      "HOMO": "-7.26",
      "LUMO": "-3.5",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.06",
      "E00T1": "2.45",
      "Eox": "2.41",
      "Ered": "",
      "ES1ox": "0.35",
      "ES1red": "",
      "ET1ox": "-0.04",
      "ET1red": "",
      "DipoleMomentS0": "1.32",
      "DipoleMomentS1": "1.32",
      "DipoleMomentT1": "1.23",
      "ionizationPotential": "8.24",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "JHZHVDOOTJQIDG-UHFFFAOYSA-N",
      "Smiles": "FC(c1nnc(nn1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1nnc(nn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1nnc(nn1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1nnc(nn1)C(F)(F)F)(F)F",
      "HOMO": "-7.64",
      "LUMO": "-3.69",
      "VEE": "1.53",
      "Lmax": "810.326797385621",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.07",
      "DipoleMomentS1": "5.07",
      "DipoleMomentT1": "5.2",
      "ionizationPotential": "8.71",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "JIXAVVSXBXDPQM-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)[N+](=O)[O-])cc(c5ccc4)c1cnc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)[N+](=O)[O-])cc(c5ccc4)c1cnc([nH]1)[N+](=O)[O-]",
      "HOMO": "-5.84",
      "LUMO": "-3.36",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "1.75",
      "E00T1": "",
      "Eox": "1.02",
      "Ered": "",
      "ES1ox": "-0.73",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.9",
      "DipoleMomentS1": "1.9",
      "DipoleMomentT1": "2.32",
      "ionizationPotential": "7.25",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "JIYAFZOCKIZJTH-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)O",
      "HOMO": "-5.16",
      "LUMO": "-2.43",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "1.87",
      "E00T1": "1.12",
      "Eox": "0.35",
      "Ered": "",
      "ES1ox": "-1.52",
      "ES1red": "",
      "ET1ox": "-0.77",
      "ET1red": "",
      "DipoleMomentS0": "1.29",
      "DipoleMomentS1": "1.29",
      "DipoleMomentT1": "1.22",
      "ionizationPotential": "5.87",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JJGDOQVCYJJRSB-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)C#N",
      "HOMO": "-7.38",
      "LUMO": "-3.32",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "2.9",
      "E00T1": "",
      "Eox": "2.49",
      "Ered": "",
      "ES1ox": "-0.41",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.8",
      "DipoleMomentS1": "5.8",
      "DipoleMomentT1": "",
      "ionizationPotential": "8.46",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "JKNKPGMLFQHMNZ-UHFFFAOYSA-N",
      "Smiles": "N#CC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)C#N)cc(c5ccc4)C1=CC=C(C1)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)C#N)cc(c5ccc4)C1=CC=C(C1)C#N",
      "HOMO": "-5.43",
      "LUMO": "-2.88",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "1.73",
      "E00T1": "0.27",
      "Eox": "-4.27",
      "Ered": "",
      "ES1ox": "-6",
      "ES1red": "",
      "ET1ox": "-4.54",
      "ET1red": "",
      "DipoleMomentS0": "5.32",
      "DipoleMomentS1": "5.32",
      "DipoleMomentT1": "3.19",
      "ionizationPotential": "6.52",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "JKSPOQSFDZEWKR-UHFFFAOYSA-N",
      "Smiles": "N#CC1=CC=C(S1(=O)=O)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(S1(=O)=O)[C@]1(C#N)N=N1",
      "HOMO": "-8",
      "LUMO": "-3.74",
      "VEE": "3.69",
      "Lmax": "335.989159891599",
      "E00S1": "3.01",
      "E00T1": "1.54",
      "Eox": "3.07",
      "Ered": "",
      "ES1ox": "0.06",
      "ES1red": "",
      "ET1ox": "1.53",
      "ET1red": "",
      "DipoleMomentS0": "6.37",
      "DipoleMomentS1": "6.37",
      "DipoleMomentT1": "6.18",
      "ionizationPotential": "9.64",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JLDWRMXSHHBAPM-IHXWQEJPSA-N",
      "Smiles": "CO/N=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/OC",
      "MolView_url": "http://molview.org/?q=CO/N=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/OC",
      "HOMO": "-5.51",
      "LUMO": "-2.59",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "2.07",
      "E00T1": "1.2",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "-1.38",
      "ES1red": "",
      "ET1ox": "-0.51",
      "ET1red": "",
      "DipoleMomentS0": "3.2",
      "DipoleMomentS1": "3.2",
      "DipoleMomentT1": "2.86",
      "ionizationPotential": "6.32",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JLWFEAQRZHPBQN-QURGRASLSA-N",
      "Smiles": "C1=CC=C(C1)c1ccc([nH]1)/N=N/c1ccc([nH]1)C1=CC=CC1",
      "MolView_url": "http://molview.org/?q=C1=CC=C(C1)c1ccc([nH]1)/N=N/c1ccc([nH]1)C1=CC=CC1",
      "HOMO": "-5.06",
      "LUMO": "-2.46",
      "VEE": "2.59",
      "Lmax": "478.687258687259",
      "E00S1": "1.9",
      "E00T1": "0.68",
      "Eox": "0.15",
      "Ered": "",
      "ES1ox": "-1.75",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "4.15",
      "DipoleMomentS1": "4.15",
      "DipoleMomentT1": "4.69",
      "ionizationPotential": "5.82",
      "source_directory": "/scratch/myrthil.n/pyrrole_trans"
    },
    {
      "InchiKey": "JMIKSFJNGIZWIL-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)C#N)cc(c5ccc4)c1ncc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)C#N)cc(c5ccc4)c1ncc(cn1)C#N",
      "HOMO": "-5.76",
      "LUMO": "-3.16",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "1.8",
      "E00T1": "",
      "Eox": "1.05",
      "Ered": "",
      "ES1ox": "-0.75",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.11",
      "DipoleMomentS1": "1.11",
      "DipoleMomentT1": "1.48",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "JMIPLAQWPSKGSH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)C#N",
      "HOMO": "-5.45",
      "LUMO": "-2.57",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.01",
      "E00T1": "1.2",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "-1.32",
      "ES1red": "",
      "ET1ox": "-0.51",
      "ET1red": "",
      "DipoleMomentS0": "7.97",
      "DipoleMomentS1": "7.97",
      "DipoleMomentT1": "7.43",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JMYKMBOJCHIFKY-UHFFFAOYSA-N",
      "Smiles": "COC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)OC",
      "HOMO": "-5",
      "LUMO": "-2.33",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.78",
      "E00T1": "1.07",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.51",
      "DipoleMomentS1": "4.51",
      "DipoleMomentT1": "6.04",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JNAVYPURJQGPHQ-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc([nH]1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1ccc([nH]1)[C@]1(S)N=N1",
      "HOMO": "-6.42",
      "LUMO": "-2.3",
      "VEE": "3.32",
      "Lmax": "373.433734939759",
      "E00S1": "2.67",
      "E00T1": "1.85",
      "Eox": "1.06",
      "Ered": "",
      "ES1ox": "-1.61",
      "ES1red": "",
      "ET1ox": "-0.79",
      "ET1red": "",
      "DipoleMomentS0": "2.47",
      "DipoleMomentS1": "2.47",
      "DipoleMomentT1": "13.48",
      "ionizationPotential": "7.44",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JOAPSDZSESAMGR-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cc1)OC(F)(F)F)C#N)c1ccc(cc1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cc1)OC(F)(F)F)C#N)c1ccc(cc1)OC(F)(F)F",
      "HOMO": "-7.14",
      "LUMO": "-2.9",
      "VEE": "3.92",
      "Lmax": "316.275510204082",
      "E00S1": "",
      "E00T1": "2.94",
      "Eox": "-0.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.9",
      "ET1red": "",
      "DipoleMomentS0": "1.1",
      "DipoleMomentS1": "1.1",
      "DipoleMomentT1": "0.83",
      "ionizationPotential": "8.4",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JOEYSAXCRZKEQY-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C=O)C(F)(F)F)C#N)c1ccc(n1C=O)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C=O)C(F)(F)F)C#N)c1ccc(n1C=O)C(F)(F)F",
      "HOMO": "-7",
      "LUMO": "-3.16",
      "VEE": "3.41",
      "Lmax": "363.577712609971",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.44",
      "DipoleMomentS1": "8.44",
      "DipoleMomentT1": "6.3",
      "ionizationPotential": "8.54",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JOLFDAPIXAOLBO-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(o1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(o1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-7.43",
      "LUMO": "-2.86",
      "VEE": "3.61",
      "Lmax": "343.434903047091",
      "E00S1": "3.01",
      "E00T1": "2.41",
      "Eox": "2.53",
      "Ered": "",
      "ES1ox": "-0.48",
      "ES1red": "",
      "ET1ox": "0.12",
      "ET1red": "",
      "DipoleMomentS0": "4.25",
      "DipoleMomentS1": "4.25",
      "DipoleMomentT1": "7.72",
      "ionizationPotential": "9.12",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JPBFPJMEKZFCBQ-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(s1)c1cc2c3cc(c4ncc(s4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)OC(F)(F)F)cc(c5ccc4)c1ncc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(s1)c1cc2c3cc(c4ncc(s4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)OC(F)(F)F)cc(c5ccc4)c1ncc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-5.73",
      "LUMO": "-2.96",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.91",
      "E00T1": "0.24",
      "Eox": "0.87",
      "Ered": "",
      "ES1ox": "-1.04",
      "ES1red": "",
      "ET1ox": "0.63",
      "ET1red": "",
      "DipoleMomentS0": "1.9",
      "DipoleMomentS1": "1.9",
      "DipoleMomentT1": "1.91",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "JPIGCUPCUXNSSH-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1coc(n1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1coc(n1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.59",
      "LUMO": "-3.39",
      "VEE": "4",
      "Lmax": "309.95",
      "E00S1": "3.76",
      "E00T1": "2.75",
      "Eox": "3.46",
      "Ered": "",
      "ES1ox": "-0.3",
      "ES1red": "",
      "ET1ox": "0.71",
      "ET1red": "",
      "DipoleMomentS0": "4.07",
      "DipoleMomentS1": "4.07",
      "DipoleMomentT1": "7.57",
      "ionizationPotential": "10.21",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JPUWVOGVTYULQS-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1[nH]cc(c1)C(F)(F)F)C#N)c1[nH]cc(c1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1[nH]cc(c1)C(F)(F)F)C#N)c1[nH]cc(c1)C(F)(F)F",
      "HOMO": "-6.71",
      "LUMO": "-2.67",
      "VEE": "3.21",
      "Lmax": "386.230529595016",
      "E00S1": "",
      "E00T1": "2.21",
      "Eox": "1.67",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "3.77",
      "DipoleMomentS1": "3.77",
      "DipoleMomentT1": "9.56",
      "ionizationPotential": "8.05",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JPZYQLYNLFAIFN-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(S)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C=O)S)c1ccc(n1C=O)S",
      "MolView_url": "http://molview.org/?q=O=Cn1c(S)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C=O)S)c1ccc(n1C=O)S",
      "HOMO": "-5.59",
      "LUMO": "-2.49",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "-4.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-4.51",
      "ET1red": "",
      "DipoleMomentS0": "8.12",
      "DipoleMomentS1": "8.12",
      "DipoleMomentT1": "8.63",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "JQSOQVADOSMRTR-WCWDXBQESA-N",
      "Smiles": "Fc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)F",
      "HOMO": "-6.21",
      "LUMO": "-3.19",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.85",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.42",
      "ET1red": "",
      "DipoleMomentS0": "1.45",
      "DipoleMomentS1": "1.45",
      "DipoleMomentT1": "1.61",
      "ionizationPotential": "6.97",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "JQTYPAGRIIJCCU-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)S)C1=CC=C(C1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)S)C1=CC=C(C1)S",
      "HOMO": "-5.7",
      "LUMO": "-3.05",
      "VEE": "1.69",
      "Lmax": "733.609467455621",
      "E00S1": "2.04",
      "E00T1": "1.53",
      "Eox": "1.05",
      "Ered": "",
      "ES1ox": "-0.99",
      "ES1red": "",
      "ET1ox": "-0.48",
      "ET1red": "",
      "DipoleMomentS0": "3.63",
      "DipoleMomentS1": "3.63",
      "DipoleMomentT1": "10.68",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "JRJFJJWJFLUAJU-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)O",
      "HOMO": "-5.04",
      "LUMO": "-2.34",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.85",
      "E00T1": "1.13",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.33",
      "DipoleMomentS1": "3.33",
      "DipoleMomentT1": "4.91",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JRNZLBXPFSULCL-VHXPQNKSSA-N",
      "Smiles": "Clc1ccc(n1C)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(n1C)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1ccc(n1C)Cl",
      "HOMO": "-5.46",
      "LUMO": "-2.42",
      "VEE": "2.52",
      "Lmax": "491.984126984127",
      "E00S1": "",
      "E00T1": "0.26",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.96",
      "DipoleMomentS1": "5.96",
      "DipoleMomentT1": "0.17",
      "ionizationPotential": "6",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "JSLJYDNAMWMXGH-UHFFFAOYSA-N",
      "Smiles": "Cl[B-]12n3c4cc5-c6c(-c([n+]25)cc2n1c(cc3c1c4cccc1)c1c2cccc1)cccc6",
      "MolView_url": "http://molview.org/?q=Cl[B-]12n3c4cc5-c6c(-c([n+]25)cc2n1c(cc3c1c4cccc1)c1c2cccc1)cccc6",
      "HOMO": "-5.57",
      "LUMO": "-2.3",
      "VEE": "2.53",
      "Lmax": "490.0395256917",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.57",
      "DipoleMomentS1": "7.57",
      "DipoleMomentT1": "8.39",
      "ionizationPotential": "6.39",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "JSOYTMOWCXZMMI-UHFFFAOYSA-N",
      "Smiles": "Oc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)O",
      "HOMO": "-5.48",
      "LUMO": "-2.76",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "2.07",
      "E00T1": "1.12",
      "Eox": "0.57",
      "Ered": "",
      "ES1ox": "-1.5",
      "ES1red": "",
      "ET1ox": "-0.55",
      "ET1red": "",
      "DipoleMomentS0": "2.84",
      "DipoleMomentS1": "2.84",
      "DipoleMomentT1": "2.51",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JSUHZLWWIINXDU-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)[N+](=O)[O-])C1=CC=C(C1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)[N+](=O)[O-])C1=CC=C(C1)[N+](=O)[O-]",
      "HOMO": "-6.84",
      "LUMO": "-3.56",
      "VEE": "1.9",
      "Lmax": "652.526315789474",
      "E00S1": "2.55",
      "E00T1": "1.97",
      "Eox": "1.84",
      "Ered": "",
      "ES1ox": "-0.71",
      "ES1red": "",
      "ET1ox": "-0.13",
      "ET1red": "",
      "DipoleMomentS0": "0.48",
      "DipoleMomentS1": "0.48",
      "DipoleMomentT1": "3.17",
      "ionizationPotential": "8.04",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "JTJAODTXIAIKGV-QURGRASLSA-N",
      "Smiles": "Oc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)O",
      "HOMO": "-5.99",
      "LUMO": "-3.02",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "2.25",
      "E00T1": "0.88",
      "Eox": "1.14",
      "Ered": "",
      "ES1ox": "-1.11",
      "ES1red": "",
      "ET1ox": "0.26",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "JTJORRCAOCFLCX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(n1C)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)C#N)c1ccc(n1C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(n1C)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)C#N)c1ccc(n1C)C#N",
      "HOMO": "-5.65",
      "LUMO": "-2.49",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "1.62",
      "Eox": "0.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.77",
      "ET1red": "",
      "DipoleMomentS0": "3.24",
      "DipoleMomentS1": "3.24",
      "DipoleMomentT1": "2.23",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "JTQCJUUHQNOLPB-UHFFFAOYSA-N",
      "Smiles": "COc1csc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1scc(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1csc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1scc(n1)OC",
      "HOMO": "-6.36",
      "LUMO": "-3.27",
      "VEE": "2.24",
      "Lmax": "553.482142857143",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.57",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.32",
      "DipoleMomentS1": "0.32",
      "DipoleMomentT1": "5.6",
      "ionizationPotential": "7.25",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "JUDXSGWCARZGAW-HSFFGMMNSA-N",
      "Smiles": "O/C=C\\c1cc(C#N)cc(c1C#N)/C=C/O",
      "MolView_url": "http://molview.org/?q=O/C=C\\c1cc(C#N)cc(c1C#N)/C=C/O",
      "HOMO": "-6.52",
      "LUMO": "-2.64",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "",
      "E00T1": "2.19",
      "Eox": "-1.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.43",
      "ET1red": "",
      "DipoleMomentS0": "4.93",
      "DipoleMomentS1": "4.93",
      "DipoleMomentT1": "8.6",
      "ionizationPotential": "8.13",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JUHVRKSVADQROH-DQSJHHFOSA-N",
      "Smiles": "FC(Oc1ccc(s1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(s1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-5.57",
      "LUMO": "-2.63",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "0.33",
      "Eox": "-0.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "2.25",
      "DipoleMomentS1": "2.25",
      "DipoleMomentT1": "3.13",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "JUJDRNUIBVKKIU-OCEACIFDSA-N",
      "Smiles": "Fc1ccc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc([nH]1)F",
      "HOMO": "-5.7",
      "LUMO": "-2.6",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.45",
      "DipoleMomentS1": "1.45",
      "DipoleMomentT1": "3.74",
      "ionizationPotential": "6.54",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "JUMBSOFKWVILMC-UHFFFAOYSA-N",
      "Smiles": "Cc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)C",
      "HOMO": "-5.48",
      "LUMO": "-2.67",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "1.95",
      "E00T1": "",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "-1.3",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.22",
      "DipoleMomentS1": "2.22",
      "DipoleMomentT1": "2.18",
      "ionizationPotential": "6.18",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JUXQBPFUXGVYKT-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)F)cc(c5ccc4)c1ccc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)F)cc(c5ccc4)c1ccc([nH]1)F",
      "HOMO": "-5.22",
      "LUMO": "-2.48",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "1.9",
      "E00T1": "",
      "Eox": "0.41",
      "Ered": "",
      "ES1ox": "-1.49",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.41",
      "DipoleMomentS1": "2.41",
      "DipoleMomentT1": "2.19",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "JVIAWTOETIGNIE-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc([nH]1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1ccc([nH]1)[C@]1(C)N=N1",
      "HOMO": "-5.93",
      "LUMO": "-2.03",
      "VEE": "3.12",
      "Lmax": "397.371794871795",
      "E00S1": "2.4",
      "E00T1": "1.84",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "-1.4",
      "ES1red": "",
      "ET1ox": "-0.84",
      "ET1red": "",
      "DipoleMomentS0": "1.24",
      "DipoleMomentS1": "1.24",
      "DipoleMomentT1": "9.03",
      "ionizationPotential": "7.29",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JVKVDNMEESIYAD-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cnc([nH]1)F)C#N)c1cnc([nH]1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cnc([nH]1)F)C#N)c1cnc([nH]1)F",
      "HOMO": "-6.5",
      "LUMO": "-2.94",
      "VEE": "3.17",
      "Lmax": "391.104100946372",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.98",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.75",
      "DipoleMomentS1": "1.75",
      "DipoleMomentT1": "6.29",
      "ionizationPotential": "8.14",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JVKYIKMCUZHPLM-QPLCGJKRSA-N",
      "Smiles": "O=Cn1c(C)ccc1c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C=O)C",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C)ccc1c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C=O)C",
      "HOMO": "-6.02",
      "LUMO": "-2.4",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.09",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.71",
      "DipoleMomentS1": "5.71",
      "DipoleMomentT1": "4.91",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "JVVJERDNGFIVKM-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(n1C)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)OC)c1ccc(n1C)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)OC)c1ccc(n1C)OC",
      "HOMO": "-5.22",
      "LUMO": "-2.29",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "1.82",
      "Eox": "0.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-1.39",
      "ET1red": "",
      "DipoleMomentS0": "10.86",
      "DipoleMomentS1": "10.86",
      "DipoleMomentT1": "12.72",
      "ionizationPotential": "5.67",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "JVZBYQGPMAXYPW-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(nc1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1ccc(nc1)[C@]1(C)N=N1",
      "HOMO": "-6.97",
      "LUMO": "-2.15",
      "VEE": "3.26",
      "Lmax": "380.306748466258",
      "E00S1": "3.03",
      "E00T1": "2.45",
      "Eox": "2.01",
      "Ered": "",
      "ES1ox": "-1.02",
      "ES1red": "",
      "ET1ox": "-0.44",
      "ET1red": "",
      "DipoleMomentS0": "3.49",
      "DipoleMomentS1": "3.49",
      "DipoleMomentT1": "7.39",
      "ionizationPotential": "8.25",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JWJOAFLKSWXFJL-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(o1)C)C#N)c1ccc(o1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(o1)C)C#N)c1ccc(o1)C",
      "HOMO": "-6.18",
      "LUMO": "-2.75",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "1.96",
      "Eox": "-1.23",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.19",
      "ET1red": "",
      "DipoleMomentS0": "2.61",
      "DipoleMomentS1": "2.61",
      "DipoleMomentT1": "7.91",
      "ionizationPotential": "7.42",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JWKMTUUMZMVCEU-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(o1)c1cc2c3cc(c4ncc(o4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)O)cc(c5ccc4)c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(o1)c1cc2c3cc(c4ncc(o4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)O)cc(c5ccc4)c1ncc(o1)O",
      "HOMO": "-5.43",
      "LUMO": "-2.84",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "1.79",
      "E00T1": "0.25",
      "Eox": "-4.2",
      "Ered": "",
      "ES1ox": "-5.99",
      "ES1red": "",
      "ET1ox": "-4.45",
      "ET1red": "",
      "DipoleMomentS0": "2.26",
      "DipoleMomentS1": "2.26",
      "DipoleMomentT1": "2.82",
      "ionizationPotential": "6.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "JWSPNZSBXWFJQK-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(s1)c1cc2c3cc(c4ncc(s4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)Cl)cc(c5ccc4)c1ncc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(s1)c1cc2c3cc(c4ncc(s4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)Cl)cc(c5ccc4)c1ncc(s1)Cl",
      "HOMO": "-5.66",
      "LUMO": "-2.92",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.8",
      "DipoleMomentS1": "1.8",
      "DipoleMomentT1": "1.42",
      "ionizationPotential": "6.41",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "JXAQNEJXRUBTFL-VHXPQNKSSA-N",
      "Smiles": "O=Cn1c(C)ccc1c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(n1C=O)C",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C)ccc1c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(n1C=O)C",
      "HOMO": "-5.58",
      "LUMO": "-2.46",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "-0.35",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.84",
      "ET1red": "",
      "DipoleMomentS0": "11.93",
      "DipoleMomentS1": "11.93",
      "DipoleMomentT1": "12.09",
      "ionizationPotential": "5.36",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "JXISTJUAWADPOS-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(C#C[N+](=O)[O-])cc(cc1C#C[N+](=O)[O-])C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(C#C[N+](=O)[O-])cc(cc1C#C[N+](=O)[O-])C#N",
      "HOMO": "-8.51",
      "LUMO": "-3.9",
      "VEE": "3.56",
      "Lmax": "348.258426966292",
      "E00S1": "",
      "E00T1": "2.5",
      "Eox": "3.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.87",
      "ET1red": "",
      "DipoleMomentS0": "6.15",
      "DipoleMomentS1": "6.15",
      "DipoleMomentT1": "8.03",
      "ionizationPotential": "10.04",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JXJUFLLXYYKGOQ-MVJHLKBCSA-N",
      "Smiles": "FC(Oc1ccc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc([nH]1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc([nH]1)OC(F)(F)F)(F)F",
      "HOMO": "-5.89",
      "LUMO": "-2.45",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "0.51",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "1.13",
      "DipoleMomentS1": "1.13",
      "DipoleMomentT1": "1.29",
      "ionizationPotential": "6.64",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "JXNPOZCEXHOVEW-QURGRASLSA-N",
      "Smiles": "N#Cc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)C#N",
      "HOMO": "-5.94",
      "LUMO": "-3.21",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "2.04",
      "E00T1": "0.75",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "-1.01",
      "ES1red": "",
      "ET1ox": "0.28",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.92",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "JXPVUGMJGJKSTD-QPLCGJKRSA-N",
      "Smiles": "Clc1cnc(nc1)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1ncc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(nc1)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1ncc(cn1)Cl",
      "HOMO": "-5.91",
      "LUMO": "-2.87",
      "VEE": "2.55",
      "Lmax": "486.196078431373",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.42",
      "DipoleMomentS1": "5.42",
      "DipoleMomentT1": "4.33",
      "ionizationPotential": "6.54",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "JXTWNJLAJDOLPS-UHFFFAOYSA-N",
      "Smiles": "COC1=CC=C(S1(=O)=O)c1cc(C#N)cc(c1C#N)C1=CC=C(S1(=O)=O)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(S1(=O)=O)c1cc(C#N)cc(c1C#N)C1=CC=C(S1(=O)=O)OC",
      "HOMO": "-6.98",
      "LUMO": "-3.04",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.95",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "12.31",
      "DipoleMomentS1": "12.31",
      "DipoleMomentT1": "12.43",
      "ionizationPotential": "7.93",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JXVRSQVBQFYKMK-UHFFFAOYSA-N",
      "Smiles": "ClC1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)Cl)C1=CC=C(C1)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)Cl)C1=CC=C(C1)Cl",
      "HOMO": "-5.4",
      "LUMO": "-2.44",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "1.54",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.76",
      "ET1red": "",
      "DipoleMomentS0": "6.58",
      "DipoleMomentS1": "6.58",
      "DipoleMomentT1": "7.56",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "JXYBLMIQLNTGAY-ISLYRVAYSA-N",
      "Smiles": "SC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)S",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)S",
      "HOMO": "-5.98",
      "LUMO": "-3.58",
      "VEE": "2.29",
      "Lmax": "541.397379912664",
      "E00S1": "1.71",
      "E00T1": "0.39",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "-0.79",
      "ES1red": "",
      "ET1ox": "0.53",
      "ET1red": "",
      "DipoleMomentS0": "6.81",
      "DipoleMomentS1": "6.81",
      "DipoleMomentT1": "5.93",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "JYNPPXUFMJEUEV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)S)C#N)c1ccc(cn1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)S)C#N)c1ccc(cn1)S",
      "HOMO": "-6.52",
      "LUMO": "-2.88",
      "VEE": "3.38",
      "Lmax": "366.804733727811",
      "E00S1": "",
      "E00T1": "2.67",
      "Eox": "-1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.67",
      "ET1red": "",
      "DipoleMomentS0": "5.45",
      "DipoleMomentS1": "5.45",
      "DipoleMomentT1": "8.68",
      "ionizationPotential": "7.85",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "JYSCQQRNTHDPBR-UHFFFAOYSA-N",
      "Smiles": "CC1(C)N=N1",
      "MolView_url": "http://molview.org/?q=CC1(C)N=N1",
      "HOMO": "-7.47",
      "LUMO": "-1.87",
      "VEE": "3.27",
      "Lmax": "379.14373088685",
      "E00S1": "3.24",
      "E00T1": "2.55",
      "Eox": "2.43",
      "Ered": "",
      "ES1ox": "-0.81",
      "ES1red": "",
      "ET1ox": "-0.12",
      "ET1red": "",
      "DipoleMomentS0": "2.94",
      "DipoleMomentS1": "2.94",
      "DipoleMomentT1": "4.77",
      "ionizationPotential": "9.15",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "JYSVEOQXUAPQDZ-DQRAZIAOSA-N",
      "Smiles": "Clc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)Cl",
      "HOMO": "-5.5",
      "LUMO": "-2.87",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.33",
      "ET1red": "",
      "DipoleMomentS0": "7.56",
      "DipoleMomentS1": "7.56",
      "DipoleMomentT1": "6.3",
      "ionizationPotential": "6.22",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "JYSVEOQXUAPQDZ-QURGRASLSA-N",
      "Smiles": "Clc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)Cl",
      "HOMO": "-5.43",
      "LUMO": "-2.88",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "0.71",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.18",
      "ET1red": "",
      "DipoleMomentS0": "1.88",
      "DipoleMomentS1": "1.88",
      "DipoleMomentT1": "2.28",
      "ionizationPotential": "6.19",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "JYTUXPJQXFTXJE-VHXPQNKSSA-N",
      "Smiles": "Cc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)C",
      "MolView_url": "http://molview.org/?q=Cc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)C",
      "HOMO": "-6.73",
      "LUMO": "-3.03",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "1.84",
      "E00T1": "1.34",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.91",
      "DipoleMomentS1": "3.91",
      "DipoleMomentT1": "3.73",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "JYTUXPJQXFTXJE-WCWDXBQESA-N",
      "Smiles": "Cc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)C",
      "MolView_url": "http://molview.org/?q=Cc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)C",
      "HOMO": "-6.99",
      "LUMO": "-3.18",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "1.34",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.11",
      "DipoleMomentS1": "0.11",
      "DipoleMomentT1": "0.24",
      "ionizationPotential": "7.76",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "JZDBLSYECNRXRI-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C#Cc1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C#C[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C#Cc1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C#C[N+](=O)[O-]",
      "HOMO": "-5.94",
      "LUMO": "-3.64",
      "VEE": "1.37",
      "Lmax": "904.963503649635",
      "E00S1": "1.61",
      "E00T1": "1",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "-0.46",
      "ES1red": "",
      "ET1ox": "0.15",
      "ET1red": "",
      "DipoleMomentS0": "16.73",
      "DipoleMomentS1": "16.73",
      "DipoleMomentT1": "20.83",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "JZKWNEIVZJDNHN-UHFFFAOYSA-N",
      "Smiles": "FC(c1coc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1occ(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1coc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1occ(n1)C(F)(F)F)(F)F",
      "HOMO": "-7.35",
      "LUMO": "-3.39",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "3.09",
      "E00T1": "2.47",
      "Eox": "2.48",
      "Ered": "",
      "ES1ox": "-0.61",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "4.5",
      "DipoleMomentS1": "4.5",
      "DipoleMomentT1": "3.77",
      "ionizationPotential": "8.49",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KAFHCZHNLYGURE-UHFFFAOYSA-N",
      "Smiles": "Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)C1=CC=C(C1)C(F)(F)F)c1c3cc(cc1)C1=CC=C(C1)C(F)(F)F)ccc(c5)C1=CC=C(C1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)C1=CC=C(C1)C(F)(F)F)c1c3cc(cc1)C1=CC=C(C1)C(F)(F)F)ccc(c5)C1=CC=C(C1)C(F)(F)F",
      "HOMO": "-5.54",
      "LUMO": "-2.55",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.63",
      "ET1red": "",
      "DipoleMomentS0": "5.58",
      "DipoleMomentS1": "5.58",
      "DipoleMomentT1": "5.49",
      "ionizationPotential": "6.44",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "KANJJQPVBDKKFU-VHXPQNKSSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(nc1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(nc1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(cn1)[N+](=O)[O-]",
      "HOMO": "-6.19",
      "LUMO": "-3.58",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.02",
      "DipoleMomentS1": "1.02",
      "DipoleMomentT1": "0.65",
      "ionizationPotential": "7.08",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "KAOWGNCTHPSQJR-OCEACIFDSA-N",
      "Smiles": "c1cnc(nc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncccn1",
      "MolView_url": "http://molview.org/?q=c1cnc(nc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncccn1",
      "HOMO": "-6.71",
      "LUMO": "-2.93",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "1.44",
      "Eox": "1.83",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.44",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "KAOWGNCTHPSQJR-QPLCGJKRSA-N",
      "Smiles": "c1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncccn1",
      "MolView_url": "http://molview.org/?q=c1cnc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncccn1",
      "HOMO": "-6.51",
      "LUMO": "-2.6",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.41",
      "DipoleMomentS1": "8.41",
      "DipoleMomentT1": "6",
      "ionizationPotential": "7.02",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "KAVRPHUQUURSCB-DQRAZIAOSA-N",
      "Smiles": "Oc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)O",
      "HOMO": "-6.01",
      "LUMO": "-3.03",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.47",
      "DipoleMomentS1": "8.47",
      "DipoleMomentT1": "7.56",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "KAVRPHUQUURSCB-QURGRASLSA-N",
      "Smiles": "Oc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)O",
      "HOMO": "-6.06",
      "LUMO": "-3.1",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.14",
      "DipoleMomentS1": "1.14",
      "DipoleMomentT1": "1.67",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "KAWFTXZXSPHVEO-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)S)c1ccc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)S)c1ccc(s1)S",
      "HOMO": "-5.58",
      "LUMO": "-2.48",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.78",
      "DipoleMomentS1": "5.78",
      "DipoleMomentT1": "7.35",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "KAZZZJFZFBSVOH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)C(F)(F)F)C#N)c1ccc(cn1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)C(F)(F)F)C#N)c1ccc(cn1)C(F)(F)F",
      "HOMO": "-7.46",
      "LUMO": "-3.1",
      "VEE": "4.11",
      "Lmax": "301.654501216545",
      "E00S1": "",
      "E00T1": "3",
      "Eox": "-0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.78",
      "ET1red": "",
      "DipoleMomentS0": "2.39",
      "DipoleMomentS1": "2.39",
      "DipoleMomentT1": "2.75",
      "ionizationPotential": "9",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "KBARPJGZXKOOCQ-QURGRASLSA-N",
      "Smiles": "N#Cc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)C#N",
      "HOMO": "-5.93",
      "LUMO": "-3.27",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "1.95",
      "E00T1": "0.7",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.27",
      "DipoleMomentS1": "6.27",
      "DipoleMomentT1": "5.88",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "KBRZVNHSPYSMKA-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(s1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1ccc(s1)[C@]1(F)N=N1",
      "HOMO": "-6.94",
      "LUMO": "-2.64",
      "VEE": "3.36",
      "Lmax": "368.988095238095",
      "E00S1": "2.69",
      "E00T1": "2.09",
      "Eox": "1.99",
      "Ered": "",
      "ES1ox": "-0.7",
      "ES1red": "",
      "ET1ox": "-0.1",
      "ET1red": "",
      "DipoleMomentS0": "2.07",
      "DipoleMomentS1": "2.07",
      "DipoleMomentT1": "8.45",
      "ionizationPotential": "8.55",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "KCFRCSBJQYXGDH-OCEACIFDSA-N",
      "Smiles": "N#Cc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)C#N",
      "HOMO": "-6.5",
      "LUMO": "-3.52",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "",
      "E00T1": "0.92",
      "Eox": "1.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.61",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.44",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "KCFRCSBJQYXGDH-QPLCGJKRSA-N",
      "Smiles": "N#Cc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)C#N",
      "HOMO": "-6.42",
      "LUMO": "-3.43",
      "VEE": "2.3",
      "Lmax": "539.04347826087",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.33",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.44",
      "DipoleMomentS1": "1.44",
      "DipoleMomentT1": "1.55",
      "ionizationPotential": "7.29",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "KDMLHGDHICZGAW-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(cc1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1ccc(cc1)[C@]1(F)N=N1",
      "HOMO": "-7.27",
      "LUMO": "-2.62",
      "VEE": "3.31",
      "Lmax": "374.561933534743",
      "E00S1": "2.91",
      "E00T1": "2.34",
      "Eox": "2.35",
      "Ered": "",
      "ES1ox": "-0.56",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "1.18",
      "DipoleMomentS1": "1.18",
      "DipoleMomentT1": "6.23",
      "ionizationPotential": "8.83",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "KDMSTFVZHVDMGN-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cnc([nH]1)[N+](=O)[O-])C#N)c1cnc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cnc([nH]1)[N+](=O)[O-])C#N)c1cnc([nH]1)[N+](=O)[O-]",
      "HOMO": "-7.27",
      "LUMO": "-3.66",
      "VEE": "3.36",
      "Lmax": "368.988095238095",
      "E00S1": "",
      "E00T1": "2.04",
      "Eox": "-0.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.34",
      "ET1red": "",
      "DipoleMomentS0": "7",
      "DipoleMomentS1": "7",
      "DipoleMomentT1": "11.75",
      "ionizationPotential": "9.03",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "KDNRVQWCLWVKSQ-UHFFFAOYSA-N",
      "Smiles": "F/N=C/c1cc2c3cc(/C=N/F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/F)cc(c5ccc4)/C=N/F",
      "MolView_url": "http://molview.org/?q=F/N=C/c1cc2c3cc(/C=N/F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/F)cc(c5ccc4)/C=N/F",
      "HOMO": "-6.03",
      "LUMO": "-3.25",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.96",
      "E00T1": "1.1",
      "Eox": "1.21",
      "Ered": "",
      "ES1ox": "-0.75",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "3.28",
      "DipoleMomentS1": "3.28",
      "DipoleMomentT1": "2.17",
      "ionizationPotential": "7.16",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "KEUZKSADLPIPAJ-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(o1)C(F)(F)F)(F)F",
      "HOMO": "-6.82",
      "LUMO": "-3.26",
      "VEE": "2.29",
      "Lmax": "541.397379912664",
      "E00S1": "2.76",
      "E00T1": "2.19",
      "Eox": "1.97",
      "Ered": "",
      "ES1ox": "-0.79",
      "ES1red": "",
      "ET1ox": "-0.22",
      "ET1red": "",
      "DipoleMomentS0": "3.88",
      "DipoleMomentS1": "3.88",
      "DipoleMomentT1": "4.1",
      "ionizationPotential": "7.93",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KFGJYPPAOUOYSY-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)OC(F)(F)F)cc(c5ccc4)c1ncc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)OC(F)(F)F)cc(c5ccc4)c1ncc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-5.66",
      "LUMO": "-2.87",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "1.95",
      "E00T1": "1.14",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.12",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.35",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "KFIVBGSZXBHTMF-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(o1)C#N",
      "HOMO": "-6.84",
      "LUMO": "-3.32",
      "VEE": "2.26",
      "Lmax": "548.58407079646",
      "E00S1": "2.74",
      "E00T1": "",
      "Eox": "2.01",
      "Ered": "",
      "ES1ox": "-0.73",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.25",
      "DipoleMomentS1": "6.25",
      "DipoleMomentT1": "5.17",
      "ionizationPotential": "8.01",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KFMHGTKULGBBOO-DQRAZIAOSA-N",
      "Smiles": "Clc1coc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1occ(n1)Cl",
      "HOMO": "-5.92",
      "LUMO": "-2.87",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "",
      "E00T1": "0.36",
      "Eox": "0.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "6.04",
      "DipoleMomentS1": "6.04",
      "DipoleMomentT1": "4.44",
      "ionizationPotential": "6.69",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "KFQPAZXSJDMCQC-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1nnc(nn1)O)C#N)c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1nnc(nn1)O)C#N)c1nnc(nn1)O",
      "HOMO": "-7.5",
      "LUMO": "-3.44",
      "VEE": "2.11",
      "Lmax": "587.582938388626",
      "E00S1": "",
      "E00T1": "1.44",
      "Eox": "2.48",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.04",
      "ET1red": "",
      "DipoleMomentS0": "4.07",
      "DipoleMomentS1": "4.07",
      "DipoleMomentT1": "3.91",
      "ionizationPotential": "8.7",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "KGAOXUWMVUSMIW-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(s1)Cl)C#N)c1ccc(s1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(s1)Cl)C#N)c1ccc(s1)Cl",
      "HOMO": "-6.8",
      "LUMO": "-2.68",
      "VEE": "3.33",
      "Lmax": "372.312312312312",
      "E00S1": "",
      "E00T1": "2.57",
      "Eox": "1.88",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.69",
      "ET1red": "",
      "DipoleMomentS0": "1.24",
      "DipoleMomentS1": "1.24",
      "DipoleMomentT1": "4.06",
      "ionizationPotential": "7.97",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "KGNDRWXZJVZDAK-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)F)c1cnc([nH]1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)F)c1cnc([nH]1)F",
      "HOMO": "-6.57",
      "LUMO": "-3.17",
      "VEE": "2.26",
      "Lmax": "548.58407079646",
      "E00S1": "2.61",
      "E00T1": "2.06",
      "Eox": "1.69",
      "Ered": "",
      "ES1ox": "-0.92",
      "ES1red": "",
      "ET1ox": "-0.37",
      "ET1red": "",
      "DipoleMomentS0": "4.15",
      "DipoleMomentS1": "4.15",
      "DipoleMomentT1": "2.67",
      "ionizationPotential": "7.69",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KGOQQGKHFVCHLU-VXPUYCOJSA-N",
      "Smiles": "Oc1coc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1coc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1occ(n1)O",
      "HOMO": "-5.74",
      "LUMO": "-2.8",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.76",
      "DipoleMomentS1": "6.76",
      "DipoleMomentT1": "6.15",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "KGTPITGDDKVHBS-UHFFFAOYSA-N",
      "Smiles": "Clc1ncc([nH]1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1ncc([nH]1)[C@]1(Cl)N=N1",
      "HOMO": "-6.96",
      "LUMO": "-2.67",
      "VEE": "3.45",
      "Lmax": "359.36231884058",
      "E00S1": "2.72",
      "E00T1": "2.13",
      "Eox": "2.01",
      "Ered": "",
      "ES1ox": "-0.71",
      "ES1red": "",
      "ET1ox": "-0.12",
      "ET1red": "",
      "DipoleMomentS0": "3.58",
      "DipoleMomentS1": "3.58",
      "DipoleMomentT1": "7.78",
      "ionizationPotential": "8.49",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "KHPJYWVSKYTXIO-UHFFFAOYSA-N",
      "Smiles": "N=C[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=N=C[C@@H]1N=N1",
      "HOMO": "-8.2",
      "LUMO": "-2.35",
      "VEE": "3.61",
      "Lmax": "343.434903047091",
      "E00S1": "3.62",
      "E00T1": "2.92",
      "Eox": "3.24",
      "Ered": "",
      "ES1ox": "-0.38",
      "ES1red": "",
      "ET1ox": "0.32",
      "ET1red": "",
      "DipoleMomentS0": "3.11",
      "DipoleMomentS1": "3.11",
      "DipoleMomentT1": "3.02",
      "ionizationPotential": "9.69",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "KIWAMNKIBWCIKN-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cc1)C)C#N)c1ccc(cc1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cc1)C)C#N)c1ccc(cc1)C",
      "HOMO": "-6.71",
      "LUMO": "-2.74",
      "VEE": "3.79",
      "Lmax": "327.12401055409",
      "E00S1": "",
      "E00T1": "2.84",
      "Eox": "-1.12",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.96",
      "ET1red": "",
      "DipoleMomentS0": "1.61",
      "DipoleMomentS1": "1.61",
      "DipoleMomentT1": "4.75",
      "ionizationPotential": "7.93",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "KJOGKVFGMQZSHF-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)C)c1ccc(cc1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)C)c1ccc(cc1)C",
      "HOMO": "-6.69",
      "LUMO": "-3.08",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.09",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.88",
      "DipoleMomentS1": "1.88",
      "DipoleMomentT1": "5.89",
      "ionizationPotential": "7.52",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KKNUWMXPTDAORX-NXVVXOECSA-N",
      "Smiles": "Sc1nnc(nn1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1nnc(nn1)S",
      "MolView_url": "http://molview.org/?q=Sc1nnc(nn1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1nnc(nn1)S",
      "HOMO": "-6.14",
      "LUMO": "-3.41",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.74",
      "ET1red": "",
      "DipoleMomentS0": "3.73",
      "DipoleMomentS1": "3.73",
      "DipoleMomentT1": "1.51",
      "ionizationPotential": "6.91",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "KKOZKWZUDPEEOI-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(c2ncco2)c(c(c1)c1ncco1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(c2ncco2)c(c(c1)c1ncco1)C#N",
      "HOMO": "-6.9",
      "LUMO": "-3.08",
      "VEE": "3.42",
      "Lmax": "362.514619883041",
      "E00S1": "",
      "E00T1": "2.57",
      "Eox": "-0.8",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.37",
      "ET1red": "",
      "DipoleMomentS0": "4.39",
      "DipoleMomentS1": "4.39",
      "DipoleMomentT1": "5.71",
      "ionizationPotential": "8.41",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "KKUQQWPRCOLPOU-FLWNBWAVSA-N",
      "Smiles": "FC(c1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)C(F)(F)F)(F)F",
      "HOMO": "-6.6",
      "LUMO": "-2.74",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.25",
      "DipoleMomentS1": "7.25",
      "DipoleMomentT1": "6.83",
      "ionizationPotential": "7.5",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "KKZBWXQYRJFBSM-VCFJNTAESA-N",
      "Smiles": "FC(O/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/OC(F)(F)F)(F)F",
      "HOMO": "-5.41",
      "LUMO": "-2.52",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.03",
      "E00T1": "1.17",
      "Eox": "0.61",
      "Ered": "",
      "ES1ox": "-1.42",
      "ES1red": "",
      "ET1ox": "-0.56",
      "ET1red": "",
      "DipoleMomentS0": "4.61",
      "DipoleMomentS1": "4.61",
      "DipoleMomentT1": "4.67",
      "ionizationPotential": "6.36",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "KKZTYSOYSNCIKK-NYEUIXQESA-N",
      "Smiles": "[O-][N+](=O)/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C\\[N+](=O)[O-]",
      "HOMO": "-6.57",
      "LUMO": "-3.94",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "0.62",
      "Eox": "1.6",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.98",
      "ET1red": "",
      "DipoleMomentS0": "7.16",
      "DipoleMomentS1": "7.16",
      "DipoleMomentT1": "8.74",
      "ionizationPotential": "7.71",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "KLEOYUDFCCIRKJ-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)O",
      "HOMO": "-5.33",
      "LUMO": "-2.35",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.32",
      "E00T1": "1.25",
      "Eox": "0.47",
      "Ered": "",
      "ES1ox": "-1.85",
      "ES1red": "",
      "ET1ox": "-0.78",
      "ET1red": "",
      "DipoleMomentS0": "1.79",
      "DipoleMomentS1": "1.79",
      "DipoleMomentT1": "2.24",
      "ionizationPotential": "6.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "KLFDWXZHHWPUHL-ISLYRVAYSA-N",
      "Smiles": "Oc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)O",
      "HOMO": "-5.77",
      "LUMO": "-3.14",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "0.73",
      "Eox": "0.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.12",
      "ET1red": "",
      "DipoleMomentS0": "0.98",
      "DipoleMomentS1": "0.98",
      "DipoleMomentT1": "2.12",
      "ionizationPotential": "6.44",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "KLFDWXZHHWPUHL-ZCXUNETKSA-N",
      "Smiles": "Oc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)O",
      "HOMO": "-5.78",
      "LUMO": "-3.1",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "0.14",
      "Eox": "0.75",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.61",
      "ET1red": "",
      "DipoleMomentS0": "7.71",
      "DipoleMomentS1": "7.71",
      "DipoleMomentT1": "6.58",
      "ionizationPotential": "6.4",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "KMOVHJLIQRHBTM-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)O)c1ncc(cn1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)O)c1ncc(cn1)O",
      "HOMO": "-6.95",
      "LUMO": "-3.17",
      "VEE": "2.57",
      "Lmax": "482.412451361868",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.15",
      "DipoleMomentS1": "4.15",
      "DipoleMomentT1": "7.48",
      "ionizationPotential": "7.83",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KNGUUMPRNXXVCX-UHFFFAOYSA-N",
      "Smiles": "N#CC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)C#N)cc(c5ccc4)C1=CC=C(S1(=O)=O)C#N)C1=CC=C(S1(=O)=O)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)C#N)cc(c5ccc4)C1=CC=C(S1(=O)=O)C#N)C1=CC=C(S1(=O)=O)C#N",
      "HOMO": "-6.04",
      "LUMO": "-3.77",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "1.5",
      "E00T1": "0.22",
      "Eox": "1.18",
      "Ered": "",
      "ES1ox": "-0.32",
      "ES1red": "",
      "ET1ox": "0.96",
      "ET1red": "",
      "DipoleMomentS0": "5.87",
      "DipoleMomentS1": "5.87",
      "DipoleMomentT1": "8.68",
      "ionizationPotential": "7.21",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "KNLLDXZTDYDKJX-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(s1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1cnc(s1)[C@]1(Cl)N=N1",
      "HOMO": "-7.24",
      "LUMO": "-2.71",
      "VEE": "3.19",
      "Lmax": "388.652037617555",
      "E00S1": "3.02",
      "E00T1": "2.42",
      "Eox": "2.27",
      "Ered": "",
      "ES1ox": "-0.75",
      "ES1red": "",
      "ET1ox": "-0.15",
      "ET1red": "",
      "DipoleMomentS0": "2.44",
      "DipoleMomentS1": "2.44",
      "DipoleMomentT1": "9.87",
      "ionizationPotential": "8.7",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "KNPWZQXKAVQUKT-VXPUYCOJSA-N",
      "Smiles": "n1cnnc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1nncnn1",
      "MolView_url": "http://molview.org/?q=n1cnnc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1nncnn1",
      "HOMO": "-6.26",
      "LUMO": "-3.26",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "0.31",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.34",
      "ET1red": "",
      "DipoleMomentS0": "2.33",
      "DipoleMomentS1": "2.33",
      "DipoleMomentT1": "0.87",
      "ionizationPotential": "6.47",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "KNRHLDREPUBFNY-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)OC(F)(F)F)cc(c5ccc4)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)OC(F)(F)F)cc(c5ccc4)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "HOMO": "-5.38",
      "LUMO": "-2.57",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "1.97",
      "E00T1": "0.24",
      "Eox": "-4.2",
      "Ered": "",
      "ES1ox": "-6.17",
      "ES1red": "",
      "ET1ox": "-4.44",
      "ET1red": "",
      "DipoleMomentS0": "7.39",
      "DipoleMomentS1": "7.39",
      "DipoleMomentT1": "7.2",
      "ionizationPotential": "0.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "KNRWPOLAJHKOQA-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)Cl)c1ccc(o1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)Cl)c1ccc(o1)Cl",
      "HOMO": "-6.39",
      "LUMO": "-3.15",
      "VEE": "2.11",
      "Lmax": "587.582938388626",
      "E00S1": "2.49",
      "E00T1": "",
      "Eox": "1.72",
      "Ered": "",
      "ES1ox": "-0.77",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.25",
      "DipoleMomentS1": "4.25",
      "DipoleMomentT1": "7.86",
      "ionizationPotential": "7.39",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KOONMAVXNWLHHF-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)OC(F)(F)F)C#N)c1ccc(cn1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)OC(F)(F)F)C#N)c1ccc(cn1)OC(F)(F)F",
      "HOMO": "-7.08",
      "LUMO": "-2.95",
      "VEE": "3.86",
      "Lmax": "321.19170984456",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.42",
      "DipoleMomentS1": "3.42",
      "DipoleMomentT1": "4.72",
      "ionizationPotential": "8.5",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "KPIOZLAXQVJGSM-QURGRASLSA-N",
      "Smiles": "Cc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)C",
      "HOMO": "-5.07",
      "LUMO": "-2.6",
      "VEE": "2.56",
      "Lmax": "484.296875",
      "E00S1": "1.82",
      "E00T1": "0.65",
      "Eox": "0.15",
      "Ered": "",
      "ES1ox": "-1.67",
      "ES1red": "",
      "ET1ox": "-0.5",
      "ET1red": "",
      "DipoleMomentS0": "2.82",
      "DipoleMomentS1": "2.82",
      "DipoleMomentT1": "5.68",
      "ionizationPotential": "5.67",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "KQACHIMSZHHWMR-UHFFFAOYSA-N",
      "Smiles": "FC(c1coc(n1)c1cc2c3cc(c4occ(n4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)C(F)(F)F)cc(c5ccc4)c1occ(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1coc(n1)c1cc2c3cc(c4occ(n4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)C(F)(F)F)cc(c5ccc4)c1occ(n1)C(F)(F)F)(F)F",
      "HOMO": "-6.32",
      "LUMO": "-3.24",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "1.87",
      "E00T1": "0.21",
      "Eox": "-4.4",
      "Ered": "",
      "ES1ox": "-6.27",
      "ES1red": "",
      "ET1ox": "-4.61",
      "ET1red": "",
      "DipoleMomentS0": "0.26",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "7.57",
      "ionizationPotential": "-0.04",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "KQDDRWCNRRTCIL-FMQUCBEESA-N",
      "Smiles": "CC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)C",
      "HOMO": "-5.99",
      "LUMO": "-3.44",
      "VEE": "2.51",
      "Lmax": "493.94422310757",
      "E00S1": "1.88",
      "E00T1": "0.46",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "-0.85",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "12.47",
      "DipoleMomentS1": "12.47",
      "DipoleMomentT1": "12.39",
      "ionizationPotential": "6.7",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "KQKHCXXGPHCGAE-UHFFFAOYSA-N",
      "Smiles": "O/C=C\\[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=O/C=C\\[C@]1(O)N=N1",
      "HOMO": "-6.64",
      "LUMO": "-2.32",
      "VEE": "3.22",
      "Lmax": "385.031055900621",
      "E00S1": "2.47",
      "E00T1": "1.88",
      "Eox": "1.74",
      "Ered": "",
      "ES1ox": "-0.73",
      "ES1red": "",
      "ET1ox": "-0.14",
      "ET1red": "",
      "DipoleMomentS0": "1.36",
      "DipoleMomentS1": "1.36",
      "DipoleMomentT1": "4.15",
      "ionizationPotential": "8.35",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "KQRDWAWPUACENE-VXPUYCOJSA-N",
      "Smiles": "Oc1cnc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ncc(o1)O",
      "HOMO": "-6.1",
      "LUMO": "-2.58",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.76",
      "DipoleMomentS1": "5.76",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.66",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "KRUMPIYRKNRXGF-AFPVCRNPSA-N",
      "Smiles": "O/C=C/c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/O",
      "MolView_url": "http://molview.org/?q=O/C=C/c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/O",
      "HOMO": "-5.97",
      "LUMO": "-2.59",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.05",
      "ET1red": "",
      "DipoleMomentS0": "2.39",
      "DipoleMomentS1": "2.39",
      "DipoleMomentT1": "4.85",
      "ionizationPotential": "6.87",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "KRYKMKOZVMXKQR-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)S",
      "HOMO": "-5.38",
      "LUMO": "-2.55",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "",
      "E00T1": "1.16",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.16",
      "DipoleMomentS1": "3.16",
      "DipoleMomentT1": "2.93",
      "ionizationPotential": "6.04",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "KSLJYANZKHBSLQ-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)[N+](=O)[O-]",
      "HOMO": "-6.11",
      "LUMO": "-3.5",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "1.94",
      "E00T1": "0.72",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "KSQNTTKMYJSUTA-VHXPQNKSSA-N",
      "Smiles": "Cc1ccc(n1C)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(n1C)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(n1C)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(n1C)C",
      "HOMO": "-5.04",
      "LUMO": "-2.33",
      "VEE": "2.51",
      "Lmax": "493.94422310757",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.62",
      "DipoleMomentS1": "10.62",
      "DipoleMomentT1": "7.27",
      "ionizationPotential": "5.55",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "KSWNZYPSSAEAJE-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1coc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1occ(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1occ(n1)OC(F)(F)F)(F)F",
      "HOMO": "-7.11",
      "LUMO": "-3.38",
      "VEE": "2.27",
      "Lmax": "546.167400881057",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.84",
      "DipoleMomentS1": "3.84",
      "DipoleMomentT1": "16.33",
      "ionizationPotential": "8.05",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KSZNNMZCXNHKGV-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)C)c1occ(n1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)C)c1occ(n1)C",
      "HOMO": "-6.78",
      "LUMO": "-3.27",
      "VEE": "2.25",
      "Lmax": "551.022222222222",
      "E00S1": "2.75",
      "E00T1": "2.2",
      "Eox": "1.87",
      "Ered": "",
      "ES1ox": "-0.88",
      "ES1red": "",
      "ET1ox": "-0.33",
      "ET1red": "",
      "DipoleMomentS0": "1.88",
      "DipoleMomentS1": "1.88",
      "DipoleMomentT1": "6.16",
      "ionizationPotential": "7.69",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KTOKHRZFCPOKGT-UHFFFAOYSA-N",
      "Smiles": "Fc1nnc(nn1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1nnc(nn1)[C@]1(F)N=N1",
      "HOMO": "-7.99",
      "LUMO": "-3.87",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.15",
      "E00T1": "1.49",
      "Eox": "3.03",
      "Ered": "",
      "ES1ox": "0.88",
      "ES1red": "",
      "ET1ox": "1.54",
      "ET1red": "",
      "DipoleMomentS0": "0.31",
      "DipoleMomentS1": "0.31",
      "DipoleMomentT1": "0.19",
      "ionizationPotential": "9.71",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "KUCPGGFZVKBQSI-VHXPQNKSSA-N",
      "Smiles": "FC1=CC=C(S1(=O)=O)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(S1(=O)=O)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(S1(=O)=O)c1ccc(cc1)/N=N\\c1ccc(cc1)C1=CC=C(S1(=O)=O)F",
      "HOMO": "-6.49",
      "LUMO": "-3.05",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "0.45",
      "Eox": "1.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.02",
      "ET1red": "",
      "DipoleMomentS0": "13.15",
      "DipoleMomentS1": "13.15",
      "DipoleMomentT1": "13.47",
      "ionizationPotential": "7.33",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "KUPYCWQOMWMFLN-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc([nH]1)F)C#N)c1ccc([nH]1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc([nH]1)F)C#N)c1ccc([nH]1)F",
      "HOMO": "-6.06",
      "LUMO": "-2.75",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "",
      "E00T1": "1.94",
      "Eox": "-1.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.18",
      "ET1red": "",
      "DipoleMomentS0": "1.48",
      "DipoleMomentS1": "1.48",
      "DipoleMomentT1": "7.63",
      "ionizationPotential": "7.47",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "KVSJSWGMWHJJOX-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1csc(n1)c1cc2c3cc(c4scc(n4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)[N+](=O)[O-])cc(c5ccc4)c1scc(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1csc(n1)c1cc2c3cc(c4scc(n4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)[N+](=O)[O-])cc(c5ccc4)c1scc(n1)[N+](=O)[O-]",
      "HOMO": "-5.88",
      "LUMO": "-3.13",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "",
      "E00T1": "0.24",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.79",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.18",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "KVUZEYJKDMWDHP-VHXPQNKSSA-N",
      "Smiles": "c1ccc(cc1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccccc1",
      "MolView_url": "http://molview.org/?q=c1ccc(cc1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccccc1",
      "HOMO": "-5.54",
      "LUMO": "-2.47",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.15",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.2",
      "DipoleMomentS1": "6.2",
      "DipoleMomentT1": "4",
      "ionizationPotential": "6.21",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "KVVLQIRVYQOTOI-DQRAZIAOSA-N",
      "Smiles": "Cc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)C",
      "MolView_url": "http://molview.org/?q=Cc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)C",
      "HOMO": "-5.48",
      "LUMO": "-2.69",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "0.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.27",
      "ET1red": "",
      "DipoleMomentS0": "9.75",
      "DipoleMomentS1": "9.75",
      "DipoleMomentT1": "2.92",
      "ionizationPotential": "6.03",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "KVVLQIRVYQOTOI-QURGRASLSA-N",
      "Smiles": "Cc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)C",
      "MolView_url": "http://molview.org/?q=Cc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)C",
      "HOMO": "-5.31",
      "LUMO": "-2.8",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "",
      "E00T1": "0.71",
      "Eox": "0.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.32",
      "ET1red": "",
      "DipoleMomentS0": "3.38",
      "DipoleMomentS1": "3.38",
      "DipoleMomentT1": "3.42",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "KVYQPBKWBDUFHN-FOCLMDBBSA-N",
      "Smiles": "Clc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)Cl",
      "HOMO": "-6.68",
      "LUMO": "-3.67",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "0.9",
      "Eox": "1.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.81",
      "ET1red": "",
      "DipoleMomentS0": "4.47",
      "DipoleMomentS1": "4.47",
      "DipoleMomentT1": "5.05",
      "ionizationPotential": "7.66",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "KVYQPBKWBDUFHN-NXVVXOECSA-N",
      "Smiles": "Clc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)Cl",
      "HOMO": "-6.58",
      "LUMO": "-3.55",
      "VEE": "2.31",
      "Lmax": "536.709956709957",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.51",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.28",
      "ET1red": "",
      "DipoleMomentS0": "2.14",
      "DipoleMomentS1": "2.14",
      "DipoleMomentT1": "2.02",
      "ionizationPotential": "7.51",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "KWAYRWYAVSZKQR-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)C)c1ncc(o1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)C)c1ncc(o1)C",
      "HOMO": "-6.69",
      "LUMO": "-3.25",
      "VEE": "2.2",
      "Lmax": "563.545454545455",
      "E00S1": "2.69",
      "E00T1": "2.15",
      "Eox": "1.75",
      "Ered": "",
      "ES1ox": "-0.94",
      "ES1red": "",
      "ET1ox": "-0.4",
      "ET1red": "",
      "DipoleMomentS0": "3.03",
      "DipoleMomentS1": "3.03",
      "DipoleMomentT1": "6.97",
      "ionizationPotential": "7.58",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KWSYKCVMUMMOPV-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)O)c1cnc([nH]1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)O)c1cnc([nH]1)O",
      "HOMO": "-6.17",
      "LUMO": "-3.11",
      "VEE": "2.07",
      "Lmax": "598.937198067633",
      "E00S1": "2.31",
      "E00T1": "1.82",
      "Eox": "1.33",
      "Ered": "",
      "ES1ox": "-0.98",
      "ES1red": "",
      "ET1ox": "-0.49",
      "ET1red": "",
      "DipoleMomentS0": "3.99",
      "DipoleMomentS1": "3.99",
      "DipoleMomentT1": "8.47",
      "ionizationPotential": "7.17",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KXYIINYJULPZNE-UHFFFAOYSA-N",
      "Smiles": "Fc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)F)cc(c5ccc4)c1cnc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)F)cc(c5ccc4)c1cnc([nH]1)F",
      "HOMO": "-5.49",
      "LUMO": "-2.65",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "2",
      "E00T1": "",
      "Eox": "0.67",
      "Ered": "",
      "ES1ox": "-1.33",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.33",
      "DipoleMomentS1": "5.33",
      "DipoleMomentT1": "6.28",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "KYEYAYZFSPILLW-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)C)c1ccc(s1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)C)c1ccc(s1)C",
      "HOMO": "-6.31",
      "LUMO": "-3.1",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.55",
      "DipoleMomentS1": "2.55",
      "DipoleMomentT1": "6.83",
      "ionizationPotential": "7.16",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KYKCTAHWFMKXQW-UHFFFAOYSA-N",
      "Smiles": "Fc1coc(n1)c1cc2c3cc(c4occ(n4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)F)cc(c5ccc4)c1occ(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1coc(n1)c1cc2c3cc(c4occ(n4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)F)cc(c5ccc4)c1occ(n1)F",
      "HOMO": "-5.72",
      "LUMO": "-3.09",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "1.83",
      "E00T1": "0.21",
      "Eox": "0.88",
      "Ered": "",
      "ES1ox": "-0.95",
      "ES1red": "",
      "ET1ox": "0.67",
      "ET1red": "",
      "DipoleMomentS0": "5.44",
      "DipoleMomentS1": "5.44",
      "DipoleMomentT1": "5.39",
      "ionizationPotential": "0",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "KYXOUDFXQWYADW-FMQUCBEESA-N",
      "Smiles": "O=Cn1c(ccc1[N+](=O)[O-])c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1[N+](=O)[O-])c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)[N+](=O)[O-]",
      "HOMO": "-6.4",
      "LUMO": "-3.53",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.15",
      "DipoleMomentS1": "6.15",
      "DipoleMomentT1": "3.14",
      "ionizationPotential": "7.42",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "KYXOUDFXQWYADW-VXPUYCOJSA-N",
      "Smiles": "O=Cn1c(ccc1[N+](=O)[O-])c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1[N+](=O)[O-])c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)[N+](=O)[O-]",
      "HOMO": "-6.26",
      "LUMO": "-3.55",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "1.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1",
      "ET1red": "",
      "DipoleMomentS0": "10.13",
      "DipoleMomentS1": "10.13",
      "DipoleMomentT1": "9.05",
      "ionizationPotential": "7.22",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "KZGIUAORNALIPR-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(n1C)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C)OC",
      "HOMO": "-5.63",
      "LUMO": "-2.97",
      "VEE": "2",
      "Lmax": "619.9",
      "E00S1": "1.76",
      "E00T1": "1.53",
      "Eox": "0.93",
      "Ered": "",
      "ES1ox": "-0.83",
      "ES1red": "",
      "ET1ox": "-0.6",
      "ET1red": "",
      "DipoleMomentS0": "4.46",
      "DipoleMomentS1": "4.46",
      "DipoleMomentT1": "9.87",
      "ionizationPotential": "6.4",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "KZRFWYMADJUHCA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)O)C#N)C1=CC=C(S1(=O)=O)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)O)C#N)C1=CC=C(S1(=O)=O)O",
      "HOMO": "-6.66",
      "LUMO": "-3.47",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "1.88",
      "Eox": "-0.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.29",
      "ET1red": "",
      "DipoleMomentS0": "14.27",
      "DipoleMomentS1": "14.27",
      "DipoleMomentT1": "13.18",
      "ionizationPotential": "8.2",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "KZTJCLUFCQOUOV-UHFFFAOYSA-N",
      "Smiles": "n1cc([nH]c1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=n1cc([nH]c1)[C@@H]1N=N1",
      "HOMO": "-6.65",
      "LUMO": "-2.25",
      "VEE": "3.42",
      "Lmax": "362.514619883041",
      "E00S1": "2.75",
      "E00T1": "",
      "Eox": "1.74",
      "Ered": "",
      "ES1ox": "-1.01",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.51",
      "DipoleMomentS1": "2.51",
      "DipoleMomentT1": "4.62",
      "ionizationPotential": "8.32",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "KZUSOYJKLMOYIU-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(s1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(s1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.24",
      "LUMO": "-3.54",
      "VEE": "3.64",
      "Lmax": "340.604395604396",
      "E00S1": "3.47",
      "E00T1": "2.3",
      "Eox": "3.25",
      "Ered": "",
      "ES1ox": "-0.22",
      "ES1red": "",
      "ET1ox": "0.95",
      "ET1red": "",
      "DipoleMomentS0": "3.03",
      "DipoleMomentS1": "3.03",
      "DipoleMomentT1": "6.01",
      "ionizationPotential": "9.91",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "KZXQANUVFJQDNS-FMQUCBEESA-N",
      "Smiles": "Cc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)C",
      "HOMO": "-6.04",
      "LUMO": "-3.2",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "0.82",
      "Eox": "1.09",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.27",
      "ET1red": "",
      "DipoleMomentS0": "1.39",
      "DipoleMomentS1": "1.39",
      "DipoleMomentT1": "0.19",
      "ionizationPotential": "6.73",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "KZXQANUVFJQDNS-VXPUYCOJSA-N",
      "Smiles": "Cc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)C",
      "HOMO": "-6.03",
      "LUMO": "-3.13",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.44",
      "DipoleMomentS1": "8.44",
      "DipoleMomentT1": "7.1",
      "ionizationPotential": "6.69",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "LABPMIXCQUQHQC-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)O)cc(c5ccc4)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)O)cc(c5ccc4)c1ccc([nH]1)O",
      "HOMO": "-4.93",
      "LUMO": "-2.35",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "1.78",
      "E00T1": "",
      "Eox": "0.13",
      "Ered": "",
      "ES1ox": "-1.65",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.57",
      "DipoleMomentS1": "2.57",
      "DipoleMomentT1": "",
      "ionizationPotential": "5.44",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LAIDIDGTYFIZQQ-UHFFFAOYSA-N",
      "Smiles": "Clc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)Cl)cc(c5ccc4)c1nnc(nn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)Cl)cc(c5ccc4)c1nnc(nn1)Cl",
      "HOMO": "-5.97",
      "LUMO": "-3.46",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.95",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.67",
      "DipoleMomentS1": "0.67",
      "DipoleMomentT1": "1.24",
      "ionizationPotential": "-0.01",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LAMVYJRTIVXOFS-DQRAZIAOSA-N",
      "Smiles": "COc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)OC",
      "HOMO": "-5.49",
      "LUMO": "-2.85",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "0.13",
      "Eox": "0.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.28",
      "ET1red": "",
      "DipoleMomentS0": "2.17",
      "DipoleMomentS1": "2.17",
      "DipoleMomentT1": "3.11",
      "ionizationPotential": "6.05",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "LBAYJWTUTPFZBE-FXOCUQNHSA-N",
      "Smiles": "FC(O/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/OC(F)(F)F)(F)F",
      "HOMO": "-7.88",
      "LUMO": "-3.52",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "3.05",
      "E00T1": "2.45",
      "Eox": "2.91",
      "Ered": "",
      "ES1ox": "-0.14",
      "ES1red": "",
      "ET1ox": "0.46",
      "ET1red": "",
      "DipoleMomentS0": "0.71",
      "DipoleMomentS1": "0.71",
      "DipoleMomentT1": "3.25",
      "ionizationPotential": "9.06",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LBOQKUCTWQDYDC-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)OC",
      "HOMO": "-6.6",
      "LUMO": "-3.11",
      "VEE": "2.51",
      "Lmax": "493.94422310757",
      "E00S1": "2.49",
      "E00T1": "",
      "Eox": "1.71",
      "Ered": "",
      "ES1ox": "-0.78",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.35",
      "DipoleMomentS1": "7.35",
      "DipoleMomentT1": "18.47",
      "ionizationPotential": "7.36",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LBVNNSILRXREOB-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)S)cc(c5ccc4)c1ccc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)S)cc(c5ccc4)c1ccc(cn1)S",
      "HOMO": "-5.41",
      "LUMO": "-2.57",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "1.99",
      "E00T1": "0.23",
      "Eox": "0.57",
      "Ered": "",
      "ES1ox": "-1.42",
      "ES1red": "",
      "ET1ox": "0.34",
      "ET1red": "",
      "DipoleMomentS0": "4.26",
      "DipoleMomentS1": "4.26",
      "DipoleMomentT1": "4.11",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LCACDFZXNYCPLI-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(s1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(s1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-7.47",
      "LUMO": "-2.87",
      "VEE": "3.79",
      "Lmax": "327.12401055409",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.56",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.82",
      "DipoleMomentS1": "1.82",
      "DipoleMomentT1": "7.06",
      "ionizationPotential": "9.06",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "LCCDDFQMUIASDI-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(o1)S)C#N)c1ncc(o1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(o1)S)C#N)c1ncc(o1)S",
      "HOMO": "-6.36",
      "LUMO": "-3.09",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "",
      "E00T1": "1.88",
      "Eox": "-0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.79",
      "ET1red": "",
      "DipoleMomentS0": "2.24",
      "DipoleMomentS1": "2.24",
      "DipoleMomentT1": "9.17",
      "ionizationPotential": "7.8",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "LCKYCPGALWQSEI-LIVBUOEUSA-N",
      "Smiles": "F/C=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=C/F)ccc2-c([n+]31)c4)Cl)/C=C\\F",
      "MolView_url": "http://molview.org/?q=F/C=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=C/F)ccc2-c([n+]31)c4)Cl)/C=C\\F",
      "HOMO": "-5.56",
      "LUMO": "-2.44",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "2.27",
      "E00T1": "",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.1",
      "DipoleMomentS1": "6.1",
      "DipoleMomentT1": "6.2",
      "ionizationPotential": "6.43",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "LDKLMPYCERJJNT-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)[N+](=O)[O-])c1nnc(nn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)[N+](=O)[O-])c1nnc(nn1)[N+](=O)[O-]",
      "HOMO": "-7.86",
      "LUMO": "-4",
      "VEE": "1.57",
      "Lmax": "789.68152866242",
      "E00S1": "1.54",
      "E00T1": "",
      "Eox": "2.88",
      "Ered": "",
      "ES1ox": "1.34",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.36",
      "DipoleMomentS1": "7.36",
      "DipoleMomentT1": "7.49",
      "ionizationPotential": "9.05",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LDYHEVMBJKLSPZ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1occ(n1)C)C#N)c1occ(n1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1occ(n1)C)C#N)c1occ(n1)C",
      "HOMO": "-6.73",
      "LUMO": "-3.05",
      "VEE": "3.25",
      "Lmax": "381.476923076923",
      "E00S1": "",
      "E00T1": "2.47",
      "Eox": "-0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.39",
      "ET1red": "",
      "DipoleMomentS0": "4",
      "DipoleMomentS1": "4",
      "DipoleMomentT1": "5.91",
      "ionizationPotential": "8.09",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "LEHCFWCIPPVHMR-VHXPQNKSSA-N",
      "Smiles": "Fc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)F",
      "HOMO": "-5.92",
      "LUMO": "-2.49",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "0.48",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.52",
      "ET1red": "",
      "DipoleMomentS0": "2.82",
      "DipoleMomentS1": "2.82",
      "DipoleMomentT1": "1.74",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "LEHCFWCIPPVHMR-WCWDXBQESA-N",
      "Smiles": "Fc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)F",
      "HOMO": "-5.96",
      "LUMO": "-2.73",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.38",
      "DipoleMomentS1": "4.38",
      "DipoleMomentT1": "5.49",
      "ionizationPotential": "6.82",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "LGDXKEZRUUNCLT-ZEELXFFVSA-N",
      "Smiles": "O/N=C/c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/O",
      "MolView_url": "http://molview.org/?q=O/N=C/c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/O",
      "HOMO": "-7.17",
      "LUMO": "-3.26",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "3.05",
      "E00T1": "2.39",
      "Eox": "2.3",
      "Ered": "",
      "ES1ox": "-0.75",
      "ES1red": "",
      "ET1ox": "-0.09",
      "ET1red": "",
      "DipoleMomentS0": "0.37",
      "DipoleMomentS1": "0.37",
      "DipoleMomentT1": "2.7",
      "ionizationPotential": "8.21",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LGEBIZCWKDXNAH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C)C)C#N)c1ccc(n1C)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C)C)C#N)c1ccc(n1C)C",
      "HOMO": "-6.13",
      "LUMO": "-2.46",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.46",
      "DipoleMomentS1": "3.46",
      "DipoleMomentT1": "14.62",
      "ionizationPotential": "7.13",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "LGKYRROZSBDGQK-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccccc1)C#N)c1ccccc1",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccccc1)C#N)c1ccccc1",
      "HOMO": "-6.95",
      "LUMO": "-2.79",
      "VEE": "4.03",
      "Lmax": "307.642679900744",
      "E00S1": "",
      "E00T1": "2.55",
      "Eox": "-0.79",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.34",
      "ET1red": "",
      "DipoleMomentS0": "0.79",
      "DipoleMomentS1": "0.79",
      "DipoleMomentT1": "2.58",
      "ionizationPotential": "8.5",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "LGLUSYSGRUGXPD-UHFFFAOYSA-N",
      "Smiles": "S/C=C\\c1cc2c3cc(/C=C\\S)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/S)cc(c5ccc4)/C=C\\S",
      "MolView_url": "http://molview.org/?q=S/C=C\\c1cc2c3cc(/C=C\\S)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/S)cc(c5ccc4)/C=C\\S",
      "HOMO": "-5.31",
      "LUMO": "-2.54",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "1.9",
      "E00T1": "1.13",
      "Eox": "0.48",
      "Ered": "",
      "ES1ox": "-1.42",
      "ES1red": "",
      "ET1ox": "-0.65",
      "ET1red": "",
      "DipoleMomentS0": "2.35",
      "DipoleMomentS1": "2.35",
      "DipoleMomentT1": "2.24",
      "ionizationPotential": "0.09",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LGNYRUIHYGIHHX-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)C)c1ccc(o1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)C)c1ccc(o1)C",
      "HOMO": "-6.16",
      "LUMO": "-3.09",
      "VEE": "1.99",
      "Lmax": "623.015075376884",
      "E00S1": "2.33",
      "E00T1": "",
      "Eox": "1.49",
      "Ered": "",
      "ES1ox": "-0.84",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.52",
      "DipoleMomentS1": "2.52",
      "DipoleMomentT1": "7.19",
      "ionizationPotential": "7.05",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LHLDXVGFRLTTFU-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(o1)O)C#N)c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(o1)O)C#N)c1ccc(o1)O",
      "HOMO": "-5.98",
      "LUMO": "-2.68",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.34",
      "DipoleMomentS1": "3.34",
      "DipoleMomentT1": "7.48",
      "ionizationPotential": "7.31",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "LIERIBVTPNMSIM-FMQUCBEESA-N",
      "Smiles": "Sc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)S",
      "HOMO": "-5.41",
      "LUMO": "-2.74",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "1.99",
      "E00T1": "0.76",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.3",
      "DipoleMomentS1": "3.3",
      "DipoleMomentT1": "3.59",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "LIMLKRDWJARBMT-FMQUCBEESA-N",
      "Smiles": "[O-][N+](=O)c1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)[N+](=O)[O-]",
      "HOMO": "-5.87",
      "LUMO": "-3.11",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "",
      "E00T1": "0.79",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "2.76",
      "DipoleMomentS1": "2.76",
      "DipoleMomentT1": "0.07",
      "ionizationPotential": "6.9",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "LIMLKRDWJARBMT-VXPUYCOJSA-N",
      "Smiles": "[O-][N+](=O)c1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)[N+](=O)[O-]",
      "HOMO": "-5.91",
      "LUMO": "-3.07",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "0.19",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.65",
      "ET1red": "",
      "DipoleMomentS0": "12.21",
      "DipoleMomentS1": "12.21",
      "DipoleMomentT1": "7.98",
      "ionizationPotential": "6.9",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "LJDXSTNIBCAULL-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)F)c1ccc(n1C)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)F)c1ccc(n1C)F",
      "HOMO": "-6.08",
      "LUMO": "-3.07",
      "VEE": "2.12",
      "Lmax": "584.811320754717",
      "E00S1": "2.28",
      "E00T1": "1.81",
      "Eox": "1.22",
      "Ered": "",
      "ES1ox": "-1.06",
      "ES1red": "",
      "ET1ox": "-0.59",
      "ET1red": "",
      "DipoleMomentS0": "1.68",
      "DipoleMomentS1": "1.68",
      "DipoleMomentT1": "6.88",
      "ionizationPotential": "7.03",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LJHIBWKDCMRMBO-VEXXTHBSSA-N",
      "Smiles": "Cl/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N/Cl",
      "MolView_url": "http://molview.org/?q=Cl/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N/Cl",
      "HOMO": "-6.94",
      "LUMO": "-3.17",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.81",
      "ET1red": "",
      "DipoleMomentS0": "5.18",
      "DipoleMomentS1": "5.18",
      "DipoleMomentT1": "4.81",
      "ionizationPotential": "7.83",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "LJKAVFXEKIPINN-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)[N+](=O)[O-])c1ccc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)[N+](=O)[O-])c1ccc([nH]1)[N+](=O)[O-]",
      "HOMO": "-6.9",
      "LUMO": "-3.43",
      "VEE": "2.3",
      "Lmax": "539.04347826087",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.04",
      "DipoleMomentS1": "7.04",
      "DipoleMomentT1": "4.93",
      "ionizationPotential": "8.04",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LKFUOFQKJFSURI-UHFFFAOYSA-N",
      "Smiles": "Oc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)O",
      "HOMO": "-5.26",
      "LUMO": "-2.43",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.97",
      "E00T1": "1.18",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "-1.48",
      "ES1red": "",
      "ET1ox": "-0.69",
      "ET1red": "",
      "DipoleMomentS0": "5.16",
      "DipoleMomentS1": "5.16",
      "DipoleMomentT1": "4.3",
      "ionizationPotential": "6",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "LKZLJDDIGGYRGC-LBPZLPHVSA-N",
      "Smiles": "O/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/O",
      "MolView_url": "http://molview.org/?q=O/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/O",
      "HOMO": "-5.65",
      "LUMO": "-2.81",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "7.61",
      "DipoleMomentS1": "7.61",
      "DipoleMomentT1": "6.48",
      "ionizationPotential": "6.45",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "LLSGTKNVFKTJJX-FOCLMDBBSA-N",
      "Smiles": "CC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CC",
      "MolView_url": "http://molview.org/?q=CC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CC",
      "HOMO": "-5.98",
      "LUMO": "-2.99",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "0.83",
      "Eox": "1.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.25",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "LLSGTKNVFKTJJX-NXVVXOECSA-N",
      "Smiles": "CC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CC",
      "MolView_url": "http://molview.org/?q=CC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CC",
      "HOMO": "-5.97",
      "LUMO": "-2.97",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.64",
      "ET1red": "",
      "DipoleMomentS0": "6.85",
      "DipoleMomentS1": "6.85",
      "DipoleMomentT1": "5.88",
      "ionizationPotential": "6.61",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "LMGDRJOINLBYCG-UHFFFAOYSA-N",
      "Smiles": "SC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)S)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)S)cc(c5ccc4)C1=CC=C(C1)S",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)S)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)S)cc(c5ccc4)C1=CC=C(C1)S",
      "HOMO": "-5.1",
      "LUMO": "-2.41",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "1.78",
      "E00T1": "1.08",
      "Eox": "-4.24",
      "Ered": "",
      "ES1ox": "-6.02",
      "ES1red": "",
      "ET1ox": "-5.32",
      "ET1red": "",
      "DipoleMomentS0": "2.69",
      "DipoleMomentS1": "2.69",
      "DipoleMomentT1": "3",
      "ionizationPotential": "0.12",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LNABAYXQLRGOHY-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(o1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(o1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.61",
      "LUMO": "-3.57",
      "VEE": "3.87",
      "Lmax": "320.361757105943",
      "E00S1": "3.8",
      "E00T1": "2.45",
      "Eox": "3.51",
      "Ered": "",
      "ES1ox": "-0.29",
      "ES1red": "",
      "ET1ox": "1.06",
      "ET1red": "",
      "DipoleMomentS0": "4.57",
      "DipoleMomentS1": "4.57",
      "DipoleMomentT1": "7.62",
      "ionizationPotential": "10.25",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "LNIQMXOMSHCWDZ-UHFFFAOYSA-N",
      "Smiles": "Cc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)C)cc(c5ccc4)c1[nH]cc(c1)C",
      "MolView_url": "http://molview.org/?q=Cc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)C)cc(c5ccc4)c1[nH]cc(c1)C",
      "HOMO": "-5.06",
      "LUMO": "-2.12",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "1.88",
      "E00T1": "1.12",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.13",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "2.57",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LNXJJQIACHHUTR-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cnc([nH]1)O)C#N)c1cnc([nH]1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cnc([nH]1)O)C#N)c1cnc([nH]1)O",
      "HOMO": "-6.17",
      "LUMO": "-2.8",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "1.94",
      "Eox": "-1.12",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.06",
      "ET1red": "",
      "DipoleMomentS0": "2.15",
      "DipoleMomentS1": "2.15",
      "DipoleMomentT1": "6.07",
      "ionizationPotential": "7.58",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "LNZNQCYWFFPEFA-UHFFFAOYSA-N",
      "Smiles": "Oc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=Oc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)O",
      "HOMO": "-5.64",
      "LUMO": "-3.18",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "1.45",
      "E00T1": "1.39",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-0.62",
      "ES1red": "",
      "ET1ox": "-0.56",
      "ET1red": "",
      "DipoleMomentS0": "4.08",
      "DipoleMomentS1": "4.08",
      "DipoleMomentT1": "3.83",
      "ionizationPotential": "6.52",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "LOROPOOJTNUHGV-UHFFFAOYSA-N",
      "Smiles": "FC(C1=CC=C(C1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(C1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-7.04",
      "LUMO": "-2.74",
      "VEE": "3.58",
      "Lmax": "346.312849162011",
      "E00S1": "2.81",
      "E00T1": "2.09",
      "Eox": "2.15",
      "Ered": "",
      "ES1ox": "-0.66",
      "ES1red": "",
      "ET1ox": "0.06",
      "ET1red": "",
      "DipoleMomentS0": "1.23",
      "DipoleMomentS1": "1.23",
      "DipoleMomentT1": "1.55",
      "ionizationPotential": "8.7",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "LOTWJJIVZSYLMF-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C=O)OC(F)(F)F)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C=O)OC(F)(F)F)OC(F)(F)F",
      "HOMO": "-5.52",
      "LUMO": "-2.53",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "1.24",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "4.31",
      "DipoleMomentS1": "4.31",
      "DipoleMomentT1": "3.94",
      "ionizationPotential": "6.4",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "LOUVUCYKERATQU-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)[N+](=O)[O-]",
      "HOMO": "-6.61",
      "LUMO": "-3.84",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.15",
      "DipoleMomentS1": "6.15",
      "DipoleMomentT1": "6.48",
      "ionizationPotential": "7.7",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "LPQREVIDFFXXMT-UHFFFAOYSA-N",
      "Smiles": "FC(O/N=C\\c1cc2c3cc(/C=N\\OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/OC(F)(F)F)cc(c5ccc4)/C=N/OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C\\c1cc2c3cc(/C=N\\OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/OC(F)(F)F)cc(c5ccc4)/C=N/OC(F)(F)F)(F)F",
      "HOMO": "-5.97",
      "LUMO": "-3.24",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "1.91",
      "E00T1": "0.25",
      "Eox": "1.07",
      "Ered": "",
      "ES1ox": "-0.84",
      "ES1red": "",
      "ET1ox": "0.82",
      "ET1red": "",
      "DipoleMomentS0": "0.45",
      "DipoleMomentS1": "0.45",
      "DipoleMomentT1": "0.15",
      "ionizationPotential": "7.07",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LPVGTYMDLCCNLF-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)Cl)C1=CC=C(C1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)Cl)C1=CC=C(C1)Cl",
      "HOMO": "-6.13",
      "LUMO": "-3.08",
      "VEE": "1.89",
      "Lmax": "655.978835978836",
      "E00S1": "2.33",
      "E00T1": "1.8",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "-0.9",
      "ES1red": "",
      "ET1ox": "-0.37",
      "ET1red": "",
      "DipoleMomentS0": "1.07",
      "DipoleMomentS1": "1.07",
      "DipoleMomentT1": "5.3",
      "ionizationPotential": "7.12",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LQHVHEQDIYMJOC-UHFFFAOYSA-N",
      "Smiles": "FC(OC1=CC=C(C1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(C1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(C1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(C1)OC(F)(F)F)(F)F",
      "HOMO": "-6.17",
      "LUMO": "-3.07",
      "VEE": "1.89",
      "Lmax": "655.978835978836",
      "E00S1": "2.37",
      "E00T1": "1.56",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "-1.08",
      "ES1red": "",
      "ET1ox": "-0.27",
      "ET1red": "",
      "DipoleMomentS0": "1.25",
      "DipoleMomentS1": "1.25",
      "DipoleMomentT1": "12.27",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LQTMNSVHKIWBKI-FMQUCBEESA-N",
      "Smiles": "FC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CF",
      "MolView_url": "http://molview.org/?q=FC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CF",
      "HOMO": "-6.61",
      "LUMO": "-2.82",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.55",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "LQZVRCPPXOYJGM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)C#N",
      "HOMO": "-5.66",
      "LUMO": "-2.85",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "11.94",
      "DipoleMomentS1": "11.94",
      "DipoleMomentT1": "11.71",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "LRCYLGIWVXGASU-LAOZNNPOSA-N",
      "Smiles": "S/C=C/c1ccc(o1)/N=N/c1ccc(o1)/C=C/S",
      "MolView_url": "http://molview.org/?q=S/C=C/c1ccc(o1)/N=N/c1ccc(o1)/C=C/S",
      "HOMO": "-5.45",
      "LUMO": "-2.86",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "1.9",
      "E00T1": "",
      "Eox": "0.56",
      "Ered": "",
      "ES1ox": "-1.34",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.16",
      "DipoleMomentS1": "3.16",
      "DipoleMomentT1": "2.01",
      "ionizationPotential": "6.31",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "LRMXRIUTNICYLD-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)[N+](=O)[O-])c1scc(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)[N+](=O)[O-])c1scc(n1)[N+](=O)[O-]",
      "HOMO": "-7.39",
      "LUMO": "-3.44",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "3.08",
      "E00T1": "",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.86",
      "DipoleMomentS1": "7.86",
      "DipoleMomentT1": "6.52",
      "ionizationPotential": "8.59",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LRXSTEJXXVQXGW-FMQUCBEESA-N",
      "Smiles": "Cc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)C",
      "MolView_url": "http://molview.org/?q=Cc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)C",
      "HOMO": "-6.56",
      "LUMO": "-3.52",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.9",
      "Eox": "1.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.74",
      "ET1red": "",
      "DipoleMomentS0": "0.25",
      "DipoleMomentS1": "0.25",
      "DipoleMomentT1": "0.26",
      "ionizationPotential": "7.37",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "LRXSTEJXXVQXGW-VXPUYCOJSA-N",
      "Smiles": "Cc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)C",
      "MolView_url": "http://molview.org/?q=Cc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)C",
      "HOMO": "-6.46",
      "LUMO": "-3.37",
      "VEE": "2.28",
      "Lmax": "543.771929824561",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.05",
      "DipoleMomentS1": "5.05",
      "DipoleMomentT1": "4.03",
      "ionizationPotential": "7.22",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "LRZILZJRJGAUPB-BYYHNAKLSA-N",
      "Smiles": "FC(Oc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)OC(F)(F)F)(F)F",
      "HOMO": "-5.37",
      "LUMO": "-2.69",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "2.01",
      "E00T1": "0.77",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "LSHSUWHQNOAEOV-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)S",
      "HOMO": "-5.53",
      "LUMO": "-2.78",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "",
      "E00T1": "1.14",
      "Eox": "0.61",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "4.09",
      "DipoleMomentS1": "4.09",
      "DipoleMomentT1": "4.15",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "LTCXPFKODGCDGQ-QPLCGJKRSA-N",
      "Smiles": "N#Cc1cnc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ncc(s1)C#N",
      "HOMO": "-6.59",
      "LUMO": "-2.96",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "0.53",
      "Eox": "1.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.05",
      "ET1red": "",
      "DipoleMomentS0": "5.21",
      "DipoleMomentS1": "5.21",
      "DipoleMomentT1": "3.64",
      "ionizationPotential": "7.54",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "LTGBCBOVAXQHGE-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)Cl)C#N)c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)Cl)C#N)c1ccc(cn1)Cl",
      "HOMO": "-7.04",
      "LUMO": "-3.07",
      "VEE": "3.79",
      "Lmax": "327.12401055409",
      "E00S1": "",
      "E00T1": "2.72",
      "Eox": "-0.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.57",
      "ET1red": "",
      "DipoleMomentS0": "5.86",
      "DipoleMomentS1": "5.86",
      "DipoleMomentT1": "7.38",
      "ionizationPotential": "8.44",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "LUJYELRRACAMSH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(s1)[N+](=O)[O-])C#N)c1ncc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(s1)[N+](=O)[O-])C#N)c1ncc(s1)[N+](=O)[O-]",
      "HOMO": "-7.5",
      "LUMO": "-3.93",
      "VEE": "3.82",
      "Lmax": "324.554973821989",
      "E00S1": "",
      "E00T1": "2.4",
      "Eox": "-0.05",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.45",
      "ET1red": "",
      "DipoleMomentS0": "5.35",
      "DipoleMomentS1": "5.35",
      "DipoleMomentT1": "7.62",
      "ionizationPotential": "9.17",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "LULOYZUKPFDTHJ-ZZDPWUPLSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)/C=N/[N+](=O)[O-])/C=N\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)/C=N/[N+](=O)[O-])/C=N\\[N+](=O)[O-]",
      "HOMO": "-8.19",
      "LUMO": "-3.74",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "3.04",
      "E00T1": "2.72",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "12.79",
      "DipoleMomentS1": "12.79",
      "DipoleMomentT1": "12.09",
      "ionizationPotential": "9.46",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LUYGXORZJGFQST-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(C1)F)C#N)C1=CC=C(C1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(C1)F)C#N)C1=CC=C(C1)F",
      "HOMO": "-6.09",
      "LUMO": "-2.78",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "",
      "E00T1": "2.04",
      "Eox": "-1.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.12",
      "ET1red": "",
      "DipoleMomentS0": "2",
      "DipoleMomentS1": "2",
      "DipoleMomentT1": "3.65",
      "ionizationPotential": "7.57",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "LVNCAERRYABXGT-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)C#N)cc(c5ccc4)c1ccc(cc1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)C#N)cc(c5ccc4)c1ccc(cc1)C#N",
      "HOMO": "-5.58",
      "LUMO": "-2.68",
      "VEE": "3.04",
      "Lmax": "407.828947368421",
      "E00S1": "2.04",
      "E00T1": "",
      "Eox": "1.82",
      "Ered": "",
      "ES1ox": "-0.22",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.75",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LWCHTJNOKPDXMQ-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(O)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C=O)O)c1ccc(n1C=O)O",
      "MolView_url": "http://molview.org/?q=O=Cn1c(O)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C=O)O)c1ccc(n1C=O)O",
      "HOMO": "-5.52",
      "LUMO": "-2.44",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "1.59",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.9",
      "ET1red": "",
      "DipoleMomentS0": "4.96",
      "DipoleMomentS1": "4.96",
      "DipoleMomentT1": "5.79",
      "ionizationPotential": "6.33",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "LWDNLAHSCSZLCY-DQRAZIAOSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)[N+](=O)[O-]",
      "HOMO": "-6.19",
      "LUMO": "-3.42",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "0.19",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.94",
      "ET1red": "",
      "DipoleMomentS0": "0.2",
      "DipoleMomentS1": "0.2",
      "DipoleMomentT1": "0.18",
      "ionizationPotential": "7.17",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "LWDNLAHSCSZLCY-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)[N+](=O)[O-]",
      "HOMO": "-6.24",
      "LUMO": "-3.46",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "0.83",
      "Eox": "1.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.37",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "7.26",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "LWLTZXORGGBWQG-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ncc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cnc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ncc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cnc([nH]1)C#N",
      "HOMO": "-6.93",
      "LUMO": "-3.31",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "2.56",
      "E00T1": "",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.3",
      "DipoleMomentS1": "7.3",
      "DipoleMomentT1": "3.33",
      "ionizationPotential": "8.17",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LXCBRKYRLYBDBC-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)[N+](=O)[O-]",
      "HOMO": "-6.58",
      "LUMO": "-3.84",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "0.75",
      "Eox": "1.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.95",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "4.22",
      "ionizationPotential": "7.59",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "LXHXWLVOOIBPLU-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(cc1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1ccc(cc1)[C@]1(C)N=N1",
      "HOMO": "-6.69",
      "LUMO": "-2.03",
      "VEE": "3.36",
      "Lmax": "368.988095238095",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.13",
      "DipoleMomentS1": "3.13",
      "DipoleMomentT1": "7.68",
      "ionizationPotential": "7.97",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "LXMPZJIPAPJLED-UHFFFAOYSA-N",
      "Smiles": "N#C/C=C\\[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\[C@]1(C#N)N=N1",
      "HOMO": "-8.07",
      "LUMO": "-3.13",
      "VEE": "3.45",
      "Lmax": "359.36231884058",
      "E00S1": "3.26",
      "E00T1": "2.49",
      "Eox": "3.17",
      "Ered": "",
      "ES1ox": "-0.09",
      "ES1red": "",
      "ET1ox": "0.68",
      "ET1red": "",
      "DipoleMomentS0": "3.69",
      "DipoleMomentS1": "3.69",
      "DipoleMomentT1": "1.51",
      "ionizationPotential": "9.97",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "LXUTTXHFJXDTFF-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)O)cc(c5ccc4)c1ccc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)O)cc(c5ccc4)c1ccc(cn1)O",
      "HOMO": "-5.37",
      "LUMO": "-2.49",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.59",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.65",
      "DipoleMomentS1": "3.65",
      "DipoleMomentT1": "3.63",
      "ionizationPotential": "5.79",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LXVWZPZIYGADII-WCWDXBQESA-N",
      "Smiles": "Clc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)Cl",
      "HOMO": "-6.48",
      "LUMO": "-3.06",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.57",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.02",
      "DipoleMomentS1": "0.02",
      "DipoleMomentT1": "4.65",
      "ionizationPotential": "7.34",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "LYEJJKJLIZYIKJ-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)[N+](=O)[O-])c1ccc(cc1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)[N+](=O)[O-])c1ccc(cc1)[N+](=O)[O-]",
      "HOMO": "-7.39",
      "LUMO": "-3.36",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "2.88",
      "E00T1": "2.51",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.51",
      "DipoleMomentS1": "6.51",
      "DipoleMomentT1": "4.41",
      "ionizationPotential": "8.56",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "LYSQFTJYHHSMNU-FMQUCBEESA-N",
      "Smiles": "Cn1cccc1c1ccc(s1)/N=N/c1ccc(s1)c1cccn1C",
      "MolView_url": "http://molview.org/?q=Cn1cccc1c1ccc(s1)/N=N/c1ccc(s1)c1cccn1C",
      "HOMO": "-5.45",
      "LUMO": "-2.81",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.11",
      "DipoleMomentS1": "2.11",
      "DipoleMomentT1": "0.53",
      "ionizationPotential": "6.06",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "LYSQFTJYHHSMNU-VXPUYCOJSA-N",
      "Smiles": "Cn1cccc1c1ccc(s1)/N=N\\c1ccc(s1)c1cccn1C",
      "MolView_url": "http://molview.org/?q=Cn1cccc1c1ccc(s1)/N=N\\c1ccc(s1)c1cccn1C",
      "HOMO": "-5.62",
      "LUMO": "-2.67",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "0.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.3",
      "ET1red": "",
      "DipoleMomentS0": "10.89",
      "DipoleMomentS1": "10.89",
      "DipoleMomentT1": "4.09",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "LYZUWPBJNDWQPW-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc([nH]1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc([nH]1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-7.05",
      "LUMO": "-2.74",
      "VEE": "3.62",
      "Lmax": "342.486187845304",
      "E00S1": "2.83",
      "E00T1": "2.24",
      "Eox": "2.15",
      "Ered": "",
      "ES1ox": "-0.68",
      "ES1red": "",
      "ET1ox": "-0.09",
      "ET1red": "",
      "DipoleMomentS0": "1.09",
      "DipoleMomentS1": "1.09",
      "DipoleMomentT1": "7.36",
      "ionizationPotential": "8.67",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "LZCQUYIDGVQHLI-UHFFFAOYSA-N",
      "Smiles": "Fc1csc(n1)c1cc2c3cc(c4scc(n4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)F)cc(c5ccc4)c1scc(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1csc(n1)c1cc2c3cc(c4scc(n4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)F)cc(c5ccc4)c1scc(n1)F",
      "HOMO": "-5.7",
      "LUMO": "-2.97",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.87",
      "E00T1": "1.11",
      "Eox": "0.87",
      "Ered": "",
      "ES1ox": "-1",
      "ES1red": "",
      "ET1ox": "-0.24",
      "ET1red": "",
      "DipoleMomentS0": "10.76",
      "DipoleMomentS1": "10.76",
      "DipoleMomentT1": "9.36",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LZEVDWXRYWHNLQ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(s1)C#N)c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(s1)C#N)c1ncc(s1)C#N",
      "HOMO": "-5.81",
      "LUMO": "-2.87",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.14",
      "E00T1": "1.57",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.64",
      "DipoleMomentS1": "1.64",
      "DipoleMomentT1": "5.07",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "LZNQPVQUYBQTGK-UHFFFAOYSA-N",
      "Smiles": "c1csc(n1)c1cc2c3cc(c4nccs4)c4c5c3c(c3c2c2c1cccc2c(c3)c1nccs1)cc(c5ccc4)c1nccs1",
      "MolView_url": "http://molview.org/?q=c1csc(n1)c1cc2c3cc(c4nccs4)c4c5c3c(c3c2c2c1cccc2c(c3)c1nccs1)cc(c5ccc4)c1nccs1",
      "HOMO": "-5.59",
      "LUMO": "-2.81",
      "VEE": "3.02",
      "Lmax": "410.529801324503",
      "E00S1": "2.09",
      "E00T1": "",
      "Eox": "0.79",
      "Ered": "",
      "ES1ox": "-1.3",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.22",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "LZPRUGBFNLSXDE-DQRAZIAOSA-N",
      "Smiles": "Cc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)C",
      "HOMO": "-5.46",
      "LUMO": "-2.58",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "0.14",
      "Eox": "0.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.13",
      "ET1red": "",
      "DipoleMomentS0": "12.63",
      "DipoleMomentS1": "12.63",
      "DipoleMomentT1": "3.56",
      "ionizationPotential": "5.87",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "LZPRUGBFNLSXDE-QURGRASLSA-N",
      "Smiles": "Cc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)C",
      "HOMO": "-5.29",
      "LUMO": "-2.76",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "0.72",
      "Eox": "0.33",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.39",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "5.85",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "LZUPKVCTPBDBIQ-KQQUZDAGSA-N",
      "Smiles": "O=C1c2cc(/C=C/[N+](=O)[O-])ccc2C(=O)c2c1cc(/C=C/[N+](=O)[O-])cc2",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(/C=C/[N+](=O)[O-])ccc2C(=O)c2c1cc(/C=C/[N+](=O)[O-])cc2",
      "HOMO": "-7.69",
      "LUMO": "-3.57",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "3.06",
      "E00T1": "",
      "Eox": "2.74",
      "Ered": "",
      "ES1ox": "-0.32",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.53",
      "DipoleMomentS1": "4.53",
      "DipoleMomentT1": "",
      "ionizationPotential": "8.84",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "MAAZYGIFEFYIMR-BUHFOSPRSA-N",
      "Smiles": "C#Cc1ccc(s1)/N=N/c1ccc(s1)C#C",
      "MolView_url": "http://molview.org/?q=C#Cc1ccc(s1)/N=N/c1ccc(s1)C#C",
      "HOMO": "-6.26",
      "LUMO": "-3.18",
      "VEE": "3.08",
      "Lmax": "402.532467532468",
      "E00S1": "",
      "E00T1": "0.87",
      "Eox": "1.26",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.14",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MAAZYGIFEFYIMR-YPKPFQOOSA-N",
      "Smiles": "C#Cc1ccc(s1)/N=N\\c1ccc(s1)C#C",
      "MolView_url": "http://molview.org/?q=C#Cc1ccc(s1)/N=N\\c1ccc(s1)C#C",
      "HOMO": "-6.19",
      "LUMO": "-3.08",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.2",
      "DipoleMomentS1": "5.2",
      "DipoleMomentT1": "4.46",
      "ionizationPotential": "7.03",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MADZQJGHYNYNKP-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(cn1)OC(F)(F)F)C#N)c1ncc(cn1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(cn1)OC(F)(F)F)C#N)c1ncc(cn1)OC(F)(F)F",
      "HOMO": "-7.87",
      "LUMO": "-2.84",
      "VEE": "3.98",
      "Lmax": "311.507537688442",
      "E00S1": "",
      "E00T1": "2.54",
      "Eox": "2.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.22",
      "ET1red": "",
      "DipoleMomentS0": "1.74",
      "DipoleMomentS1": "1.74",
      "DipoleMomentT1": "4.58",
      "ionizationPotential": "8.82",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MAFPWILHIKMBTE-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)O)c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)O)c1ccc(o1)O",
      "HOMO": "-5.95",
      "LUMO": "-3.04",
      "VEE": "1.84",
      "Lmax": "673.804347826087",
      "E00S1": "2.21",
      "E00T1": "1.73",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "-1.06",
      "ES1red": "",
      "ET1ox": "-0.58",
      "ET1red": "",
      "DipoleMomentS0": "1.61",
      "DipoleMomentS1": "1.61",
      "DipoleMomentT1": "6.11",
      "ionizationPotential": "6.94",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "MAILQVXLIVQIPS-DQRAZIAOSA-N",
      "Smiles": "N#Cc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)C#N",
      "HOMO": "-6.36",
      "LUMO": "-3.32",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.71",
      "DipoleMomentS1": "6.71",
      "DipoleMomentT1": "8.13",
      "ionizationPotential": "7.39",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MAILQVXLIVQIPS-QURGRASLSA-N",
      "Smiles": "N#Cc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)C#N",
      "HOMO": "-6.42",
      "LUMO": "-3.44",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "",
      "E00T1": "0.88",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.58",
      "ET1red": "",
      "DipoleMomentS0": "9.99",
      "DipoleMomentS1": "9.99",
      "DipoleMomentT1": "9.95",
      "ionizationPotential": "7.48",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MBCJMIRNVBKIIS-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(cn1)F)C#N)c1ncc(cn1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(cn1)F)C#N)c1ncc(cn1)F",
      "HOMO": "-6.9",
      "LUMO": "-3.02",
      "VEE": "4.01",
      "Lmax": "309.177057356608",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.58",
      "DipoleMomentS1": "1.58",
      "DipoleMomentT1": "3.7",
      "ionizationPotential": "8.89",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MCAOKTDXXHIKQR-DQRAZIAOSA-N",
      "Smiles": "FC(Oc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)OC(F)(F)F)(F)F",
      "HOMO": "-6.57",
      "LUMO": "-3.55",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.48",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.25",
      "ET1red": "",
      "DipoleMomentS0": "1.75",
      "DipoleMomentS1": "1.75",
      "DipoleMomentT1": "1.73",
      "ionizationPotential": "7.53",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MCAOKTDXXHIKQR-QURGRASLSA-N",
      "Smiles": "FC(Oc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)OC(F)(F)F)(F)F",
      "HOMO": "-6.68",
      "LUMO": "-3.66",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.68",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MCQITXOORZLVLL-BYYHNAKLSA-N",
      "Smiles": "FC(Oc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "HOMO": "-5.61",
      "LUMO": "-2.95",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.16",
      "DipoleMomentS1": "3.16",
      "DipoleMomentT1": "1.56",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MCQITXOORZLVLL-DQSJHHFOSA-N",
      "Smiles": "FC(Oc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "HOMO": "-5.65",
      "LUMO": "-2.92",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "0.62",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.45",
      "ET1red": "",
      "DipoleMomentS0": "2.46",
      "DipoleMomentS1": "2.46",
      "DipoleMomentT1": "1.07",
      "ionizationPotential": "6.47",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MDOIRCFSRFOCNR-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(C1)C(F)(F)F)C#N)C1=CC=C(C1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(C1)C(F)(F)F)C#N)C1=CC=C(C1)C(F)(F)F",
      "HOMO": "-6.84",
      "LUMO": "-2.72",
      "VEE": "3.58",
      "Lmax": "346.312849162011",
      "E00S1": "",
      "E00T1": "1.75",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.23",
      "DipoleMomentS1": "4.23",
      "DipoleMomentT1": "4.75",
      "ionizationPotential": "8.11",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MDWBFZULKQGPHZ-DQRAZIAOSA-N",
      "Smiles": "Fc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)F",
      "HOMO": "-5.79",
      "LUMO": "-2.97",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.23",
      "DipoleMomentS1": "4.23",
      "DipoleMomentT1": "4.97",
      "ionizationPotential": "6.65",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MDZZMUVEIDFBID-DQRAZIAOSA-N",
      "Smiles": "COc1ccc(n1C=O)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C=O)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)OC",
      "HOMO": "-5.93",
      "LUMO": "-2.67",
      "VEE": "2.57",
      "Lmax": "482.412451361868",
      "E00S1": "",
      "E00T1": "0.13",
      "Eox": "-0.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.3",
      "ET1red": "",
      "DipoleMomentS0": "9.31",
      "DipoleMomentS1": "9.31",
      "DipoleMomentT1": "6.29",
      "ionizationPotential": "6.07",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MDZZMUVEIDFBID-QURGRASLSA-N",
      "Smiles": "COc1ccc(n1C=O)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C=O)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)OC",
      "HOMO": "-5.41",
      "LUMO": "-2.85",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "0.67",
      "Eox": "0.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.21",
      "ET1red": "",
      "DipoleMomentS0": "9.33",
      "DipoleMomentS1": "9.33",
      "DipoleMomentT1": "10.14",
      "ionizationPotential": "5.91",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MEUKTEORSBLMAA-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)[N+](=O)[O-]",
      "HOMO": "-5.58",
      "LUMO": "-2.98",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.79",
      "E00T1": "1.14",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "14.14",
      "DipoleMomentS1": "14.14",
      "DipoleMomentT1": "16.29",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "MFFGMCMMQWGAGK-VHXPQNKSSA-N",
      "Smiles": "COc1ccc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(s1)OC",
      "HOMO": "-5.71",
      "LUMO": "-2.44",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "0.47",
      "Eox": "0.79",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.32",
      "ET1red": "",
      "DipoleMomentS0": "10.81",
      "DipoleMomentS1": "10.81",
      "DipoleMomentT1": "8.99",
      "ionizationPotential": "6.29",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "MFJWGNUVTGVVFW-BUHFOSPRSA-N",
      "Smiles": "Sc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)S",
      "MolView_url": "http://molview.org/?q=Sc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)S",
      "HOMO": "-6.46",
      "LUMO": "-3.52",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "0.88",
      "Eox": "1.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.64",
      "ET1red": "",
      "DipoleMomentS0": "2.39",
      "DipoleMomentS1": "2.39",
      "DipoleMomentT1": "3.3",
      "ionizationPotential": "7.29",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MFJWGNUVTGVVFW-YPKPFQOOSA-N",
      "Smiles": "Sc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)S",
      "MolView_url": "http://molview.org/?q=Sc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)S",
      "HOMO": "-6.39",
      "LUMO": "-3.4",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.38",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.15",
      "ET1red": "",
      "DipoleMomentS0": "2.59",
      "DipoleMomentS1": "2.59",
      "DipoleMomentT1": "2.08",
      "ionizationPotential": "7.17",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MFOGWMLFJIHLRN-HSFFGMMNSA-N",
      "Smiles": "N#Cc1c(/C=C\\C(F)(F)F)cc(cc1/C=C/C(F)(F)F)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(/C=C\\C(F)(F)F)cc(cc1/C=C/C(F)(F)F)C#N",
      "HOMO": "-7.54",
      "LUMO": "-3.06",
      "VEE": "4.12",
      "Lmax": "300.922330097087",
      "E00S1": "",
      "E00T1": "2.95",
      "Eox": "-0.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.66",
      "ET1red": "",
      "DipoleMomentS0": "2.8",
      "DipoleMomentS1": "2.8",
      "DipoleMomentT1": "3.47",
      "ionizationPotential": "9.44",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MFSUNGROIVMMKV-UHFFFAOYSA-N",
      "Smiles": "FC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)F",
      "HOMO": "-5.19",
      "LUMO": "-2.41",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.88",
      "E00T1": "",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.49",
      "DipoleMomentS1": "1.49",
      "DipoleMomentT1": "0.74",
      "ionizationPotential": "5.97",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "MFTSOMBXBJLEOT-UHFFFAOYSA-N",
      "Smiles": "Cn1c(O)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)O)c1ccc(n1C)O",
      "MolView_url": "http://molview.org/?q=Cn1c(O)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)O)c1ccc(n1C)O",
      "HOMO": "-5.22",
      "LUMO": "-2.28",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "12.03",
      "DipoleMomentS1": "12.03",
      "DipoleMomentT1": "13.75",
      "ionizationPotential": "5.75",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "MGCNBKSGJJONKA-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(nc1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1ccc(nc1)[C@]1(F)N=N1",
      "HOMO": "-7.56",
      "LUMO": "-2.58",
      "VEE": "3.18",
      "Lmax": "389.874213836478",
      "E00S1": "3.16",
      "E00T1": "2.57",
      "Eox": "2.65",
      "Ered": "",
      "ES1ox": "-0.51",
      "ES1red": "",
      "ET1ox": "0.08",
      "ET1red": "",
      "DipoleMomentS0": "3.67",
      "DipoleMomentS1": "3.67",
      "DipoleMomentT1": "7.78",
      "ionizationPotential": "9.12",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MHJPUELYLZVABE-UHFFFAOYSA-N",
      "Smiles": "c1coc(n1)c1cc2c3cc(c4ncco4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncco1)cc(c5ccc4)c1ncco1",
      "MolView_url": "http://molview.org/?q=c1coc(n1)c1cc2c3cc(c4ncco4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncco1)cc(c5ccc4)c1ncco1",
      "HOMO": "-5.6",
      "LUMO": "-2.93",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "1.85",
      "E00T1": "1.08",
      "Eox": "-4.33",
      "Ered": "",
      "ES1ox": "-6.18",
      "ES1red": "",
      "ET1ox": "-5.41",
      "ET1red": "",
      "DipoleMomentS0": "3.19",
      "DipoleMomentS1": "3.19",
      "DipoleMomentT1": "3.41",
      "ionizationPotential": "0.04",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "MIAWOSVGSUGHIC-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)[N+](=O)[O-]",
      "HOMO": "-6.21",
      "LUMO": "-3.71",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "",
      "E00T1": "0.71",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.58",
      "ET1red": "",
      "DipoleMomentS0": "1.57",
      "DipoleMomentS1": "1.57",
      "DipoleMomentT1": "2.3",
      "ionizationPotential": "7.23",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MIAWOSVGSUGHIC-ZCXUNETKSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)[N+](=O)[O-]",
      "HOMO": "-6.18",
      "LUMO": "-3.65",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.95",
      "DipoleMomentS1": "3.95",
      "DipoleMomentT1": "6.77",
      "ionizationPotential": "7.16",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MIGUOAXZJSJHKS-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)C)c1nnc(nn1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)C)c1nnc(nn1)C",
      "HOMO": "-7.1",
      "LUMO": "-3.44",
      "VEE": "1.52",
      "Lmax": "815.657894736842",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.28",
      "DipoleMomentS1": "0.28",
      "DipoleMomentT1": "0.25",
      "ionizationPotential": "7.95",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "MIIAYZFCAJVJNG-OCEACIFDSA-N",
      "Smiles": "FC(Oc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-5.72",
      "LUMO": "-2.98",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "2.04",
      "E00T1": "",
      "Eox": "0.8",
      "Ered": "",
      "ES1ox": "-1.24",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.17",
      "DipoleMomentS1": "1.17",
      "DipoleMomentT1": "1.67",
      "ionizationPotential": "6.45",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "MJDKRWIOVGVRLO-FMQUCBEESA-N",
      "Smiles": "Sc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)S",
      "HOMO": "-5.51",
      "LUMO": "-2.87",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "",
      "E00T1": "0.79",
      "Eox": "0.56",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.23",
      "ET1red": "",
      "DipoleMomentS0": "0.33",
      "DipoleMomentS1": "0.33",
      "DipoleMomentT1": "0.24",
      "ionizationPotential": "6.13",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MJDKRWIOVGVRLO-VXPUYCOJSA-N",
      "Smiles": "Sc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)S",
      "HOMO": "-5.76",
      "LUMO": "-2.71",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "0.19",
      "Eox": "0.44",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.25",
      "ET1red": "",
      "DipoleMomentS0": "9.5",
      "DipoleMomentS1": "9.5",
      "DipoleMomentT1": "5.33",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MJFIVXQILNDVGB-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(o1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(o1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.71",
      "LUMO": "-2.92",
      "VEE": "3.31",
      "Lmax": "374.561933534743",
      "E00S1": "3.21",
      "E00T1": "2.46",
      "Eox": "2.54",
      "Ered": "",
      "ES1ox": "-0.67",
      "ES1red": "",
      "ET1ox": "0.08",
      "ET1red": "",
      "DipoleMomentS0": "1.81",
      "DipoleMomentS1": "1.81",
      "DipoleMomentT1": "9.79",
      "ionizationPotential": "8.95",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MKCAPRAFLBKDLH-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(OC)ccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C=O)OC",
      "MolView_url": "http://molview.org/?q=O=Cn1c(OC)ccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C=O)OC",
      "HOMO": "-5.35",
      "LUMO": "-2.44",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "1.21",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.72",
      "ET1red": "",
      "DipoleMomentS0": "4.45",
      "DipoleMomentS1": "4.45",
      "DipoleMomentT1": "4.58",
      "ionizationPotential": "5.92",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "MKDNIKUYJDCPTD-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)[N+](=O)[O-])C#N)C1=CC=C(S1(=O)=O)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)[N+](=O)[O-])C#N)C1=CC=C(S1(=O)=O)[N+](=O)[O-]",
      "HOMO": "-8.1",
      "LUMO": "-4.21",
      "VEE": "3.13",
      "Lmax": "396.102236421725",
      "E00S1": "",
      "E00T1": "2.2",
      "Eox": "3.15",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.95",
      "ET1red": "",
      "DipoleMomentS0": "15.38",
      "DipoleMomentS1": "15.38",
      "DipoleMomentT1": "11.65",
      "ionizationPotential": "9.56",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MKMLYSRSAONWPR-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(nc1)c1cc2c3cc(c4ccc(cn4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)[N+](=O)[O-])cc(c5ccc4)c1ccc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(nc1)c1cc2c3cc(c4ccc(cn4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)[N+](=O)[O-])cc(c5ccc4)c1ccc(cn1)[N+](=O)[O-]",
      "HOMO": "-5.74",
      "LUMO": "-3.33",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "1.7",
      "E00T1": "1.08",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "-0.79",
      "ES1red": "",
      "ET1ox": "-0.17",
      "ET1red": "",
      "DipoleMomentS0": "2.97",
      "DipoleMomentS1": "2.97",
      "DipoleMomentT1": "2.29",
      "ionizationPotential": "0",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "MKVRCXSUZXRYQG-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc[nH]1)c1ccc[nH]1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc[nH]1)c1ccc[nH]1",
      "HOMO": "-6.06",
      "LUMO": "-3.06",
      "VEE": "2.03",
      "Lmax": "610.738916256158",
      "E00S1": "2.05",
      "E00T1": "",
      "Eox": "1.35",
      "Ered": "",
      "ES1ox": "-0.7",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.83",
      "DipoleMomentS1": "4.83",
      "DipoleMomentT1": "9.13",
      "ionizationPotential": "7.06",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "MLBITYNKUMCSPC-ZCXUNETKSA-N",
      "Smiles": "[O-][N+](=O)c1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)[N+](=O)[O-]",
      "HOMO": "-7.02",
      "LUMO": "-3.81",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.5",
      "DipoleMomentS1": "9.5",
      "DipoleMomentT1": "9.66",
      "ionizationPotential": "8.13",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "MLQVDPVOFZOQHD-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cnc([nH]1)OC)C#N)c1cnc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cnc([nH]1)OC)C#N)c1cnc([nH]1)OC",
      "HOMO": "-6.09",
      "LUMO": "-2.79",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "",
      "E00T1": "1.89",
      "Eox": "-1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.02",
      "ET1red": "",
      "DipoleMomentS0": "2.59",
      "DipoleMomentS1": "2.59",
      "DipoleMomentT1": "7.07",
      "ionizationPotential": "7.35",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MLVPVDPNMXZHAL-UHFFFAOYSA-N",
      "Smiles": "c1csc(n1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=c1csc(n1)[C@@H]1N=N1",
      "HOMO": "-7.11",
      "LUMO": "-2.37",
      "VEE": "3.27",
      "Lmax": "379.14373088685",
      "E00S1": "3.11",
      "E00T1": "2.5",
      "Eox": "2.15",
      "Ered": "",
      "ES1ox": "-0.96",
      "ES1red": "",
      "ET1ox": "-0.35",
      "ET1red": "",
      "DipoleMomentS0": "1.98",
      "DipoleMomentS1": "1.98",
      "DipoleMomentT1": "8.01",
      "ionizationPotential": "8.69",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MMFYUJYPEGZJEG-DQRAZIAOSA-N",
      "Smiles": "Oc1cc([nH]c1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1[nH]cc(c1)O",
      "MolView_url": "http://molview.org/?q=Oc1cc([nH]c1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1[nH]cc(c1)O",
      "HOMO": "-5.13",
      "LUMO": "-2.34",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.06",
      "DipoleMomentS1": "8.06",
      "DipoleMomentT1": "4.76",
      "ionizationPotential": "5.88",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "MMMXYFWMAFIHQB-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(C1)O)C#N)C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(C1)O)C#N)C1=CC=C(C1)O",
      "HOMO": "-5.64",
      "LUMO": "-2.57",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "1.55",
      "Eox": "-1.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.92",
      "ET1red": "",
      "DipoleMomentS0": "1.32",
      "DipoleMomentS1": "1.32",
      "DipoleMomentT1": "3.51",
      "ionizationPotential": "6.95",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MMTKKXVMFHFDIZ-CIIODKQPSA-N",
      "Smiles": "C/C=C\\c1cc(C#N)cc(c1C#N)/C=C/C",
      "MolView_url": "http://molview.org/?q=C/C=C\\c1cc(C#N)cc(c1C#N)/C=C/C",
      "HOMO": "-6.69",
      "LUMO": "-2.64",
      "VEE": "3.83",
      "Lmax": "323.707571801567",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.39",
      "DipoleMomentS1": "2.39",
      "DipoleMomentT1": "3.31",
      "ionizationPotential": "8.27",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MMXWZAKVVWUPQT-DQRAZIAOSA-N",
      "Smiles": "COc1coc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1occ(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1coc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1occ(n1)OC",
      "HOMO": "-5.67",
      "LUMO": "-2.78",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.9",
      "DipoleMomentS1": "3.9",
      "DipoleMomentT1": "3.83",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "MNFKRXGMXVVAOQ-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(cc1)c1cc2c3cc(c4ccc(cc4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)C(F)(F)F)cc(c5ccc4)c1ccc(cc1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(cc1)c1cc2c3cc(c4ccc(cc4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)C(F)(F)F)cc(c5ccc4)c1ccc(cc1)C(F)(F)F)(F)F",
      "HOMO": "-5.53",
      "LUMO": "-2.58",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "2.1",
      "E00T1": "0.23",
      "Eox": "-4.34",
      "Ered": "",
      "ES1ox": "-6.44",
      "ES1red": "",
      "ET1ox": "-4.57",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.55",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "MNLARFLVKUCHTB-QPLCGJKRSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)[N+](=O)[O-]",
      "HOMO": "-6.63",
      "LUMO": "-3.25",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "",
      "E00T1": "0.55",
      "Eox": "1.56",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.01",
      "ET1red": "",
      "DipoleMomentS0": "2.32",
      "DipoleMomentS1": "2.32",
      "DipoleMomentT1": "0.54",
      "ionizationPotential": "7.53",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "MPJLNHAYPJPOOW-AFUUSBDKSA-N",
      "Smiles": "N#C/C=C\\O/C=C\\c1cc2c3cc(/C=C\\O/C=C/C#N)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C/C#N)cc(c5ccc4)/C=C\\O/C=C/C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\O/C=C\\c1cc2c3cc(/C=C\\O/C=C/C#N)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C/C#N)cc(c5ccc4)/C=C\\O/C=C/C#N",
      "HOMO": "-5.5",
      "LUMO": "-2.68",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.68",
      "DipoleMomentS1": "4.68",
      "DipoleMomentT1": "4.5",
      "ionizationPotential": "0.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "MPOJYXZSUDTMRQ-ISLYRVAYSA-N",
      "Smiles": "c1coc(c1)c1ccc(o1)/N=N/c1ccc(o1)c1ccco1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)c1ccc(o1)/N=N/c1ccc(o1)c1ccco1",
      "HOMO": "-5.58",
      "LUMO": "-2.89",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "1.99",
      "E00T1": "0.71",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.42",
      "DipoleMomentS1": "1.42",
      "DipoleMomentT1": "1.95",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "MPSQKFNIENVJSP-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(o1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)[C@]1(C#N)N=N1",
      "HOMO": "-7.95",
      "LUMO": "-3.16",
      "VEE": "3.5",
      "Lmax": "354.228571428571",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.75",
      "DipoleMomentS1": "4.75",
      "DipoleMomentT1": "7.07",
      "ionizationPotential": "9.72",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MQBFZVASMJQCFF-UHFFFAOYSA-N",
      "Smiles": "Sc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)S",
      "MolView_url": "http://molview.org/?q=Sc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)S",
      "HOMO": "-5.65",
      "LUMO": "-3.14",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "1.48",
      "E00T1": "1.17",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "-0.62",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "2.81",
      "DipoleMomentS1": "2.81",
      "DipoleMomentT1": "3.95",
      "ionizationPotential": "6.51",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "MQVCYKGFUVSGAY-HUWOUMPJSA-N",
      "Smiles": "S/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\S",
      "MolView_url": "http://molview.org/?q=S/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\S",
      "HOMO": "-5.99",
      "LUMO": "-3.15",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.14",
      "Eox": "0.95",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.81",
      "ET1red": "",
      "DipoleMomentS0": "5.66",
      "DipoleMomentS1": "5.66",
      "DipoleMomentT1": "4.9",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MQVCYKGFUVSGAY-NSFMOVNFSA-N",
      "Smiles": "S/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\S",
      "MolView_url": "http://molview.org/?q=S/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\S",
      "HOMO": "-6.29",
      "LUMO": "-3.36",
      "VEE": "3.13",
      "Lmax": "396.102236421725",
      "E00S1": "",
      "E00T1": "0.87",
      "Eox": "1.34",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.47",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.16",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MQYLIFUZGCCMNV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(c2cccn2C)c(c(c1)c1cccn1C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(c2cccn2C)c(c(c1)c1cccn1C)C#N",
      "HOMO": "-6.2",
      "LUMO": "-2.82",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "",
      "E00T1": "2.09",
      "Eox": "-1.09",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.18",
      "ET1red": "",
      "DipoleMomentS0": "5.9",
      "DipoleMomentS1": "5.9",
      "DipoleMomentT1": "13.01",
      "ionizationPotential": "7.45",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MRANAFHCHCCNBT-OCEACIFDSA-N",
      "Smiles": "FC(c1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)C(F)(F)F)(F)F",
      "HOMO": "-6.43",
      "LUMO": "-3.43",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "1.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.65",
      "ET1red": "",
      "DipoleMomentS0": "2.09",
      "DipoleMomentS1": "2.09",
      "DipoleMomentT1": "2.35",
      "ionizationPotential": "7.38",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MRANAFHCHCCNBT-QPLCGJKRSA-N",
      "Smiles": "FC(c1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)C(F)(F)F)(F)F",
      "HOMO": "-6.36",
      "LUMO": "-3.32",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.35",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.99",
      "DipoleMomentS1": "1.99",
      "DipoleMomentT1": "1.91",
      "ionizationPotential": "7.29",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MRTMGFXVDLZQOE-OCEACIFDSA-N",
      "Smiles": "FC(c1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)C(F)(F)F)(F)F",
      "HOMO": "-6.38",
      "LUMO": "-3.41",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.17",
      "DipoleMomentS1": "0.17",
      "DipoleMomentT1": "0.2",
      "ionizationPotential": "7.36",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MRTMGFXVDLZQOE-QPLCGJKRSA-N",
      "Smiles": "FC(c1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)C(F)(F)F)(F)F",
      "HOMO": "-6.32",
      "LUMO": "-3.29",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.05",
      "ET1red": "",
      "DipoleMomentS0": "5.76",
      "DipoleMomentS1": "5.76",
      "DipoleMomentT1": "6.64",
      "ionizationPotential": "7.27",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MRVDIWCRWWNSIW-UHFFFAOYSA-N",
      "Smiles": "N1=N[C@@H]1c1nncnn1",
      "MolView_url": "http://molview.org/?q=N1=N[C@@H]1c1nncnn1",
      "HOMO": "-7.38",
      "LUMO": "-3.35",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "2.09",
      "E00T1": "1.41",
      "Eox": "2.4",
      "Ered": "",
      "ES1ox": "0.31",
      "ES1red": "",
      "ET1ox": "0.99",
      "ET1red": "",
      "DipoleMomentS0": "1.45",
      "DipoleMomentS1": "1.45",
      "DipoleMomentT1": "1.52",
      "ionizationPotential": "8.95",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MRYDESCQYLNXRY-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)OC)c1ccc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)OC)c1ccc(o1)OC",
      "HOMO": "-5.3",
      "LUMO": "-2.34",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "2.15",
      "E00T1": "",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "-1.6",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.44",
      "DipoleMomentS1": "9.44",
      "DipoleMomentT1": "",
      "ionizationPotential": "5.82",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "MSRWQLFVIGKNSX-UHFFFAOYSA-N",
      "Smiles": "Sc1ncc([nH]1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1ncc([nH]1)[C@]1(S)N=N1",
      "HOMO": "-6.34",
      "LUMO": "-2.35",
      "VEE": "3.34",
      "Lmax": "371.197604790419",
      "E00S1": "2.64",
      "E00T1": "2.03",
      "Eox": "1.45",
      "Ered": "",
      "ES1ox": "-1.19",
      "ES1red": "",
      "ET1ox": "-0.58",
      "ET1red": "",
      "DipoleMomentS0": "4.11",
      "DipoleMomentS1": "4.11",
      "DipoleMomentT1": "10.96",
      "ionizationPotential": "7.77",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MSTSHJBGXXSBBI-UHFFFAOYSA-N",
      "Smiles": "FC(c1nnc(nn1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1nnc(nn1)C(F)(F)F)c1nnc(nn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1nnc(nn1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1nnc(nn1)C(F)(F)F)c1nnc(nn1)C(F)(F)F)(F)F",
      "HOMO": "-5.93",
      "LUMO": "-3.47",
      "VEE": "2.31",
      "Lmax": "536.709956709957",
      "E00S1": "",
      "E00T1": "1.3",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.06",
      "DipoleMomentS1": "2.06",
      "DipoleMomentT1": "35.3",
      "ionizationPotential": "7.08",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "MTCSWNFRGUZZQB-FMQUCBEESA-N",
      "Smiles": "Cn1c(ccc1[N+](=O)[O-])c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=Cn1c(ccc1[N+](=O)[O-])c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)[N+](=O)[O-]",
      "HOMO": "-6.16",
      "LUMO": "-3.37",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.18",
      "DipoleMomentS1": "5.18",
      "DipoleMomentT1": "4.89",
      "ionizationPotential": "7.06",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MTCSWNFRGUZZQB-VXPUYCOJSA-N",
      "Smiles": "Cn1c(ccc1[N+](=O)[O-])c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=Cn1c(ccc1[N+](=O)[O-])c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)[N+](=O)[O-]",
      "HOMO": "-6.36",
      "LUMO": "-3.23",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.09",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.06",
      "DipoleMomentS1": "9.06",
      "DipoleMomentT1": "6.16",
      "ionizationPotential": "7.06",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MTEQHCIFYAMMCB-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)C)c1cnc([nH]1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)C)c1cnc([nH]1)C",
      "HOMO": "-6.3",
      "LUMO": "-3.13",
      "VEE": "2.11",
      "Lmax": "587.582938388626",
      "E00S1": "2.41",
      "E00T1": "1.88",
      "Eox": "1.45",
      "Ered": "",
      "ES1ox": "-0.96",
      "ES1red": "",
      "ET1ox": "-0.43",
      "ET1red": "",
      "DipoleMomentS0": "6.38",
      "DipoleMomentS1": "6.38",
      "DipoleMomentT1": "10.67",
      "ionizationPotential": "7.24",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "MTEWHBWXESQVMF-HNPZLRBASA-N",
      "Smiles": "CO/N=C\\c1ccc(o1)/N=N\\c1ccc(o1)/C=N\\OC",
      "MolView_url": "http://molview.org/?q=CO/N=C\\c1ccc(o1)/N=N\\c1ccc(o1)/C=N\\OC",
      "HOMO": "-5.97",
      "LUMO": "-3.14",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.4",
      "DipoleMomentS1": "4.4",
      "DipoleMomentT1": "3.3",
      "ionizationPotential": "6.8",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "MTEWHBWXESQVMF-PFRXGMGXSA-N",
      "Smiles": "CO/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N/OC",
      "MolView_url": "http://molview.org/?q=CO/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N/OC",
      "HOMO": "-6.03",
      "LUMO": "-3.14",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "2.22",
      "E00T1": "0.73",
      "Eox": "1.06",
      "Ered": "",
      "ES1ox": "-1.16",
      "ES1red": "",
      "ET1ox": "0.33",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.73",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "MTONWXDFLYUQKU-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)S)cc(c5ccc4)c1ccc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)S)cc(c5ccc4)c1ccc([nH]1)S",
      "HOMO": "-5.3",
      "LUMO": "-2.52",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "1.93",
      "E00T1": "0.22",
      "Eox": "0.47",
      "Ered": "",
      "ES1ox": "-1.46",
      "ES1red": "",
      "ET1ox": "0.25",
      "ET1red": "",
      "DipoleMomentS0": "3.91",
      "DipoleMomentS1": "3.91",
      "DipoleMomentT1": "3.8",
      "ionizationPotential": "0.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "MTPLHRKRPUCSNW-ISLYRVAYSA-N",
      "Smiles": "OC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)O",
      "HOMO": "-5.86",
      "LUMO": "-3.35",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "0.94",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.45",
      "ET1red": "",
      "DipoleMomentS0": "3.76",
      "DipoleMomentS1": "3.76",
      "DipoleMomentT1": "3.75",
      "ionizationPotential": "6.69",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "MTRSAADJQVZTTR-UHFFFAOYSA-N",
      "Smiles": "Cc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)C",
      "MolView_url": "http://molview.org/?q=Cc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)C",
      "HOMO": "-5.65",
      "LUMO": "-3",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "1.82",
      "E00T1": "1.2",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "-0.98",
      "ES1red": "",
      "ET1ox": "-0.36",
      "ET1red": "",
      "DipoleMomentS0": "1.22",
      "DipoleMomentS1": "1.22",
      "DipoleMomentT1": "2.35",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "MUAQFCRZYXCWMR-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(o1)c1cc2c3cc(c4ccc(o4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)F)cc(c5ccc4)c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(o1)c1cc2c3cc(c4ccc(o4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)F)cc(c5ccc4)c1ccc(o1)F",
      "HOMO": "-5.37",
      "LUMO": "-2.64",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "1.89",
      "E00T1": "0.26",
      "Eox": "0.51",
      "Ered": "",
      "ES1ox": "-1.38",
      "ES1red": "",
      "ET1ox": "0.25",
      "ET1red": "",
      "DipoleMomentS0": "4.88",
      "DipoleMomentS1": "4.88",
      "DipoleMomentT1": "4.5",
      "ionizationPotential": "6.09",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "MUGLPCCJBAVXKQ-FMQUCBEESA-N",
      "Smiles": "O=Cn1cccc1c1ccc(s1)/N=N/c1ccc(s1)c1cccn1C=O",
      "MolView_url": "http://molview.org/?q=O=Cn1cccc1c1ccc(s1)/N=N/c1ccc(s1)c1cccn1C=O",
      "HOMO": "-5.91",
      "LUMO": "-3.04",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "0.82",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.08",
      "ET1red": "",
      "DipoleMomentS0": "3.7",
      "DipoleMomentS1": "3.7",
      "DipoleMomentT1": "3.28",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MUGLPCCJBAVXKQ-VXPUYCOJSA-N",
      "Smiles": "O=Cn1cccc1c1ccc(s1)/N=N\\c1ccc(s1)c1cccn1C=O",
      "MolView_url": "http://molview.org/?q=O=Cn1cccc1c1ccc(s1)/N=N\\c1ccc(s1)c1cccn1C=O",
      "HOMO": "-6.16",
      "LUMO": "-2.8",
      "VEE": "2.5",
      "Lmax": "495.92",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "0.23",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "11.3",
      "DipoleMomentS1": "11.3",
      "DipoleMomentT1": "8.34",
      "ionizationPotential": "6.44",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MUIDZKYQJWIUJE-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)C)c1[nH]cc(c1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)C)c1[nH]cc(c1)C",
      "HOMO": "-5.96",
      "LUMO": "-3.01",
      "VEE": "1.98",
      "Lmax": "626.161616161616",
      "E00S1": "2.25",
      "E00T1": "1.73",
      "Eox": "1.19",
      "Ered": "",
      "ES1ox": "-1.06",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "0.96",
      "DipoleMomentS1": "0.96",
      "DipoleMomentT1": "3.56",
      "ionizationPotential": "6.82",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "MUWCWQSJGMOQQK-VHXPQNKSSA-N",
      "Smiles": "Clc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)Cl",
      "HOMO": "-5.93",
      "LUMO": "-2.97",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.64",
      "ET1red": "",
      "DipoleMomentS0": "5.02",
      "DipoleMomentS1": "5.02",
      "DipoleMomentT1": "4.05",
      "ionizationPotential": "6.61",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MUWCWQSJGMOQQK-WCWDXBQESA-N",
      "Smiles": "Clc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)Cl",
      "HOMO": "-5.95",
      "LUMO": "-3.02",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "",
      "E00T1": "0.83",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.13",
      "ET1red": "",
      "DipoleMomentS0": "0.04",
      "DipoleMomentS1": "0.04",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MVBAVADLRAQYLB-FMQUCBEESA-N",
      "Smiles": "Fc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)F",
      "HOMO": "-5.97",
      "LUMO": "-3.17",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "2.1",
      "E00T1": "0.79",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.94",
      "DipoleMomentS1": "2.94",
      "DipoleMomentT1": "2.96",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "MVIZPPNIWQMMMN-FMQUCBEESA-N",
      "Smiles": "n1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cnc1",
      "MolView_url": "http://molview.org/?q=n1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cnc1",
      "HOMO": "-5.69",
      "LUMO": "-2.96",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MVIZPPNIWQMMMN-VXPUYCOJSA-N",
      "Smiles": "n1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cnc1",
      "MolView_url": "http://molview.org/?q=n1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cnc1",
      "HOMO": "-5.74",
      "LUMO": "-2.94",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.54",
      "ET1red": "",
      "DipoleMomentS0": "5.78",
      "DipoleMomentS1": "5.78",
      "DipoleMomentT1": "7.12",
      "ionizationPotential": "6.52",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MVJWHJIINGXEKF-UHFFFAOYSA-N",
      "Smiles": "ClC#Cc1cc(C#N)cc(c1C#N)C#CCl",
      "MolView_url": "http://molview.org/?q=ClC#Cc1cc(C#N)cc(c1C#N)C#CCl",
      "HOMO": "-7.26",
      "LUMO": "-3.02",
      "VEE": "3.61",
      "Lmax": "343.434903047091",
      "E00S1": "",
      "E00T1": "2.87",
      "Eox": "-0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.68",
      "ET1red": "",
      "DipoleMomentS0": "0.04",
      "DipoleMomentS1": "0.04",
      "DipoleMomentT1": "1.42",
      "ionizationPotential": "8.85",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MVTJFTFARBCLKA-HUWOUMPJSA-N",
      "Smiles": "N=Cc1ccc(s1)/N=N\\c1ccc(s1)C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1ccc(s1)/N=N\\c1ccc(s1)C=N",
      "HOMO": "-6.41",
      "LUMO": "-3.19",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.05",
      "ET1red": "",
      "DipoleMomentS0": "5.8",
      "DipoleMomentS1": "5.8",
      "DipoleMomentT1": "5.26",
      "ionizationPotential": "7.32",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MVTJFTFARBCLKA-MYUULXRZSA-N",
      "Smiles": "N=Cc1ccc(s1)/N=N\\c1ccc(s1)C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1ccc(s1)/N=N\\c1ccc(s1)C=N",
      "HOMO": "-6.41",
      "LUMO": "-3.19",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.8",
      "DipoleMomentS1": "5.8",
      "DipoleMomentT1": "5.26",
      "ionizationPotential": "7.32",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "MVTJFTFARBCLKA-ROZVNZQISA-N",
      "Smiles": "N=Cc1ccc(s1)/N=N/c1ccc(s1)C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1ccc(s1)/N=N/c1ccc(s1)C=N",
      "HOMO": "-6.52",
      "LUMO": "-3.34",
      "VEE": "3.07",
      "Lmax": "403.843648208469",
      "E00S1": "",
      "E00T1": "0.92",
      "Eox": "1.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.6",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.61",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MWARHWLDNOPYFQ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cc1)Cl)C#N)c1ccc(cc1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cc1)Cl)C#N)c1ccc(cc1)Cl",
      "HOMO": "-7.19",
      "LUMO": "-2.57",
      "VEE": "3.91",
      "Lmax": "317.084398976982",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.25",
      "DipoleMomentS1": "1.25",
      "DipoleMomentT1": "1.74",
      "ionizationPotential": "8.32",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "MWDFQPDJWWCJFC-MNSGOHHFSA-N",
      "Smiles": "O/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N\\O",
      "MolView_url": "http://molview.org/?q=O/N=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=N\\O",
      "HOMO": "-6.83",
      "LUMO": "-3",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "1.08",
      "Eox": "1.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.69",
      "ET1red": "",
      "DipoleMomentS0": "4.55",
      "DipoleMomentS1": "4.55",
      "DipoleMomentT1": "4.71",
      "ionizationPotential": "7.76",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "MWGSTMUSSPAFHC-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)O)c1ncc(s1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)O)c1ncc(s1)O",
      "HOMO": "-6.45",
      "LUMO": "-3.21",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "2.53",
      "E00T1": "2.01",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.81",
      "DipoleMomentS1": "2.81",
      "DipoleMomentT1": "7.93",
      "ionizationPotential": "7.39",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "MWICKHZZMVWCRX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1nnc(nn1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1nnc(nn1)[C@]1(C#N)N=N1",
      "HOMO": "-8.09",
      "LUMO": "-4.01",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.12",
      "E00T1": "1.46",
      "Eox": "3.1",
      "Ered": "",
      "ES1ox": "0.98",
      "ES1red": "",
      "ET1ox": "1.64",
      "ET1red": "",
      "DipoleMomentS0": "3.39",
      "DipoleMomentS1": "3.39",
      "DipoleMomentT1": "3.24",
      "ionizationPotential": "9.82",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MXLHCQJJQHKRNB-UHFFFAOYSA-N",
      "Smiles": "Oc1coc(n1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1coc(n1)[C@]1(O)N=N1",
      "HOMO": "-6.87",
      "LUMO": "-2.5",
      "VEE": "3.16",
      "Lmax": "392.341772151899",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.57",
      "DipoleMomentS1": "3.57",
      "DipoleMomentT1": "9.38",
      "ionizationPotential": "8.45",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MXOFOMLTZLRBJK-QURGRASLSA-N",
      "Smiles": "Clc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)Cl",
      "HOMO": "-5.24",
      "LUMO": "-2.64",
      "VEE": "2.59",
      "Lmax": "478.687258687259",
      "E00S1": "1.93",
      "E00T1": "",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "MXOIRLYYIXXIBC-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(s1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(s1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.65",
      "LUMO": "-2.98",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "3.09",
      "E00T1": "2.47",
      "Eox": "2.47",
      "Ered": "",
      "ES1ox": "-0.62",
      "ES1red": "",
      "ET1ox": "0",
      "ET1red": "",
      "DipoleMomentS0": "2.05",
      "DipoleMomentS1": "2.05",
      "DipoleMomentT1": "7.7",
      "ionizationPotential": "8.91",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MYMCCQOKBAWNCS-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)Cl)c1ccc(cc1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)Cl)c1ccc(cc1)Cl",
      "HOMO": "-6.89",
      "LUMO": "-3.15",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.54",
      "DipoleMomentS1": "1.54",
      "DipoleMomentT1": "2.29",
      "ionizationPotential": "7.82",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "MYSHGDYHOSJOCQ-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-5.51",
      "LUMO": "-2.55",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.04",
      "DipoleMomentS1": "2.04",
      "DipoleMomentT1": "2.17",
      "ionizationPotential": "6.42",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "MYZXZTIAXGTQLV-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(nc1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1cnc(nc1)[C@]1(F)N=N1",
      "HOMO": "-7.98",
      "LUMO": "-2.7",
      "VEE": "3.3",
      "Lmax": "375.69696969697",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.79",
      "DipoleMomentS1": "3.79",
      "DipoleMomentT1": "6.71",
      "ionizationPotential": "9.53",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "MYZZDPQANNNJDA-DQRAZIAOSA-N",
      "Smiles": "Oc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)O",
      "HOMO": "-5.68",
      "LUMO": "-2.84",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.6",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.01",
      "DipoleMomentS1": "8.01",
      "DipoleMomentT1": "7.24",
      "ionizationPotential": "6.24",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "MYZZDPQANNNJDA-QURGRASLSA-N",
      "Smiles": "Oc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)O",
      "HOMO": "-5.66",
      "LUMO": "-2.83",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "6.27",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "MZAJZFSNDKQOQC-FLWNBWAVSA-N",
      "Smiles": "N#Cc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cn1)C#N",
      "HOMO": "-6.55",
      "LUMO": "-2.77",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.31",
      "DipoleMomentS1": "6.31",
      "DipoleMomentT1": "5.43",
      "ionizationPotential": "7.4",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "MZCAIXMFCGSKDV-UHFFFAOYSA-N",
      "Smiles": "c1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nncnn1",
      "MolView_url": "http://molview.org/?q=c1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nncnn1",
      "HOMO": "-5.7",
      "LUMO": "-3.09",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "1.57",
      "E00T1": "",
      "Eox": "-4.33",
      "Ered": "",
      "ES1ox": "-5.9",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.5",
      "DipoleMomentS1": "3.5",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.64",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "MZCVGFHRLMKZGY-UHFFFAOYSA-N",
      "Smiles": "Oc1cc2c3cc(O)c4c5c3c(c3c2c2c1cccc2c(c3)O)cc(c5ccc4)O",
      "MolView_url": "http://molview.org/?q=Oc1cc2c3cc(O)c4c5c3c(c3c2c2c1cccc2c(c3)O)cc(c5ccc4)O",
      "HOMO": "-5.13",
      "LUMO": "-2.17",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "2.32",
      "E00T1": "1.23",
      "Eox": "0.34",
      "Ered": "",
      "ES1ox": "-1.98",
      "ES1red": "",
      "ET1ox": "-0.89",
      "ET1red": "",
      "DipoleMomentS0": "5.28",
      "DipoleMomentS1": "5.28",
      "DipoleMomentT1": "5.45",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "MZXQVFRTMJTNPM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(c2nccs2)c(c(c1)c1nccs1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(c2nccs2)c(c(c1)c1nccs1)C#N",
      "HOMO": "-6.82",
      "LUMO": "-3.15",
      "VEE": "3.74",
      "Lmax": "331.497326203208",
      "E00S1": "",
      "E00T1": "2.43",
      "Eox": "-0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.27",
      "ET1red": "",
      "DipoleMomentS0": "5.77",
      "DipoleMomentS1": "5.77",
      "DipoleMomentT1": "6.82",
      "ionizationPotential": "8.22",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NAENMAXOXHGNFQ-PGMKUTCJSA-N",
      "Smiles": "FC(O/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/OC(F)(F)F)(F)F",
      "HOMO": "-5.97",
      "LUMO": "-3.05",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.82",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NAENMAXOXHGNFQ-WYSJLEGWSA-N",
      "Smiles": "FC(O/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/OC(F)(F)F)(F)F",
      "HOMO": "-5.94",
      "LUMO": "-2.98",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "",
      "E00T1": "0.18",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.61",
      "DipoleMomentS1": "2.61",
      "DipoleMomentT1": "2.73",
      "ionizationPotential": "6.74",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NAMCWXVZSGFYFR-OCEACIFDSA-N",
      "Smiles": "FC(Oc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-6.18",
      "LUMO": "-3.36",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "0.82",
      "Eox": "1.31",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.49",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.05",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NAMCWXVZSGFYFR-QPLCGJKRSA-N",
      "Smiles": "FC(Oc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-6.14",
      "LUMO": "-3.27",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.18",
      "Eox": "1.14",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.96",
      "ET1red": "",
      "DipoleMomentS0": "4.64",
      "DipoleMomentS1": "4.64",
      "DipoleMomentT1": "4.36",
      "ionizationPotential": "6.94",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NCCHNDROWKVNLR-HMXUXOOYSA-N",
      "Smiles": "C/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C\\C",
      "MolView_url": "http://molview.org/?q=C/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C\\C",
      "HOMO": "-5.72",
      "LUMO": "-2.84",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0",
      "ET1red": "",
      "DipoleMomentS0": "1.46",
      "DipoleMomentS1": "1.46",
      "DipoleMomentT1": "2.4",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NCCHNDROWKVNLR-OLCNVMMDSA-N",
      "Smiles": "C/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C\\C",
      "MolView_url": "http://molview.org/?q=C/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C\\C",
      "HOMO": "-5.96",
      "LUMO": "-2.73",
      "VEE": "1.89",
      "Lmax": "655.978835978836",
      "E00S1": "",
      "E00T1": "0.14",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.54",
      "ET1red": "",
      "DipoleMomentS0": "6.94",
      "DipoleMomentS1": "6.94",
      "DipoleMomentT1": "4.67",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "NCCHNDROWKVNLR-OXLOUKGFSA-N",
      "Smiles": "C/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C/C",
      "MolView_url": "http://molview.org/?q=C/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C/C",
      "HOMO": "-5.7",
      "LUMO": "-2.84",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.47",
      "DipoleMomentS1": "7.47",
      "DipoleMomentT1": "6.35",
      "ionizationPotential": "6.37",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NCDMQYQHPCGCCS-UHFFFAOYSA-N",
      "Smiles": "c1csc(c1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=c1csc(c1)[C@@H]1N=N1",
      "HOMO": "-6.68",
      "LUMO": "-2.13",
      "VEE": "3.35",
      "Lmax": "370.089552238806",
      "E00S1": "2.9",
      "E00T1": "2.29",
      "Eox": "1.72",
      "Ered": "",
      "ES1ox": "-1.18",
      "ES1red": "",
      "ET1ox": "-0.57",
      "ET1red": "",
      "DipoleMomentS0": "2.2",
      "DipoleMomentS1": "2.2",
      "DipoleMomentT1": "8.55",
      "ionizationPotential": "8.2",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "NCGSINXYGXNQDP-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)O)c1ccc(n1C)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)O)c1ccc(n1C)O",
      "HOMO": "-5.64",
      "LUMO": "-2.96",
      "VEE": "1.81",
      "Lmax": "684.972375690608",
      "E00S1": "1.78",
      "E00T1": "",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "-1",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.7",
      "DipoleMomentS1": "3.7",
      "DipoleMomentT1": "9.01",
      "ionizationPotential": "6.5",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "NCPWDKSTMQSEKS-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(Cl)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C=O)Cl)c1ccc(n1C=O)Cl",
      "MolView_url": "http://molview.org/?q=O=Cn1c(Cl)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C=O)Cl)c1ccc(n1C=O)Cl",
      "HOMO": "-5.62",
      "LUMO": "-2.49",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.51",
      "DipoleMomentS1": "9.51",
      "DipoleMomentT1": "10.5",
      "ionizationPotential": "6.41",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "NCVKZEOJJJXHBA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(C#C)c(c(c1)C#C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(C#C)c(c(c1)C#C)C#N",
      "HOMO": "-7.53",
      "LUMO": "-3.05",
      "VEE": "3.9",
      "Lmax": "317.897435897436",
      "E00S1": "",
      "E00T1": "2.99",
      "Eox": "-0.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.73",
      "ET1red": "",
      "DipoleMomentS0": "0.1",
      "DipoleMomentS1": "0.1",
      "DipoleMomentT1": "0.5",
      "ionizationPotential": "9.22",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NDBUZFCFWBQMGU-UHFFFAOYSA-N",
      "Smiles": "Oc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)O)cc(c5ccc4)c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=Oc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)O)cc(c5ccc4)c1nnc(nn1)O",
      "HOMO": "-5.82",
      "LUMO": "-3.25",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "1.54",
      "E00T1": "",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "-0.54",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "",
      "ionizationPotential": "-0.01",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "NEMSGRBZIGIZSZ-ISLYRVAYSA-N",
      "Smiles": "Oc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)O",
      "HOMO": "-6",
      "LUMO": "-3.29",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "",
      "E00T1": "0.78",
      "Eox": "1.1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.32",
      "ET1red": "",
      "DipoleMomentS0": "0.24",
      "DipoleMomentS1": "0.24",
      "DipoleMomentT1": "0.26",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NEMSGRBZIGIZSZ-ZCXUNETKSA-N",
      "Smiles": "Oc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)O",
      "HOMO": "-5.99",
      "LUMO": "-3.19",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.95",
      "DipoleMomentS1": "4.95",
      "DipoleMomentT1": "4.46",
      "ionizationPotential": "6.75",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NEPKELDIRWSVAJ-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)S)c1ccc(cc1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)S)c1ccc(cc1)S",
      "HOMO": "-6.35",
      "LUMO": "-3.1",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "2.6",
      "E00T1": "1.98",
      "Eox": "1.67",
      "Ered": "",
      "ES1ox": "-0.93",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "1.69",
      "DipoleMomentS1": "1.69",
      "DipoleMomentT1": "20.78",
      "ionizationPotential": "7.28",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "NFXINJMTLZWKOI-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(s1)C)C#N)c1ccc(s1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(s1)C)C#N)c1ccc(s1)C",
      "HOMO": "-6.55",
      "LUMO": "-2.58",
      "VEE": "3.25",
      "Lmax": "381.476923076923",
      "E00S1": "",
      "E00T1": "2.08",
      "Eox": "1.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "1.65",
      "DipoleMomentS1": "1.65",
      "DipoleMomentT1": "6.65",
      "ionizationPotential": "7.56",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NGEYNIDQBALANX-OCEACIFDSA-N",
      "Smiles": "FC(Oc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-5.87",
      "LUMO": "-3.09",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.13",
      "DipoleMomentS1": "6.13",
      "DipoleMomentT1": "6.26",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NGEYNIDQBALANX-QPLCGJKRSA-N",
      "Smiles": "FC(Oc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-5.86",
      "LUMO": "-3.06",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5",
      "DipoleMomentS1": "5",
      "DipoleMomentT1": "5.23",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NGPARNWSSXPNDJ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1scc(n1)O)C#N)c1scc(n1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1scc(n1)O)C#N)c1scc(n1)O",
      "HOMO": "-6.47",
      "LUMO": "-3.19",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.24",
      "DipoleMomentS1": "2.24",
      "DipoleMomentT1": "4.17",
      "ionizationPotential": "7.87",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NGPVVXZPVMKQOI-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)S)c1cnc([nH]1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)S)c1cnc([nH]1)S",
      "HOMO": "-6.15",
      "LUMO": "-3.15",
      "VEE": "2.01",
      "Lmax": "616.81592039801",
      "E00S1": "2.29",
      "E00T1": "1.83",
      "Eox": "1.57",
      "Ered": "",
      "ES1ox": "-0.72",
      "ES1red": "",
      "ET1ox": "-0.26",
      "ET1red": "",
      "DipoleMomentS0": "3.24",
      "DipoleMomentS1": "3.24",
      "DipoleMomentT1": "9.47",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "NGYPRDGPTXUVCT-SFECMWDFSA-N",
      "Smiles": "O/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\O",
      "MolView_url": "http://molview.org/?q=O/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\O",
      "HOMO": "-6.44",
      "LUMO": "-3.07",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "2.56",
      "E00T1": "1.81",
      "Eox": "1.59",
      "Ered": "",
      "ES1ox": "-0.97",
      "ES1red": "",
      "ET1ox": "-0.22",
      "ET1red": "",
      "DipoleMomentS0": "1.81",
      "DipoleMomentS1": "1.81",
      "DipoleMomentT1": "13.34",
      "ionizationPotential": "7.6",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "NGZRDDBXJHPDMB-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)Cl)c1ccc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)Cl)c1ccc(o1)Cl",
      "HOMO": "-5.51",
      "LUMO": "-2.45",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "2.22",
      "E00T1": "0.1",
      "Eox": "1.31",
      "Ered": "",
      "ES1ox": "-0.91",
      "ES1red": "",
      "ET1ox": "1.21",
      "ET1red": "",
      "DipoleMomentS0": "7.83",
      "DipoleMomentS1": "7.83",
      "DipoleMomentT1": "7.98",
      "ionizationPotential": "6.21",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "NHPDSOGZAIVQRC-MVJHLKBCSA-N",
      "Smiles": "FC(Oc1cnc(nc1)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(nc1)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-5.98",
      "LUMO": "-2.92",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "",
      "E00T1": "0.35",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.34",
      "DipoleMomentS1": "3.34",
      "DipoleMomentT1": "1.7",
      "ionizationPotential": "6.69",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "NIAFOSMNTKOEGH-UHFFFAOYSA-N",
      "Smiles": "FC(c1ncc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cnc([nH]1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ncc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cnc([nH]1)C(F)(F)F)(F)F",
      "HOMO": "-6.88",
      "LUMO": "-3.26",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.81",
      "E00T1": "2.21",
      "Eox": "2.02",
      "Ered": "",
      "ES1ox": "-0.79",
      "ES1red": "",
      "ET1ox": "-0.19",
      "ET1red": "",
      "DipoleMomentS0": "2.44",
      "DipoleMomentS1": "2.44",
      "DipoleMomentT1": "1.36",
      "ionizationPotential": "8.07",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "NIUUGWCTNJUJTK-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)S",
      "HOMO": "-5.47",
      "LUMO": "-2.57",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.01",
      "E00T1": "1.22",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.56",
      "DipoleMomentS1": "3.56",
      "DipoleMomentT1": "3.23",
      "ionizationPotential": "6.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "NJXWMZGIALRBFI-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(o1)c1cc2c3cc(c4ccc(o4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)C)cc(c5ccc4)c1ccc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(o1)c1cc2c3cc(c4ccc(o4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)C)cc(c5ccc4)c1ccc(o1)C",
      "HOMO": "-5.17",
      "LUMO": "-2.55",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "1.81",
      "E00T1": "1.06",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "-1.26",
      "ES1red": "",
      "ET1ox": "-0.51",
      "ET1red": "",
      "DipoleMomentS0": "0.2",
      "DipoleMomentS1": "0.2",
      "DipoleMomentT1": "0.28",
      "ionizationPotential": "0.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "NKJFJKOQYGMLHE-QPLCGJKRSA-N",
      "Smiles": "COc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C)OC",
      "HOMO": "-5.4",
      "LUMO": "-2.31",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.5",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.63",
      "DipoleMomentS1": "8.63",
      "DipoleMomentT1": "7.96",
      "ionizationPotential": "6",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "NLKJLDRJMXBWEE-UHFFFAOYSA-N",
      "Smiles": "c1ncc([nH]1)c1cc2c3cc(c4cnc[nH]4)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc[nH]1)cc(c5ccc4)c1cnc[nH]1",
      "MolView_url": "http://molview.org/?q=c1ncc([nH]1)c1cc2c3cc(c4cnc[nH]4)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc[nH]1)cc(c5ccc4)c1cnc[nH]1",
      "HOMO": "-5.46",
      "LUMO": "-2.6",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "2.01",
      "E00T1": "1.19",
      "Eox": "0.62",
      "Ered": "",
      "ES1ox": "-1.39",
      "ES1red": "",
      "ET1ox": "-0.57",
      "ET1red": "",
      "DipoleMomentS0": "7.31",
      "DipoleMomentS1": "7.31",
      "DipoleMomentT1": "7.35",
      "ionizationPotential": "6.33",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "NLLZSBUWCOPDKB-QURGRASLSA-N",
      "Smiles": "COC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)OC",
      "HOMO": "-4.92",
      "LUMO": "-2.68",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.7",
      "DipoleMomentS1": "2.7",
      "DipoleMomentT1": "3.44",
      "ionizationPotential": "5.51",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "NMSSJHHYMXFPHZ-UHFFFAOYSA-N",
      "Smiles": "Fc1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)F)c1[nH]cc(c1)F",
      "MolView_url": "http://molview.org/?q=Fc1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)F)c1[nH]cc(c1)F",
      "HOMO": "-5.45",
      "LUMO": "-2.38",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.22",
      "E00T1": "",
      "Eox": "-4.33",
      "Ered": "",
      "ES1ox": "-6.55",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.98",
      "DipoleMomentS1": "5.98",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.21",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "NNBWJMBWXCKLPX-FMQUCBEESA-N",
      "Smiles": "COc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)OC",
      "HOMO": "-5.74",
      "LUMO": "-3.18",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "1.89",
      "E00T1": "0.66",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.23",
      "DipoleMomentS1": "3.23",
      "DipoleMomentT1": "1.89",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "NNLCGKGYRBFIHI-OCEACIFDSA-N",
      "Smiles": "N#Cc1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)C#N",
      "HOMO": "-6.21",
      "LUMO": "-3.34",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "2.13",
      "E00T1": "0.84",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "NNODELAMZQIUFP-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)[N+](=O)[O-]",
      "HOMO": "-5.59",
      "LUMO": "-3.19",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "12.11",
      "DipoleMomentS1": "12.11",
      "DipoleMomentT1": "16.77",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "NNRWLQFWXGIWTM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C)Cl)C#N)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C)Cl)C#N)c1ccc(n1C)Cl",
      "HOMO": "-6.32",
      "LUMO": "-2.59",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.35",
      "DipoleMomentS1": "3.35",
      "DipoleMomentT1": "12.23",
      "ionizationPotential": "7.45",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NOCRKHKVSYSVHP-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)[N+](=O)[O-]",
      "HOMO": "-5.69",
      "LUMO": "-3.41",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "1.59",
      "E00T1": "1.06",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "-0.73",
      "ES1red": "",
      "ET1ox": "-0.2",
      "ET1red": "",
      "DipoleMomentS0": "8.96",
      "DipoleMomentS1": "8.96",
      "DipoleMomentT1": "14.26",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "NOSWHONGURFIAL-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C)OC(F)(F)F)C#N)c1ccc(n1C)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C)OC(F)(F)F)C#N)c1ccc(n1C)OC(F)(F)F",
      "HOMO": "-6.69",
      "LUMO": "-2.63",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.85",
      "DipoleMomentS1": "1.85",
      "DipoleMomentT1": "12.15",
      "ionizationPotential": "7.63",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NPDFXBGWLDCBKO-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(O)ccc1c1cc2c3cc(c4ccc(n4C=O)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)O)cc(c5ccc4)c1ccc(n1C=O)O",
      "MolView_url": "http://molview.org/?q=O=Cn1c(O)ccc1c1cc2c3cc(c4ccc(n4C=O)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)O)cc(c5ccc4)c1ccc(n1C=O)O",
      "HOMO": "-5.41",
      "LUMO": "-2.64",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "1.93",
      "E00T1": "1.17",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.95",
      "DipoleMomentS1": "4.95",
      "DipoleMomentT1": "4.91",
      "ionizationPotential": "0.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "NPJATZUXSXHWBW-QURGRASLSA-N",
      "Smiles": "COc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C)OC",
      "HOMO": "-5.08",
      "LUMO": "-2.54",
      "VEE": "2.55",
      "Lmax": "486.196078431373",
      "E00S1": "1.9",
      "E00T1": "0.72",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.04",
      "ionizationPotential": "5.21",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "NQEPYCQXLQERNG-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)F)c1ccc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)F)c1ccc(s1)F",
      "HOMO": "-5.54",
      "LUMO": "-2.44",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.25",
      "E00T1": "",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.53",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.99",
      "DipoleMomentS1": "5.99",
      "DipoleMomentT1": "",
      "ionizationPotential": "21.98",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "NQPZOHUXAAHRJJ-FMQUCBEESA-N",
      "Smiles": "Cc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)C",
      "HOMO": "-5.97",
      "LUMO": "-3.25",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "1.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.35",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.64",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NQPZOHUXAAHRJJ-VXPUYCOJSA-N",
      "Smiles": "Cc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)C",
      "HOMO": "-5.94",
      "LUMO": "-3.16",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.75",
      "ET1red": "",
      "DipoleMomentS0": "6.93",
      "DipoleMomentS1": "6.93",
      "DipoleMomentT1": "6.07",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NRBURGVTCFDSMW-UHFFFAOYSA-N",
      "Smiles": "c1coc(n1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=c1coc(n1)[C@@H]1N=N1",
      "HOMO": "-7.18",
      "LUMO": "-2.37",
      "VEE": "3.33",
      "Lmax": "372.312312312312",
      "E00S1": "3.17",
      "E00T1": "2.56",
      "Eox": "2.25",
      "Ered": "",
      "ES1ox": "-0.92",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "1.71",
      "DipoleMomentS1": "1.71",
      "DipoleMomentT1": "8.05",
      "ionizationPotential": "8.84",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "NRKXGXJHKRNWDU-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1scc(n1)OC(F)(F)F)C#N)c1scc(n1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1scc(n1)OC(F)(F)F)C#N)c1scc(n1)OC(F)(F)F",
      "HOMO": "-6.84",
      "LUMO": "-3.31",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "",
      "E00T1": "2.32",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.56",
      "DipoleMomentS1": "7.56",
      "DipoleMomentT1": "8.34",
      "ionizationPotential": "8.34",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NRVAEXYFRAVVGG-UHFFFAOYSA-N",
      "Smiles": "Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1ncco1)c1c3cc(cc1)c1ncco1)ccc(c5)c1ncco1",
      "MolView_url": "http://molview.org/?q=Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1ncco1)c1c3cc(cc1)c1ncco1)ccc(c5)c1ncco1",
      "HOMO": "-5.68",
      "LUMO": "-2.56",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "2.27",
      "E00T1": "1.6",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "-1.43",
      "ES1red": "",
      "ET1ox": "-0.76",
      "ET1red": "",
      "DipoleMomentS0": "8.32",
      "DipoleMomentS1": "8.32",
      "DipoleMomentT1": "9.77",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "NSCSWGRVYZWBPG-UHFFFAOYSA-N",
      "Smiles": "COc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)OC",
      "HOMO": "-5.28",
      "LUMO": "-2.44",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "1.97",
      "E00T1": "1.19",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.84",
      "DipoleMomentS1": "3.84",
      "DipoleMomentT1": "3.77",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "NSJSFJHQEGNIIA-QURGRASLSA-N",
      "Smiles": "c1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncco1",
      "MolView_url": "http://molview.org/?q=c1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncco1",
      "HOMO": "-6.48",
      "LUMO": "-2.94",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.67",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "2.1",
      "ionizationPotential": "7.28",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "NSVBZFGZCLVDSX-FEGKCWGTSA-N",
      "Smiles": "N#C/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N/C#N",
      "MolView_url": "http://molview.org/?q=N#C/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N/C#N",
      "HOMO": "-6.99",
      "LUMO": "-4.01",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "17.27",
      "DipoleMomentS1": "17.27",
      "DipoleMomentT1": "17.43",
      "ionizationPotential": "8.11",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NSVBZFGZCLVDSX-GKMNODQZSA-N",
      "Smiles": "N#C/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\C#N",
      "MolView_url": "http://molview.org/?q=N#C/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\C#N",
      "HOMO": "-6.78",
      "LUMO": "-3.79",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.47",
      "DipoleMomentS1": "6.47",
      "DipoleMomentT1": "5.83",
      "ionizationPotential": "7.93",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NTECWRLOSKTCOE-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)Cl)C#N)C1=CC=C(S1(=O)=O)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(S1(=O)=O)Cl)C#N)C1=CC=C(S1(=O)=O)Cl",
      "HOMO": "-7.4",
      "LUMO": "-3.32",
      "VEE": "3.12",
      "Lmax": "397.371794871795",
      "E00S1": "",
      "E00T1": "2.25",
      "Eox": "2.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.29",
      "ET1red": "",
      "DipoleMomentS0": "13.99",
      "DipoleMomentS1": "13.99",
      "DipoleMomentT1": "12.54",
      "ionizationPotential": "8.64",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NUWOFTDZQKLGNO-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(C=C)c(c(c1)C=C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(C=C)c(c(c1)C=C)C#N",
      "HOMO": "-7",
      "LUMO": "-2.73",
      "VEE": "3.96",
      "Lmax": "313.080808080808",
      "E00S1": "",
      "E00T1": "2.77",
      "Eox": "-1.1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.87",
      "ET1red": "",
      "DipoleMomentS0": "0.01",
      "DipoleMomentS1": "0.01",
      "DipoleMomentT1": "0.3",
      "ionizationPotential": "8.72",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NVBJYCYMIBFHFG-UHFFFAOYSA-N",
      "Smiles": "FC(C1=CC=C(C1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(C1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(C1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(C1)C(F)(F)F)(F)F",
      "HOMO": "-6.54",
      "LUMO": "-3.22",
      "VEE": "2.1",
      "Lmax": "590.380952380952",
      "E00S1": "2.54",
      "E00T1": "1.97",
      "Eox": "-4.28",
      "Ered": "",
      "ES1ox": "-6.82",
      "ES1red": "",
      "ET1ox": "-6.25",
      "ET1red": "",
      "DipoleMomentS0": "5.47",
      "DipoleMomentS1": "5.47",
      "DipoleMomentT1": "1.15",
      "ionizationPotential": "7.66",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "NVPLGYDRYVYVBZ-UHFFFAOYSA-N",
      "Smiles": "COc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)OC",
      "MolView_url": "http://molview.org/?q=COc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)OC",
      "HOMO": "-5.62",
      "LUMO": "-3.1",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "1.52",
      "E00T1": "1.32",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.19",
      "DipoleMomentS1": "2.19",
      "DipoleMomentT1": "30.86",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "NWFYNCFZQIPJID-AGSASIROSA-N",
      "Smiles": "N#C/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/C#N",
      "HOMO": "-6.32",
      "LUMO": "-3.48",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.65",
      "ET1red": "",
      "DipoleMomentS0": "2.4",
      "DipoleMomentS1": "2.4",
      "DipoleMomentT1": "3.77",
      "ionizationPotential": "7.4",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NWFYNCFZQIPJID-CXTSRPLSSA-N",
      "Smiles": "N#C/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C/C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C/C#N",
      "HOMO": "-6.54",
      "LUMO": "-3.29",
      "VEE": "1.82",
      "Lmax": "681.208791208791",
      "E00S1": "",
      "E00T1": "0.13",
      "Eox": "1.19",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.06",
      "ET1red": "",
      "DipoleMomentS0": "9.21",
      "DipoleMomentS1": "9.21",
      "DipoleMomentT1": "4.91",
      "ionizationPotential": "7.21",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "NWFYNCFZQIPJID-NNRVBYKRSA-N",
      "Smiles": "N#C/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\C#N",
      "HOMO": "-6.29",
      "LUMO": "-3.4",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "0.13",
      "Eox": "1.22",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.09",
      "ET1red": "",
      "DipoleMomentS0": "8.7",
      "DipoleMomentS1": "8.7",
      "DipoleMomentT1": "8.26",
      "ionizationPotential": "7.34",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NWTQWQBAWLAPQC-HNPZLRBASA-N",
      "Smiles": "C/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\C",
      "MolView_url": "http://molview.org/?q=C/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\C",
      "HOMO": "-6.39",
      "LUMO": "-3.16",
      "VEE": "1.52",
      "Lmax": "815.657894736842",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.03",
      "DipoleMomentS1": "3.03",
      "DipoleMomentT1": "3.04",
      "ionizationPotential": "7.28",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "NWTQWQBAWLAPQC-QNWCNZHSSA-N",
      "Smiles": "C/N=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=N/C",
      "MolView_url": "http://molview.org/?q=C/N=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=N/C",
      "HOMO": "-6.39",
      "LUMO": "-3.16",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.03",
      "DipoleMomentS1": "3.03",
      "DipoleMomentT1": "3.04",
      "ionizationPotential": "7.28",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NWTQWQBAWLAPQC-RSQYGXQASA-N",
      "Smiles": "C/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\C",
      "MolView_url": "http://molview.org/?q=C/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\C",
      "HOMO": "-6.36",
      "LUMO": "-3.22",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "",
      "E00T1": "0.9",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.51",
      "ET1red": "",
      "DipoleMomentS0": "3.34",
      "DipoleMomentS1": "3.34",
      "DipoleMomentT1": "2.69",
      "ionizationPotential": "7.18",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NWTVUGLGZMCHKI-UHFFFAOYSA-N",
      "Smiles": "FC1=CC=C(C1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(C1)[C@]1(F)N=N1",
      "HOMO": "-6.52",
      "LUMO": "-2.48",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "2.52",
      "E00T1": "1.9",
      "Eox": "1.61",
      "Ered": "",
      "ES1ox": "-0.91",
      "ES1red": "",
      "ET1ox": "-0.29",
      "ET1red": "",
      "DipoleMomentS0": "1.73",
      "DipoleMomentS1": "1.73",
      "DipoleMomentT1": "9.22",
      "ionizationPotential": "8.19",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "NXEHLTPSELYRLD-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(s1)C#N",
      "HOMO": "-6.95",
      "LUMO": "-3.33",
      "VEE": "2.52",
      "Lmax": "491.984126984127",
      "E00S1": "2.56",
      "E00T1": "1.95",
      "Eox": "2.03",
      "Ered": "",
      "ES1ox": "-0.53",
      "ES1red": "",
      "ET1ox": "0.08",
      "ET1red": "",
      "DipoleMomentS0": "6.16",
      "DipoleMomentS1": "6.16",
      "DipoleMomentT1": "5.73",
      "ionizationPotential": "8.06",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "NXKXAFYQGYROAE-DQRAZIAOSA-N",
      "Smiles": "Fc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)F",
      "HOMO": "-5.77",
      "LUMO": "-2.63",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.81",
      "DipoleMomentS1": "5.81",
      "DipoleMomentT1": "3.02",
      "ionizationPotential": "6.08",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NXKXAFYQGYROAE-QURGRASLSA-N",
      "Smiles": "Fc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)F",
      "HOMO": "-5.45",
      "LUMO": "-2.82",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "0.75",
      "Eox": "0.5",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.25",
      "ET1red": "",
      "DipoleMomentS0": "4.15",
      "DipoleMomentS1": "4.15",
      "DipoleMomentT1": "3.59",
      "ionizationPotential": "6.12",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NXPNMLUFHSVALN-UHFFFAOYSA-N",
      "Smiles": "Sc1csc(n1)c1cc2c3cc(c4scc(n4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)S)cc(c5ccc4)c1scc(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1csc(n1)c1cc2c3cc(c4scc(n4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)S)cc(c5ccc4)c1scc(n1)S",
      "HOMO": "-5.62",
      "LUMO": "-2.88",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.87",
      "E00T1": "0.22",
      "Eox": "-4.32",
      "Ered": "",
      "ES1ox": "-6.19",
      "ES1red": "",
      "ET1ox": "-4.54",
      "ET1red": "",
      "DipoleMomentS0": "2.07",
      "DipoleMomentS1": "2.07",
      "DipoleMomentT1": "1.44",
      "ionizationPotential": "6.31",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "NXTDUJHUOIEOGF-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(o1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(o1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.15",
      "LUMO": "-2.79",
      "VEE": "3.02",
      "Lmax": "410.529801324503",
      "E00S1": "2.8",
      "E00T1": "2.1",
      "Eox": "2.01",
      "Ered": "",
      "ES1ox": "-0.79",
      "ES1red": "",
      "ET1ox": "-0.09",
      "ET1red": "",
      "DipoleMomentS0": "3.51",
      "DipoleMomentS1": "3.51",
      "DipoleMomentT1": "10.44",
      "ionizationPotential": "8.46",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "NYANLMQODXOHCY-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)F)c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)F)c1ccc(o1)F",
      "HOMO": "-5.49",
      "LUMO": "-2.43",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.23",
      "E00T1": "",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "-1.54",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.73",
      "DipoleMomentS1": "8.73",
      "DipoleMomentT1": "9.12",
      "ionizationPotential": "6.2",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "NYMDESIINVCXAW-UHFFFAOYSA-N",
      "Smiles": "SC#Cc1cc2c3cc(C#CS)c4c5c3c(c3c2c2c1cccc2c(c3)C#CS)cc(c5ccc4)C#CS",
      "MolView_url": "http://molview.org/?q=SC#Cc1cc2c3cc(C#CS)c4c5c3c(c3c2c2c1cccc2c(c3)C#CS)cc(c5ccc4)C#CS",
      "HOMO": "-5.36",
      "LUMO": "-2.73",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "1.87",
      "E00T1": "1.09",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "-1.32",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "2.29",
      "DipoleMomentS1": "2.29",
      "DipoleMomentT1": "2.29",
      "ionizationPotential": "6.01",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "NYVRHZVLFVAQMR-ISLYRVAYSA-N",
      "Smiles": "Oc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)O",
      "HOMO": "-5.8",
      "LUMO": "-3.16",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "0.75",
      "Eox": "0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.14",
      "ET1red": "",
      "DipoleMomentS0": "6.91",
      "DipoleMomentS1": "6.91",
      "DipoleMomentT1": "7.18",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "NYVRHZVLFVAQMR-ZCXUNETKSA-N",
      "Smiles": "Oc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)O",
      "HOMO": "-5.8",
      "LUMO": "-3.1",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.66",
      "ET1red": "",
      "DipoleMomentS0": "6.91",
      "DipoleMomentS1": "6.91",
      "DipoleMomentT1": "7.7",
      "ionizationPotential": "6.5",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "NYXJUXLBFPWZBV-JVHCSDPSSA-N",
      "Smiles": "Cl/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/Cl",
      "MolView_url": "http://molview.org/?q=Cl/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/Cl",
      "HOMO": "-5.7",
      "LUMO": "-2.91",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "2.15",
      "E00T1": "1.14",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.32",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "3.7",
      "DipoleMomentS1": "3.7",
      "DipoleMomentT1": "4.16",
      "ionizationPotential": "6.7",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "NYYWTRITLNTWRW-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(o1)C#N)C#N)c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(o1)C#N)C#N)c1ccc(o1)C#N",
      "HOMO": "-6.87",
      "LUMO": "-3.2",
      "VEE": "3.36",
      "Lmax": "368.988095238095",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.72",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "11.89",
      "DipoleMomentS1": "11.89",
      "DipoleMomentT1": "10.46",
      "ionizationPotential": "8.52",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NYZCKEGDULHRHW-QURGRASLSA-N",
      "Smiles": "N#CC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CC#N",
      "MolView_url": "http://molview.org/?q=N#CC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CC#N",
      "HOMO": "-7.04",
      "LUMO": "-3.33",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "8.19",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "NZGPLWNIGWOADS-HSFFGMMNSA-N",
      "Smiles": "N#C/C=C\\c1cc(C#N)cc(c1C#N)/C=C/C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C\\c1cc(C#N)cc(c1C#N)/C=C/C#N",
      "HOMO": "-7.49",
      "LUMO": "-3.45",
      "VEE": "4.02",
      "Lmax": "308.407960199005",
      "E00S1": "",
      "E00T1": "1.77",
      "Eox": "-0.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.19",
      "ET1red": "",
      "DipoleMomentS0": "4.99",
      "DipoleMomentS1": "4.99",
      "DipoleMomentT1": "7.78",
      "ionizationPotential": "9.37",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "NZLQNLRTMWQPAI-WQMFOKDPSA-N",
      "Smiles": "FC(/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C\\C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C\\C(F)(F)F)(F)F",
      "HOMO": "-5.81",
      "LUMO": "-2.83",
      "VEE": "1.67",
      "Lmax": "742.395209580838",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "11.2",
      "DipoleMomentS1": "11.2",
      "DipoleMomentT1": "8.13",
      "ionizationPotential": "6.35",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "NZROCRUBYJFUFL-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(cc1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1ccc(cc1)[C@]1(O)N=N1",
      "HOMO": "-6.6",
      "LUMO": "-2.2",
      "VEE": "3.08",
      "Lmax": "402.532467532468",
      "E00S1": "2.81",
      "E00T1": "",
      "Eox": "1.69",
      "Ered": "",
      "ES1ox": "-1.12",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.67",
      "DipoleMomentS1": "3.67",
      "DipoleMomentT1": "8.81",
      "ionizationPotential": "7.93",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "NZVKOQOJZNWYEO-FMQUCBEESA-N",
      "Smiles": "COC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#COC",
      "MolView_url": "http://molview.org/?q=COC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#COC",
      "HOMO": "-6.12",
      "LUMO": "-2.64",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.39",
      "DipoleMomentS1": "4.39",
      "DipoleMomentT1": "6.94",
      "ionizationPotential": "6.81",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "OABQUKQVPGMFJT-VHXPQNKSSA-N",
      "Smiles": "Cc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)C",
      "HOMO": "-5.76",
      "LUMO": "-2.44",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "0.47",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "7.29",
      "DipoleMomentS1": "7.29",
      "DipoleMomentT1": "5.97",
      "ionizationPotential": "6.42",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OABQUKQVPGMFJT-WCWDXBQESA-N",
      "Smiles": "Cc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)C",
      "HOMO": "-5.77",
      "LUMO": "-2.66",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.15",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "6.11",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "OAPHTAZJCMLJQP-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-5.58",
      "LUMO": "-2.8",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "2.11",
      "E00T1": "1.14",
      "Eox": "-4.34",
      "Ered": "",
      "ES1ox": "-6.45",
      "ES1red": "",
      "ET1ox": "-5.48",
      "ET1red": "",
      "DipoleMomentS0": "3.44",
      "DipoleMomentS1": "3.44",
      "DipoleMomentT1": "3.52",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OAXDNQCQXCCNPD-FLWNBWAVSA-N",
      "Smiles": "FC(c1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-6.38",
      "LUMO": "-3.31",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.12",
      "DipoleMomentS1": "4.12",
      "DipoleMomentT1": "3.49",
      "ionizationPotential": "7.16",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OAXDNQCQXCCNPD-QVIHXGFCSA-N",
      "Smiles": "FC(c1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-6.48",
      "LUMO": "-3.46",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.5",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.32",
      "DipoleMomentS1": "0.32",
      "DipoleMomentT1": "0.7",
      "ionizationPotential": "7.32",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OBKBKQSXMCWIJF-WCWDXBQESA-N",
      "Smiles": "Fc1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)F",
      "HOMO": "-6.41",
      "LUMO": "-2.98",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.59",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.55",
      "DipoleMomentS1": "2.55",
      "DipoleMomentT1": "2.9",
      "ionizationPotential": "7.27",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "OBONPIBGQAKYKA-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cc2c3cc(OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)OC(F)(F)F)cc(c5ccc4)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cc2c3cc(OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)OC(F)(F)F)cc(c5ccc4)OC(F)(F)F)(F)F",
      "HOMO": "-5.95",
      "LUMO": "-2.84",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "2.29",
      "E00T1": "",
      "Eox": "1.1",
      "Ered": "",
      "ES1ox": "-1.19",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.04",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "OBWQJNZQDLADPT-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)OC",
      "HOMO": "-5.32",
      "LUMO": "-2.34",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.32",
      "E00T1": "1.25",
      "Eox": "0.56",
      "Ered": "",
      "ES1ox": "-1.76",
      "ES1red": "",
      "ET1ox": "-0.69",
      "ET1red": "",
      "DipoleMomentS0": "4.07",
      "DipoleMomentS1": "4.07",
      "DipoleMomentT1": "4.33",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OCOBYLQQTNRJJG-UHFFFAOYSA-N",
      "Smiles": "FC(C1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)C(F)(F)F)cc(c5ccc4)C1=CC=C(S1(=O)=O)C(F)(F)F)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)C(F)(F)F)cc(c5ccc4)C1=CC=C(S1(=O)=O)C(F)(F)F)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "HOMO": "-5.98",
      "LUMO": "-3.52",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "1.65",
      "E00T1": "0.24",
      "Eox": "-4.25",
      "Ered": "",
      "ES1ox": "-5.9",
      "ES1red": "",
      "ET1ox": "-4.49",
      "ET1red": "",
      "DipoleMomentS0": "14.66",
      "DipoleMomentS1": "14.66",
      "DipoleMomentT1": "13.26",
      "ionizationPotential": "0.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "OCSFNHXBAPGJNU-QPLCGJKRSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(cc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cc1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(cc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(cc1)[N+](=O)[O-]",
      "HOMO": "-6.54",
      "LUMO": "-3.05",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7",
      "DipoleMomentS1": "7",
      "DipoleMomentT1": "4.93",
      "ionizationPotential": "7.5",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OCTLBRUBJOUMNF-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)S)c1ncc(cn1)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)S)c1ncc(cn1)S",
      "HOMO": "-6.73",
      "LUMO": "-3.22",
      "VEE": "2.49",
      "Lmax": "497.911646586345",
      "E00S1": "2.84",
      "E00T1": "2.16",
      "Eox": "2.1",
      "Ered": "",
      "ES1ox": "-0.74",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "5",
      "DipoleMomentS1": "5",
      "DipoleMomentT1": "17.88",
      "ionizationPotential": "7.72",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ODBFDXSFCJZMSC-QTQNKRQRSA-N",
      "Smiles": "FC(O/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N\\OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N\\OC(F)(F)F)(F)F",
      "HOMO": "-5.71",
      "LUMO": "-2.84",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "2.04",
      "E00T1": "1.19",
      "Eox": "0.88",
      "Ered": "",
      "ES1ox": "-1.16",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "9.1",
      "DipoleMomentS1": "9.1",
      "DipoleMomentT1": "9.82",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "ODFHAZRMMQYUMA-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1csc(n1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1csc(n1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.47",
      "LUMO": "-2.87",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "3.08",
      "E00T1": "",
      "Eox": "2.37",
      "Ered": "",
      "ES1ox": "-0.71",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.53",
      "DipoleMomentS1": "4.53",
      "DipoleMomentT1": "",
      "ionizationPotential": "8.81",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ODFPAXZPIBZLOA-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(cc1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(cc1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-8.05",
      "LUMO": "-2.7",
      "VEE": "3.78",
      "Lmax": "327.989417989418",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.8",
      "DipoleMomentS1": "0.8",
      "DipoleMomentT1": "4.56",
      "ionizationPotential": "9.27",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ODTIDXIHLUBMRA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(o1)F)C#N)c1ncc(o1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(o1)F)C#N)c1ncc(o1)F",
      "HOMO": "-6.87",
      "LUMO": "-3.11",
      "VEE": "3.36",
      "Lmax": "368.988095238095",
      "E00S1": "",
      "E00T1": "2.53",
      "Eox": "-0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.31",
      "ET1red": "",
      "DipoleMomentS0": "1.74",
      "DipoleMomentS1": "1.74",
      "DipoleMomentT1": "3",
      "ionizationPotential": "8.46",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ODVJAZILYYHGGO-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)O",
      "HOMO": "-5.26",
      "LUMO": "-2.42",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.95",
      "E00T1": "1.18",
      "Eox": "0.44",
      "Ered": "",
      "ES1ox": "-1.51",
      "ES1red": "",
      "ET1ox": "-0.74",
      "ET1red": "",
      "DipoleMomentS0": "2.51",
      "DipoleMomentS1": "2.51",
      "DipoleMomentT1": "3.06",
      "ionizationPotential": "5.93",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "ODYMXNQLQHWXCU-UHFFFAOYSA-N",
      "Smiles": "CC#C[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=CC#C[C@]1(C)N=N1",
      "HOMO": "-7.11",
      "LUMO": "-2.09",
      "VEE": "3.15",
      "Lmax": "393.587301587302",
      "E00S1": "3.05",
      "E00T1": "2.44",
      "Eox": "2.18",
      "Ered": "",
      "ES1ox": "-0.87",
      "ES1red": "",
      "ET1ox": "-0.26",
      "ET1red": "",
      "DipoleMomentS0": "3.18",
      "DipoleMomentS1": "3.18",
      "DipoleMomentT1": "6.77",
      "ionizationPotential": "8.54",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "OEAQTIAMKYGQTH-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)[N+](=O)[O-]",
      "HOMO": "-5.8",
      "LUMO": "-3.46",
      "VEE": "1.44",
      "Lmax": "860.972222222222",
      "E00S1": "1.79",
      "E00T1": "1.04",
      "Eox": "0.94",
      "Ered": "",
      "ES1ox": "-0.85",
      "ES1red": "",
      "ET1ox": "-0.1",
      "ET1red": "",
      "DipoleMomentS0": "12.11",
      "DipoleMomentS1": "12.11",
      "DipoleMomentT1": "15.03",
      "ionizationPotential": "6.86",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OERXINNCVZOFPC-QURGRASLSA-N",
      "Smiles": "N#Cc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)C#N",
      "HOMO": "-6.28",
      "LUMO": "-3.53",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "2.02",
      "E00T1": "",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "-0.59",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.88",
      "DipoleMomentS1": "10.88",
      "DipoleMomentT1": "",
      "ionizationPotential": "7.34",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "OFAGIVDWHNKWFV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(C1)OC(F)(F)F)C#N)C1=CC=C(C1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(C1)OC(F)(F)F)C#N)C1=CC=C(C1)OC(F)(F)F",
      "HOMO": "-6.36",
      "LUMO": "-2.56",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "",
      "E00T1": "2.18",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.77",
      "ET1red": "",
      "DipoleMomentS0": "0.96",
      "DipoleMomentS1": "0.96",
      "DipoleMomentT1": "3.07",
      "ionizationPotential": "7.54",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "OFJBMRGKMFXQOC-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(nc1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1ccc(nc1)[C@]1(S)N=N1",
      "HOMO": "-6.62",
      "LUMO": "-2.41",
      "VEE": "3.3",
      "Lmax": "375.69696969697",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.79",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.25",
      "DipoleMomentS1": "5.25",
      "DipoleMomentT1": "8.02",
      "ionizationPotential": "8.02",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "OFJSZMQSSJNTON-OCEACIFDSA-N",
      "Smiles": "N#Cc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)C#N",
      "HOMO": "-6.8",
      "LUMO": "-3.1",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "1.41",
      "Eox": "2.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.6",
      "ET1red": "",
      "DipoleMomentS0": "10.08",
      "DipoleMomentS1": "10.08",
      "DipoleMomentT1": "10.14",
      "ionizationPotential": "7.85",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "OFJSZMQSSJNTON-QPLCGJKRSA-N",
      "Smiles": "N#Cc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)C#N",
      "HOMO": "-6.63",
      "LUMO": "-2.78",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "0.55",
      "Eox": "1.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.99",
      "ET1red": "",
      "DipoleMomentS0": "10.08",
      "DipoleMomentS1": "10.08",
      "DipoleMomentT1": "9.38",
      "ionizationPotential": "7.62",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OFLYEHCQTBWENM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)C#N",
      "HOMO": "-5.57",
      "LUMO": "-2.71",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.98",
      "E00T1": "1.21",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.94",
      "DipoleMomentS1": "6.94",
      "DipoleMomentT1": "7.81",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OFWWCQVPJVJXDZ-FMQUCBEESA-N",
      "Smiles": "Cc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)C",
      "HOMO": "-5.96",
      "LUMO": "-3.17",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "",
      "E00T1": "0.8",
      "Eox": "1.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.22",
      "ET1red": "",
      "DipoleMomentS0": "2.55",
      "DipoleMomentS1": "2.55",
      "DipoleMomentT1": "1.39",
      "ionizationPotential": "6.61",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OFWWCQVPJVJXDZ-VXPUYCOJSA-N",
      "Smiles": "Cc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)C",
      "HOMO": "-5.96",
      "LUMO": "-3.12",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.8",
      "DipoleMomentS1": "10.8",
      "DipoleMomentT1": "8.79",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OGABMUGEMPKQHC-NXVVXOECSA-N",
      "Smiles": "C#Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C#C",
      "MolView_url": "http://molview.org/?q=C#Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C#C",
      "HOMO": "-5.83",
      "LUMO": "-2.6",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "0.36",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.45",
      "ET1red": "",
      "DipoleMomentS0": "1.91",
      "DipoleMomentS1": "1.91",
      "DipoleMomentT1": "3.99",
      "ionizationPotential": "6.66",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "OGITVVRLTFNMAM-BUHFOSPRSA-N",
      "Smiles": "OC#Cc1ccc(o1)/N=N/c1ccc(o1)C#CO",
      "MolView_url": "http://molview.org/?q=OC#Cc1ccc(o1)/N=N/c1ccc(o1)C#CO",
      "HOMO": "-5.93",
      "LUMO": "-2.92",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "",
      "E00T1": "0.78",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.71",
      "DipoleMomentS1": "0.71",
      "DipoleMomentT1": "8.78",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "OGNWBRQHYDYTLA-OCEACIFDSA-N",
      "Smiles": "FC(Oc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-6.27",
      "LUMO": "-3.35",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.46",
      "ET1red": "",
      "DipoleMomentS0": "0.62",
      "DipoleMomentS1": "0.62",
      "DipoleMomentT1": "0.67",
      "ionizationPotential": "7",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OGNWBRQHYDYTLA-QPLCGJKRSA-N",
      "Smiles": "FC(Oc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-6.22",
      "LUMO": "-3.24",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "1.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.96",
      "ET1red": "",
      "DipoleMomentS0": "4.35",
      "DipoleMomentS1": "4.35",
      "DipoleMomentT1": "3.79",
      "ionizationPotential": "6.95",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OGOPTHVOTNETCZ-OCEACIFDSA-N",
      "Smiles": "FC(c1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)C(F)(F)F)(F)F",
      "HOMO": "-5.99",
      "LUMO": "-3.21",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "0.77",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.35",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.9",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OGOPTHVOTNETCZ-QPLCGJKRSA-N",
      "Smiles": "FC(c1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)C(F)(F)F)(F)F",
      "HOMO": "-5.95",
      "LUMO": "-3.13",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.75",
      "ET1red": "",
      "DipoleMomentS0": "6.37",
      "DipoleMomentS1": "6.37",
      "DipoleMomentT1": "7.21",
      "ionizationPotential": "6.81",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OGVRBIAGVLCELS-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)S)cc(c5ccc4)c1ncc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)S)cc(c5ccc4)c1ncc(cn1)S",
      "HOMO": "-5.49",
      "LUMO": "-2.7",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "1.94",
      "E00T1": "0.24",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "-1.23",
      "ES1red": "",
      "ET1ox": "0.47",
      "ET1red": "",
      "DipoleMomentS0": "1.66",
      "DipoleMomentS1": "1.66",
      "DipoleMomentT1": "1.69",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "OGZNDJPWQSMLDC-AROPPZRASA-N",
      "Smiles": "[O-][N+](=O)/C=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=C\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C\\c1ccc(cc1)/N=N\\c1ccc(cc1)/C=C\\[N+](=O)[O-]",
      "HOMO": "-6.78",
      "LUMO": "-3.37",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "1.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.15",
      "ET1red": "",
      "DipoleMomentS0": "8.13",
      "DipoleMomentS1": "8.13",
      "DipoleMomentT1": "5.54",
      "ionizationPotential": "7.89",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OGZNDJPWQSMLDC-TXCZWMRLSA-N",
      "Smiles": "[O-][N+](=O)/C=C/c1ccc(cc1)/N=N/c1ccc(cc1)/C=C\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C/c1ccc(cc1)/N=N/c1ccc(cc1)/C=C\\[N+](=O)[O-]",
      "HOMO": "-6.99",
      "LUMO": "-3.61",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "1.01",
      "Eox": "1.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.92",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "6.61",
      "ionizationPotential": "8.13",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "OISJEJDJQHGLMV-DQRAZIAOSA-N",
      "Smiles": "Sc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1coc(n1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1occ(n1)S",
      "HOMO": "-6.23",
      "LUMO": "-2.69",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "0.54",
      "Eox": "1.38",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.84",
      "ET1red": "",
      "DipoleMomentS0": "1.66",
      "DipoleMomentS1": "1.66",
      "DipoleMomentT1": "0.79",
      "ionizationPotential": "7.05",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OISJEJDJQHGLMV-QURGRASLSA-N",
      "Smiles": "Sc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)S",
      "HOMO": "-6.32",
      "LUMO": "-2.99",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.42",
      "ET1red": "",
      "DipoleMomentS0": "2.71",
      "DipoleMomentS1": "2.71",
      "DipoleMomentT1": "3.15",
      "ionizationPotential": "7.09",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "OIVCIIIOGKTWCM-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(nc1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)[C@]1(Cl)N=N1",
      "HOMO": "-7.44",
      "LUMO": "-2.56",
      "VEE": "3.31",
      "Lmax": "374.561933534743",
      "E00S1": "3.19",
      "E00T1": "2.62",
      "Eox": "2.52",
      "Ered": "",
      "ES1ox": "-0.67",
      "ES1red": "",
      "ET1ox": "-0.1",
      "ET1red": "",
      "DipoleMomentS0": "3.57",
      "DipoleMomentS1": "3.57",
      "DipoleMomentT1": "8.12",
      "ionizationPotential": "8.84",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "OJFBAGYPOFXMPY-DQRAZIAOSA-N",
      "Smiles": "Clc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)Cl",
      "HOMO": "-5.58",
      "LUMO": "-2.85",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.16",
      "DipoleMomentS1": "8.16",
      "DipoleMomentT1": "6.95",
      "ionizationPotential": "6.2",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OJFBAGYPOFXMPY-QURGRASLSA-N",
      "Smiles": "Clc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)Cl",
      "HOMO": "-5.52",
      "LUMO": "-2.87",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "0.79",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.21",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.17",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OJFZBXYBKDZVCB-UHFFFAOYSA-N",
      "Smiles": "Fc1cc2c3cc(F)c4c5c3c(c3c2c2c1cccc2c(c3)F)cc(c5ccc4)F",
      "MolView_url": "http://molview.org/?q=Fc1cc2c3cc(F)c4c5c3c(c3c2c2c1cccc2c(c3)F)cc(c5ccc4)F",
      "HOMO": "-5.65",
      "LUMO": "-2.52",
      "VEE": "3.07",
      "Lmax": "403.843648208469",
      "E00S1": "2.47",
      "E00T1": "1.31",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "-1.57",
      "ES1red": "",
      "ET1ox": "-0.41",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.7",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "OJMDVDGDEDIZET-QPLCGJKRSA-N",
      "Smiles": "c1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccccn1",
      "MolView_url": "http://molview.org/?q=c1ccc(nc1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccccn1",
      "HOMO": "-6.36",
      "LUMO": "-2.5",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "0.56",
      "Eox": "1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.74",
      "ET1red": "",
      "DipoleMomentS0": "8.09",
      "DipoleMomentS1": "8.09",
      "DipoleMomentT1": "6.3",
      "ionizationPotential": "6.91",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OJRFIKKIPCQWKM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1[nH]cc(c1)F)C#N)c1[nH]cc(c1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1[nH]cc(c1)F)C#N)c1[nH]cc(c1)F",
      "HOMO": "-6.21",
      "LUMO": "-2.84",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.72",
      "DipoleMomentS1": "4.72",
      "DipoleMomentT1": "10.11",
      "ionizationPotential": "7.68",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "OJUDDNUXMGCXRM-GFIWHYBVSA-N",
      "Smiles": "O/N=C\\c1cc(C#N)cc(c1C#N)/C=N\\O",
      "MolView_url": "http://molview.org/?q=O/N=C\\c1cc(C#N)cc(c1C#N)/C=N\\O",
      "HOMO": "-7.17",
      "LUMO": "-2.92",
      "VEE": "3.85",
      "Lmax": "322.025974025974",
      "E00S1": "",
      "E00T1": "2.79",
      "Eox": "-0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.71",
      "ET1red": "",
      "DipoleMomentS0": "0.02",
      "DipoleMomentS1": "0.02",
      "DipoleMomentT1": "0.3",
      "ionizationPotential": "8.8",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "OKSVETFNPDSZGL-FMQUCBEESA-N",
      "Smiles": "Fc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)F",
      "HOMO": "-5.57",
      "LUMO": "-2.9",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "1.99",
      "E00T1": "",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "-1.34",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.68",
      "DipoleMomentS1": "1.68",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.41",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "OLAVNXSVZYTRLM-NORIJPHCSA-N",
      "Smiles": "F/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/F",
      "MolView_url": "http://molview.org/?q=F/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N/F",
      "HOMO": "-7.98",
      "LUMO": "-3.54",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "3.04",
      "E00T1": "2.45",
      "Eox": "3.02",
      "Ered": "",
      "ES1ox": "-0.02",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "1.11",
      "DipoleMomentS1": "1.11",
      "DipoleMomentT1": "2.86",
      "ionizationPotential": "9.28",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "OLDPXWMLFMJAPZ-MYUULXRZSA-N",
      "Smiles": "N=Cc1ccc(o1)/N=N\\c1ccc(o1)C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1ccc(o1)/N=N\\c1ccc(o1)C=N",
      "HOMO": "-6.32",
      "LUMO": "-3.19",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.22",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.29",
      "DipoleMomentS1": "3.29",
      "DipoleMomentT1": "2.11",
      "ionizationPotential": "0",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "OLEYQUCYIPBSKM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(C#CC(F)(F)F)cc(cc1C#CC(F)(F)F)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(C#CC(F)(F)F)cc(cc1C#CC(F)(F)F)C#N",
      "HOMO": "-8.03",
      "LUMO": "-3.36",
      "VEE": "4.07",
      "Lmax": "304.619164619165",
      "E00S1": "",
      "E00T1": "3.14",
      "Eox": "-0.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.56",
      "ET1red": "",
      "DipoleMomentS0": "3.58",
      "DipoleMomentS1": "3.58",
      "DipoleMomentT1": "3.59",
      "ionizationPotential": "9.86",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "OLKVKTVHTBTHGV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1coc(n1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1coc(n1)[C@]1(C#N)N=N1",
      "HOMO": "-7.98",
      "LUMO": "-3.1",
      "VEE": "3.49",
      "Lmax": "355.243553008596",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.55",
      "DipoleMomentS1": "2.55",
      "DipoleMomentT1": "7.21",
      "ionizationPotential": "9.79",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "OLRRNOSWDQRJBP-QVIHXGFCSA-N",
      "Smiles": "FC(c1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)C(F)(F)F)(F)F",
      "HOMO": "-5.95",
      "LUMO": "-3.06",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "2.15",
      "E00T1": "0.8",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.5",
      "DipoleMomentS1": "7.5",
      "DipoleMomentT1": "7.36",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "OMFGOXVCAWTIIB-UHFFFAOYSA-N",
      "Smiles": "C/C=C\\c1cc2c3cc(/C=C\\C)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/C)cc(c5ccc4)/C=C\\C",
      "MolView_url": "http://molview.org/?q=C/C=C\\c1cc2c3cc(/C=C\\C)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/C)cc(c5ccc4)/C=C\\C",
      "HOMO": "-5.26",
      "LUMO": "-2.41",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "2",
      "E00T1": "1.14",
      "Eox": "-4.3",
      "Ered": "",
      "ES1ox": "-6.3",
      "ES1red": "",
      "ET1ox": "-5.44",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "",
      "ionizationPotential": "5.94",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "OMKHCMDSVRFLPM-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cn1)C(F)(F)F)c1ccc(cn1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cn1)C(F)(F)F)c1ccc(cn1)C(F)(F)F",
      "HOMO": "-7.37",
      "LUMO": "-3.26",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "2.92",
      "E00T1": "2.52",
      "Eox": "-4.25",
      "Ered": "",
      "ES1ox": "-7.17",
      "ES1red": "",
      "ET1ox": "-6.77",
      "ET1red": "",
      "DipoleMomentS0": "4.1",
      "DipoleMomentS1": "4.1",
      "DipoleMomentT1": "2.8",
      "ionizationPotential": "8.39",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "OMTYYSJPXLZROB-FMQUCBEESA-N",
      "Smiles": "[O-][N+](=O)c1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)[N+](=O)[O-]",
      "HOMO": "-6.09",
      "LUMO": "-3.51",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "0.75",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "0.88",
      "DipoleMomentS1": "0.88",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "7.07",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OMTYYSJPXLZROB-VXPUYCOJSA-N",
      "Smiles": "[O-][N+](=O)c1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)[N+](=O)[O-]",
      "HOMO": "-6.07",
      "LUMO": "-3.42",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "1.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.91",
      "ET1red": "",
      "DipoleMomentS0": "5.55",
      "DipoleMomentS1": "5.55",
      "DipoleMomentT1": "4.06",
      "ionizationPotential": "7.03",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OMWSKKKENYUCBZ-DQRAZIAOSA-N",
      "Smiles": "Fc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)F",
      "HOMO": "-5.55",
      "LUMO": "-2.72",
      "VEE": "2.52",
      "Lmax": "491.984126984127",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "0.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.33",
      "ET1red": "",
      "DipoleMomentS0": "6.95",
      "DipoleMomentS1": "6.95",
      "DipoleMomentT1": "2.17",
      "ionizationPotential": "6.21",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OMWSKKKENYUCBZ-QURGRASLSA-N",
      "Smiles": "Fc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)F",
      "HOMO": "-5.4",
      "LUMO": "-2.84",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "0.74",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.2",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.19",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OMZUCWXJIWIFEJ-UHFFFAOYSA-N",
      "Smiles": "F/C=C\\c1cc2c3cc(/C=C/F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/F)cc(c5ccc4)/C=C/F",
      "MolView_url": "http://molview.org/?q=F/C=C\\c1cc2c3cc(/C=C/F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/F)cc(c5ccc4)/C=C/F",
      "HOMO": "-5.45",
      "LUMO": "-2.58",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "2.03",
      "E00T1": "1.16",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "-1.45",
      "ES1red": "",
      "ET1ox": "-0.58",
      "ET1red": "",
      "DipoleMomentS0": "2.97",
      "DipoleMomentS1": "2.97",
      "DipoleMomentT1": "3.09",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ONOZITHPRCKLAM-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(s1)c1cc2c3cc(c4ncc(s4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)[N+](=O)[O-])cc(c5ccc4)c1ncc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(s1)c1cc2c3cc(c4ncc(s4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)[N+](=O)[O-])cc(c5ccc4)c1ncc(s1)[N+](=O)[O-]",
      "HOMO": "-5.97",
      "LUMO": "-3.62",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "1.61",
      "E00T1": "1.01",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "-0.48",
      "ES1red": "",
      "ET1ox": "0.12",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "0.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ONPGEIUMHHZZCE-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(s1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1ccc(s1)[C@]1(Cl)N=N1",
      "HOMO": "-6.87",
      "LUMO": "-2.63",
      "VEE": "3.47",
      "Lmax": "357.291066282421",
      "E00S1": "2.72",
      "E00T1": "",
      "Eox": "1.91",
      "Ered": "",
      "ES1ox": "-0.81",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.52",
      "DipoleMomentS1": "1.52",
      "DipoleMomentT1": "9.18",
      "ionizationPotential": "8.31",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ONVMGEWESFHGIO-UHFFFAOYSA-N",
      "Smiles": "FC(C1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)C(F)(F)F)(F)F",
      "HOMO": "-5.37",
      "LUMO": "-2.56",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "1.12",
      "Eox": "0.61",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.51",
      "ET1red": "",
      "DipoleMomentS0": "4.6",
      "DipoleMomentS1": "4.6",
      "DipoleMomentT1": "4.11",
      "ionizationPotential": "6.31",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OOIWQHYXIFVQSN-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)C#N",
      "HOMO": "-5.68",
      "LUMO": "-2.99",
      "VEE": "1.44",
      "Lmax": "860.972222222222",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.86",
      "DipoleMomentS1": "4.86",
      "DipoleMomentT1": "5.43",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OOQNYRFJLUIUOV-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-5.45",
      "LUMO": "-2.53",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.15",
      "DipoleMomentS1": "4.15",
      "DipoleMomentT1": "3.64",
      "ionizationPotential": "6.35",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OOXGOVAKHNBCRZ-FMQUCBEESA-N",
      "Smiles": "[O-][N+](=O)c1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)[N+](=O)[O-]",
      "HOMO": "-6.24",
      "LUMO": "-3.63",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "0.77",
      "Eox": "1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.53",
      "ET1red": "",
      "DipoleMomentS0": "1.42",
      "DipoleMomentS1": "1.42",
      "DipoleMomentT1": "1.94",
      "ionizationPotential": "7.37",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OOXGOVAKHNBCRZ-VXPUYCOJSA-N",
      "Smiles": "[O-][N+](=O)c1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)[N+](=O)[O-]",
      "HOMO": "-6.2",
      "LUMO": "-3.55",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "1.14",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.98",
      "ET1red": "",
      "DipoleMomentS0": "1.54",
      "DipoleMomentS1": "1.54",
      "DipoleMomentT1": "1.69",
      "ionizationPotential": "7.31",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OPTSCBXCTOBKCH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)C#N",
      "HOMO": "-5.58",
      "LUMO": "-2.65",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "2.04",
      "E00T1": "1.21",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "13.88",
      "DipoleMomentS1": "13.88",
      "DipoleMomentT1": "13.97",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OQETWTUMGACLDV-UHFFFAOYSA-N",
      "Smiles": "Cl/N=C/c1cc2c3cc(/C=N/Cl)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/Cl)cc(c5ccc4)/C=N/Cl",
      "MolView_url": "http://molview.org/?q=Cl/N=C/c1cc2c3cc(/C=N/Cl)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/Cl)cc(c5ccc4)/C=N/Cl",
      "HOMO": "-5.9",
      "LUMO": "-3.07",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "1.97",
      "E00T1": "",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "-0.94",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.46",
      "DipoleMomentS1": "4.46",
      "DipoleMomentT1": "6.85",
      "ionizationPotential": "6.88",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ORBVYHIUNUJQAJ-UHFFFAOYSA-N",
      "Smiles": "O/C=C\\c1cc2c3cc(/C=C\\O)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O)cc(c5ccc4)/C=C\\O",
      "MolView_url": "http://molview.org/?q=O/C=C\\c1cc2c3cc(/C=C\\O)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O)cc(c5ccc4)/C=C\\O",
      "HOMO": "-5.13",
      "LUMO": "-2.33",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "2.19",
      "E00T1": "1.14",
      "Eox": "0.34",
      "Ered": "",
      "ES1ox": "-1.85",
      "ES1red": "",
      "ET1ox": "-0.8",
      "ET1red": "",
      "DipoleMomentS0": "1.81",
      "DipoleMomentS1": "1.81",
      "DipoleMomentT1": "1.31",
      "ionizationPotential": "5.81",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ORSJDHBHUQFCHD-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)Cl)C1=CC=C(S1(=O)=O)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)Cl)C1=CC=C(S1(=O)=O)Cl",
      "HOMO": "-7.09",
      "LUMO": "-3.5",
      "VEE": "1.65",
      "Lmax": "751.393939393939",
      "E00S1": "2.52",
      "E00T1": "2.12",
      "Eox": "2.17",
      "Ered": "",
      "ES1ox": "-0.35",
      "ES1red": "",
      "ET1ox": "0.05",
      "ET1red": "",
      "DipoleMomentS0": "12.64",
      "DipoleMomentS1": "12.64",
      "DipoleMomentT1": "15.08",
      "ionizationPotential": "8.12",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ORSNSCHVMKEVIR-UHFFFAOYSA-N",
      "Smiles": "COc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)OC",
      "HOMO": "-5.43",
      "LUMO": "-2.5",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.06",
      "E00T1": "1.23",
      "Eox": "0.62",
      "Ered": "",
      "ES1ox": "-1.44",
      "ES1red": "",
      "ET1ox": "-0.61",
      "ET1red": "",
      "DipoleMomentS0": "6.57",
      "DipoleMomentS1": "6.57",
      "DipoleMomentT1": "6.34",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "ORYDEEIICVMFRA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)C#N)cc(c5ccc4)c1ccc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)C#N)cc(c5ccc4)c1ccc([nH]1)C#N",
      "HOMO": "-5.52",
      "LUMO": "-2.75",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "1.94",
      "E00T1": "0.24",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "-1.2",
      "ES1red": "",
      "ET1ox": "0.5",
      "ET1red": "",
      "DipoleMomentS0": "2.57",
      "DipoleMomentS1": "2.57",
      "DipoleMomentT1": "2.09",
      "ionizationPotential": "6.66",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "OSOULSAYBIDZGO-UHFFFAOYSA-N",
      "Smiles": "Cc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)C)cc(c5ccc4)c1nnc(nn1)C",
      "MolView_url": "http://molview.org/?q=Cc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)C)cc(c5ccc4)c1nnc(nn1)C",
      "HOMO": "-5.83",
      "LUMO": "-3.09",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "1.82",
      "E00T1": "0.14",
      "Eox": "-4.3",
      "Ered": "",
      "ES1ox": "-6.12",
      "ES1red": "",
      "ET1ox": "-4.44",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.45",
      "ionizationPotential": "6.42",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "OSWMTBHWIOIBSG-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cnc([nH]1)C#N)C#N)c1cnc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cnc([nH]1)C#N)C#N)c1cnc([nH]1)C#N",
      "HOMO": "-6.91",
      "LUMO": "-3.28",
      "VEE": "3.37",
      "Lmax": "367.893175074184",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.5",
      "DipoleMomentS1": "5.5",
      "DipoleMomentT1": "4.05",
      "ionizationPotential": "8.67",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "OSXYMLXERUPICY-UHFFFAOYSA-N",
      "Smiles": "Oc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)O",
      "MolView_url": "http://molview.org/?q=Oc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)O",
      "HOMO": "-5.19",
      "LUMO": "-2.4",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.93",
      "E00T1": "",
      "Eox": "0.4",
      "Ered": "",
      "ES1ox": "-1.53",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.98",
      "DipoleMomentS1": "2.98",
      "DipoleMomentT1": "1.79",
      "ionizationPotential": "5.92",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OTFHVEJPVINVHT-DQSJHHFOSA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C=O)C#N)C#N",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C=O)C#N)C#N",
      "HOMO": "-6.44",
      "LUMO": "-2.6",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "0.55",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.77",
      "ET1red": "",
      "DipoleMomentS0": "8.21",
      "DipoleMomentS1": "8.21",
      "DipoleMomentT1": "5.42",
      "ionizationPotential": "7.26",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OTFKTKRVGQNEQM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)C#N)c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(o1)C#N)c1ccc(o1)C#N",
      "HOMO": "-5.69",
      "LUMO": "-2.63",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.76",
      "ET1red": "",
      "DipoleMomentS0": "11.92",
      "DipoleMomentS1": "11.92",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.66",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "OUDHZALUTVOHQH-VHXPQNKSSA-N",
      "Smiles": "COc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)OC",
      "HOMO": "-5.54",
      "LUMO": "-2.4",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "0.45",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.23",
      "ET1red": "",
      "DipoleMomentS0": "8.78",
      "DipoleMomentS1": "8.78",
      "DipoleMomentT1": "7.82",
      "ionizationPotential": "6.2",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OUDHZALUTVOHQH-WCWDXBQESA-N",
      "Smiles": "COc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)OC",
      "HOMO": "-5.5",
      "LUMO": "-2.59",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "1.21",
      "Eox": "0.67",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "6.19",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "OUJFUAVRMJIJDR-ISLYRVAYSA-N",
      "Smiles": "Sc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)S",
      "MolView_url": "http://molview.org/?q=Sc1nnc(nn1)c1ccc(cc1)/N=N/c1ccc(cc1)c1nnc(nn1)S",
      "HOMO": "-6.85",
      "LUMO": "-3.21",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.8",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.74",
      "ET1red": "",
      "DipoleMomentS0": "2.44",
      "DipoleMomentS1": "2.44",
      "DipoleMomentT1": "2.81",
      "ionizationPotential": "7.64",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "OUJFUAVRMJIJDR-ZCXUNETKSA-N",
      "Smiles": "Sc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)S",
      "MolView_url": "http://molview.org/?q=Sc1nnc(nn1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1nnc(nn1)S",
      "HOMO": "-6.67",
      "LUMO": "-3.17",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "2.06",
      "E00T1": "1.39",
      "Eox": "1.6",
      "Ered": "",
      "ES1ox": "-0.46",
      "ES1red": "",
      "ET1ox": "0.21",
      "ET1red": "",
      "DipoleMomentS0": "1.97",
      "DipoleMomentS1": "1.97",
      "DipoleMomentT1": "1.76",
      "ionizationPotential": "7.41",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OUOLUUWQHRHMFS-UHFFFAOYSA-N",
      "Smiles": "Oc1csc(n1)c1cc2c3cc(c4scc(n4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)O)cc(c5ccc4)c1scc(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1csc(n1)c1cc2c3cc(c4scc(n4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)O)cc(c5ccc4)c1scc(n1)O",
      "HOMO": "-5.58",
      "LUMO": "-2.87",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.84",
      "E00T1": "1.11",
      "Eox": "-4.27",
      "Ered": "",
      "ES1ox": "-6.11",
      "ES1red": "",
      "ET1ox": "-5.38",
      "ET1red": "",
      "DipoleMomentS0": "1.15",
      "DipoleMomentS1": "1.15",
      "DipoleMomentT1": "0.88",
      "ionizationPotential": "6.31",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "OUXSTDTYBUABTQ-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)Cl)c1ncc(o1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)Cl)c1ncc(o1)Cl",
      "HOMO": "-6.88",
      "LUMO": "-3.33",
      "VEE": "2.22",
      "Lmax": "558.468468468468",
      "E00S1": "2.79",
      "E00T1": "2.25",
      "Eox": "2.29",
      "Ered": "",
      "ES1ox": "-0.5",
      "ES1red": "",
      "ET1ox": "0.04",
      "ET1red": "",
      "DipoleMomentS0": "1.23",
      "DipoleMomentS1": "1.23",
      "DipoleMomentT1": "6.63",
      "ionizationPotential": "7.89",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "OVESNQSEWSQDFH-ISLYRVAYSA-N",
      "Smiles": "N#CC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CC#N",
      "MolView_url": "http://molview.org/?q=N#CC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CC#N",
      "HOMO": "-6.65",
      "LUMO": "-3.68",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "",
      "E00T1": "0.85",
      "Eox": "1.62",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.77",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.81",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OVMJWGVBMXTWNY-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)[N+](=O)[O-]",
      "HOMO": "-6.5",
      "LUMO": "-3.92",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "0.77",
      "Eox": "1.57",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.8",
      "ET1red": "",
      "DipoleMomentS0": "1.16",
      "DipoleMomentS1": "1.16",
      "DipoleMomentT1": "1.35",
      "ionizationPotential": "7.57",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OVMJWGVBMXTWNY-ZCXUNETKSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)[N+](=O)[O-]",
      "HOMO": "-6.43",
      "LUMO": "-3.77",
      "VEE": "2.26",
      "Lmax": "548.58407079646",
      "E00S1": "",
      "E00T1": "0.18",
      "Eox": "1.37",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.19",
      "ET1red": "",
      "DipoleMomentS0": "1.86",
      "DipoleMomentS1": "1.86",
      "DipoleMomentT1": "1.18",
      "ionizationPotential": "7.47",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OVNVUAULXBWRDI-UHFFFAOYSA-N",
      "Smiles": "Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1ccco1)c1c3cc(cc1)c1ccco1)ccc(c5)c1ccco1",
      "MolView_url": "http://molview.org/?q=Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1ccco1)c1c3cc(cc1)c1ccco1)ccc(c5)c1ccco1",
      "HOMO": "-5.49",
      "LUMO": "-2.42",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "2.23",
      "E00T1": "1.59",
      "Eox": "0.67",
      "Ered": "",
      "ES1ox": "-1.56",
      "ES1red": "",
      "ET1ox": "-0.92",
      "ET1red": "",
      "DipoleMomentS0": "8.39",
      "DipoleMomentS1": "8.39",
      "DipoleMomentT1": "9.29",
      "ionizationPotential": "6.11",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "OVUDLBOGJMJQTB-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(n1C)c1cc2c3cc(c4ccc(n4C)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)C#N)cc(c5ccc4)c1ccc(n1C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(n1C)c1cc2c3cc(c4ccc(n4C)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)C#N)cc(c5ccc4)c1ccc(n1C)C#N",
      "HOMO": "-5.55",
      "LUMO": "-2.72",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.14",
      "ionizationPotential": "6.91",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "OXEPRSKVXRIBIP-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)F",
      "HOMO": "-5.27",
      "LUMO": "-2.42",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.19",
      "E00T1": "",
      "Eox": "0.48",
      "Ered": "",
      "ES1ox": "-1.71",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.51",
      "DipoleMomentS1": "2.51",
      "DipoleMomentT1": "1.54",
      "ionizationPotential": "6.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OXKIOYMLWNLBJJ-FMQUCBEESA-N",
      "Smiles": "Cc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)C",
      "HOMO": "-5.46",
      "LUMO": "-2.9",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "0.68",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.13",
      "ET1red": "",
      "DipoleMomentS0": "0.87",
      "DipoleMomentS1": "0.87",
      "DipoleMomentT1": "1.99",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "OXKIOYMLWNLBJJ-VXPUYCOJSA-N",
      "Smiles": "Cc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)C",
      "HOMO": "-5.5",
      "LUMO": "-2.9",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "0.12",
      "Eox": "0.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.35",
      "ET1red": "",
      "DipoleMomentS0": "7.88",
      "DipoleMomentS1": "7.88",
      "DipoleMomentT1": "6.99",
      "ionizationPotential": "6.08",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "OXRCQLJJYWDIIX-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1occ(n1)OC(F)(F)F)C#N)c1occ(n1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1occ(n1)OC(F)(F)F)C#N)c1occ(n1)OC(F)(F)F",
      "HOMO": "-6.94",
      "LUMO": "-3.22",
      "VEE": "3.23",
      "Lmax": "383.839009287926",
      "E00S1": "",
      "E00T1": "2.5",
      "Eox": "-0.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.15",
      "ET1red": "",
      "DipoleMomentS0": "4.76",
      "DipoleMomentS1": "4.76",
      "DipoleMomentT1": "6.08",
      "ionizationPotential": "8.51",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "OXRZQUYVKIGBCH-QURGRASLSA-N",
      "Smiles": "Cc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)C",
      "HOMO": "-5.48",
      "LUMO": "-2.74",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "2.06",
      "E00T1": "",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "-1.48",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.18",
      "DipoleMomentS1": "8.18",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.18",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "OXTFEKYZMFFGGC-QPLCGJKRSA-N",
      "Smiles": "COc1ccc(n1C=O)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C=O)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C=O)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C=O)OC",
      "HOMO": "-5.76",
      "LUMO": "-2.37",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "14.08",
      "DipoleMomentS1": "14.08",
      "DipoleMomentT1": "10.75",
      "ionizationPotential": "6.28",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "OXVYLCYNNKSEOE-UHFFFAOYSA-N",
      "Smiles": "Cc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)C",
      "HOMO": "-5.46",
      "LUMO": "-2.53",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "",
      "E00T1": "1.23",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "5.2",
      "DipoleMomentS1": "5.2",
      "DipoleMomentT1": "4.76",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "OYJOKNMMYUWAPR-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)O)c1ccc(cc1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cc1)O)c1ccc(cc1)O",
      "HOMO": "-6.46",
      "LUMO": "-3.06",
      "VEE": "2.5",
      "Lmax": "495.92",
      "E00S1": "2.66",
      "E00T1": "2.16",
      "Eox": "-4.2",
      "Ered": "",
      "ES1ox": "-6.86",
      "ES1red": "",
      "ET1ox": "-6.36",
      "ET1red": "",
      "DipoleMomentS0": "2.09",
      "DipoleMomentS1": "2.09",
      "DipoleMomentT1": "6.43",
      "ionizationPotential": "7.36",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "OZSJDRWCCCFCAT-UHFFFAOYSA-N",
      "Smiles": "C=C[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=C=C[C@@H]1N=N1",
      "HOMO": "-7.15",
      "LUMO": "-2.13",
      "VEE": "3.22",
      "Lmax": "385.031055900621",
      "E00S1": "3.1",
      "E00T1": "2.44",
      "Eox": "2.25",
      "Ered": "",
      "ES1ox": "-0.85",
      "ES1red": "",
      "ET1ox": "-0.19",
      "ET1red": "",
      "DipoleMomentS0": "2.25",
      "DipoleMomentS1": "2.25",
      "DipoleMomentT1": "5.99",
      "ionizationPotential": "9",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "OZWAPSSRUGNEMS-DQRAZIAOSA-N",
      "Smiles": "Sc1ccc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(s1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(s1)S",
      "HOMO": "-6.1",
      "LUMO": "-2.55",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "0.5",
      "Eox": "0.95",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.45",
      "ET1red": "",
      "DipoleMomentS0": "2.69",
      "DipoleMomentS1": "2.69",
      "DipoleMomentT1": "1.91",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "PANPSQAOXIJIMP-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)F)c1occ(n1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)F)c1occ(n1)F",
      "HOMO": "-7.02",
      "LUMO": "-3.36",
      "VEE": "2.3",
      "Lmax": "539.04347826087",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.52",
      "DipoleMomentS1": "3.52",
      "DipoleMomentT1": "7.87",
      "ionizationPotential": "8.12",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "PBFSRJWAYNIEPO-UHFFFAOYSA-N",
      "Smiles": "ClC1=CC=C(S1(=O)=O)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(S1(=O)=O)[C@]1(Cl)N=N1",
      "HOMO": "-7.46",
      "LUMO": "-3.02",
      "VEE": "3.44",
      "Lmax": "360.406976744186",
      "E00S1": "2.94",
      "E00T1": "1.58",
      "Eox": "2.51",
      "Ered": "",
      "ES1ox": "-0.43",
      "ES1red": "",
      "ET1ox": "0.93",
      "ET1red": "",
      "DipoleMomentS0": "7.04",
      "DipoleMomentS1": "7.04",
      "DipoleMomentT1": "7.26",
      "ionizationPotential": "8.89",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "PCLMOZGOEGOTJN-UHFFFAOYSA-N",
      "Smiles": "OC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)O)cc(c5ccc4)C1=CC=C(S1(=O)=O)O)C1=CC=C(S1(=O)=O)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)O)cc(c5ccc4)C1=CC=C(S1(=O)=O)O)C1=CC=C(S1(=O)=O)O",
      "HOMO": "-5.67",
      "LUMO": "-3.01",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "1.82",
      "E00T1": "0.24",
      "Eox": "-4.32",
      "Ered": "",
      "ES1ox": "-6.14",
      "ES1red": "",
      "ET1ox": "-4.56",
      "ET1red": "",
      "DipoleMomentS0": "7.32",
      "DipoleMomentS1": "7.32",
      "DipoleMomentT1": "5.9",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "PDDAEYJQLOTAAU-UHFFFAOYSA-N",
      "Smiles": "Cc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)C",
      "HOMO": "-5.47",
      "LUMO": "-2.61",
      "VEE": "1.52",
      "Lmax": "815.657894736842",
      "E00S1": "1.95",
      "E00T1": "",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "-0.91",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.49",
      "DipoleMomentS1": "0.49",
      "DipoleMomentT1": "0.73",
      "ionizationPotential": "6.15",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "PDVCEHOSZTWIBM-DQRAZIAOSA-N",
      "Smiles": "COc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)OC",
      "MolView_url": "http://molview.org/?q=COc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)OC",
      "HOMO": "-5.4",
      "LUMO": "-2.72",
      "VEE": "2.57",
      "Lmax": "482.412451361868",
      "E00S1": "",
      "E00T1": "0.05",
      "Eox": "0.34",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.29",
      "ET1red": "",
      "DipoleMomentS0": "6.58",
      "DipoleMomentS1": "6.58",
      "DipoleMomentT1": "1.48",
      "ionizationPotential": "5.96",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "PDVCEHOSZTWIBM-QURGRASLSA-N",
      "Smiles": "COc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)OC",
      "MolView_url": "http://molview.org/?q=COc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)OC",
      "HOMO": "-5.25",
      "LUMO": "-2.82",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "0.67",
      "Eox": "0.36",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "2.1",
      "DipoleMomentS1": "2.1",
      "DipoleMomentT1": "1.62",
      "ionizationPotential": "5.91",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "PEAXTMNZUNEBAH-UHFFFAOYSA-N",
      "Smiles": "c1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncccn1",
      "MolView_url": "http://molview.org/?q=c1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncccn1",
      "HOMO": "-5.5",
      "LUMO": "-2.57",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.05",
      "E00T1": "1.22",
      "Eox": "0.69",
      "Ered": "",
      "ES1ox": "-1.36",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "3.78",
      "DipoleMomentS1": "3.78",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.16",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "PFBRYRKOINJWPD-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)Cl",
      "HOMO": "-5.48",
      "LUMO": "-2.53",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.27",
      "E00T1": "",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "-1.61",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.05",
      "DipoleMomentS1": "0.05",
      "DipoleMomentT1": "0.22",
      "ionizationPotential": "6.44",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "PFBUXVUJDDHWIH-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(nc1)c1cc2c3cc(c4ncc(cn4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)C(F)(F)F)cc(c5ccc4)c1ncc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(nc1)c1cc2c3cc(c4ncc(cn4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)C(F)(F)F)cc(c5ccc4)c1ncc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-5.71",
      "LUMO": "-2.98",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "1.9",
      "E00T1": "0.22",
      "Eox": "1.26",
      "Ered": "",
      "ES1ox": "-0.64",
      "ES1red": "",
      "ET1ox": "1.04",
      "ET1red": "",
      "DipoleMomentS0": "0.27",
      "DipoleMomentS1": "0.27",
      "DipoleMomentT1": "0.35",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "PFNPYWXCQRQSCM-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(nc1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(cn1)C(F)(F)F)c1ncc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(nc1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(cn1)C(F)(F)F)c1ncc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-5.74",
      "LUMO": "-2.72",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.62",
      "DipoleMomentS1": "5.62",
      "DipoleMomentT1": "5.77",
      "ionizationPotential": "6.54",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "PFXOMWSJOIYHLA-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)[N+](=O)[O-])cc(c5ccc4)c1[nH]cc(c1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)[N+](=O)[O-])cc(c5ccc4)c1[nH]cc(c1)[N+](=O)[O-]",
      "HOMO": "-5.59",
      "LUMO": "-2.78",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "1.98",
      "E00T1": "1.18",
      "Eox": "-4.3",
      "Ered": "",
      "ES1ox": "-6.28",
      "ES1red": "",
      "ET1ox": "-5.48",
      "ET1red": "",
      "DipoleMomentS0": "15.3",
      "DipoleMomentS1": "15.3",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.83",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "PGZGZMMUFGTCKT-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)[N+](=O)[O-])c1cnc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cnc([nH]1)[N+](=O)[O-])c1cnc([nH]1)[N+](=O)[O-]",
      "HOMO": "-7.16",
      "LUMO": "-3.51",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "2.87",
      "E00T1": "2.28",
      "Eox": "2.25",
      "Ered": "",
      "ES1ox": "-0.62",
      "ES1red": "",
      "ET1ox": "-0.03",
      "ET1red": "",
      "DipoleMomentS0": "8.37",
      "DipoleMomentS1": "8.37",
      "DipoleMomentT1": "5.44",
      "ionizationPotential": "8.43",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "PIKWSLLRPAJIDA-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)O)c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1occ(n1)O)c1occ(n1)O",
      "HOMO": "-6.6",
      "LUMO": "-3.31",
      "VEE": "2.09",
      "Lmax": "593.205741626794",
      "E00S1": "2.36",
      "E00T1": "2.07",
      "Eox": "1.96",
      "Ered": "",
      "ES1ox": "-0.4",
      "ES1red": "",
      "ET1ox": "-0.11",
      "ET1red": "",
      "DipoleMomentS0": "0.48",
      "DipoleMomentS1": "0.48",
      "DipoleMomentT1": "5.42",
      "ionizationPotential": "7.59",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "PJDRKFLCSCGOHC-UHFFFAOYSA-N",
      "Smiles": "CC1=CC=C(C1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(C1)[C@]1(C)N=N1",
      "HOMO": "-5.95",
      "LUMO": "-1.95",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "2.51",
      "E00T1": "1.89",
      "Eox": "0.99",
      "Ered": "",
      "ES1ox": "-1.52",
      "ES1red": "",
      "ET1ox": "-0.9",
      "ET1red": "",
      "DipoleMomentS0": "2.12",
      "DipoleMomentS1": "2.12",
      "DipoleMomentT1": "9.09",
      "ionizationPotential": "7.34",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "PKGGHRJFUOLIGP-DQRAZIAOSA-N",
      "Smiles": "Oc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)O",
      "HOMO": "-5.8",
      "LUMO": "-2.93",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "0.8",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.6",
      "ET1red": "",
      "DipoleMomentS0": "12.05",
      "DipoleMomentS1": "12.05",
      "DipoleMomentT1": "10.01",
      "ionizationPotential": "6.33",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "PKGGHRJFUOLIGP-QURGRASLSA-N",
      "Smiles": "Oc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)O",
      "HOMO": "-5.82",
      "LUMO": "-3.01",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "",
      "E00T1": "0.82",
      "Eox": "0.94",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.12",
      "ET1red": "",
      "DipoleMomentS0": "3.63",
      "DipoleMomentS1": "3.63",
      "DipoleMomentT1": "3.64",
      "ionizationPotential": "6.45",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "PKUXZMQQBPAVAS-UHFFFAOYSA-N",
      "Smiles": "COc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)OC",
      "MolView_url": "http://molview.org/?q=COc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)OC",
      "HOMO": "-5.18",
      "LUMO": "-2.39",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.93",
      "E00T1": "1.18",
      "Eox": "0.37",
      "Ered": "",
      "ES1ox": "-1.56",
      "ES1red": "",
      "ET1ox": "-0.81",
      "ET1red": "",
      "DipoleMomentS0": "4.23",
      "DipoleMomentS1": "4.23",
      "DipoleMomentT1": "3.39",
      "ionizationPotential": "5.84",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "PLCRMFDVYCNTMM-UHFFFAOYSA-N",
      "Smiles": "Cn1c(S)ccc1c1cc2c3cc(c4ccc(n4C)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)S)cc(c5ccc4)c1ccc(n1C)S",
      "MolView_url": "http://molview.org/?q=Cn1c(S)ccc1c1cc2c3cc(c4ccc(n4C)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)S)cc(c5ccc4)c1ccc(n1C)S",
      "HOMO": "-5.34",
      "LUMO": "-2.52",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "1.97",
      "E00T1": "1.57",
      "Eox": "-4.25",
      "Ered": "",
      "ES1ox": "-6.22",
      "ES1red": "",
      "ET1ox": "-5.82",
      "ET1red": "",
      "DipoleMomentS0": "4.41",
      "DipoleMomentS1": "4.41",
      "DipoleMomentT1": "4.44",
      "ionizationPotential": "0.31",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "PLXJSNRWUZXPGO-ISLYRVAYSA-N",
      "Smiles": "Sc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)S",
      "HOMO": "-6",
      "LUMO": "-3.19",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "2.12",
      "E00T1": "0.83",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.04",
      "DipoleMomentS1": "2.04",
      "DipoleMomentT1": "1.97",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "PMMYVBVYOSLPJM-UHFFFAOYSA-N",
      "Smiles": "Clc1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)Cl)c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)Cl)c1[nH]cc(c1)Cl",
      "HOMO": "-5.47",
      "LUMO": "-2.4",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "0.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.54",
      "ET1red": "",
      "DipoleMomentS0": "7.82",
      "DipoleMomentS1": "7.82",
      "DipoleMomentT1": "8.07",
      "ionizationPotential": "6.25",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "PMQNRRVPLPHKKT-ISLYRVAYSA-N",
      "Smiles": "Oc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)O",
      "HOMO": "-5.3",
      "LUMO": "-2.84",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "0.66",
      "Eox": "0.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.24",
      "ET1red": "",
      "DipoleMomentS0": "3.18",
      "DipoleMomentS1": "3.18",
      "DipoleMomentT1": "4.54",
      "ionizationPotential": "5.99",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "PMQNRRVPLPHKKT-ZCXUNETKSA-N",
      "Smiles": "Oc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)O",
      "HOMO": "-5.35",
      "LUMO": "-2.84",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "0.11",
      "Eox": "0.35",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.24",
      "ET1red": "",
      "DipoleMomentS0": "6.68",
      "DipoleMomentS1": "6.68",
      "DipoleMomentT1": "6.53",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "PMXXIAYXYJOWJB-ISLYRVAYSA-N",
      "Smiles": "Sc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)S",
      "HOMO": "-5.59",
      "LUMO": "-2.9",
      "VEE": "2.59",
      "Lmax": "478.687258687259",
      "E00S1": "2",
      "E00T1": "0.73",
      "Eox": "0.52",
      "Ered": "",
      "ES1ox": "-1.48",
      "ES1red": "",
      "ET1ox": "-0.21",
      "ET1red": "",
      "DipoleMomentS0": "2.61",
      "DipoleMomentS1": "2.61",
      "DipoleMomentT1": "4.38",
      "ionizationPotential": "6.13",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "PNBBGZBNNRZKLS-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1scc(n1)C(F)(F)F)C#N)c1scc(n1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1scc(n1)C(F)(F)F)C#N)c1scc(n1)C(F)(F)F",
      "HOMO": "-7.2",
      "LUMO": "-3.35",
      "VEE": "3.81",
      "Lmax": "325.406824146982",
      "E00S1": "",
      "E00T1": "2.58",
      "Eox": "-0.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.22",
      "ET1red": "",
      "DipoleMomentS0": "8.2",
      "DipoleMomentS1": "8.2",
      "DipoleMomentT1": "8.46",
      "ionizationPotential": "8.79",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "PNESMCZHXNIPBB-UHFFFAOYSA-N",
      "Smiles": "Oc1csc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1scc(n1)O)c1scc(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1csc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1scc(n1)O)c1scc(n1)O",
      "HOMO": "-5.65",
      "LUMO": "-2.61",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.2",
      "E00T1": "",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "-1.39",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.74",
      "DipoleMomentS1": "5.74",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.4",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "PNPYUROZPQBSRT-OCEACIFDSA-N",
      "Smiles": "N#Cc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)C#N",
      "HOMO": "-6.69",
      "LUMO": "-3.11",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.87",
      "ET1red": "",
      "DipoleMomentS0": "12.9",
      "DipoleMomentS1": "12.9",
      "DipoleMomentT1": "11.69",
      "ionizationPotential": "7.72",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "PNSJJABKOWERCW-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)O",
      "HOMO": "-5.41",
      "LUMO": "-2.58",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.92",
      "E00T1": "1.16",
      "Eox": "0.61",
      "Ered": "",
      "ES1ox": "-1.31",
      "ES1red": "",
      "ET1ox": "-0.55",
      "ET1red": "",
      "DipoleMomentS0": "1.79",
      "DipoleMomentS1": "1.79",
      "DipoleMomentT1": "2.11",
      "ionizationPotential": "6.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "PNTHJSBCHUYZLS-UHFFFAOYSA-N",
      "Smiles": "Cc1c[nH]c(c1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1c[nH]c(c1)[C@]1(C)N=N1",
      "HOMO": "-6.04",
      "LUMO": "-2.05",
      "VEE": "3.07",
      "Lmax": "403.843648208469",
      "E00S1": "2.48",
      "E00T1": "1.91",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "-1.35",
      "ES1red": "",
      "ET1ox": "-0.78",
      "ET1red": "",
      "DipoleMomentS0": "1.25",
      "DipoleMomentS1": "1.25",
      "DipoleMomentT1": "8.8",
      "ionizationPotential": "7.42",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "POCFCUBDCNXOFQ-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(s1)c1cc2c3cc(c4ncc(s4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)O)cc(c5ccc4)c1ncc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(s1)c1cc2c3cc(c4ncc(s4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)O)cc(c5ccc4)c1ncc(s1)O",
      "HOMO": "-5.45",
      "LUMO": "-2.75",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "1.86",
      "E00T1": "1.11",
      "Eox": "0.63",
      "Ered": "",
      "ES1ox": "-1.23",
      "ES1red": "",
      "ET1ox": "-0.48",
      "ET1red": "",
      "DipoleMomentS0": "3.64",
      "DipoleMomentS1": "3.64",
      "DipoleMomentT1": "3.66",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "POPAGRONNAPESZ-WCWDXBQESA-N",
      "Smiles": "Fc1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)F",
      "HOMO": "-5.95",
      "LUMO": "-3.02",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "2.18",
      "E00T1": "0.82",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.17",
      "DipoleMomentS1": "4.17",
      "DipoleMomentT1": "5.05",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "PPFFBKPJNFHSAG-QVIHXGFCSA-N",
      "Smiles": "FC(c1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-6.18",
      "LUMO": "-3.21",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.78",
      "Eox": "1.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.47",
      "ET1red": "",
      "DipoleMomentS0": "5.17",
      "DipoleMomentS1": "5.17",
      "DipoleMomentT1": "5.18",
      "ionizationPotential": "7.09",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "PPIRZLJGRZUALY-DQRAZIAOSA-N",
      "Smiles": "Sc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)S",
      "HOMO": "-5.71",
      "LUMO": "-2.91",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.67",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.72",
      "DipoleMomentS1": "4.72",
      "DipoleMomentT1": "4.25",
      "ionizationPotential": "6.31",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "PQFJIVGPYCGDAY-FMQUCBEESA-N",
      "Smiles": "Cn1cccc1c1ccc(o1)/N=N/c1ccc(o1)c1cccn1C",
      "MolView_url": "http://molview.org/?q=Cn1cccc1c1ccc(o1)/N=N/c1ccc(o1)c1cccn1C",
      "HOMO": "-5.18",
      "LUMO": "-2.64",
      "VEE": "2.57",
      "Lmax": "482.412451361868",
      "E00S1": "1.88",
      "E00T1": "0.69",
      "Eox": "0.3",
      "Ered": "",
      "ES1ox": "-1.58",
      "ES1red": "",
      "ET1ox": "-0.39",
      "ET1red": "",
      "DipoleMomentS0": "3.23",
      "DipoleMomentS1": "3.23",
      "DipoleMomentT1": "4.41",
      "ionizationPotential": "5.9",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "PQWQTSGWGHIIEG-ONYWOVPASA-N",
      "Smiles": "F/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/F",
      "MolView_url": "http://molview.org/?q=F/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N/F",
      "HOMO": "-5.77",
      "LUMO": "-2.92",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.47",
      "DipoleMomentS1": "5.47",
      "DipoleMomentT1": "5.59",
      "ionizationPotential": "6.86",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "PQXUCUCTSUEGPK-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc([nH]1)O)c1ccc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc([nH]1)O)c1ccc([nH]1)O",
      "HOMO": "-5.16",
      "LUMO": "-2.28",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "2.08",
      "E00T1": "0.11",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.12",
      "DipoleMomentS1": "10.12",
      "DipoleMomentT1": "10.72",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "PRIFUROIHQCEJE-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)[N+](=O)[O-]",
      "HOMO": "-5.53",
      "LUMO": "-3.24",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "1.78",
      "E00T1": "0.95",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.06",
      "ES1red": "",
      "ET1ox": "-0.23",
      "ET1red": "",
      "DipoleMomentS0": "9.93",
      "DipoleMomentS1": "9.93",
      "DipoleMomentT1": "13.16",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "PRJIXWHDYYHPMQ-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)[N+](=O)[O-])cc(c5ccc4)C1=CC=C(S1(=O)=O)[N+](=O)[O-])C1=CC=C(S1(=O)=O)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)[N+](=O)[O-])cc(c5ccc4)C1=CC=C(S1(=O)=O)[N+](=O)[O-])C1=CC=C(S1(=O)=O)[N+](=O)[O-]",
      "HOMO": "-6.15",
      "LUMO": "-4.19",
      "VEE": "2.57",
      "Lmax": "482.412451361868",
      "E00S1": "1.27",
      "E00T1": "0.18",
      "Eox": "-4.35",
      "Ered": "",
      "ES1ox": "-5.62",
      "ES1red": "",
      "ET1ox": "-4.53",
      "ET1red": "",
      "DipoleMomentS0": "10.05",
      "DipoleMomentS1": "10.05",
      "DipoleMomentT1": "5.61",
      "ionizationPotential": "0.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "PRZWELOWDZZMCZ-UHFFFAOYSA-N",
      "Smiles": "COc1cnc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(o1)OC",
      "HOMO": "-6.28",
      "LUMO": "-3.2",
      "VEE": "1.99",
      "Lmax": "623.015075376884",
      "E00S1": "2.4",
      "E00T1": "1.91",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.35",
      "DipoleMomentS1": "4.35",
      "DipoleMomentT1": "8.19",
      "ionizationPotential": "7.18",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "PSLZCHOHZVCTKM-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(s1)c1cc2c3cc(c4ccc(s4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)[N+](=O)[O-])cc(c5ccc4)c1ccc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(s1)c1cc2c3cc(c4ccc(s4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)[N+](=O)[O-])cc(c5ccc4)c1ccc(s1)[N+](=O)[O-]",
      "HOMO": "-5.79",
      "LUMO": "-3.37",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "1.66",
      "E00T1": "1.06",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "-0.7",
      "ES1red": "",
      "ET1ox": "-0.1",
      "ET1red": "",
      "DipoleMomentS0": "4.74",
      "DipoleMomentS1": "4.74",
      "DipoleMomentT1": "3.63",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "PSRIQMPOAYVQQQ-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)[N+](=O)[O-])c1ccc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(s1)[N+](=O)[O-])c1ccc(s1)[N+](=O)[O-]",
      "HOMO": "-7.18",
      "LUMO": "-3.54",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "2.58",
      "E00T1": "2.22",
      "Eox": "2.25",
      "Ered": "",
      "ES1ox": "-0.33",
      "ES1red": "",
      "ET1ox": "0.03",
      "ET1red": "",
      "DipoleMomentS0": "8.02",
      "DipoleMomentS1": "8.02",
      "DipoleMomentT1": "6.1",
      "ionizationPotential": "8.32",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "PSYLCHYIOWELFL-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c[nH]c(c1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1c[nH]c(c1)[C@]1(C#N)N=N1",
      "HOMO": "-7.05",
      "LUMO": "-2.84",
      "VEE": "3.81",
      "Lmax": "325.406824146982",
      "E00S1": "2.74",
      "E00T1": "",
      "Eox": "2.13",
      "Ered": "",
      "ES1ox": "-0.61",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.89",
      "DipoleMomentS1": "3.89",
      "DipoleMomentT1": "7.74",
      "ionizationPotential": "8.86",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "PTAIPHSRRMOGMA-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cccs1)c1cccs1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cccs1)c1cccs1",
      "HOMO": "-6.54",
      "LUMO": "-3.13",
      "VEE": "2.53",
      "Lmax": "490.0395256917",
      "E00S1": "2.63",
      "E00T1": "2.04",
      "Eox": "1.71",
      "Ered": "",
      "ES1ox": "-0.92",
      "ES1red": "",
      "ET1ox": "-0.33",
      "ET1red": "",
      "DipoleMomentS0": "2.32",
      "DipoleMomentS1": "2.32",
      "DipoleMomentT1": "6.02",
      "ionizationPotential": "7.46",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "PTDQXCQKCYDWCN-UHFFFAOYSA-N",
      "Smiles": "OC#C[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=OC#C[C@]1(O)N=N1",
      "HOMO": "-7.27",
      "LUMO": "-2.25",
      "VEE": "3.17",
      "Lmax": "391.104100946372",
      "E00S1": "2.98",
      "E00T1": "2.22",
      "Eox": "2.14",
      "Ered": "",
      "ES1ox": "-0.84",
      "ES1red": "",
      "ET1ox": "-0.08",
      "ET1red": "",
      "DipoleMomentS0": "5.14",
      "DipoleMomentS1": "5.14",
      "DipoleMomentT1": "9.93",
      "ionizationPotential": "8.65",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "PTHJBCVBJNKSLY-ISLYRVAYSA-N",
      "Smiles": "SC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)S",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)S",
      "HOMO": "-6.07",
      "LUMO": "-3.63",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "0.47",
      "Eox": "1.05",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.58",
      "ET1red": "",
      "DipoleMomentS0": "0.62",
      "DipoleMomentS1": "0.62",
      "DipoleMomentT1": "1.02",
      "ionizationPotential": "6.74",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "PTHJBCVBJNKSLY-ZCXUNETKSA-N",
      "Smiles": "SC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)S",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)S",
      "HOMO": "-6.22",
      "LUMO": "-3.48",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "-0.14",
      "Eox": "0.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.61",
      "ET1red": "",
      "DipoleMomentS0": "6.79",
      "DipoleMomentS1": "6.79",
      "DipoleMomentT1": "8.65",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "PTYCMIIDOVNFJW-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)[N+](=O)[O-]",
      "HOMO": "-7.01",
      "LUMO": "-3.59",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "1.04",
      "Eox": "1.88",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.84",
      "ET1red": "",
      "DipoleMomentS0": "0.01",
      "DipoleMomentS1": "0.01",
      "DipoleMomentT1": "6.36",
      "ionizationPotential": "8.03",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "PUCWBIWRYUVOBN-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)C(F)(F)F)(F)F",
      "HOMO": "-5.62",
      "LUMO": "-2.81",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.92",
      "E00T1": "",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "-1.08",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.83",
      "DipoleMomentS1": "6.83",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "PUKRSFISJKNLPG-UHFFFAOYSA-N",
      "Smiles": "SC#C[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=SC#C[C@]1(S)N=N1",
      "HOMO": "-7.02",
      "LUMO": "-2.48",
      "VEE": "3.38",
      "Lmax": "366.804733727811",
      "E00S1": "3.07",
      "E00T1": "2.19",
      "Eox": "2.02",
      "Ered": "",
      "ES1ox": "-1.05",
      "ES1red": "",
      "ET1ox": "-0.17",
      "ET1red": "",
      "DipoleMomentS0": "2.57",
      "DipoleMomentS1": "2.57",
      "DipoleMomentT1": "9.02",
      "ionizationPotential": "8.44",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "PUUGWYMDTCHYNU-FMQUCBEESA-N",
      "Smiles": "Clc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)Cl",
      "HOMO": "-5.75",
      "LUMO": "-3.06",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "",
      "E00T1": "0.71",
      "Eox": "0.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.06",
      "ET1red": "",
      "DipoleMomentS0": "2.17",
      "DipoleMomentS1": "2.17",
      "DipoleMomentT1": "3.1",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "PUUGWYMDTCHYNU-VXPUYCOJSA-N",
      "Smiles": "Clc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)Cl",
      "HOMO": "-5.76",
      "LUMO": "-3.04",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.12",
      "Eox": "0.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.58",
      "ET1red": "",
      "DipoleMomentS0": "5.64",
      "DipoleMomentS1": "5.64",
      "DipoleMomentT1": "5.31",
      "ionizationPotential": "6.43",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "PUYAHTQIPHBIHY-VIPGHUNISA-N",
      "Smiles": "FC(/N=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N\\C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/N=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N\\C(F)(F)F)(F)F",
      "HOMO": "-5.84",
      "LUMO": "-3.09",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.94",
      "E00T1": "1.14",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "-0.94",
      "ES1red": "",
      "ET1ox": "-0.14",
      "ET1red": "",
      "DipoleMomentS0": "6.53",
      "DipoleMomentS1": "6.53",
      "DipoleMomentT1": "7.36",
      "ionizationPotential": "6.95",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "PVPDQNNTDWHODP-SBXLLLBQSA-N",
      "Smiles": "C/C=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/C",
      "MolView_url": "http://molview.org/?q=C/C=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/C",
      "HOMO": "-6.09",
      "LUMO": "-2.64",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "4.48",
      "ionizationPotential": "6.88",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "PWDIGGBJHKNYRL-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(o1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(o1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-8.04",
      "LUMO": "-3",
      "VEE": "3.57",
      "Lmax": "347.282913165266",
      "E00S1": "3.45",
      "E00T1": "",
      "Eox": "3.12",
      "Ered": "",
      "ES1ox": "-0.33",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.61",
      "DipoleMomentS1": "1.61",
      "DipoleMomentT1": "6.94",
      "ionizationPotential": "9.68",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "PWEMZVNUCQIDDW-VHXPQNKSSA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)C#N)C#N",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)C#N)C#N",
      "HOMO": "-6.47",
      "LUMO": "-3.04",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.95",
      "DipoleMomentS1": "8.95",
      "DipoleMomentT1": "9.89",
      "ionizationPotential": "7.13",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "PWEMZVNUCQIDDW-WCWDXBQESA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)C#N)C#N",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)C#N)C#N",
      "HOMO": "-6.24",
      "LUMO": "-3.31",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "0.86",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.43",
      "ET1red": "",
      "DipoleMomentS0": "6.48",
      "DipoleMomentS1": "6.48",
      "DipoleMomentT1": "4.97",
      "ionizationPotential": "7.28",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "PWQDOXXFWMIDOV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(s1)Cl)C#N)c1ncc(s1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(s1)Cl)C#N)c1ncc(s1)Cl",
      "HOMO": "-6.77",
      "LUMO": "-3.24",
      "VEE": "3.43",
      "Lmax": "361.457725947522",
      "E00S1": "",
      "E00T1": "2.34",
      "Eox": "-0.8",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.14",
      "ET1red": "",
      "DipoleMomentS0": "3.58",
      "DipoleMomentS1": "3.58",
      "DipoleMomentT1": "4.92",
      "ionizationPotential": "8.17",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "PWSOXMIAJFSXQL-UHFFFAOYSA-N",
      "Smiles": "N#Cc1nnc(nn1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1nnc(nn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1nnc(nn1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1nnc(nn1)C#N",
      "HOMO": "-7.69",
      "LUMO": "-3.79",
      "VEE": "1.55",
      "Lmax": "799.870967741936",
      "E00S1": "2.09",
      "E00T1": "1.41",
      "Eox": "2.82",
      "Ered": "",
      "ES1ox": "0.73",
      "ES1red": "",
      "ET1ox": "1.41",
      "ET1red": "",
      "DipoleMomentS0": "6.86",
      "DipoleMomentS1": "6.86",
      "DipoleMomentT1": "6.95",
      "ionizationPotential": "8.82",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "PYDMUIZFUSMTAD-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)Cl)c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)Cl)c1[nH]cc(c1)Cl",
      "HOMO": "-6.24",
      "LUMO": "-3.1",
      "VEE": "2.13",
      "Lmax": "582.06572769953",
      "E00S1": "2.43",
      "E00T1": "1.9",
      "Eox": "1.49",
      "Ered": "",
      "ES1ox": "-0.94",
      "ES1red": "",
      "ET1ox": "-0.41",
      "ET1red": "",
      "DipoleMomentS0": "6.29",
      "DipoleMomentS1": "6.29",
      "DipoleMomentT1": "1.53",
      "ionizationPotential": "7.23",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "PYYPVSAFLLQGLD-UHFFFAOYSA-N",
      "Smiles": "C=Cc1cc2c3cc(C=C)c4c5c3c(c3c2c2c1cccc2c(c3)C=C)cc(c5ccc4)C=C",
      "MolView_url": "http://molview.org/?q=C=Cc1cc2c3cc(C=C)c4c5c3c(c3c2c2c1cccc2c(c3)C=C)cc(c5ccc4)C=C",
      "HOMO": "-5.37",
      "LUMO": "-2.53",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "2.21",
      "E00T1": "1.12",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "-1.63",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "0.04",
      "DipoleMomentS1": "0.04",
      "DipoleMomentT1": "0.03",
      "ionizationPotential": "6.16",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "PZBQQGVJESPBJZ-FMQUCBEESA-N",
      "Smiles": "COC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)OC",
      "HOMO": "-5.93",
      "LUMO": "-3.42",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.98",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "11.77",
      "DipoleMomentS1": "11.77",
      "DipoleMomentT1": "10.84",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "PZBQQGVJESPBJZ-VXPUYCOJSA-N",
      "Smiles": "COC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)OC",
      "HOMO": "-6.13",
      "LUMO": "-3.32",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.71",
      "DipoleMomentS1": "8.71",
      "DipoleMomentT1": "7.16",
      "ionizationPotential": "6.51",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "PZDKQXAPVLXBQE-WCWDXBQESA-N",
      "Smiles": "COc1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)OC",
      "HOMO": "-6.08",
      "LUMO": "-2.87",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.24",
      "ET1red": "",
      "DipoleMomentS0": "1.14",
      "DipoleMomentS1": "1.14",
      "DipoleMomentT1": "6.29",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "PZOMTUSIDMWLSK-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1scc(n1)F)C#N)c1scc(n1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1scc(n1)F)C#N)c1scc(n1)F",
      "HOMO": "-6.88",
      "LUMO": "-3.3",
      "VEE": "3.58",
      "Lmax": "346.312849162011",
      "E00S1": "",
      "E00T1": "2.35",
      "Eox": "-0.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.03",
      "ET1red": "",
      "DipoleMomentS0": "6.96",
      "DipoleMomentS1": "6.96",
      "DipoleMomentT1": "7.89",
      "ionizationPotential": "8.41",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "PZWDQIDWDHHOJO-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(s1)C#N)C#N)c1ccc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(s1)C#N)C#N)c1ccc(s1)C#N",
      "HOMO": "-7",
      "LUMO": "-3.25",
      "VEE": "3.56",
      "Lmax": "348.258426966292",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.1",
      "DipoleMomentS1": "3.1",
      "DipoleMomentT1": "3.92",
      "ionizationPotential": "8.64",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "QAWGRQFZNYGQPP-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)C",
      "HOMO": "-5.16",
      "LUMO": "-2.37",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.92",
      "E00T1": "1.16",
      "Eox": "0.35",
      "Ered": "",
      "ES1ox": "-1.57",
      "ES1red": "",
      "ET1ox": "-0.81",
      "ET1red": "",
      "DipoleMomentS0": "4.33",
      "DipoleMomentS1": "4.33",
      "DipoleMomentT1": "5.25",
      "ionizationPotential": "5.83",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "QBNBRNLPBNZAAZ-DQRAZIAOSA-N",
      "Smiles": "O=Cn1c(Cl)ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)Cl",
      "MolView_url": "http://molview.org/?q=O=Cn1c(Cl)ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)Cl",
      "HOMO": "-6.38",
      "LUMO": "-2.86",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "0.26",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.48",
      "ET1red": "",
      "DipoleMomentS0": "4.65",
      "DipoleMomentS1": "4.65",
      "DipoleMomentT1": "5.52",
      "ionizationPotential": "6.66",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QBNBRNLPBNZAAZ-QURGRASLSA-N",
      "Smiles": "O=Cn1c(Cl)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)Cl",
      "MolView_url": "http://molview.org/?q=O=Cn1c(Cl)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)Cl",
      "HOMO": "-5.92",
      "LUMO": "-3.1",
      "VEE": "3.11",
      "Lmax": "398.649517684887",
      "E00S1": "",
      "E00T1": "0.82",
      "Eox": "0.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QBWVHCZJRQSVJC-YDJGGUBZSA-N",
      "Smiles": "Cl/C=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=C\\Cl)ccc2-c([n+]31)c4)Cl)/C=C\\Cl",
      "MolView_url": "http://molview.org/?q=Cl/C=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=C\\Cl)ccc2-c([n+]31)c4)Cl)/C=C\\Cl",
      "HOMO": "-5.61",
      "LUMO": "-2.52",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.75",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.7",
      "DipoleMomentS1": "5.7",
      "DipoleMomentT1": "6.62",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "QCPJFKWHSVIKSY-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(C#COC(F)(F)F)cc(cc1C#COC(F)(F)F)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(C#COC(F)(F)F)cc(cc1C#COC(F)(F)F)C#N",
      "HOMO": "-7.28",
      "LUMO": "-2.97",
      "VEE": "3.79",
      "Lmax": "327.12401055409",
      "E00S1": "",
      "E00T1": "2.9",
      "Eox": "-0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.74",
      "ET1red": "",
      "DipoleMomentS0": "0.42",
      "DipoleMomentS1": "0.42",
      "DipoleMomentT1": "1.07",
      "ionizationPotential": "8.87",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "QDANKVPKEYSTBP-UHFFFAOYSA-N",
      "Smiles": "Cn1cccc1c1cc2c3cc(c4cccn4C)c4c5c3c(c3c2c2c1cccc2c(c3)c1cccn1C)cc(c5ccc4)c1cccn1C",
      "MolView_url": "http://molview.org/?q=Cn1cccc1c1cc2c3cc(c4cccn4C)c4c5c3c(c3c2c2c1cccc2c(c3)c1cccn1C)cc(c5ccc4)c1cccn1C",
      "HOMO": "-5.41",
      "LUMO": "-2.48",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "2.04",
      "E00T1": "0.26",
      "Eox": "-4.1",
      "Ered": "",
      "ES1ox": "-6.14",
      "ES1red": "",
      "ET1ox": "-4.36",
      "ET1red": "",
      "DipoleMomentS0": "4.48",
      "DipoleMomentS1": "4.48",
      "DipoleMomentT1": "",
      "ionizationPotential": "0.17",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "QDBYDSIVBQETJI-UHFFFAOYSA-N",
      "Smiles": "COc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)OC",
      "HOMO": "-5.41",
      "LUMO": "-2.58",
      "VEE": "1.52",
      "Lmax": "815.657894736842",
      "E00S1": "2.14",
      "E00T1": "1.16",
      "Eox": "0.62",
      "Ered": "",
      "ES1ox": "-1.52",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "2.58",
      "DipoleMomentS1": "2.58",
      "DipoleMomentT1": "2.84",
      "ionizationPotential": "5.99",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "QDUWWKZTRRDTAW-UHFFFAOYSA-N",
      "Smiles": "FC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)F)cc(c5ccc4)C1=CC=C(S1(=O)=O)F)C1=CC=C(S1(=O)=O)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)F)cc(c5ccc4)C1=CC=C(S1(=O)=O)F)C1=CC=C(S1(=O)=O)F",
      "HOMO": "-5.82",
      "LUMO": "-3.19",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "1.8",
      "E00T1": "0.25",
      "Eox": "-4.31",
      "Ered": "",
      "ES1ox": "-6.11",
      "ES1red": "",
      "ET1ox": "-4.56",
      "ET1red": "",
      "DipoleMomentS0": "12.62",
      "DipoleMomentS1": "12.62",
      "DipoleMomentT1": "11.21",
      "ionizationPotential": "0.04",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "QEULNLXZPVRYQQ-UHFFFAOYSA-N",
      "Smiles": "Cl[B@@-]12n3c4cc5n1c(cc1-c6c(-c([n+]21)cc3c1c4ccc(c1)c1ccccn1)ccc(c6)c1ccccn1)c1c5cc(cc1)c1ccccn1",
      "MolView_url": "http://molview.org/?q=Cl[B@@-]12n3c4cc5n1c(cc1-c6c(-c([n+]21)cc3c1c4ccc(c1)c1ccccn1)ccc(c6)c1ccccn1)c1c5cc(cc1)c1ccccn1",
      "HOMO": "-5.58",
      "LUMO": "-2.44",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "2.29",
      "E00T1": "1.61",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.6",
      "DipoleMomentS1": "10.6",
      "DipoleMomentT1": "11.47",
      "ionizationPotential": "6.12",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "QFMKQKSDUFYTCI-UHFFFAOYSA-N",
      "Smiles": "Clc1csc(n1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1csc(n1)[C@]1(Cl)N=N1",
      "HOMO": "-7.29",
      "LUMO": "-2.74",
      "VEE": "3.25",
      "Lmax": "381.476923076923",
      "E00S1": "3.06",
      "E00T1": "2.47",
      "Eox": "2.33",
      "Ered": "",
      "ES1ox": "-0.73",
      "ES1red": "",
      "ET1ox": "-0.14",
      "ET1red": "",
      "DipoleMomentS0": "4.3",
      "DipoleMomentS1": "4.3",
      "DipoleMomentT1": "9.89",
      "ionizationPotential": "8.77",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "QFWUVTRPOKBFOH-UHFFFAOYSA-N",
      "Smiles": "N#C/C=C/c1cc2c3cc(/C=C/C#N)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/C#N)cc(c5ccc4)/C=C/C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C/c1cc2c3cc(/C=C/C#N)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/C#N)cc(c5ccc4)/C=C/C#N",
      "HOMO": "-5.78",
      "LUMO": "-3.19",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "1.75",
      "E00T1": "0.98",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "-0.78",
      "ES1red": "",
      "ET1ox": "-0.01",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.88",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "QGQYVSSHFUNKGC-DQRAZIAOSA-N",
      "Smiles": "N#Cc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)C#N",
      "HOMO": "-6.03",
      "LUMO": "-3.27",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.49",
      "DipoleMomentS1": "3.49",
      "DipoleMomentT1": "5.65",
      "ionizationPotential": "6.97",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QGQYVSSHFUNKGC-QURGRASLSA-N",
      "Smiles": "N#Cc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)C#N",
      "HOMO": "-6.05",
      "LUMO": "-3.34",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "",
      "E00T1": "0.74",
      "Eox": "1.14",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.4",
      "ET1red": "",
      "DipoleMomentS0": "0.97",
      "DipoleMomentS1": "0.97",
      "DipoleMomentT1": "1.47",
      "ionizationPotential": "7.03",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QHFUNOVCFOWNCA-OCEACIFDSA-N",
      "Smiles": "FC(OC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)OC(F)(F)F)(F)F",
      "HOMO": "-6.08",
      "LUMO": "-3.56",
      "VEE": "2.54",
      "Lmax": "488.110236220472",
      "E00S1": "1.86",
      "E00T1": "0.42",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "12.35",
      "DipoleMomentS1": "12.35",
      "DipoleMomentT1": "10.63",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "QHHSXCOTCPSOQX-MHDGOOOCSA-N",
      "Smiles": "N=Cc1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(C=N)ccc2-c([n+]31)c4)Cl)C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(C=N)ccc2-c([n+]31)c4)Cl)C=N",
      "HOMO": "-5.79",
      "LUMO": "-2.67",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "2.28",
      "E00T1": "1.6",
      "Eox": "0.89",
      "Ered": "",
      "ES1ox": "-1.39",
      "ES1red": "",
      "ET1ox": "-0.71",
      "ET1red": "",
      "DipoleMomentS0": "4.04",
      "DipoleMomentS1": "4.04",
      "DipoleMomentT1": "5.41",
      "ionizationPotential": "6.84",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "QHSOOYGDUFCIRX-OCEACIFDSA-N",
      "Smiles": "FC(c1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)C(F)(F)F)(F)F",
      "HOMO": "-5.87",
      "LUMO": "-3.13",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "2.02",
      "E00T1": "0.71",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "-1.06",
      "ES1red": "",
      "ET1ox": "0.25",
      "ET1red": "",
      "DipoleMomentS0": "7.2",
      "DipoleMomentS1": "7.2",
      "DipoleMomentT1": "7.63",
      "ionizationPotential": "6.81",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "QHSWFGIEBBTFOB-QVIHXGFCSA-N",
      "Smiles": "FC(c1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)C(F)(F)F)(F)F",
      "HOMO": "-6.66",
      "LUMO": "-3.08",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.51",
      "DipoleMomentS1": "9.51",
      "DipoleMomentT1": "8.56",
      "ionizationPotential": "7.61",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "QHXJTHRKHCDWJZ-UHFFFAOYSA-N",
      "Smiles": "FC(c1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)C(F)(F)F)cc(c5ccc4)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)C(F)(F)F)cc(c5ccc4)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "HOMO": "-5.44",
      "LUMO": "-2.61",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "1.98",
      "E00T1": "1.17",
      "Eox": "0.77",
      "Ered": "",
      "ES1ox": "-1.21",
      "ES1red": "",
      "ET1ox": "-0.4",
      "ET1red": "",
      "DipoleMomentS0": "6.12",
      "DipoleMomentS1": "6.12",
      "DipoleMomentT1": "7.2",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "QJDIQVSCGKMXPM-UQTXLMIWSA-N",
      "Smiles": "F/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\F",
      "MolView_url": "http://molview.org/?q=F/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\F",
      "HOMO": "-5.91",
      "LUMO": "-2.94",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.09",
      "DipoleMomentS1": "4.09",
      "DipoleMomentT1": "3.89",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QJDIQVSCGKMXPM-XHVJHNBZSA-N",
      "Smiles": "F/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/F",
      "MolView_url": "http://molview.org/?q=F/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/F",
      "HOMO": "-5.91",
      "LUMO": "-2.99",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.94",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.84",
      "DipoleMomentS1": "0.84",
      "DipoleMomentT1": "0.88",
      "ionizationPotential": "6.84",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QJEUUMAROGGDCR-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(cc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cc1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(cc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cc1)OC",
      "HOMO": "-6.39",
      "LUMO": "-3.05",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.63",
      "E00T1": "2.13",
      "Eox": "1.69",
      "Ered": "",
      "ES1ox": "-0.94",
      "ES1red": "",
      "ET1ox": "-0.44",
      "ET1red": "",
      "DipoleMomentS0": "4.7",
      "DipoleMomentS1": "4.7",
      "DipoleMomentT1": "10.45",
      "ionizationPotential": "7.21",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "QJKVVTSMHSFFIO-FLWNBWAVSA-N",
      "Smiles": "FC(Oc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-6.31",
      "LUMO": "-3.23",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.22",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.35",
      "DipoleMomentS1": "5.35",
      "DipoleMomentT1": "4.76",
      "ionizationPotential": "7.03",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QJKVVTSMHSFFIO-QVIHXGFCSA-N",
      "Smiles": "FC(Oc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-6.4",
      "LUMO": "-3.36",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "",
      "E00T1": "0.86",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.55",
      "ET1red": "",
      "DipoleMomentS0": "1.12",
      "DipoleMomentS1": "1.12",
      "DipoleMomentT1": "2.8",
      "ionizationPotential": "7.17",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QJSGEGQXIFELTR-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)OC(F)(F)F)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)OC(F)(F)F)OC(F)(F)F",
      "HOMO": "-6.78",
      "LUMO": "-3.2",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "2.49",
      "E00T1": "1.95",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.92",
      "DipoleMomentS1": "6.92",
      "DipoleMomentT1": "9.5",
      "ionizationPotential": "7.73",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "QJSGVFGZLFOLDY-BBKYZYOHSA-N",
      "Smiles": "S/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C/S",
      "MolView_url": "http://molview.org/?q=S/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C/S",
      "HOMO": "-5.54",
      "LUMO": "-2.89",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.48",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.11",
      "DipoleMomentS1": "9.11",
      "DipoleMomentT1": "7.31",
      "ionizationPotential": "6.27",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QJSGVFGZLFOLDY-XLLIXGIFSA-N",
      "Smiles": "S/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/S",
      "MolView_url": "http://molview.org/?q=S/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/S",
      "HOMO": "-5.63",
      "LUMO": "-2.95",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "",
      "E00T1": "0.72",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.02",
      "ET1red": "",
      "DipoleMomentS0": "2.75",
      "DipoleMomentS1": "2.75",
      "DipoleMomentT1": "1.72",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QJUQJENBCHEYPA-UHFFFAOYSA-N",
      "Smiles": "Oc1c[nH]c(c1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1c[nH]c(c1)[C@]1(O)N=N1",
      "HOMO": "-6.02",
      "LUMO": "-2.21",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "2.32",
      "E00T1": "1.72",
      "Eox": "1.09",
      "Ered": "",
      "ES1ox": "-1.23",
      "ES1red": "",
      "ET1ox": "-0.63",
      "ET1red": "",
      "DipoleMomentS0": "2.77",
      "DipoleMomentS1": "2.77",
      "DipoleMomentT1": "7.43",
      "ionizationPotential": "7.45",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "QKPRSBRZLAKJAK-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc([nH]1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc([nH]1)C(F)(F)F)(F)F",
      "HOMO": "-6.55",
      "LUMO": "-3.19",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "2.59",
      "E00T1": "",
      "Eox": "1.69",
      "Ered": "",
      "ES1ox": "-0.9",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.64",
      "DipoleMomentS1": "3.64",
      "DipoleMomentT1": "0.94",
      "ionizationPotential": "7.64",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "QKRKTRGOINPVNU-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)F)c1ccc([nH]1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)F)c1ccc([nH]1)F",
      "HOMO": "-6.07",
      "LUMO": "-3.07",
      "VEE": "2",
      "Lmax": "619.9",
      "E00S1": "2.26",
      "E00T1": "1.78",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.39",
      "DipoleMomentS1": "1.39",
      "DipoleMomentT1": "5.84",
      "ionizationPotential": "7.12",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "QKYLSUBBLFIOOP-UHFFFAOYSA-N",
      "Smiles": "FC#C[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=FC#C[C@]1(F)N=N1",
      "HOMO": "-7.98",
      "LUMO": "-2.73",
      "VEE": "3.18",
      "Lmax": "389.874213836478",
      "E00S1": "3.18",
      "E00T1": "",
      "Eox": "3.13",
      "Ered": "",
      "ES1ox": "-0.05",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.95",
      "DipoleMomentS1": "1.95",
      "DipoleMomentT1": "4.66",
      "ionizationPotential": "9.92",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "QLWOASKHWLOFCL-WCWDXBQESA-N",
      "Smiles": "Cc1ccc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(s1)C",
      "HOMO": "-5.92",
      "LUMO": "-2.71",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.98",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "5.19",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "QMARPWPZFNSQRR-BYYHNAKLSA-N",
      "Smiles": "FC(c1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "HOMO": "-5.71",
      "LUMO": "-2.97",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.75",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.05",
      "DipoleMomentS1": "8.05",
      "DipoleMomentT1": "8.29",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QMARPWPZFNSQRR-DQSJHHFOSA-N",
      "Smiles": "FC(c1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)C(F)(F)F)(F)F",
      "HOMO": "-5.92",
      "LUMO": "-2.82",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "-0.23",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.95",
      "ET1red": "",
      "DipoleMomentS0": "10.35",
      "DipoleMomentS1": "10.35",
      "DipoleMomentT1": "11.25",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QNCYQJFSWILAJE-FMQUCBEESA-N",
      "Smiles": "Oc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)O",
      "MolView_url": "http://molview.org/?q=Oc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)O",
      "HOMO": "-5.29",
      "LUMO": "-2.84",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "0.69",
      "Eox": "0.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.27",
      "ET1red": "",
      "DipoleMomentS0": "1.01",
      "DipoleMomentS1": "1.01",
      "DipoleMomentT1": "1.07",
      "ionizationPotential": "6.02",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QNCYQJFSWILAJE-VXPUYCOJSA-N",
      "Smiles": "Oc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)O",
      "MolView_url": "http://molview.org/?q=Oc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)O",
      "HOMO": "-5.37",
      "LUMO": "-2.83",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "",
      "E00T1": "0.15",
      "Eox": "0.33",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.18",
      "ET1red": "",
      "DipoleMomentS0": "10.84",
      "DipoleMomentS1": "10.84",
      "DipoleMomentT1": "9.06",
      "ionizationPotential": "6.05",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QNPGEIQDVNNHKR-FMQUCBEESA-N",
      "Smiles": "c1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc[nH]1",
      "MolView_url": "http://molview.org/?q=c1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc[nH]1",
      "HOMO": "-5.39",
      "LUMO": "-2.82",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "0.75",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.17",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.11",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QNPGEIQDVNNHKR-VXPUYCOJSA-N",
      "Smiles": "c1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc[nH]1",
      "MolView_url": "http://molview.org/?q=c1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc[nH]1",
      "HOMO": "-5.41",
      "LUMO": "-2.81",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.38",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.43",
      "DipoleMomentS1": "3.43",
      "DipoleMomentT1": "3.6",
      "ionizationPotential": "6.08",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QNQPWNLHWFUZHO-DQRAZIAOSA-N",
      "Smiles": "N#Cc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)C#N",
      "HOMO": "-6.01",
      "LUMO": "-3.25",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "",
      "E00T1": "0.16",
      "Eox": "0.98",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.82",
      "ET1red": "",
      "DipoleMomentS0": "7.11",
      "DipoleMomentS1": "7.11",
      "DipoleMomentT1": "9.35",
      "ionizationPotential": "6.95",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QNQPWNLHWFUZHO-QURGRASLSA-N",
      "Smiles": "N#Cc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)C#N",
      "HOMO": "-6.05",
      "LUMO": "-3.33",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.36",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.04",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QNSGMSIYAGLROC-FMQUCBEESA-N",
      "Smiles": "Clc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)Cl",
      "HOMO": "-5.63",
      "LUMO": "-2.93",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "2",
      "E00T1": "0.74",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "QNWOQZDYGZWNDX-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc([nH]1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1ccc([nH]1)[C@]1(O)N=N1",
      "HOMO": "-5.84",
      "LUMO": "-2.21",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "2.17",
      "E00T1": "1.58",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "-1.21",
      "ES1red": "",
      "ET1ox": "-0.62",
      "ET1red": "",
      "DipoleMomentS0": "2.68",
      "DipoleMomentS1": "2.68",
      "DipoleMomentT1": "10.71",
      "ionizationPotential": "7.26",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "QOQZVBAINVSLOB-QURGRASLSA-N",
      "Smiles": "Clc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)Cl",
      "HOMO": "-5.66",
      "LUMO": "-2.87",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "2.09",
      "E00T1": "0.79",
      "Eox": "0.8",
      "Ered": "",
      "ES1ox": "-1.29",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "QOWJGUXBHWFTCX-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(F)ccc1c1cc2c3cc(c4ccc(n4C=O)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)F)cc(c5ccc4)c1ccc(n1C=O)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(F)ccc1c1cc2c3cc(c4ccc(n4C=O)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)F)cc(c5ccc4)c1ccc(n1C=O)F",
      "HOMO": "-5.68",
      "LUMO": "-2.75",
      "VEE": "2.96",
      "Lmax": "418.851351351351",
      "E00S1": "2.07",
      "E00T1": "",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.24",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.83",
      "DipoleMomentS1": "4.83",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.67",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "QPTOCXDUQXOJIU-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(s1)Cl)c1ncc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(s1)Cl)c1ncc(s1)Cl",
      "HOMO": "-5.69",
      "LUMO": "-2.64",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "2.22",
      "E00T1": "1.6",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.5",
      "DipoleMomentS1": "6.5",
      "DipoleMomentT1": "8.48",
      "ionizationPotential": "6.5",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "QPTZFMQMXPAMTJ-UHFFFAOYSA-N",
      "Smiles": "ClC1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=ClC1(Cl)N=N1",
      "HOMO": "-8.34",
      "LUMO": "-2.92",
      "VEE": "3.28",
      "Lmax": "377.987804878049",
      "E00S1": "3.26",
      "E00T1": "2.66",
      "Eox": "3.35",
      "Ered": "",
      "ES1ox": "0.09",
      "ES1red": "",
      "ET1ox": "0.69",
      "ET1red": "",
      "DipoleMomentS0": "0.44",
      "DipoleMomentS1": "0.44",
      "DipoleMomentT1": "2.79",
      "ionizationPotential": "10.18",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "QQGLHCHHQVIELG-WCWDXBQESA-N",
      "Smiles": "Fc1ccc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(s1)F",
      "HOMO": "-6.08",
      "LUMO": "-2.75",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "1.19",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.12",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.69",
      "ionizationPotential": "6.89",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "QRSPOUGKAVHYPG-DQRAZIAOSA-N",
      "Smiles": "N#Cc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)C#N",
      "HOMO": "-6.76",
      "LUMO": "-3.87",
      "VEE": "2.24",
      "Lmax": "553.482142857143",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "1.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.49",
      "ET1red": "",
      "DipoleMomentS0": "2.26",
      "DipoleMomentS1": "2.26",
      "DipoleMomentT1": "0.72",
      "ionizationPotential": "7.92",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QRSPOUGKAVHYPG-QURGRASLSA-N",
      "Smiles": "N#Cc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)C#N",
      "HOMO": "-6.87",
      "LUMO": "-4.01",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "0.88",
      "Eox": "1.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.03",
      "ET1red": "",
      "DipoleMomentS0": "8.76",
      "DipoleMomentS1": "8.76",
      "DipoleMomentT1": "9.46",
      "ionizationPotential": "8.08",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QRWMCFRMTINLNG-UHFFFAOYSA-N",
      "Smiles": "Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1ncc(o1)C(F)(F)F)c1c3cc(cc1)c1ncc(o1)C(F)(F)F)ccc(c5)c1ncc(o1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=Cl[B@@-]12[n+]3c4-c5c(-c3cc3n1c(cc1n2c(c4)c2c1cc(cc2)c1ncc(o1)C(F)(F)F)c1c3cc(cc1)c1ncc(o1)C(F)(F)F)ccc(c5)c1ncc(o1)C(F)(F)F",
      "HOMO": "-5.8",
      "LUMO": "-2.7",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "2.26",
      "E00T1": "",
      "Eox": "0.95",
      "Ered": "",
      "ES1ox": "-1.31",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.95",
      "DipoleMomentS1": "4.95",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "QRWYOWPZJRRWPK-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(s1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(s1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-7.85",
      "LUMO": "-3.44",
      "VEE": "4",
      "Lmax": "309.95",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.93",
      "DipoleMomentS1": "5.93",
      "DipoleMomentT1": "8.8",
      "ionizationPotential": "9.49",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "QSGMVWDRSQJNJL-UHFFFAOYSA-N",
      "Smiles": "OC#Cc1cc2c3cc(C#CO)c4c5c3c(c3c2c2c1cccc2c(c3)C#CO)cc(c5ccc4)C#CO",
      "MolView_url": "http://molview.org/?q=OC#Cc1cc2c3cc(C#CO)c4c5c3c(c3c2c2c1cccc2c(c3)C#CO)cc(c5ccc4)C#CO",
      "HOMO": "-5.26",
      "LUMO": "-2.53",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.7",
      "DipoleMomentS1": "3.7",
      "DipoleMomentT1": "3.52",
      "ionizationPotential": "5.96",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "QSYYUWWIFMAGBS-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)[N+](=O)[O-]",
      "HOMO": "-6.61",
      "LUMO": "-3.44",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "1.02",
      "Eox": "1.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.63",
      "ET1red": "",
      "DipoleMomentS0": "12.92",
      "DipoleMomentS1": "12.92",
      "DipoleMomentT1": "12.37",
      "ionizationPotential": "7.62",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "QTFYIAXTAWUAGH-QJUDHZBZSA-N",
      "Smiles": "C/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N\\C",
      "MolView_url": "http://molview.org/?q=C/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N\\C",
      "HOMO": "-7.44",
      "LUMO": "-3.28",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.32",
      "DipoleMomentS1": "2.32",
      "DipoleMomentT1": "4.15",
      "ionizationPotential": "8.28",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "QTOOCQZEWRGGPF-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)O",
      "HOMO": "-5.44",
      "LUMO": "-2.5",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.24",
      "E00T1": "",
      "Eox": "0.64",
      "Ered": "",
      "ES1ox": "-1.6",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.08",
      "DipoleMomentS1": "5.08",
      "DipoleMomentT1": "4.95",
      "ionizationPotential": "6.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "QWPRHUWBPRQXAL-UHFFFAOYSA-N",
      "Smiles": "N#Cc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)C#N",
      "HOMO": "-5.61",
      "LUMO": "-2.77",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.95",
      "E00T1": "1.18",
      "Eox": "0.79",
      "Ered": "",
      "ES1ox": "-1.16",
      "ES1red": "",
      "ET1ox": "-0.39",
      "ET1red": "",
      "DipoleMomentS0": "12.94",
      "DipoleMomentS1": "12.94",
      "DipoleMomentT1": "14.59",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "QWZWABUSTMBZOI-QURGRASLSA-N",
      "Smiles": "Oc1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)O",
      "HOMO": "-6.09",
      "LUMO": "-2.88",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "",
      "E00T1": "1.04",
      "Eox": "1.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.2",
      "ET1red": "",
      "DipoleMomentS0": "1.08",
      "DipoleMomentS1": "1.08",
      "DipoleMomentT1": "7.67",
      "ionizationPotential": "6.84",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "QXGXDJYVBDHGBW-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)C#N",
      "HOMO": "-5.43",
      "LUMO": "-2.5",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.05",
      "E00T1": "1.22",
      "Eox": "0.64",
      "Ered": "",
      "ES1ox": "-1.41",
      "ES1red": "",
      "ET1ox": "-0.58",
      "ET1red": "",
      "DipoleMomentS0": "12.74",
      "DipoleMomentS1": "12.74",
      "DipoleMomentT1": "12.43",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "QXOAIEXQKVHRAO-UHFFFAOYSA-N",
      "Smiles": "Oc1coc(n1)c1cc2c3cc(c4occ(n4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)O)cc(c5ccc4)c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1coc(n1)c1cc2c3cc(c4occ(n4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)O)cc(c5ccc4)c1occ(n1)O",
      "HOMO": "-5.58",
      "LUMO": "-2.98",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "1.79",
      "E00T1": "0.22",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "-1.05",
      "ES1red": "",
      "ET1ox": "0.52",
      "ET1red": "",
      "DipoleMomentS0": "0.26",
      "DipoleMomentS1": "0.26",
      "DipoleMomentT1": "0.33",
      "ionizationPotential": "0.04",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "QXPFEOLYXHXNLZ-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)[N+](=O)[O-]",
      "HOMO": "-5.64",
      "LUMO": "-3.18",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "1.72",
      "E00T1": "",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "-0.91",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "15.78",
      "DipoleMomentS1": "15.78",
      "DipoleMomentT1": "19.21",
      "ionizationPotential": "6.83",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "QXXCIXLCGUQNJY-DQRAZIAOSA-N",
      "Smiles": "Clc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)Cl",
      "HOMO": "-5.81",
      "LUMO": "-3",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "1.04",
      "DipoleMomentS1": "1.04",
      "DipoleMomentT1": "0.23",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QXXCIXLCGUQNJY-QURGRASLSA-N",
      "Smiles": "Clc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)Cl",
      "HOMO": "-5.79",
      "LUMO": "-3.03",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.78",
      "Eox": "0.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.07",
      "ET1red": "",
      "DipoleMomentS0": "5.19",
      "DipoleMomentS1": "5.19",
      "DipoleMomentT1": "5.38",
      "ionizationPotential": "6.64",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QXXZMVAHNVGQPA-VVAFTLCXSA-N",
      "Smiles": "N=Cc1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C=N",
      "HOMO": "-5.64",
      "LUMO": "-2.74",
      "VEE": "1.52",
      "Lmax": "815.657894736842",
      "E00S1": "2.27",
      "E00T1": "1.19",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "-1.46",
      "ES1red": "",
      "ET1ox": "-0.38",
      "ET1red": "",
      "DipoleMomentS0": "6.3",
      "DipoleMomentS1": "6.3",
      "DipoleMomentT1": "6.8",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "QYHWQLINNDQAFN-DQRAZIAOSA-N",
      "Smiles": "N#CC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)C#N",
      "HOMO": "-6.65",
      "LUMO": "-3.98",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "-0.14",
      "Eox": "1.45",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.59",
      "ET1red": "",
      "DipoleMomentS0": "9.07",
      "DipoleMomentS1": "9.07",
      "DipoleMomentT1": "9.67",
      "ionizationPotential": "7.58",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "QYHWQLINNDQAFN-QURGRASLSA-N",
      "Smiles": "N#CC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)C#N",
      "HOMO": "-6.53",
      "LUMO": "-4.12",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "1.57",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.08",
      "ET1red": "",
      "DipoleMomentS0": "10.33",
      "DipoleMomentS1": "10.33",
      "DipoleMomentT1": "9.68",
      "ionizationPotential": "7.61",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "QYSTXMPTSZPMOO-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1c[nH]c(c1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1c[nH]c(c1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-6.83",
      "LUMO": "-2.7",
      "VEE": "3.2",
      "Lmax": "387.4375",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.69",
      "DipoleMomentS1": "3.69",
      "DipoleMomentT1": "9.36",
      "ionizationPotential": "8.29",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "QZCFTQPZIIQSOY-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(o1)c1cc2c3cc(c4ncc(o4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)S)cc(c5ccc4)c1ncc(o1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(o1)c1cc2c3cc(c4ncc(o4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)S)cc(c5ccc4)c1ncc(o1)S",
      "HOMO": "-5.67",
      "LUMO": "-3.01",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "1.83",
      "E00T1": "0.25",
      "Eox": "-4.29",
      "Ered": "",
      "ES1ox": "-6.12",
      "ES1red": "",
      "ET1ox": "-4.54",
      "ET1red": "",
      "DipoleMomentS0": "2.63",
      "DipoleMomentS1": "2.63",
      "DipoleMomentT1": "2.51",
      "ionizationPotential": "6.28",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "QZYFWEUCFXHFGC-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)[N+](=O)[O-]",
      "HOMO": "-5.64",
      "LUMO": "-3.25",
      "VEE": "1.45",
      "Lmax": "855.034482758621",
      "E00S1": "1.63",
      "E00T1": "1.04",
      "Eox": "0.82",
      "Ered": "",
      "ES1ox": "-0.81",
      "ES1red": "",
      "ET1ox": "-0.22",
      "ET1red": "",
      "DipoleMomentS0": "14.2",
      "DipoleMomentS1": "14.2",
      "DipoleMomentT1": "17.34",
      "ionizationPotential": "6.66",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "RAABYYNKDGTVDP-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-7.54",
      "LUMO": "-3.31",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "2.94",
      "E00T1": "2.5",
      "Eox": "2.66",
      "Ered": "",
      "ES1ox": "-0.28",
      "ES1red": "",
      "ET1ox": "0.16",
      "ET1red": "",
      "DipoleMomentS0": "0.89",
      "DipoleMomentS1": "0.89",
      "DipoleMomentT1": "4.75",
      "ionizationPotential": "8.49",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "RAAGIWOBWYPZDK-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)F",
      "HOMO": "-5.4",
      "LUMO": "-2.4",
      "VEE": "1.52",
      "Lmax": "815.657894736842",
      "E00S1": "2.14",
      "E00T1": "1.26",
      "Eox": "0.6",
      "Ered": "",
      "ES1ox": "-1.54",
      "ES1red": "",
      "ET1ox": "-0.66",
      "ET1red": "",
      "DipoleMomentS0": "3.27",
      "DipoleMomentS1": "3.27",
      "DipoleMomentT1": "3.2",
      "ionizationPotential": "6.25",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "RADGKLLIRIXODR-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(S)ccc1c1cc2c3cc(c4ccc(n4C=O)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)S)cc(c5ccc4)c1ccc(n1C=O)S",
      "MolView_url": "http://molview.org/?q=O=Cn1c(S)ccc1c1cc2c3cc(c4ccc(n4C=O)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)S)cc(c5ccc4)c1ccc(n1C=O)S",
      "HOMO": "-5.7",
      "LUMO": "-2.76",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "2.04",
      "E00T1": "1.22",
      "Eox": "-4.16",
      "Ered": "",
      "ES1ox": "-6.2",
      "ES1red": "",
      "ET1ox": "-5.38",
      "ET1red": "",
      "DipoleMomentS0": "6.17",
      "DipoleMomentS1": "6.17",
      "DipoleMomentT1": "5.89",
      "ionizationPotential": "0.19",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RAMMNTSPGTVCFK-FMQUCBEESA-N",
      "Smiles": "Clc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)Cl",
      "HOMO": "-6.08",
      "LUMO": "-3.2",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "2.17",
      "E00T1": "",
      "Eox": "1.17",
      "Ered": "",
      "ES1ox": "-1",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.7",
      "DipoleMomentS1": "1.7",
      "DipoleMomentT1": "1.86",
      "ionizationPotential": "6.89",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "RAPLMSGLQMGQLJ-AHLMDIMYSA-N",
      "Smiles": "F/C=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/F",
      "MolView_url": "http://molview.org/?q=F/C=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/F",
      "HOMO": "-6.31",
      "LUMO": "-2.75",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.45",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "0.08",
      "DipoleMomentS1": "0.08",
      "DipoleMomentT1": "1.32",
      "ionizationPotential": "7.27",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "RBOPSTUROHMICF-UHFFFAOYSA-N",
      "Smiles": "Clc1coc(n1)c1cc2c3cc(c4occ(n4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)Cl)cc(c5ccc4)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)c1cc2c3cc(c4occ(n4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)Cl)cc(c5ccc4)c1occ(n1)Cl",
      "HOMO": "-5.73",
      "LUMO": "-3.06",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "1.85",
      "E00T1": "",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "-1.01",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RBXRWONXFMQEJY-UHFFFAOYSA-N",
      "Smiles": "Oc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)O)cc(c5ccc4)c1cnc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)O)cc(c5ccc4)c1cnc([nH]1)O",
      "HOMO": "-5.32",
      "LUMO": "-2.55",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.5",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.28",
      "DipoleMomentS1": "7.28",
      "DipoleMomentT1": "6.8",
      "ionizationPotential": "6.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RCDGTUIDPWPUIP-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)C)c1ncc(cn1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)C)c1ncc(cn1)C",
      "HOMO": "-7.19",
      "LUMO": "-3.2",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "2.88",
      "E00T1": "2.5",
      "Eox": "2.25",
      "Ered": "",
      "ES1ox": "-0.63",
      "ES1red": "",
      "ET1ox": "-0.25",
      "ET1red": "",
      "DipoleMomentS0": "4.05",
      "DipoleMomentS1": "4.05",
      "DipoleMomentT1": "6.69",
      "ionizationPotential": "8.02",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "RCFZHWFLUONIJV-UHFFFAOYSA-N",
      "Smiles": "ClC#Cc1cc2c3cc(C#CCl)c4c5c3c(c3c2c2c1cccc2c(c3)C#CCl)cc(c5ccc4)C#CCl",
      "MolView_url": "http://molview.org/?q=ClC#Cc1cc2c3cc(C#CCl)c4c5c3c(c3c2c2c1cccc2c(c3)C#CCl)cc(c5ccc4)C#CCl",
      "HOMO": "-5.59",
      "LUMO": "-2.87",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "2.1",
      "E00T1": "1.1",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "-1.26",
      "ES1red": "",
      "ET1ox": "-0.26",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.37",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RCHMEPZHRVVESE-ISLYRVAYSA-N",
      "Smiles": "Sc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)S",
      "HOMO": "-5.69",
      "LUMO": "-3.05",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.6",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.17",
      "DipoleMomentS1": "1.17",
      "DipoleMomentT1": "1.63",
      "ionizationPotential": "6.21",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "RCHMEPZHRVVESE-ZCXUNETKSA-N",
      "Smiles": "Sc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)S",
      "HOMO": "-5.7",
      "LUMO": "-3.02",
      "VEE": "2.33",
      "Lmax": "532.103004291845",
      "E00S1": "",
      "E00T1": "0.14",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "5.29",
      "DipoleMomentS1": "5.29",
      "DipoleMomentT1": "5.69",
      "ionizationPotential": "6.21",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "RCVCNLJVGMQWQP-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cn1)[N+](=O)[O-])c1ccc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cn1)[N+](=O)[O-])c1ccc(cn1)[N+](=O)[O-]",
      "HOMO": "-7.53",
      "LUMO": "-3.5",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "2.52",
      "Eox": "2.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "6.24",
      "DipoleMomentS1": "6.24",
      "DipoleMomentT1": "4.33",
      "ionizationPotential": "8.67",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "RCXJLMOXZUONKF-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C1(N=N1)[N+](=O)[O-]",
      "HOMO": "-9.73",
      "LUMO": "-3.83",
      "VEE": "4.16",
      "Lmax": "298.028846153846",
      "E00S1": "4",
      "E00T1": "3.1",
      "Eox": "4.56",
      "Ered": "",
      "ES1ox": "0.56",
      "ES1red": "",
      "ET1ox": "1.46",
      "ET1red": "",
      "DipoleMomentS0": "2.41",
      "DipoleMomentS1": "2.41",
      "DipoleMomentT1": "1.67",
      "ionizationPotential": "11.3",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RDIBJFNAFTYVNQ-WCWDXBQESA-N",
      "Smiles": "Clc1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)Cl",
      "HOMO": "-5.99",
      "LUMO": "-3.06",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "2.19",
      "E00T1": "0.86",
      "Eox": "1.05",
      "Ered": "",
      "ES1ox": "-1.14",
      "ES1red": "",
      "ET1ox": "0.19",
      "ET1red": "",
      "DipoleMomentS0": "5.32",
      "DipoleMomentS1": "5.32",
      "DipoleMomentT1": "5.38",
      "ionizationPotential": "6.75",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "RDMDDIYCAZUQKH-FMQUCBEESA-N",
      "Smiles": "Sc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)S",
      "MolView_url": "http://molview.org/?q=Sc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)S",
      "HOMO": "-5.52",
      "LUMO": "-2.88",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.29",
      "DipoleMomentS1": "7.29",
      "DipoleMomentT1": "6.68",
      "ionizationPotential": "6.24",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "RDMDDIYCAZUQKH-VXPUYCOJSA-N",
      "Smiles": "Sc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)S",
      "MolView_url": "http://molview.org/?q=Sc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)S",
      "HOMO": "-5.68",
      "LUMO": "-2.77",
      "VEE": "2.6",
      "Lmax": "476.846153846154",
      "E00S1": "",
      "E00T1": "0.15",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "9.51",
      "DipoleMomentS1": "9.51",
      "DipoleMomentT1": "6.23",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "RDRGFNYXWJFASY-WCWDXBQESA-N",
      "Smiles": "Clc1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(cc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cc1)Cl",
      "HOMO": "-5.78",
      "LUMO": "-2.9",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "2.15",
      "E00T1": "0.84",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "REEBEMXGMMAFNK-BLOCHSASSA-N",
      "Smiles": "FC(O/C=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/C=C\\c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/OC(F)(F)F)(F)F",
      "HOMO": "-6.39",
      "LUMO": "-2.81",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.51",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.08",
      "DipoleMomentS1": "0.08",
      "DipoleMomentT1": "4.88",
      "ionizationPotential": "7.26",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "RENBTYLSUPKGNS-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)/C=C\\c1cc2c3cc(/C=C\\[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/[N+](=O)[O-])cc(c5ccc4)/C=C\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C\\c1cc2c3cc(/C=C\\[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/[N+](=O)[O-])cc(c5ccc4)/C=C\\[N+](=O)[O-]",
      "HOMO": "-5.9",
      "LUMO": "-3.55",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "1.57",
      "E00T1": "0.93",
      "Eox": "-4.14",
      "Ered": "",
      "ES1ox": "-5.71",
      "ES1red": "",
      "ET1ox": "-5.07",
      "ET1red": "",
      "DipoleMomentS0": "18.41",
      "DipoleMomentS1": "18.41",
      "DipoleMomentT1": "19.22",
      "ionizationPotential": "0.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RFIIBGAVOJCYBG-UHFFFAOYSA-N",
      "Smiles": "Cn1cccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cccn1C)c1cccn1C",
      "MolView_url": "http://molview.org/?q=Cn1cccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cccn1C)c1cccn1C",
      "HOMO": "-5.28",
      "LUMO": "-2.29",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.24",
      "E00T1": "1.59",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.63",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "14.22",
      "ionizationPotential": "0.08",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "RFXPTLQYMKKJGO-MZAUSLISSA-N",
      "Smiles": "N=Cc1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C=N",
      "MolView_url": "http://molview.org/?q=N=Cc1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C=N",
      "HOMO": "-7.72",
      "LUMO": "-3.34",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "3.09",
      "E00T1": "",
      "Eox": "2.77",
      "Ered": "",
      "ES1ox": "-0.32",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.71",
      "DipoleMomentS1": "1.71",
      "DipoleMomentT1": "",
      "ionizationPotential": "8.87",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "RFYHLJRNDBSBCG-VCFJNTAESA-N",
      "Smiles": "S/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/S",
      "MolView_url": "http://molview.org/?q=S/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C/S",
      "HOMO": "-5.33",
      "LUMO": "-2.44",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.01",
      "E00T1": "1.18",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "-1.52",
      "ES1red": "",
      "ET1ox": "-0.69",
      "ET1red": "",
      "DipoleMomentS0": "1.97",
      "DipoleMomentS1": "1.97",
      "DipoleMomentT1": "2.4",
      "ionizationPotential": "6.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "RFYJYQRUYORDDU-CYLCCLTRSA-N",
      "Smiles": "Cl/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/Cl",
      "MolView_url": "http://molview.org/?q=Cl/C=C/c1ccc(s1)/N=N\\c1ccc(s1)/C=C/Cl",
      "HOMO": "-5.98",
      "LUMO": "-3.03",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.24",
      "DipoleMomentS1": "3.24",
      "DipoleMomentT1": "3.13",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "RFYJYQRUYORDDU-XHVJHNBZSA-N",
      "Smiles": "Cl/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/Cl",
      "MolView_url": "http://molview.org/?q=Cl/C=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=C/Cl",
      "HOMO": "-5.93",
      "LUMO": "-3.1",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "",
      "E00T1": "0.73",
      "Eox": "0.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.23",
      "ET1red": "",
      "DipoleMomentS0": "0.56",
      "DipoleMomentS1": "0.56",
      "DipoleMomentT1": "0.39",
      "ionizationPotential": "6.82",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "RGBPMDPGDXOQKZ-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)OC(F)(F)F)(F)F",
      "HOMO": "-5.51",
      "LUMO": "-2.3",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "",
      "E00T1": "1.26",
      "Eox": "0.61",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.65",
      "ET1red": "",
      "DipoleMomentS0": "3.87",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "4.86",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "RGGNNNTXXLRLRO-UHFFFAOYSA-N",
      "Smiles": "Cn1c(O)ccc1c1cc2c3cc(c4ccc(n4C)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)O)cc(c5ccc4)c1ccc(n1C)O",
      "MolView_url": "http://molview.org/?q=Cn1c(O)ccc1c1cc2c3cc(c4ccc(n4C)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)O)cc(c5ccc4)c1ccc(n1C)O",
      "HOMO": "-5",
      "LUMO": "-2.35",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "1.84",
      "E00T1": "1.11",
      "Eox": "-4.18",
      "Ered": "",
      "ES1ox": "-6.02",
      "ES1red": "",
      "ET1ox": "-5.29",
      "ET1red": "",
      "DipoleMomentS0": "2.93",
      "DipoleMomentS1": "2.93",
      "DipoleMomentT1": "3.81",
      "ionizationPotential": "0.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RHYLKSVDQIMAEY-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(Cl)ccc1[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=O=Cn1c(Cl)ccc1[C@]1(Cl)N=N1",
      "HOMO": "-7.05",
      "LUMO": "-2.6",
      "VEE": "3.41",
      "Lmax": "363.577712609971",
      "E00S1": "3.12",
      "E00T1": "2.3",
      "Eox": "1.99",
      "Ered": "",
      "ES1ox": "-1.13",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "5.22",
      "DipoleMomentS1": "5.22",
      "DipoleMomentT1": "12.22",
      "ionizationPotential": "8.42",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RISCEDDTHRBLGC-UHFFFAOYSA-N",
      "Smiles": "F/C=C\\[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=F/C=C\\[C@]1(F)N=N1",
      "HOMO": "-7.54",
      "LUMO": "-2.63",
      "VEE": "3.06",
      "Lmax": "405.16339869281",
      "E00S1": "2.96",
      "E00T1": "",
      "Eox": "2.65",
      "Ered": "",
      "ES1ox": "-0.31",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.58",
      "DipoleMomentS1": "3.58",
      "DipoleMomentT1": "6.25",
      "ionizationPotential": "9.38",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RJUZWVSAYMNISV-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(s1)c1cc2c3cc(c4ccc(s4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)OC(F)(F)F)cc(c5ccc4)c1ccc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(s1)c1cc2c3cc(c4ccc(s4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)OC(F)(F)F)cc(c5ccc4)c1ccc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-5.5",
      "LUMO": "-2.69",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "1.97",
      "E00T1": "1.15",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "-1.31",
      "ES1red": "",
      "ET1ox": "-0.49",
      "ET1red": "",
      "DipoleMomentS0": "1.68",
      "DipoleMomentS1": "1.68",
      "DipoleMomentT1": "1.39",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RKTKTNUMPNSCPM-UHFFFAOYSA-N",
      "Smiles": "N#CC1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)C#N)C1=CC=C(C1)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)C#N)C1=CC=C(C1)C#N",
      "HOMO": "-5.57",
      "LUMO": "-2.67",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "1.53",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.65",
      "DipoleMomentS1": "0.65",
      "DipoleMomentT1": "4.21",
      "ionizationPotential": "0.01",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "RLDNAQLKHCBSKJ-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)[N+](=O)[O-])c1ccc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)[N+](=O)[O-])c1ccc(s1)[N+](=O)[O-]",
      "HOMO": "-5.79",
      "LUMO": "-3.22",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "1.46",
      "Eox": "0.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "7.84",
      "DipoleMomentS1": "7.84",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.9",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "RLSNCVUCZASIAX-DQRAZIAOSA-N",
      "Smiles": "O=Cn1c(F)ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(F)ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)F",
      "HOMO": "-6.32",
      "LUMO": "-2.8",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "0.1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.13",
      "ET1red": "",
      "DipoleMomentS0": "6.2",
      "DipoleMomentS1": "6.2",
      "DipoleMomentT1": "9.65",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "RLSNCVUCZASIAX-QURGRASLSA-N",
      "Smiles": "O=Cn1c(F)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(F)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)F",
      "HOMO": "-5.89",
      "LUMO": "-3.04",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "0.82",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.1",
      "ET1red": "",
      "DipoleMomentS0": "1.48",
      "DipoleMomentS1": "1.48",
      "DipoleMomentT1": "2.97",
      "ionizationPotential": "6.74",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "RLVSSUOLMMPFFA-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(s1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1ccc(s1)[C@]1(O)N=N1",
      "HOMO": "-6.28",
      "LUMO": "-2.2",
      "VEE": "3.18",
      "Lmax": "389.874213836478",
      "E00S1": "2.58",
      "E00T1": "1.81",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "-1.26",
      "ES1red": "",
      "ET1ox": "-0.49",
      "ET1red": "",
      "DipoleMomentS0": "4.45",
      "DipoleMomentS1": "4.45",
      "DipoleMomentT1": "8.62",
      "ionizationPotential": "7.66",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RLXWMXYCOCFCRF-UHFFFAOYSA-N",
      "Smiles": "Cc1csc(n1)c1cc2c3cc(c4scc(n4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)C)cc(c5ccc4)c1scc(n1)C",
      "MolView_url": "http://molview.org/?q=Cc1csc(n1)c1cc2c3cc(c4scc(n4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)C)cc(c5ccc4)c1scc(n1)C",
      "HOMO": "-5.55",
      "LUMO": "-2.79",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.89",
      "E00T1": "1.13",
      "Eox": "0.79",
      "Ered": "",
      "ES1ox": "-1.1",
      "ES1red": "",
      "ET1ox": "-0.34",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.1",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RMEAZJGFNYFQIS-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1csc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1scc(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1csc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1scc(n1)OC(F)(F)F)(F)F",
      "HOMO": "-6.99",
      "LUMO": "-3.36",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.55",
      "DipoleMomentS1": "4.55",
      "DipoleMomentT1": "15.23",
      "ionizationPotential": "7.93",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "RMKKUDSLRKQFNA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)C#N)cc(c5ccc4)c1nnc(nn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1nnc(nn1)c1cc2c3cc(c4nnc(nn4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1nnc(nn1)C#N)cc(c5ccc4)c1nnc(nn1)C#N",
      "HOMO": "-6.13",
      "LUMO": "-3.76",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "1.63",
      "E00T1": "0.98",
      "Eox": "1.35",
      "Ered": "",
      "ES1ox": "-0.28",
      "ES1red": "",
      "ET1ox": "0.37",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "0.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RMQKYCWFBVZMTH-FMQUCBEESA-N",
      "Smiles": "Oc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)O",
      "MolView_url": "http://molview.org/?q=Oc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)O",
      "HOMO": "-5.21",
      "LUMO": "-2.66",
      "VEE": "2.59",
      "Lmax": "478.687258687259",
      "E00S1": "1.9",
      "E00T1": "0.72",
      "Eox": "0.36",
      "Ered": "",
      "ES1ox": "-1.54",
      "ES1red": "",
      "ET1ox": "-0.36",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.97",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "RMSOSBDUDVEPNP-UHFFFAOYSA-N",
      "Smiles": "Cn1c(F)ccc1[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Cn1c(F)ccc1[C@]1(F)N=N1",
      "HOMO": "-6.57",
      "LUMO": "-2.56",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.94",
      "DipoleMomentS1": "1.94",
      "DipoleMomentT1": "10.19",
      "ionizationPotential": "7.96",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RNNDPGIZCQAUTL-DQRAZIAOSA-N",
      "Smiles": "c1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccccn1",
      "MolView_url": "http://molview.org/?q=c1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccccn1",
      "HOMO": "-5.98",
      "LUMO": "-3",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "0.95",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.73",
      "ET1red": "",
      "DipoleMomentS0": "11.13",
      "DipoleMomentS1": "11.13",
      "DipoleMomentT1": "8.98",
      "ionizationPotential": "6.57",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "RNNDPGIZCQAUTL-QURGRASLSA-N",
      "Smiles": "c1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccccn1",
      "MolView_url": "http://molview.org/?q=c1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccccn1",
      "HOMO": "-6.04",
      "LUMO": "-3.09",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "",
      "E00T1": "0.86",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.14",
      "ET1red": "",
      "DipoleMomentS0": "2.45",
      "DipoleMomentS1": "2.45",
      "DipoleMomentT1": "3.52",
      "ionizationPotential": "6.69",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ROWJQBZDSJZGRP-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C=O)C#N)C#N)c1ccc(n1C=O)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C=O)C#N)C#N)c1ccc(n1C=O)C#N",
      "HOMO": "-6.99",
      "LUMO": "-3.31",
      "VEE": "3.34",
      "Lmax": "371.197604790419",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "12.61",
      "DipoleMomentS1": "12.61",
      "DipoleMomentT1": "10.64",
      "ionizationPotential": "8.57",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "RQGLBMPNSGCWTR-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(s1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1cnc(s1)[C@]1(O)N=N1",
      "HOMO": "-6.74",
      "LUMO": "-2.42",
      "VEE": "3.26",
      "Lmax": "380.306748466258",
      "E00S1": "2.65",
      "E00T1": "2.08",
      "Eox": "1.78",
      "Ered": "",
      "ES1ox": "-0.87",
      "ES1red": "",
      "ET1ox": "-0.3",
      "ET1red": "",
      "DipoleMomentS0": "6.35",
      "DipoleMomentS1": "6.35",
      "DipoleMomentT1": "11.53",
      "ionizationPotential": "8.24",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RQRBPQPYGWRYGE-BYYHNAKLSA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)OC(F)(F)F)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)OC(F)(F)F)OC(F)(F)F",
      "HOMO": "-6.04",
      "LUMO": "-3.13",
      "VEE": "3.08",
      "Lmax": "402.532467532468",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "1.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.24",
      "ET1red": "",
      "DipoleMomentS0": "1.11",
      "DipoleMomentS1": "1.11",
      "DipoleMomentT1": "1.02",
      "ionizationPotential": "6.9",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "RQTAFCVNGSTPDB-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(o1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1cnc(o1)[C@]1(O)N=N1",
      "HOMO": "-6.74",
      "LUMO": "-2.46",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.88",
      "DipoleMomentS1": "3.88",
      "DipoleMomentT1": "9.07",
      "ionizationPotential": "8.33",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RRMSZOVUIKLMDK-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(n1C=O)c1cc2c3cc(c4ccc(n4C=O)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)C#N)cc(c5ccc4)c1ccc(n1C=O)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(n1C=O)c1cc2c3cc(c4ccc(n4C=O)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)C#N)cc(c5ccc4)c1ccc(n1C=O)C#N",
      "HOMO": "-5.96",
      "LUMO": "-2.95",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "2.13",
      "E00T1": "1.26",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "0.14",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RSMLFDSBTGFWGC-FMQUCBEESA-N",
      "Smiles": "Oc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)O",
      "HOMO": "-5.45",
      "LUMO": "-2.88",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "0.71",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.13",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.19",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "RSMLFDSBTGFWGC-VXPUYCOJSA-N",
      "Smiles": "Oc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)O",
      "HOMO": "-5.53",
      "LUMO": "-2.87",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.47",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.54",
      "DipoleMomentS1": "6.54",
      "DipoleMomentT1": "6.57",
      "ionizationPotential": "6.22",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "RSSGFHPXFQXABE-UHFFFAOYSA-N",
      "Smiles": "COc1cc([nH]c1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1[nH]cc(c1)OC",
      "MolView_url": "http://molview.org/?q=COc1cc([nH]c1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1[nH]cc(c1)OC",
      "HOMO": "-5.93",
      "LUMO": "-3.06",
      "VEE": "2.05",
      "Lmax": "604.780487804878",
      "E00S1": "2.21",
      "E00T1": "1.69",
      "Eox": "1.19",
      "Ered": "",
      "ES1ox": "-1.02",
      "ES1red": "",
      "ET1ox": "-0.5",
      "ET1red": "",
      "DipoleMomentS0": "4.25",
      "DipoleMomentS1": "4.25",
      "DipoleMomentT1": "8.41",
      "ionizationPotential": "6.8",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "RTKQMRDYRUDRPH-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1nnc(nn1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1nnc(nn1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.32",
      "LUMO": "-4.15",
      "VEE": "2.49",
      "Lmax": "497.911646586345",
      "E00S1": "2.28",
      "E00T1": "1.47",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.97",
      "DipoleMomentS1": "2.97",
      "DipoleMomentT1": "2.78",
      "ionizationPotential": "10.07",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RTXFLXYHGUXDIR-BUHFOSPRSA-N",
      "Smiles": "SC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CS",
      "MolView_url": "http://molview.org/?q=SC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CS",
      "HOMO": "-5.81",
      "LUMO": "-3.04",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "0.8",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "2.34",
      "DipoleMomentS1": "2.34",
      "DipoleMomentT1": "2.33",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "RTXFLXYHGUXDIR-YPKPFQOOSA-N",
      "Smiles": "SC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CS",
      "MolView_url": "http://molview.org/?q=SC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CS",
      "HOMO": "-6.07",
      "LUMO": "-3.08",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.61",
      "ET1red": "",
      "DipoleMomentS0": "3.85",
      "DipoleMomentS1": "3.85",
      "DipoleMomentT1": "3.4",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "RVGZJCQIZKSHTJ-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(O)ccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C=O)O",
      "MolView_url": "http://molview.org/?q=O=Cn1c(O)ccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C=O)O",
      "HOMO": "-5.47",
      "LUMO": "-2.54",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "",
      "E00T1": "1.22",
      "Eox": "0.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.58",
      "ET1red": "",
      "DipoleMomentS0": "7.91",
      "DipoleMomentS1": "7.91",
      "DipoleMomentT1": "7.16",
      "ionizationPotential": "6.24",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "RVLODOITISSKGV-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)C)C1=CC=C(S1(=O)=O)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)C)C1=CC=C(S1(=O)=O)C",
      "HOMO": "-6.96",
      "LUMO": "-3.38",
      "VEE": "1.75",
      "Lmax": "708.457142857143",
      "E00S1": "2.77",
      "E00T1": "2.11",
      "Eox": "2.18",
      "Ered": "",
      "ES1ox": "-0.59",
      "ES1red": "",
      "ET1ox": "0.07",
      "ET1red": "",
      "DipoleMomentS0": "13.48",
      "DipoleMomentS1": "13.48",
      "DipoleMomentT1": "16.29",
      "ionizationPotential": "7.89",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "RWCYJZZZTRECJY-VHXPQNKSSA-N",
      "Smiles": "Fc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)F",
      "HOMO": "-6.21",
      "LUMO": "-3.14",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.99",
      "DipoleMomentS1": "5.99",
      "DipoleMomentT1": "4.93",
      "ionizationPotential": "6.87",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "RWCYJZZZTRECJY-WCWDXBQESA-N",
      "Smiles": "Fc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)F",
      "HOMO": "-6.29",
      "LUMO": "-3.26",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "",
      "E00T1": "0.9",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.39",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.01",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "RWFRPOBYWKKEID-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)[N+](=O)[O-]",
      "HOMO": "-6.39",
      "LUMO": "-3.51",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.62",
      "ET1red": "",
      "DipoleMomentS0": "13.69",
      "DipoleMomentS1": "13.69",
      "DipoleMomentT1": "13.6",
      "ionizationPotential": "7.43",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "RWFRPOBYWKKEID-ZCXUNETKSA-N",
      "Smiles": "[O-][N+](=O)c1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)[N+](=O)[O-]",
      "HOMO": "-6.33",
      "LUMO": "-3.38",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.07",
      "ET1red": "",
      "DipoleMomentS0": "9.29",
      "DipoleMomentS1": "9.29",
      "DipoleMomentT1": "10.94",
      "ionizationPotential": "7.34",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "RWHANBNXQPOEOY-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1coc(n1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.63",
      "LUMO": "-3",
      "VEE": "3.3",
      "Lmax": "375.69696969697",
      "E00S1": "3.11",
      "E00T1": "2.43",
      "Eox": "2.53",
      "Ered": "",
      "ES1ox": "-0.58",
      "ES1red": "",
      "ET1ox": "0.1",
      "ET1red": "",
      "DipoleMomentS0": "3.92",
      "DipoleMomentS1": "3.92",
      "DipoleMomentT1": "9.65",
      "ionizationPotential": "9.02",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RWHUXQJJEVRPRA-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(S)ccc1[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=O=Cn1c(S)ccc1[C@]1(S)N=N1",
      "HOMO": "-6.39",
      "LUMO": "-2.47",
      "VEE": "3.37",
      "Lmax": "367.893175074184",
      "E00S1": "2.7",
      "E00T1": "1.99",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "-1.38",
      "ES1red": "",
      "ET1ox": "-0.67",
      "ET1red": "",
      "DipoleMomentS0": "3.26",
      "DipoleMomentS1": "3.26",
      "DipoleMomentT1": "10.22",
      "ionizationPotential": "7.68",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RWKGCJIAGLNCOU-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)[N+](=O)[O-])cc(c5ccc4)c1ccc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)[N+](=O)[O-])cc(c5ccc4)c1ccc([nH]1)[N+](=O)[O-]",
      "HOMO": "-5.73",
      "LUMO": "-3.17",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "1.78",
      "E00T1": "1.09",
      "Eox": "-4.34",
      "Ered": "",
      "ES1ox": "-6.12",
      "ES1red": "",
      "ET1ox": "-5.43",
      "ET1red": "",
      "DipoleMomentS0": "11.86",
      "DipoleMomentS1": "11.86",
      "DipoleMomentT1": "11.53",
      "ionizationPotential": "0.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "RYJPJFNNJPAINW-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1occ(n1)S)C#N)c1occ(n1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1occ(n1)S)C#N)c1occ(n1)S",
      "HOMO": "-6.49",
      "LUMO": "-3.16",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.18",
      "DipoleMomentS1": "2.18",
      "DipoleMomentT1": "4.65",
      "ionizationPotential": "7.9",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "RYSCLVZXACDRPK-QURGRASLSA-N",
      "Smiles": "c1csc(c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cccs1",
      "MolView_url": "http://molview.org/?q=c1csc(c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cccs1",
      "HOMO": "-6.1",
      "LUMO": "-2.74",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "1.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.14",
      "ET1red": "",
      "DipoleMomentS0": "0.4",
      "DipoleMomentS1": "0.4",
      "DipoleMomentT1": "4.64",
      "ionizationPotential": "6.82",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "RYYJJENVUDZMCO-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(cc1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1ccc(cc1)[C@]1(Cl)N=N1",
      "HOMO": "-7.21",
      "LUMO": "-2.63",
      "VEE": "3.42",
      "Lmax": "362.514619883041",
      "E00S1": "2.94",
      "E00T1": "2.39",
      "Eox": "2.28",
      "Ered": "",
      "ES1ox": "-0.66",
      "ES1red": "",
      "ET1ox": "-0.11",
      "ET1red": "",
      "DipoleMomentS0": "0.73",
      "DipoleMomentS1": "0.73",
      "DipoleMomentT1": "6.46",
      "ionizationPotential": "8.61",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "RZVHIXYEVGDQDX-UHFFFAOYSA-N",
      "Smiles": "O=C1c2ccccc2C(=O)c2c1cccc2",
      "MolView_url": "http://molview.org/?q=O=C1c2ccccc2C(=O)c2c1cccc2",
      "HOMO": "-7.76",
      "LUMO": "-3.07",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "3.14",
      "E00T1": "2.76",
      "Eox": "2.75",
      "Ered": "",
      "ES1ox": "-0.39",
      "ES1red": "",
      "ET1ox": "-0.01",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "9.17",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "RZZXVLDCDQSYBK-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1cnc([nH]1)OC(F)(F)F)C#N)c1cnc([nH]1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1cnc([nH]1)OC(F)(F)F)C#N)c1cnc([nH]1)OC(F)(F)F",
      "HOMO": "-6.46",
      "LUMO": "-2.95",
      "VEE": "3.12",
      "Lmax": "397.371794871795",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.99",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.48",
      "DipoleMomentS1": "1.48",
      "DipoleMomentT1": "7.02",
      "ionizationPotential": "7.99",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "SAWFHQKTEYKKKG-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc([nH]1)OC(F)(F)F)C#N)c1ccc([nH]1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc([nH]1)OC(F)(F)F)C#N)c1ccc([nH]1)OC(F)(F)F",
      "HOMO": "-6.14",
      "LUMO": "-2.82",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.11",
      "DipoleMomentS1": "2.11",
      "DipoleMomentT1": "8.78",
      "ionizationPotential": "7.53",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "SAWFWYKXDXLNIO-UHFFFAOYSA-N",
      "Smiles": "Fc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)F",
      "HOMO": "-5.43",
      "LUMO": "-2.51",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.59",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.44",
      "DipoleMomentS1": "8.44",
      "DipoleMomentT1": "7.97",
      "ionizationPotential": "6.37",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "SBFXMFFMTNYNLA-QURGRASLSA-N",
      "Smiles": "FC(Oc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)OC(F)(F)F)(F)F",
      "HOMO": "-6.63",
      "LUMO": "-3.63",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.15",
      "E00T1": "",
      "Eox": "1.62",
      "Ered": "",
      "ES1ox": "-0.53",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "",
      "ionizationPotential": "7.67",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "SBOQJGOSOHNEAR-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C)[N+](=O)[O-])C#N)c1ccc(n1C)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C)[N+](=O)[O-])C#N)c1ccc(n1C)[N+](=O)[O-]",
      "HOMO": "-7",
      "LUMO": "-3.27",
      "VEE": "3.44",
      "Lmax": "360.406976744186",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.07",
      "DipoleMomentS1": "9.07",
      "DipoleMomentT1": "12.16",
      "ionizationPotential": "8.68",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "SCLNROQCMNGMHX-FMQUCBEESA-N",
      "Smiles": "Fc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)F",
      "HOMO": "-6.11",
      "LUMO": "-3.26",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "",
      "E00T1": "0.82",
      "Eox": "1.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.36",
      "ET1red": "",
      "DipoleMomentS0": "0.87",
      "DipoleMomentS1": "0.87",
      "DipoleMomentT1": "1.15",
      "ionizationPotential": "6.95",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "SCLNROQCMNGMHX-VXPUYCOJSA-N",
      "Smiles": "Fc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(o1)F",
      "HOMO": "-6.09",
      "LUMO": "-3.2",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "0.19",
      "Eox": "1.06",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.87",
      "ET1red": "",
      "DipoleMomentS0": "4.17",
      "DipoleMomentS1": "4.17",
      "DipoleMomentT1": "3.62",
      "ionizationPotential": "6.89",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "SDMPXCJIDHXODL-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(s1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(s1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.22",
      "LUMO": "-2.82",
      "VEE": "3.16",
      "Lmax": "392.341772151899",
      "E00S1": "2.81",
      "E00T1": "2.14",
      "Eox": "2.08",
      "Ered": "",
      "ES1ox": "-0.73",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "1.71",
      "DipoleMomentS1": "1.71",
      "DipoleMomentT1": "9.93",
      "ionizationPotential": "8.45",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "SDSNBBBFQQHLOR-UHFFFAOYSA-N",
      "Smiles": "Cn1c(ccc1[C@@]1(N=N1)OC(F)(F)F)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=Cn1c(ccc1[C@@]1(N=N1)OC(F)(F)F)OC(F)(F)F",
      "HOMO": "-6.93",
      "LUMO": "-2.67",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "2.89",
      "E00T1": "1.94",
      "Eox": "1.59",
      "Ered": "",
      "ES1ox": "-1.3",
      "ES1red": "",
      "ET1ox": "-0.35",
      "ET1red": "",
      "DipoleMomentS0": "1.76",
      "DipoleMomentS1": "1.76",
      "DipoleMomentT1": "11.03",
      "ionizationPotential": "7.94",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "SDTXZMNCQRLHCC-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(o1)c1cc2c3cc(c4ccc(o4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)OC(F)(F)F)cc(c5ccc4)c1ccc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(o1)c1cc2c3cc(c4ccc(o4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)OC(F)(F)F)cc(c5ccc4)c1ccc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-5.45",
      "LUMO": "-2.73",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "1.91",
      "E00T1": "",
      "Eox": "-4.21",
      "Ered": "",
      "ES1ox": "-6.12",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.33",
      "DipoleMomentS1": "5.33",
      "DipoleMomentT1": "5.26",
      "ionizationPotential": "0.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "SDUSPDDHYFVXFM-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)F",
      "HOMO": "-5.51",
      "LUMO": "-2.76",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "2.1",
      "E00T1": "1.13",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "-1.44",
      "ES1red": "",
      "ET1ox": "-0.47",
      "ET1red": "",
      "DipoleMomentS0": "2.75",
      "DipoleMomentS1": "2.75",
      "DipoleMomentT1": "2.72",
      "ionizationPotential": "6.34",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "SENAMVZHIKAWOR-WMCCOBJESA-N",
      "Smiles": "Cl/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N\\Cl",
      "MolView_url": "http://molview.org/?q=Cl/N=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=N\\Cl",
      "HOMO": "-6.54",
      "LUMO": "-3.5",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "0.79",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.13",
      "DipoleMomentS1": "2.13",
      "DipoleMomentT1": "1.87",
      "ionizationPotential": "7.59",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "SFCQXRZTJVTXTI-UCZXZSEYSA-N",
      "Smiles": "Cl/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/Cl",
      "MolView_url": "http://molview.org/?q=Cl/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/Cl",
      "HOMO": "-6.63",
      "LUMO": "-3.39",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "0.28",
      "Eox": "1.5",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.22",
      "ET1red": "",
      "DipoleMomentS0": "2.43",
      "DipoleMomentS1": "2.43",
      "DipoleMomentT1": "1.24",
      "ionizationPotential": "7.67",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "SFCQXRZTJVTXTI-ZPHADDHFSA-N",
      "Smiles": "Cl/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/Cl",
      "MolView_url": "http://molview.org/?q=Cl/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/Cl",
      "HOMO": "-6.56",
      "LUMO": "-3.34",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "",
      "E00T1": "0.25",
      "Eox": "1.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.18",
      "ET1red": "",
      "DipoleMomentS0": "3.29",
      "DipoleMomentS1": "3.29",
      "DipoleMomentT1": "1.98",
      "ionizationPotential": "7.49",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "SFRQOIMIYKFGGN-NXVVXOECSA-N",
      "Smiles": "Oc1nnc(nn1)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=Oc1nnc(nn1)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1nnc(nn1)O",
      "HOMO": "-6.09",
      "LUMO": "-3.29",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "0.26",
      "Eox": "0.5",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.24",
      "ET1red": "",
      "DipoleMomentS0": "3.36",
      "DipoleMomentS1": "3.36",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.2",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "SFTAYZQAISLIBU-UHFFFAOYSA-N",
      "Smiles": "FC(c1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)C(F)(F)F)(F)F",
      "HOMO": "-5.65",
      "LUMO": "-2.87",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "",
      "E00T1": "1.41",
      "Eox": "-4.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-5.61",
      "ET1red": "",
      "DipoleMomentS0": "3.87",
      "DipoleMomentS1": "3.87",
      "DipoleMomentT1": "4.15",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "SGDBNWXFJCOTEO-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)Cl",
      "HOMO": "-5.41",
      "LUMO": "-2.52",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2",
      "E00T1": "1.2",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.95",
      "DipoleMomentS1": "1.95",
      "DipoleMomentT1": "1.49",
      "ionizationPotential": "6.23",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "SGEDLNHIPIUMPX-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1[C@@]1(N=N1)OC(F)(F)F)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1[C@@]1(N=N1)OC(F)(F)F)OC(F)(F)F",
      "HOMO": "-7.43",
      "LUMO": "-2.7",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "",
      "E00T1": "2.29",
      "Eox": "2.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.22",
      "ET1red": "",
      "DipoleMomentS0": "4.82",
      "DipoleMomentS1": "4.82",
      "DipoleMomentT1": "12.74",
      "ionizationPotential": "8.49",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "SGNSTVSLEXEUOI-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(o1)OC)C#N)c1ccc(o1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(o1)OC)C#N)c1ccc(o1)OC",
      "HOMO": "-5.79",
      "LUMO": "-2.58",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "1.83",
      "Eox": "-1.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.22",
      "ET1red": "",
      "DipoleMomentS0": "4.25",
      "DipoleMomentS1": "4.25",
      "DipoleMomentT1": "10.4",
      "ionizationPotential": "7.01",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "SGPWDDSIPAQMLM-UHFFFAOYSA-N",
      "Smiles": "Cn1c(F)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)F)c1ccc(n1C)F",
      "MolView_url": "http://molview.org/?q=Cn1c(F)ccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(n1C)F)c1ccc(n1C)F",
      "HOMO": "-5.43",
      "LUMO": "-2.35",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.24",
      "E00T1": "1.58",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.42",
      "DipoleMomentS1": "7.42",
      "DipoleMomentT1": "8.81",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "SGXKGNUZWJVEMZ-VHXPQNKSSA-N",
      "Smiles": "COc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)OC",
      "HOMO": "-5.65",
      "LUMO": "-2.84",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.48",
      "DipoleMomentS1": "4.48",
      "DipoleMomentT1": "4.52",
      "ionizationPotential": "6.14",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "SGXKGNUZWJVEMZ-WCWDXBQESA-N",
      "Smiles": "COc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)OC",
      "HOMO": "-5.64",
      "LUMO": "-2.86",
      "VEE": "3.08",
      "Lmax": "402.532467532468",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.26",
      "DipoleMomentS1": "1.26",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.18",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "SGZPNRZSLLTHQA-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)S",
      "HOMO": "-5.53",
      "LUMO": "-2.7",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.92",
      "E00T1": "1.17",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "-1.19",
      "ES1red": "",
      "ET1ox": "-0.44",
      "ET1red": "",
      "DipoleMomentS0": "3.03",
      "DipoleMomentS1": "3.03",
      "DipoleMomentT1": "3.89",
      "ionizationPotential": "6.28",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "SHIHDGWETAUKCX-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(C)ccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C=O)C",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C)ccc1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(n1C=O)C",
      "HOMO": "-5.51",
      "LUMO": "-2.52",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "1.24",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.37",
      "DipoleMomentS1": "6.37",
      "DipoleMomentT1": "6.09",
      "ionizationPotential": "6.27",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "SHSJVQJMDLUJBY-HMXUXOOYSA-N",
      "Smiles": "C/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C\\C",
      "MolView_url": "http://molview.org/?q=C/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C\\C",
      "HOMO": "-5.57",
      "LUMO": "-2.79",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "2.09",
      "E00T1": "0.67",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.05",
      "DipoleMomentS1": "1.05",
      "DipoleMomentT1": "1.03",
      "ionizationPotential": "6.35",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "SJJPPLLYTYUHBQ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)O)C#N)c1ccc(cn1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)O)C#N)c1ccc(cn1)O",
      "HOMO": "-6.64",
      "LUMO": "-2.86",
      "VEE": "3.53",
      "Lmax": "351.218130311615",
      "E00S1": "",
      "E00T1": "2.28",
      "Eox": "-1.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.39",
      "ET1red": "",
      "DipoleMomentS0": "4.4",
      "DipoleMomentS1": "4.4",
      "DipoleMomentT1": "8.63",
      "ionizationPotential": "7.97",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "SJLPCXSQYIVAMZ-QVDRYVJWSA-N",
      "Smiles": "N#C/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C/O/C=C\\C#N",
      "MolView_url": "http://molview.org/?q=N#C/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C/O/C=C\\C#N",
      "HOMO": "-5.73",
      "LUMO": "-2.95",
      "VEE": "1.72",
      "Lmax": "720.813953488372",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.44",
      "ET1red": "",
      "DipoleMomentS0": "15.2",
      "DipoleMomentS1": "15.2",
      "DipoleMomentT1": "11.98",
      "ionizationPotential": "6.07",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "SKLXORZRIKHALY-DQRAZIAOSA-N",
      "Smiles": "COc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)OC",
      "HOMO": "-5.27",
      "LUMO": "-2.53",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "-0.48",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.58",
      "ET1red": "",
      "DipoleMomentS0": "14.06",
      "DipoleMomentS1": "14.06",
      "DipoleMomentT1": "4.26",
      "ionizationPotential": "5.64",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "SKLXORZRIKHALY-QURGRASLSA-N",
      "Smiles": "COc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)OC",
      "HOMO": "-5.1",
      "LUMO": "-2.71",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "0.69",
      "Eox": "0.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.48",
      "ET1red": "",
      "DipoleMomentS0": "1.17",
      "DipoleMomentS1": "1.17",
      "DipoleMomentT1": "0.16",
      "ionizationPotential": "5.6",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "SLXCMGDIMGJDES-UHFFFAOYSA-N",
      "Smiles": "FC(c1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)C(F)(F)F)cc(c5ccc4)c1cnc([nH]1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)C(F)(F)F)cc(c5ccc4)c1cnc([nH]1)C(F)(F)F)(F)F",
      "HOMO": "-5.68",
      "LUMO": "-2.77",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "2.05",
      "E00T1": "",
      "Eox": "0.95",
      "Ered": "",
      "ES1ox": "-1.1",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.39",
      "DipoleMomentS1": "3.39",
      "DipoleMomentT1": "",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "SLXWWCJUMOAGIY-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1[C@@]1(N=N1)[N+](=O)[O-])[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1[C@@]1(N=N1)[N+](=O)[O-])[N+](=O)[O-]",
      "HOMO": "-8.07",
      "LUMO": "-3.44",
      "VEE": "3.69",
      "Lmax": "335.989159891599",
      "E00S1": "3.5",
      "E00T1": "2.16",
      "Eox": "3",
      "Ered": "",
      "ES1ox": "-0.5",
      "ES1red": "",
      "ET1ox": "0.84",
      "ET1red": "",
      "DipoleMomentS0": "5.9",
      "DipoleMomentS1": "5.9",
      "DipoleMomentT1": "7.83",
      "ionizationPotential": "9.62",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "SMEOKADIKUGGPQ-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)/N=C\\[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/N=C\\[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-9.29",
      "LUMO": "-3.56",
      "VEE": "4.03",
      "Lmax": "307.642679900744",
      "E00S1": "3.94",
      "E00T1": "",
      "Eox": "3.69",
      "Ered": "",
      "ES1ox": "-0.25",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.84",
      "DipoleMomentS1": "1.84",
      "DipoleMomentT1": "0.53",
      "ionizationPotential": "10.76",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "SMYUZYZNCSOQKO-DQRAZIAOSA-N",
      "Smiles": "N#Cc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)C#N",
      "HOMO": "-6.3",
      "LUMO": "-3.5",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.11",
      "ET1red": "",
      "DipoleMomentS0": "1",
      "DipoleMomentS1": "1",
      "DipoleMomentT1": "0.97",
      "ionizationPotential": "7.28",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "SMYUZYZNCSOQKO-QURGRASLSA-N",
      "Smiles": "N#Cc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)C#N",
      "HOMO": "-6.33",
      "LUMO": "-3.52",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "1.38",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.54",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.37",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "SOFBEOXOLCTREZ-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(cc1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1ccc(cc1)[C@]1(S)N=N1",
      "HOMO": "-6.45",
      "LUMO": "-2.35",
      "VEE": "3.4",
      "Lmax": "364.647058823529",
      "E00S1": "2.79",
      "E00T1": "",
      "Eox": "1.63",
      "Ered": "",
      "ES1ox": "-1.16",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.13",
      "DipoleMomentS1": "2.13",
      "DipoleMomentT1": "8.61",
      "ionizationPotential": "7.89",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "SOGJZNGOKNSNJN-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(cc1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(cc1)[C@]1(C#N)N=N1",
      "HOMO": "-7.71",
      "LUMO": "-2.95",
      "VEE": "3.72",
      "Lmax": "333.279569892473",
      "E00S1": "3.15",
      "E00T1": "",
      "Eox": "2.8",
      "Ered": "",
      "ES1ox": "-0.35",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.2",
      "DipoleMomentS1": "3.2",
      "DipoleMomentT1": "3.64",
      "ionizationPotential": "9.36",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "SPMBXFYLTIABCA-FMQUCBEESA-N",
      "Smiles": "Sc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)S",
      "HOMO": "-5.43",
      "LUMO": "-2.78",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "1.97",
      "E00T1": "0.76",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.24",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "SQBYHFNGVWDBRV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccccn1)C#N)c1ccccn1",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccccn1)C#N)c1ccccn1",
      "HOMO": "-7.09",
      "LUMO": "-2.99",
      "VEE": "3.9",
      "Lmax": "317.897435897436",
      "E00S1": "",
      "E00T1": "2.77",
      "Eox": "-0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.68",
      "ET1red": "",
      "DipoleMomentS0": "7.25",
      "DipoleMomentS1": "7.25",
      "DipoleMomentT1": "8.58",
      "ionizationPotential": "8.44",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "SRTAUVROQYETGY-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(C2=CC=CC2)c(c(c1)C1=CC=CC1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(C2=CC=CC2)c(c(c1)C1=CC=CC1)C#N",
      "HOMO": "-6.11",
      "LUMO": "-2.76",
      "VEE": "3.1",
      "Lmax": "399.935483870968",
      "E00S1": "",
      "E00T1": "1.62",
      "Eox": "-1.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.8",
      "ET1red": "",
      "DipoleMomentS0": "2.31",
      "DipoleMomentS1": "2.31",
      "DipoleMomentT1": "4.32",
      "ionizationPotential": "7.52",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "STBSRMUWBKNXNU-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(Cl)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)Cl",
      "MolView_url": "http://molview.org/?q=O=Cn1c(Cl)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)Cl",
      "HOMO": "-6.55",
      "LUMO": "-3.24",
      "VEE": "2.22",
      "Lmax": "558.468468468468",
      "E00S1": "2.56",
      "E00T1": "2.07",
      "Eox": "1.81",
      "Ered": "",
      "ES1ox": "-0.75",
      "ES1red": "",
      "ET1ox": "-0.26",
      "ET1red": "",
      "DipoleMomentS0": "5.28",
      "DipoleMomentS1": "5.28",
      "DipoleMomentT1": "8.16",
      "ionizationPotential": "7.54",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "STHUZRXTANAZIP-UHFFFAOYSA-N",
      "Smiles": "Fc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)F)cc(c5ccc4)c1[nH]cc(c1)F",
      "MolView_url": "http://molview.org/?q=Fc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)F)cc(c5ccc4)c1[nH]cc(c1)F",
      "HOMO": "-5.3",
      "LUMO": "-2.53",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "1.93",
      "E00T1": "1.46",
      "Eox": "0.48",
      "Ered": "",
      "ES1ox": "-1.45",
      "ES1red": "",
      "ET1ox": "-0.98",
      "ET1red": "",
      "DipoleMomentS0": "5.91",
      "DipoleMomentS1": "5.91",
      "DipoleMomentT1": "",
      "ionizationPotential": "0.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "STJXOOIJOUXRGT-QVIHXGFCSA-N",
      "Smiles": "FC(Oc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-6.63",
      "LUMO": "-3.03",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.62",
      "ET1red": "",
      "DipoleMomentS0": "0.67",
      "DipoleMomentS1": "0.67",
      "DipoleMomentT1": "0.89",
      "ionizationPotential": "7.35",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "STSDHBSEFGFSLT-VHXPQNKSSA-N",
      "Smiles": "Clc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)Cl",
      "HOMO": "-6.03",
      "LUMO": "-3.07",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.66",
      "DipoleMomentS1": "9.66",
      "DipoleMomentT1": "7.76",
      "ionizationPotential": "6.69",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "STSDHBSEFGFSLT-WCWDXBQESA-N",
      "Smiles": "Clc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)Cl",
      "HOMO": "-6.08",
      "LUMO": "-3.14",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.81",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "STTVECOXFAYCEN-UHFFFAOYSA-N",
      "Smiles": "Clc1csc(n1)c1cc2c3cc(c4scc(n4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)Cl)cc(c5ccc4)c1scc(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1csc(n1)c1cc2c3cc(c4scc(n4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1scc(n1)Cl)cc(c5ccc4)c1scc(n1)Cl",
      "HOMO": "-5.71",
      "LUMO": "-2.95",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "1.89",
      "E00T1": "",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "-0.92",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.37",
      "DipoleMomentS1": "9.37",
      "DipoleMomentT1": "",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "SUZZXDDMFIUOCE-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)F)C#N)c1ccc(cn1)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)F)C#N)c1ccc(cn1)F",
      "HOMO": "-7.11",
      "LUMO": "-2.92",
      "VEE": "3.94",
      "Lmax": "314.670050761421",
      "E00S1": "",
      "E00T1": "2.9",
      "Eox": "-0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.81",
      "ET1red": "",
      "DipoleMomentS0": "4.13",
      "DipoleMomentS1": "4.13",
      "DipoleMomentT1": "5.38",
      "ionizationPotential": "8.56",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "SVXOPIFUBPCZBP-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(o1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1cnc(o1)[C@]1(F)N=N1",
      "HOMO": "-7.45",
      "LUMO": "-2.84",
      "VEE": "3.12",
      "Lmax": "397.371794871795",
      "E00S1": "3.01",
      "E00T1": "2.4",
      "Eox": "2.56",
      "Ered": "",
      "ES1ox": "-0.45",
      "ES1red": "",
      "ET1ox": "0.16",
      "ET1red": "",
      "DipoleMomentS0": "2.21",
      "DipoleMomentS1": "2.21",
      "DipoleMomentT1": "8.61",
      "ionizationPotential": "9.21",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "SWOHDZXFUIXZDU-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C1=CC=C(S1(=O)=O)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C1=CC=C(S1(=O)=O)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.42",
      "LUMO": "-4.18",
      "VEE": "3.69",
      "Lmax": "335.989159891599",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.44",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.74",
      "DipoleMomentS1": "6.74",
      "DipoleMomentT1": "6.98",
      "ionizationPotential": "10.06",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "SXCNXWRYXTWHHK-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(s1)C(F)(F)F)(F)F",
      "HOMO": "-6.95",
      "LUMO": "-3.27",
      "VEE": "2.56",
      "Lmax": "484.296875",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.01",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.04",
      "DipoleMomentS1": "4.04",
      "DipoleMomentT1": "7.87",
      "ionizationPotential": "8",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "SXHLYOKFIAHHDV-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(S)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)S",
      "MolView_url": "http://molview.org/?q=O=Cn1c(S)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)S",
      "HOMO": "-6.26",
      "LUMO": "-3.19",
      "VEE": "2.23",
      "Lmax": "555.964125560538",
      "E00S1": "2.02",
      "E00T1": "1.58",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.85",
      "DipoleMomentS1": "2.85",
      "DipoleMomentT1": "18.43",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "SXLMBDOFDYPZKN-UHFFFAOYSA-N",
      "Smiles": "O=Cn1cccc1c1cc2c3cc(c4cccn4C=O)c4c5c3c(c3c2c2c1cccc2c(c3)c1cccn1C=O)cc(c5ccc4)c1cccn1C=O",
      "MolView_url": "http://molview.org/?q=O=Cn1cccc1c1cc2c3cc(c4cccn4C=O)c4c5c3c(c3c2c2c1cccc2c(c3)c1cccn1C=O)cc(c5ccc4)c1cccn1C=O",
      "HOMO": "-5.95",
      "LUMO": "-2.86",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "2.12",
      "E00T1": "1.25",
      "Eox": "-4.19",
      "Ered": "",
      "ES1ox": "-6.31",
      "ES1red": "",
      "ET1ox": "-5.44",
      "ET1red": "",
      "DipoleMomentS0": "0.05",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "9.72",
      "ionizationPotential": "0.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "SXLUIASGYNPXMQ-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1cc2c3cc([N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)[N+](=O)[O-])cc(c5ccc4)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cc2c3cc([N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)[N+](=O)[O-])cc(c5ccc4)[N+](=O)[O-]",
      "HOMO": "-6.46",
      "LUMO": "-3.85",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "1.81",
      "E00T1": "1.04",
      "Eox": "1.58",
      "Ered": "",
      "ES1ox": "-0.23",
      "ES1red": "",
      "ET1ox": "0.54",
      "ET1red": "",
      "DipoleMomentS0": "1.13",
      "DipoleMomentS1": "1.13",
      "DipoleMomentT1": "1.16",
      "ionizationPotential": "7.79",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "SZCFBFFWSONDLD-KIPVTJEFSA-N",
      "Smiles": "FC(/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N\\C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N\\C(F)(F)F)(F)F",
      "HOMO": "-8.06",
      "LUMO": "-3.56",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "3.05",
      "E00T1": "2.44",
      "Eox": "3.07",
      "Ered": "",
      "ES1ox": "0.02",
      "ES1red": "",
      "ET1ox": "0.63",
      "ET1red": "",
      "DipoleMomentS0": "5.49",
      "DipoleMomentS1": "5.49",
      "DipoleMomentT1": "9.43",
      "ionizationPotential": "9.38",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "SZLBFMKDLBLWAI-UHFFFAOYSA-N",
      "Smiles": "ClC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)Cl)cc(c5ccc4)C1=CC=C(C1)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)Cl)cc(c5ccc4)C1=CC=C(C1)Cl",
      "HOMO": "-5.23",
      "LUMO": "-2.51",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "1.86",
      "E00T1": "0.23",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "-1.13",
      "ES1red": "",
      "ET1ox": "0.5",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "0.09",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "TTWHHNHFIJDMIX-NXVVXOECSA-N",
      "Smiles": "COc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)OC",
      "MolView_url": "http://molview.org/?q=COc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)OC",
      "HOMO": "-6.44",
      "LUMO": "-3.43",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.11",
      "DipoleMomentS1": "4.11",
      "DipoleMomentT1": "4.08",
      "ionizationPotential": "7.18",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "TUBKDZFEWNUEEL-OAPATYPNSA-N",
      "Smiles": "O/C=C\\O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C\\O",
      "MolView_url": "http://molview.org/?q=O/C=C\\O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C\\O",
      "HOMO": "-5.72",
      "LUMO": "-2.66",
      "VEE": "1.76",
      "Lmax": "704.431818181818",
      "E00S1": "",
      "E00T1": "0.54",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0",
      "ET1red": "",
      "DipoleMomentS0": "14.71",
      "DipoleMomentS1": "14.71",
      "DipoleMomentT1": "12.51",
      "ionizationPotential": "6.2",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "TUHCHBVGDQVWHQ-OCEACIFDSA-N",
      "Smiles": "FC(Oc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-6.2",
      "LUMO": "-3.25",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.52",
      "DipoleMomentS1": "0.52",
      "DipoleMomentT1": "2.34",
      "ionizationPotential": "7.06",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "TUHSFGKYPZECNR-DQRAZIAOSA-N",
      "Smiles": "c1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncccn1",
      "MolView_url": "http://molview.org/?q=c1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncccn1",
      "HOMO": "-6.2",
      "LUMO": "-3.12",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.62",
      "DipoleMomentS1": "7.62",
      "DipoleMomentT1": "6.18",
      "ionizationPotential": "6.75",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "TUHSFGKYPZECNR-QURGRASLSA-N",
      "Smiles": "c1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncccn1",
      "MolView_url": "http://molview.org/?q=c1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncccn1",
      "HOMO": "-6.27",
      "LUMO": "-3.21",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "0.91",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "1.39",
      "DipoleMomentS1": "1.39",
      "DipoleMomentT1": "1.36",
      "ionizationPotential": "6.89",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "TVMTUXIWOMKHOM-UHFFFAOYSA-N",
      "Smiles": "FC(c1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)C(F)(F)F)(F)F",
      "HOMO": "-5.64",
      "LUMO": "-2.82",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "1.96",
      "E00T1": "",
      "Eox": "0.87",
      "Ered": "",
      "ES1ox": "-1.09",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.07",
      "DipoleMomentS1": "8.07",
      "DipoleMomentT1": "8.2",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "TVRUMEPBGBBDIZ-UHFFFAOYSA-N",
      "Smiles": "O=S1(=O)C=CC=C1c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=CS1(=O)=O)cc(c5ccc4)C1=CC=CS1(=O)=O)C1=CC=CS1(=O)=O",
      "MolView_url": "http://molview.org/?q=O=S1(=O)C=CC=C1c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=CS1(=O)=O)cc(c5ccc4)C1=CC=CS1(=O)=O)C1=CC=CS1(=O)=O",
      "HOMO": "-5.79",
      "LUMO": "-3.16",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "1.77",
      "E00T1": "",
      "Eox": "-4.31",
      "Ered": "",
      "ES1ox": "-6.08",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "17.8",
      "DipoleMomentS1": "17.8",
      "DipoleMomentT1": "20.01",
      "ionizationPotential": "0.09",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "TVSQJBCFPNMDQA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(s1)C#N)C#N)c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(s1)C#N)C#N)c1ncc(s1)C#N",
      "HOMO": "-7.22",
      "LUMO": "-3.57",
      "VEE": "3.93",
      "Lmax": "315.470737913486",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.38",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.89",
      "DipoleMomentS1": "3.89",
      "DipoleMomentT1": "3.94",
      "ionizationPotential": "8.86",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "TWTHCSPGJSTTDY-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)Cl)c1ccc(s1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)Cl)c1ccc(s1)Cl",
      "HOMO": "-5.57",
      "LUMO": "-2.47",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "2.25",
      "E00T1": "",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.53",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.32",
      "DipoleMomentS1": "7.32",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.32",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "TWUZYTFLECKLTO-IAZLARGGSA-N",
      "Smiles": "Cl/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C/Cl)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C\\Cl)cc(c5ccc4)/C=C\\O/C=C/Cl",
      "MolView_url": "http://molview.org/?q=Cl/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C/Cl)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C\\Cl)cc(c5ccc4)/C=C\\O/C=C/Cl",
      "HOMO": "-5.27",
      "LUMO": "-2.52",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "1.93",
      "E00T1": "0.23",
      "Eox": "-4.19",
      "Ered": "",
      "ES1ox": "-6.12",
      "ES1red": "",
      "ET1ox": "-4.42",
      "ET1red": "",
      "DipoleMomentS0": "4.14",
      "DipoleMomentS1": "4.14",
      "DipoleMomentT1": "4.22",
      "ionizationPotential": "0.01",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "TXURIWKAZPDNHA-UHFFFAOYSA-N",
      "Smiles": "FC(c1coc(n1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1coc(n1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-8.01",
      "LUMO": "-2.98",
      "VEE": "3.56",
      "Lmax": "348.258426966292",
      "E00S1": "3.43",
      "E00T1": "",
      "Eox": "3.05",
      "Ered": "",
      "ES1ox": "-0.38",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.11",
      "DipoleMomentS1": "3.11",
      "DipoleMomentT1": "7.94",
      "ionizationPotential": "9.69",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "TYSAMMJUPXREMB-UHFFFAOYSA-N",
      "Smiles": "COC1=CC=C(C1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(C1)OC",
      "MolView_url": "http://molview.org/?q=COC1=CC=C(C1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C1=CC=C(C1)OC",
      "HOMO": "-5.61",
      "LUMO": "-2.94",
      "VEE": "1.65",
      "Lmax": "751.393939393939",
      "E00S1": "2",
      "E00T1": "1.31",
      "Eox": "0.7",
      "Ered": "",
      "ES1ox": "-1.3",
      "ES1red": "",
      "ET1ox": "-0.61",
      "ET1red": "",
      "DipoleMomentS0": "7.17",
      "DipoleMomentS1": "7.17",
      "DipoleMomentT1": "18.57",
      "ionizationPotential": "6.48",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "TYUZRYABXDVSJK-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)F)c1ncc(s1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(s1)F)c1ncc(s1)F",
      "HOMO": "-6.88",
      "LUMO": "-3.31",
      "VEE": "2.5",
      "Lmax": "495.92",
      "E00S1": "2.78",
      "E00T1": "",
      "Eox": "2.27",
      "Ered": "",
      "ES1ox": "-0.51",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.6",
      "DipoleMomentS1": "1.6",
      "DipoleMomentT1": "5.8",
      "ionizationPotential": "7.93",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "TZEXZMIFWXRMMV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(o1)OC)C#N)c1ncc(o1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(o1)OC)C#N)c1ncc(o1)OC",
      "HOMO": "-6.2",
      "LUMO": "-2.87",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "2.21",
      "Eox": "-1.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.28",
      "ET1red": "",
      "DipoleMomentS0": "9.21",
      "DipoleMomentS1": "9.21",
      "DipoleMomentT1": "12.46",
      "ionizationPotential": "7.5",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "UAFPGYOUFGRHFV-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(s1)O)C#N)c1ncc(s1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(s1)O)C#N)c1ncc(s1)O",
      "HOMO": "-6.38",
      "LUMO": "-3.01",
      "VEE": "3.19",
      "Lmax": "388.652037617555",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.85",
      "DipoleMomentS1": "4.85",
      "DipoleMomentT1": "6.89",
      "ionizationPotential": "7.67",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "UAHYMAHXEZGGJJ-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)[N+](=O)[O-]",
      "HOMO": "-5.51",
      "LUMO": "-2.65",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "2.03",
      "E00T1": "1.22",
      "Eox": "1.24",
      "Ered": "",
      "ES1ox": "-0.79",
      "ES1red": "",
      "ET1ox": "0.02",
      "ET1red": "",
      "DipoleMomentS0": "8.2",
      "DipoleMomentS1": "8.2",
      "DipoleMomentT1": "7.4",
      "ionizationPotential": "6.62",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "UAPPNQRQLFMKPW-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc([nH]1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc([nH]1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-6.77",
      "LUMO": "-2.68",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.15",
      "DipoleMomentS1": "1.15",
      "DipoleMomentT1": "10.75",
      "ionizationPotential": "8.06",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "UATJMJADWAXJQS-QURGRASLSA-N",
      "Smiles": "FC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)F",
      "HOMO": "-5.3",
      "LUMO": "-2.78",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "1.88",
      "E00T1": "0.57",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.96",
      "DipoleMomentS1": "0.96",
      "DipoleMomentT1": "0.66",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "UBCCHNGOWLDYMP-FMQUCBEESA-N",
      "Smiles": "[O-][N+](=O)c1cnc(o1)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1ncc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(o1)c1ccc([nH]1)/N=N/c1ccc([nH]1)c1ncc(o1)[N+](=O)[O-]",
      "HOMO": "-6.27",
      "LUMO": "-3.6",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "2.01",
      "E00T1": "0.93",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "",
      "source_directory": "/scratch/myrthil.n/pyrrole_trans"
    },
    {
      "InchiKey": "UBKTWTNJIRIVSY-FMQUCBEESA-N",
      "Smiles": "Cc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(o1)C",
      "HOMO": "-5.4",
      "LUMO": "-2.76",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "1.98",
      "E00T1": "",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "-1.49",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.59",
      "DipoleMomentS1": "0.59",
      "DipoleMomentT1": "0.48",
      "ionizationPotential": "6.05",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "UBXPPAFKWLXWJX-UHFFFAOYSA-N",
      "Smiles": "FC#Cc1cc(C#N)cc(c1C#N)C#CF",
      "MolView_url": "http://molview.org/?q=FC#Cc1cc(C#N)cc(c1C#N)C#CF",
      "HOMO": "-7.42",
      "LUMO": "-2.97",
      "VEE": "3.89",
      "Lmax": "318.714652956298",
      "E00S1": "",
      "E00T1": "2.99",
      "Eox": "-0.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.8",
      "ET1red": "",
      "DipoleMomentS0": "0.54",
      "DipoleMomentS1": "0.54",
      "DipoleMomentT1": "0.4",
      "ionizationPotential": "9.21",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "UCMVMRBWSIQSAO-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc([nH]1)Cl)C#N)c1ccc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc([nH]1)Cl)C#N)c1ccc([nH]1)Cl",
      "HOMO": "-6.2",
      "LUMO": "-2.78",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "2.33",
      "Eox": "-1.14",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.47",
      "ET1red": "",
      "DipoleMomentS0": "1.55",
      "DipoleMomentS1": "1.55",
      "DipoleMomentT1": "1.74",
      "ionizationPotential": "7.72",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "UCWMJHQNTGWUIG-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(o1)S)C#N)c1ccc(o1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(o1)S)C#N)c1ccc(o1)S",
      "HOMO": "-5.97",
      "LUMO": "-2.78",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "1.83",
      "Eox": "-1.22",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.05",
      "ET1red": "",
      "DipoleMomentS0": "1.35",
      "DipoleMomentS1": "1.35",
      "DipoleMomentT1": "10.65",
      "ionizationPotential": "7.32",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "UDROOWQCQSGKRM-UHFFFAOYSA-N",
      "Smiles": "COc1cnc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(s1)OC",
      "HOMO": "-6.42",
      "LUMO": "-3.22",
      "VEE": "2.38",
      "Lmax": "520.924369747899",
      "E00S1": "2.5",
      "E00T1": "1.98",
      "Eox": "1.68",
      "Ered": "",
      "ES1ox": "-0.82",
      "ES1red": "",
      "ET1ox": "-0.3",
      "ET1red": "",
      "DipoleMomentS0": "4.32",
      "DipoleMomentS1": "4.32",
      "DipoleMomentT1": "10.08",
      "ionizationPotential": "7.25",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "UEKJARWOMMTSJQ-FMQUCBEESA-N",
      "Smiles": "FC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(S1(=O)=O)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(S1(=O)=O)F",
      "HOMO": "-6.12",
      "LUMO": "-3.59",
      "VEE": "2.49",
      "Lmax": "497.911646586345",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.67",
      "DipoleMomentS1": "6.67",
      "DipoleMomentT1": "5.64",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "UEUSUSMWZMXYRD-UHFFFAOYSA-N",
      "Smiles": "Cc1cnc(o1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1cnc(o1)[C@]1(C)N=N1",
      "HOMO": "-6.81",
      "LUMO": "-2.24",
      "VEE": "3.15",
      "Lmax": "393.587301587302",
      "E00S1": "2.96",
      "E00T1": "",
      "Eox": "1.83",
      "Ered": "",
      "ES1ox": "-1.13",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.17",
      "DipoleMomentS1": "2.17",
      "DipoleMomentT1": "8.33",
      "ionizationPotential": "8.18",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "UFMJYPCNGWAXIE-UHFFFAOYSA-N",
      "Smiles": "Cc1cnc(o1)c1cc2c3cc(c4ncc(o4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)C)cc(c5ccc4)c1ncc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(o1)c1cc2c3cc(c4ncc(o4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)C)cc(c5ccc4)c1ncc(o1)C",
      "HOMO": "-5.5",
      "LUMO": "-2.86",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "1.83",
      "E00T1": "",
      "Eox": "-4.28",
      "Ered": "",
      "ES1ox": "-6.11",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.85",
      "DipoleMomentS1": "4.85",
      "DipoleMomentT1": "4.75",
      "ionizationPotential": "5.98",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "UGYLFQRWGIFICE-FMQUCBEESA-N",
      "Smiles": "Oc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)O",
      "HOMO": "-5.11",
      "LUMO": "-2.7",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "0.67",
      "Eox": "0.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.5",
      "ET1red": "",
      "DipoleMomentS0": "1.11",
      "DipoleMomentS1": "1.11",
      "DipoleMomentT1": "1.76",
      "ionizationPotential": "5.69",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "UGYLFQRWGIFICE-VXPUYCOJSA-N",
      "Smiles": "Oc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)O",
      "HOMO": "-5.39",
      "LUMO": "-2.51",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "",
      "E00T1": "-0.05",
      "Eox": "-0.6",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.55",
      "ET1red": "",
      "DipoleMomentS0": "12.11",
      "DipoleMomentS1": "12.11",
      "DipoleMomentT1": "2.35",
      "ionizationPotential": "5.66",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "UGZFFZJQBFVQJR-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(o1)c1cc2c3cc(c4ncc(o4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)[N+](=O)[O-])cc(c5ccc4)c1ncc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(o1)c1cc2c3cc(c4ncc(o4)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)[N+](=O)[O-])cc(c5ccc4)c1ncc(o1)[N+](=O)[O-]",
      "HOMO": "-6.06",
      "LUMO": "-3.76",
      "VEE": "2.74",
      "Lmax": "452.481751824818",
      "E00S1": "1.59",
      "E00T1": "0.92",
      "Eox": "-4.03",
      "Ered": "",
      "ES1ox": "-5.62",
      "ES1red": "",
      "ET1ox": "-4.95",
      "ET1red": "",
      "DipoleMomentS0": "11.84",
      "DipoleMomentS1": "11.84",
      "DipoleMomentT1": "12.34",
      "ionizationPotential": "7.17",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "UHPUQOUDFQFJMC-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)[N+](=O)[O-])c1ccc(n1C)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)[N+](=O)[O-])c1ccc(n1C)[N+](=O)[O-]",
      "HOMO": "-6.97",
      "LUMO": "-3.36",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.58",
      "E00T1": "1.95",
      "Eox": "2.19",
      "Ered": "",
      "ES1ox": "-0.39",
      "ES1red": "",
      "ET1ox": "0.24",
      "ET1red": "",
      "DipoleMomentS0": "4.11",
      "DipoleMomentS1": "4.11",
      "DipoleMomentT1": "2.8",
      "ionizationPotential": "8",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "UIDLDMDOITVGKT-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)Cl",
      "HOMO": "-5.56",
      "LUMO": "-2.73",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "1.95",
      "E00T1": "1.16",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.23",
      "ES1red": "",
      "ET1ox": "-0.44",
      "ET1red": "",
      "DipoleMomentS0": "1.31",
      "DipoleMomentS1": "1.31",
      "DipoleMomentT1": "1.01",
      "ionizationPotential": "6.36",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "UIOHCWBTPTVUAA-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(s1)S)C#N)c1ccc(s1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(s1)S)C#N)c1ccc(s1)S",
      "HOMO": "-6.11",
      "LUMO": "-2.84",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "",
      "E00T1": "2.21",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.03",
      "DipoleMomentS1": "0.03",
      "DipoleMomentT1": "3.14",
      "ionizationPotential": "7.52",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "UJBZLGCKTGMOBR-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(n1C)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(n1C)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-7.68",
      "LUMO": "-3.19",
      "VEE": "3.72",
      "Lmax": "333.279569892473",
      "E00S1": "3.3",
      "E00T1": "2.35",
      "Eox": "2.63",
      "Ered": "",
      "ES1ox": "-0.67",
      "ES1red": "",
      "ET1ox": "0.28",
      "ET1red": "",
      "DipoleMomentS0": "4.26",
      "DipoleMomentS1": "4.26",
      "DipoleMomentT1": "9.41",
      "ionizationPotential": "9.18",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "UKCOEFWMCBYOFR-ISLYRVAYSA-N",
      "Smiles": "Oc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)O",
      "HOMO": "-5.9",
      "LUMO": "-3.29",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.57",
      "DipoleMomentS1": "2.57",
      "DipoleMomentT1": "3.03",
      "ionizationPotential": "6.67",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "UKCOEFWMCBYOFR-ZCXUNETKSA-N",
      "Smiles": "Oc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)O",
      "HOMO": "-5.89",
      "LUMO": "-3.23",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.14",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.78",
      "ET1red": "",
      "DipoleMomentS0": "5.11",
      "DipoleMomentS1": "5.11",
      "DipoleMomentT1": "4.54",
      "ionizationPotential": "6.64",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "UKMWUDVPYPXHRQ-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(s1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1ccc(s1)[C@]1(C)N=N1",
      "HOMO": "-6.4",
      "LUMO": "-2.02",
      "VEE": "3.41",
      "Lmax": "363.577712609971",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.44",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.18",
      "DipoleMomentS1": "3.18",
      "DipoleMomentT1": "9.33",
      "ionizationPotential": "7.67",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "UKUWUJSFPMRTPY-UHFFFAOYSA-N",
      "Smiles": "Cc1csc(n1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1csc(n1)[C@]1(C)N=N1",
      "HOMO": "-6.84",
      "LUMO": "-2.27",
      "VEE": "3.2",
      "Lmax": "387.4375",
      "E00S1": "2.97",
      "E00T1": "2.37",
      "Eox": "1.83",
      "Ered": "",
      "ES1ox": "-1.14",
      "ES1red": "",
      "ET1ox": "-0.54",
      "ET1red": "",
      "DipoleMomentS0": "2.25",
      "DipoleMomentS1": "2.25",
      "DipoleMomentT1": "8.02",
      "ionizationPotential": "8.17",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ULAPAMUJTGYXPK-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(o1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1ccc(o1)[C@]1(C)N=N1",
      "HOMO": "-6.27",
      "LUMO": "-2.03",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "2.67",
      "E00T1": "2.06",
      "Eox": "1.34",
      "Ered": "",
      "ES1ox": "-1.33",
      "ES1red": "",
      "ET1ox": "-0.72",
      "ET1red": "",
      "DipoleMomentS0": "3.29",
      "DipoleMomentS1": "3.29",
      "DipoleMomentT1": "9.77",
      "ionizationPotential": "7.66",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ULLAZZPASNQPCL-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1occ(n1)OC)C#N)c1occ(n1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1occ(n1)OC)C#N)c1occ(n1)OC",
      "HOMO": "-6.41",
      "LUMO": "-3.07",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "2.23",
      "Eox": "-0.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.09",
      "ET1red": "",
      "DipoleMomentS0": "1.19",
      "DipoleMomentS1": "1.19",
      "DipoleMomentT1": "3.78",
      "ionizationPotential": "7.72",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ULXCQBAOMKNJKG-UHFFFAOYSA-N",
      "Smiles": "Sc1c[nH]c(c1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1c[nH]c(c1)[C@]1(S)N=N1",
      "HOMO": "-6.44",
      "LUMO": "-2.29",
      "VEE": "3.31",
      "Lmax": "374.561933534743",
      "E00S1": "2.69",
      "E00T1": "1.96",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "-1.54",
      "ES1red": "",
      "ET1ox": "-0.81",
      "ET1red": "",
      "DipoleMomentS0": "4.06",
      "DipoleMomentS1": "4.06",
      "DipoleMomentT1": "11.58",
      "ionizationPotential": "7.5",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "UMJAUFVJNJCRFD-FMQUCBEESA-N",
      "Smiles": "Fc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)F",
      "HOMO": "-6.22",
      "LUMO": "-3.34",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.58",
      "DipoleMomentS1": "7.58",
      "DipoleMomentT1": "7.52",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "UMJAUFVJNJCRFD-VXPUYCOJSA-N",
      "Smiles": "Fc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1coc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1occ(n1)F",
      "HOMO": "-6.18",
      "LUMO": "-3.26",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.95",
      "ET1red": "",
      "DipoleMomentS0": "5.42",
      "DipoleMomentS1": "5.42",
      "DipoleMomentT1": "5.74",
      "ionizationPotential": "7.04",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "UNBYLUXKWAMVTM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)C#N",
      "HOMO": "-5.53",
      "LUMO": "-2.74",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "1.91",
      "E00T1": "1.15",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.55",
      "DipoleMomentS1": "10.55",
      "DipoleMomentT1": "11.14",
      "ionizationPotential": "6.52",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "UNDUTZUGSQJIKZ-YPKPFQOOSA-N",
      "Smiles": "Oc1nnc(nn1)c1ccc(o1)/N=N\\c1ccc(o1)c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=Oc1nnc(nn1)c1ccc(o1)/N=N\\c1ccc(o1)c1nnc(nn1)O",
      "HOMO": "-6.54",
      "LUMO": "-3.39",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "1.46",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.59",
      "DipoleMomentS1": "7.59",
      "DipoleMomentT1": "7.42",
      "ionizationPotential": "0",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "UNINBBOUVKIBHT-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(s1)C)C#N)c1ncc(s1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(s1)C)C#N)c1ncc(s1)C",
      "HOMO": "-6.61",
      "LUMO": "-3.07",
      "VEE": "3.43",
      "Lmax": "361.457725947522",
      "E00S1": "",
      "E00T1": "2.32",
      "Eox": "-1.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.34",
      "ET1red": "",
      "DipoleMomentS0": "7.26",
      "DipoleMomentS1": "7.26",
      "DipoleMomentT1": "8.82",
      "ionizationPotential": "7.84",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "UNKFGSSRVYXUBA-VCFJNTAESA-N",
      "Smiles": "FC(/C=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C\\C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C/c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C\\C(F)(F)F)(F)F",
      "HOMO": "-5.55",
      "LUMO": "-2.71",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "",
      "E00T1": "1.15",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.41",
      "ET1red": "",
      "DipoleMomentS0": "7.29",
      "DipoleMomentS1": "7.29",
      "DipoleMomentT1": "7.93",
      "ionizationPotential": "6.62",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "UNLMOHZREVDJIH-UHFFFAOYSA-N",
      "Smiles": "COc1ccc(n1C=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(n1C=O)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)OC",
      "HOMO": "-6.07",
      "LUMO": "-3.09",
      "VEE": "2.07",
      "Lmax": "598.937198067633",
      "E00S1": "2.02",
      "E00T1": "1.57",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "-0.87",
      "ES1red": "",
      "ET1ox": "-0.42",
      "ET1red": "",
      "DipoleMomentS0": "6.24",
      "DipoleMomentS1": "6.24",
      "DipoleMomentT1": "18.77",
      "ionizationPotential": "6.89",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "UNNSHJUVFWRJPD-UHFFFAOYSA-N",
      "Smiles": "Fc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)F",
      "HOMO": "-5.56",
      "LUMO": "-2.82",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "2.09",
      "E00T1": "1.13",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "-1.37",
      "ES1red": "",
      "ET1ox": "-0.41",
      "ET1red": "",
      "DipoleMomentS0": "6.91",
      "DipoleMomentS1": "6.91",
      "DipoleMomentT1": "6.95",
      "ionizationPotential": "6.41",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "UOLWBYCRPRFVKR-ISLYRVAYSA-N",
      "Smiles": "SC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CS",
      "MolView_url": "http://molview.org/?q=SC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CS",
      "HOMO": "-6.15",
      "LUMO": "-2.78",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "UOSNPUMQCZVRHZ-UHFFFAOYSA-N",
      "Smiles": "Clc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)Cl",
      "HOMO": "-5.35",
      "LUMO": "-2.46",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.01",
      "E00T1": "1.2",
      "Eox": "0.52",
      "Ered": "",
      "ES1ox": "-1.49",
      "ES1red": "",
      "ET1ox": "-0.68",
      "ET1red": "",
      "DipoleMomentS0": "2.33",
      "DipoleMomentS1": "2.33",
      "DipoleMomentT1": "1.11",
      "ionizationPotential": "6.19",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "UPBPDHNJGDAZDT-QURGRASLSA-N",
      "Smiles": "COc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)OC",
      "HOMO": "-5.12",
      "LUMO": "-2.71",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "",
      "E00T1": "0.07",
      "Eox": "0.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "9.14",
      "DipoleMomentS1": "9.14",
      "DipoleMomentT1": "2.41",
      "ionizationPotential": "5.66",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "UPFQSHJPTCTYHG-VHXPQNKSSA-N",
      "Smiles": "Cc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)C",
      "HOMO": "-5.79",
      "LUMO": "-2.88",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.42",
      "DipoleMomentS1": "7.42",
      "DipoleMomentT1": "5.88",
      "ionizationPotential": "6.28",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "UPFQSHJPTCTYHG-WCWDXBQESA-N",
      "Smiles": "Cc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)C",
      "HOMO": "-5.79",
      "LUMO": "-2.9",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.08",
      "DipoleMomentS1": "0.08",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.32",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "UPKBTDFDRJUFCQ-OCEACIFDSA-N",
      "Smiles": "N#Cc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)C#N",
      "HOMO": "-6.28",
      "LUMO": "-3.38",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "0.87",
      "Eox": "1.39",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.52",
      "ET1red": "",
      "DipoleMomentS0": "11.38",
      "DipoleMomentS1": "11.38",
      "DipoleMomentT1": "11.76",
      "ionizationPotential": "7.21",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "UPKBTDFDRJUFCQ-QPLCGJKRSA-N",
      "Smiles": "N#Cc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)C#N",
      "HOMO": "-6.23",
      "LUMO": "-3.3",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.95",
      "ET1red": "",
      "DipoleMomentS0": "4.25",
      "DipoleMomentS1": "4.25",
      "DipoleMomentT1": "5.09",
      "ionizationPotential": "7.13",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "UQBCVMRECREKJX-DQRAZIAOSA-N",
      "Smiles": "Cc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc([nH]1)C",
      "HOMO": "-5.27",
      "LUMO": "-2.76",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.13",
      "Eox": "0.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.11",
      "ET1red": "",
      "DipoleMomentS0": "8.17",
      "DipoleMomentS1": "8.17",
      "DipoleMomentT1": "8.2",
      "ionizationPotential": "5.87",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "UQBCVMRECREKJX-QURGRASLSA-N",
      "Smiles": "Cc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)C",
      "HOMO": "-5.18",
      "LUMO": "-2.76",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.31",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.01",
      "DipoleMomentS1": "0.01",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.84",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "UQVUPSANBQZQEL-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(s1)c1cc2c3cc(c4ccc(s4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)C(F)(F)F)cc(c5ccc4)c1ccc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(s1)c1cc2c3cc(c4ccc(s4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)C(F)(F)F)cc(c5ccc4)c1ccc(s1)C(F)(F)F)(F)F",
      "HOMO": "-5.6",
      "LUMO": "-2.79",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "1.97",
      "E00T1": "1.16",
      "Eox": "-4.1",
      "Ered": "",
      "ES1ox": "-6.07",
      "ES1red": "",
      "ET1ox": "-5.26",
      "ET1red": "",
      "DipoleMomentS0": "3.34",
      "DipoleMomentS1": "3.34",
      "DipoleMomentT1": "2.86",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "URRGAFPHSFSTBO-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)F)c1nnc(nn1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1nnc(nn1)F)c1nnc(nn1)F",
      "HOMO": "-7.66",
      "LUMO": "-3.63",
      "VEE": "1.58",
      "Lmax": "784.683544303797",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.4",
      "DipoleMomentS1": "3.4",
      "DipoleMomentT1": "3.56",
      "ionizationPotential": "8.71",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "URZFXQISWCTQBI-OCEACIFDSA-N",
      "Smiles": "FC(C1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "HOMO": "-6.48",
      "LUMO": "-3.93",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "0.54",
      "Eox": "1.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.98",
      "ET1red": "",
      "DipoleMomentS0": "10.34",
      "DipoleMomentS1": "10.34",
      "DipoleMomentT1": "9.72",
      "ionizationPotential": "7.51",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "URZFXQISWCTQBI-QPLCGJKRSA-N",
      "Smiles": "FC(C1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)C(F)(F)F)(F)F",
      "HOMO": "-6.4",
      "LUMO": "-3.78",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "-0.33",
      "Eox": "1.35",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.68",
      "ET1red": "",
      "DipoleMomentS0": "11.59",
      "DipoleMomentS1": "11.59",
      "DipoleMomentT1": "16.57",
      "ionizationPotential": "7.35",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "USOZWLFFKKBOAI-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(n1C=O)Cl)C#N)c1ccc(n1C=O)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(n1C=O)Cl)C#N)c1ccc(n1C=O)Cl",
      "HOMO": "-6.48",
      "LUMO": "-2.97",
      "VEE": "3.03",
      "Lmax": "409.174917491749",
      "E00S1": "",
      "E00T1": "1.98",
      "Eox": "-0.96",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.94",
      "ET1red": "",
      "DipoleMomentS0": "3.54",
      "DipoleMomentS1": "3.54",
      "DipoleMomentT1": "1.61",
      "ionizationPotential": "7.89",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "UTAZHTUKEIYLOY-UHFFFAOYSA-N",
      "Smiles": "n1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cnc1",
      "MolView_url": "http://molview.org/?q=n1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cnc1",
      "HOMO": "-5.42",
      "LUMO": "-2.48",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "2.05",
      "E00T1": "1.22",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.67",
      "DipoleMomentS1": "2.67",
      "DipoleMomentT1": "1.67",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "UTRXCHHWPIEYIX-UHFFFAOYSA-N",
      "Smiles": "C/N=C\\c1cc2c3cc(/C=N\\C)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/C)cc(c5ccc4)/C=N\\C",
      "MolView_url": "http://molview.org/?q=C/N=C\\c1cc2c3cc(/C=N\\C)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/C)cc(c5ccc4)/C=N\\C",
      "HOMO": "-5.58",
      "LUMO": "-2.8",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "1.96",
      "E00T1": "",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "-1.31",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.07",
      "DipoleMomentS1": "3.07",
      "DipoleMomentT1": "3.11",
      "ionizationPotential": "6.29",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "UUDOLQUPNYRWGC-UHFFFAOYSA-N",
      "Smiles": "FC(C1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)C(F)(F)F)cc(c5ccc4)C1=CC=C(C1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(C1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)C(F)(F)F)cc(c5ccc4)C1=CC=C(C1)C(F)(F)F)(F)F",
      "HOMO": "-5.5",
      "LUMO": "-2.58",
      "VEE": "3.04",
      "Lmax": "407.828947368421",
      "E00S1": "1.99",
      "E00T1": "",
      "Eox": "-4.26",
      "Ered": "",
      "ES1ox": "-6.25",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.35",
      "DipoleMomentS1": "3.35",
      "DipoleMomentT1": "3.16",
      "ionizationPotential": "0.07",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "UUKCNFFQWJTCGM-UHFFFAOYSA-N",
      "Smiles": "Clc1cc2c3cc(Cl)c4c5c3c(c3c2c2c1cccc2c(c3)Cl)cc(c5ccc4)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cc2c3cc(Cl)c4c5c3c(c3c2c2c1cccc2c(c3)Cl)cc(c5ccc4)Cl",
      "HOMO": "-5.75",
      "LUMO": "-2.74",
      "VEE": "3.02",
      "Lmax": "410.529801324503",
      "E00S1": "2.36",
      "E00T1": "1.25",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "-1.39",
      "ES1red": "",
      "ET1ox": "-0.28",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "UVPMWAUAFSWDAY-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc2c3cc(C#N)c4c5c3c(c3c2c2c1cccc2c(c3)C#N)cc(c5ccc4)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc2c3cc(C#N)c4c5c3c(c3c2c2c1cccc2c(c3)C#N)cc(c5ccc4)C#N",
      "HOMO": "-6.27",
      "LUMO": "-3.47",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.52",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.62",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "UWMYFXPXONHEAW-UHFFFAOYSA-N",
      "Smiles": "ClC#C[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=ClC#C[C@]1(Cl)N=N1",
      "HOMO": "-7.67",
      "LUMO": "-2.72",
      "VEE": "3.17",
      "Lmax": "391.104100946372",
      "E00S1": "3.09",
      "E00T1": "2.52",
      "Eox": "2.74",
      "Ered": "",
      "ES1ox": "-0.35",
      "ES1red": "",
      "ET1ox": "0.22",
      "ET1red": "",
      "DipoleMomentS0": "2.11",
      "DipoleMomentS1": "2.11",
      "DipoleMomentT1": "5.98",
      "ionizationPotential": "9.28",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "UWQDTQWGUPLMNI-FMQUCBEESA-N",
      "Smiles": "Clc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)Cl",
      "HOMO": "-6.14",
      "LUMO": "-3.39",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "",
      "E00T1": "0.77",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.97",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "UWQDTQWGUPLMNI-VXPUYCOJSA-N",
      "Smiles": "Clc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1csc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1scc(n1)Cl",
      "HOMO": "-6.09",
      "LUMO": "-3.26",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "",
      "E00T1": "0.19",
      "Eox": "1.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.88",
      "ET1red": "",
      "DipoleMomentS0": "10.59",
      "DipoleMomentS1": "10.59",
      "DipoleMomentT1": "8.38",
      "ionizationPotential": "6.89",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "UWRWLWRXCLCEAL-ISLYRVAYSA-N",
      "Smiles": "C#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#C",
      "MolView_url": "http://molview.org/?q=C#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#C",
      "HOMO": "-6.67",
      "LUMO": "-2.89",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.55",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "UWYCWGVQWXUALU-BUHFOSPRSA-N",
      "Smiles": "Sc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)S",
      "MolView_url": "http://molview.org/?q=Sc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)S",
      "HOMO": "-6.41",
      "LUMO": "-3.48",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "2.13",
      "E00T1": "0.76",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "-0.67",
      "ES1red": "",
      "ET1ox": "0.7",
      "ET1red": "",
      "DipoleMomentS0": "0.02",
      "DipoleMomentS1": "0.02",
      "DipoleMomentT1": "1.52",
      "ionizationPotential": "7.27",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "UXLKPVVWAOUPLI-FMQUCBEESA-N",
      "Smiles": "Clc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)Cl",
      "HOMO": "-6.05",
      "LUMO": "-3.3",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "2.02",
      "E00T1": "0.74",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.29",
      "DipoleMomentS1": "1.29",
      "DipoleMomentT1": "1.65",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "UXOJKJMWEMMJAH-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)Cl)cc(c5ccc4)c1ccc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)Cl)cc(c5ccc4)c1ccc(cn1)Cl",
      "HOMO": "-5.52",
      "LUMO": "-2.66",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.2",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "UYIARXYFPGMNDC-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cccn1C)c1cccn1C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1cccn1C)c1cccn1C",
      "HOMO": "-6.1",
      "LUMO": "-3.05",
      "VEE": "2.17",
      "Lmax": "571.336405529954",
      "E00S1": "2.33",
      "E00T1": "1.82",
      "Eox": "1.35",
      "Ered": "",
      "ES1ox": "-0.98",
      "ES1red": "",
      "ET1ox": "-0.47",
      "ET1red": "",
      "DipoleMomentS0": "3.89",
      "DipoleMomentS1": "3.89",
      "DipoleMomentT1": "7.1",
      "ionizationPotential": "6.98",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "UYVMSUAKFQZQHD-VHXPQNKSSA-N",
      "Smiles": "Cc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)C",
      "HOMO": "-6.11",
      "LUMO": "-3.07",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.34",
      "DipoleMomentS1": "8.34",
      "DipoleMomentT1": "6.86",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "UYVMSUAKFQZQHD-WCWDXBQESA-N",
      "Smiles": "Cc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)C",
      "HOMO": "-6.17",
      "LUMO": "-3.15",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.22",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.37",
      "DipoleMomentS1": "2.37",
      "DipoleMomentT1": "2.21",
      "ionizationPotential": "6.68",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "UZNLQIGOGAPYBS-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(s1)c1cc2c3cc(c4ncc(s4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)F)cc(c5ccc4)c1ncc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(s1)c1cc2c3cc(c4ncc(s4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)F)cc(c5ccc4)c1ncc(s1)F",
      "HOMO": "-5.66",
      "LUMO": "-2.87",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "1.92",
      "E00T1": "1.14",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.09",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "2.73",
      "DipoleMomentS1": "2.73",
      "DipoleMomentT1": "3.5",
      "ionizationPotential": "6.43",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "UZOGZEJPMLNCJB-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)F",
      "HOMO": "-7.09",
      "LUMO": "-3.18",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "3.02",
      "E00T1": "2.4",
      "Eox": "2.2",
      "Ered": "",
      "ES1ox": "-0.82",
      "ES1red": "",
      "ET1ox": "-0.2",
      "ET1red": "",
      "DipoleMomentS0": "4.72",
      "DipoleMomentS1": "4.72",
      "DipoleMomentT1": "7.46",
      "ionizationPotential": "8.03",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "UZZWLIKGPUNPRF-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(o1)c1cc2c3cc(c4ncc(o4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)Cl)cc(c5ccc4)c1ncc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(o1)c1cc2c3cc(c4ncc(o4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)Cl)cc(c5ccc4)c1ncc(o1)Cl",
      "HOMO": "-5.66",
      "LUMO": "-3.02",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.53",
      "DipoleMomentS1": "1.53",
      "DipoleMomentT1": "1.5",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "VAJVCZWQJMCWIP-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccccc1)c1ccccc1",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccccc1)c1ccccc1",
      "HOMO": "-6.9",
      "LUMO": "-3.1",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "2.97",
      "E00T1": "2.36",
      "Eox": "2.01",
      "Ered": "",
      "ES1ox": "-0.96",
      "ES1red": "",
      "ET1ox": "-0.35",
      "ET1red": "",
      "DipoleMomentS0": "0.93",
      "DipoleMomentS1": "0.93",
      "DipoleMomentT1": "4.02",
      "ionizationPotential": "7.8",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VAVBHVWTOFIHEX-UHFFFAOYSA-N",
      "Smiles": "CC1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(S1(=O)=O)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(S1(=O)=O)C",
      "HOMO": "-5.56",
      "LUMO": "-2.87",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "",
      "E00T1": "1.04",
      "Eox": "0.75",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.29",
      "ET1red": "",
      "DipoleMomentS0": "7",
      "DipoleMomentS1": "7",
      "DipoleMomentT1": "6.05",
      "ionizationPotential": "6.39",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VCLQYNRUAXGOCV-FMQUCBEESA-N",
      "Smiles": "OC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)O",
      "HOMO": "-5.07",
      "LUMO": "-2.79",
      "VEE": "2.5",
      "Lmax": "495.92",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.77",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "VCLQYNRUAXGOCV-VXPUYCOJSA-N",
      "Smiles": "OC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)O",
      "HOMO": "-5.08",
      "LUMO": "-2.74",
      "VEE": "2.29",
      "Lmax": "541.397379912664",
      "E00S1": "",
      "E00T1": "-0.09",
      "Eox": "0.05",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.14",
      "ET1red": "",
      "DipoleMomentS0": "6.77",
      "DipoleMomentS1": "6.77",
      "DipoleMomentT1": "3.25",
      "ionizationPotential": "5.7",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "VCOXFTXTTXOMFC-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)F",
      "HOMO": "-5.33",
      "LUMO": "-2.52",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "2.15",
      "E00T1": "1.15",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.49",
      "DipoleMomentS1": "2.49",
      "DipoleMomentT1": "2.5",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VCXKXBKOHCHWIO-OCEACIFDSA-N",
      "Smiles": "Clc1ncc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cnc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ncc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cnc([nH]1)Cl",
      "HOMO": "-6.15",
      "LUMO": "-2.77",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "1.08",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.19",
      "ET1red": "",
      "DipoleMomentS0": "5.02",
      "DipoleMomentS1": "5.02",
      "DipoleMomentT1": "4.67",
      "ionizationPotential": "7.02",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "VDLSVTNUBHNLJC-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)F)cc(c5ccc4)c1ccc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(nc1)c1cc2c3cc(c4ccc(cn4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)F)cc(c5ccc4)c1ccc(cn1)F",
      "HOMO": "-5.5",
      "LUMO": "-2.6",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "2.05",
      "E00T1": "0.24",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "-1.39",
      "ES1red": "",
      "ET1ox": "0.42",
      "ET1red": "",
      "DipoleMomentS0": "3.99",
      "DipoleMomentS1": "3.99",
      "DipoleMomentT1": "3.72",
      "ionizationPotential": "6.17",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "VEECYVLRWODZDX-UHFFFAOYSA-N",
      "Smiles": "Cl/C=C/[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Cl/C=C/[C@]1(Cl)N=N1",
      "HOMO": "-7.41",
      "LUMO": "-2.69",
      "VEE": "3.34",
      "Lmax": "371.197604790419",
      "E00S1": "2.96",
      "E00T1": "",
      "Eox": "2.46",
      "Ered": "",
      "ES1ox": "-0.5",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.67",
      "DipoleMomentS1": "0.67",
      "DipoleMomentT1": "5.47",
      "ionizationPotential": "9.04",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "VFKZMYNQAAXEPG-UHFFFAOYSA-N",
      "Smiles": "Cc1ncc([nH]1)[C@]1(C)N=N1",
      "MolView_url": "http://molview.org/?q=Cc1ncc([nH]1)[C@]1(C)N=N1",
      "HOMO": "-6.41",
      "LUMO": "-2.16",
      "VEE": "3.11",
      "Lmax": "398.649517684887",
      "E00S1": "2.62",
      "E00T1": "2.06",
      "Eox": "1.54",
      "Ered": "",
      "ES1ox": "-1.08",
      "ES1red": "",
      "ET1ox": "-0.52",
      "ET1red": "",
      "DipoleMomentS0": "2.14",
      "DipoleMomentS1": "2.14",
      "DipoleMomentT1": "4.86",
      "ionizationPotential": "7.8",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "VFQBHMRYZSHOSN-UHFFFAOYSA-N",
      "Smiles": "c1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccccc1",
      "MolView_url": "http://molview.org/?q=c1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccccc1",
      "HOMO": "-5.38",
      "LUMO": "-2.38",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.14",
      "E00T1": "1.26",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.28",
      "DipoleMomentS1": "0.28",
      "DipoleMomentT1": "0.34",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VFWAKHCXWFHKRR-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1cnc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-7.15",
      "LUMO": "-3.36",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "2.76",
      "E00T1": "2.38",
      "Eox": "2.07",
      "Ered": "",
      "ES1ox": "-0.69",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "1.2",
      "DipoleMomentS1": "1.2",
      "DipoleMomentT1": "2.79",
      "ionizationPotential": "7.98",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VFXRUYDIWUFTBQ-UHFFFAOYSA-N",
      "Smiles": "COc1ncc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cnc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ncc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cnc([nH]1)OC",
      "HOMO": "-6.1",
      "LUMO": "-3.11",
      "VEE": "2.01",
      "Lmax": "616.81592039801",
      "E00S1": "2.27",
      "E00T1": "",
      "Eox": "1.26",
      "Ered": "",
      "ES1ox": "-1.01",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.02",
      "DipoleMomentS1": "5.02",
      "DipoleMomentT1": "9.98",
      "ionizationPotential": "6.99",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VFZRSEVIRWTEIO-FLWNBWAVSA-N",
      "Smiles": "FC(Oc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-6.12",
      "LUMO": "-3.12",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.83",
      "ET1red": "",
      "DipoleMomentS0": "5.13",
      "DipoleMomentS1": "5.13",
      "DipoleMomentT1": "5.15",
      "ionizationPotential": "6.87",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "VFZRSEVIRWTEIO-QVIHXGFCSA-N",
      "Smiles": "FC(Oc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-6.16",
      "LUMO": "-3.18",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.43",
      "DipoleMomentS1": "7.43",
      "DipoleMomentT1": "7.88",
      "ionizationPotential": "6.95",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "VGCGZYXOZFLPHD-ISLYRVAYSA-N",
      "Smiles": "Oc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)O",
      "HOMO": "-5.7",
      "LUMO": "-3.03",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "2",
      "E00T1": "0.72",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.38",
      "DipoleMomentS1": "1.38",
      "DipoleMomentT1": "5.97",
      "ionizationPotential": "6.38",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "VGDHAPXIFDONTJ-DQRAZIAOSA-N",
      "Smiles": "FC(c1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)C(F)(F)F)(F)F",
      "HOMO": "-6.72",
      "LUMO": "-3.71",
      "VEE": "2.25",
      "Lmax": "551.022222222222",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.43",
      "ET1red": "",
      "DipoleMomentS0": "0.27",
      "DipoleMomentS1": "0.27",
      "DipoleMomentT1": "0.31",
      "ionizationPotential": "7.82",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "VGDHAPXIFDONTJ-QURGRASLSA-N",
      "Smiles": "FC(c1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)C(F)(F)F)(F)F",
      "HOMO": "-6.84",
      "LUMO": "-3.88",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.33",
      "DipoleMomentS1": "0.33",
      "DipoleMomentT1": "4.51",
      "ionizationPotential": "7.98",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "VGIHWABVDVIVFK-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)/C=C/[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/C=C/[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-8.83",
      "LUMO": "-3.5",
      "VEE": "3.76",
      "Lmax": "329.734042553192",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.86",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.1",
      "DipoleMomentS1": "3.1",
      "DipoleMomentT1": "4.93",
      "ionizationPotential": "10.75",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "VGOBUGDELNQBLP-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(cc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cc1)C",
      "HOMO": "-5.35",
      "LUMO": "-2.36",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.43",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.56",
      "DipoleMomentS1": "1.56",
      "DipoleMomentT1": "1.71",
      "ionizationPotential": "6.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VGOGOTKWMWRSDZ-UHFFFAOYSA-N",
      "Smiles": "COc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc([nH]1)OC",
      "HOMO": "-5.05",
      "LUMO": "-2.34",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "1.85",
      "E00T1": "1.13",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "-1.14",
      "ES1red": "",
      "ET1ox": "-0.42",
      "ET1red": "",
      "DipoleMomentS0": "3.9",
      "DipoleMomentS1": "3.9",
      "DipoleMomentT1": "5.5",
      "ionizationPotential": "5.63",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VGPGUDNAXMDJIG-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)[N+](=O)[O-])c1[nH]cc(c1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)[N+](=O)[O-])c1[nH]cc(c1)[N+](=O)[O-]",
      "HOMO": "-5.64",
      "LUMO": "-2.62",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "1.61",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.82",
      "DipoleMomentS1": "9.82",
      "DipoleMomentT1": "11.4",
      "ionizationPotential": "6.74",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "VGRNIPXRSAUBAG-UHFFFAOYSA-N",
      "Smiles": "FC(c1nnc(nn1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1nnc(nn1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-7.97",
      "LUMO": "-3.91",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "2.1",
      "E00T1": "1.43",
      "Eox": "3.11",
      "Ered": "",
      "ES1ox": "1.01",
      "ES1red": "",
      "ET1ox": "1.68",
      "ET1red": "",
      "DipoleMomentS0": "1.51",
      "DipoleMomentS1": "1.51",
      "DipoleMomentT1": "1.43",
      "ionizationPotential": "9.62",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "VHLGNIDZOUYILC-QURGRASLSA-N",
      "Smiles": "Clc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)Cl",
      "HOMO": "-5.33",
      "LUMO": "-2.71",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.44",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.13",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "VHRDCPICNVFIFR-UHFFFAOYSA-N",
      "Smiles": "FC1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)F)C1=CC=C(C1)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(C1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)C1=CC=C(C1)F)C1=CC=C(C1)F",
      "HOMO": "-5.35",
      "LUMO": "-2.39",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.14",
      "E00T1": "1.53",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.95",
      "DipoleMomentS1": "6.95",
      "DipoleMomentT1": "8.07",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "VHTLNAXNOJENOQ-UHFFFAOYSA-N",
      "Smiles": "FC#Cc1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C#CF",
      "MolView_url": "http://molview.org/?q=FC#Cc1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C#CF",
      "HOMO": "-5.49",
      "LUMO": "-2.58",
      "VEE": "1.43",
      "Lmax": "866.993006993007",
      "E00S1": "",
      "E00T1": "1.2",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.48",
      "ET1red": "",
      "DipoleMomentS0": "2.64",
      "DipoleMomentS1": "2.64",
      "DipoleMomentT1": "2.53",
      "ionizationPotential": "6.4",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VIAAGTGCGOKRCQ-DQRAZIAOSA-N",
      "Smiles": "Fc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)F",
      "MolView_url": "http://molview.org/?q=Fc1cc([nH]c1)c1ccc(s1)/N=N\\c1ccc(s1)c1[nH]cc(c1)F",
      "HOMO": "-5.57",
      "LUMO": "-2.89",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.37",
      "ET1red": "",
      "DipoleMomentS0": "11.17",
      "DipoleMomentS1": "11.17",
      "DipoleMomentT1": "8.31",
      "ionizationPotential": "6.36",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "VIAAGTGCGOKRCQ-QURGRASLSA-N",
      "Smiles": "Fc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)F",
      "MolView_url": "http://molview.org/?q=Fc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)F",
      "HOMO": "-5.5",
      "LUMO": "-2.9",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "0.71",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.16",
      "ET1red": "",
      "DipoleMomentS0": "2.92",
      "DipoleMomentS1": "2.92",
      "DipoleMomentT1": "3.64",
      "ionizationPotential": "6.34",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "VIWSVDCWAXIXFH-UHFFFAOYSA-N",
      "Smiles": "Clc1coc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1occ(n1)Cl)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1occ(n1)Cl)c1occ(n1)Cl",
      "HOMO": "-5.74",
      "LUMO": "-2.63",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "2.26",
      "E00T1": "1.61",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.09",
      "DipoleMomentS1": "8.09",
      "DipoleMomentT1": "8.65",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "VJJHGSBINDPJTE-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)C)c1scc(n1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)C)c1scc(n1)C",
      "HOMO": "-6.7",
      "LUMO": "-3.26",
      "VEE": "2.49",
      "Lmax": "497.911646586345",
      "E00S1": "2.69",
      "E00T1": "2.15",
      "Eox": "1.86",
      "Ered": "",
      "ES1ox": "-0.83",
      "ES1red": "",
      "ET1ox": "-0.29",
      "ET1red": "",
      "DipoleMomentS0": "2.04",
      "DipoleMomentS1": "2.04",
      "DipoleMomentT1": "6.15",
      "ionizationPotential": "7.6",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VJLIFLNBSHPFOH-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)O)C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)O)C1=CC=C(C1)O",
      "HOMO": "-5.65",
      "LUMO": "-2.98",
      "VEE": "1.66",
      "Lmax": "746.867469879518",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.04",
      "DipoleMomentS1": "6.04",
      "DipoleMomentT1": "10.97",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VKGZFKQEBBPAMS-VHXPQNKSSA-N",
      "Smiles": "N#Cc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ncc([nH]1)c1ccc(s1)/N=N\\c1ccc(s1)c1cnc([nH]1)C#N",
      "HOMO": "-6.06",
      "LUMO": "-3.17",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "1.06",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.86",
      "ET1red": "",
      "DipoleMomentS0": "0.66",
      "DipoleMomentS1": "0.66",
      "DipoleMomentT1": "0.31",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "VKGZFKQEBBPAMS-WCWDXBQESA-N",
      "Smiles": "N#Cc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ncc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1cnc([nH]1)C#N",
      "HOMO": "-6.06",
      "LUMO": "-3.25",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.23",
      "DipoleMomentS1": "0.23",
      "DipoleMomentT1": "0.56",
      "ionizationPotential": "7.16",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "VKIJCDQOAVFDNX-ISLYRVAYSA-N",
      "Smiles": "OC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)O",
      "HOMO": "-5.96",
      "LUMO": "-3.43",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "",
      "E00T1": "0.52",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.51",
      "ET1red": "",
      "DipoleMomentS0": "11.54",
      "DipoleMomentS1": "11.54",
      "DipoleMomentT1": "10.68",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "VKIJCDQOAVFDNX-ZCXUNETKSA-N",
      "Smiles": "OC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)O",
      "HOMO": "-6.09",
      "LUMO": "-3.29",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "0.02",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.88",
      "ET1red": "",
      "DipoleMomentS0": "12.53",
      "DipoleMomentS1": "12.53",
      "DipoleMomentT1": "5.24",
      "ionizationPotential": "6.7",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "VLCUFNVWIZJARL-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)F)c1[nH]cc(c1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1[nH]cc(c1)F)c1[nH]cc(c1)F",
      "HOMO": "-6.21",
      "LUMO": "-3.1",
      "VEE": "2.17",
      "Lmax": "571.336405529954",
      "E00S1": "2.15",
      "E00T1": "",
      "Eox": "1.36",
      "Ered": "",
      "ES1ox": "-0.79",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.52",
      "DipoleMomentS1": "3.52",
      "DipoleMomentT1": "4.59",
      "ionizationPotential": "7.27",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VLKDHOQFKGRBEJ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1csc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1scc(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1csc(n1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1scc(n1)C#N",
      "HOMO": "-7.24",
      "LUMO": "-3.4",
      "VEE": "2.64",
      "Lmax": "469.621212121212",
      "E00S1": "3.01",
      "E00T1": "2.41",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.92",
      "DipoleMomentS1": "7.92",
      "DipoleMomentT1": "10.99",
      "ionizationPotential": "8.44",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VLPOLPGMELAAEJ-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(s1)c1cc2c3cc(c4ccc(s4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)C)cc(c5ccc4)c1ccc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(s1)c1cc2c3cc(c4ccc(s4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)C)cc(c5ccc4)c1ccc(s1)C",
      "HOMO": "-5.28",
      "LUMO": "-2.52",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "1.91",
      "E00T1": "1.12",
      "Eox": "0.42",
      "Ered": "",
      "ES1ox": "-1.49",
      "ES1red": "",
      "ET1ox": "-0.7",
      "ET1red": "",
      "DipoleMomentS0": "0.47",
      "DipoleMomentS1": "0.47",
      "DipoleMomentT1": "0.59",
      "ionizationPotential": "0.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "VLPSFTVFLKBLCB-UHFFFAOYSA-N",
      "Smiles": "COc1nnc(nn1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1nnc(nn1)OC",
      "MolView_url": "http://molview.org/?q=COc1nnc(nn1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1nnc(nn1)OC",
      "HOMO": "-7.21",
      "LUMO": "-3.39",
      "VEE": "1.58",
      "Lmax": "784.683544303797",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.17",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.2",
      "DipoleMomentS1": "2.2",
      "DipoleMomentT1": "2.49",
      "ionizationPotential": "8.01",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VNKYVCOETRWKKN-IQRFGFHNSA-N",
      "Smiles": "CO/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C\\OC",
      "MolView_url": "http://molview.org/?q=CO/C=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=C\\OC",
      "HOMO": "-5.18",
      "LUMO": "-2.33",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "2.21",
      "E00T1": "1.16",
      "Eox": "0.37",
      "Ered": "",
      "ES1ox": "-1.84",
      "ES1red": "",
      "ET1ox": "-0.79",
      "ET1red": "",
      "DipoleMomentS0": "3.5",
      "DipoleMomentS1": "3.5",
      "DipoleMomentT1": "3.89",
      "ionizationPotential": "5.9",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VNSPUAVDIIFBHR-WCWDXBQESA-N",
      "Smiles": "N#CC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)C#N",
      "HOMO": "-5.7",
      "LUMO": "-3.23",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "1.81",
      "E00T1": "0.52",
      "Eox": "0.82",
      "Ered": "",
      "ES1ox": "-0.99",
      "ES1red": "",
      "ET1ox": "0.3",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "6.69",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "VOKPFYUGHYVHEN-QURGRASLSA-N",
      "Smiles": "O=Cn1c(F)ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(F)ccc1c1ccc(o1)/N=N/c1ccc(o1)c1ccc(n1C=O)F",
      "HOMO": "-5.68",
      "LUMO": "-2.96",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "2.02",
      "E00T1": "0.69",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "-1.36",
      "ES1red": "",
      "ET1ox": "-0.03",
      "ET1red": "",
      "DipoleMomentS0": "7.82",
      "DipoleMomentS1": "7.82",
      "DipoleMomentT1": "7.81",
      "ionizationPotential": "6.52",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "VPKOACZBTMIKIB-XOHWUJONSA-N",
      "Smiles": "CO/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\OC",
      "MolView_url": "http://molview.org/?q=CO/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\OC",
      "HOMO": "-6.26",
      "LUMO": "-2.98",
      "VEE": "",
      "Lmax": "#DIV/0!",
      "E00S1": "2.51",
      "E00T1": "",
      "Eox": "1.35",
      "Ered": "",
      "ES1ox": "-1.16",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.97",
      "DipoleMomentS1": "5.97",
      "DipoleMomentT1": "15.91",
      "ionizationPotential": "7.23",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VPVLDOKSDQTZFP-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)C)C1=CC=C(C1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(C1)C)C1=CC=C(C1)C",
      "HOMO": "-5.88",
      "LUMO": "-3.03",
      "VEE": "1.79",
      "Lmax": "692.625698324022",
      "E00S1": "2.14",
      "E00T1": "1.63",
      "Eox": "1.17",
      "Ered": "",
      "ES1ox": "-0.97",
      "ES1red": "",
      "ET1ox": "-0.46",
      "ET1red": "",
      "DipoleMomentS0": "4.63",
      "DipoleMomentS1": "4.63",
      "DipoleMomentT1": "9.67",
      "ionizationPotential": "6.81",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VQKNNQCCPGSLPB-VAVLHINXSA-N",
      "Smiles": "FC(O/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\OC(F)(F)F)(F)F",
      "HOMO": "-6.67",
      "LUMO": "-3.53",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.66",
      "DipoleMomentS1": "10.66",
      "DipoleMomentT1": "10.46",
      "ionizationPotential": "7.67",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "VQKNNQCCPGSLPB-WXWJDCRNSA-N",
      "Smiles": "FC(O/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\OC(F)(F)F)(F)F",
      "HOMO": "-6.54",
      "LUMO": "-3.34",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.52",
      "DipoleMomentS1": "3.52",
      "DipoleMomentT1": "2.3",
      "ionizationPotential": "7.51",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "VQRBSAKWBZPUKA-KTKRTIGZSA-N",
      "Smiles": "c1coc(c1)/N=N\\c1ccco1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)/N=N\\c1ccco1",
      "HOMO": "-6.24",
      "LUMO": "-2.69",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "0.37",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.78",
      "ET1red": "",
      "DipoleMomentS0": "6.43",
      "DipoleMomentS1": "6.43",
      "DipoleMomentT1": "5.1",
      "ionizationPotential": "7.25",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "VQRBSAKWBZPUKA-MDZDMXLPSA-N",
      "Smiles": "c1coc(c1)/N=N/c1ccco1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)/N=N/c1ccco1",
      "HOMO": "-6.25",
      "LUMO": "-2.66",
      "VEE": "3.13",
      "Lmax": "396.102236421725",
      "E00S1": "2.84",
      "E00T1": "1.09",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "VQVSCOCWUVEGEA-WCWDXBQESA-N",
      "Smiles": "N#CC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)C#N",
      "MolView_url": "http://molview.org/?q=N#CC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)C#N",
      "HOMO": "-5.79",
      "LUMO": "-3.29",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "0.57",
      "Eox": "0.89",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.32",
      "ET1red": "",
      "DipoleMomentS0": "6.56",
      "DipoleMomentS1": "6.56",
      "DipoleMomentT1": "6.3",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "VRPIRTUPNKMEFV-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(o1)O",
      "HOMO": "-5.37",
      "LUMO": "-2.66",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "2.06",
      "E00T1": "1.12",
      "Eox": "0.62",
      "Ered": "",
      "ES1ox": "-1.44",
      "ES1red": "",
      "ET1ox": "-0.5",
      "ET1red": "",
      "DipoleMomentS0": "5.47",
      "DipoleMomentS1": "5.47",
      "DipoleMomentT1": "4.93",
      "ionizationPotential": "6.09",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VRWMDZVKOREQIL-UHFFFAOYSA-N",
      "Smiles": "Fc1csc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1scc(n1)F)c1scc(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1csc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1scc(n1)F)c1scc(n1)F",
      "HOMO": "-5.72",
      "LUMO": "-2.67",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.14",
      "DipoleMomentS1": "8.14",
      "DipoleMomentT1": "8.59",
      "ionizationPotential": "6.57",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "VSAVIXGAKQYSDV-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1occ(n1)OC(F)(F)F)(F)F",
      "HOMO": "-5.59",
      "LUMO": "-2.84",
      "VEE": "1.45",
      "Lmax": "855.034482758621",
      "E00S1": "1.93",
      "E00T1": "1.14",
      "Eox": "0.78",
      "Ered": "",
      "ES1ox": "-1.15",
      "ES1red": "",
      "ET1ox": "-0.36",
      "ET1red": "",
      "DipoleMomentS0": "8.43",
      "DipoleMomentS1": "8.43",
      "DipoleMomentT1": "8.66",
      "ionizationPotential": "6.56",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VSDMXBXEQMWKHG-OCEACIFDSA-N",
      "Smiles": "Fc1cc([nH]c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1[nH]cc(c1)F",
      "MolView_url": "http://molview.org/?q=Fc1cc([nH]c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1[nH]cc(c1)F",
      "HOMO": "-5.85",
      "LUMO": "-2.67",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "1.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.05",
      "ET1red": "",
      "DipoleMomentS0": "1.96",
      "DipoleMomentS1": "1.96",
      "DipoleMomentT1": "5.32",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "VSIHYVJNMIRCBV-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)C",
      "HOMO": "-5.24",
      "LUMO": "-2.45",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "1.91",
      "E00T1": "1.14",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.92",
      "DipoleMomentS1": "1.92",
      "DipoleMomentT1": "2.91",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VSKHDKAFKDTZTA-ZKUPEGJOSA-N",
      "Smiles": "S/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C/S",
      "MolView_url": "http://molview.org/?q=S/C=C/O/C=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=C\\O/C=C/S",
      "HOMO": "-5.91",
      "LUMO": "-2.72",
      "VEE": "1.67",
      "Lmax": "742.395209580838",
      "E00S1": "",
      "E00T1": "-0.02",
      "Eox": "0.48",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.5",
      "ET1red": "",
      "DipoleMomentS0": "10.31",
      "DipoleMomentS1": "10.31",
      "DipoleMomentT1": "0.88",
      "ionizationPotential": "6.22",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "VTDMQIPAYZBDCI-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)C",
      "HOMO": "-5.31",
      "LUMO": "-2.44",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.99",
      "E00T1": "1.19",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.88",
      "DipoleMomentS1": "1.88",
      "DipoleMomentT1": "2.39",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VTFHFDDNODPPRZ-UHFFFAOYSA-N",
      "Smiles": "S/N=C\\c1cc2c3cc(/C=N/S)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/S)cc(c5ccc4)/C=N/S",
      "MolView_url": "http://molview.org/?q=S/N=C\\c1cc2c3cc(/C=N/S)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N/S)cc(c5ccc4)/C=N/S",
      "HOMO": "-5.72",
      "LUMO": "-2.87",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "1.99",
      "E00T1": "1.14",
      "Eox": "-4.35",
      "Ered": "",
      "ES1ox": "-6.34",
      "ES1red": "",
      "ET1ox": "-5.49",
      "ET1red": "",
      "DipoleMomentS0": "4.69",
      "DipoleMomentS1": "4.69",
      "DipoleMomentT1": "5.55",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "VTIUZGHPLUFFEN-OCEACIFDSA-N",
      "Smiles": "Sc1ccc(cc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(cc1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(cc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(cc1)S",
      "HOMO": "-6.07",
      "LUMO": "-2.66",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "1.1",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.13",
      "DipoleMomentS1": "1.13",
      "DipoleMomentT1": "3.32",
      "ionizationPotential": "6.82",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "VTOFUCNLENABNK-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(s1)S)c1ncc(s1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(s1)S)c1ncc(s1)S",
      "HOMO": "-5.69",
      "LUMO": "-2.64",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.21",
      "E00T1": "",
      "Eox": "0.83",
      "Ered": "",
      "ES1ox": "-1.38",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.15",
      "DipoleMomentS1": "6.15",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.4",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "VUCKCLICIVTJTQ-OCEACIFDSA-N",
      "Smiles": "FC(Oc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)OC(F)(F)F)(F)F",
      "HOMO": "-6.23",
      "LUMO": "-3.29",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.52",
      "ET1red": "",
      "DipoleMomentS0": "6.71",
      "DipoleMomentS1": "6.71",
      "DipoleMomentT1": "6.79",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "VUCKCLICIVTJTQ-QPLCGJKRSA-N",
      "Smiles": "FC(Oc1coc(n1)c1ccc(o1)/N=N\\c1ccc(o1)c1occ(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)c1ccc(o1)/N=N\\c1ccc(o1)c1occ(n1)OC(F)(F)F)(F)F",
      "HOMO": "-6.16",
      "LUMO": "-3.32",
      "VEE": "2.5",
      "Lmax": "495.92",
      "E00S1": "",
      "E00T1": "0.24",
      "Eox": "1.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.84",
      "ET1red": "",
      "DipoleMomentS0": "6.74",
      "DipoleMomentS1": "6.74",
      "DipoleMomentT1": "6.69",
      "ionizationPotential": "0",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "VVATVIDZUSEQAU-UHFFFAOYSA-N",
      "Smiles": "SC1=CC=C(S1(=O)=O)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(S1(=O)=O)[C@]1(S)N=N1",
      "HOMO": "-6.93",
      "LUMO": "-2.77",
      "VEE": "3.11",
      "Lmax": "398.649517684887",
      "E00S1": "2.86",
      "E00T1": "",
      "Eox": "1.91",
      "Ered": "",
      "ES1ox": "-0.95",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.34",
      "DipoleMomentS1": "5.34",
      "DipoleMomentT1": "",
      "ionizationPotential": "8.24",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "VVESSWNAGQSYRQ-UHFFFAOYSA-N",
      "Smiles": "CC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(C1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=C(C1)C",
      "HOMO": "-5.1",
      "LUMO": "-2.37",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.08",
      "E00T1": "1.09",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.33",
      "DipoleMomentS1": "1.33",
      "DipoleMomentT1": "1.68",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VVFADXVVTIJEEZ-UHFFFAOYSA-N",
      "Smiles": "Oc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)O",
      "HOMO": "-5.48",
      "LUMO": "-2.67",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "1.9",
      "E00T1": "1.16",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "-1.24",
      "ES1red": "",
      "ET1ox": "-0.5",
      "ET1red": "",
      "DipoleMomentS0": "4.36",
      "DipoleMomentS1": "4.36",
      "DipoleMomentT1": "4.58",
      "ionizationPotential": "6.21",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VWTHUUXCCIDLPY-UHFFFAOYSA-N",
      "Smiles": "Sc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)S",
      "MolView_url": "http://molview.org/?q=Sc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)S",
      "HOMO": "-5.5",
      "LUMO": "-2.67",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "1.92",
      "E00T1": "1.17",
      "Eox": "0.64",
      "Ered": "",
      "ES1ox": "-1.28",
      "ES1red": "",
      "ET1ox": "-0.53",
      "ET1red": "",
      "DipoleMomentS0": "4.47",
      "DipoleMomentS1": "4.47",
      "DipoleMomentT1": "4.76",
      "ionizationPotential": "6.28",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VWZBSTIBPIQIGW-FMQUCBEESA-N",
      "Smiles": "COc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1coc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1occ(n1)OC",
      "HOMO": "-5.91",
      "LUMO": "-3.24",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "0.75",
      "Eox": "1.03",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.28",
      "ET1red": "",
      "DipoleMomentS0": "1.63",
      "DipoleMomentS1": "1.63",
      "DipoleMomentT1": "1.71",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "VXQJWCAPJIMJNY-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cn1)C",
      "HOMO": "-6.9",
      "LUMO": "-3.14",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "2.67",
      "E00T1": "2.32",
      "Eox": "2.02",
      "Ered": "",
      "ES1ox": "-0.65",
      "ES1red": "",
      "ET1ox": "-0.3",
      "ET1red": "",
      "DipoleMomentS0": "7.14",
      "DipoleMomentS1": "7.14",
      "DipoleMomentT1": "10.48",
      "ionizationPotential": "7.69",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "VYJZPPKECZDVCO-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(nc1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1cnc(nc1)[C@]1(S)N=N1",
      "HOMO": "-6.81",
      "LUMO": "-2.37",
      "VEE": "3.3",
      "Lmax": "375.69696969697",
      "E00S1": "2.81",
      "E00T1": "2.35",
      "Eox": "2.05",
      "Ered": "",
      "ES1ox": "-0.76",
      "ES1red": "",
      "ET1ox": "-0.3",
      "ET1red": "",
      "DipoleMomentS0": "6.01",
      "DipoleMomentS1": "6.01",
      "DipoleMomentT1": "9",
      "ionizationPotential": "8.22",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "VYKLYIKLUCYICJ-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1nnc(nn1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1nnc(nn1)OC(F)(F)F)(F)F",
      "HOMO": "-5.72",
      "LUMO": "-3.43",
      "VEE": "1.46",
      "Lmax": "849.178082191781",
      "E00S1": "",
      "E00T1": "1.07",
      "Eox": "0.91",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.16",
      "ET1red": "",
      "DipoleMomentS0": "6.96",
      "DipoleMomentS1": "6.96",
      "DipoleMomentT1": "34.36",
      "ionizationPotential": "6.77",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "VYTXQTVOWUMTJN-OCEACIFDSA-N",
      "Smiles": "N#Cc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc(o1)C#N",
      "HOMO": "-6.38",
      "LUMO": "-3.02",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.61",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.79",
      "ionizationPotential": "7.38",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "VYTXQTVOWUMTJN-QPLCGJKRSA-N",
      "Smiles": "N#Cc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(o1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(o1)C#N",
      "HOMO": "-6.3",
      "LUMO": "-2.72",
      "VEE": "2.63",
      "Lmax": "471.406844106464",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.71",
      "DipoleMomentS1": "10.71",
      "DipoleMomentT1": "10.07",
      "ionizationPotential": "7.24",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "WAWPOJWTGHXOLV-QURGRASLSA-N",
      "Smiles": "Oc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)O",
      "HOMO": "-6.18",
      "LUMO": "-3",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "1.04",
      "Eox": "1.38",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.34",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "1.04",
      "ionizationPotential": "7.03",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "WBEWKJNBBKVMII-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(cn1)C)C#N)c1ccc(cn1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(cn1)C)C#N)c1ccc(cn1)C",
      "HOMO": "-6.9",
      "LUMO": "-2.84",
      "VEE": "3.84",
      "Lmax": "322.864583333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.13",
      "DipoleMomentS1": "6.13",
      "DipoleMomentT1": "7.9",
      "ionizationPotential": "8.13",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "WBIISIYNKFWIJV-UHFFFAOYSA-N",
      "Smiles": "O=S1(=O)C=CC=C1c1ccc2c(c1)c1cc3-c4ccc(cc4-c4[n+]3[B@@-]3(n1c2cc1n3c(c2c1cc(cc2)C1=CC=CS1(=O)=O)c4)Cl)C1=CC=CS1(=O)=O",
      "MolView_url": "http://molview.org/?q=O=S1(=O)C=CC=C1c1ccc2c(c1)c1cc3-c4ccc(cc4-c4[n+]3[B@@-]3(n1c2cc1n3c(c2c1cc(cc2)C1=CC=CS1(=O)=O)c4)Cl)C1=CC=CS1(=O)=O",
      "HOMO": "-5.77",
      "LUMO": "-3",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "1.98",
      "E00T1": "",
      "Eox": "0.9",
      "Ered": "",
      "ES1ox": "-1.08",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "13.22",
      "DipoleMomentS1": "13.22",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "WBVXYFYRGLTZKS-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(o1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1cnc(o1)[C@]1(Cl)N=N1",
      "HOMO": "-7.28",
      "LUMO": "-2.78",
      "VEE": "3.17",
      "Lmax": "391.104100946372",
      "E00S1": "3",
      "E00T1": "2.41",
      "Eox": "2.35",
      "Ered": "",
      "ES1ox": "-0.65",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "2.34",
      "DipoleMomentS1": "2.34",
      "DipoleMomentT1": "9.91",
      "ionizationPotential": "8.81",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "WCCDACIHQNFVPY-UHFFFAOYSA-N",
      "Smiles": "Fc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)F",
      "MolView_url": "http://molview.org/?q=Fc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)F",
      "HOMO": "-5.29",
      "LUMO": "-2.43",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.21",
      "E00T1": "1.19",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "-1.67",
      "ES1red": "",
      "ET1ox": "-0.65",
      "ET1red": "",
      "DipoleMomentS0": "7.65",
      "DipoleMomentS1": "7.65",
      "DipoleMomentT1": "6.88",
      "ionizationPotential": "6.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "WCXXBFNWCCIYQO-UHFFFAOYSA-N",
      "Smiles": "c1cc2ccc3c4c2c(c1)ccc4c1c2c3ccc3c2c(cc1)ccc3",
      "MolView_url": "http://molview.org/?q=c1cc2ccc3c4c2c(c1)ccc4c1c2c3ccc3c2c(cc1)ccc3",
      "HOMO": "-5.45",
      "LUMO": "-2.31",
      "VEE": "3.14",
      "Lmax": "394.84076433121",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.37",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WEJWGBQAEJMQEO-FMQUCBEESA-N",
      "Smiles": "COc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(s1)OC",
      "HOMO": "-5.3",
      "LUMO": "-2.77",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "1.87",
      "E00T1": "0.66",
      "Eox": "0.35",
      "Ered": "",
      "ES1ox": "-1.52",
      "ES1red": "",
      "ET1ox": "-0.31",
      "ET1red": "",
      "DipoleMomentS0": "5.86",
      "DipoleMomentS1": "5.86",
      "DipoleMomentT1": "6.67",
      "ionizationPotential": "5.87",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WEOUZDWQNZLNGU-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)F)c1ncc(cn1)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(cn1)F)c1ncc(cn1)F",
      "HOMO": "-7.37",
      "LUMO": "-3.25",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.56",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1",
      "DipoleMomentS1": "1",
      "DipoleMomentT1": "3.17",
      "ionizationPotential": "8.31",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "WFFOCXXHZURLBE-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(s1)c1cc2c3cc(c4ncc(s4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)C#N)cc(c5ccc4)c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(s1)c1cc2c3cc(c4ncc(s4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(s1)C#N)cc(c5ccc4)c1ncc(s1)C#N",
      "HOMO": "-5.86",
      "LUMO": "-3.19",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "1.82",
      "E00T1": "1.08",
      "Eox": "1.02",
      "Ered": "",
      "ES1ox": "-0.8",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WFOUBLVIQPTXSI-UHFFFAOYSA-N",
      "Smiles": "Sc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)S",
      "MolView_url": "http://molview.org/?q=Sc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)S",
      "HOMO": "-5.31",
      "LUMO": "-2.43",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2",
      "E00T1": "1.2",
      "Eox": "0.47",
      "Ered": "",
      "ES1ox": "-1.53",
      "ES1red": "",
      "ET1ox": "-0.73",
      "ET1red": "",
      "DipoleMomentS0": "6.05",
      "DipoleMomentS1": "6.05",
      "DipoleMomentT1": "5.59",
      "ionizationPotential": "6.09",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "WGDLKDLEVJKCBJ-OCEACIFDSA-N",
      "Smiles": "FC(Oc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1csc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1scc(n1)OC(F)(F)F)(F)F",
      "HOMO": "-6.15",
      "LUMO": "-3.39",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "",
      "E00T1": "0.79",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.49",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.13",
      "ionizationPotential": "6.95",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WHRKGXUABOOFKL-UHFFFAOYSA-N",
      "Smiles": "FC(OC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)OC(F)(F)F)cc(c5ccc4)C1=CC=C(C1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)OC(F)(F)F)cc(c5ccc4)C1=CC=C(C1)OC(F)(F)F)(F)F",
      "HOMO": "-5.27",
      "LUMO": "-2.5",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.13",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.02",
      "DipoleMomentS1": "3.02",
      "DipoleMomentT1": "2.27",
      "ionizationPotential": "0.12",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WHSJFSWUVLXVTP-UHFFFAOYSA-N",
      "Smiles": "FC(O/C=C\\[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/C=C\\[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.59",
      "LUMO": "-2.76",
      "VEE": "3.23",
      "Lmax": "383.839009287926",
      "E00S1": "3.01",
      "E00T1": "",
      "Eox": "2.57",
      "Ered": "",
      "ES1ox": "-0.44",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.03",
      "DipoleMomentS1": "1.03",
      "DipoleMomentT1": "5.1",
      "ionizationPotential": "9.12",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "WINNKSRVVHQFSJ-FMQUCBEESA-N",
      "Smiles": "Fc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(o1)F",
      "HOMO": "-5.64",
      "LUMO": "-2.99",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.73",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.85",
      "DipoleMomentS1": "2.85",
      "DipoleMomentT1": "3.33",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WINNKSRVVHQFSJ-VXPUYCOJSA-N",
      "Smiles": "Fc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(o1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(o1)F",
      "HOMO": "-5.67",
      "LUMO": "-2.98",
      "VEE": "2.35",
      "Lmax": "527.574468085106",
      "E00S1": "",
      "E00T1": "0.13",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.52",
      "ET1red": "",
      "DipoleMomentS0": "2.76",
      "DipoleMomentS1": "2.76",
      "DipoleMomentT1": "2.92",
      "ionizationPotential": "6.42",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "WIUMYWHQFTYOIX-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)S)cc(c5ccc4)c1ccc(cc1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)S)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)S)cc(c5ccc4)c1ccc(cc1)S",
      "HOMO": "-5.34",
      "LUMO": "-2.42",
      "VEE": "3.04",
      "Lmax": "407.828947368421",
      "E00S1": "2.06",
      "E00T1": "",
      "Eox": "-4.25",
      "Ered": "",
      "ES1ox": "-6.31",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.82",
      "DipoleMomentS1": "1.82",
      "DipoleMomentT1": "1.71",
      "ionizationPotential": "5.97",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WIVZPEAHEMKIFC-UHFFFAOYSA-N",
      "Smiles": "FC(OC1=CC=C(S1(=O)=O)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(S1(=O)=O)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.6",
      "LUMO": "-3.2",
      "VEE": "3.35",
      "Lmax": "370.089552238806",
      "E00S1": "2.92",
      "E00T1": "",
      "Eox": "2.6",
      "Ered": "",
      "ES1ox": "-0.32",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.01",
      "DipoleMomentS1": "7.01",
      "DipoleMomentT1": "7.17",
      "ionizationPotential": "8.95",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "WJFJLKIHZDVYPW-UHFFFAOYSA-N",
      "Smiles": "Cc1cc2c3cc(C)c4c5c3c(c3c2c2c1cccc2c(c3)C)cc(c5ccc4)C",
      "MolView_url": "http://molview.org/?q=Cc1cc2c3cc(C)c4c5c3c(c3c2c2c1cccc2c(c3)C)cc(c5ccc4)C",
      "HOMO": "-5.31",
      "LUMO": "-2.21",
      "VEE": "3.07",
      "Lmax": "403.843648208469",
      "E00S1": "2.43",
      "E00T1": "1.31",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "-1.94",
      "ES1red": "",
      "ET1ox": "-0.82",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.11",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WJHUNVAELKTREP-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cnc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(s1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(s1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(s1)C#N",
      "HOMO": "-7.25",
      "LUMO": "-3.48",
      "VEE": "2.54",
      "Lmax": "488.110236220472",
      "E00S1": "2.96",
      "E00T1": "2.06",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.33",
      "DipoleMomentS1": "5.33",
      "DipoleMomentT1": "6.27",
      "ionizationPotential": "8.42",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "WJXADHNAIVACPR-UHFFFAOYSA-N",
      "Smiles": "COc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)OC",
      "MolView_url": "http://molview.org/?q=COc1csc(n1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1scc(n1)OC",
      "HOMO": "-5.44",
      "LUMO": "-2.66",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.51",
      "DipoleMomentS1": "2.51",
      "DipoleMomentT1": "2.24",
      "ionizationPotential": "6.16",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "WKFILXOYOYIBMK-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(nc1)c1cc2c3cc(c4ccc(cn4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)C(F)(F)F)cc(c5ccc4)c1ccc(cn1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(nc1)c1cc2c3cc(c4ccc(cn4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cn1)C(F)(F)F)cc(c5ccc4)c1ccc(cn1)C(F)(F)F)(F)F",
      "HOMO": "-5.62",
      "LUMO": "-2.77",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "2",
      "E00T1": "1.17",
      "Eox": "-4.07",
      "Ered": "",
      "ES1ox": "-6.07",
      "ES1red": "",
      "ET1ox": "-5.24",
      "ET1red": "",
      "DipoleMomentS0": "0.39",
      "DipoleMomentS1": "0.39",
      "DipoleMomentT1": "0.38",
      "ionizationPotential": "-0.03",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WLENLCVQGKJAJV-QURGRASLSA-N",
      "Smiles": "COc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc([nH]1)OC",
      "HOMO": "-4.95",
      "LUMO": "-2.57",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "1.77",
      "E00T1": "0.63",
      "Eox": "0.05",
      "Ered": "",
      "ES1ox": "-1.72",
      "ES1red": "",
      "ET1ox": "-0.58",
      "ET1red": "",
      "DipoleMomentS0": "5.13",
      "DipoleMomentS1": "5.13",
      "DipoleMomentT1": "6.78",
      "ionizationPotential": "5.57",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WLVHCSKIOYXRPQ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc([nH]1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc([nH]1)[C@]1(C#N)N=N1",
      "HOMO": "-7.04",
      "LUMO": "-2.99",
      "VEE": "3.78",
      "Lmax": "327.989417989418",
      "E00S1": "2.64",
      "E00T1": "",
      "Eox": "2.15",
      "Ered": "",
      "ES1ox": "-0.49",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.58",
      "DipoleMomentS1": "1.58",
      "DipoleMomentT1": "7.33",
      "ionizationPotential": "8.82",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "WMHZMKIFOJGBEG-FMQUCBEESA-N",
      "Smiles": "[O-][N+](=O)c1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ncc([nH]1)c1ccc(o1)/N=N/c1ccc(o1)c1cnc([nH]1)[N+](=O)[O-]",
      "HOMO": "-6.14",
      "LUMO": "-3.58",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "1.94",
      "E00T1": "0.71",
      "Eox": "1.12",
      "Ered": "",
      "ES1ox": "-0.82",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "7.14",
      "ionizationPotential": "7.32",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WMNREBJBEXLOKX-FOCLMDBBSA-N",
      "Smiles": "FC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CF",
      "MolView_url": "http://molview.org/?q=FC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CF",
      "HOMO": "-6.17",
      "LUMO": "-3.08",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "",
      "E00T1": "0.9",
      "Eox": "1.22",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.32",
      "ET1red": "",
      "DipoleMomentS0": "2.11",
      "DipoleMomentS1": "2.11",
      "DipoleMomentT1": "2.31",
      "ionizationPotential": "7.12",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WMNREBJBEXLOKX-NXVVXOECSA-N",
      "Smiles": "FC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CF",
      "MolView_url": "http://molview.org/?q=FC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CF",
      "HOMO": "-6.13",
      "LUMO": "-3.01",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.95",
      "DipoleMomentS1": "4.95",
      "DipoleMomentT1": "4.19",
      "ionizationPotential": "7.01",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "WMZSWGHCDQZISW-DQRAZIAOSA-N",
      "Smiles": "O=Cn1c(C)ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)C",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C)ccc1c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C=O)C",
      "HOMO": "-5.77",
      "LUMO": "-2.95",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.53",
      "DipoleMomentS1": "3.53",
      "DipoleMomentT1": "7.93",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "WMZSWGHCDQZISW-QURGRASLSA-N",
      "Smiles": "O=Cn1c(C)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)C",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C)ccc1c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C=O)C",
      "HOMO": "-5.74",
      "LUMO": "-2.98",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.61",
      "DipoleMomentS1": "3.61",
      "DipoleMomentT1": "3.72",
      "ionizationPotential": "6.46",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WPBCQBDOUULMEP-UHFFFAOYSA-N",
      "Smiles": "Oc1coc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1occ(n1)O)c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1coc(n1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1occ(n1)O)c1occ(n1)O",
      "HOMO": "-5.68",
      "LUMO": "-2.59",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "2.25",
      "E00T1": "",
      "Eox": "0.85",
      "Ered": "",
      "ES1ox": "-1.4",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.94",
      "DipoleMomentS1": "5.94",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.43",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "WPBWQXSVAOFTLZ-UHFFFAOYSA-N",
      "Smiles": "Cc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)C)cc(c5ccc4)c1ccc(cc1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)C)cc(c5ccc4)c1ccc(cc1)C",
      "HOMO": "-5.32",
      "LUMO": "-2.37",
      "VEE": "3.03",
      "Lmax": "409.174917491749",
      "E00S1": "2.09",
      "E00T1": "0.25",
      "Eox": "-4.11",
      "Ered": "",
      "ES1ox": "-6.2",
      "ES1red": "",
      "ET1ox": "-4.36",
      "ET1red": "",
      "DipoleMomentS0": "0.07",
      "DipoleMomentS1": "0.07",
      "DipoleMomentT1": "0.09",
      "ionizationPotential": "0.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WPROCCKHBOXXNL-UHFFFAOYSA-N",
      "Smiles": "FC(O/C=C\\c1cc2c3cc(/C=C\\OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/OC(F)(F)F)cc(c5ccc4)/C=C/OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/C=C\\c1cc2c3cc(/C=C\\OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/OC(F)(F)F)cc(c5ccc4)/C=C/OC(F)(F)F)(F)F",
      "HOMO": "-5.47",
      "LUMO": "-2.62",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "2.03",
      "E00T1": "0.25",
      "Eox": "0.66",
      "Ered": "",
      "ES1ox": "-1.37",
      "ES1red": "",
      "ET1ox": "0.41",
      "ET1red": "",
      "DipoleMomentS0": "5.3",
      "DipoleMomentS1": "5.3",
      "DipoleMomentT1": "5.46",
      "ionizationPotential": "0.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WQCJHDAKUJEHEI-VHXPQNKSSA-N",
      "Smiles": "Sc1ncc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1cnc([nH]1)S",
      "MolView_url": "http://molview.org/?q=Sc1ncc([nH]1)c1ccc(cc1)/N=N\\c1ccc(cc1)c1cnc([nH]1)S",
      "HOMO": "-5.84",
      "LUMO": "-2.46",
      "VEE": "2.66",
      "Lmax": "466.09022556391",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "0.93",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.44",
      "ET1red": "",
      "DipoleMomentS0": "1.8",
      "DipoleMomentS1": "1.8",
      "DipoleMomentT1": "0.51",
      "ionizationPotential": "6.6",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "WQICQMWNZMSYSK-IAZLARGGSA-N",
      "Smiles": "O/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C\\O)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C\\O)cc(c5ccc4)/C=C\\O/C=C\\O",
      "MolView_url": "http://molview.org/?q=O/C=C/O/C=C\\c1cc2c3cc(/C=C\\O/C=C\\O)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C\\O/C=C\\O)cc(c5ccc4)/C=C\\O/C=C\\O",
      "HOMO": "-5.2",
      "LUMO": "-2.47",
      "VEE": "2.91",
      "Lmax": "426.048109965636",
      "E00S1": "1.9",
      "E00T1": "0.22",
      "Eox": "-4.33",
      "Ered": "",
      "ES1ox": "-6.23",
      "ES1red": "",
      "ET1ox": "-4.55",
      "ET1red": "",
      "DipoleMomentS0": "6.22",
      "DipoleMomentS1": "6.22",
      "DipoleMomentT1": "6.13",
      "ionizationPotential": "0.07",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WQYHEZANLNWFKU-UHFFFAOYSA-N",
      "Smiles": "FC(/C=C\\[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C\\[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-8.3",
      "LUMO": "-2.84",
      "VEE": "3.64",
      "Lmax": "340.604395604396",
      "E00S1": "3.47",
      "E00T1": "2.84",
      "Eox": "3.45",
      "Ered": "",
      "ES1ox": "-0.02",
      "ES1red": "",
      "ET1ox": "0.61",
      "ET1red": "",
      "DipoleMomentS0": "1.04",
      "DipoleMomentS1": "1.04",
      "DipoleMomentT1": "2.51",
      "ionizationPotential": "10.12",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "WRBZLQSSDGNMIB-BYYHNAKLSA-N",
      "Smiles": "FC(OC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)OC(F)(F)F)(F)F",
      "HOMO": "-5.36",
      "LUMO": "-2.88",
      "VEE": "2.54",
      "Lmax": "488.110236220472",
      "E00S1": "1.83",
      "E00T1": "0.51",
      "Eox": "0.45",
      "Ered": "",
      "ES1ox": "-1.38",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "5.05",
      "DipoleMomentS1": "5.05",
      "DipoleMomentT1": "4.77",
      "ionizationPotential": "6.13",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WRDRUGKVOFFAKI-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)C#N)C#N",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)C#N)C#N",
      "HOMO": "-7.04",
      "LUMO": "-3.37",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "2.9",
      "E00T1": "2.31",
      "Eox": "2.25",
      "Ered": "",
      "ES1ox": "-0.65",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "0.32",
      "DipoleMomentS1": "0.32",
      "DipoleMomentT1": "5.44",
      "ionizationPotential": "8.17",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "WRSBLGGZFBXPFY-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1c1cc2c3cc(c4ccc(n4C=O)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)[N+](=O)[O-])cc(c5ccc4)c1ccc(n1C=O)[N+](=O)[O-])[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1cc2c3cc(c4ccc(n4C=O)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)[N+](=O)[O-])cc(c5ccc4)c1ccc(n1C=O)[N+](=O)[O-])[N+](=O)[O-]",
      "HOMO": "-6",
      "LUMO": "-3.23",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "-4.31",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-4.51",
      "ET1red": "",
      "DipoleMomentS0": "10.77",
      "DipoleMomentS1": "10.77",
      "DipoleMomentT1": "9.57",
      "ionizationPotential": "0.12",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WRXJQFRVOHIFKD-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)[N+](=O)[O-]",
      "HOMO": "-6.48",
      "LUMO": "-3.44",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "2.3",
      "E00T1": "0.78",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "2.02",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WSAUYOSTHKNDPT-FMQUCBEESA-N",
      "Smiles": "Cc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)C",
      "HOMO": "-5.91",
      "LUMO": "-3.18",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "0.78",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.19",
      "ET1red": "",
      "DipoleMomentS0": "3.52",
      "DipoleMomentS1": "3.52",
      "DipoleMomentT1": "3.63",
      "ionizationPotential": "6.54",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WSAUYOSTHKNDPT-VXPUYCOJSA-N",
      "Smiles": "Cc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)C",
      "HOMO": "-5.9",
      "LUMO": "-3.14",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.57",
      "DipoleMomentS1": "7.57",
      "DipoleMomentT1": "7.12",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "WSEZCVHPQHCTNW-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(o1)Cl)C#N)c1ccc(o1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(o1)Cl)C#N)c1ccc(o1)Cl",
      "HOMO": "-6.42",
      "LUMO": "-2.89",
      "VEE": "3.07",
      "Lmax": "403.843648208469",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.04",
      "DipoleMomentS1": "4.04",
      "DipoleMomentT1": "5.59",
      "ionizationPotential": "7.81",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "WSZIWLQBWANSMK-UHFFFAOYSA-N",
      "Smiles": "Cn1c(C)ccc1c1cc2c3cc(c4ccc(n4C)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)C)cc(c5ccc4)c1ccc(n1C)C",
      "MolView_url": "http://molview.org/?q=Cn1c(C)ccc1c1cc2c3cc(c4ccc(n4C)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)C)cc(c5ccc4)c1ccc(n1C)C",
      "HOMO": "-5.22",
      "LUMO": "-2.41",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "1.95",
      "E00T1": "1.17",
      "Eox": "-4.14",
      "Ered": "",
      "ES1ox": "-6.09",
      "ES1red": "",
      "ET1ox": "-5.31",
      "ET1red": "",
      "DipoleMomentS0": "2.83",
      "DipoleMomentS1": "2.83",
      "DipoleMomentT1": "3.54",
      "ionizationPotential": "0.16",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WTFPGGKIGIMAER-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(C)ccc1c1cc2c3cc(c4ccc(n4C=O)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)C)cc(c5ccc4)c1ccc(n1C=O)C",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C)ccc1c1cc2c3cc(c4ccc(n4C=O)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)C)cc(c5ccc4)c1ccc(n1C=O)C",
      "HOMO": "-5.67",
      "LUMO": "-2.68",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "2.09",
      "E00T1": "0.26",
      "Eox": "-4.17",
      "Ered": "",
      "ES1ox": "-6.26",
      "ES1red": "",
      "ET1ox": "-4.43",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.09",
      "ionizationPotential": "0.14",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WTJYNBQOACSSJQ-VVAFTLCXSA-N",
      "Smiles": "[O-][N+](=O)/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/N=C\\c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1/C=N\\[N+](=O)[O-]",
      "HOMO": "-5.94",
      "LUMO": "-3.48",
      "VEE": "1.45",
      "Lmax": "855.034482758621",
      "E00S1": "1.71",
      "E00T1": "1.05",
      "Eox": "1.06",
      "Ered": "",
      "ES1ox": "-0.65",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "16.8",
      "DipoleMomentS1": "16.8",
      "DipoleMomentT1": "21.88",
      "ionizationPotential": "7.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "WUADVEIELZFNSY-ISLYRVAYSA-N",
      "Smiles": "Oc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)O",
      "HOMO": "-5.84",
      "LUMO": "-3.19",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.63",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WUAGRDIKRAUSJL-QURGRASLSA-N",
      "Smiles": "N#Cc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)C#N",
      "HOMO": "-6.39",
      "LUMO": "-3.43",
      "VEE": "2.3",
      "Lmax": "539.04347826087",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.33",
      "DipoleMomentS1": "5.33",
      "DipoleMomentT1": "4.5",
      "ionizationPotential": "7.39",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WUKPZEBIEFKFPO-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1[nH]cc(c1)Cl)C#N)c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1[nH]cc(c1)Cl)C#N)c1[nH]cc(c1)Cl",
      "HOMO": "-6.23",
      "LUMO": "-2.86",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "1.97",
      "Eox": "-1.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.08",
      "ET1red": "",
      "DipoleMomentS0": "4.54",
      "DipoleMomentS1": "4.54",
      "DipoleMomentT1": "10.55",
      "ionizationPotential": "7.63",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "WVBQAPORXBSOSF-XHVJHNBZSA-N",
      "Smiles": "Cl/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C/Cl",
      "MolView_url": "http://molview.org/?q=Cl/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C/Cl",
      "HOMO": "-5.87",
      "LUMO": "-3.03",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "2.13",
      "E00T1": "0.7",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "-1.21",
      "ES1red": "",
      "ET1ox": "0.22",
      "ET1red": "",
      "DipoleMomentS0": "2.18",
      "DipoleMomentS1": "2.18",
      "DipoleMomentT1": "2.11",
      "ionizationPotential": "6.77",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WVEFBYJRDHAJCA-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(o1)F)c1ncc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(o1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(o1)F)c1ncc(o1)F",
      "HOMO": "-5.71",
      "LUMO": "-2.59",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.27",
      "E00T1": "1.61",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.3",
      "DipoleMomentS1": "6.3",
      "DipoleMomentT1": "7.55",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "WVESBXZBBPNCRX-VHXPQNKSSA-N",
      "Smiles": "O=Cn1c(O)ccc1c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C=O)O",
      "MolView_url": "http://molview.org/?q=O=Cn1c(O)ccc1c1ccc(cc1)/N=N\\c1ccc(cc1)c1ccc(n1C=O)O",
      "HOMO": "-5.84",
      "LUMO": "-2.48",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "0.52",
      "Eox": "0.95",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.43",
      "ET1red": "",
      "DipoleMomentS0": "2.49",
      "DipoleMomentS1": "2.49",
      "DipoleMomentT1": "2.23",
      "ionizationPotential": "6.69",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/benzene_cis"
    },
    {
      "InchiKey": "WVLJMPMAIXBWFH-QURGRASLSA-N",
      "Smiles": "Oc1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ccc(cn1)O",
      "HOMO": "-5.72",
      "LUMO": "-2.86",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "",
      "E00T1": "0.78",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.63",
      "DipoleMomentS1": "3.63",
      "DipoleMomentT1": "7.6",
      "ionizationPotential": "6.37",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WVQABVSPOWSGEN-BUHFOSPRSA-N",
      "Smiles": "[O-][N+](=O)C#Cc1ccc(s1)/N=N/c1ccc(s1)C#C[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C#Cc1ccc(s1)/N=N/c1ccc(s1)C#C[N+](=O)[O-]",
      "HOMO": "-6.89",
      "LUMO": "-4.09",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "0.86",
      "Eox": "1.88",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.02",
      "ET1red": "",
      "DipoleMomentS0": "9.2",
      "DipoleMomentS1": "9.2",
      "DipoleMomentT1": "9.78",
      "ionizationPotential": "8.05",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WVQABVSPOWSGEN-YPKPFQOOSA-N",
      "Smiles": "[O-][N+](=O)C#Cc1ccc(s1)/N=N\\c1ccc(s1)C#C[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C#Cc1ccc(s1)/N=N\\c1ccc(s1)C#C[N+](=O)[O-]",
      "HOMO": "-6.79",
      "LUMO": "-3.94",
      "VEE": "1.41",
      "Lmax": "879.290780141844",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "1.67",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.46",
      "ET1red": "",
      "DipoleMomentS0": "3.2",
      "DipoleMomentS1": "3.2",
      "DipoleMomentT1": "1.56",
      "ionizationPotential": "7.94",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "WWHTVCLFXHSGJM-BUHFOSPRSA-N",
      "Smiles": "Oc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=Oc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)O",
      "HOMO": "-6.48",
      "LUMO": "-3.42",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "0.91",
      "Eox": "1.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.62",
      "ET1red": "",
      "DipoleMomentS0": "3.77",
      "DipoleMomentS1": "3.77",
      "DipoleMomentT1": "3.76",
      "ionizationPotential": "7.36",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WWHTVCLFXHSGJM-YPKPFQOOSA-N",
      "Smiles": "Oc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)O",
      "MolView_url": "http://molview.org/?q=Oc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)O",
      "HOMO": "-6.4",
      "LUMO": "-3.35",
      "VEE": "2.34",
      "Lmax": "529.82905982906",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.34",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.67",
      "DipoleMomentS1": "1.67",
      "DipoleMomentT1": "4.87",
      "ionizationPotential": "7.22",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "WWUCXXYCHQXUNE-FMQUCBEESA-N",
      "Smiles": "n1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cnc1",
      "MolView_url": "http://molview.org/?q=n1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cnc1",
      "HOMO": "-5.59",
      "LUMO": "-2.8",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "2.1",
      "E00T1": "0.8",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "-1.42",
      "ES1red": "",
      "ET1ox": "-0.12",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.5",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WXBJQXKHKIOOHO-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)Cl)c1scc(n1)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)Cl)c1scc(n1)Cl",
      "HOMO": "-6.91",
      "LUMO": "-3.34",
      "VEE": "2.51",
      "Lmax": "493.94422310757",
      "E00S1": "2.79",
      "E00T1": "2.01",
      "Eox": "2.14",
      "Ered": "",
      "ES1ox": "-0.65",
      "ES1red": "",
      "ET1ox": "0.13",
      "ET1red": "",
      "DipoleMomentS0": "3.64",
      "DipoleMomentS1": "3.64",
      "DipoleMomentT1": "14.88",
      "ionizationPotential": "7.9",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "WXHQSLJZHDRHFJ-UHFFFAOYSA-N",
      "Smiles": "Sc1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)S)c1[nH]cc(c1)S",
      "MolView_url": "http://molview.org/?q=Sc1c[nH]c(c1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1[nH]cc(c1)S)c1[nH]cc(c1)S",
      "HOMO": "-5.44",
      "LUMO": "-2.37",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "",
      "E00T1": "1.55",
      "Eox": "0.75",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.8",
      "ET1red": "",
      "DipoleMomentS0": "5.35",
      "DipoleMomentS1": "5.35",
      "DipoleMomentT1": "6.76",
      "ionizationPotential": "6.15",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "WXLHOLLAEDINGQ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(o1)[N+](=O)[O-])C#N)c1ncc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(o1)[N+](=O)[O-])C#N)c1ncc(o1)[N+](=O)[O-]",
      "HOMO": "-7.71",
      "LUMO": "-3.85",
      "VEE": "3.59",
      "Lmax": "345.348189415042",
      "E00S1": "",
      "E00T1": "2.69",
      "Eox": "-0.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.8",
      "ET1red": "",
      "DipoleMomentS0": "4.24",
      "DipoleMomentS1": "4.24",
      "DipoleMomentT1": "5.65",
      "ionizationPotential": "9.42",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "WXPRIPLMRHTAJQ-ISLYRVAYSA-N",
      "Smiles": "n1cnnc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1nncnn1",
      "MolView_url": "http://molview.org/?q=n1cnnc(n1)c1ccc(s1)/N=N/c1ccc(s1)c1nncnn1",
      "HOMO": "-6.67",
      "LUMO": "-3.62",
      "VEE": "2.37",
      "Lmax": "523.122362869198",
      "E00S1": "",
      "E00T1": "0.9",
      "Eox": "1.69",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.79",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.62",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WXPRIPLMRHTAJQ-ZCXUNETKSA-N",
      "Smiles": "n1cnnc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1nncnn1",
      "MolView_url": "http://molview.org/?q=n1cnnc(n1)c1ccc(s1)/N=N\\c1ccc(s1)c1nncnn1",
      "HOMO": "-6.56",
      "LUMO": "-3.46",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.26",
      "ET1red": "",
      "DipoleMomentS0": "3.53",
      "DipoleMomentS1": "3.53",
      "DipoleMomentT1": "2.96",
      "ionizationPotential": "7.46",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "WYDWJHYTZLOOAY-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(c2ccc[nH]2)c(c(c1)c1ccc[nH]1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(c2ccc[nH]2)c(c(c1)c1ccc[nH]1)C#N",
      "HOMO": "-6.12",
      "LUMO": "-2.73",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.23",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.91",
      "DipoleMomentS1": "1.91",
      "DipoleMomentT1": "6.69",
      "ionizationPotential": "7.46",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "WZBZECHLFUXAAC-UHFFFAOYSA-N",
      "Smiles": "c1cnc(nc1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=c1cnc(nc1)[C@@H]1N=N1",
      "HOMO": "-7.72",
      "LUMO": "-2.24",
      "VEE": "3.58",
      "Lmax": "346.312849162011",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.81",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.38",
      "DipoleMomentS1": "4.38",
      "DipoleMomentT1": "7.98",
      "ionizationPotential": "9.23",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "WZVJEYGYZXVVSZ-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1coc(n1)c1cc2c3cc(c4occ(n4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)OC(F)(F)F)cc(c5ccc4)c1occ(n1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1coc(n1)c1cc2c3cc(c4occ(n4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1occ(n1)OC(F)(F)F)cc(c5ccc4)c1occ(n1)OC(F)(F)F)(F)F",
      "HOMO": "-5.76",
      "LUMO": "-3.14",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.17",
      "DipoleMomentS1": "0.17",
      "DipoleMomentT1": "0.11",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "WZWMCUBBMSCZSX-FMQUCBEESA-N",
      "Smiles": "Fc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)F",
      "HOMO": "-6.16",
      "LUMO": "-3.27",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "2.19",
      "E00T1": "",
      "Eox": "1.24",
      "Ered": "",
      "ES1ox": "-0.95",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.09",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "WZXCMUFOAYQGOH-VAVLHINXSA-N",
      "Smiles": "FC(/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\C(F)(F)F)(F)F",
      "HOMO": "-6.85",
      "LUMO": "-3.72",
      "VEE": "2.81",
      "Lmax": "441.209964412811",
      "E00S1": "",
      "E00T1": "0.82",
      "Eox": "1.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.03",
      "ET1red": "",
      "DipoleMomentS0": "11.52",
      "DipoleMomentS1": "11.52",
      "DipoleMomentT1": "11.05",
      "ionizationPotential": "7.96",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "WZXCMUFOAYQGOH-YLKSDFICSA-N",
      "Smiles": "FC(/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N/C(F)(F)F)(F)F",
      "HOMO": "-6.78",
      "LUMO": "-3.56",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "",
      "E00T1": "0.27",
      "Eox": "1.66",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.39",
      "ET1red": "",
      "DipoleMomentS0": "4.64",
      "DipoleMomentS1": "4.64",
      "DipoleMomentT1": "3.43",
      "ionizationPotential": "7.86",
      "source_directory": "/scratch/agarwal.sni/1-1"
    },
    {
      "InchiKey": "XAEZHDHLNLXJHC-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(s1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(s1)[N+](=O)[O-]",
      "HOMO": "-6.88",
      "LUMO": "-3.63",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "",
      "E00T1": "1.44",
      "Eox": "2.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.67",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "5.9",
      "ionizationPotential": "7.95",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "XANASUINKUBZFT-OCEACIFDSA-N",
      "Smiles": "Oc1cnc(nc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(cn1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(nc1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(cn1)O",
      "HOMO": "-6.45",
      "LUMO": "-2.83",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "1.47",
      "Eox": "1.7",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.23",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.08",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "XAUGSJLRJBNJBS-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(C#N)ccc1[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C#N)ccc1[C@]1(C#N)N=N1",
      "HOMO": "-7.64",
      "LUMO": "-2.92",
      "VEE": "3.63",
      "Lmax": "341.542699724518",
      "E00S1": "3.28",
      "E00T1": "",
      "Eox": "2.55",
      "Ered": "",
      "ES1ox": "-0.73",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.27",
      "DipoleMomentS1": "6.27",
      "DipoleMomentT1": "7.89",
      "ionizationPotential": "9.15",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "XAZVFHMZLBNNPU-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)Cl)cc(c5ccc4)c1ccc(cc1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(cc1)c1cc2c3cc(c4ccc(cc4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(cc1)Cl)cc(c5ccc4)c1ccc(cc1)Cl",
      "HOMO": "-5.45",
      "LUMO": "-2.49",
      "VEE": "3.04",
      "Lmax": "407.828947368421",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.46",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "0.07",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "XBJCAJFOFLJEQX-UHFFFAOYSA-N",
      "Smiles": "CC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)C)cc(c5ccc4)C1=CC=C(S1(=O)=O)C)C1=CC=C(S1(=O)=O)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)C)cc(c5ccc4)C1=CC=C(S1(=O)=O)C)C1=CC=C(S1(=O)=O)C",
      "HOMO": "-5.71",
      "LUMO": "-3.06",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "1.8",
      "E00T1": "",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.61",
      "DipoleMomentS1": "10.61",
      "DipoleMomentT1": "8.95",
      "ionizationPotential": "0.06",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "XBRMAQHRKMIYQQ-UHFFFAOYSA-N",
      "Smiles": "Clc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ncc([nH]1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1cnc([nH]1)Cl",
      "HOMO": "-5.42",
      "LUMO": "-2.51",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.03",
      "E00T1": "1.2",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.41",
      "DipoleMomentS1": "9.41",
      "DipoleMomentT1": "9.19",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "XDGAJCODVAFQOH-UHFFFAOYSA-N",
      "Smiles": "FC(OC1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-9.13",
      "LUMO": "-3.16",
      "VEE": "3.53",
      "Lmax": "351.218130311615",
      "E00S1": "3.57",
      "E00T1": "2.61",
      "Eox": "2.42",
      "Ered": "",
      "ES1ox": "-1.15",
      "ES1red": "",
      "ET1ox": "-0.19",
      "ET1red": "",
      "DipoleMomentS0": "0.43",
      "DipoleMomentS1": "0.43",
      "DipoleMomentT1": "2.07",
      "ionizationPotential": "9.23",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "XDNIHYGZNNWMOJ-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(o1)c1cc2c3cc(c4ncc(o4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)F)cc(c5ccc4)c1ncc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(o1)c1cc2c3cc(c4ncc(o4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(o1)F)cc(c5ccc4)c1ncc(o1)F",
      "HOMO": "-5.64",
      "LUMO": "-2.99",
      "VEE": "2.84",
      "Lmax": "436.549295774648",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.06",
      "DipoleMomentS1": "1.06",
      "DipoleMomentT1": "1.03",
      "ionizationPotential": "-0.01",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "XDSADWVWSKDHSO-UHFFFAOYSA-N",
      "Smiles": "Cn1c(ccc1C(F)(F)F)c1cc2c3cc(c4ccc(n4C)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)C(F)(F)F)cc(c5ccc4)c1ccc(n1C)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=Cn1c(ccc1C(F)(F)F)c1cc2c3cc(c4ccc(n4C)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)C(F)(F)F)cc(c5ccc4)c1ccc(n1C)C(F)(F)F",
      "HOMO": "-5.65",
      "LUMO": "-2.66",
      "VEE": "2.98",
      "Lmax": "416.040268456376",
      "E00S1": "2.12",
      "E00T1": "1.64",
      "Eox": "-4.23",
      "Ered": "",
      "ES1ox": "-6.35",
      "ES1red": "",
      "ET1ox": "-5.87",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "0.16",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "XEBZNXDFTCMMMD-OCEACIFDSA-N",
      "Smiles": "COc1ccc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ccc([nH]1)OC",
      "HOMO": "-5.33",
      "LUMO": "-2.5",
      "VEE": "2.82",
      "Lmax": "439.645390070922",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "7.25",
      "ionizationPotential": "5.97",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "XECPDTPOJORBRT-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc(s1)OC)C#N)c1ccc(s1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc(s1)OC)C#N)c1ccc(s1)OC",
      "HOMO": "-6.1",
      "LUMO": "-2.74",
      "VEE": "3.03",
      "Lmax": "409.174917491749",
      "E00S1": "",
      "E00T1": "2.26",
      "Eox": "-1.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.44",
      "ET1red": "",
      "DipoleMomentS0": "5.66",
      "DipoleMomentS1": "5.66",
      "DipoleMomentT1": "9.18",
      "ionizationPotential": "7.43",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "XEHTXGWIUJETJE-UHFFFAOYSA-N",
      "Smiles": "N#Cc1cc(c(c(c1)C1=CC=CS1(=O)=O)C#N)C1=CC=CS1(=O)=O",
      "MolView_url": "http://molview.org/?q=N#Cc1cc(c(c(c1)C1=CC=CS1(=O)=O)C#N)C1=CC=CS1(=O)=O",
      "HOMO": "-7.17",
      "LUMO": "-3.79",
      "VEE": "3.35",
      "Lmax": "370.089552238806",
      "E00S1": "",
      "E00T1": "2.02",
      "Eox": "-0.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-2.18",
      "ET1red": "",
      "DipoleMomentS0": "14.34",
      "DipoleMomentS1": "14.34",
      "DipoleMomentT1": "12.73",
      "ionizationPotential": "8.78",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "XEMSEOUSOFZWHP-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc([nH]1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc([nH]1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-7.52",
      "LUMO": "-3.35",
      "VEE": "3.51",
      "Lmax": "353.219373219373",
      "E00S1": "2.88",
      "E00T1": "",
      "Eox": "2.56",
      "Ered": "",
      "ES1ox": "-0.32",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.57",
      "DipoleMomentS1": "5.57",
      "DipoleMomentT1": "8.34",
      "ionizationPotential": "9.23",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "XFBWTMXPYPDQRW-FMQUCBEESA-N",
      "Smiles": "SC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)S",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)S",
      "HOMO": "-5.15",
      "LUMO": "-2.88",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "0.48",
      "Eox": "0.26",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.22",
      "ET1red": "",
      "DipoleMomentS0": "2.81",
      "DipoleMomentS1": "2.81",
      "DipoleMomentT1": "3.17",
      "ionizationPotential": "5.86",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "XFBWTMXPYPDQRW-VXPUYCOJSA-N",
      "Smiles": "SC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)S",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)S",
      "HOMO": "-5.2",
      "LUMO": "-2.89",
      "VEE": "2.29",
      "Lmax": "541.397379912664",
      "E00S1": "",
      "E00T1": "-0.07",
      "Eox": "0.22",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.29",
      "ET1red": "",
      "DipoleMomentS0": "6.11",
      "DipoleMomentS1": "6.11",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.84",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "XGAJYNNFXMJFEO-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(s1)C(F)(F)F)(F)F",
      "HOMO": "-5.5",
      "LUMO": "-2.6",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.02",
      "E00T1": "1.2",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.7",
      "DipoleMomentS1": "4.7",
      "DipoleMomentT1": "4.62",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "XHBGMOUYDPMOMP-FMQUCBEESA-N",
      "Smiles": "Fc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(s1)F",
      "HOMO": "-6.04",
      "LUMO": "-3.26",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.1",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.76",
      "DipoleMomentS1": "2.76",
      "DipoleMomentT1": "2.24",
      "ionizationPotential": "6.86",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "XHBGMOUYDPMOMP-VXPUYCOJSA-N",
      "Smiles": "Fc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(s1)F",
      "HOMO": "-6.03",
      "LUMO": "-3.21",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "",
      "E00T1": "0.17",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.8",
      "ET1red": "",
      "DipoleMomentS0": "5.29",
      "DipoleMomentS1": "5.29",
      "DipoleMomentT1": "4.76",
      "ionizationPotential": "6.8",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "XHJDAOXUHIPYRI-UHFFFAOYSA-N",
      "Smiles": "FC(OC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)OC(F)(F)F)cc(c5ccc4)C1=CC=C(S1(=O)=O)OC(F)(F)F)C1=CC=C(S1(=O)=O)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(S1(=O)=O)c1cc2c3cc(c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(S1(=O)=O)OC(F)(F)F)cc(c5ccc4)C1=CC=C(S1(=O)=O)OC(F)(F)F)C1=CC=C(S1(=O)=O)OC(F)(F)F)(F)F",
      "HOMO": "-5.84",
      "LUMO": "-3.18",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "",
      "E00T1": "0.26",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.01",
      "DipoleMomentS1": "0.01",
      "DipoleMomentT1": "0.7",
      "ionizationPotential": "0.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "XHLKTQGUSYKRNH-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ccc(n1C)c1cc2c3cc(c4ccc(n4C)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)[N+](=O)[O-])cc(c5ccc4)c1ccc(n1C)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ccc(n1C)c1cc2c3cc(c4ccc(n4C)[N+](=O)[O-])c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)[N+](=O)[O-])cc(c5ccc4)c1ccc(n1C)[N+](=O)[O-]",
      "HOMO": "-5.84",
      "LUMO": "-2.99",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "1.97",
      "E00T1": "0.26",
      "Eox": "-4.26",
      "Ered": "",
      "ES1ox": "-6.23",
      "ES1red": "",
      "ET1ox": "-4.52",
      "ET1red": "",
      "DipoleMomentS0": "3.43",
      "DipoleMomentS1": "3.43",
      "DipoleMomentT1": "3.23",
      "ionizationPotential": "0.07",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "XHSAZBNXKNIAKB-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)c1ncc([nH]1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1ncc([nH]1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-7.89",
      "LUMO": "-3.49",
      "VEE": "3.6",
      "Lmax": "344.388888888889",
      "E00S1": "3.06",
      "E00T1": "2.35",
      "Eox": "2.92",
      "Ered": "",
      "ES1ox": "-0.14",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "4.87",
      "DipoleMomentS1": "4.87",
      "DipoleMomentT1": "9.63",
      "ionizationPotential": "9.69",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "XICAZMHZWNAGJT-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(s1)c1cc2c3cc(c4ccc(s4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)O)cc(c5ccc4)c1ccc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(s1)c1cc2c3cc(c4ccc(s4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(s1)O)cc(c5ccc4)c1ccc(s1)O",
      "HOMO": "-5.18",
      "LUMO": "-2.49",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "1.87",
      "E00T1": "1.11",
      "Eox": "0.38",
      "Ered": "",
      "ES1ox": "-1.49",
      "ES1red": "",
      "ET1ox": "-0.73",
      "ET1red": "",
      "DipoleMomentS0": "5.97",
      "DipoleMomentS1": "5.97",
      "DipoleMomentT1": "5.8",
      "ionizationPotential": "0.08",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "XICRBQHEQWMQPJ-WCWDXBQESA-N",
      "Smiles": "Oc1ncc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cnc([nH]1)O",
      "MolView_url": "http://molview.org/?q=Oc1ncc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cnc([nH]1)O",
      "HOMO": "-5.8",
      "LUMO": "-2.65",
      "VEE": "2.79",
      "Lmax": "444.372759856631",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "0.99",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.06",
      "ET1red": "",
      "DipoleMomentS0": "1.26",
      "DipoleMomentS1": "1.26",
      "DipoleMomentT1": "5.21",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "XIXVYJSBTZVSOC-UHFFFAOYSA-N",
      "Smiles": "O=S1(=O)C=CC=C1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=CS1(=O)=O",
      "MolView_url": "http://molview.org/?q=O=S1(=O)C=CC=C1c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1C1=CC=CS1(=O)=O",
      "HOMO": "-5.61",
      "LUMO": "-3",
      "VEE": "1.48",
      "Lmax": "837.702702702703",
      "E00S1": "1.98",
      "E00T1": "1.02",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.55",
      "DipoleMomentS1": "5.55",
      "DipoleMomentT1": "4.14",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "XJFLSTJYJASTTK-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(O)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)O",
      "MolView_url": "http://molview.org/?q=O=Cn1c(O)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)O",
      "HOMO": "-6.09",
      "LUMO": "-3.15",
      "VEE": "2.16",
      "Lmax": "573.981481481481",
      "E00S1": "2.23",
      "E00T1": "1.56",
      "Eox": "1.2",
      "Ered": "",
      "ES1ox": "-1.03",
      "ES1red": "",
      "ET1ox": "-0.36",
      "ET1red": "",
      "DipoleMomentS0": "2.33",
      "DipoleMomentS1": "2.33",
      "DipoleMomentT1": "19.85",
      "ionizationPotential": "7.11",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "XKBXMNCOHQRVEF-FOCLMDBBSA-N",
      "Smiles": "ClC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CCl",
      "MolView_url": "http://molview.org/?q=ClC#Cc1ccc(s1)/N=N/c1ccc(s1)C#CCl",
      "HOMO": "-6.15",
      "LUMO": "-3.16",
      "VEE": "3.07",
      "Lmax": "403.843648208469",
      "E00S1": "",
      "E00T1": "0.86",
      "Eox": "1.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.34",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.99",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "XKBXMNCOHQRVEF-NXVVXOECSA-N",
      "Smiles": "ClC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CCl",
      "MolView_url": "http://molview.org/?q=ClC#Cc1ccc(s1)/N=N\\c1ccc(s1)C#CCl",
      "HOMO": "-6.1",
      "LUMO": "-3.07",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "1.06",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.85",
      "ET1red": "",
      "DipoleMomentS0": "5.19",
      "DipoleMomentS1": "5.19",
      "DipoleMomentT1": "4.48",
      "ionizationPotential": "6.9",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "XKOIWKKHOLSVAX-HKDSNFNUSA-N",
      "Smiles": "CO/C=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=C\\OC)ccc2-c([n+]31)c4)Cl)/C=C/OC",
      "MolView_url": "http://molview.org/?q=CO/C=C/c1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(/C=C\\OC)ccc2-c([n+]31)c4)Cl)/C=C/OC",
      "HOMO": "-5.34",
      "LUMO": "-2.3",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.21",
      "E00T1": "",
      "Eox": "0.57",
      "Ered": "",
      "ES1ox": "-1.64",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "10.12",
      "DipoleMomentS1": "10.12",
      "DipoleMomentT1": "",
      "ionizationPotential": "5.88",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "XLEGUMMIGGVXKB-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(cn1)O)C#N)c1ncc(cn1)O",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(cn1)O)C#N)c1ncc(cn1)O",
      "HOMO": "-6.92",
      "LUMO": "-2.92",
      "VEE": "3.78",
      "Lmax": "327.989417989418",
      "E00S1": "",
      "E00T1": "2.78",
      "Eox": "-0.94",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.72",
      "ET1red": "",
      "DipoleMomentS0": "7.9",
      "DipoleMomentS1": "7.9",
      "DipoleMomentT1": "9.89",
      "ionizationPotential": "8.29",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "XLXAYTWNPGTNSG-UHFFFAOYSA-N",
      "Smiles": "c1nnc(nn1)c1cc2c3cc(c4nncnn4)c4c5c3c(c3c2c2c1cccc2c(c3)c1nncnn1)cc(c5ccc4)c1nncnn1",
      "MolView_url": "http://molview.org/?q=c1nnc(nn1)c1cc2c3cc(c4nncnn4)c4c5c3c(c3c2c2c1cccc2c(c3)c1nncnn1)cc(c5ccc4)c1nncnn1",
      "HOMO": "-5.91",
      "LUMO": "-3.22",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "1.36",
      "Eox": "1.15",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.21",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.92",
      "ionizationPotential": "6.8",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "XNWSCCZGXTUIAV-WCWDXBQESA-N",
      "Smiles": "Fc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)F",
      "HOMO": "-6.56",
      "LUMO": "-3.04",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "1.06",
      "Eox": "1.68",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.62",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "3.51",
      "ionizationPotential": "7.48",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "XNXCXENKTKRNJU-NYEUIXQESA-N",
      "Smiles": "O/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C\\O",
      "MolView_url": "http://molview.org/?q=O/C=C\\c1ccc(o1)/N=N/c1ccc(o1)/C=C\\O",
      "HOMO": "-5.47",
      "LUMO": "-2.71",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "2.1",
      "E00T1": "0.72",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.77",
      "DipoleMomentS1": "1.77",
      "DipoleMomentT1": "1.59",
      "ionizationPotential": "6.35",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "XOQGXSYVWVOCNN-FOCLMDBBSA-N",
      "Smiles": "Fc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)F",
      "MolView_url": "http://molview.org/?q=Fc1nnc(nn1)c1ccc(o1)/N=N/c1ccc(o1)c1nnc(nn1)F",
      "HOMO": "-6.66",
      "LUMO": "-3.62",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "2.16",
      "E00T1": "",
      "Eox": "1.66",
      "Ered": "",
      "ES1ox": "-0.5",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "2.13",
      "ionizationPotential": "7.76",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "XOWBFOLEPIDIHX-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(C)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)C",
      "MolView_url": "http://molview.org/?q=O=Cn1c(C)ccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(n1C=O)C",
      "HOMO": "-6.35",
      "LUMO": "-3.13",
      "VEE": "2.18",
      "Lmax": "568.715596330275",
      "E00S1": "2.22",
      "E00T1": "",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "-0.81",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.07",
      "DipoleMomentS1": "5.07",
      "DipoleMomentT1": "13.72",
      "ionizationPotential": "7.23",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "XPFBQFTXZFDQIM-UHFFFAOYSA-N",
      "Smiles": "COc1ccc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc([nH]1)OC",
      "MolView_url": "http://molview.org/?q=COc1ccc([nH]1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc([nH]1)OC",
      "HOMO": "-5.64",
      "LUMO": "-2.96",
      "VEE": "1.78",
      "Lmax": "696.516853932584",
      "E00S1": "1.78",
      "E00T1": "1.54",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.46",
      "DipoleMomentS1": "4.46",
      "DipoleMomentT1": "6.9",
      "ionizationPotential": "6.47",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "XPVUVRIUPCAJTM-SFECMWDFSA-N",
      "Smiles": "F/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\F",
      "MolView_url": "http://molview.org/?q=F/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\F",
      "HOMO": "-6.94",
      "LUMO": "-3.17",
      "VEE": "2.56",
      "Lmax": "484.296875",
      "E00S1": "2.9",
      "E00T1": "2.03",
      "Eox": "2.06",
      "Ered": "",
      "ES1ox": "-0.84",
      "ES1red": "",
      "ET1ox": "0.03",
      "ET1red": "",
      "DipoleMomentS0": "1.26",
      "DipoleMomentS1": "1.26",
      "DipoleMomentT1": "8.17",
      "ionizationPotential": "8.11",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "XQFPONIHCGDLKU-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(nc1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(nc1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-8.02",
      "LUMO": "-2.72",
      "VEE": "3.84",
      "Lmax": "322.864583333333",
      "E00S1": "3.56",
      "E00T1": "2.95",
      "Eox": "3.13",
      "Ered": "",
      "ES1ox": "-0.43",
      "ES1red": "",
      "ET1ox": "0.18",
      "ET1red": "",
      "DipoleMomentS0": "2.05",
      "DipoleMomentS1": "2.05",
      "DipoleMomentT1": "6.17",
      "ionizationPotential": "9.58",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "XQGXFJJBYKALIK-UHFFFAOYSA-N",
      "Smiles": "Fc1coc(n1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1coc(n1)[C@]1(F)N=N1",
      "HOMO": "-7.53",
      "LUMO": "-2.89",
      "VEE": "3.19",
      "Lmax": "388.652037617555",
      "E00S1": "3.06",
      "E00T1": "2.43",
      "Eox": "2.61",
      "Ered": "",
      "ES1ox": "-0.45",
      "ES1red": "",
      "ET1ox": "0.18",
      "ET1red": "",
      "DipoleMomentS0": "2.99",
      "DipoleMomentS1": "2.99",
      "DipoleMomentT1": "8.91",
      "ionizationPotential": "9.29",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "XQJQTSXSKRJHBX-FOCLMDBBSA-N",
      "Smiles": "Fc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)F",
      "MolView_url": "http://molview.org/?q=Fc1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)F",
      "HOMO": "-6.69",
      "LUMO": "-3.63",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.73",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.03",
      "DipoleMomentS1": "5.03",
      "DipoleMomentT1": "5.58",
      "ionizationPotential": "7.76",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "XQJQTSXSKRJHBX-NXVVXOECSA-N",
      "Smiles": "Fc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)F",
      "MolView_url": "http://molview.org/?q=Fc1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)F",
      "HOMO": "-6.58",
      "LUMO": "-3.55",
      "VEE": "2.32",
      "Lmax": "534.396551724138",
      "E00S1": "",
      "E00T1": "0.23",
      "Eox": "1.51",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.28",
      "ET1red": "",
      "DipoleMomentS0": "1.74",
      "DipoleMomentS1": "1.74",
      "DipoleMomentT1": "1.74",
      "ionizationPotential": "7.6",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "XQWCLWVPBGMXTB-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1c1cc2c3cc(c4ccc(n4C=O)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)OC(F)(F)F)cc(c5ccc4)c1ccc(n1C=O)OC(F)(F)F)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1c1cc2c3cc(c4ccc(n4C=O)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C=O)OC(F)(F)F)cc(c5ccc4)c1ccc(n1C=O)OC(F)(F)F)OC(F)(F)F",
      "HOMO": "-5.72",
      "LUMO": "-2.69",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "2.15",
      "E00T1": "0.3",
      "Eox": "-4.13",
      "Ered": "",
      "ES1ox": "-6.28",
      "ES1red": "",
      "ET1ox": "-4.43",
      "ET1red": "",
      "DipoleMomentS0": "9.11",
      "DipoleMomentS1": "9.11",
      "DipoleMomentT1": "",
      "ionizationPotential": "0.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "XSAWUCPCOZXAAW-UHFFFAOYSA-N",
      "Smiles": "Oc1cnc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(s1)O)c1ncc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1cnc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ncc(s1)O)c1ncc(s1)O",
      "HOMO": "-5.58",
      "LUMO": "-2.52",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "2.23",
      "E00T1": "",
      "Eox": "-4.31",
      "Ered": "",
      "ES1ox": "-6.54",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "8.73",
      "DipoleMomentS1": "8.73",
      "DipoleMomentT1": "9.92",
      "ionizationPotential": "6.17",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "XSMKDNAUZRLVCZ-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)S)c1ccc(n1C)S",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)S)c1ccc(n1C)S",
      "HOMO": "-6.13",
      "LUMO": "-3.1",
      "VEE": "2.24",
      "Lmax": "553.482142857143",
      "E00S1": "1.92",
      "E00T1": "",
      "Eox": "0.94",
      "Ered": "",
      "ES1ox": "-0.98",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.45",
      "DipoleMomentS1": "3.45",
      "DipoleMomentT1": "23.87",
      "ionizationPotential": "6.8",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "XSRMZVRMOIBEAO-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)[N+](=O)[O-]",
      "HOMO": "-6.64",
      "LUMO": "-3.88",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.76",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.18",
      "DipoleMomentS1": "6.18",
      "DipoleMomentT1": "6.39",
      "ionizationPotential": "7.71",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "XTQJBHDJHRZCMB-FMQUCBEESA-N",
      "Smiles": "Clc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1coc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1occ(n1)Cl",
      "HOMO": "-6.17",
      "LUMO": "-3.25",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "1.49",
      "ionizationPotential": "7.04",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "XUDDEHLBXFUORL-DQRAZIAOSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(nc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ncc(cn1)[N+](=O)[O-]",
      "HOMO": "-6.52",
      "LUMO": "-3.71",
      "VEE": "2.28",
      "Lmax": "543.771929824561",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.19",
      "ET1red": "",
      "DipoleMomentS0": "0.53",
      "DipoleMomentS1": "0.53",
      "DipoleMomentT1": "0.75",
      "ionizationPotential": "7.43",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "XUDDEHLBXFUORL-QURGRASLSA-N",
      "Smiles": "[O-][N+](=O)c1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1cnc(nc1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(cn1)[N+](=O)[O-]",
      "HOMO": "-6.62",
      "LUMO": "-3.85",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.59",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "XWCZLASPNZHQBK-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=CS1(=O)=O)C1=CC=CS1(=O)=O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=CS1(=O)=O)C1=CC=CS1(=O)=O",
      "HOMO": "-7.15",
      "LUMO": "-3.5",
      "VEE": "1.83",
      "Lmax": "677.486338797814",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.25",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.87",
      "DipoleMomentS1": "6.87",
      "DipoleMomentT1": "13.25",
      "ionizationPotential": "8.16",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "XWINHINTUGRJJI-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(o1)C)C#N)c1ncc(o1)C",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(o1)C)C#N)c1ncc(o1)C",
      "HOMO": "-6.64",
      "LUMO": "-3.01",
      "VEE": "3.19",
      "Lmax": "388.652037617555",
      "E00S1": "",
      "E00T1": "2.45",
      "Eox": "-0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.42",
      "ET1red": "",
      "DipoleMomentS0": "3.24",
      "DipoleMomentS1": "3.24",
      "DipoleMomentT1": "5.13",
      "ionizationPotential": "7.97",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "XXCVOPWRUIIUPN-FMQUCBEESA-N",
      "Smiles": "Cc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(o1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(o1)C",
      "HOMO": "-5.89",
      "LUMO": "-3.1",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "2.08",
      "E00T1": "0.76",
      "Eox": "0.95",
      "Ered": "",
      "ES1ox": "-1.13",
      "ES1red": "",
      "ET1ox": "0.19",
      "ET1red": "",
      "DipoleMomentS0": "3.47",
      "DipoleMomentS1": "3.47",
      "DipoleMomentT1": "4.32",
      "ionizationPotential": "6.58",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "XXYGXHDDMBNMGU-UHFFFAOYSA-N",
      "Smiles": "O=Cn1cccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cccn1C=O)c1cccn1C=O",
      "MolView_url": "http://molview.org/?q=O=Cn1cccc1c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1cccn1C=O)c1cccn1C=O",
      "HOMO": "-5.64",
      "LUMO": "-2.48",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "1.62",
      "Eox": "0.79",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.83",
      "ET1red": "",
      "DipoleMomentS0": "7.57",
      "DipoleMomentS1": "7.57",
      "DipoleMomentT1": "8.59",
      "ionizationPotential": "6.53",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "XYCUIPWXRMKQIE-WCWDXBQESA-N",
      "Smiles": "N#Cc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cc([nH]c1)c1ccc(s1)/N=N/c1ccc(s1)c1[nH]cc(c1)C#N",
      "HOMO": "-5.77",
      "LUMO": "-3",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "0.78",
      "Eox": "0.79",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "7.87",
      "DipoleMomentS1": "7.87",
      "DipoleMomentT1": "8.63",
      "ionizationPotential": "6.78",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "XYURNJUMVFIFBP-UHFFFAOYSA-N",
      "Smiles": "Clc1nnc(nn1)[C@]1(Cl)N=N1",
      "MolView_url": "http://molview.org/?q=Clc1nnc(nn1)[C@]1(Cl)N=N1",
      "HOMO": "-7.8",
      "LUMO": "-3.74",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "2.11",
      "E00T1": "1.45",
      "Eox": "2.78",
      "Ered": "",
      "ES1ox": "0.67",
      "ES1red": "",
      "ET1ox": "1.33",
      "ET1red": "",
      "DipoleMomentS0": "0.35",
      "DipoleMomentS1": "0.35",
      "DipoleMomentT1": "0.53",
      "ionizationPotential": "9.24",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "XYYVLCZXNVOVEX-OCEACIFDSA-N",
      "Smiles": "FC(OC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)OC(F)(F)F)(F)F",
      "HOMO": "-6.22",
      "LUMO": "-3.64",
      "VEE": "3.03",
      "Lmax": "409.174917491749",
      "E00S1": "",
      "E00T1": "0.51",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.77",
      "ET1red": "",
      "DipoleMomentS0": "0.7",
      "DipoleMomentS1": "0.7",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "XYYZHXPUWJXTKW-UHFFFAOYSA-N",
      "Smiles": "O=Cn1c(ccc1[C@@]1(N=N1)C(F)(F)F)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=Cn1c(ccc1[C@@]1(N=N1)C(F)(F)F)C(F)(F)F",
      "HOMO": "-7.74",
      "LUMO": "-2.69",
      "VEE": "3.74",
      "Lmax": "331.497326203208",
      "E00S1": "3.56",
      "E00T1": "2.68",
      "Eox": "2.63",
      "Ered": "",
      "ES1ox": "-0.93",
      "ES1red": "",
      "ET1ox": "-0.05",
      "ET1red": "",
      "DipoleMomentS0": "4.59",
      "DipoleMomentS1": "4.59",
      "DipoleMomentT1": "9.23",
      "ionizationPotential": "9.12",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "XZCUIVOSLABRFP-KQQUZDAGSA-N",
      "Smiles": "FC(/C=C/c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C/c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C/C(F)(F)F)(F)F",
      "HOMO": "-7.44",
      "LUMO": "-3.29",
      "VEE": "2.72",
      "Lmax": "455.808823529412",
      "E00S1": "2.94",
      "E00T1": "2.23",
      "Eox": "2.54",
      "Ered": "",
      "ES1ox": "-0.4",
      "ES1red": "",
      "ET1ox": "0.31",
      "ET1red": "",
      "DipoleMomentS0": "2.23",
      "DipoleMomentS1": "2.23",
      "DipoleMomentT1": "5.37",
      "ionizationPotential": "8.68",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "XZUJYXLHLMOXGB-UHFFFAOYSA-N",
      "Smiles": "O/N=C/[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=O/N=C/[C@]1(O)N=N1",
      "HOMO": "-7.39",
      "LUMO": "-2.46",
      "VEE": "3.28",
      "Lmax": "377.987804878049",
      "E00S1": "2.87",
      "E00T1": "2.25",
      "Eox": "2.44",
      "Ered": "",
      "ES1ox": "-0.43",
      "ES1red": "",
      "ET1ox": "0.19",
      "ET1red": "",
      "DipoleMomentS0": "3.89",
      "DipoleMomentS1": "3.89",
      "DipoleMomentT1": "6.72",
      "ionizationPotential": "9.02",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "YAIMSTKONOBBBH-OCEACIFDSA-N",
      "Smiles": "FC(Oc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(s1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(s1)OC(F)(F)F)(F)F",
      "HOMO": "-6.09",
      "LUMO": "-3.3",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "2.06",
      "E00T1": "0.75",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.82",
      "DipoleMomentS1": "3.82",
      "DipoleMomentT1": "3.59",
      "ionizationPotential": "6.97",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "YBPMXCYXGLRPPI-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)O)C1=CC=C(S1(=O)=O)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)O)C1=CC=C(S1(=O)=O)O",
      "HOMO": "-6.73",
      "LUMO": "-3.31",
      "VEE": "1.56",
      "Lmax": "794.74358974359",
      "E00S1": "2.43",
      "E00T1": "1.4",
      "Eox": "1.82",
      "Ered": "",
      "ES1ox": "-0.61",
      "ES1red": "",
      "ET1ox": "0.42",
      "ET1red": "",
      "DipoleMomentS0": "13.36",
      "DipoleMomentS1": "13.36",
      "DipoleMomentT1": "13.44",
      "ionizationPotential": "7.73",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YBXVIOSGZHURCJ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ccc([nH]1)S)C#N)c1ccc([nH]1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ccc([nH]1)S)C#N)c1ccc([nH]1)S",
      "HOMO": "-5.68",
      "LUMO": "-2.72",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.05",
      "DipoleMomentS1": "5.05",
      "DipoleMomentT1": "9.76",
      "ionizationPotential": "7.14",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "YDJAGHQEEIVKPW-ISLYRVAYSA-N",
      "Smiles": "Oc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)O",
      "HOMO": "-5.4",
      "LUMO": "-2.89",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "",
      "E00T1": "0.67",
      "Eox": "0.49",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.18",
      "ET1red": "",
      "DipoleMomentS0": "1.91",
      "DipoleMomentS1": "1.91",
      "DipoleMomentT1": "1.95",
      "ionizationPotential": "6.04",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YDJAGHQEEIVKPW-ZCXUNETKSA-N",
      "Smiles": "Oc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)O",
      "HOMO": "-5.45",
      "LUMO": "-2.89",
      "VEE": "2.43",
      "Lmax": "510.205761316872",
      "E00S1": "",
      "E00T1": "-0.04",
      "Eox": "0.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.36",
      "ET1red": "",
      "DipoleMomentS0": "7.64",
      "DipoleMomentS1": "7.64",
      "DipoleMomentT1": "2.01",
      "ionizationPotential": "6.05",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "YDMXLEOUUGAJRP-HUWOUMPJSA-N",
      "Smiles": "[O-][N+](=O)/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/N=C\\c1ccc(s1)/N=N\\c1ccc(s1)/C=N\\[N+](=O)[O-]",
      "HOMO": "-6.94",
      "LUMO": "-3.83",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "0.26",
      "Eox": "1.85",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.59",
      "ET1red": "",
      "DipoleMomentS0": "9.97",
      "DipoleMomentS1": "9.97",
      "DipoleMomentT1": "9.03",
      "ionizationPotential": "8.06",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "YDMXLEOUUGAJRP-NSFMOVNFSA-N",
      "Smiles": "[O-][N+](=O)/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)/N=C\\c1ccc(s1)/N=N/c1ccc(s1)/C=N\\[N+](=O)[O-]",
      "HOMO": "-7.03",
      "LUMO": "-3.88",
      "VEE": "3.02",
      "Lmax": "410.529801324503",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.06",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.01",
      "DipoleMomentS1": "0.01",
      "DipoleMomentT1": "2.54",
      "ionizationPotential": "8.21",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YEOWZRYRGMLDHH-FMQUCBEESA-N",
      "Smiles": "COc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)OC",
      "HOMO": "-5.71",
      "LUMO": "-3.12",
      "VEE": "2.75",
      "Lmax": "450.836363636364",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.82",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.33",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YEWVDGDKQBCJCU-MZAUSLISSA-N",
      "Smiles": "S/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N\\S",
      "MolView_url": "http://molview.org/?q=S/N=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=N\\S",
      "HOMO": "-6.73",
      "LUMO": "-3.22",
      "VEE": "2.48",
      "Lmax": "499.91935483871",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.16",
      "DipoleMomentS1": "1.16",
      "DipoleMomentT1": "4.71",
      "ionizationPotential": "7.83",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YFBHWMHWOVFIMH-UHFFFAOYSA-N",
      "Smiles": "Sc1cc2c3cc(S)c4c5c3c(c3c2c2c1cccc2c(c3)S)cc(c5ccc4)S",
      "MolView_url": "http://molview.org/?q=Sc1cc2c3cc(S)c4c5c3c(c3c2c2c1cccc2c(c3)S)cc(c5ccc4)S",
      "HOMO": "-5.39",
      "LUMO": "-2.51",
      "VEE": "2.88",
      "Lmax": "430.486111111111",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.76",
      "DipoleMomentS1": "1.76",
      "DipoleMomentT1": "2.32",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "YFNCKTCFCGZXRT-UHFFFAOYSA-N",
      "Smiles": "Cc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(s1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(s1)C",
      "HOMO": "-5.45",
      "LUMO": "-2.6",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.15",
      "E00T1": "1.17",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.76",
      "DipoleMomentS1": "2.76",
      "DipoleMomentT1": "1.96",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "YFXOZWBVNXKPLD-UHFFFAOYSA-N",
      "Smiles": "c1ccc([nH]1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=c1ccc([nH]1)[C@@H]1N=N1",
      "HOMO": "-6.17",
      "LUMO": "-2.13",
      "VEE": "3.2",
      "Lmax": "387.4375",
      "E00S1": "2.51",
      "E00T1": "1.96",
      "Eox": "1.27",
      "Ered": "",
      "ES1ox": "-1.24",
      "ES1red": "",
      "ET1ox": "-0.69",
      "ET1red": "",
      "DipoleMomentS0": "0.85",
      "DipoleMomentS1": "0.85",
      "DipoleMomentT1": "8.64",
      "ionizationPotential": "7.77",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "YGFADCFQUXALFA-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(o1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(o1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(o1)OC(F)(F)F)(F)F",
      "HOMO": "-6.6",
      "LUMO": "-3.18",
      "VEE": "2.21",
      "Lmax": "560.995475113122",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.72",
      "DipoleMomentS1": "4.72",
      "DipoleMomentT1": "8.08",
      "ionizationPotential": "7.47",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YGSOAHYBMOOYFP-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)c1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1csc(n1)c1ccc(o1)/N=N/c1ccc(o1)c1scc(n1)[N+](=O)[O-]",
      "HOMO": "-6.31",
      "LUMO": "-3.44",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "2.13",
      "E00T1": "0.79",
      "Eox": "1.36",
      "Ered": "",
      "ES1ox": "-0.77",
      "ES1red": "",
      "ET1ox": "0.57",
      "ET1red": "",
      "DipoleMomentS0": "0.66",
      "DipoleMomentS1": "0.66",
      "DipoleMomentT1": "0.87",
      "ionizationPotential": "7.43",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "YHMJOMCVSOIKEK-UHFFFAOYSA-N",
      "Smiles": "Sc1cnc(s1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1cnc(s1)[C@]1(S)N=N1",
      "HOMO": "-6.96",
      "LUMO": "-2.57",
      "VEE": "3.6",
      "Lmax": "344.388888888889",
      "E00S1": "2.68",
      "E00T1": "2.11",
      "Eox": "1.71",
      "Ered": "",
      "ES1ox": "-0.97",
      "ES1red": "",
      "ET1ox": "-0.4",
      "ET1red": "",
      "DipoleMomentS0": "3.21",
      "DipoleMomentS1": "3.21",
      "DipoleMomentT1": "9.55",
      "ionizationPotential": "8.1",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "YIACYVPXHITQHF-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)C)c1ccc([nH]1)C",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc([nH]1)C)c1ccc([nH]1)C",
      "HOMO": "-5.84",
      "LUMO": "-3.02",
      "VEE": "1.88",
      "Lmax": "659.468085106383",
      "E00S1": "1.9",
      "E00T1": "",
      "Eox": "1.05",
      "Ered": "",
      "ES1ox": "-0.85",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.63",
      "DipoleMomentS1": "6.63",
      "DipoleMomentT1": "11.59",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YIJONLONYQMHSA-UHFFFAOYSA-N",
      "Smiles": "Cn1cccc1[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=Cn1cccc1[C@@H]1N=N1",
      "HOMO": "-6.14",
      "LUMO": "-2.01",
      "VEE": "3.65",
      "Lmax": "339.671232876712",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.2",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.85",
      "DipoleMomentS1": "4.85",
      "DipoleMomentT1": "10.94",
      "ionizationPotential": "7.56",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "YIKZGUMSMXDYAY-SFECMWDFSA-N",
      "Smiles": "Cl/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\Cl",
      "MolView_url": "http://molview.org/?q=Cl/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\Cl",
      "HOMO": "-6.93",
      "LUMO": "-3.23",
      "VEE": "2.57",
      "Lmax": "482.412451361868",
      "E00S1": "2.86",
      "E00T1": "",
      "Eox": "2.08",
      "Ered": "",
      "ES1ox": "-0.78",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.9",
      "DipoleMomentS1": "0.9",
      "DipoleMomentT1": "9.37",
      "ionizationPotential": "8.04",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YJGWJMCDBBCECK-WCWDXBQESA-N",
      "Smiles": "Fc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1csc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1scc(n1)F",
      "HOMO": "-6.46",
      "LUMO": "-3.06",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.65",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.6",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "1.36",
      "ionizationPotential": "7.36",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "YJJCIEWXKKGOBE-WCWDXBQESA-N",
      "Smiles": "Fc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)F",
      "HOMO": "-6.47",
      "LUMO": "-2.97",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "1.46",
      "Eox": "1.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.12",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.35",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "YJSRAZHTCVWBTN-UHFFFAOYSA-N",
      "Smiles": "ClC#Cc1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(C#CCl)ccc2-c([n+]31)c4)Cl)C#CCl",
      "MolView_url": "http://molview.org/?q=ClC#Cc1ccc2c(c1)c1cc3c4ccc(cc4c4n3[B@@-]3(n1c2cc1-c2cc(C#CCl)ccc2-c([n+]31)c4)Cl)C#CCl",
      "HOMO": "-5.69",
      "LUMO": "-2.52",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "0.11",
      "Eox": "0.84",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.73",
      "ET1red": "",
      "DipoleMomentS0": "6.29",
      "DipoleMomentS1": "6.29",
      "DipoleMomentT1": "6.58",
      "ionizationPotential": "0.04",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "YJXPEWNQQZOSIX-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(o1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(o1)Cl",
      "HOMO": "-5.36",
      "LUMO": "-2.55",
      "VEE": "1.47",
      "Lmax": "843.401360544218",
      "E00S1": "2.14",
      "E00T1": "1.15",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.3",
      "DipoleMomentS1": "2.3",
      "DipoleMomentT1": "2.24",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "YKAWGSCWUZEUFM-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)Cl)c1ccc(n1C)Cl",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(n1C)Cl)c1ccc(n1C)Cl",
      "HOMO": "-6.12",
      "LUMO": "-3.1",
      "VEE": "2.11",
      "Lmax": "587.582938388626",
      "E00S1": "2.31",
      "E00T1": "1.66",
      "Eox": "1.42",
      "Ered": "",
      "ES1ox": "-0.89",
      "ES1red": "",
      "ET1ox": "-0.24",
      "ET1red": "",
      "DipoleMomentS0": "1.09",
      "DipoleMomentS1": "1.09",
      "DipoleMomentT1": "19.88",
      "ionizationPotential": "7.03",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YKOQPONUQQUCRK-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1occ(n1)C#N)C#N)c1occ(n1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1occ(n1)C#N)C#N)c1occ(n1)C#N",
      "HOMO": "-7.4",
      "LUMO": "-3.34",
      "VEE": "3.67",
      "Lmax": "337.820163487738",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-0.53",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.15",
      "DipoleMomentS1": "5.15",
      "DipoleMomentT1": "4.31",
      "ionizationPotential": "9.13",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "YKXOZRNVEVFZRM-ISLYRVAYSA-N",
      "Smiles": "c1csc(c1)c1ccc(s1)/N=N/c1ccc(s1)c1cccs1",
      "MolView_url": "http://molview.org/?q=c1csc(c1)c1ccc(s1)/N=N/c1ccc(s1)c1cccs1",
      "HOMO": "-5.7",
      "LUMO": "-3.02",
      "VEE": "3.09",
      "Lmax": "401.229773462783",
      "E00S1": "",
      "E00T1": "0.73",
      "Eox": "0.77",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.04",
      "ET1red": "",
      "DipoleMomentS0": "0.12",
      "DipoleMomentS1": "0.12",
      "DipoleMomentT1": "0.02",
      "ionizationPotential": "6.4",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YKXOZRNVEVFZRM-ZCXUNETKSA-N",
      "Smiles": "c1csc(c1)c1ccc(s1)/N=N\\c1ccc(s1)c1cccs1",
      "MolView_url": "http://molview.org/?q=c1csc(c1)c1ccc(s1)/N=N\\c1ccc(s1)c1cccs1",
      "HOMO": "-5.72",
      "LUMO": "-2.98",
      "VEE": "2.42",
      "Lmax": "512.314049586777",
      "E00S1": "",
      "E00T1": "0.14",
      "Eox": "0.64",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.5",
      "ET1red": "",
      "DipoleMomentS0": "6",
      "DipoleMomentS1": "6",
      "DipoleMomentT1": "5.19",
      "ionizationPotential": "6.36",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "YLBLEMUHRXYQQS-FMQUCBEESA-N",
      "Smiles": "ClC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CCl",
      "MolView_url": "http://molview.org/?q=ClC#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#CCl",
      "HOMO": "-6.56",
      "LUMO": "-2.88",
      "VEE": "2.73",
      "Lmax": "454.139194139194",
      "E00S1": "",
      "E00T1": "1.08",
      "Eox": "1.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.66",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "2.2",
      "ionizationPotential": "7.43",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "YLZWOXYCRGOLBY-UHFFFAOYSA-N",
      "Smiles": "Cn1c(F)ccc1c1cc2c3cc(c4ccc(n4C)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)F)cc(c5ccc4)c1ccc(n1C)F",
      "MolView_url": "http://molview.org/?q=Cn1c(F)ccc1c1cc2c3cc(c4ccc(n4C)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(n1C)F)cc(c5ccc4)c1ccc(n1C)F",
      "HOMO": "-5.44",
      "LUMO": "-2.39",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "1.93",
      "E00T1": "1.12",
      "Eox": "-4.27",
      "Ered": "",
      "ES1ox": "-6.2",
      "ES1red": "",
      "ET1ox": "-5.39",
      "ET1red": "",
      "DipoleMomentS0": "0.63",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "2.89",
      "ionizationPotential": "0.1",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "YMSLFDYDPCEIJA-UHFFFAOYSA-N",
      "Smiles": "Fc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)F)cc(c5ccc4)c1ncc(cn1)F",
      "MolView_url": "http://molview.org/?q=Fc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)F)cc(c5ccc4)c1ncc(cn1)F",
      "HOMO": "-5.58",
      "LUMO": "-2.74",
      "VEE": "2.95",
      "Lmax": "420.271186440678",
      "E00S1": "1.99",
      "E00T1": "1.15",
      "Eox": "0.76",
      "Ered": "",
      "ES1ox": "-1.23",
      "ES1red": "",
      "ET1ox": "-0.39",
      "ET1red": "",
      "DipoleMomentS0": "0.13",
      "DipoleMomentS1": "0.13",
      "DipoleMomentT1": "0.08",
      "ionizationPotential": "6.16",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "YNFSQDSLRDGBJY-UHFFFAOYSA-N",
      "Smiles": "COc1cnc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(cn1)OC",
      "MolView_url": "http://molview.org/?q=COc1cnc(nc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ncc(cn1)OC",
      "HOMO": "-6.87",
      "LUMO": "-3.16",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "2.94",
      "E00T1": "",
      "Eox": "2.07",
      "Ered": "",
      "ES1ox": "-0.87",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.84",
      "DipoleMomentS1": "4.84",
      "DipoleMomentT1": "8.71",
      "ionizationPotential": "7.66",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YNFXXMKLTIRKJU-VHXPQNKSSA-N",
      "Smiles": "N#Cc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(n1C)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(n1C)C#N",
      "HOMO": "-6.06",
      "LUMO": "-2.94",
      "VEE": "2.6",
      "Lmax": "476.846153846154",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "0.88",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.66",
      "ET1red": "",
      "DipoleMomentS0": "7.72",
      "DipoleMomentS1": "7.72",
      "DipoleMomentT1": "4.32",
      "ionizationPotential": "6.73",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "YNFXXMKLTIRKJU-WCWDXBQESA-N",
      "Smiles": "N#Cc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(n1C)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(n1C)C#N",
      "HOMO": "-5.91",
      "LUMO": "-3.1",
      "VEE": "3.08",
      "Lmax": "402.532467532468",
      "E00S1": "",
      "E00T1": "0.84",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.13",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.76",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YNZXZWNGKFZVFG-ISLYRVAYSA-N",
      "Smiles": "c1coc(c1)c1ccc(s1)/N=N/c1ccc(s1)c1ccco1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)c1ccc(s1)/N=N/c1ccc(s1)c1ccco1",
      "HOMO": "-5.65",
      "LUMO": "-2.98",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "",
      "E00T1": "0.73",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.01",
      "ET1red": "",
      "DipoleMomentS0": "1.39",
      "DipoleMomentS1": "1.39",
      "DipoleMomentT1": "2.02",
      "ionizationPotential": "6.39",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YNZXZWNGKFZVFG-ZCXUNETKSA-N",
      "Smiles": "c1coc(c1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccco1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccco1",
      "HOMO": "-5.66",
      "LUMO": "-2.95",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.63",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.21",
      "DipoleMomentS1": "7.21",
      "DipoleMomentT1": "6.34",
      "ionizationPotential": "6.33",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "YOECZXJIBOAOTM-FOCLMDBBSA-N",
      "Smiles": "ClC#Cc1ccc(o1)/N=N/c1ccc(o1)C#CCl",
      "MolView_url": "http://molview.org/?q=ClC#Cc1ccc(o1)/N=N/c1ccc(o1)C#CCl",
      "HOMO": "-6.08",
      "LUMO": "-3.08",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "2.29",
      "E00T1": "0.82",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.65",
      "DipoleMomentS1": "2.65",
      "DipoleMomentT1": "2.22",
      "ionizationPotential": "",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "YOECZXJIBOAOTM-NXVVXOECSA-N",
      "Smiles": "ClC#Cc1ccc(o1)/N=N\\c1ccc(o1)C#CCl",
      "MolView_url": "http://molview.org/?q=ClC#Cc1ccc(o1)/N=N\\c1ccc(o1)C#CCl",
      "HOMO": "-5.99",
      "LUMO": "-3.08",
      "VEE": "2.59",
      "Lmax": "478.687258687259",
      "E00S1": "",
      "E00T1": "0.21",
      "Eox": "0.97",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.76",
      "ET1red": "",
      "DipoleMomentS0": "4.8",
      "DipoleMomentS1": "4.8",
      "DipoleMomentT1": "3.89",
      "ionizationPotential": "0",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "YOQGRWZWSLUZTC-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1c[nH]c(c1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1[nH]cc(c1)OC(F)(F)F)(F)F",
      "HOMO": "-5.36",
      "LUMO": "-2.46",
      "VEE": "1.49",
      "Lmax": "832.080536912752",
      "E00S1": "",
      "E00T1": "1.2",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.66",
      "ET1red": "",
      "DipoleMomentS0": "8.97",
      "DipoleMomentS1": "8.97",
      "DipoleMomentT1": "8.35",
      "ionizationPotential": "6.26",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "YPJLYZZQAYFVAI-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)S",
      "MolView_url": "http://molview.org/?q=Sc1ccc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ccc(cn1)S",
      "HOMO": "-5.42",
      "LUMO": "-2.48",
      "VEE": "1.51",
      "Lmax": "821.059602649007",
      "E00S1": "2.26",
      "E00T1": "1.23",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.89",
      "DipoleMomentS1": "1.89",
      "DipoleMomentT1": "1.88",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "YPXHLVIGWXGSIO-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ccc(cc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cc1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc(cc1)c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1ccc(cc1)OC(F)(F)F)(F)F",
      "HOMO": "-7.11",
      "LUMO": "-3.16",
      "VEE": "2.67",
      "Lmax": "464.34456928839",
      "E00S1": "",
      "E00T1": "",
      "Eox": "2.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.97",
      "DipoleMomentS1": "2.97",
      "DipoleMomentT1": "1.23",
      "ionizationPotential": "8.11",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YQIKJLLDZVYCCL-UHFFFAOYSA-N",
      "Smiles": "FC(c1csc(n1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1csc(n1)[C@@]1(N=N1)C(F)(F)F)(F)F",
      "HOMO": "-7.85",
      "LUMO": "-2.86",
      "VEE": "3.96",
      "Lmax": "313.080808080808",
      "E00S1": "3.39",
      "E00T1": "2.77",
      "Eox": "2.86",
      "Ered": "",
      "ES1ox": "-0.53",
      "ES1red": "",
      "ET1ox": "0.09",
      "ET1red": "",
      "DipoleMomentS0": "4.51",
      "DipoleMomentS1": "4.51",
      "DipoleMomentT1": "9.1",
      "ionizationPotential": "9.45",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "YRBYTWPNNWAEPE-DQRAZIAOSA-N",
      "Smiles": "Fc1coc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1occ(n1)F",
      "MolView_url": "http://molview.org/?q=Fc1coc(n1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1occ(n1)F",
      "HOMO": "-5.91",
      "LUMO": "-2.88",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "1.57",
      "E00T1": "",
      "Eox": "0.3",
      "Ered": "",
      "ES1ox": "-1.27",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.96",
      "DipoleMomentS1": "5.96",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.08",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "YRJCQEJHVSORCM-QURGRASLSA-N",
      "Smiles": "Oc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)O",
      "MolView_url": "http://molview.org/?q=Oc1coc(n1)c1ccc(cc1)/N=N/c1ccc(cc1)c1occ(n1)O",
      "HOMO": "-6.27",
      "LUMO": "-2.98",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "1.41",
      "Eox": "1.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0",
      "ET1red": "",
      "DipoleMomentS0": "0.27",
      "DipoleMomentS1": "0.27",
      "DipoleMomentT1": "0.3",
      "ionizationPotential": "7.09",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "YSBWCFBBMLISQO-OCEACIFDSA-N",
      "Smiles": "N#Cc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)C#N",
      "HOMO": "-6.14",
      "LUMO": "-3.22",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "",
      "E00T1": "0.82",
      "Eox": "1.19",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.37",
      "ET1red": "",
      "DipoleMomentS0": "7.33",
      "DipoleMomentS1": "7.33",
      "DipoleMomentT1": "7.35",
      "ionizationPotential": "7.12",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YSBWCFBBMLISQO-QPLCGJKRSA-N",
      "Smiles": "N#Cc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)C#N",
      "HOMO": "-6.11",
      "LUMO": "-3.14",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.12",
      "DipoleMomentS1": "1.12",
      "DipoleMomentT1": "0.76",
      "ionizationPotential": "7.04",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "YSPNHIGDZKPKML-UHFFFAOYSA-N",
      "Smiles": "N#C/N=C/[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#C/N=C/[C@]1(C#N)N=N1",
      "HOMO": "-8.77",
      "LUMO": "-3.23",
      "VEE": "3.81",
      "Lmax": "325.406824146982",
      "E00S1": "",
      "E00T1": "",
      "Eox": "3.79",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.96",
      "DipoleMomentS1": "2.96",
      "DipoleMomentT1": "0.53",
      "ionizationPotential": "10.57",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "YSQSAYOCEAZPMB-QPLCGJKRSA-N",
      "Smiles": "N#Cc1cnc(nc1)c1ccc(o1)/N=N\\c1ccc(o1)c1ncc(cn1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(nc1)c1ccc(o1)/N=N\\c1ccc(o1)c1ncc(cn1)C#N",
      "HOMO": "-6.56",
      "LUMO": "-3.51",
      "VEE": "2.49",
      "Lmax": "497.911646586345",
      "E00S1": "",
      "E00T1": "0.25",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.31",
      "DipoleMomentS1": "6.31",
      "DipoleMomentT1": "8.37",
      "ionizationPotential": "0",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "YSWTWWVHTOBCMH-FMQUCBEESA-N",
      "Smiles": "ClC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)Cl",
      "MolView_url": "http://molview.org/?q=ClC1=CC=C(S1(=O)=O)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(S1(=O)=O)Cl",
      "HOMO": "-6.23",
      "LUMO": "-3.71",
      "VEE": "2.83",
      "Lmax": "438.091872791519",
      "E00S1": "",
      "E00T1": "0.49",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.79",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "7.08",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YTJOEMMGJZVPEF-OCEACIFDSA-N",
      "Smiles": "FC(c1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)C(F)(F)F)(F)F",
      "HOMO": "-6.01",
      "LUMO": "-3.23",
      "VEE": "3.11",
      "Lmax": "398.649517684887",
      "E00S1": "",
      "E00T1": "0.74",
      "Eox": "1.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.37",
      "ET1red": "",
      "DipoleMomentS0": "4.68",
      "DipoleMomentS1": "4.68",
      "DipoleMomentT1": "4.94",
      "ionizationPotential": "6.87",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YTJOEMMGJZVPEF-QPLCGJKRSA-N",
      "Smiles": "FC(c1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)C(F)(F)F)(F)F",
      "HOMO": "-6.17",
      "LUMO": "-3.07",
      "VEE": "2.59",
      "Lmax": "478.687258687259",
      "E00S1": "",
      "E00T1": "0.26",
      "Eox": "1.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.81",
      "ET1red": "",
      "DipoleMomentS0": "1.65",
      "DipoleMomentS1": "1.65",
      "DipoleMomentT1": "0.09",
      "ionizationPotential": "6.88",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "YTLNUZSNEHTRQT-UHFFFAOYSA-N",
      "Smiles": "O=Cn1cccc1[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=O=Cn1cccc1[C@@H]1N=N1",
      "HOMO": "-6.61",
      "LUMO": "-2.1",
      "VEE": "3.28",
      "Lmax": "377.987804878049",
      "E00S1": "2.88",
      "E00T1": "2.25",
      "Eox": "1.65",
      "Ered": "",
      "ES1ox": "-1.23",
      "ES1red": "",
      "ET1ox": "-0.6",
      "ET1red": "",
      "DipoleMomentS0": "4.55",
      "DipoleMomentS1": "4.55",
      "DipoleMomentT1": "10.98",
      "ionizationPotential": "8.12",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "YUKGDBAWLBIMPM-DZSKJMEMSA-N",
      "Smiles": "FC(/N=C\\c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)/C=N/C(F)(F)F)/C=N/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/N=C\\c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)/C=N/C(F)(F)F)/C=N/C(F)(F)F)(F)F",
      "HOMO": "-5.94",
      "LUMO": "-2.96",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "",
      "E00T1": "1.56",
      "Eox": "1.07",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.49",
      "ET1red": "",
      "DipoleMomentS0": "5.6",
      "DipoleMomentS1": "5.6",
      "DipoleMomentT1": "9.43",
      "ionizationPotential": "7.1",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "YUXUXWXAFGFVKS-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)O)c1scc(n1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1scc(n1)O)c1scc(n1)O",
      "HOMO": "-6.5",
      "LUMO": "-3.29",
      "VEE": "2.39",
      "Lmax": "518.744769874477",
      "E00S1": "2.51",
      "E00T1": "",
      "Eox": "1.51",
      "Ered": "",
      "ES1ox": "-1",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.23",
      "DipoleMomentS1": "2.23",
      "DipoleMomentT1": "3.82",
      "ionizationPotential": "7.48",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YVLNFCALCWJGJS-DQRAZIAOSA-N",
      "Smiles": "N#Cc1cnc(o1)c1ccc(o1)/N=N\\c1ccc(o1)c1ncc(o1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1cnc(o1)c1ccc(o1)/N=N\\c1ccc(o1)c1ncc(o1)C#N",
      "HOMO": "-6.34",
      "LUMO": "-3.51",
      "VEE": "2.41",
      "Lmax": "514.439834024896",
      "E00S1": "",
      "E00T1": "0.22",
      "Eox": "1.21",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.99",
      "ET1red": "",
      "DipoleMomentS0": "2.05",
      "DipoleMomentS1": "2.05",
      "DipoleMomentT1": "1.44",
      "ionizationPotential": "0.03",
      "source_directory": "/scratch/adrion.d/workflow/furan_cis"
    },
    {
      "InchiKey": "YVOZBSSIPJBLJM-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)[N+](=O)[O-])c1ccc(o1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(o1)[N+](=O)[O-])c1ccc(o1)[N+](=O)[O-]",
      "HOMO": "-7.13",
      "LUMO": "-3.57",
      "VEE": "2.15",
      "Lmax": "576.651162790698",
      "E00S1": "2.79",
      "E00T1": "2.22",
      "Eox": "2.44",
      "Ered": "",
      "ES1ox": "-0.35",
      "ES1red": "",
      "ET1ox": "0.22",
      "ET1red": "",
      "DipoleMomentS0": "6.32",
      "DipoleMomentS1": "6.32",
      "DipoleMomentT1": "9.15",
      "ionizationPotential": "8.33",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YVSVUXBGVMGZOK-QURGRASLSA-N",
      "Smiles": "Cc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)C",
      "MolView_url": "http://molview.org/?q=Cc1cc([nH]c1)c1ccc(o1)/N=N/c1ccc(o1)c1[nH]cc(c1)C",
      "HOMO": "-5.21",
      "LUMO": "-2.62",
      "VEE": "2.6",
      "Lmax": "476.846153846154",
      "E00S1": "1.93",
      "E00T1": "0.74",
      "Eox": "0.31",
      "Ered": "",
      "ES1ox": "-1.62",
      "ES1red": "",
      "ET1ox": "-0.43",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.93",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "YWABZRVUFYXPEZ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)C#N)cc(c5ccc4)c1[nH]cc(c1)C#N",
      "MolView_url": "http://molview.org/?q=N#Cc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)C#N)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)C#N)cc(c5ccc4)c1[nH]cc(c1)C#N",
      "HOMO": "-5.51",
      "LUMO": "-2.66",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "2",
      "E00T1": "",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "-1.32",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "17.09",
      "DipoleMomentS1": "17.09",
      "DipoleMomentT1": "15.82",
      "ionizationPotential": "0.09",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "YWLRUHNUFSJURY-BUHFOSPRSA-N",
      "Smiles": "[O-][N+](=O)c1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1nnc(nn1)c1ccc(s1)/N=N/c1ccc(s1)c1nnc(nn1)[N+](=O)[O-]",
      "HOMO": "-6.97",
      "LUMO": "-4.24",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "",
      "E00T1": "0.85",
      "Eox": "2.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.19",
      "ET1red": "",
      "DipoleMomentS0": "0.12",
      "DipoleMomentS1": "0.12",
      "DipoleMomentT1": "0.76",
      "ionizationPotential": "8.18",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "YWLRUHNUFSJURY-YPKPFQOOSA-N",
      "Smiles": "[O-][N+](=O)c1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)c1nnc(nn1)c1ccc(s1)/N=N\\c1ccc(s1)c1nnc(nn1)[N+](=O)[O-]",
      "HOMO": "-6.84",
      "LUMO": "-4.1",
      "VEE": "2.21",
      "Lmax": "560.995475113122",
      "E00S1": "",
      "E00T1": "0.2",
      "Eox": "1.75",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.55",
      "ET1red": "",
      "DipoleMomentS0": "2.89",
      "DipoleMomentS1": "2.89",
      "DipoleMomentT1": "1.34",
      "ionizationPotential": "8.01",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "YWQIMCROBNPOEV-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cn1)OC(F)(F)F)c1ccc(cn1)OC(F)(F)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ccc(cn1)OC(F)(F)F)c1ccc(cn1)OC(F)(F)F",
      "HOMO": "-7.27",
      "LUMO": "-3.23",
      "VEE": "2.71",
      "Lmax": "457.490774907749",
      "E00S1": "",
      "E00T1": "0.27",
      "Eox": "2.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.97",
      "ET1red": "",
      "DipoleMomentS0": "3.21",
      "DipoleMomentS1": "3.21",
      "DipoleMomentT1": "3.04",
      "ionizationPotential": "8.23",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "YXXPKOIHKNEONS-UHFFFAOYSA-N",
      "Smiles": "N#Cc1csc(n1)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1csc(n1)[C@]1(C#N)N=N1",
      "HOMO": "-7.81",
      "LUMO": "-2.99",
      "VEE": "3.48",
      "Lmax": "356.264367816092",
      "E00S1": "3.3",
      "E00T1": "2.72",
      "Eox": "2.86",
      "Ered": "",
      "ES1ox": "-0.44",
      "ES1red": "",
      "ET1ox": "0.14",
      "ET1red": "",
      "DipoleMomentS0": "4.21",
      "DipoleMomentS1": "4.21",
      "DipoleMomentT1": "8.8",
      "ionizationPotential": "9.54",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "YYOKSVUPZSHXEG-UHFFFAOYSA-N",
      "Smiles": "FC(OC#C[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(OC#C[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-8.2",
      "LUMO": "-2.82",
      "VEE": "3.43",
      "Lmax": "361.457725947522",
      "E00S1": "3.4",
      "E00T1": "2.6",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.8",
      "DipoleMomentS1": "1.8",
      "DipoleMomentT1": "5.17",
      "ionizationPotential": "9.46",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "YZLFRTJVBQHTHY-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(o1)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=Oc1ccc(o1)[C@]1(O)N=N1",
      "HOMO": "-6.2",
      "LUMO": "-2.26",
      "VEE": "2.99",
      "Lmax": "414.648829431438",
      "E00S1": "2.46",
      "E00T1": "1.78",
      "Eox": "1.28",
      "Ered": "",
      "ES1ox": "-1.18",
      "ES1red": "",
      "ET1ox": "-0.5",
      "ET1red": "",
      "DipoleMomentS0": "3",
      "DipoleMomentS1": "3",
      "DipoleMomentT1": "6.8",
      "ionizationPotential": "7.69",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZAFUHUJOUOBOMX-VHXPQNKSSA-N",
      "Smiles": "Fc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(cc1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(cc1)F",
      "HOMO": "-5.9",
      "LUMO": "-2.92",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "",
      "E00T1": "0.1",
      "Eox": "0.72",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.62",
      "ET1red": "",
      "DipoleMomentS0": "5.35",
      "DipoleMomentS1": "5.35",
      "DipoleMomentT1": "3.94",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "ZAFUHUJOUOBOMX-WCWDXBQESA-N",
      "Smiles": "Fc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)F",
      "MolView_url": "http://molview.org/?q=Fc1ccc(cc1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(cc1)F",
      "HOMO": "-5.91",
      "LUMO": "-2.95",
      "VEE": "3.05",
      "Lmax": "406.491803278689",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.92",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.6",
      "DipoleMomentS1": "2.6",
      "DipoleMomentT1": "2.81",
      "ionizationPotential": "6.66",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ZAKRFRDSUVZKFH-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1ncc(cn1)OC)C#N)c1ncc(cn1)OC",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1ncc(cn1)OC)C#N)c1ncc(cn1)OC",
      "HOMO": "-6.84",
      "LUMO": "-2.84",
      "VEE": "3.76",
      "Lmax": "329.734042553192",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.11",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.93",
      "DipoleMomentS1": "6.93",
      "DipoleMomentT1": "9.16",
      "ionizationPotential": "8.04",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ZBCUWYGPFYFBOD-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1occ(n1)C(F)(F)F)C#N)c1occ(n1)C(F)(F)F",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1occ(n1)C(F)(F)F)C#N)c1occ(n1)C(F)(F)F",
      "HOMO": "-7.36",
      "LUMO": "-3.28",
      "VEE": "3.67",
      "Lmax": "337.820163487738",
      "E00S1": "",
      "E00T1": "2.78",
      "Eox": "-0.59",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.37",
      "ET1red": "",
      "DipoleMomentS0": "3.43",
      "DipoleMomentS1": "3.43",
      "DipoleMomentT1": "3",
      "ionizationPotential": "9.04",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ZBMHUZFCMSVYED-UHFFFAOYSA-N",
      "Smiles": "Clc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(nc1)c1ccc2c3c1ccc1c3c(cc2)c2c3c1ccc1c3c(cc2)ccc1c1ncc(cn1)Cl",
      "HOMO": "-5.53",
      "LUMO": "-2.65",
      "VEE": "1.5",
      "Lmax": "826.533333333333",
      "E00S1": "2.18",
      "E00T1": "1.21",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.18",
      "DipoleMomentS1": "0.18",
      "DipoleMomentT1": "0.53",
      "ionizationPotential": "",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes_2sub"
    },
    {
      "InchiKey": "ZBQQVSKMKPBDNM-HSFFGMMNSA-N",
      "Smiles": "S/C=C\\c1cc(C#N)cc(c1C#N)/C=C/S",
      "MolView_url": "http://molview.org/?q=S/C=C\\c1cc(C#N)cc(c1C#N)/C=C/S",
      "HOMO": "-6.24",
      "LUMO": "-2.74",
      "VEE": "3.19",
      "Lmax": "388.652037617555",
      "E00S1": "",
      "E00T1": "1.95",
      "Eox": "-1.16",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.11",
      "ET1red": "",
      "DipoleMomentS0": "1.82",
      "DipoleMomentS1": "1.82",
      "DipoleMomentT1": "4.21",
      "ionizationPotential": "7.78",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ZCCJIBBNUAQCGM-UHFFFAOYSA-N",
      "Smiles": "N#Cc1ccc(n1C)[C@]1(C#N)N=N1",
      "MolView_url": "http://molview.org/?q=N#Cc1ccc(n1C)[C@]1(C#N)N=N1",
      "HOMO": "-7.15",
      "LUMO": "-2.88",
      "VEE": "3.63",
      "Lmax": "341.542699724518",
      "E00S1": "2.91",
      "E00T1": "2.17",
      "Eox": "2.1",
      "Ered": "",
      "ES1ox": "-0.81",
      "ES1red": "",
      "ET1ox": "-0.07",
      "ET1red": "",
      "DipoleMomentS0": "3.91",
      "DipoleMomentS1": "3.91",
      "DipoleMomentT1": "7.01",
      "ionizationPotential": "8.62",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZCDLQMSGPCSTAI-VHEBKMAFSA-N",
      "Smiles": "FC(/C=C/c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/C=C/c1ccc(cc1)/N=N/c1ccc(cc1)/C=C/C(F)(F)F)(F)F",
      "HOMO": "-6.72",
      "LUMO": "-3.02",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "1.05",
      "Eox": "1.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.66",
      "ET1red": "",
      "DipoleMomentS0": "0.8",
      "DipoleMomentS1": "0.8",
      "DipoleMomentT1": "1.69",
      "ionizationPotential": "7.77",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "ZCGJCECGLFKXDQ-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1nnc(nn1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1nnc(nn1)[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-7.93",
      "LUMO": "-3.84",
      "VEE": "2.47",
      "Lmax": "501.943319838057",
      "E00S1": "2.13",
      "E00T1": "1.47",
      "Eox": "2.96",
      "Ered": "",
      "ES1ox": "0.83",
      "ES1red": "",
      "ET1ox": "1.49",
      "ET1red": "",
      "DipoleMomentS0": "0.63",
      "DipoleMomentS1": "0.63",
      "DipoleMomentT1": "0.56",
      "ionizationPotential": "9.41",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZCTUGSFGFLQGFF-UHFFFAOYSA-N",
      "Smiles": "FC(Oc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)OC(F)(F)F)cc(c5ccc4)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ncc([nH]1)c1cc2c3cc(c4cnc([nH]4)OC(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1cnc([nH]1)OC(F)(F)F)cc(c5ccc4)c1cnc([nH]1)OC(F)(F)F)(F)F",
      "HOMO": "-5.53",
      "LUMO": "-2.67",
      "VEE": "2.9",
      "Lmax": "427.51724137931",
      "E00S1": "2.01",
      "E00T1": "",
      "Eox": "-4.24",
      "Ered": "",
      "ES1ox": "-6.25",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "4.49",
      "DipoleMomentS1": "4.49",
      "DipoleMomentT1": "4.57",
      "ionizationPotential": "0.13",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZECFVCXSUALHIJ-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1occ(n1)Cl)C#N)c1occ(n1)Cl",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1occ(n1)Cl)C#N)c1occ(n1)Cl",
      "HOMO": "-6.96",
      "LUMO": "-3.19",
      "VEE": "3.23",
      "Lmax": "383.839009287926",
      "E00S1": "",
      "E00T1": "2.55",
      "Eox": "-0.78",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.33",
      "ET1red": "",
      "DipoleMomentS0": "4.4",
      "DipoleMomentS1": "4.4",
      "DipoleMomentT1": "5.7",
      "ionizationPotential": "8.49",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ZEMKVMZBJNKPFO-BYYHNAKLSA-N",
      "Smiles": "FC(Oc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1ccc([nH]1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc([nH]1)OC(F)(F)F)(F)F",
      "HOMO": "-5.57",
      "LUMO": "-2.91",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "0.76",
      "Eox": "0.61",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.15",
      "ET1red": "",
      "DipoleMomentS0": "3.19",
      "DipoleMomentS1": "3.19",
      "DipoleMomentT1": "2.96",
      "ionizationPotential": "6.28",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ZEPMUHKMRPHFOV-UXPOPVFASA-N",
      "Smiles": "CO/C=C/c1ccc(s1)/N=N/c1ccc(s1)/C=C/OC",
      "MolView_url": "http://molview.org/?q=CO/C=C/c1ccc(s1)/N=N/c1ccc(s1)/C=C/OC",
      "HOMO": "-5.44",
      "LUMO": "-2.74",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.55",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.32",
      "DipoleMomentS1": "3.32",
      "DipoleMomentT1": "3.58",
      "ionizationPotential": "6.04",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ZEQJMPVEKYRVSD-UHFFFAOYSA-N",
      "Smiles": "Fc1ccc([nH]1)[C@]1(F)N=N1",
      "MolView_url": "http://molview.org/?q=Fc1ccc([nH]1)[C@]1(F)N=N1",
      "HOMO": "-6.49",
      "LUMO": "-2.56",
      "VEE": "3.38",
      "Lmax": "366.804733727811",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.59",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.64",
      "DipoleMomentS1": "1.64",
      "DipoleMomentT1": "9.9",
      "ionizationPotential": "8.18",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZFAHPQZHYPEXBS-RDIYVQNMSA-N",
      "Smiles": "S/N=C/c1ccc([nH]1)/N=N\\c1ccc([nH]1)/C=N/S",
      "MolView_url": "http://molview.org/?q=S/N=C/c1ccc([nH]1)/N=N\\c1ccc([nH]1)/C=N/S",
      "HOMO": "-5.71",
      "LUMO": "-2.82",
      "VEE": "2.89",
      "Lmax": "428.996539792388",
      "E00S1": "2.14",
      "E00T1": "",
      "Eox": "0.81",
      "Ered": "",
      "ES1ox": "-1.33",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.16",
      "DipoleMomentS1": "2.16",
      "DipoleMomentT1": "",
      "ionizationPotential": "6.59",
      "source_directory": "/scratch/myrthil.n/pyrrole_trans"
    },
    {
      "InchiKey": "ZGEVBMPCRGTMLK-FMQUCBEESA-N",
      "Smiles": "C1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=CC1",
      "MolView_url": "http://molview.org/?q=C1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=CC1",
      "HOMO": "-5.34",
      "LUMO": "-2.8",
      "VEE": "2.61",
      "Lmax": "475.019157088123",
      "E00S1": "1.88",
      "E00T1": "0.58",
      "Eox": "0.42",
      "Ered": "",
      "ES1ox": "-1.46",
      "ES1red": "",
      "ET1ox": "-0.16",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0",
      "ionizationPotential": "6.06",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ZGTNYEVHEAYRPI-OCEACIFDSA-N",
      "Smiles": "Fc1ncc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cnc([nH]1)F",
      "MolView_url": "http://molview.org/?q=Fc1ncc([nH]1)c1ccc(cc1)/N=N/c1ccc(cc1)c1cnc([nH]1)F",
      "HOMO": "-6.13",
      "LUMO": "-2.74",
      "VEE": "2.76",
      "Lmax": "449.202898550725",
      "E00S1": "",
      "E00T1": "1.08",
      "Eox": "1.26",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.18",
      "ET1red": "",
      "DipoleMomentS0": "5.02",
      "DipoleMomentS1": "5.02",
      "DipoleMomentT1": "4.28",
      "ionizationPotential": "7.06",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "ZHCFGIDVOIUSCF-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)O)c1ncc(o1)O",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)c1ncc(o1)O)c1ncc(o1)O",
      "HOMO": "-6.43",
      "LUMO": "-3.23",
      "VEE": "2.03",
      "Lmax": "610.738916256158",
      "E00S1": "2.51",
      "E00T1": "2.02",
      "Eox": "1.66",
      "Ered": "",
      "ES1ox": "-0.85",
      "ES1red": "",
      "ET1ox": "-0.36",
      "ET1red": "",
      "DipoleMomentS0": "3.49",
      "DipoleMomentS1": "3.49",
      "DipoleMomentT1": "6.86",
      "ionizationPotential": "7.44",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ZHGGPENXBPYPNG-UHFFFAOYSA-N",
      "Smiles": "FC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)F)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)F)cc(c5ccc4)C1=CC=C(C1)F",
      "MolView_url": "http://molview.org/?q=FC1=CC=C(C1)c1cc2c3cc(C4=CC=C(C4)F)c4c5c3c(c3c2c2c1cccc2c(c3)C1=CC=C(C1)F)cc(c5ccc4)C1=CC=C(C1)F",
      "HOMO": "-5.12",
      "LUMO": "-2.46",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "1.84",
      "E00T1": "0.23",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "-1.3",
      "ES1red": "",
      "ET1ox": "0.31",
      "ET1red": "",
      "DipoleMomentS0": "1",
      "DipoleMomentS1": "1",
      "DipoleMomentT1": "1.05",
      "ionizationPotential": "5.88",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZILSNCWKDAKLAC-QVIHXGFCSA-N",
      "Smiles": "FC(Oc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(Oc1cnc(nc1)c1ccc(o1)/N=N/c1ccc(o1)c1ncc(cn1)OC(F)(F)F)(F)F",
      "HOMO": "-6.34",
      "LUMO": "-3.29",
      "VEE": "2.93",
      "Lmax": "423.139931740614",
      "E00S1": "",
      "E00T1": "0.79",
      "Eox": "1.42",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.63",
      "ET1red": "",
      "DipoleMomentS0": "1.1",
      "DipoleMomentS1": "1.1",
      "DipoleMomentT1": "1.37",
      "ionizationPotential": "7.15",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ZIMFFDPFFRNYRT-UHFFFAOYSA-N",
      "Smiles": "Sc1csc(n1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1csc(n1)[C@]1(S)N=N1",
      "HOMO": "-6.6",
      "LUMO": "-2.56",
      "VEE": "3.61",
      "Lmax": "343.434903047091",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.73",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.49",
      "DipoleMomentS1": "2.49",
      "DipoleMomentT1": "8.26",
      "ionizationPotential": "8.08",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZIOXCXAZNIYFKT-UHFFFAOYSA-N",
      "Smiles": "FC(/N=C/c1cc2c3cc(/C=N/C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N\\C(F)(F)F)cc(c5ccc4)/C=N/C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(/N=C/c1cc2c3cc(/C=N/C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)/C=N\\C(F)(F)F)cc(c5ccc4)/C=N/C(F)(F)F)(F)F",
      "HOMO": "-6.08",
      "LUMO": "-3.54",
      "VEE": "2.92",
      "Lmax": "424.58904109589",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-4.08",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.03",
      "DipoleMomentS1": "2.03",
      "DipoleMomentT1": "2.17",
      "ionizationPotential": "0.02",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZIZZUWNWIMBSAR-ZCXUNETKSA-N",
      "Smiles": "FC#Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C#CF",
      "MolView_url": "http://molview.org/?q=FC#Cc1ccc([nH]1)/N=N\\c1ccc([nH]1)C#CF",
      "HOMO": "-5.78",
      "LUMO": "-2.56",
      "VEE": "2.65",
      "Lmax": "467.849056603774",
      "E00S1": "",
      "E00T1": "0.35",
      "Eox": "0.71",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.36",
      "ET1red": "",
      "DipoleMomentS0": "3.79",
      "DipoleMomentS1": "3.79",
      "DipoleMomentT1": "3.41",
      "ionizationPotential": "6.72",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "ZJPUGYWVFBARGF-UHFFFAOYSA-N",
      "Smiles": "Oc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)O)cc(c5ccc4)c1[nH]cc(c1)O",
      "MolView_url": "http://molview.org/?q=Oc1c[nH]c(c1)c1cc2c3cc(c4[nH]cc(c4)O)c4c5c3c(c3c2c2c1cccc2c(c3)c1[nH]cc(c1)O)cc(c5ccc4)c1[nH]cc(c1)O",
      "HOMO": "-5.13",
      "LUMO": "-2.45",
      "VEE": "2.87",
      "Lmax": "431.98606271777",
      "E00S1": "",
      "E00T1": "0.24",
      "Eox": "-4.23",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-4.47",
      "ET1red": "",
      "DipoleMomentS0": "4.27",
      "DipoleMomentS1": "4.27",
      "DipoleMomentT1": "",
      "ionizationPotential": "5.81",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZKHXYSAMRICNOJ-UHFFFAOYSA-N",
      "Smiles": "C=Cc1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C=C",
      "MolView_url": "http://molview.org/?q=C=Cc1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)C=C",
      "HOMO": "-6.95",
      "LUMO": "-3.13",
      "VEE": "2.58",
      "Lmax": "480.542635658915",
      "E00S1": "2.94",
      "E00T1": "2.29",
      "Eox": "2.12",
      "Ered": "",
      "ES1ox": "-0.82",
      "ES1red": "",
      "ET1ox": "-0.17",
      "ET1red": "",
      "DipoleMomentS0": "0.61",
      "DipoleMomentS1": "0.61",
      "DipoleMomentT1": "2.89",
      "ionizationPotential": "8.08",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ZKVBFLSERUVRBG-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc(o1)c1cc2c3cc(c4ccc(o4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)Cl)cc(c5ccc4)c1ccc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc(o1)c1cc2c3cc(c4ccc(o4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)Cl)cc(c5ccc4)c1ccc(o1)Cl",
      "HOMO": "-5.36",
      "LUMO": "-2.7",
      "VEE": "2.8",
      "Lmax": "442.785714285714",
      "E00S1": "1.85",
      "E00T1": "1.19",
      "Eox": "0.54",
      "Ered": "",
      "ES1ox": "-1.31",
      "ES1red": "",
      "ET1ox": "-0.65",
      "ET1red": "",
      "DipoleMomentS0": "3.13",
      "DipoleMomentS1": "3.13",
      "DipoleMomentT1": "3.29",
      "ionizationPotential": "0.05",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZLEPIQKBSKWNLE-FMQUCBEESA-N",
      "Smiles": "Cc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(s1)c1ccc(s1)/N=N/c1ccc(s1)c1ccc(s1)C",
      "HOMO": "-5.56",
      "LUMO": "-2.96",
      "VEE": "3",
      "Lmax": "413.266666666667",
      "E00S1": "",
      "E00T1": "0.69",
      "Eox": "0.58",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.11",
      "ET1red": "",
      "DipoleMomentS0": "0.49",
      "DipoleMomentS1": "0.49",
      "DipoleMomentT1": "0.54",
      "ionizationPotential": "6.16",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ZLEPIQKBSKWNLE-VXPUYCOJSA-N",
      "Smiles": "Cc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)C",
      "MolView_url": "http://molview.org/?q=Cc1ccc(s1)c1ccc(s1)/N=N\\c1ccc(s1)c1ccc(s1)C",
      "HOMO": "-5.6",
      "LUMO": "-2.93",
      "VEE": "2.4",
      "Lmax": "516.583333333333",
      "E00S1": "",
      "E00T1": "-0.04",
      "Eox": "0.41",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.45",
      "ET1red": "",
      "DipoleMomentS0": "7.83",
      "DipoleMomentS1": "7.83",
      "DipoleMomentT1": "1.22",
      "ionizationPotential": "6.14",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "ZLSVJFDXOBLGGN-WCWDXBQESA-N",
      "Smiles": "Clc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cnc(o1)c1ccc(cc1)/N=N/c1ccc(cc1)c1ncc(o1)Cl",
      "HOMO": "-6.46",
      "LUMO": "-2.99",
      "VEE": "2.69",
      "Lmax": "460.89219330855",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.69",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.58",
      "DipoleMomentS1": "1.58",
      "DipoleMomentT1": "1.82",
      "ionizationPotential": "7.29",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "ZLYIYYMBQVVTRL-ZCXUNETKSA-N",
      "Smiles": "O=S1(=O)C(=CC=C1[N+](=O)[O-])c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=S1(=O)C(=CC=C1[N+](=O)[O-])c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(S1(=O)=O)[N+](=O)[O-]",
      "HOMO": "-6.81",
      "LUMO": "-4.32",
      "VEE": "2.6",
      "Lmax": "476.846153846154",
      "E00S1": "",
      "E00T1": "-0.24",
      "Eox": "1.02",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.26",
      "ET1red": "",
      "DipoleMomentS0": "10.49",
      "DipoleMomentS1": "10.49",
      "DipoleMomentT1": "19.73",
      "ionizationPotential": "7.77",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "ZMCXIEGOOGARCP-ISLYRVAYSA-N",
      "Smiles": "[O-][N+](=O)C#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#C[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C#Cc1ccc(cc1)/N=N/c1ccc(cc1)C#C[N+](=O)[O-]",
      "HOMO": "-7.27",
      "LUMO": "-3.82",
      "VEE": "2.7",
      "Lmax": "459.185185185185",
      "E00S1": "",
      "E00T1": "1.01",
      "Eox": "2.18",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "1.17",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "6.78",
      "ionizationPotential": "8.29",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "ZMIIJKOYOYHKOC-UHFFFAOYSA-N",
      "Smiles": "[O-][N+](=O)C1=CC=C(C1)[C@@]1(N=N1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=[O-][N+](=O)C1=CC=C(C1)[C@@]1(N=N1)[N+](=O)[O-]",
      "HOMO": "-7.5",
      "LUMO": "-3.36",
      "VEE": "3.7",
      "Lmax": "335.081081081081",
      "E00S1": "2.95",
      "E00T1": "1.75",
      "Eox": "2.58",
      "Ered": "",
      "ES1ox": "-0.37",
      "ES1red": "",
      "ET1ox": "0.83",
      "ET1red": "",
      "DipoleMomentS0": "5.87",
      "DipoleMomentS1": "5.87",
      "DipoleMomentT1": "7.48",
      "ionizationPotential": "9.23",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZMOTYASAHAZYIC-UHFFFAOYSA-N",
      "Smiles": "OC1=CC=C(S1(=O)=O)[C@]1(O)N=N1",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(S1(=O)=O)[C@]1(O)N=N1",
      "HOMO": "-6.96",
      "LUMO": "-2.53",
      "VEE": "3.37",
      "Lmax": "367.893175074184",
      "E00S1": "2.84",
      "E00T1": "1.59",
      "Eox": "1.96",
      "Ered": "",
      "ES1ox": "-0.88",
      "ES1red": "",
      "ET1ox": "0.37",
      "ET1red": "",
      "DipoleMomentS0": "8.53",
      "DipoleMomentS1": "8.53",
      "DipoleMomentT1": "9.22",
      "ionizationPotential": "8.43",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZNRISTFCMGZZOK-OCEACIFDSA-N",
      "Smiles": "Clc1cc([nH]c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1[nH]cc(c1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1cc([nH]c1)c1ccc(cc1)/N=N/c1ccc(cc1)c1[nH]cc(c1)Cl",
      "HOMO": "-5.88",
      "LUMO": "-2.67",
      "VEE": "2.78",
      "Lmax": "445.971223021583",
      "E00S1": "",
      "E00T1": "1.08",
      "Eox": "1.04",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-0.04",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.32",
      "ionizationPotential": "6.71",
      "source_directory": "/scratch/adrion.d/workflow/benzene_trans"
    },
    {
      "InchiKey": "ZNWOMZQCKFNCFG-UHFFFAOYSA-N",
      "Smiles": "Cc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)C)cc(c5ccc4)c1ncc(cn1)C",
      "MolView_url": "http://molview.org/?q=Cc1cnc(nc1)c1cc2c3cc(c4ncc(cn4)C)c4c5c3c(c3c2c2c1cccc2c(c3)c1ncc(cn1)C)cc(c5ccc4)c1ncc(cn1)C",
      "HOMO": "-5.08",
      "LUMO": "-1.98",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "2",
      "E00T1": "0.23",
      "Eox": "-4.38",
      "Ered": "",
      "ES1ox": "-6.38",
      "ES1red": "",
      "ET1ox": "-4.61",
      "ET1red": "",
      "DipoleMomentS0": "0.1",
      "DipoleMomentS1": "",
      "DipoleMomentT1": "",
      "ionizationPotential": "0.04",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZOTXVKYQCVVCRA-DQRAZIAOSA-N",
      "Smiles": "CC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(C1)c1ccc(s1)/N=N\\c1ccc(s1)C1=CC=C(C1)C",
      "HOMO": "-5.31",
      "LUMO": "-2.88",
      "VEE": "2.3",
      "Lmax": "539.04347826087",
      "E00S1": "",
      "E00T1": "-0.06",
      "Eox": "0.3",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.36",
      "ET1red": "",
      "DipoleMomentS0": "10.02",
      "DipoleMomentS1": "10.02",
      "DipoleMomentT1": "0",
      "ionizationPotential": "5.9",
      "source_directory": "/scratch/mileson.b/thiophene_cis"
    },
    {
      "InchiKey": "ZOTXVKYQCVVCRA-QURGRASLSA-N",
      "Smiles": "CC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)C",
      "MolView_url": "http://molview.org/?q=CC1=CC=C(C1)c1ccc(s1)/N=N/c1ccc(s1)C1=CC=C(C1)C",
      "HOMO": "-5.24",
      "LUMO": "-2.86",
      "VEE": "2.55",
      "Lmax": "486.196078431373",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.31",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0.48",
      "DipoleMomentS1": "0.48",
      "DipoleMomentT1": "2.48",
      "ionizationPotential": "5.88",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    },
    {
      "InchiKey": "ZPIRVBKWVRTJLF-UHFFFAOYSA-N",
      "Smiles": "Cl/C=C/c1cc2c3cc(/C=C/Cl)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/Cl)cc(c5ccc4)/C=C/Cl",
      "MolView_url": "http://molview.org/?q=Cl/C=C/c1cc2c3cc(/C=C/Cl)c4c5c3c(c3c2c2c1cccc2c(c3)/C=C/Cl)cc(c5ccc4)/C=C/Cl",
      "HOMO": "-5.51",
      "LUMO": "-2.68",
      "VEE": "2.97",
      "Lmax": "417.441077441077",
      "E00S1": "1.97",
      "E00T1": "",
      "Eox": "0.68",
      "Ered": "",
      "ES1ox": "-1.29",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.07",
      "ionizationPotential": "6.44",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZPRHAPILUBLPFE-GLIMQPGKSA-N",
      "Smiles": "C/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\C",
      "MolView_url": "http://molview.org/?q=C/C=C\\c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)/C=C\\C",
      "HOMO": "-6.78",
      "LUMO": "-3.06",
      "VEE": "2.6",
      "Lmax": "476.846153846154",
      "E00S1": "2.86",
      "E00T1": "2.23",
      "Eox": "1.88",
      "Ered": "",
      "ES1ox": "-0.98",
      "ES1red": "",
      "ET1ox": "-0.35",
      "ET1red": "",
      "DipoleMomentS0": "2.3",
      "DipoleMomentS1": "2.3",
      "DipoleMomentT1": "5.88",
      "ionizationPotential": "7.79",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ZPVTVRMMTGPKME-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1C1=CC=C(C1)S)C#N)C1=CC=C(C1)S",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1C1=CC=C(C1)S)C#N)C1=CC=C(C1)S",
      "HOMO": "-5.68",
      "LUMO": "-2.7",
      "VEE": "2.68",
      "Lmax": "462.611940298507",
      "E00S1": "",
      "E00T1": "1.84",
      "Eox": "-1.24",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.08",
      "ET1red": "",
      "DipoleMomentS0": "4.51",
      "DipoleMomentS1": "4.51",
      "DipoleMomentT1": "7.06",
      "ionizationPotential": "6.99",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ZPZSLHMPYOJZGW-GLIMQPGKSA-N",
      "Smiles": "CO/C=C\\c1cc(C#N)cc(c1C#N)/C=C\\OC",
      "MolView_url": "http://molview.org/?q=CO/C=C\\c1cc(C#N)cc(c1C#N)/C=C\\OC",
      "HOMO": "-6.24",
      "LUMO": "-2.58",
      "VEE": "3.33",
      "Lmax": "372.312312312312",
      "E00S1": "",
      "E00T1": "",
      "Eox": "-1.32",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "5.72",
      "DipoleMomentS1": "5.72",
      "DipoleMomentT1": "8.25",
      "ionizationPotential": "7.63",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ZQPOTDXQUCATOH-UHFFFAOYSA-N",
      "Smiles": "Sc1ccc(o1)[C@]1(S)N=N1",
      "MolView_url": "http://molview.org/?q=Sc1ccc(o1)[C@]1(S)N=N1",
      "HOMO": "-6.68",
      "LUMO": "-2.41",
      "VEE": "3.18",
      "Lmax": "389.874213836478",
      "E00S1": "2.77",
      "E00T1": "1.99",
      "Eox": "1.29",
      "Ered": "",
      "ES1ox": "-1.48",
      "ES1red": "",
      "ET1ox": "-0.7",
      "ET1red": "",
      "DipoleMomentS0": "3.07",
      "DipoleMomentS1": "3.07",
      "DipoleMomentT1": "11.34",
      "ionizationPotential": "7.67",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZSVWBCJGYMRHLO-UHFFFAOYSA-N",
      "Smiles": "O=Cn1cccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cccn1C=O",
      "MolView_url": "http://molview.org/?q=O=Cn1cccc1c1ccc2c(c1)C(=O)c1c(C2=O)ccc(c1)c1cccn1C=O",
      "HOMO": "-6.59",
      "LUMO": "-3.17",
      "VEE": "2.36",
      "Lmax": "525.338983050848",
      "E00S1": "",
      "E00T1": "",
      "Eox": "1.83",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "3.53",
      "DipoleMomentS1": "3.53",
      "DipoleMomentT1": "5.24",
      "ionizationPotential": "7.55",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ZTGSGGXGYCXSRJ-UHFFFAOYSA-N",
      "Smiles": "Clc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)Cl)cc(c5ccc4)c1ccc([nH]1)Cl",
      "MolView_url": "http://molview.org/?q=Clc1ccc([nH]1)c1cc2c3cc(c4ccc([nH]4)Cl)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc([nH]1)Cl)cc(c5ccc4)c1ccc([nH]1)Cl",
      "HOMO": "-5.26",
      "LUMO": "-2.52",
      "VEE": "2.86",
      "Lmax": "433.496503496504",
      "E00S1": "",
      "E00T1": "",
      "Eox": "0.4",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "1.3",
      "DipoleMomentS1": "1.3",
      "DipoleMomentT1": "1.7",
      "ionizationPotential": "6",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZTQQCQRRTHAIBE-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)F)C1=CC=C(S1(=O)=O)F",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)F)C1=CC=C(S1(=O)=O)F",
      "HOMO": "-7.13",
      "LUMO": "-3.48",
      "VEE": "1.68",
      "Lmax": "737.97619047619",
      "E00S1": "2.84",
      "E00T1": "1.44",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "7.1",
      "DipoleMomentS1": "7.1",
      "DipoleMomentT1": "8.77",
      "ionizationPotential": "8.19",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ZUGMHXKPUIITOO-UHFFFAOYSA-N",
      "Smiles": "O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)[N+](=O)[O-])C1=CC=C(S1(=O)=O)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=O=C1c2cc(ccc2C(=O)c2c1cc(cc2)C1=CC=C(S1(=O)=O)[N+](=O)[O-])C1=CC=C(S1(=O)=O)[N+](=O)[O-]",
      "HOMO": "-7.64",
      "LUMO": "-4.2",
      "VEE": "1.82",
      "Lmax": "681.208791208791",
      "E00S1": "2.38",
      "E00T1": "2.05",
      "Eox": "2.74",
      "Ered": "",
      "ES1ox": "0.36",
      "ES1red": "",
      "ET1ox": "0.69",
      "ET1red": "",
      "DipoleMomentS0": "10.87",
      "DipoleMomentS1": "10.87",
      "DipoleMomentT1": "10.85",
      "ionizationPotential": "8.86",
      "source_directory": "/scratch/adrion.d/workflow/anthracene"
    },
    {
      "InchiKey": "ZVDLVILVHSTBRD-UHFFFAOYSA-N",
      "Smiles": "FC(c1ccc(o1)c1cc2c3cc(c4ccc(o4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)C(F)(F)F)cc(c5ccc4)c1ccc(o1)C(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(c1ccc(o1)c1cc2c3cc(c4ccc(o4)C(F)(F)F)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccc(o1)C(F)(F)F)cc(c5ccc4)c1ccc(o1)C(F)(F)F)(F)F",
      "HOMO": "-5.58",
      "LUMO": "-2.85",
      "VEE": "2.85",
      "Lmax": "435.017543859649",
      "E00S1": "1.91",
      "E00T1": "1.11",
      "Eox": "0.74",
      "Ered": "",
      "ES1ox": "-1.17",
      "ES1red": "",
      "ET1ox": "-0.37",
      "ET1red": "",
      "DipoleMomentS0": "5.06",
      "DipoleMomentS1": "5.06",
      "DipoleMomentT1": "3.74",
      "ionizationPotential": "6.49",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZWLXMFKMAKBKLY-UHFFFAOYSA-N",
      "Smiles": "c1coc(c1)[C@@H]1N=N1",
      "MolView_url": "http://molview.org/?q=c1coc(c1)[C@@H]1N=N1",
      "HOMO": "-6.59",
      "LUMO": "-2.17",
      "VEE": "3.01",
      "Lmax": "411.893687707641",
      "E00S1": "2.82",
      "E00T1": "",
      "Eox": "1.7",
      "Ered": "",
      "ES1ox": "-1.12",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "2.1",
      "DipoleMomentS1": "2.1",
      "DipoleMomentT1": "8.86",
      "ionizationPotential": "8.23",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZWTDDPHFUOZLCL-VXPUYCOJSA-N",
      "Smiles": "Oc1ccc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(o1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(o1)c1ccc([nH]1)/N=N\\c1ccc([nH]1)c1ccc(o1)O",
      "HOMO": "-5.1",
      "LUMO": "-2.37",
      "VEE": "2.62",
      "Lmax": "473.206106870229",
      "E00S1": "",
      "E00T1": "0.11",
      "Eox": "",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "6.55",
      "DipoleMomentS1": "6.55",
      "DipoleMomentT1": "1.27",
      "ionizationPotential": "4.95",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/pyrrole_cis"
    },
    {
      "InchiKey": "ZXIDXYPSBCTZOS-FMQUCBEESA-N",
      "Smiles": "SC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)S",
      "MolView_url": "http://molview.org/?q=SC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)S",
      "HOMO": "-5.05",
      "LUMO": "-2.73",
      "VEE": "2.44",
      "Lmax": "508.114754098361",
      "E00S1": "1.73",
      "E00T1": "0.51",
      "Eox": "0.25",
      "Ered": "",
      "ES1ox": "-1.48",
      "ES1red": "",
      "ET1ox": "-0.26",
      "ET1red": "",
      "DipoleMomentS0": "0",
      "DipoleMomentS1": "0",
      "DipoleMomentT1": "0.01",
      "ionizationPotential": "5.78",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ZXLOXJVSKMQQQS-UHFFFAOYSA-N",
      "Smiles": "N#Cc1c(cc(cc1c1[nH]cc(c1)[N+](=O)[O-])C#N)c1[nH]cc(c1)[N+](=O)[O-]",
      "MolView_url": "http://molview.org/?q=N#Cc1c(cc(cc1c1[nH]cc(c1)[N+](=O)[O-])C#N)c1[nH]cc(c1)[N+](=O)[O-]",
      "HOMO": "-6.7",
      "LUMO": "-3.06",
      "VEE": "3.33",
      "Lmax": "372.312312312312",
      "E00S1": "",
      "E00T1": "2.16",
      "Eox": "-0.9",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "-3.06",
      "ET1red": "",
      "DipoleMomentS0": "5.35",
      "DipoleMomentS1": "5.35",
      "DipoleMomentT1": "8.87",
      "ionizationPotential": "8.4",
      "source_directory": "/scratch/adrion.d/workflow/dicyanobenzene_2_5"
    },
    {
      "InchiKey": "ZXLRBLJZPXWTFE-UHFFFAOYSA-N",
      "Smiles": "FC(O/N=C/[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "MolView_url": "http://molview.org/?q=FC(O/N=C/[C@@]1(N=N1)OC(F)(F)F)(F)F",
      "HOMO": "-8.51",
      "LUMO": "-3.09",
      "VEE": "3.42",
      "Lmax": "362.514619883041",
      "E00S1": "3.43",
      "E00T1": "2.64",
      "Eox": "3.4",
      "Ered": "",
      "ES1ox": "-0.03",
      "ES1red": "",
      "ET1ox": "0.76",
      "ET1red": "",
      "DipoleMomentS0": "3.38",
      "DipoleMomentS1": "3.38",
      "DipoleMomentT1": "1.52",
      "ionizationPotential": "9.93",
      "source_directory": "/scratch/abreha.b/diazirines"
    },
    {
      "InchiKey": "ZYHZEVXYFDNYEL-UHFFFAOYSA-N",
      "Smiles": "c1ccc(nc1)c1cc2c3cc(c4ccccn4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccccn1)cc(c5ccc4)c1ccccn1",
      "MolView_url": "http://molview.org/?q=c1ccc(nc1)c1cc2c3cc(c4ccccn4)c4c5c3c(c3c2c2c1cccc2c(c3)c1ccccn1)cc(c5ccc4)c1ccccn1",
      "HOMO": "-5.45",
      "LUMO": "-2.56",
      "VEE": "2.94",
      "Lmax": "421.700680272109",
      "E00S1": "2.04",
      "E00T1": "1.17",
      "Eox": "0.62",
      "Ered": "",
      "ES1ox": "-1.42",
      "ES1red": "",
      "ET1ox": "-0.55",
      "ET1red": "",
      "DipoleMomentS0": "4.01",
      "DipoleMomentS1": "4.01",
      "DipoleMomentT1": "3.77",
      "ionizationPotential": "5.94",
      "source_directory": "/scratch/abreha.b/dibenzoperylenes"
    },
    {
      "InchiKey": "ZYMRMUJHEJYFIB-FMQUCBEESA-N",
      "Smiles": "OC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)O",
      "MolView_url": "http://molview.org/?q=OC1=CC=C(C1)c1ccc(o1)/N=N/c1ccc(o1)C1=CC=C(C1)O",
      "HOMO": "-4.94",
      "LUMO": "-2.59",
      "VEE": "2.46",
      "Lmax": "503.983739837398",
      "E00S1": "1.76",
      "E00T1": "0.54",
      "Eox": "0.02",
      "Ered": "",
      "ES1ox": "-1.74",
      "ES1red": "",
      "ET1ox": "-0.52",
      "ET1red": "",
      "DipoleMomentS0": "3.69",
      "DipoleMomentS1": "3.69",
      "DipoleMomentT1": "3.73",
      "ionizationPotential": "5.65",
      "source_directory": "/scratch/adrion.d/workflow/furan_trans"
    },
    {
      "InchiKey": "ZZEFNHAKBGXQIM-UHFFFAOYSA-N",
      "Smiles": "Oc1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)O)c1ccc(s1)O",
      "MolView_url": "http://molview.org/?q=Oc1ccc(s1)c1ccc2-c3[n+]4c(-c2c1)cc1c2ccc(cc2c2n1[B@@-]4(Cl)n1c(c3)c3cc(ccc3c1c2)c1ccc(s1)O)c1ccc(s1)O",
      "HOMO": "-5.41",
      "LUMO": "-2.37",
      "VEE": "2.45",
      "Lmax": "506.040816326531",
      "E00S1": "2.19",
      "E00T1": "",
      "Eox": "0.65",
      "Ered": "",
      "ES1ox": "-1.54",
      "ES1red": "",
      "ET1ox": "",
      "ET1red": "",
      "DipoleMomentS0": "9.22",
      "DipoleMomentS1": "9.22",
      "DipoleMomentT1": "",
      "ionizationPotential": "5.97",
      "source_directory": "/scratch/abreha.b/descriptor_project/subpc"
    },
    {
      "InchiKey": "ZZILKUUVQBHYCL-ISLYRVAYSA-N",
      "Smiles": "Sc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)S",
      "MolView_url": "http://molview.org/?q=Sc1cnc(o1)c1ccc(s1)/N=N/c1ccc(s1)c1ncc(o1)S",
      "HOMO": "-6.16",
      "LUMO": "-3.26",
      "VEE": "2.77",
      "Lmax": "447.581227436823",
      "E00S1": "",
      "E00T1": "0.78",
      "Eox": "1.05",
      "Ered": "",
      "ES1ox": "",
      "ES1red": "",
      "ET1ox": "0.27",
      "ET1red": "",
      "DipoleMomentS0": "2.27",
      "DipoleMomentS1": "2.27",
      "DipoleMomentT1": "4.71",
      "ionizationPotential": "6.74",
      "source_directory": "/scratch/abreha.b/photoswitch_flows/thiophene_trans"
    }
  ]