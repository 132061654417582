import React from "react";
import "./Subscribe.css";
import { ImDownload } from "react-icons/im";
import XLS from '../../assets/VERDE.xlsx';

const Subscribe = () => {
  return (
    <section id="subscribe">
      <div className="container subscribe" data-aos="fade-up">
        <h2>DOWNLOAD WHOLE DATABASE</h2>
        <a href={XLS} className="download-button">
          <ImDownload className="download-button"></ImDownload>
        </a>
        
        {/* <form>
          <div className="form-control">
            <input type="text" placeholder="Enter Your Email..." />
            <button>Subscribe</button>
          </div>
        </form>
        <div className="social-icons">
          <div className="social-icon">
            <TiSocialGooglePlus />
          </div>
          <div className="social-icon">
            <FaFacebookF />
          </div>
          <div className="social-icon">
            <FaTwitter />
          </div>
          <div className="social-icon">
            <FaInstagram />
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Subscribe;